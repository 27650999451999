/*
 *
 * ReportingGroups reducer
 *
 */

import { fromJS, List, Map } from 'immutable'

import { ReportingGroupRecord } from 'records'

import {
  ADD_DIMENSIONS_TO_REPORTING_GROUP,
  ADD_DIMENSIONS_TO_REPORTING_GROUP_ERROR,
  ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS,
  ADD_ROW,
  EDIT_ROW,
  CREATE_REPORTING_GROUP,
  CREATE_REPORTING_GROUP_ERROR,
  CREATE_REPORTING_GROUP_SUCCESS,
  DELETE_REPORTING_GROUP,
  DELETE_REPORTING_GROUP_ERROR,
  GET_REPORTING_GROUPS,
  GET_REPORTING_GROUPS_ERROR,
  GET_REPORTING_GROUPS_SUCCESS,
  REMOVE_DIMENSION,
  REMOVE_DIMENSION_ERROR,
  REMOVE_DIMENSION_SUCCESS,
  REMOVE_DIMENSION_VALUE,
  REMOVE_DIMENSION_VALUE_ERROR,
  REMOVE_DIMENSION_VALUE_SUCCESS,
  TOGGLE_SHOW_REPORTING_GROUP_FORM,
  TOGGLE_COLLAPSE,
  UPDATE_REPORTING_GROUP,
  UPDATE_REPORTING_GROUP_ERROR,
  UPDATE_REPORTING_GROUP_SUCCESS,
} from './constants'
import { removeDimension, removeDimensionValue, toggleCollapse } from './utils'

// `reportingGroups` are mapped by companyCode
const initialState = fromJS({
  loading: false,
  error: false,
  reportingGroups: Map(),
  addRowId: undefined,
  editRowId: undefined,
  showCreateReportingGroupForm: false,
})
const createIdList = (dimensionObjects) => {
  return List(
    dimensionObjects ? dimensionObjects.map((rgd) => parseInt(rgd.id, 10)) : []
  )
}
const mapReportingGroup = ({
  reportingGroups,
  reportingGroupDimensions,
  reportingGroupDimensionValues,
  level,
  ...rest
}) => {
  const groupLevel = level || 0
  const reportingGroupChildren = List(
    reportingGroups
      ? reportingGroups.map((r) =>
          mapReportingGroup({ level: groupLevel + 1, ...r })
        )
      : []
  )
  const dimensionIds = createIdList(reportingGroupDimensions)
  const dimensionValueIds = createIdList(reportingGroupDimensionValues)
  const dimensionValueNamesAndDimension = reportingGroupDimensionValues?.map(
    (rgdv) => ({
      dimensionName: rgdv.dimensionName,
      name: rgdv.nameToDisplay,
      isOperativeDimension: rgdv.isOperativeDimension,
    })
  )
  return new ReportingGroupRecord({
    reportingGroups: reportingGroupChildren,
    reportingGroupDimensionIds: dimensionIds,
    reportingGroupDimensionValueIds: dimensionValueIds,
    dimensionValueNamesAndDimension,
    level: groupLevel,
    ...rest,
  })
}

const reportingGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ROW:
      return state.set('addRowId', action.reportingGroupId)

    case EDIT_ROW:
      return state
        .set('editRowId', action.reportingGroupId)
        .set('addRowId', undefined)

    case UPDATE_REPORTING_GROUP:
    case REMOVE_DIMENSION:
    case REMOVE_DIMENSION_VALUE:
    case ADD_DIMENSIONS_TO_REPORTING_GROUP:
    case CREATE_REPORTING_GROUP:
    case DELETE_REPORTING_GROUP:
    case GET_REPORTING_GROUPS:
      return state.set('loading', true).set('error', false)

    case UPDATE_REPORTING_GROUP_ERROR:
    case REMOVE_DIMENSION_ERROR:
    case REMOVE_DIMENSION_VALUE_ERROR:
    case ADD_DIMENSIONS_TO_REPORTING_GROUP_ERROR:
    case CREATE_REPORTING_GROUP_ERROR:
    case DELETE_REPORTING_GROUP_ERROR:
    case GET_REPORTING_GROUPS_ERROR:
      return state.set('error', action.error).set('loading', false)

    case CREATE_REPORTING_GROUP_SUCCESS:
      return state.set('showCreateReportingGroupForm', false)

    case UPDATE_REPORTING_GROUP_SUCCESS:
      return state.set('editRowId', undefined)

    case ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS:
      return state.set('addRowId', undefined)

    case REMOVE_DIMENSION_SUCCESS:
      return state
        .set('loading', false)
        .updateIn(['reportingGroups', action.companyCode], (reportingGroups) =>
          reportingGroups.map((g) => removeDimension(action.dimensionId, g))
        )

    case REMOVE_DIMENSION_VALUE_SUCCESS:
      return state
        .set('loading', false)
        .updateIn(['reportingGroups', action.companyCode], (reportingGroups) =>
          reportingGroups.map((g) =>
            removeDimensionValue(action.dimensionValueId, g)
          )
        )

    case GET_REPORTING_GROUPS_SUCCESS:
      return state
        .set('loading', false)
        .setIn(
          ['reportingGroups', action.companyCode],
          List(action.reportingGroups.map(mapReportingGroup))
        )

    case TOGGLE_SHOW_REPORTING_GROUP_FORM:
      return state.set('showCreateReportingGroupForm', action.state)

    case TOGGLE_COLLAPSE:
      return state.updateIn(
        ['reportingGroups', action.companyCode],
        (reportingGroups) =>
          reportingGroups.map((g) =>
            toggleCollapse({
              toggleId: action.reportingGroupId,
              reportingGroup: g,
            })
          )
      )

    default:
      return state
  }
}

export default reportingGroupsReducer
