import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'

import { handleError } from 'api/api-utils'
import recurringPaymentApi from 'api/RecurringPaymentApi'

import {
  createRecurringPaymentError,
  createRecurringPaymentSuccess,
  deleteRecurringPaymentSuccess,
  getRecurringPaymentsError,
  getRecurringPaymentsSuccess,
  updateRecurringPaymentSuccess,
  updateRecurringPaymentError,
  getRecurringPaymentOccurences as getRecurringPaymentOccurencesAction,
  getRecurringPaymentOccurencesError,
  getRecurringPaymentOccurencesSuccess,
  deleteRecurringPaymentError,
  getRecurringPaymentPaymentEvents as getRecurringPaymentPaymentEventsAction,
  getRecurringPaymentPaymentEventsSuccess,
  getRecurringPaymentPaymentEventsError,
  createRecurringPaymentOccurenceSuccess,
  createRecurringPaymentOccurenceError,
  updateRecurringPaymentOccurenceSuccess,
  updateRecurringPaymentOccurenceError,
} from './actions'
import { getCashFlow } from 'containers/CashFlowContainer/actions'
import {
  CREATE_RECURRING_PAYMENT,
  DELETE_RECURRING_PAYMENT,
  GET_RECURRING_PAYMENTS,
  UPDATE_RECURRING_PAYMENT,
  GET_RECURRING_PAYMENT_PAYMENT_EVENTS,
  CREATE_RECURRING_PAYMENT_OCCURENCE,
  UPDATE_RECURRING_PAYMENT_OCCURENCE,
  GET_RECURRING_PAYMENT_OCCURENCES,
} from './constants'

const UpdateRecurringPaymentRecord = Record({
  dueDate: undefined,
  repeatCount: undefined,
  frequency: undefined,
  amount: undefined,
  paymentType: undefined,
  description: undefined,
})

const UpdateRecurringPaymentOccurenceRecord = Record({
  amount: undefined,
})

export function* deleteRecurringPayment(action) {
  const { recurringPaymentId, companyCode } = action
  try {
    yield call(recurringPaymentApi.deleteRecurringPayment, {
      companyCode,
      recurringPaymentId,
    })
    yield put(deleteRecurringPaymentSuccess({ recurringPaymentId }))
    yield put(getCashFlow({ companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteRecurringPaymentError))
  }
}

export function* createRecurringPayment(action) {
  const { companyCode, recurringPayment } = action
  try {
    const createdRecurringPayment = yield call(
      recurringPaymentApi.createRecurringPayment,
      { companyCode, recurringPayment }
    )
    yield put(
      createRecurringPaymentSuccess({
        recurringPayment: createdRecurringPayment,
      })
    )
    yield put(getCashFlow({ companyCode }))
    yield put(
      getRecurringPaymentPaymentEventsAction({
        companyCode,
      })
    )
  } catch (error) {
    yield put(handleError(error, createRecurringPaymentError))
  }
}

export function* updateRecurringPayment(action) {
  const { companyCode, recurringPayment, updatedRecurringPayment } = action

  const patch = Diff(
    new UpdateRecurringPaymentRecord(recurringPayment),
    new UpdateRecurringPaymentRecord(updatedRecurringPayment)
  ).toJS()
  try {
    const updatedApiRecurringPayment = yield call(
      recurringPaymentApi.patchRecurringPayment,
      {
        companyCode,
        recurringPaymentId: recurringPayment.id,
        patch,
      }
    )
    yield put(
      updateRecurringPaymentSuccess({
        companyCode,
        recurringPayment: updatedApiRecurringPayment,
      })
    )
    yield put(getCashFlow({ companyCode }))
    yield put(getRecurringPaymentPaymentEventsAction({ companyCode }))
  } catch (error) {
    yield put(handleError(error, updateRecurringPaymentError))
  }
}

export function* getRecurringPayments(action) {
  try {
    const { companyCode } = action
    const recurringPayments = yield call(
      recurringPaymentApi.getRecurringPayments,
      {
        companyCode,
      }
    )
    yield put(getRecurringPaymentsSuccess({ recurringPayments }))
  } catch (error) {
    yield put(handleError(error, getRecurringPaymentsError))
  }
}

export function* getRecurringPaymentOccurences(action) {
  try {
    const { recurringPaymentId, companyCode } = action
    const recurringPaymentOccurences = yield call(
      recurringPaymentApi.getRecurringPaymentOccurences,
      {
        recurringPaymentId,
        companyCode,
      }
    )
    yield put(
      getRecurringPaymentOccurencesSuccess({
        recurringPaymentOccurences,
        recurringPaymentId,
      })
    )
  } catch (error) {
    yield put(handleError(error, getRecurringPaymentOccurencesError))
  }
}

export function* createRecurringPaymentOccurence(action) {
  const { companyCode, recurringPaymentId, recurringPaymentOccurence } = action
  try {
    const createdRecurringPayment = yield call(
      recurringPaymentApi.createRecurringPaymentOccurence,
      { companyCode, recurringPaymentId, recurringPaymentOccurence }
    )
    yield put(
      createRecurringPaymentOccurenceSuccess({
        recurringPayment: createdRecurringPayment,
      })
    )
    yield put(
      getRecurringPaymentOccurencesAction({ companyCode, recurringPaymentId })
    )
    yield put(getCashFlow({ companyCode }))
  } catch (error) {
    yield put(handleError(error, createRecurringPaymentOccurenceError))
  }
}

export function* updateRecurringPaymentOccurence(action) {
  const {
    companyCode,
    recurringPaymentOccurence,
    updatedRecurringPaymentOccurence,
  } = action

  const patch = Diff(
    new UpdateRecurringPaymentOccurenceRecord(recurringPaymentOccurence),
    new UpdateRecurringPaymentOccurenceRecord(updatedRecurringPaymentOccurence)
  ).toJS()

  try {
    const updatedApiRecurringPaymentOccurence = yield call(
      recurringPaymentApi.patchRecurringPaymentOccurence,
      {
        companyCode,
        recurringPaymentId: recurringPaymentOccurence.recurringPaymentId,
        id: recurringPaymentOccurence.id,
        patch,
      }
    )
    yield put(
      updateRecurringPaymentOccurenceSuccess({
        companyCode,
        recurringPaymentOccurence: updatedApiRecurringPaymentOccurence,
      })
    )
    yield put(
      getRecurringPaymentOccurencesAction({
        companyCode,
        recurringPaymentId: recurringPaymentOccurence.recurringPaymentId,
      })
    )
    yield put(getCashFlow({ companyCode }))
  } catch (error) {
    yield put(handleError(error, updateRecurringPaymentOccurenceError))
  }
}

export function* getRecurringPaymentPaymentEvents(action) {
  try {
    const { companyCode, redirectRoute, navigate } = action
    const recurringPaymentPaymentEvents = yield call(
      recurringPaymentApi.getRecurringPaymentPaymentEvents,
      { companyCode }
    )
    yield put(
      getRecurringPaymentPaymentEventsSuccess({ recurringPaymentPaymentEvents })
    )
    if (redirectRoute) {
      const { query, pathName } = redirectRoute
      navigate({
        pathName,
        search: query,
      })
    }
  } catch (error) {
    yield put(handleError(error, getRecurringPaymentPaymentEventsError))
  }
}

export function* recurringPaymentsSaga() {
  yield all([
    takeLatest(CREATE_RECURRING_PAYMENT, createRecurringPayment),
    takeLatest(DELETE_RECURRING_PAYMENT, deleteRecurringPayment),
    takeEvery(GET_RECURRING_PAYMENTS, getRecurringPayments),
    takeEvery(GET_RECURRING_PAYMENT_OCCURENCES, getRecurringPaymentOccurences),
    takeLatest(UPDATE_RECURRING_PAYMENT, updateRecurringPayment),
    takeEvery(
      GET_RECURRING_PAYMENT_PAYMENT_EVENTS,
      getRecurringPaymentPaymentEvents
    ),
    takeLatest(
      CREATE_RECURRING_PAYMENT_OCCURENCE,
      createRecurringPaymentOccurence
    ),
    takeLatest(
      UPDATE_RECURRING_PAYMENT_OCCURENCE,
      updateRecurringPaymentOccurence
    ),
  ])
}

export default recurringPaymentsSaga
