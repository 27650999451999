import { List } from 'immutable'
import Papa from 'papaparse'

import { ParsedAccountRecord, ParsedAccountErrorRecord } from 'records'

import {
  accountNumberRange,
  NUM_AND_CHAR_REGEX,
  NUM_ONLY_REGEX,
} from 'api/api-utils'

const getAccountValidationErrors = ({
  accountNumber,
  regex,
  min,
  max,
  accountNumbers,
}) => {
  const validNumber = regex.test(accountNumber)

  if (!validNumber) {
    return {
      type: 'FieldTypeError',
      code: 'NotANumber',
      message: `Unfortunately account number ${accountNumber} is not a valid number.`,
    }
  }

  if (accountNumber < min || accountNumber > max) {
    return {
      type: 'FieldTypeError',
      code: 'NotInRange',
      message: `Unfortunately account number ${accountNumber} is not in allowed range ${min} - ${max}.`,
    }
  }

  if (accountNumbers.includes(accountNumber)) {
    return {
      type: 'DuplicateKeyError',
      code: 'DuplicateKey',
      message: `Duplicate Account number`,
    }
  }
}

const getNameValidationErrors = ({ name }) => {
  if (!name) {
    return {
      type: 'FieldType',
      code: 'EmptyField',
      message: `Empty field`,
    }
  }
}

const getReportingCodeValidationErrors = ({
  regex,
  reportingCode,
  min,
  max,
  reportingCodes,
}) => {
  const validCode = regex.test(reportingCode)

  if (!validCode) {
    return {
      type: 'FieldTypeError',
      code: 'NotANumber',
      message: `Unfortunately reporting code ${reportingCode} is not a valid number.`,
    }
  }
  if (reportingCode < min || reportingCode > max) {
    return {
      type: 'FieldTypeError',
      code: 'NotInRange',
      message: `Unfortunately reporting code ${reportingCode} is not between range.`,
    }
  }
  if (reportingCodes.includes(reportingCode)) {
    return {
      type: 'DuplicateKeyError',
      code: 'DuplicateKey',
      message: `Duplicate code`,
    }
  }
}

const getTargetCompany = ({ companies, targetCompanyValue }) =>
  companies.find(
    (company) =>
      company.code === targetCompanyValue ||
      company.name === targetCompanyValue ||
      company.oVTCode === targetCompanyValue
  )

const getTargetCompanyValidationErrors = ({
  targetCompany,
  targetCompanyValue,
}) => {
  if (!targetCompany) {
    return {
      type: 'FieldTypeError',
      code: 'NotInRange',
      message: `Unable to resolve elimination target company ${targetCompanyValue}. Valid values are Company's code, name or ovt`,
    }
  }
}

const parseAccount = ({
  data,
  errors,
  meta,
  rowNumber,
  accountNumbers,
  reportingCodes,
  regex,
  min,
  max,
  companies,
}) => {
  const accountNumber = data[0][meta.fields[0]]
  const accountValidationError = getAccountValidationErrors({
    accountNumber,
    regex,
    min,
    max,
    accountNumbers,
  })
  if (accountValidationError) errors.push(accountValidationError)
  else accountNumbers.push(accountNumber)

  const name = data[0][meta.fields[1]]
  const nameError = getNameValidationErrors({ name })
  if (nameError) errors.push(nameError)

  const reportingCode = data[0][meta.fields[2]]
  if (reportingCode) {
    const reportingCodeValidationError = getReportingCodeValidationErrors({
      regex,
      reportingCode,
      min,
      max,
      reportingCodes,
    })
    if (reportingCodeValidationError) errors.push(reportingCodeValidationError)
    else reportingCodes.push(reportingCode)
  }

  const isConsolidatedAccountValue = data[0][meta.fields[3]]
  const isConsolidatedAccount = !!isConsolidatedAccountValue?.trim() // turn to boolean

  const targetCompanyValue = data[0][meta.fields[4]]
  const targetCompany = targetCompanyValue
    ? getTargetCompany({ targetCompanyValue, companies })
    : null
  const targetCompanyId = targetCompany?.id
  if (targetCompanyValue) {
    const targetCompanyValidationError = getTargetCompanyValidationErrors({
      targetCompany,
      targetCompanyValue,
    })
    if (targetCompanyValidationError) errors.push(targetCompanyValidationError)
  }

  return new ParsedAccountRecord({
    accountNumber,
    name,
    errors: List(
      errors.map(
        (error) => new ParsedAccountErrorRecord({ ...error, rowNumber })
      )
    ),
    reportingCode,
    rowNumber,
    isConsolidatedAccount,
    targetCompanyId,
  })
}

const parseAccounts = ({
  file,
  allowLetters = false,
  min = accountNumberRange.MIN,
  max = accountNumberRange.MAX,
  companies,
}) =>
  new Promise((resolve, reject) => {
    const accountNumbers = []
    const reportingCodes = []
    const parsedAccounts = []
    const regex = allowLetters ? NUM_AND_CHAR_REGEX : NUM_ONLY_REGEX
    let parseError
    let rowNumber = 2 // First row is header

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: () => {
        if (parseError) {
          reject(parseError)
        }
        resolve({ parsedAccounts, parsedFile: file.name })
      },
      error: (error) => reject(error),
      step: (row, parser) => {
        const { data, meta, errors } = row
        //We require accountnumber and account name fiels. Rest (consolidation / targetCompanyId) are optional
        if (rowNumber === 2 && meta.fields.length < 2) {
          parseError = 'HeaderError'
          parser.abort()
        }
        var parsedAccount = parseAccount({
          data,
          errors,
          meta,
          rowNumber,
          accountNumbers,
          reportingCodes,
          regex,
          min,
          max,
          companies,
        })
        parsedAccounts.push(parsedAccount)
        rowNumber++
      },
    })
  })

export default parseAccounts
