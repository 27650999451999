/*
 *
 * BudgetBalanceModal reducer
 *
 */
import { fromJS } from 'immutable'

import {
  IMPORT_BUDGET_BALANCES,
  GET_BUDGET_BALANCES_PREVIEW,
  IMPORT_BUDGET_BALANCES_ERROR,
  IMPORT_BUDGET_BALANCES_SUCCESS,
  GET_BUDGET_BALANCES_PREVIEW_SUCCESS,
} from './constants'

import { createBudgetData } from 'containers/Budget/reducer'
//import { BudgetDataRecord } from 'records'

const initialState = fromJS({
  loading: false,
  error: undefined,
})

const budgetBalanceModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_BUDGET_BALANCES:
    case GET_BUDGET_BALANCES_PREVIEW:
      return state
        .set('loading', true)
        .set('error', undefined)
        .set('data', undefined)
    case IMPORT_BUDGET_BALANCES_SUCCESS:
      return state.set('loading', false)
    case IMPORT_BUDGET_BALANCES_ERROR:
      return state.set('loading', false).set('error', action.error)
    case GET_BUDGET_BALANCES_PREVIEW_SUCCESS:
      return state
        .set('loading', false)
        .set('data', createBudgetData(action.data))
    default:
      return state
  }
}

export default budgetBalanceModalReducer
