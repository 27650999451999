import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import subBudgetsApi from 'api/SubBudgetsApi'

import { getBudget } from 'containers/Budget/actions'
import { getContents } from 'containers/Content/actions'
import { CONTENT_TYPE_BUDGET } from 'containers/Content/constants'

import {
  CREATE_SUB_BUDGET,
  DELETE_SUB_BUDGET,
  GET_ALL_SUB_BUDGETS,
  GET_BUDGET_SUB_BUDGETS,
  UPDATE_SUB_BUDGET,
} from './constants'
import {
  createSubBudgetError,
  createSubBudgetSuccess,
  deleteSubBudgetError,
  deleteSubBudgetSuccess,
  getAllSubBudgetsError,
  getAllSubBudgetsSuccess,
  getBudgetSubBudgetsError,
  getBudgetSubBudgetsSuccess,
  updateSubBudgetError,
  updateSubBudgetSuccess,
} from './actions'

export function* getAllSubBudgets(action) {
  const { companyCode } = action
  try {
    const subBudgets = yield call(subBudgetsApi.getAllSubBudgets, {
      companyCode,
    })
    yield put(getAllSubBudgetsSuccess({ subBudgets, companyCode }))
  } catch (error) {
    yield put(handleError(error, getAllSubBudgetsError))
  }
}

export function* getBudgetSubBudgets(action) {
  const { companyCode, budgetId } = action
  try {
    const subBudgets = yield call(subBudgetsApi.getBudgetSubBudgets, {
      companyCode,
      budgetId,
    })
    yield put(getBudgetSubBudgetsSuccess({ subBudgets, budgetId, companyCode }))
  } catch (error) {
    yield put(handleError(error, getBudgetSubBudgetsError))
  }
}

export function* deleteSubBudget(action) {
  const { companyCode, budgetId, dv, end, start, subBudgetId, view } = action

  try {
    yield call(subBudgetsApi.deleteSubBudget, {
      companyCode,
      budgetId,
      subBudgetId,
    })
    yield put(getBudget({ budgetId, companyCode, dv, end, start, view }))
    yield put(deleteSubBudgetSuccess({ budgetId, subBudgetId }))
    //rearrange content state
    yield put(
      getContents({ contentType: CONTENT_TYPE_BUDGET, targetId: budgetId })
    )
  } catch (error) {
    yield put(handleError(error, deleteSubBudgetError))
  }
}

export function* createSubBudget(action) {
  const {
    companyCode,
    budgetId,
    subBudget,
    dv,
    end,
    start,
    view,
    subBudget: { subBudgetId },
  } = action
  const copySubBudget = Boolean(subBudgetId)

  try {
    let createdSubBudget
    if (copySubBudget) {
      createdSubBudget = yield call(subBudgetsApi.createSubBudgetWithCopy, {
        companyCode,
        budgetId,
        subBudget,
        sourceSubBudgetId: subBudgetId,
      })
    } else {
      createdSubBudget = yield call(subBudgetsApi.createSubBudget, {
        companyCode,
        budgetId,
        subBudget,
      })
    }

    yield put(
      createSubBudgetSuccess({
        budgetId,
        subBudget: createdSubBudget,
        companyCode,
      })
    )
    if (copySubBudget) {
      // this is necessary to rearrange content state
      yield put(
        getContents({ contentType: CONTENT_TYPE_BUDGET, targetId: budgetId })
      )
      yield put(getBudget({ budgetId, companyCode, dv, end, start, view }))
    }
  } catch (error) {
    yield put(handleError(error, createSubBudgetError))
  }
}

export function* updateSubBudget(action) {
  const {
    companyCode,
    budgetId,
    sourceSubBudget: { id: subBudgetId },
    updatedSubBudget: { name, displayDecimalsAmount, presentationType, status },
  } = action

  const patch = [
    {
      op: 'replace',
      path: 'name',
      value: name,
    },
    {
      op: 'replace',
      path: 'displayDecimalsAmount',
      value: displayDecimalsAmount || 0,
    },
    {
      op: 'replace',
      path: 'presentationType',
      value: presentationType || 'Months',
    },
    {
      op: 'replace',
      path: 'status',
      value: status,
    },
  ]

  try {
    const subBudget = yield call(subBudgetsApi.patchSubBudget, {
      companyCode,
      budgetId,
      subBudgetId,
      patch,
    })

    yield put(updateSubBudgetSuccess({ budgetId, subBudget, companyCode }))
  } catch (error) {
    yield put(handleError(error, updateSubBudgetError))
  }
}

export function* subBudgetsSaga() {
  yield all([
    takeLatest(GET_BUDGET_SUB_BUDGETS, getBudgetSubBudgets),
    takeEvery(DELETE_SUB_BUDGET, deleteSubBudget),
    takeEvery(CREATE_SUB_BUDGET, createSubBudget),
    takeLatest(GET_ALL_SUB_BUDGETS, getAllSubBudgets),
    takeLatest(UPDATE_SUB_BUDGET, updateSubBudget),
  ])
}

export default subBudgetsSaga
