import { Record, List } from 'immutable'

const RollingRuleSchemeTree = new Record({
  children: List(),
  dimensionValueId: undefined,
  path: '',
  rowId: undefined,
  rowType: 'rolling-scheme-row',
  id: undefined,
  editable: false,
  rows: List(),
  type: 'budget-scheme-group',
  name: '',
})

export default RollingRuleSchemeTree
