import { BackButton } from 'components/Button'
import { RefreshButton } from 'components/Button'
import { Container, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import messages from '../messages'
import styles from '../styles.module.css'

export const tryParse = (message) => {
  if (message.includes('Uncaught exception was thrown:')) {
    const splitFromStack = message.split('   at ')[0]
    const splitFromPrefix = splitFromStack.split(
      'Uncaught exception was thrown:'
    )[1]
    return splitFromPrefix
  } else if (message.includes('   at ')) {
    const splitFromStack = message.split('   at ')[0]
    return splitFromStack
  } else if (message.includes('.cs')) {
    //Could not find stack. But one exists
    return <FormattedMessage {...messages.unknown} />
  }
  return message
}
export const AADInvitationError = (props) => {
  const msg =
    props.message && props.message.length > 0 ? (
      props.message
    ) : (
      <FormattedMessage {...messages.aadInvitationError} />
    )
  const title = !!props.title ? (
    <FormattedMessage {...messages.aadInvitationErrorTitle} />
  ) : (
    <FormattedMessage {...messages.aadInvitationProblemTitle} />
  )
  return (
    <Container>
      <Card>
        <Card.Header as="h3" className={styles.errorHeader}>
          {title}
        </Card.Header>
        <Card.Body>
          <Card.Text>{msg}</Card.Text>
          <RefreshButton />
        </Card.Body>
      </Card>
    </Container>
  )
}

export const JsErrorComponent = () => {
  return (
    <Container>
      <Card>
        <Card.Header as="h3" className={styles.errorHeader}>
          <FormattedMessage {...messages.error} />
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <FormattedMessage {...messages.goBackOrTryAgain} />
          </Card.Text>
          <BackButton /> <RefreshButton />
        </Card.Body>
      </Card>
    </Container>
  )
}

export const InvalidVoucherlineError = (props) => {
  const { errorMessages } = props
  return (
    <Card>
      <Card.Header as="h3" className={styles.errorHeader}>
        <FormattedMessage {...messages.voucherError} />
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <ul>{errorMessages?.map((message) => <li>{message}</li>)}</ul>
        </Card.Text>
        <RefreshButton />
      </Card.Body>
    </Card>
  )
}

export const InvalidReportError = (props) => {
  return (
    <Card>
      <Card.Header as="h3" className={styles.errorHeader}>
        <FormattedMessage {...messages.brokenReport} />
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <FormattedMessage {...messages.reportError} />
        </Card.Text>
        <BackButton />
      </Card.Body>
    </Card>
  )
}

export const ForbiddenErrorComponent = (props) => {
  return (
    <Card>
      <Card.Header as="h3" className={styles.errorHeader}>
        <FormattedMessage {...messages.forbidden} />
      </Card.Header>
      <Card.Body>
        <BackButton />
      </Card.Body>
    </Card>
  )
}

const DefaultErrorComponent = (props) => {
  const {
    config,
    data,
    jsError,
    statusText,
    errorMessages,
    message,
    hideRefreshButton,
  } = props
  return (
    <Card
      header={
        <h3>
          <FormattedMessage {...messages.header} />
        </h3>
      }
      variant="danger"
    >
      <h3>{jsError || statusText || 'Something went wrong'}</h3>
      {data && message && errorMessages?.length === 1
        ? errorMessages[0]
        : errorMessages?.map?.((m, index) =>
            tryParse(m).length > 0 ? (
              <ul key={index}>
                <li key={`message-${index}`}>{m}</li>
              </ul>
            ) : null
          )}
      <ul>
        {data && data.config && <li>{config.url}</li>}
        {data?.errors?.map?.(
          (error, index) =>
            error && (
              <li key={index}>
                {error.code} - {tryParse(error.message || error)}
              </li>
            )
        )}
      </ul>
      {!hideRefreshButton && (
        <div>
          <RefreshButton />
        </div>
      )}
    </Card>
  )
}

export default DefaultErrorComponent
