import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const setCashInHand = ({ companyCode, amount }) =>
  axios
    .post(`${companyCode}/CashInHand`, { amount }, config())
    .then(returnData)
    .catch(catchError)

const cashInHandApi = { setCashInHand }

export default cashInHandApi
