import { Record, OrderedMap } from 'immutable'

export const TREE_ITEM_NORMAL = 'normal'
export const TREE_ITEM_FETCHING = 'fetching'
export const TREE_ITEM_FETCHING_ERROR = 'error'

export const TREE_ITEM_RENDER_TYPE_CHECKBOX = 'tree-item-checkbox'
export const TREE_ITEM_RENDER_TYPE_TOGGLE_BUTTONS = 'tree-item-toggle-buttons'
export const TREE_ITEM_RENDER_TYPE_NONE = 'none'

const TreeItemRecord = Record({
  id: '',
  name: '',
  itemType: '',
  canHaveChildren: false,
  items: OrderedMap(),
  status: TREE_ITEM_NORMAL,
  renderType: TREE_ITEM_RENDER_TYPE_CHECKBOX,
})

export default TreeItemRecord
