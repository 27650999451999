import {
  CREATE_DASHBOARD,
  CREATE_DASHBOARD_ERROR,
  CREATE_DASHBOARD_SUCCESS,
  CREATE_DASHBOARD_SHARE,
  CREATE_DASHBOARD_SHARE_ERROR,
  CREATE_DASHBOARD_SHARE_SUCCESS,
  COPY_DASHBOARD,
  COPY_DASHBOARD_ERROR,
  COPY_DASHBOARD_SUCCESS,
  COPY_DASHBOARD_WIDGET,
  COPY_DASHBOARD_WIDGET_ERROR,
  COPY_DASHBOARD_WIDGET_SUCCESS,
  CREATE_DASHBOARD_WIDGET,
  CREATE_DASHBOARD_WIDGET_ERROR,
  CREATE_DASHBOARD_WIDGET_SUCCESS,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_ERROR,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_SHARE,
  DELETE_DASHBOARD_SHARE_ERROR,
  DELETE_DASHBOARD_SHARE_SUCCESS,
  DELETE_DASHBOARD_WIDGET,
  DELETE_DASHBOARD_WIDGET_ERROR,
  DELETE_DASHBOARD_WIDGET_SUCCESS,
  GET_DASHBOARDS,
  GET_DASHBOARDS_ERROR,
  GET_DASHBOARDS_SUCCESS,
  GET_DASHBOARD_SHARES,
  GET_DASHBOARD_SHARES_ERROR,
  GET_DASHBOARD_SHARES_SUCCESS,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_ERROR,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_SHARES,
  UPDATE_DASHBOARD_SHARES_ERROR,
  UPDATE_DASHBOARD_SHARES_SUCCESS,
  UPDATE_DASHBOARD_WIDGET,
  UPDATE_DASHBOARD_WIDGET_ERROR,
  UPDATE_DASHBOARD_WIDGET_SUCCESS,
  UPDATE_DASHBOARD_WIDGET_PARAMETERS,
  UPDATE_DASHBOARD_WIDGET_PARAMETERS_ERROR,
  UPDATE_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
} from './constants'

export const createDashboard = ({ name }) => ({
  type: CREATE_DASHBOARD,
  name,
})
export const createDashboardError = ({ error }) => ({
  type: CREATE_DASHBOARD_ERROR,
  error,
})
export const createDashboardSuccess = ({ dashboard }) => ({
  type: CREATE_DASHBOARD_SUCCESS,
  dashboard,
})

export const createDashboardShare = ({ dashboardId, userIds }) => ({
  type: CREATE_DASHBOARD_SHARE,
  dashboardId,
  userIds,
})
export const createDashboardShareSuccess = ({ dashboardId, userIds }) => ({
  type: CREATE_DASHBOARD_SHARE_SUCCESS,
  dashboardId,
  userIds,
})
export const createDashboardShareError = ({ dashboardId }) => ({
  type: CREATE_DASHBOARD_SHARE_ERROR,
  dashboardId,
})

export const copyDashboard = ({ copyDashboardShares, id, name }) => ({
  type: COPY_DASHBOARD,
  copyDashboardShares,
  id,
  name,
})
export const copyDashboardError = ({ error }) => ({
  type: COPY_DASHBOARD_ERROR,
  error,
})
export const copyDashboardSuccess = ({ dashboard }) => ({
  type: COPY_DASHBOARD_SUCCESS,
  dashboard,
})

export const copyDashboardWidget = ({ dashboardId, widget }) => ({
  type: COPY_DASHBOARD_WIDGET,
  dashboardId,
  widget,
})
export const copyDashboardWidgetError = () => ({
  type: COPY_DASHBOARD_WIDGET_ERROR,
})

export const copyDashboardWidgetSuccess = ({ widget, temporaryWidgetId }) => ({
  type: COPY_DASHBOARD_WIDGET_SUCCESS,
  widget,
  temporaryWidgetId,
})

export const createDashboardWidget = ({ dashboardId, widget }) => ({
  type: CREATE_DASHBOARD_WIDGET,
  dashboardId,
  widget,
})
export const createDashboardWidgetError = () => ({
  type: CREATE_DASHBOARD_WIDGET_ERROR,
})

export const createDashboardWidgetSuccess = ({
  widget,
  temporaryWidgetId,
}) => ({
  type: CREATE_DASHBOARD_WIDGET_SUCCESS,
  widget,
  temporaryWidgetId,
})

export const deleteDashboard = ({
  companyCode,
  customerCode,
  id,
  navigate,
}) => ({
  type: DELETE_DASHBOARD,
  companyCode,
  customerCode,
  id,
  navigate,
})
export const deleteDashboardError = ({ id, error }) => ({
  type: DELETE_DASHBOARD_ERROR,
  id,
  error,
})
export const deleteDashboardSuccess = ({ id }) => ({
  type: DELETE_DASHBOARD_SUCCESS,
  id,
})

export const deleteDashboardShare = ({ dashboardId, userIds }) => ({
  type: DELETE_DASHBOARD_SHARE,
  dashboardId,
  userIds,
})
export const deleteDashboardShareSuccess = ({ dashboardId, userIds }) => ({
  type: DELETE_DASHBOARD_SHARE_SUCCESS,
  dashboardId,
  userIds,
})
export const deleteDashboardShareError = ({ dashboardId }) => ({
  type: DELETE_DASHBOARD_SHARE_ERROR,
  dashboardId,
})

export const deleteDashboardWidget = ({ dashboardId, widgetId }) => ({
  type: DELETE_DASHBOARD_WIDGET,
  widgetId,
  dashboardId,
})

export const deleteDashboardWidgetSuccess = () => ({
  type: DELETE_DASHBOARD_WIDGET_SUCCESS,
})

export const deleteDashboardWidgetError = () => ({
  type: DELETE_DASHBOARD_WIDGET_ERROR,
})

export const getDashboards = ({ companyCode }) => ({
  type: GET_DASHBOARDS,
  companyCode,
})
export const getDashboardsError = (error) => ({
  type: GET_DASHBOARDS_ERROR,
  error,
})
export const getDashboardsSuccess = ({ dashboards }) => ({
  type: GET_DASHBOARDS_SUCCESS,
  dashboards,
})

export const getDashboardShares = ({ dashboardId }) => ({
  type: GET_DASHBOARD_SHARES,
  dashboardId,
})
export const getDashboardSharesError = ({ dashboardId, error }) => ({
  type: GET_DASHBOARD_SHARES_ERROR,
  dashboardId,
  error,
})
export const getDashboardSharesSuccess = ({ dashboardId, userIds }) => ({
  type: GET_DASHBOARD_SHARES_SUCCESS,
  dashboardId,
  userIds,
})

export const updateDashboard = ({ dashboard, updatedDashboard }) => ({
  type: UPDATE_DASHBOARD,
  dashboard,
  updatedDashboard,
})
export const updateDashboardError = ({ error, dashboardId }) => ({
  type: UPDATE_DASHBOARD_ERROR,
  dashboardId,
  error,
})
export const updateDashboardSuccess = ({ dashboard }) => ({
  type: UPDATE_DASHBOARD_SUCCESS,
  dashboard,
})

export const updateDashboardShares = ({
  dashboardId,
  userIdsToAdd,
  userIdsToRemove,
}) => ({
  type: UPDATE_DASHBOARD_SHARES,
  dashboardId,
  userIdsToAdd,
  userIdsToRemove,
})
export const updateDashboardSharesError = ({ dashboardId }) => ({
  type: UPDATE_DASHBOARD_SHARES_ERROR,
  dashboardId,
})
export const updateDashboardSharesSuccess = ({ dashboardId }) => ({
  type: UPDATE_DASHBOARD_SHARES_SUCCESS,
  dashboardId,
})

export const updateDashboardWidget = ({ dashboardId, editWidget, widget }) => ({
  type: UPDATE_DASHBOARD_WIDGET,
  dashboardId,
  editWidget,
  widget,
})
export const updateDashboardWidgetError = ({ error, widgetId }) => ({
  type: UPDATE_DASHBOARD_WIDGET_ERROR,
  error,
  widgetId,
})
export const updateDashboardWidgetSuccess = ({ dashboardId, widget }) => ({
  type: UPDATE_DASHBOARD_WIDGET_SUCCESS,
  dashboardId,
  widget,
})

export const updateDashboardWidgetParameters = ({
  dashboardId,
  widgetId,
  parameters,
}) => ({
  type: UPDATE_DASHBOARD_WIDGET_PARAMETERS,
  dashboardId,
  widgetId,
  parameters,
})

export const updateDashboardWidgetParametersSuccess = () => ({
  type: UPDATE_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
})

export const updateDashboardWidgetParametersError = () => ({
  type: UPDATE_DASHBOARD_WIDGET_PARAMETERS_ERROR,
})
