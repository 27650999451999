export const GET_NOTIFICATIONS = 'app/Notifications/GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS =
  'app/Notifications/GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR =
  'app/Notifications/GET_NOTIFICATIONS_ERROR'

export const CREATE_NOTIFICATION = 'app/Notifications/CREATE_NOTIFICATION'
export const CREATE_NOTIFICATION_SUCCESS =
  'app/Notifications/CREATE_NOTIFICATION_SUCCESS'
export const CREATE_NOTIFICATION_ERROR =
  'app/Notifications/CREATE_NOTIFICATION_ERROR'

export const DISMISS_ALL_NOTIFICATIONS =
  'app/Notifications/DISMISS_ALL_NOTIFICATIONS'
export const DISMISS_ALL_NOTIFICATIONS_SUCCESS =
  'app/Notifications/DISMISS_ALL_NOTIFICATIONS_SUCCESS'
export const DISMISS_ALL_NOTIFICATIONS_ERROR =
  'app/Notifications/DISMISS_ALL_NOTIFICATIONS_ERROR'

export const DISMISS_NOTIFICATION = 'app/Notifications/DISMISS_NOTIFICATION'
export const DISMISS_NOTIFICATION_SUCCESS =
  'app/Notifications/DISMISS_NOTIFICATION_SUCCESS'
export const DISMISS_NOTIFICATION_ERROR =
  'app/Notifications/DISMISS_NOTIFICATION_ERROR'

export const DELETE_NOTIFICATION = 'app/Notifications/DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS =
  'app/Notifications/DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_ERROR =
  'app/Notifications/DELETE_NOTIFICATION_ERROR'

export const NOTIFICATION_SCOPE_SYSTEM = 'System'
export const NOTIFICATION_SCOPE_COMPANY = 'Company'
export const NOTIFICATION_SCOPE_USER = 'User'

export const NOTIFICATION_STATUS_INFORMATION = 'Information'
export const NOTIFICATION_STATUS_WARNING = 'Warning'
export const NOTIFICATION_STATUS_ERROR = 'Error'
export const NOTIFICATION_STATUS_PENDING = 'Pending'

export const NOTIFICATION_SEVERITIES = [
  NOTIFICATION_STATUS_INFORMATION,
  NOTIFICATION_STATUS_WARNING,
  NOTIFICATION_STATUS_ERROR,
  NOTIFICATION_STATUS_PENDING,
]

export const SENDABLE_NOTIFICATION_SEVERITIES = [
  NOTIFICATION_STATUS_INFORMATION,
  NOTIFICATION_STATUS_WARNING,
  NOTIFICATION_STATUS_ERROR,
]

export const NOTIFICATION_SCOPES = [
  NOTIFICATION_SCOPE_USER,
  NOTIFICATION_SCOPE_COMPANY,
  NOTIFICATION_SCOPE_SYSTEM,
]
