import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import notificationsApi from 'api/NotificationsApi'
import {
  createNotificationError,
  createNotificationSuccess,
  deleteNotificationError,
  deleteNotificationSuccess,
  dismissAllNotificationsSuccess,
  dismissNotificationError,
  dismissNotificationSuccess,
  getNotificationsError,
  getNotificationsSuccess,
} from './actions'
import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  DISMISS_ALL_NOTIFICATIONS,
  DISMISS_NOTIFICATION,
  GET_NOTIFICATIONS,
} from './constants'

function* getNotifications({ companyCode, customerCode }) {
  try {
    const notifications = yield call(notificationsApi.getNotifications, {
      companyCode,
      customerCode,
    })
    yield put(
      getNotificationsSuccess({ notifications, companyCode, customerCode })
    )
  } catch (error) {
    yield put(
      handleError(error, getNotificationsError, { companyCode, customerCode })
    )
  }
}

function* createNotification({ companyCode, customerCode, notification }) {
  try {
    const notificationResponse = yield call(
      notificationsApi.createNotification,
      {
        companyCode,
        customerCode,
        notification,
      }
    )
    yield put(
      createNotificationSuccess({
        notification: notificationResponse,
        companyCode,
        customerCode,
      })
    )
    yield getNotifications({ companyCode, customerCode })
  } catch (error) {
    yield put(
      handleError(error, createNotificationError, {
        companyCode,
        customerCode,
        notification,
      })
    )
  }
}

function* dismissNotification({ companyCode, customerCode, notificationId }) {
  try {
    yield call(notificationsApi.dismissNotification, {
      companyCode,
      customerCode,
      notificationId,
    })
    yield put(
      dismissNotificationSuccess({
        notificationId,
        companyCode,
        customerCode,
      })
    )
  } catch (error) {
    yield put(
      handleError(error, dismissNotificationError, {
        companyCode,
        customerCode,
      })
    )
  }
}

function* dismissAllNotifications({ companyCode, customerCode }) {
  try {
    yield call(notificationsApi.dismissAllNotifications, {
      companyCode,
      customerCode,
    })
    yield put(
      dismissAllNotificationsSuccess({
        companyCode,
        customerCode,
      })
    )
  } catch (error) {
    yield put(
      handleError(error, dismissNotificationError, {
        companyCode,
        customerCode,
      })
    )
  }
}

function* deleteNotification({ companyCode, customerCode, notificationId }) {
  try {
    yield call(notificationsApi.deleteNotification, {
      companyCode,
      customerCode,
      notificationId,
    })
    yield put(
      deleteNotificationSuccess({
        notificationId,
        companyCode,
        customerCode,
      })
    )
  } catch (error) {
    yield put(
      handleError(error, deleteNotificationError, {
        companyCode,
        customerCode,
      })
    )
  }
}

// All sagas to be loaded
export function* notificationsSaga() {
  yield all([
    takeLatest(GET_NOTIFICATIONS, getNotifications),
    takeEvery(CREATE_NOTIFICATION, createNotification),
    takeEvery(DISMISS_NOTIFICATION, dismissNotification),
    takeEvery(DELETE_NOTIFICATION, deleteNotification),
    takeEvery(DISMISS_ALL_NOTIFICATIONS, dismissAllNotifications),
  ])
}

const sagas = [notificationsSaga]
export default sagas
