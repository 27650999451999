import { all, call, put, takeEvery } from 'redux-saga/effects'
import api from 'api/rollingrule-api'
import { handleError } from 'api/api-utils'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'
import {
  CREATE_ROLLINGRULE,
  DELETE_ROLLINGRULE,
  GET_ROLLINGRULES,
  UPDATE_ROLLING_RULE,
} from './constants'

import {
  createRollingRuleError,
  createRollingRuleSuccess,
  deleteRollingRuleError,
  deleteRollingRuleSuccess,
  getRollingRulesError,
  getRollingRulesSuccess,
  updateRollingRuleError,
  updateRollingRuleSuccess,
} from './actions'

const UpdateRollingRuleRecord = Record({
  name: undefined,
  dimensionId: undefined,
  rollingRuleType: undefined,
  percentageMode: undefined,
  totalShare: undefined,
  sourceDimensionValueId: undefined,
})

export function* getRollingRules(action) {
  const { companyCode } = action
  try {
    const rollingRules = yield call(api.getRollingRules, companyCode)
    yield put(getRollingRulesSuccess({ companyCode, rollingRules }))
  } catch (error) {
    yield put(handleError(error, getRollingRulesError))
  }
}

export function* createRollingRule(action) {
  const { companyCode, rule } = action
  try {
    const rollingRule = yield call(api.addRollingRule, companyCode, rule)
    yield put(createRollingRuleSuccess({ companyCode, rollingRule }))
  } catch (err) {
    yield put(handleError(err, createRollingRuleError))
  }
}

export function* updateRollingRule(action) {
  const { companyCode, oldRollingRule, newRollingRule } = action
  const patch = Diff(
    new UpdateRollingRuleRecord(oldRollingRule),
    new UpdateRollingRuleRecord(newRollingRule)
  ).toJS()
  try {
    const rollingRule = yield call(
      api.patchRollingRule,
      companyCode,
      patch,
      oldRollingRule.id
    )
    yield put(updateRollingRuleSuccess({ companyCode, rollingRule }))
  } catch (err) {
    yield put(handleError(err, updateRollingRuleError))
  }
}

export function* deleteRollingRule(action) {
  const { companyCode, ruleId } = action
  try {
    yield call(api.deleteRollingRule, ruleId, companyCode)
    yield put(deleteRollingRuleSuccess({ companyCode, ruleId }))
  } catch (err) {
    yield put(handleError(err, deleteRollingRuleError))
  }
}

// TODO: remove saga-utils do everythings here
export function* commonRollingruleSaga() {
  yield all([
    takeEvery(GET_ROLLINGRULES, getRollingRules),
    takeEvery(CREATE_ROLLINGRULE, createRollingRule),
    takeEvery(DELETE_ROLLINGRULE, deleteRollingRule),
    takeEvery(UPDATE_ROLLING_RULE, updateRollingRule),
  ])
}

// All sagas to be loaded
export default commonRollingruleSaga
