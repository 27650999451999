export const GET_RECURRING_PAYMENTS =
  'app/RecurringPayments/GET_RECURRING_PAYMENTS'
export const GET_RECURRING_PAYMENTS_ERROR =
  'app/RecurringPayments/GET_RECURRING_PAYMENTS_ERROR'
export const GET_RECURRING_PAYMENTS_SUCCESS =
  'app/RecurringPayments/GET_RECURRING_PAYMENTS_SUCCESS'

export const DELETE_RECURRING_PAYMENT =
  'app/RecurringPayments/DELETE_RECURRING_PAYMENT'
export const DELETE_RECURRING_PAYMENT_ERROR =
  'app/RecurringPayments/DELETE_RECURRING_PAYMENT_ERROR'
export const DELETE_RECURRING_PAYMENT_SUCCESS =
  'app/RecurringPayments/DELETE_RECURRING_PAYMENT_SUCCESS'

export const UPDATE_RECURRING_PAYMENT =
  'app/RecurringPayments/UPDATE_RECURRING_PAYMENT'
export const UPDATE_RECURRING_PAYMENT_ERROR =
  'app/RecurringPayments/UPDATE_RECURRING_PAYMENT_ERROR'
export const UPDATE_RECURRING_PAYMENT_SUCCESS =
  'app/RecurringPayments/UPDATE_RECURRING_PAYMENT_SUCCESS'

export const CREATE_RECURRING_PAYMENT =
  'app/RecurringPayments/CREATE_RECURRING_PAYMENT'
export const CREATE_RECURRING_PAYMENT_ERROR =
  'app/RecurringPayments/CREATE_RECURRING_PAYMENT_ERROR'
export const CREATE_RECURRING_PAYMENT_SUCCESS =
  'app/RecurringPayments/CREATE_RECURRING_PAYMENT_SUCCESS'

export const GET_RECURRING_PAYMENT_OCCURENCES =
  'app/RecurringPayments/GET_RECURRING_PAYMENT_OCCURENCES'
export const GET_RECURRING_PAYMENT_OCCURENCES_ERROR =
  'app/RecurringPayments/GET_RECURRING_PAYMENT_OCCURENCES_ERROR'
export const GET_RECURRING_PAYMENT_OCCURENCES_SUCCESS =
  'app/RecurringPayments/GET_RECURRING_PAYMENT_OCCURENCES_SUCCESS'

export const DELETE_RECURRING_PAYMENT_OCCURENCE =
  'app/RecurringPayments/DELETE_RECURRING_PAYMENT_OCCURENCE'
export const DELETE_RECURRING_PAYMENT_OCCURENCE_ERROR =
  'app/RecurringPayments/DELETE_RECURRING_PAYMENT_OCCURENCE_ERROR'
export const DELETE_RECURRING_PAYMENT_OCCURENCE_SUCCESS =
  'app/RecurringPayments/DELETE_RECURRING_PAYMENT_OCCURENCE_SUCCESS'

export const UPDATE_RECURRING_PAYMENT_OCCURENCE =
  'app/RecurringPayments/UPDATE_RECURRING_PAYMENT_OCCURENCE'
export const UPDATE_RECURRING_PAYMENT_OCCURENCE_ERROR =
  'app/RecurringPayments/UPDATE_RECURRING_PAYMENT_OCCURENCE_ERROR'
export const UPDATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS =
  'app/RecurringPayments/UPDATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS'

export const CREATE_RECURRING_PAYMENT_OCCURENCE =
  'app/RecurringPayments/CREATE_RECURRING_PAYMENT_OCCURENCE'
export const CREATE_RECURRING_PAYMENT_OCCURENCE_ERROR =
  'app/RecurringPayments/CREATE_RECURRING_PAYMENT_OCCURENCE_ERROR'
export const CREATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS =
  'app/RecurringPayments/CREATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS'

export const GET_RECURRING_PAYMENT_PAYMENT_EVENTS =
  'app/RecurringPayments/GET_RECURRING_PAYMENT_PAYMENT_EVENTS'
export const GET_RECURRING_PAYMENT_PAYMENT_EVENTS_ERROR =
  'app/RecurringPayments/GET_RECURRING_PAYMENT_PAYMENT_EVENTS_ERROR'
export const GET_RECURRING_PAYMENT_PAYMENT_EVENTS_SUCCESS =
  'app/RecurringPayments/GET_RECURRING_PAYMENT_PAYMENT_EVENTS_SUCCESS'

export const RECURRING_PAYMENT_FREQUENCY_DAILY = 'Daily'
export const RECURRING_PAYMENT_FREQUENCY_WEEKLY = 'Weekly'
export const RECURRING_PAYMENT_FREQUENCY_MONTHLY = 'Monthly'
export const RECURRING_PAYMENT_FREQUENCY_ANNUALLY = 'Annually'
export const RECURRING_PAYMENT_FREQUENCIES = {
  0: RECURRING_PAYMENT_FREQUENCY_DAILY,
  5: RECURRING_PAYMENT_FREQUENCY_WEEKLY,
  10: RECURRING_PAYMENT_FREQUENCY_MONTHLY,
  15: RECURRING_PAYMENT_FREQUENCY_ANNUALLY,
}

export const PAYMENT_TYPE_INCOME = 'Income'
export const PAYMENT_TYPE_EXPENSE = 'Expense'
export const PAYMENT_TYPES = {
  0: PAYMENT_TYPE_INCOME,
  1: PAYMENT_TYPE_EXPENSE,
}
