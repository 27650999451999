import { fromJS, List, Map } from 'immutable'

import {
  COPY_CUSTOMER_DASHBOARD,
  COPY_CUSTOMER_DASHBOARD_ERROR,
  COPY_CUSTOMER_DASHBOARD_SUCCESS,
  COPY_CUSTOMER_DASHBOARD_WIDGET,
  COPY_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  COPY_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  CREATE_CUSTOMER_DASHBOARD,
  CREATE_CUSTOMER_DASHBOARD_ERROR,
  CREATE_CUSTOMER_DASHBOARD_SUCCESS,
  CREATE_CUSTOMER_DASHBOARD_WIDGET,
  CREATE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  CREATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  DELETE_CUSTOMER_DASHBOARD,
  DELETE_CUSTOMER_DASHBOARD_ERROR,
  DELETE_CUSTOMER_DASHBOARD_SUCCESS,
  DELETE_CUSTOMER_DASHBOARD_WIDGET,
  DELETE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  DELETE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  GET_CUSTOMER_DASHBOARDS,
  GET_CUSTOMER_DASHBOARDS_ERROR,
  GET_CUSTOMER_DASHBOARDS_SUCCESS,
  UPDATE_CUSTOMER_DASHBOARD,
  UPDATE_CUSTOMER_DASHBOARD_ERROR,
  UPDATE_CUSTOMER_DASHBOARD_SUCCESS,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_ERROR,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
} from './constants'
import { mapDashboard, mapWidget } from './functions'

const initialState = fromJS({
  dashboards: Map(),
  error: false,
  loading: false,
  widgets: Map(),
})

const dashboardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COPY_CUSTOMER_DASHBOARD:
    case CREATE_CUSTOMER_DASHBOARD:
    case DELETE_CUSTOMER_DASHBOARD:
    case GET_CUSTOMER_DASHBOARDS:
    case UPDATE_CUSTOMER_DASHBOARD: {
      return state.set('loading', true).set('error', false)
    }

    case COPY_CUSTOMER_DASHBOARD_ERROR:
    case COPY_CUSTOMER_DASHBOARD_WIDGET_ERROR:
    case CREATE_CUSTOMER_DASHBOARD_ERROR:
    case CREATE_CUSTOMER_DASHBOARD_WIDGET_ERROR:
    case DELETE_CUSTOMER_DASHBOARD_ERROR:
    case DELETE_CUSTOMER_DASHBOARD_WIDGET_ERROR:
    case GET_CUSTOMER_DASHBOARDS_ERROR:
    case UPDATE_CUSTOMER_DASHBOARD_ERROR:
    case UPDATE_CUSTOMER_DASHBOARD_WIDGET_ERROR:
    case UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_ERROR: {
      return state.set('loading', false).set('error', action.error)
    }

    case COPY_CUSTOMER_DASHBOARD_SUCCESS: {
      return state
        .updateIn(['dashboards', action.customerCode], (dashboards) => {
          const newDashboard = mapDashboard(action.dashboard)
          return dashboards
            ? new List([...dashboards].concat(newDashboard))
            : new List(newDashboard)
        })
        .updateIn(['widgets', action.customerCode], (widgets) => {
          const newWidgets = action.dashboard.widgets.map((widget) =>
            mapWidget({ widget, dashboardId: action.dashboard.id })
          )
          return widgets
            ? new List([...widgets].concat(newWidgets))
            : new List(newWidgets)
        })
        .set('loading', false)
    }

    case CREATE_CUSTOMER_DASHBOARD_SUCCESS: {
      return state
        .updateIn(['dashboards', action.customerCode], (dashboards) => {
          const newDashboard = mapDashboard(action.dashboard)
          return dashboards
            ? new List([...dashboards].concat(newDashboard))
            : new List(newDashboard)
        })
        .set('loading', false)
    }

    case COPY_CUSTOMER_DASHBOARD_WIDGET:
    case CREATE_CUSTOMER_DASHBOARD_WIDGET: {
      return state.updateIn(['widgets', action.customerCode], (widgets) => {
        const newWidget = mapWidget(action)
        return widgets?.size > 0
          ? new List([...widgets].concat(newWidget))
          : new List([newWidget])
      })
    }

    case COPY_CUSTOMER_DASHBOARD_WIDGET_SUCCESS:
    case CREATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS: {
      const { temporaryWidgetId, widget, customerCode } = action
      const indexOfUpdatedWidget = state
        .getIn(['widgets', customerCode])
        .findIndex((widget) => widget.id === temporaryWidgetId)

      return state.setIn(
        ['widgets', customerCode, indexOfUpdatedWidget, 'id'],
        widget?.id?.toString()
      )
    }

    case DELETE_CUSTOMER_DASHBOARD_SUCCESS: {
      const dashboards = state
        .getIn(['dashboards', action.customerCode])
        .filter((dashboard) => dashboard.id !== action.id)
      return state
        .setIn(['dashboards', action.customerCode], dashboards)
        .set('loading', false)
    }

    case DELETE_CUSTOMER_DASHBOARD_WIDGET: {
      return state.updateIn(['widgets', action.customerCode], (widgets) =>
        widgets?.filter((widget) => widget.id !== action.widgetId)
      )
    }

    case GET_CUSTOMER_DASHBOARDS_SUCCESS: {
      const { customerCode, dashboards } = action
      const newDashboards = new List(
        action.dashboards?.map((dashboard) => mapDashboard(dashboard))
      )

      const newWidgets = new List(
        dashboards?.reduce(
          (widgets, current) => [
            ...widgets,
            ...current.widgets.map((widget) =>
              mapWidget({ widget, dashboardId: current.id })
            ),
          ],
          []
        )
      )

      return state
        .setIn(['dashboards', customerCode], newDashboards)
        .setIn(['widgets', customerCode], newWidgets)
        .set('loading', false)
    }

    case UPDATE_CUSTOMER_DASHBOARD_SUCCESS: {
      const {
        customerCode,
        dashboard,
        dashboard: { id },
      } = action
      const indexOfDashboard = state
        .getIn(['dashboards', customerCode])
        .findIndex((dashboard) => dashboard.id === id)

      return state
        .setIn(
          ['dashboards', customerCode, indexOfDashboard],
          mapDashboard(dashboard)
        )
        .set('loading', false)
    }

    case UPDATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS: {
      const {
        customerCode,
        dashboardId,
        widget,
        widget: { id },
      } = action
      const index = state
        .getIn(['widgets', customerCode])
        .findIndex((item) => item.id === id)

      return state.setIn(
        ['widgets', customerCode, index],
        mapWidget({ dashboardId, widget })
      )
    }

    case UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS: {
      const index = state
        .getIn(['widgets', action.customerCode])
        .findIndex((widget) => widget.id === action.widgetId)

      if (index === -1) {
        return state
      }

      return state.updateIn(
        ['widgets', action.customerCode, index, 'parameters'],
        (parameters) => parameters.merge(action.parameters)
      )
    }

    case UPDATE_CUSTOMER_DASHBOARD_WIDGET:
    case DELETE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS:
    case UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_SUCCESS:
    default:
      return state
  }
}

export default dashboardsReducer
