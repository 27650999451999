/*
 *
 * AccountSchemeTemplates constants
 *
 */

export const CREATE_ACCOUNT_SCHEME_TEMPLATE =
  'app/AccountSchemeTemplates/CREATE_ACCOUNT_SCHEME_TEMPLATES'
export const CREATE_ACCOUNT_SCHEME_TEMPLATE_ERROR =
  'app/AccountSchemeTemplates/CREATE_ACCOUNT_SCHEME_TEMPLATES_ERROR'
export const CREATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS =
  'app/AccountSchemeTemplates/CREATE_ACCOUNT_SCHEME_TEMPLATES_SUCCESS'

export const COPY_ACCOUNT_SCHEME_TEMPLATE =
  'app/AccountSchemeTemplates/COPY_ACCOUNT_SCHEME_TEMPLATE'
export const COPY_ACCOUNT_SCHEME_TEMPLATE_ERROR =
  'app/AccountSchemeTemplates/COPY_ACCOUNT_SCHEME_TEMPLATE_ERROR'
export const COPY_ACCOUNT_SCHEME_TEMPLATE_SUCCESS =
  'app/AccountSchemeTemplates/COPY_ACCOUNT_SCHEME_TEMPLATE_SUCCESS'

export const DELETE_ACCOUNT_SCHEME_TEMPLATE =
  'app/AccountSchemeTemplates/DELETE_ACCOUNT_SCHEME_TEMPLATES'
export const DELETE_ACCOUNT_SCHEME_TEMPLATE_ERROR =
  'app/AccountSchemeTemplates/DELETE_ACCOUNT_SCHEME_TEMPLATES_ERROR'
export const DELETE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS =
  'app/AccountSchemeTemplates/DELETE_ACCOUNT_SCHEME_TEMPLATES_SUCCESS'

export const EDIT_ACCOUNT_SCHEME_TEMPLATE =
  'app/AccountSchemeTemplates/EDIT_ACCOUNT_SCHEME_TEMPLATES'

export const GET_ACCOUNT_SCHEME_TEMPLATES =
  'app/AccountSchemeTemplates/GET_ACCOUNT_SCHEME_TEMPLATES'
export const GET_ACCOUNT_SCHEME_TEMPLATES_ERROR =
  'app/AccountSchemeTemplates/GET_ACCOUNT_SCHEME_TEMPLATES_ERROR'
export const GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS =
  'app/AccountSchemeTemplates/GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS'

export const TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM =
  'app/AccountSchemeTemplates/TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM'

export const UPDATE_ACCOUNT_SCHEME_TEMPLATE =
  'app/AccountSchemeTemplates/UPDATE_ACCOUNT_SCHEME_TEMPLATES'
export const UPDATE_ACCOUNT_SCHEME_TEMPLATE_ERROR =
  'app/AccountSchemeTemplates/UPDATE_ACCOUNT_SCHEME_TEMPLATES_ERROR'
export const UPDATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS =
  'app/AccountSchemeTemplates/UPDATE_ACCOUNT_SCHEME_TEMPLATES_SUCCESS'
