/* eslint-disable no-undef */

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

/* eslint-enable import/no-unresolved, import/no-webpack-loader-syntax, import/extensions */

import './utils/dev-tools-in-cypress'

// Import all the third party stuff
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import LanguageProvider from 'containers/LanguageProvider'
import { HelmetProvider } from 'react-helmet-async'
import configureStore from './store'
import { apiConfig } from 'api/api-utils'

import * as serviceWorker from './serviceWorker'

// Import i18n messages
import { translationMessages } from './i18n'

// Import the CSS reset, which HtmlWebpackPlugin transfers to the build folder
import 'sanitize.css/sanitize.css'

// TODO: REFACTOR STYLES TO CONTAINERS OR COMPONENTS
import './styles/app.scss'
import 'draft-js/dist/Draft.css'

// Set up the router, wrapping all Routes in the App component
import FinazillaRouter from 'finazillaRouter'

import Hotjar from '@hotjar/browser'

const siteId = process.env.REACT_APP_HOTJAR_SITE_ID
const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION

if (siteId && hotjarVersion) {
  Hotjar.init(siteId, hotjarVersion)
}

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();
const initialState = {}
const store = configureStore(initialState)

window.env = apiConfig

const render = (translatedMessages) => {
  const container = document.getElementById('app')
  const root = createRoot(container)
  const reduxProvider = (
    <Provider store={store}>
      <HelmetProvider>
        <LanguageProvider messages={translatedMessages}>
          <FinazillaRouter translatedMessages={translatedMessages} />
        </LanguageProvider>
      </HelmetProvider>
    </Provider>
  )

  root.render(reduxProvider)
}

// Hot reloadable translation json files
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept('./i18n', () => {
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/de.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err
    })
} else {
  render(translationMessages)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

//Expose store when run in cypress
if (window.Cypress) {
  window.store = store
}
