/*
 *
 * Customers constants
 *
 */

export const GET_CUSTOMERS = 'app/Customers/GET_CUSTOMERS'
export const GET_CUSTOMERS_SUCCESS = 'app/Customers/GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_ERROR = 'app/Customers/GET_CUSTOMERS_ERROR'

export const CREATE_CUSTOMER = 'app/Customers/CREATE_CUSTOMER'
export const CREATE_CUSTOMER_ERROR = 'app/Customers/CREATE_CUSTOMER_ERROR'
export const CREATE_CUSTOMER_SUCCESS = 'app/Customers/CREATE_CUSTOMER_SUCCESS'

export const DELETE_CUSTOMER = 'app/Customers/DELETE_CUSTOMER'
export const DELETE_CUSTOMER_ERROR = 'app/Customers/DELETE_CUSTOMER_ERROR'
export const DELETE_CUSTOMER_SUCCESS = 'app/Customers/DELETE_CUSTOMER_SUCCESS'

export const TOGGLE_SHOW_CREATE_CUSTOMER_FORM =
  'app/Customers/TOGGLE_SHOW_CREATE_CUSTOMER_FORM'

export const SET_CUSTOMERS_LOADING = 'app/Customers/SET_CUSTOMERS_LOADING'
