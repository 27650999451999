/*
 *
 * BudgetBalanceImportModal actions
 *
 */

import {
  IMPORT_BUDGET_BALANCES,
  IMPORT_BUDGET_BALANCES_ERROR,
  IMPORT_BUDGET_BALANCES_SUCCESS,
  PARSE_BUDGET_BALANCES,
  PARSE_BUDGET_BALANCES_ERROR,
  PARSE_BUDGET_BALANCES_SUCCESS,
  RESET_IMPORT_BUDGET_BALANCE_MODAL,
} from './constants'

export const importBudgetBalances = ({
  budgetId,
  companyCode,
  validBudgetBalances,
}) => ({
  type: IMPORT_BUDGET_BALANCES,
  budgetId,
  companyCode,
  validBudgetBalances,
})
export const importBudgetBalancesError = (error) => ({
  type: IMPORT_BUDGET_BALANCES_ERROR,
  error,
})
export const importBudgetBalancesSuccess = ({ importResponse }) => ({
  type: IMPORT_BUDGET_BALANCES_SUCCESS,
  importResponse,
})

export const parseBudgetBalances = ({
  accountNumbers,
  allowLetters,
  endDate,
  file,
  max,
  min,
  startDate,
}) => ({
  type: PARSE_BUDGET_BALANCES,
  accountNumbers,
  allowLetters,
  endDate,
  file,
  max,
  min,
  startDate,
})
export const parseBudgetBalancesError = (error) => ({
  type: PARSE_BUDGET_BALANCES_ERROR,
  error,
})
export const parseBudgetBalancesSuccess = ({
  parsedBudgetBalances,
  parsedFile,
}) => ({
  type: PARSE_BUDGET_BALANCES_SUCCESS,
  parsedBudgetBalances,
  parsedFile,
})

export const resetImportBudgetBalanceModal = () => ({
  type: RESET_IMPORT_BUDGET_BALANCE_MODAL,
})
