import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import contentsApi from 'api/ContentApi'

import {
  getContentsError,
  getContentsSuccess,
  createContentError,
  createContentSuccess,
  updateContentError,
  updateContentSuccess,
  updateBudgetContentSuccess,
  updateSubBudgetContentSuccess,
  deleteContentError,
  deleteContentSuccess,
} from './actions'
import {
  GET_CONTENTS,
  CREATE_CONTENT,
  DELETE_BUDGET_CONTENT,
  DELETE_SUBBUDGET_CONTENT,
  DELETE_CONTENT,
  UPDATE_CONTENT,
  UPDATE_BUDGET_CONTENT,
  UPDATE_SUBBUDGET_CONTENT,
} from './constants'

export function* getContents(action) {
  const { contentType, targetId } = action
  try {
    const contents = yield call(contentsApi.getContents, {
      type: contentType,
      targetId,
    })
    yield put(getContentsSuccess({ contents, contentType, targetId }))
  } catch (error) {
    yield put(
      getContentsError({
        error,
        targetId,
        contentType,
      })
    )
  }
}

export function* deleteBudgetContent(action) {
  const {
    contentId,
    targetId,
    contentType,
    parentTargetId,
    companyCode,
  } = action
  try {
    yield call(contentsApi.deleteBudgetContent, {
      id: contentId,
      companyCode,
    })
    yield put(
      deleteContentSuccess({ contentId, targetId, contentType, parentTargetId })
    )
  } catch (error) {
    yield put(
      deleteContentError({
        error,
        contentId,
        targetId,
        contentType,
        parentTargetId,
      })
    )
  }
}

export function* deleteSubBudgetContent(action) {
  const {
    contentId,
    targetId,
    contentType,
    parentTargetId,
    companyCode,
  } = action
  try {
    yield call(contentsApi.deleteSubBudgetContent, {
      id: contentId,
      companyCode,
    })
    yield put(
      deleteContentSuccess({ contentId, targetId, contentType, parentTargetId })
    )
  } catch (error) {
    yield put(
      deleteContentError({
        error,
        contentId,
        targetId,
        contentType,
        parentTargetId,
      })
    )
  }
}

export function* deleteContent(action) {
  const { contentId, targetId, contentType, parentTargetId } = action
  try {
    yield call(contentsApi.deleteContent, {
      id: contentId,
    })
    yield put(
      deleteContentSuccess({ contentId, targetId, contentType, parentTargetId })
    )
  } catch (error) {
    yield put(
      deleteContentError({
        error,
        contentId,
        targetId,
        contentType,
        parentTargetId,
      })
    )
  }
}

export function* createContent(action) {
  const {
    contentType,
    targetId,
    data,
    temporaryContentId,
    parentTargetId,
    dimensionValueIds,
  } = action
  try {
    const createdContent = yield call(contentsApi.createContent, {
      type: contentType,
      targetId,
      data: JSON.stringify(data, null, 4),
      dimensionValueIds,
    })
    yield put(
      createContentSuccess({
        content: createdContent,
        contentType,
        targetId,
        temporaryContentId,
        parentTargetId,
      })
    )
  } catch (error) {
    yield put(
      createContentError({
        contentType,
        error,
        targetId,
        temporaryContentId,
        parentTargetId,
      })
    )
  }
}

export function* updateContent(action) {
  const { contentId, data, contentType, targetId } = action

  try {
    yield call(contentsApi.updateContent, {
      id: contentId,
      data: JSON.stringify(data, null, 4),
    })
    yield put(updateContentSuccess({ contentId, data, contentType, targetId }))
  } catch (error) {
    yield put(
      updateContentError({
        error,
        contentId,
        contentType,
        targetId,
      })
    )
  }
}

export function* updateBudgetContent(action) {
  const { contentId, data, contentType, targetId, companyCode } = action

  try {
    const updatedContent = yield call(contentsApi.updateBudgetContent, {
      id: contentId,
      data: JSON.stringify(data, null, 4),
      companyCode,
    })
    yield put(
      updateBudgetContentSuccess({
        contentId,
        data,
        contentType,
        targetId,
        content: updatedContent,
      })
    )
  } catch (error) {
    yield put(
      updateContentError({
        error,
        contentId,
        contentType,
        targetId,
      })
    )
  }
}

export function* updateSubBudgetContent(action) {
  const { contentId, data, contentType, targetId, companyCode } = action

  try {
    const updatedContent = yield call(contentsApi.updateSubBudgetContent, {
      id: contentId,
      data: JSON.stringify(data, null, 4),
      companyCode,
    })
    yield put(
      updateSubBudgetContentSuccess({
        contentId,
        data,
        contentType,
        targetId,
        content: updatedContent,
      })
    )
  } catch (error) {
    yield put(
      updateContentError({
        error,
        contentId,
        contentType,
        targetId,
      })
    )
  }
}

export function* contentSagas() {
  yield all([
    takeEvery(GET_CONTENTS, getContents),
    takeLatest(CREATE_CONTENT, createContent),
    takeLatest(UPDATE_CONTENT, updateContent),
    takeLatest(DELETE_CONTENT, deleteContent),
    takeLatest(DELETE_BUDGET_CONTENT, deleteBudgetContent),
    takeLatest(DELETE_SUBBUDGET_CONTENT, deleteSubBudgetContent),
    takeLatest(UPDATE_BUDGET_CONTENT, updateBudgetContent),
    takeLatest(UPDATE_SUBBUDGET_CONTENT, updateSubBudgetContent),
  ])
}

export default contentSagas
