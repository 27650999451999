import { Record, List, Map } from 'immutable'

const DimensionRecord = Record({
  id: undefined,
  code: undefined,
  displayName: undefined,
  name: undefined,
  externalId: undefined,
  parent: undefined,
  parentId: undefined,
  level: 0,
  hideFromBudgeting: undefined,
  hideFromReporting: undefined,
  dimensionType: undefined,
  type: 'dimension',
  // hierarchy of DimensionValues. Might be incomplete list
  values: List(),
  // Total number of values in dimension. Complete list
  valueCount: undefined,
  // lookup for searching DimensionValues by id,
  // should include all values in dimension's hierarchy
  dimensionValuesById: Map(),

  // true for Dimensions that can be used as root
  // Dimension for matrix selection
  providesMatrixSelection: undefined,
  // Combination of code, name and displayname depending on dimension settings and what of the previous values is defined
  nameToDisplay: undefined,
  // Setting that defines how nameToDisplay is generated. For both this dimension and it's values
  useGeneratedDisplayName: false,
})

export default DimensionRecord
