/*
 *
 * Voucher actions
 *
 */

import {
  GET_VOUCHER,
  GET_VOUCHER_ERROR,
  GET_VOUCHER_SUCCESS,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_ERROR,
  UPDATE_VOUCHER_SUCCESS,
} from './constants'

export const getVoucher = ({ companyCode, id }) => ({
  type: GET_VOUCHER,
  companyCode,
  id,
})
export const getVoucherError = (error) => ({
  type: GET_VOUCHER_ERROR,
  error,
})
export const getVoucherSuccess = ({ voucher }) => ({
  type: GET_VOUCHER_SUCCESS,
  voucher,
})
export const updateVoucher = ({ companyCode, voucher }) => ({
  type: UPDATE_VOUCHER,
  companyCode,
  voucher,
})
export const updateVoucherError = (error) => ({
  type: UPDATE_VOUCHER_ERROR,
  error,
})
export const updateVoucherSuccess = ({ voucher }) => ({
  type: UPDATE_VOUCHER_SUCCESS,
  voucher,
})
