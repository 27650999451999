import {
  EXECUTE_INTEGRATION,
  EXECUTE_INTEGRATION_SUCCESS,
  EXECUTE_INTEGRATION_ERROR,
  GET_INTEGRATION_EVENTS,
  GET_INTEGRATION_EVENTS_SUCCESS,
  GET_INTEGRATION_EVENTS_ERROR,
  GET_INTEGRATION_CONNECTIONS,
  GET_INTEGRATION_CONNECTIONS_SUCCESS,
  GET_INTEGRATION_CONNECTIONS_ERROR,
  DELETE_INTEGRATION_CONNECTION,
  DELETE_INTEGRATION_CONNECTION_SUCCESS,
  DELETE_INTEGRATION_CONNECTION_ERROR,
  PUT_INTEGRATION_CONNECTION,
  PUT_INTEGRATION_CONNECTION_SUCCESS,
  PUT_INTEGRATION_CONNECTION_ERROR,
  CREATE_INTEGRATION_CONNECTION,
  CREATE_INTEGRATION_CONNECTION_ERROR,
} from './constants'

export const executeCompanyIntegration = ({
  companyCode,
  customerCode,
  startDate,
  endDate,
  materialTypes,
  integrationCode,
  modifiedLogicApplied,
}) => ({
  type: EXECUTE_INTEGRATION,
  companyCode,
  customerCode,
  startDate,
  endDate,
  materialTypes,
  integrationCode,
  modifiedLogicApplied,
})

export const executeCompanyIntegrationSuccess = (companyCode) => ({
  type: EXECUTE_INTEGRATION_SUCCESS,
  companyCode,
})

export const executeCompanyIntegrationError = (error) => ({
  type: EXECUTE_INTEGRATION_ERROR,
  error,
})

export const getIntegrationEvents = ({ companyCode, limit }) => ({
  type: GET_INTEGRATION_EVENTS,
  companyCode,
  limit,
})

export const getIntegrationEventsSuccess = (eventList) => ({
  type: GET_INTEGRATION_EVENTS_SUCCESS,
  eventList,
})

export const getIntegrationEventsError = (error) => ({
  type: GET_INTEGRATION_EVENTS_ERROR,
  error,
})

export const getIntegrationConnections = ({ companyCode }) => ({
  type: GET_INTEGRATION_CONNECTIONS,
  companyCode,
})

export const getIntegrationConnectionsSuccess = ({
  companyCode,
  connections,
}) => ({
  type: GET_INTEGRATION_CONNECTIONS_SUCCESS,
  companyCode,
  connections,
})

export const getIntegrationConnectionsError = (error) => ({
  type: GET_INTEGRATION_CONNECTIONS_ERROR,
  error,
})

export const createIntegrationConnection = ({
  companyCode,
  integrationCode,
  connection,
}) => ({
  type: CREATE_INTEGRATION_CONNECTION,
  companyCode,
  integrationCode,
  connection,
})
export const createIntegrationConnectionError = ({
  companyCode,
  integrationCode,
  ...error
}) => ({
  type: CREATE_INTEGRATION_CONNECTION_ERROR,
  companyCode,
  integrationCode,
  error,
})

export const putIntegrationConnection = ({
  companyCode,
  integrationCode,
  connection,
}) => ({
  type: PUT_INTEGRATION_CONNECTION,
  companyCode,
  integrationCode,
  connection,
})

export const putIntegrationConnectionSuccess = ({
  companyCode,
  integrationCode,
  connection,
}) => ({
  type: PUT_INTEGRATION_CONNECTION_SUCCESS,
  companyCode,
  integrationCode,
  connection,
})

export const putIntegrationConnectionError = ({
  integrationCode,
  ...error
}) => ({
  type: PUT_INTEGRATION_CONNECTION_ERROR,
  integrationCode,
  error,
})

export const deleteIntegrationConnection = ({
  companyCode,
  integrationCode,
}) => ({
  type: DELETE_INTEGRATION_CONNECTION,
  companyCode,
  integrationCode,
})

export const deleteIntegrationConnectionSuccess = ({
  companyCode,
  integrationCode,
}) => ({
  type: DELETE_INTEGRATION_CONNECTION_SUCCESS,
  companyCode,
  integrationCode,
})

export const deleteIntegrationConnectionError = (error) => ({
  type: DELETE_INTEGRATION_CONNECTION_ERROR,
  error,
})
