/*
 *
 * Report reducer
 *
 */
import { fromJS } from 'immutable'
import {
  CREATE_REPORT_SELECTION_ERROR,
  UPDATE_REPORT_SELECTION,
  UPDATE_REPORT_SELECTION_ERROR,
  UPDATE_REPORT_SELECTION_SUCCESS,
} from 'containers/Reports/constants'
import {
  OPEN_REPORT_WITHOUT_SAVING,
  OPEN_REPORT_WITHOUT_SAVING_ERROR,
  OPEN_REPORT_WITHOUT_SAVING_SUCCESS,
  SAVE_REPORT,
  SAVE_REPORT_SELECTION_ERROR,
  SAVE_REPORT_SELECTION_SUCCESS,
  GET_REPORT_PROGRESS_PREVIEW_SUCCESS,
  GET_REPORT_DATA_PREVIEW_SUCCESS,
  GET_REPORT_DATA_PREVIEW,
  CLEAR_REPORT_DATA_PREVIEW,
  GET_REPORT_DATA_PREVIEW_ERROR,
  CLEAR_REPORT_DATA_PREVIEW_ERROR,
} from './constants'

const initialState = fromJS({
  error: false,
  loading: false,
  reportDataForPreview: undefined,
  reportDataForPreviewError: undefined,
  reportProgressForPreview: undefined,
})

function reportReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_REPORT_WITHOUT_SAVING_ERROR:
    case UPDATE_REPORT_SELECTION_ERROR:
    case CREATE_REPORT_SELECTION_ERROR:
    case SAVE_REPORT_SELECTION_ERROR: {
      return state.set('loading', false).set('error', action.error)
    }
    case GET_REPORT_DATA_PREVIEW_ERROR: {
      return state
        .set('loading', false)
        .set('reportDataForPreviewError', action.error)
    }
    case CLEAR_REPORT_DATA_PREVIEW_ERROR: {
      return state.set('reportDataForPreviewError', undefined)
    }
    case CLEAR_REPORT_DATA_PREVIEW: {
      return state.set('reportDataForPreview', undefined)
    }
    case UPDATE_REPORT_SELECTION_SUCCESS:
    case SAVE_REPORT_SELECTION_SUCCESS: {
      // const reportSelectionForState = new ReportRecord(action.reportSelection);
      const returnValue = state
        .set('loading', false)
        .set('reportSelection', undefined)
      return returnValue
    }
    case OPEN_REPORT_WITHOUT_SAVING: {
      const returnValue = state
        .set('loading', true)
        .set('reportDataForPreview', undefined)
        .set('reportProgressForPreview', undefined)
      return returnValue
    }

    case GET_REPORT_DATA_PREVIEW_SUCCESS:
    case OPEN_REPORT_WITHOUT_SAVING_SUCCESS:
      return state
        .set('loading', false)
        .set('reportDataForPreview', action.report)
        .set('error', false)
        .set('reportProgressForPreview', undefined)
    case GET_REPORT_DATA_PREVIEW:
    case UPDATE_REPORT_SELECTION:
    case SAVE_REPORT:
      return state.set('loading', action.setLoading).set('error', false)
    case GET_REPORT_PROGRESS_PREVIEW_SUCCESS:
      return state.set('reportProgressForPreview', action.progress)
    default:
      return state
  }
}

export default reportReducer
