/*
 *
 * AccountScheme actions
 *
 */

import {
  ADD_ACCOUNT_SCHEME_ROW,
  ADD_ACCOUNT_SCHEME_ROW_ERROR,
  ADD_ACCOUNT_SCHEME_ROW_SUCCESS,
  ADD_ACCOUNT_SCHEME_ROW_GROUP,
  ADD_ACCOUNT_SCHEME_ROW_GROUP_ERROR,
  ADD_ACCOUNT_SCHEME_ROW_GROUP_SUCCESS,
  ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP,
  DELETE_ACCOUNT_SCHEME_ROW,
  DELETE_ACCOUNT_SCHEME_ROW_ERROR,
  DELETE_ACCOUNT_SCHEME_ROW_SUCCESS,
  GET_ACCOUNT_SCHEME,
  GET_ACCOUNT_SCHEME_ERROR,
  GET_ACCOUNT_SCHEME_SUCCESS,
  GET_ACCOUNT_SCHEME_AND_TREE,
  GET_ACCOUNT_SCHEME_AND_TREE_ERROR,
  GET_ACCOUNT_SCHEME_AND_TREE_SUCCESS,
  GET_ACCOUNT_SCHEME_TREE,
  GET_ACCOUNT_SCHEME_TREE_ERROR,
  GET_ACCOUNT_SCHEME_TREE_SUCCESS,
  MOVE_ACCOUNT_SCHEME_ROW,
  MOVE_ACCOUNT_SCHEME_ROW_ERROR,
  MOVE_ACCOUNT_SCHEME_ROW_SUCCESS,
  TOGGLE_ACCOUNT_SCHEME_SHOW_SETTINGS,
  SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR,
  SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS,
  SET_ACCOUNT_SCHEME_ACTIVE_ROW,
  UPDATE_ACCOUNT_SCHEME_ROW,
  UPDATE_ACCOUNT_SCHEME_ROW_ERROR,
  UPDATE_ACCOUNT_SCHEME_ROW_SUCCESS,
} from './constants'

export const addAccountSchemeRow = ({
  activeRow,
  parent,
  companyCode,
  id,
  idConcernAccount,
  row,
}) => ({
  type: ADD_ACCOUNT_SCHEME_ROW,
  parent,
  activeRow,
  companyCode,
  id,
  idConcernAccount,
  row,
})
export const addAccountSchemeRowError = (error) => ({
  type: ADD_ACCOUNT_SCHEME_ROW_ERROR,
  error,
})
export const addAccountSchemeRowSuccess = ({ tree }) => ({
  type: ADD_ACCOUNT_SCHEME_ROW_SUCCESS,
  tree,
})

export const addAccountSchemeRowGroup = ({
  activeRow,
  companyCode,
  id,
  rowGroup,
}) => ({
  type: ADD_ACCOUNT_SCHEME_ROW_GROUP,
  activeRow,
  companyCode,
  id,
  rowGroup,
})
export const addAccountSchemeRowChildGroup = ({
  activeRow,
  companyCode,
  id,
  rowGroup,
}) => ({
  type: ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP,
  activeRow,
  companyCode,
  id,
  rowGroup,
})
export const addAccountSchemeRowGroupError = (error) => ({
  type: ADD_ACCOUNT_SCHEME_ROW_GROUP_ERROR,
  error,
})
export const addAccountSchemeRowGroupSuccess = ({ tree }) => ({
  type: ADD_ACCOUNT_SCHEME_ROW_GROUP_SUCCESS,
  tree,
})

export const deleteAccountSchemeRow = ({ companyCode, id, path }) => ({
  type: DELETE_ACCOUNT_SCHEME_ROW,
  companyCode,
  id,
  path,
})
export const deleteAccountSchemeRowError = (error) => ({
  type: DELETE_ACCOUNT_SCHEME_ROW_ERROR,
  error,
})
export const deleteAccountSchemeRowSuccess = ({ tree }) => ({
  type: DELETE_ACCOUNT_SCHEME_ROW_SUCCESS,
  tree,
})

export const getAccountScheme = ({ companyCode, id }) => ({
  type: GET_ACCOUNT_SCHEME,
  companyCode,
  id,
})
export const getAccountSchemeError = (error) => ({
  type: GET_ACCOUNT_SCHEME_ERROR,
  error,
})
export const getAccountSchemeSuccess = ({ accountScheme }) => ({
  type: GET_ACCOUNT_SCHEME_SUCCESS,
  accountScheme,
})

export const getAccountSchemeAndTree = ({ companyCode, id }) => ({
  type: GET_ACCOUNT_SCHEME_AND_TREE,
  companyCode,
  id,
})
export const getAccountSchemeAndTreeError = (error) => ({
  type: GET_ACCOUNT_SCHEME_AND_TREE_ERROR,
  error,
})
export const getAccountSchemeAndTreeSuccess = ({ accountScheme, tree }) => ({
  type: GET_ACCOUNT_SCHEME_AND_TREE_SUCCESS,
  accountScheme,
  tree,
})

export const getAccountSchemeTree = ({ companyCode, id }) => ({
  type: GET_ACCOUNT_SCHEME_TREE,
  companyCode,
  id,
})
export const getAccountSchemeTreeError = (error) => ({
  type: GET_ACCOUNT_SCHEME_TREE_ERROR,
  error,
})
export const getAccountSchemeTreeSuccess = ({ tree }) => ({
  type: GET_ACCOUNT_SCHEME_TREE_SUCCESS,
  tree,
})

export const moveAccountSchemeRow = ({
  companyCode,
  id,
  source,
  target,
  moveAfter,
}) => ({
  type: MOVE_ACCOUNT_SCHEME_ROW,
  companyCode,
  id,
  source,
  target,
  moveAfter,
})
export const moveAccountSchemeRowError = (error) => ({
  type: MOVE_ACCOUNT_SCHEME_ROW_ERROR,
  error,
})
export const moveAccountSchemeRowSuccess = ({ tree }) => ({
  type: MOVE_ACCOUNT_SCHEME_ROW_SUCCESS,
  tree,
})

export const setAccountSchemeActiveRow = ({ row }) => ({
  type: SET_ACCOUNT_SCHEME_ACTIVE_ROW,
  row,
})

export const setAccountSchemeSpecialAccount = ({
  accountNumber,
  companyCode,
  id,
  specialAccount,
}) => ({
  type: SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  accountNumber,
  companyCode,
  id,
  specialAccount,
})
export const setAccountSchemeSpecialAccountError = (error) => ({
  type: SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR,
  error,
})
export const setAccountSchemeSpecialAccountSuccess = ({ accountScheme }) => ({
  type: SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS,
  accountScheme,
})

export const toggleAccountSchemeShowSettings = () => ({
  type: TOGGLE_ACCOUNT_SCHEME_SHOW_SETTINGS,
})

export const updateAccountSchemeRow = ({
  activeRow,
  companyCode,
  id,
  row,
}) => ({
  type: UPDATE_ACCOUNT_SCHEME_ROW,
  activeRow,
  companyCode,
  id,
  row,
})

export const updateAccountSchemeRowSuccess = ({ tree }) => ({
  type: UPDATE_ACCOUNT_SCHEME_ROW_SUCCESS,
  tree,
})

export const updateAccountSchemeRowError = (error) => ({
  type: UPDATE_ACCOUNT_SCHEME_ROW_ERROR,
  error,
})
