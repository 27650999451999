import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const deleteOperativeDatas = ({ companyCode }) =>
  axios
    .delete(`${companyCode}/operativedata`, config())
    .then(returnData)
    .catch(catchError)

const getOperativeItems = ({ operativeItemIds }) => {
  const url = `operativedata/list`
  return axios
    .post(url, operativeItemIds, config())
    .then(returnData)
    .catch(catchError)
}

const operativeDataApi = {
  deleteOperativeDatas,
  getOperativeItems,
}
export default operativeDataApi
