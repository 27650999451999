import { all, call, put, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import budgetBalanceApi from 'api/BudgetBalanceApi'

import {
  GET_BUDGET_BALANCES_PREVIEW,
  IMPORT_BUDGET_BALANCES,
} from './constants'
import { clearBudgetData } from 'containers/Budget/actions'
import {
  getBudgetBalancesPreviewSuccess,
  importBudgetBalancesError,
  importBudgetBalancesSuccess,
} from './actions'
import { eventChannel } from 'redux-saga'
import { tryParseErrorJson } from 'utils/parseError'
import { END } from 'redux-saga'
import { take } from 'redux-saga/effects'

export function* importBudgetBalance(action) {
  const { importMode, ...parameters } = action
  try {
    const progressChannel = eventChannel((eventEmmit) => {
      const onMessage = (event) => {
        try {
          eventEmmit(importBudgetBalancesSuccess())
          eventEmmit(clearBudgetData())
          eventEmmit(END)
        } catch (error) {
          eventEmmit(handleError(error, importBudgetBalancesError))
          eventEmmit(END)
        }
      }

      const onProgress = (event) => {
        console.log(event.data)
      }

      const onError = (event) => {
        console.error('Error in importBudgetBalance', event)
        eventEmmit(
          handleError(
            { data: tryParseErrorJson(event.data) },
            importBudgetBalancesError
          )
        )
        eventEmmit(END)
      }

      if (importMode === 'singleDimensionTarget') {
        budgetBalanceApi.importBudgetBalance({
          ...parameters,
          onMessage,
          onProgress,
          onError,
        })
      } else {
        budgetBalanceApi.importBudgetBalanceAllDimensions({
          ...parameters,
          onMessage,
          onProgress,
          onError,
        })
      }

      return () => {}
    })

    while (true) {
      const event = yield take(progressChannel)
      if (event === END) break
      yield put(event)
    }
  } catch (error) {
    yield put(clearBudgetData())
    yield put(handleError(error, importBudgetBalancesError))
  }
}

export function* getBudgetBalancesPreview(action) {
  const { importMode, ...parameters } = action
  try {
    let data
    if (importMode === 'singleDimensionTarget') {
      data = yield call(budgetBalanceApi.getBudgetBalancesPreview, parameters)
    } else {
      data = yield call(
        budgetBalanceApi.getBudgetBalancesPreviewAllDimensions,
        parameters
      )
    }
    yield put(getBudgetBalancesPreviewSuccess({ data }))
  } catch (error) {
    yield put(handleError(error, importBudgetBalancesError))
  }
}

// All sagas to be loaded
export function* budgetSaga() {
  yield all([
    takeLatest(IMPORT_BUDGET_BALANCES, importBudgetBalance),
    takeLatest(GET_BUDGET_BALANCES_PREVIEW, getBudgetBalancesPreview),
  ])
}

export default budgetSaga
