import { fromJS, Map } from 'immutable'

import UserRecord from 'records/user'
import {
  TOGGLE_ITEM_SELECTION,
  TOGGLE_BRANCH,
  GET_USERS_SUCCESS,
  SET_TREE_ITEM_STATUS,
  SET_INITIAL_USER_SELECTIONS,
  TREE_ITEM_TYPE_USER,
  ROOT_ITEM_ID,
  EXPAND_BRANCH,
  SET_ITEM_SELECTION,
} from './constants'
import { toggleProperty } from 'utils/immutable'

const initialState = fromJS({
  selectedItems: Map(),
  expandedBranches: Map(),
  users: Map(),
  selectedUsers: Map(),
  itemStatuses: Map(),
})

export const selectedItemsReducer = (selectedItems, action) => {
  if (action.type === TOGGLE_ITEM_SELECTION) {
    return toggleProperty(selectedItems, action.id, true)
  }

  if (action.type === SET_INITIAL_USER_SELECTIONS) {
    return Map(action.userIds.map((userId) => [userId, true]))
  }

  if (action.type === SET_ITEM_SELECTION) {
    return action.ids.reduce(
      (working, itemId) =>
        action.isSelected ? working.set(itemId, true) : working.delete(itemId),
      selectedItems
    )
  }

  return selectedItems
}

export const expandedBranchesReducer = (expandedBranches, action) => {
  if (action.type === TOGGLE_BRANCH) {
    return toggleProperty(expandedBranches, action.id, true)
  }

  if (action.type === EXPAND_BRANCH) {
    return expandedBranches.set(action.id, true)
  }

  if (action.type === SET_INITIAL_USER_SELECTIONS) {
    return Map()
  }

  return expandedBranches
}

export const usersReducer = (users, action) => {
  if (action.type === GET_USERS_SUCCESS) {
    const key = action.companyCode || action.customerCode || ROOT_ITEM_ID
    return users.set(
      key,
      action.users
        .filter((u) => u.id !== action.selfId)
        .map((u) => UserRecord(u))
    )
  }

  return users
}

export const selectedUsersReducer = (selectedUsers, action) => {
  if (action.type === TOGGLE_ITEM_SELECTION) {
    if (action.itemType !== TREE_ITEM_TYPE_USER) {
      return selectedUsers
    }

    return toggleProperty(selectedUsers, action.id, true)
  }

  if (action.type === SET_INITIAL_USER_SELECTIONS) {
    return Map(action.userIds.map((userId) => [userId, true]))
  }

  if (action.type === SET_ITEM_SELECTION) {
    return action.ids.reduce(
      (working, itemId) =>
        action.isSelected ? working.set(itemId, true) : working.delete(itemId),
      selectedUsers
    )
  }

  return selectedUsers
}

export const itemStatusesReducer = (itemStatuses, action) => {
  if (action.type === SET_TREE_ITEM_STATUS) {
    if (action.itemType === TREE_ITEM_TYPE_USER) {
      return itemStatuses
    }

    return itemStatuses.set(action.id, action.status)
  }

  if (action.type === SET_INITIAL_USER_SELECTIONS) {
    return Map()
  }

  return itemStatuses
}

export const shareReducer = (state = initialState, action) =>
  state
    .set(
      'selectedItems',
      selectedItemsReducer(state.get('selectedItems'), action)
    )
    .set(
      'selectedUsers',
      selectedUsersReducer(state.get('selectedUsers'), action)
    )
    .set(
      'expandedBranches',
      expandedBranchesReducer(state.get('expandedBranches'), action)
    )
    .set('itemStatuses', itemStatusesReducer(state.get('itemStatuses'), action))
    .set('users', usersReducer(state.get('users'), action))

export default shareReducer
