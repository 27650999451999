import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { injectIntl } from 'react-intl'

import messages from './messages'

import { parseSubBudgetScheme } from 'utils/file-parsers/subBudgetSchemeParser'
import FormField from 'components/FormField'

export const NAME_FIELD = 'Name'
export const DEFINED_FIELDS = [NAME_FIELD]

const SubBudgetSchemeImportForm = ({
  intl: { formatMessage },
  form,
  setForm,
}) => {
  const setRows = (result) => {
    const matrixDimensionNames = result.meta.fields.filter(
      (field) => !DEFINED_FIELDS.includes(field)
    )
    setForm({
      ...form,
      matrixes: result.data.map((row) => ({
        name: row.Name,
        dimensionValues: matrixDimensionNames.map((field) => row[field]),
      })),
      matrixDimensionNames,
    })
  }

  return (
    <>
      <Row>
        <hr />
        <Col md={12}>
          <input
            type="file"
            onChange={(e) =>
              parseSubBudgetScheme({
                file: e.target.files[0],
                complete: setRows,
              })
            }
          />
        </Col>
      </Row>
      <hr />
      {form.matrixes && (
        <>
          <Row>
            <Col>
              <b>{formatMessage(messages.name)}</b>
            </Col>
            {form.matrixDimensionNames.map((name) => (
              <Col>
                <b>{name}</b>
              </Col>
            ))}
          </Row>
          {form?.matrixes?.map((row, i) => (
            <Row key={'Scheme-Row-' + i}>
              <Col>
                <FormField name={`matrixes[${i}].name`} />
              </Col>
              {form.matrixDimensionNames.map((name, dimensionIndex) => (
                <Col>
                  <FormField
                    name={`matrixes[${i}].dimensionValues[${dimensionIndex}]`}
                    type="input"
                  />
                </Col>
              ))}
            </Row>
          ))}
        </>
      )}
    </>
  )
}

export default injectIntl(SubBudgetSchemeImportForm)
