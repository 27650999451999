import { Record } from 'immutable'

const IntegrationLogEntry = Record({
  companyCode: undefined,
  companyName: '',
  created: '',
  dataType: '',
  logItemType: '',
  message: '',
})

export default IntegrationLogEntry
