import {
  GET_RECURRING_PAYMENTS,
  GET_RECURRING_PAYMENTS_ERROR,
  GET_RECURRING_PAYMENTS_SUCCESS,
  DELETE_RECURRING_PAYMENT,
  DELETE_RECURRING_PAYMENT_ERROR,
  DELETE_RECURRING_PAYMENT_SUCCESS,
  UPDATE_RECURRING_PAYMENT,
  UPDATE_RECURRING_PAYMENT_ERROR,
  UPDATE_RECURRING_PAYMENT_SUCCESS,
  CREATE_RECURRING_PAYMENT,
  CREATE_RECURRING_PAYMENT_ERROR,
  CREATE_RECURRING_PAYMENT_SUCCESS,
  GET_RECURRING_PAYMENT_OCCURENCES,
  GET_RECURRING_PAYMENT_OCCURENCES_ERROR,
  GET_RECURRING_PAYMENT_OCCURENCES_SUCCESS,
  DELETE_RECURRING_PAYMENT_OCCURENCE,
  DELETE_RECURRING_PAYMENT_OCCURENCE_ERROR,
  DELETE_RECURRING_PAYMENT_OCCURENCE_SUCCESS,
  UPDATE_RECURRING_PAYMENT_OCCURENCE,
  UPDATE_RECURRING_PAYMENT_OCCURENCE_ERROR,
  UPDATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS,
  CREATE_RECURRING_PAYMENT_OCCURENCE,
  CREATE_RECURRING_PAYMENT_OCCURENCE_ERROR,
  CREATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS,
  GET_RECURRING_PAYMENT_PAYMENT_EVENTS,
  GET_RECURRING_PAYMENT_PAYMENT_EVENTS_ERROR,
  GET_RECURRING_PAYMENT_PAYMENT_EVENTS_SUCCESS,
} from './constants'

export const getRecurringPayments = ({ companyCode }) => ({
  type: GET_RECURRING_PAYMENTS,
  companyCode,
})
export const getRecurringPaymentsError = (error) => ({
  type: GET_RECURRING_PAYMENTS_ERROR,
  error,
})
export const getRecurringPaymentsSuccess = ({ recurringPayments }) => ({
  type: GET_RECURRING_PAYMENTS_SUCCESS,
  recurringPayments,
})

export const deleteRecurringPayment = ({
  recurringPaymentId,
  companyCode,
}) => ({
  type: DELETE_RECURRING_PAYMENT,
  recurringPaymentId,
  companyCode,
})
export const deleteRecurringPaymentError = (error) => ({
  type: DELETE_RECURRING_PAYMENT_ERROR,
  error,
})
export const deleteRecurringPaymentSuccess = ({ recurringPaymentId }) => ({
  type: DELETE_RECURRING_PAYMENT_SUCCESS,
  recurringPaymentId,
})

export const updateRecurringPayment = ({
  recurringPayment,
  updatedRecurringPayment,
  companyCode,
}) => ({
  type: UPDATE_RECURRING_PAYMENT,
  recurringPayment,
  updatedRecurringPayment,
  companyCode,
})
export const updateRecurringPaymentSuccess = ({ recurringPayment }) => ({
  type: UPDATE_RECURRING_PAYMENT_SUCCESS,
  recurringPayment,
})
export const updateRecurringPaymentError = () => ({
  type: UPDATE_RECURRING_PAYMENT_ERROR,
})

export const createRecurringPayment = ({
  recurringPayment,
  companyCode,
  redirectRoute,
  navigate,
}) => ({
  type: CREATE_RECURRING_PAYMENT,
  recurringPayment,
  companyCode,
  redirectRoute,
  navigate,
})
export const createRecurringPaymentSuccess = ({ recurringPayment }) => ({
  type: CREATE_RECURRING_PAYMENT_SUCCESS,
  recurringPayment,
})
export const createRecurringPaymentError = () => ({
  type: CREATE_RECURRING_PAYMENT_ERROR,
})

export const getRecurringPaymentOccurences = ({
  companyCode,
  recurringPaymentId,
}) => ({
  type: GET_RECURRING_PAYMENT_OCCURENCES,
  companyCode,
  recurringPaymentId,
})
export const getRecurringPaymentOccurencesError = (error) => ({
  type: GET_RECURRING_PAYMENT_OCCURENCES_ERROR,
  error,
})
export const getRecurringPaymentOccurencesSuccess = ({
  recurringPaymentOccurences,
  recurringPaymentId,
}) => ({
  type: GET_RECURRING_PAYMENT_OCCURENCES_SUCCESS,
  recurringPaymentOccurences,
  recurringPaymentId,
})

export const deleteRecurringPaymentOccurence = ({
  recurringPaymentOccurenceId,
  recurringPaymentId,
}) => ({
  type: DELETE_RECURRING_PAYMENT_OCCURENCE,
  recurringPaymentId,
  recurringPaymentOccurenceId,
})
export const deleteRecurringPaymentOccurenceError = (error) => ({
  type: DELETE_RECURRING_PAYMENT_OCCURENCE_ERROR,
  error,
})
export const deleteRecurringPaymentOccurenceSuccess = ({
  recurringPaymentOccurenceId,
  recurringPaymentId,
}) => ({
  type: DELETE_RECURRING_PAYMENT_OCCURENCE_SUCCESS,
  recurringPaymentOccurenceId,
  recurringPaymentId,
})

export const updateRecurringPaymentOccurence = ({
  recurringPaymentOccurence,
  updatedRecurringPaymentOccurence,
  companyCode,
}) => ({
  type: UPDATE_RECURRING_PAYMENT_OCCURENCE,
  recurringPaymentOccurence,
  updatedRecurringPaymentOccurence,
  companyCode,
})
export const updateRecurringPaymentOccurenceSuccess = ({
  recurringPaymentOccurence,
}) => ({
  type: UPDATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS,
  recurringPaymentOccurence,
})
export const updateRecurringPaymentOccurenceError = () => ({
  type: UPDATE_RECURRING_PAYMENT_OCCURENCE_ERROR,
})

export const createRecurringPaymentOccurence = ({
  recurringPaymentId,
  recurringPaymentOccurence,
  companyCode,
}) => ({
  type: CREATE_RECURRING_PAYMENT_OCCURENCE,
  recurringPaymentId,
  recurringPaymentOccurence,
  companyCode,
})
export const createRecurringPaymentOccurenceSuccess = ({
  recurringPaymentOccurence,
}) => ({
  type: CREATE_RECURRING_PAYMENT_OCCURENCE_SUCCESS,
  recurringPaymentOccurence,
})
export const createRecurringPaymentOccurenceError = () => ({
  type: CREATE_RECURRING_PAYMENT_OCCURENCE_ERROR,
})

export const getRecurringPaymentPaymentEvents = ({
  companyCode,
  redirectRoute,
  navigate,
}) => ({
  type: GET_RECURRING_PAYMENT_PAYMENT_EVENTS,
  companyCode,
  redirectRoute,
  navigate,
})
export const getRecurringPaymentPaymentEventsError = (error) => ({
  type: GET_RECURRING_PAYMENT_PAYMENT_EVENTS_ERROR,
  error,
})
export const getRecurringPaymentPaymentEventsSuccess = ({
  recurringPaymentPaymentEvents,
}) => ({
  type: GET_RECURRING_PAYMENT_PAYMENT_EVENTS_SUCCESS,
  recurringPaymentPaymentEvents,
})
