/*
 *
 * Companies constants
 *
 */

export const GET_COMPANIES = 'app/Companies/GET_COMPANIES'
export const GET_COMPANIES_SUCCESS = 'app/Companies/GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_ERROR = 'app/Companies/GET_COMPANIES_ERROR'

export const CREATE_COMPANY = 'app/Companies/CREATE_COMPANY'
export const CREATE_COMPANY_ERROR = 'app/Companies/CREATE_COMPANY_ERROR'
export const CREATE_COMPANY_SUCCESS = 'app/Companies/CREATE_COMPANY_SUCCESS'

export const DELETE_COMPANY = 'app/Companies/DELETE_COMPANY'
export const DELETE_COMPANY_ERROR = 'app/Companies/DELETE_COMPANY_ERROR'
export const DELETE_COMPANY_SUCCESS = 'app/Companies/DELETE_COMPANY_SUCCESS'

export const TOGGLE_SHOW_CREATE_COMPANY_FORM =
  'app/Companies/TOGGLE_SHOW_CREATE_COMPANY_FORM'
