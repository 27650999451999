import PropTypes from 'prop-types'
import React from 'react'
import { Modal, FormControl } from 'react-bootstrap'

import { CancelButton, OkButton } from 'components/Button'
import ModalHeader from 'components/ModalHeader'
import ModalFooter from 'components/ModalFooter'

export class PromptModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    body: PropTypes.string,
  }

  static defaultProps = {
    show: false,
    title: undefined,
  }

  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  state = {
    input: '',
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ input: '' })
  }

  handleChange = (event) => {
    this.setState({ input: event.target.value })
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.props.onSuccess(this.state.input)
    }
  }

  render() {
    const { input } = this.state
    const { body, onCancel, onSuccess, show, title } = this.props

    return (
      <div className="static-modal">
        <Modal backdrop="static" show={show} onHide={onCancel}>
          {title && <ModalHeader title={title} />}
          <Modal.Body>
            <p>{body}</p>
            <FormControl
              autoFocus
              defaultValue={input}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              type="text"
            />
          </Modal.Body>
          <ModalFooter>
            <CancelButton onClick={onCancel} />
            <OkButton onClick={() => onSuccess(input)} />
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default PromptModal
