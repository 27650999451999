import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getCustomers = () =>
  axios
    .get('customers', config())
    .then(returnData)
    .catch(catchError)

const addCustomer = (data) =>
  axios
    .post('customers', data, config())
    .then(returnData)
    .catch(catchError)

const deleteCustomer = (code) =>
  axios
    .delete(`customers/${code}`, config())
    .then(returnData)
    .catch(catchError)

const patchCustomer = (code, patch) =>
  axios
    .patch(`customers/${code}`, patch, config())
    .then(returnData)
    .catch(catchError)

const customerManagementApi = {
  getCustomers,
  addCustomer,
  deleteCustomer,
  patchCustomer,
}
export default customerManagementApi
