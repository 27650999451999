import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const createAccountScheme = ({
  accountSchemeTemplateId,
  companyCode,
  mapReportingCodes,
  name,
}) =>
  axios
    .post(
      `${companyCode}/accountschemes`,
      { accountSchemeTemplateId, mapReportingCodes, name },
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteAccountScheme = ({ companyCode, id }) =>
  axios
    .delete(`${companyCode}/accountschemes/${id}`, config())
    .then(returnData)
    .catch(catchError)

const getAccountScheme = ({ companyCode, id }) =>
  axios
    .get(`${companyCode}/accountschemes/${id}`, config())
    .then(returnData)
    .catch(catchError)

const getAccountSchemes = ({ companyCode }) =>
  axios
    .get(`${companyCode}/accountschemes`, config())
    .then(returnData)
    .catch(catchError)

const getAccountSchemeTree = ({ companyCode, id }) =>
  axios
    .get(`${companyCode}/accountschemes/${id}/tree`, config())
    .then(returnData)
    .catch(catchError)

const patchAccountScheme = ({ companyCode, id, patch }) => {
  return axios
    .patch(`${companyCode}/accountschemes/${id}`, patch, config())
    .then(returnData)
    .catch(catchError)
}

const patchAccountSchemeTree = ({ companyCode, id, patch }) => {
  return axios
    .patch(`${companyCode}/accountschemes/${id}/tree`, patch, config())
    .then(returnData)
    .catch(catchError)
}

const setDefaultAccountScheme = ({ companyCode, id }) =>
  axios
    .post(`${companyCode}/accountschemes/${id}/default`, null, config())
    .then(returnData)
    .catch(catchError)

const accountSchemeApi = {
  createAccountScheme,
  deleteAccountScheme,
  getAccountScheme,
  getAccountSchemes,
  getAccountSchemeTree,
  patchAccountScheme,
  patchAccountSchemeTree,
  setDefaultAccountScheme,
}

export default accountSchemeApi
