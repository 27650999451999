import { fromJS } from 'immutable'

import {
  GET_REPORT_CONTEXT_DATA,
  GET_REPORT_CONTEXT_DATA_ERROR,
  GET_REPORT_CONTEXT_DATA_SUCCESS,
  GET_REPORT_CONTEXT_DATA_PROGRESS_SUCCESS,
  INVALIDATE_REPORT_CONTEXT_DATA_CACHE,
  INVALIDATE_REPORT_CONTEXT_DATA_CACHE_ERROR,
  INVALIDATE_REPORT_CONTEXT_DATA_CACHE_SUCCESS,
} from './constants'

import { UPDATE_REPORT_SELECTION_SUCCESS } from 'containers/Reports/constants'

const initialState = fromJS({})

const contextReportDatasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_CONTEXT_DATA:
    case INVALIDATE_REPORT_CONTEXT_DATA_CACHE:
      return state.setIn([action.companyCode, action.reportId], {
        data: undefined,
        error: false,
        loading: true,
        progress: undefined,
      })

    case GET_REPORT_CONTEXT_DATA_ERROR:
    case INVALIDATE_REPORT_CONTEXT_DATA_CACHE_ERROR:
      return state.setIn([action.companyCode, action.reportId], {
        loading: false,
        error: action.error,
        progress: undefined,
      })

    case GET_REPORT_CONTEXT_DATA_SUCCESS:
      return state.setIn([action.companyCode, action.reportId], {
        data: action.report,
        error: false,
        loading: false,
        progress: undefined,
      })
    case GET_REPORT_CONTEXT_DATA_PROGRESS_SUCCESS:
      const { companyCode, progress, reportId } = action
      return state.updateIn([companyCode, reportId], (current) => ({
        ...current,
        progress,
      }))

    case INVALIDATE_REPORT_CONTEXT_DATA_CACHE_SUCCESS:
      return state.deleteIn([action.companyCode, action.reportId])

    case UPDATE_REPORT_SELECTION_SUCCESS: {
      const reportSelectionId = action?.reportSelection?.id

      return reportSelectionId
        ? state.map((company) => company.delete(reportSelectionId.toString()))
        : state
    }

    default:
      return state
  }
}

export default contextReportDatasReducer
