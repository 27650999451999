/*
 *
 * CompanyCreationWizard constants
 *
 */

export const CLOSE_MODAL = 'app/CompanyCreationWizard/CLOSE_MODAL'
export const COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME'
export const COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_ERROR =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_ERROR'
export const COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_SUCCESS =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_SUCCESS'
export const COMPANY_CREATION_WIZARD_CREATE_BUDGET =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_CREATE_BUDGET'
export const COMPANY_CREATION_WIZARD_CREATE_BUDGET_ERROR =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_CREATE_BUDGET_ERROR'
export const COMPANY_CREATION_WIZARD_CREATE_BUDGET_SUCCESS =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_CREATE_BUDGET_SUCCESS'
export const COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT'
export const COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR'
export const COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS =
  'app/CompanyCreationWizard/COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS'

export const GET_ACCOUNT_LIST_CHOICES =
  'app/CompanyCreationWizard/GET_ACCOUNT_LIST_CHOICES'

export const GET_ACCOUNT_LIST_CHOICES_ERROR =
  'app/CompanyCreationWizard/GET_ACCOUNT_LIST_CHOICES_ERROR'
export const GET_ACCOUNT_LIST_CHOICES_SUCCESS =
  'app/CompanyCreationWizard/GET_ACCOUNT_LIST_CHOICES_SUCCESS'
export const GET_ACCOUNT_LIST_FILE =
  'app/CompanyCreationWizard/GET_ACCOUNT_LIST_FILE'
export const GET_ACCOUNT_LIST_FILE_ERROR =
  'app/CompanyCreationWizard/GET_ACCOUNT_LIST_FILE_ERROR'
export const GET_ACCOUNT_LIST_FILE_SUCCESS =
  'app/CompanyCreationWizard/GET_ACCOUNT_LIST_FILE_SUCCESS'
export const GET_COMPANY_CREATION_WIZARD_ACCOUNT_SCHEME_TEMPLATES =
  'app/CompanyCreationWizard/GET_COMPANY_CREATION_WIZARD_ACCOUNT_SCHEME_TEMPLATES'
export const GET_COMPANY_CREATION_WIZARD_ACCOUNT_SCHEME_TEMPLATES_ERROR =
  'app/CompanyCreationWizard/GET_COMPANY_CREATION_WIZARD_ACCOUNT_SCHEME_TEMPLATES_ERROR'
export const GET_COMPANY_CREATION_WIZARD_ACCOUNT_SCHEME_TEMPLATES_SUCCESS =
  'app/CompanyCreationWizard/GET_COMPANY_CREATION_WIZARD_ACCOUNT_SCHEME_TEMPLATES_SUCCESS'
export const IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS =
  'app/CompanyCreationWizard/IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS'
export const IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR =
  'app/CompanyCreationWizard/IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR'
export const IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS =
  'app/CompanyCreationWizard/IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS'
export const PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS =
  'app/CompanyCreationWizard/PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS'
export const PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR =
  'app/CompanyCreationWizard/PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR'
export const PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS =
  'app/CompanyCreationWizard/PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS'
export const SET_SPECIAL_ACCOUNTS =
  'app/CompanyCreationWizard/SET_SPECIAL_ACCOUNTS'
export const SET_SPECIAL_ACCOUNTS_ERROR =
  'app/CompanyCreationWizard/SET_SPECIAL_ACCOUNTS_ERROR'
export const SET_SPECIAL_ACCOUNTS_SUCCESS =
  'app/CompanyCreationWizard/SET_SPECIAL_ACCOUNTS_SUCCESS'
export const START_CREATE_BUDGET =
  'app/CompanyCreationWizard/START_CREATE_BUDGET'
