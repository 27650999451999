/*
 *
 * Companies actions
 *
 */

import {
  TOGGLE_SHOW_CREATE_COMPANY_FORM,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
} from './constants'

export const toggleShowCreateCompanyForm = () => ({
  type: TOGGLE_SHOW_CREATE_COMPANY_FORM,
})

export const getCompanies = ({ customerCode }) => ({
  type: GET_COMPANIES,
  customerCode,
})
export const getCompaniesSuccess = ({ companies }) => ({
  type: GET_COMPANIES_SUCCESS,
  companies,
})
export const getCompaniesError = (error) => ({
  type: GET_COMPANIES_ERROR,
  error,
})

export const createCompany = ({ company, customerCode }) => ({
  type: CREATE_COMPANY,
  company,
  customerCode,
})
export const createCompanySuccess = ({ company, customerCode }) => ({
  type: CREATE_COMPANY_SUCCESS,
  company,
  customerCode,
})
export const createCompanyError = (error) => ({
  type: CREATE_COMPANY_ERROR,
  error,
})

export const deleteCompany = ({ customerCode, companyCode }) => ({
  type: DELETE_COMPANY,
  customerCode,
  companyCode,
})
export const deleteCompanySuccess = ({ customerCode, companyCode }) => ({
  type: DELETE_COMPANY_SUCCESS,
  customerCode,
  companyCode,
})
export const deleteCompanyError = (error) => ({
  type: DELETE_COMPANY_ERROR,
  error,
})
