/*
 *
 * Vouchers constants
 *
 */

export const COPY_VOUCHER = 'app/Vouchers/COPY_VOUCHER'
export const COPY_VOUCHER_ERROR = 'app/Vouchers/COPY_VOUCHER_ERROR'
export const COPY_VOUCHER_SUCCESS = 'app/Vouchers/COPY_VOUCHER_SUCCESS'
export const CREATE_VOUCHER = 'app/Vouchers/CREATE_VOUCHER'
export const CREATE_VOUCHER_ERROR = 'app/Vouchers/CREATE_VOUCHER_ERROR'
export const CREATE_VOUCHER_SUCCESS = 'app/Vouchers/CREATE_VOUCHER_SUCCESS'

export const DELETE_VOUCHER = 'app/Vouchers/DELETE_VOUCHER'
export const DELETE_VOUCHER_ERROR = 'app/Vouchers/DELETE_VOUCHER_ERROR'
export const DELETE_VOUCHER_SUCCESS = 'app/Vouchers/DELETE_VOUCHER_SUCCESS'

export const GET_VOUCHER_LIST = 'app/Vouchers/GET_VOUCHER_LIST'
export const GET_VOUCHER_LIST_ERROR = 'app/Vouchers/GET_VOUCHER_LIST_ERROR'
export const GET_VOUCHER_LIST_SUCCESS = 'app/Vouchers/GET_VOUCHER_LIST_SUCCESS'

export const TOGGLE_SHOW_VOUCHER_FORM = 'app/Vouchers/TOGGLE_SHOW_VOUCHER_FORM'
export const TOGGLE_SHOW_CONSOLIDATED_FORM =
  'app/Vouchers/TOGGLE_SHOW_CONSOLIDATED_FORM'

export const VOUCHER_TYPE_FINAZILLA = 'Finazilla'
export const VOUCHER_TYPE_ACQUISITION = 'Acquisition'
export const VOUCHER_TYPES = [
  'All',
  VOUCHER_TYPE_FINAZILLA,
  VOUCHER_TYPE_ACQUISITION,
  'Other',
]

export const GET_EXPORT_DATA = 'app/Vouchers/GET_EXPORT_DATA'
export const GET_EXPORT_DATA_ERROR = 'app/Vouchers/GET_EXPORT_DATA_ERROR'
export const GET_EXPORT_DATA_SUCCESS = 'app/Vouchers/GET_EXPORT_DATA_SUCCESS'

export const GENERAL_JOURNAL = 'GeneralJournal'
export const GENERAL_LEDGER = 'GeneralLedger'

export const EXPORT_TYPES = [GENERAL_JOURNAL, GENERAL_LEDGER]
export const BOTH = 'Both'
export const CONSOLIDATED = 'Consolidated'
export const NON_CONSOLIDATED = 'NonConsolidated'
export const ACCOUNT_FILTER_TYPES = [BOTH, CONSOLIDATED, NON_CONSOLIDATED]
