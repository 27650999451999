import { Record } from 'immutable'

const PeriodRecord = Record({
  y: undefined,
  m: undefined,
  d: undefined,
  q: undefined,
  t: undefined,
  dictionaryKey: '',
})

export default PeriodRecord
