import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const OverlayTriggerIfEllipsedText = ({ children, className, disabled }) => {
  return (
    <OverlayTrigger
      delayShow={400}
      delayHide={150}
      placement="top"
      overlay={<Tooltip style={{ overflow: 'hidden' }}>{children}</Tooltip>}
      trigger={children.length > 29 && !disabled ? ['hover', 'focus'] : []}
    >
      <span className={className}>{children}</span>
    </OverlayTrigger>
  )
}

export default OverlayTriggerIfEllipsedText
