import { List } from 'immutable'
import { last, isEqual, uniqBy } from 'lodash'
import { isEmptyArray } from 'utils/array'
import { getDimensionName } from 'containers/Dimension/functions.js'

export function getChildrenNodesAsFlattenedArray(
  path,
  level,
  value,
  root,
  type,
  rootId
) {
  const pathArray = [...path, getDimensionName(value)]
  const dataItem = {
    id: value.id,
    name: value.nameToDisplay,
    color: level,
    path: pathArray,
    root,
    type,
    rootId,
  }
  const children = value.children
    .flatMap((child) =>
      getChildrenNodesAsFlattenedArray(
        pathArray,
        level,
        child,
        root,
        type,
        rootId
      )
    )
    .toArray()
    .sort((a, b) => {
      const aLastIndex = a.path.length > 0 ? a.path.length - 1 : 0
      const bLastIndex = b.path.length > 0 ? b.path.length - 1 : 0
      return a.path[aLastIndex].localeCompare(b.path[bLastIndex])
    })

  return [dataItem].concat(children)
}
/**
 *
 * @param {immutable List} dimensionList
 * @returns an array
 */
export function getDimensionDataAsFlattenedArray(dimensionList) {
  if (!dimensionList || !dimensionList.filter) {
    //Some may pass empty objects that don't have filter method.
    return []
  }
  const flattenedDimensionArray = dimensionList
    .flatMap((dimension, level) =>
      dimension.values
        .flatMap((value) =>
          getChildrenNodesAsFlattenedArray(
            [],
            level,
            value,
            getDimensionName(dimension),
            dimension.dimensionType,
            dimension.id
          )
        )
        .sort((a, b) => {
          return a && a.path && a.path[0]
            ? a.path[0].localeCompare(b.path[0])
            : -1
        })
    )
    .toArray()

  const flattenedDimensionSet = new Set(
    flattenedDimensionArray.map((item) => item.id)
  )
  const dimensionsWithoutParent = dimensionList
    .flatMap((dimension, level) =>
      dimension.values
        .filter((value) => !flattenedDimensionSet.has(value.id))
        .flatMap((value) =>
          getChildrenNodesAsFlattenedArray(
            [],
            level,
            value,
            getDimensionName(dimension),
            dimension.dimensionType,
            dimension.id
          )
        )
        .sort((a, b) => {
          return a && a.path && a.path[0]
            ? a.path[0].localeCompare(b.path[0])
            : -1
        })
    )
    .toArray()

  // only unique dimension values by id, sorted alphabetically
  const returnValue = uniqBy(
    flattenedDimensionArray.concat(dimensionsWithoutParent),
    'id'
  ).filter((item) => item.path[0]) // remove null/undefined values before sort
  return returnValue
}

export function getSelectedValues(selectedIds, dimensionData) {
  const ids = new List(selectedIds)
  return ids.size > 0
    ? dimensionData.filter((item) => ids.indexOf(item.id) !== -1)
    : []
}

export function getDisabledParentItems(selectedValues, dimensionData) {
  const selectedValuesParentLevels = selectedValues.reduce((acc, curr) => {
    curr.path.forEach((item, index) => {
      // slice makes a copy of an array starting from index 0 until end of the list
      // Here we take different disabled parent levels like
      // [["Sites", "JKL", ".NET"], ["Sites", "JKL"], ["Sites"]]
      const path = curr.path.slice(0, -1 * index)
      if (isEmptyArray(path)) {
        return
      }
      acc.push(path)
    })

    return acc
  }, [])

  return dimensionData.filter((dd) =>
    selectedValuesParentLevels.some((disabledLevel) =>
      isEqual(disabledLevel, dd.path)
    )
  )
}

/**
 * Filters dimension data based on selected values. Selection's root level and it's children will be
 * filtered.
 * @param {Array} selectedValues Selected dimensions
 * @param {*} dimensionData Flat dimension array
 */
export function getDimensionsFilteredByRoot(selectedValues, dimensionData) {
  if (selectedValues.length > 0) {
    const filterBy = selectedValues.map((value) => value.root)

    return dimensionData.filter((item) => filterBy.indexOf(item.root) === -1)
  }

  return dimensionData
}

/**
 * Filters dimension data based on selected values. Selected value and it's children of the selection are filtered
 * @param {Array} selectedValues Selected dimensions
 * @param {*} dimensionData Flat dimension array
 */
export function getDimensionsFilteredByParent(selectedValues, dimensionData) {
  const lastFragmentOfSelectedValues = selectedValues.map((v) => last(v.path))
  return dimensionData.filter((item) => {
    const isSelectedValue = selectedValues.some((v) => v.id === item.id)
    const isChildOfSelection =
      item.path.length > 1 &&
      lastFragmentOfSelectedValues.includes(item.path[item.path.length - 2])

    if (isChildOfSelection || isSelectedValue) {
      return false
    }

    return true
  })
}

export function valuesChanged(newArray, old) {
  return (
    newArray.length !== old.length ||
    !newArray.every((v) => old.some((ov) => ov === v))
  )
}
