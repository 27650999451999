import { defineMessages } from 'react-intl'

export default defineMessages({
  loggedinas: {
    id: 'nav.user.loggedinas',
    description: 'Navigation Bar: User Menu, Header',
    defaultMessage: 'Signed in as',
  },
  logout: {
    id: 'nav.user.logout',
    description: 'Navigation Bar: User Menu > Logout',
    defaultMessage: 'Sign out',
  },
  profile: {
    id: 'nav.user.profile',
    description: 'Navigation Bar: User Menu > Profile',
    defaultMessage: 'Profile',
  },
  settings: {
    id: 'nav.user.settings',
    description: 'Navigation Bar: User Menu > Settings',
    defaultMessage: 'Settings',
  },
  switchUser: {
    id: 'nav.user.switchUser',
    description: 'Navigation Bar: User Menu > User',
    defaultMessage: 'Switch user',
  },
})
