/*
 *
 * BudgetMonitor actions
 *
 */

import {
  GET_BUDGET_BALANCE_FOR_ROW,
  GET_BUDGET_BALANCE_FOR_ROW_ERROR,
  GET_BUDGET_BALANCE_FOR_ROW_SUCCESS,
  SYNC_BUDGET,
  SYNC_BUDGET_SUCCESS,
  SYNC_BUDGET_ERROR,
  GET_BUDGET_MONITOR_PROGRESS_SUCCESS,
  GET_BUDGET_BALANCE_TREE_SUCCESS,
  SHOW_BUDGET_MONITOR_ROW_NOTES,
} from './constants'

export const getBudgetBalanceForRow = ({
  companyCode,
  budgetId,
  start,
  end,
  rowId,
  path,
}) => ({
  type: GET_BUDGET_BALANCE_FOR_ROW,
  companyCode,
  budgetId,
  start,
  end,
  rowId,
  path,
})

export const getBudgetBalanceForRowSuccess = ({ data, rowId, tree, path }) => ({
  type: GET_BUDGET_BALANCE_FOR_ROW_SUCCESS,
  data,
  tree,
  path,
  rowId,
})

export const getBudgetBalanceForRowError = (error) => ({
  type: GET_BUDGET_BALANCE_FOR_ROW_ERROR,
  error,
})

export const syncBudget = ({ companyCode, budgetId, start, end }) => ({
  type: SYNC_BUDGET,
  companyCode,
  budgetId,
  start,
  end,
})

export const syncBudgetSuccess = ({ data }) => ({
  type: SYNC_BUDGET_SUCCESS,
  data,
})

export const syncBudgetError = (error) => ({
  type: SYNC_BUDGET_ERROR,
  error,
})

export const getBudgetBalanceTreeSuccess = (tree) => ({
  type: GET_BUDGET_BALANCE_TREE_SUCCESS,
  tree,
})

export const getBudgetMonitorProgressSuccess = (progress) => ({
  type: GET_BUDGET_MONITOR_PROGRESS_SUCCESS,
  progress,
})

export const showBudgetMonitorRowNotes = ({ row, show }) => ({
  type: SHOW_BUDGET_MONITOR_ROW_NOTES,
  row,
  show,
})
