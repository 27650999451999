/**
 * Encapsulates transformation from ReportSelection to flexMonster components config.
 */

import { isFormulaDataSource, FormulaParser, mapRowsOrColumns } from '../utils'
import { isValueEnumMatch } from 'utils/enum'
import { lowerFirst } from 'lodash'
import {
  DIMENSION_PRES_TYPE_DIMENSION_MAPPING_ARRAY,
  DECIMAL_SEPARATOR_MAPPING_ARRAY,
  DIMENSION_PRES_TYPE_DIMENSIONS_TOTAL,
  DATATYPE_MAPPING_ARRAY,
  THOUSAND_SEPARATOR_MAPPING_ARRAY,
  DATATYPE_FORMULA,
  SEPARATOR_DOT,
  SEPARATOR_SPACE,
  SEPARATOR_COMMA,
  FORMULA_OPERATOR_DIFF_PERCENTAGE,
  COMPANY_PRES_TYPE_COMPANIES_TOTAL,
  COMPANY_PRES_TYPE_COMPANY_PER_COLUMN_CONSOLIDATED,
  DATEFORMAT_VALUES,
  OTHERS,
  COMPANY_PRES_TYPE_CONSOLIDATED_TOTAL,
  REPORT_SELECTION_TYPE_CUSTOMER,
} from '../constants'
import { hasCompanyCode } from 'utils/context'

export default class ReportSelectionToReportConfigTransformer {
  DIMENSION_VALUE_NAME = 'dimension' // these come from data. Fetch 'dimension' type from elements.
  //a few members to minimize number of loops
  myReportData = undefined
  dimensionNames = undefined
  myCurrentReportSelection = undefined
  myFieldDefinitionObject = undefined
  myFormulaDataSources = undefined
  //members end
  //TODO: dimensionNames is deprecated. U should be able to get those from data.
  constructor(reportData, dimensionNames) {
    this.myFieldDefinitionObject = reportData[0] // 1st item holds field information
    this.myReportData = reportData
    this.dimensionNames = dimensionNames
      ? dimensionNames
      : this.initDimensionNamesFromReportData()
  }

  getAllFieldsKeyAsUniqueName = () => {
    if (!this.myReportData || this.myReportData.length < 1) {
      return undefined
    }

    const definitions = this.getFieldDefinitionObject()
    return this.getMeasuresFromBEFieldNames(definitions)
  }

  getMeasuresFromBEFieldNames = (definitions) =>
    Object.keys(definitions).reduce((working, measureName) => {
      const definition = definitions[measureName]
      working.push({
        uniqueName: measureName,
        aggregation: 'sum',
        format: measureName === 'percentageTargetAmount' ? 'PERCENTAGE' : '',
        caption: measureName === 'percentageTargetAmount' ? '%' : measureName,
        toMeasuresFlag: definition.toMeasuresFlag,
      })
      return working
    }, [])

  getFieldDefinitionObject = () => this.myFieldDefinitionObject // 1st item holds field information
  getFormulaDataSources = () => {
    if (!this.myFormulaDataSources) {
      this.myFormulaDataSources = this.myCurrentReportSelection.reportDataSources.filter(
        isFormulaDataSource
      )
    }
    return this.myFormulaDataSources
  }
  getMeasuresFromData = () => {
    if (!this.myReportData || this.myReportData.length < 1) return undefined
    const definitions = this.getFieldDefinitionObject()
    const allFields = this.getMeasuresFromBEFieldNames(definitions)
    const measuredFields = allFields.filter(
      (object) =>
        object.toMeasuresFlag ||
        object.uniqueName === 'amount' ||
        object.uniqueName === 'kpl'
    )
    return measuredFields
  }
  createSliceObjectFromName = (stringValue, UnsortedDate) => {
    if (stringValue === 'period') {
      const obj = {
        uniqueName: 'date',
      }
      if (UnsortedDate) obj.sort = 'unsorted'
      return obj
    }
    return {
      uniqueName: stringValue,
    }
  }
  createMeasuresArray = (reportSelection) => {
    if (!reportSelection) return undefined
    if (this.isFormulaDataSourceUsed(reportSelection)) {
      return this.transformDataSourcesToMeasuresArray({ reportSelection })
    }
    const measuresFromData = this.getMeasuresFromData()
    if (this.isPercentageTargetAmountUsed()) {
      measuresFromData.push({
        uniqueName: 'percentageAmountFormula',
        caption: '%',
        formula: 'sum("amount")/sum("percentageTargetAmount")',
        format: 'PERCENTAGE',
        individual: true,
      })
    }
    const dsArray = this.transformDataSourcesToMeasuresArray({
      reportSelection,
    })
    const returnValue = dsArray
      ? dsArray.concat(measuresFromData)
      : measuresFromData
    return returnValue
  }
  createFormats = (reportSelection) => [
    {
      name: '',
      thousandsSeparator: this.mapThousandSeparator(
        reportSelection.thousandSeparatorType
      ),
      decimalSeparator: this.mapDecimalSeparator(
        reportSelection.decimalSeparatorType
      ),
      decimalPlaces: reportSelection.decimals,
      currencySymbol: '',
      currencySymbolAlign: 'left',
      nullValue: '',
      textAlign: 'right',
      isPercent: false,
      divideByZeroValue: '',
    },
    {
      name: 'PERCENTAGE',
      thousandsSeparator: this.mapThousandSeparator(
        reportSelection.thousandSeparatorType
      ),
      decimalSeparator: this.mapDecimalSeparator(
        reportSelection.decimalSeparatorType
      ),
      decimalPlaces: reportSelection.decimals,
      currencySymbol: '',
      currencySymbolAlign: 'left',
      nullValue: '',
      textAlign: 'right',
      isPercent: true,
      divideByZeroValue: '',
    },
  ]
  createOptions = (reportSelection, beta) => ({
    grid: {
      showGrandTotals: reportSelection.showTotalColumn ? 'rows' : 'off',
      showHeaders: false,
      showHierarchies: false,
      showTotals: !beta && reportSelection.showSubTotals ? 'on' : 'rows',
      title: beta ? undefined : reportSelection.name,
    },
    configuratorActive: false,
    showAggregationLabels: false,
  })
  /**
   *  dimension can be unused at the moment. This filters it away when necessary
   */
  filterUnUsedRowsOrColumns = (columnArray, reportSelection) => {
    const dataSourceIndex = reportSelection.reportDataSources.findIndex(
      (ds) =>
        ds.companies.findIndex(
          (company) => company.dimensionValueIds.length > 0
        ) >= 0
    )
    if (dataSourceIndex >= 0) {
      return columnArray
    }
    return columnArray.filter(
      (val) => val.uniqueName !== this.DIMENSION_VALUE_NAME
    )
  }
  /**Apparently this don't work.
   * TODO: make it work and remove dimensionNames from constructor param list */
  initDimensionNamesFromReportData = () => {
    const metaDataObject = this.myFieldDefinitionObject
    if (!metaDataObject) return []
    return Object.keys(metaDataObject).filter(
      (metaDataKey) =>
        metaDataObject[metaDataKey] &&
        metaDataObject[metaDataKey].type &&
        metaDataObject[metaDataKey].type === 'dimension'
    )
  }
  isDataSourceDimensionPresentationTypeTotalAndNotFormula = (ds) =>
    this.isFormula(ds) === false &&
    isValueEnumMatch(
      ds.dimensionPresentationType,
      DIMENSION_PRES_TYPE_DIMENSION_MAPPING_ARRAY,
      DIMENSION_PRES_TYPE_DIMENSIONS_TOTAL
    )
  isFormula = (ds) =>
    isValueEnumMatch(ds.dataType, DATATYPE_MAPPING_ARRAY, DATATYPE_FORMULA)
  isFormulaDataSourceUsed = () => this.getFormulaDataSources().length > 0
  isPercentageTargetAmountUsed = () => {
    const definitions = this.getFieldDefinitionObject()
    if (!definitions || !Object.keys(definitions)) {
      return false
    }
    return Object.keys(definitions).includes('percentageTargetAmount')
  }
  mapColumns = (reportSelection) => {
    if (!reportSelection) return []
    let columnArray = this.mapRowsOrColumns(
      reportSelection.showInColumns,
      reportSelection
    )
    if (
      !reportSelection.reportDataSources ||
      (reportSelection.reportDataSources.length <= 1 &&
        !reportSelection.showSubTotals) || // jos ds:ia on vain yksi niin ei nay, mutta jos totalissa onkin ruksi niin naytetaan sittenki, jotta silla totalilla olisi jokin paikka
      this.isFormulaDataSourceUsed(reportSelection)
    ) {
      columnArray = columnArray.filter(
        (value) => value.uniqueName !== 'datasource'
      )
    }
    if (reportSelection.reportDataSources) {
      // company is not shown if there is only one or company pres type is consolidated per col
      const companyIds = reportSelection.reportDataSources.flatMap((ds) =>
        ds.companies.map((comp) => comp.companyId)
      )
      const uniqueSetOfIds = new Set(companyIds)
      const hasNoConsolidatedDataSource = reportSelection.reportDataSources.every(
        (ds) =>
          ds.companyPresentationType !==
            COMPANY_PRES_TYPE_COMPANY_PER_COLUMN_CONSOLIDATED &&
          ds.companyPresentationType !== 1
      )
      const isCustomerTypeWithNoCompanyCode =
        reportSelection.type === REPORT_SELECTION_TYPE_CUSTOMER &&
        !hasCompanyCode()
      if (
        uniqueSetOfIds.size <= 1 &&
        hasNoConsolidatedDataSource &&
        !isCustomerTypeWithNoCompanyCode // On default show companies on customer report if in customer url. For clarity
      ) {
        columnArray = columnArray.filter(
          (value) => value.uniqueName !== 'company'
        )
      }
    }
    return columnArray
  }
  mapDecimalSeparator = (separatorNum) => {
    const i =
      separatorNum < 1 || separatorNum > DECIMAL_SEPARATOR_MAPPING_ARRAY.length
        ? 0
        : separatorNum
    return this.mapSeparator(DECIMAL_SEPARATOR_MAPPING_ARRAY[i])
  }
  mapRows = (reportSelection) => {
    if (!reportSelection) return []
    if (reportSelection.reportDataSources.some((ds) => ds.showTopRowsCount)) {
      return [
        {
          uniqueName: 'rows',
          sortOrder: [OTHERS],
        },
        ...this.mapRowsOrColumns(reportSelection.showInRows, reportSelection),
      ]
    }
    return [
      {
        uniqueName: 'rows',
        sort: 'unsorted',
      },
      ...this.mapRowsOrColumns(reportSelection.showInRows, reportSelection),
    ]
  }
  mapRowsOrColumns(arrayOfIntegers, reportSelection) {
    const transformedValueArray = mapRowsOrColumns(arrayOfIntegers, true)
    //You might wonder why there is this useUnSortedDate flag?
    //This is for months to order from the oldest to newest. Important i.e. when range is from 9/2019 to 4/2020
    const useUnSortedDate =
      reportSelection.dateFormat === DATEFORMAT_VALUES.MONTH
    const flexMonsterObjectArray = transformedValueArray.map((value) =>
      this.createSliceObjectFromName(value, useUnSortedDate)
    )
    let usedValues = this.filterUnUsedRowsOrColumns(
      flexMonsterObjectArray,
      reportSelection
    )
    if (reportSelection.reportDataSources) {
      const companyPresentationTypeIsTotal = reportSelection.reportDataSources.some(
        (ds) =>
          ds.companyPresentationType === 2 ||
          ds.companyPresentationType === 3 ||
          ds.companyPresentationType === COMPANY_PRES_TYPE_COMPANIES_TOTAL ||
          ds.companyPresentationType === COMPANY_PRES_TYPE_CONSOLIDATED_TOTAL
      )
      if (companyPresentationTypeIsTotal) {
        const companysIndex = usedValues.findIndex(
          (value) => value.uniqueName === 'company'
        )
        if (companysIndex >= 0) usedValues = usedValues.slice(0, companysIndex)
      }
      const hasDataSourceWithTotal = reportSelection.reportDataSources.some(
        this.isDataSourceDimensionPresentationTypeTotalAndNotFormula
      )

      const hasFormula = reportSelection.reportDataSources.some(this.isFormula)

      const dimensionsIndex = usedValues.findIndex(
        (value) => value.uniqueName === this.DIMENSION_VALUE_NAME
      )
      if (dimensionsIndex >= 0) {
        if (hasDataSourceWithTotal && hasFormula === false) {
          usedValues = usedValues.slice(0, dimensionsIndex)
        } else if (hasDataSourceWithTotal && hasFormula) {
          usedValues.splice(dimensionsIndex, 1, { uniqueName: 'date' })
        } else {
          const selectedDimensionsAsSliceObjects = this.transformDimensionNameArrayToSliceValues(
            this.dimensionNames
          )
          usedValues.splice(
            dimensionsIndex,
            1,
            ...selectedDimensionsAsSliceObjects
          ) // this changes 'dimension' string to dynamically fetched dimension names
        }
      }
    }
    return usedValues.filter((value) => value) // Remove all undefined elements and trim return value just in case.
  }
  mapSeparator = (separatorEnumStr) => {
    if (separatorEnumStr === SEPARATOR_DOT) return '.'
    if (separatorEnumStr === SEPARATOR_SPACE) return ' '
    if (separatorEnumStr === SEPARATOR_COMMA) return ','
    return ''
  }
  mapThousandSeparator = (separatorNum) => {
    const i =
      separatorNum < 1 || separatorNum > THOUSAND_SEPARATOR_MAPPING_ARRAY.length
        ? 0
        : separatorNum
    return this.mapSeparator(THOUSAND_SEPARATOR_MAPPING_ARRAY[i])
  }
  setCurrentReportSelection = (reportSelection) => {
    this.myCurrentReportSelection = reportSelection
  }
  transform = (reportSelection, beta) => {
    this.setCurrentReportSelection(reportSelection)
    const forceMeasuresToColumns =
      this.isFormulaDataSourceUsed() ||
      this.isPercentageTargetAmountUsed() ||
      beta
    const formats = this.createFormats(reportSelection)
    const mapping = this.getFieldDefinitionObject()
    const config = {
      formats,
      options: this.createOptions(reportSelection, beta),
      slice: {
        columns: forceMeasuresToColumns
          ? [...this.mapColumns(reportSelection), { uniqueName: '[Measures]' }]
          : this.mapColumns(reportSelection),
        drills: {
          drillAll: true,
        },
        expands: {
          expandAll: true,
        },
        reportFilters:
          (beta &&
            this.dimensionNames?.map((dimName) => ({
              uniqueName: lowerFirst(dimName),
            }))) ||
          [],
        measures: this.createMeasuresArray(reportSelection),
        rows: forceMeasuresToColumns
          ? this.mapRows(reportSelection)
          : [...this.mapRows(reportSelection), { uniqueName: '[Measures]' }],
      },
      dataSource: {
        dataSourceType: 'json',
        mapping: mapping,
        data: this.myReportData,
      },
    }
    return config
  }
  transformDataSource = ({ reportSelection, reportDataSource }) => {
    const isFormulaDataSourceUsed = this.isFormulaDataSourceUsed(
      reportSelection
    )
    return transformDatasource({
      reportDataSource,
      activeState: isFormulaDataSourceUsed,
    })
  }
  transformDataSourcesToMeasuresArray = ({ reportSelection }) => {
    if (!reportSelection || !reportSelection.reportDataSources) return undefined
    const dsList = reportSelection.reportDataSources.map((ds) =>
      this.transformDataSource({ reportSelection, reportDataSource: ds })
    )
    return dsList
  }
  transformDimensionNameArrayToSliceValues = (dimensionNames) =>
    dimensionNames.map((dimName) => ({
      uniqueName: lowerFirst(dimName),
    }))
}

export const transformDatasource = ({ reportDataSource, activeState }) => {
  if (isFormulaDataSource(reportDataSource)) {
    const dsNameVariableName = lowerFirst(reportDataSource.name)
    const isPercent =
      (reportDataSource &&
        reportDataSource.formulaSelections &&
        reportDataSource.formulaSelections.comparisonOperator ===
          FORMULA_OPERATOR_DIFF_PERCENTAGE) ||
      reportDataSource.comparisonOperator === FORMULA_OPERATOR_DIFF_PERCENTAGE
    const returnValue = {
      uniqueName: dsNameVariableName,
      caption: reportDataSource.name,
      formula: !reportDataSource.formulaSelections
        ? reportDataSource.formula
        : FormulaParser.transformSelectionsToFormula(
            reportDataSource.formulaSelections
          ),
      format: !isPercent ? '' : 'PERCENTAGE',
      active: activeState ?? true,
    }
    return returnValue
  }
  return {
    active: activeState,
    uniqueName: lowerFirst(reportDataSource.name), // tietolahteen nimi
    caption: reportDataSource.name,
    dataSourceType: 'json',
    aggregation: 'sum',
  }
}
