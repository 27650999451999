export const msalConfig = {
  auth: {
    authority: process.env.REACT_APP_MSAL_CONFIG_AUTHORITY,
    clientId: process.env.REACT_APP_MSAL_CONFIG_CLIENT_ID,
    redirectUri: process.env.REACT_APP_MSAL_CONFIG_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
}
export const loginRequest = {
  // scopes: ['User.Read'],
  scopes: [process.env.REACT_APP_MSAL_CONFIG_EXPOSED_API_SCOPE],
  state: process.env.REACT_APP_LOGIN_STATE || 'login',
}

export const lemonsoftConfig = {
  auth: {
    authority: process.env.REACT_APP_LEMONSOFT_B2C_AUTHORITY,
    redirectUri: process.env.REACT_APP_LEMONSOFT_B2C_REDIRECT_URL,
    clientId: process.env.REACT_APP_LEMONSOFT_B2C_CLIENT_ID,
    knownAuthorities: [process.env.REACT_APP_LEMONSOFT_B2C_AUTHORITY],
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
}

export const lemonsoftRequest = {
  scopes: [process.env.REACT_APP_LEMONSOFT_B2C_SCOPE],
}

export const finvoicerConfig = {
  url: process.env.REACT_APP_FINVOICER_URL,
  clientId: process.env.REACT_APP_FINVOICER_CLIENT_ID,
}
