/*
 *
 * AccountSchemes reducer
 *
 */
import { fromJS, List } from 'immutable'

import {
  CANCEL_CREATE_ACCOUNT_SCHEME,
  CREATE_ACCOUNT_SCHEME,
  CREATE_ACCOUNT_SCHEME_SUCCESS,
  CREATE_ACCOUNT_SCHEME_ERROR,
  EDIT_ACCOUNT_SCHEME,
  DELETE_ACCOUNT_SCHEME,
  DELETE_ACCOUNT_SCHEME_ERROR,
  DELETE_ACCOUNT_SCHEME_SUCCESS,
  GET_ACCOUNT_SCHEMES,
  GET_ACCOUNT_SCHEMES_ERROR,
  GET_ACCOUNT_SCHEMES_SUCCESS,
  RESET_STATE,
  SET_DEFAULT_ACCOUNT_SCHEME,
  SET_DEFAULT_ACCOUNT_SCHEME_ERROR,
  SET_DEFAULT_ACCOUNT_SCHEME_SUCCESS,
  TOGGLE_SHOW_CREATE_ACCOUNT_SCHEME,
  UPDATE_ACCOUNT_SCHEME,
  UPDATE_ACCOUNT_SCHEME_ERROR,
  UPDATE_ACCOUNT_SCHEME_SUCCESS,
} from './constants'
import { AccountSchemeRecord } from 'records'

const initialState = fromJS({
  accountSchemes: new List(),
  edit: undefined,
  error: false,
  loading: false,
  showCreateAccountScheme: false,
})

const accountSchemesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_CREATE_ACCOUNT_SCHEME:
      return state.set('showCreateAccountScheme', false)

    case CREATE_ACCOUNT_SCHEME:
    case DELETE_ACCOUNT_SCHEME:
    case GET_ACCOUNT_SCHEMES:
    case SET_DEFAULT_ACCOUNT_SCHEME:
    case UPDATE_ACCOUNT_SCHEME:
      return state.set('error', false).set('loading', true)

    case CREATE_ACCOUNT_SCHEME_ERROR:
    case DELETE_ACCOUNT_SCHEME_ERROR:
    case GET_ACCOUNT_SCHEMES_ERROR:
    case SET_DEFAULT_ACCOUNT_SCHEME_ERROR:
    case UPDATE_ACCOUNT_SCHEME_ERROR:
      return state.set('loading', false).set('error', action.error)

    case CREATE_ACCOUNT_SCHEME_SUCCESS:
      return state
        .set('showCreateAccountScheme', false)
        .set('loading', false)
        .set(
          'accountSchemes',
          state
            .get('accountSchemes')
            .push(new AccountSchemeRecord(action.accountScheme))
        )

    case EDIT_ACCOUNT_SCHEME:
      return state.set('edit', action.id).set('showCreateAccountScheme', false)

    case DELETE_ACCOUNT_SCHEME_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'accountSchemes',
          state
            .get('accountSchemes')
            .filter((accountScheme) => accountScheme.id !== action.id)
        )

    case GET_ACCOUNT_SCHEMES_SUCCESS:
      return state
        .set(
          'accountSchemes',
          List(
            action.accountSchemes.map(
              (accountScheme) => new AccountSchemeRecord(accountScheme)
            )
          )
        )
        .set('loading', false)
    case RESET_STATE:
      return initialState
    case SET_DEFAULT_ACCOUNT_SCHEME_SUCCESS: {
      const accountSchemes = state.get('accountSchemes')
      const currentIndex = accountSchemes.findIndex(
        (accountScheme) => accountScheme.isDefault
      )
      const newIndex = accountSchemes.findIndex(
        (accountScheme) => accountScheme.id === action.id
      )

      return state
        .set('loading', false)
        .set(
          'accountSchemes',
          accountSchemes
            .updateIn([currentIndex, 'isDefault'], () => false)
            .updateIn([newIndex, 'isDefault'], () => true)
        )
    }

    case TOGGLE_SHOW_CREATE_ACCOUNT_SCHEME:
      return state
        .set('edit', undefined)
        .set('showCreateAccountScheme', !state.get('showCreateAccountScheme'))

    case UPDATE_ACCOUNT_SCHEME_SUCCESS: {
      const { accountScheme } = action
      const calculationConstants = state.get('accountSchemes')
      const index = calculationConstants.findIndex(
        (item) => item.id === accountScheme.id
      )
      return state
        .set('loading', false)
        .set('edit', undefined)
        .set(
          'accountSchemes',
          calculationConstants.setIn(
            [index],
            new AccountSchemeRecord({ ...action.accountScheme })
          )
        )
    }

    default:
      return state
  }
}

export default accountSchemesReducer
