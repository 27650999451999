export const SET_CASH_IN_HAND = 'app/CashInHand/SET_CASH_IN_HAND'
export const SET_CASH_IN_HAND_SUCCESS =
  'app/CashInHand/SET_CASH_IN_HAND_SUCCESS'
export const SET_CASH_IN_HAND_ERROR = 'app/CashInHand/SET_CASH_IN_HAND_ERROR'

export const RESOLVE_TYPES = {
  none: 0,
  manuallySet: 1,
  reconciliationAccount: 2,
  bankAccounts: 3,
}
