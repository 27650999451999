/*
 *
 * AccountScheme constants
 *
 */
export const ADD_ACCOUNT_SCHEME_ROW = 'app/AccountScheme/ADD_ACCOUNT_SCHEME_ROW'
export const ADD_ACCOUNT_SCHEME_ROW_ERROR =
  'app/AccountScheme/ADD_ACCOUNT_SCHEME_ROW_ERROR'
export const ADD_ACCOUNT_SCHEME_ROW_SUCCESS =
  'app/AccountScheme/ADD_ACCOUNT_SCHEME_ROW_SUCCESS'

export const ADD_ACCOUNT_SCHEME_ROW_GROUP =
  'app/AccountScheme/ADD_ACCOUNT_SCHEME_ROW_GROUP'
export const ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP =
  'app/AccountScheme/ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP'
export const ADD_ACCOUNT_SCHEME_ROW_GROUP_ERROR =
  'app/AccountScheme/ADD_ACCOUNT_SCHEME_ROW_GROUP_ERROR'
export const ADD_ACCOUNT_SCHEME_ROW_GROUP_SUCCESS =
  'app/AccountScheme/ADD_ACCOUNT_SCHEME_ROW_GROUP_SUCCESS'

export const DELETE_ACCOUNT_SCHEME_ROW =
  'app/AccountScheme/DELETE_ACCOUNT_SCHEME_ROW'
export const DELETE_ACCOUNT_SCHEME_ROW_ERROR =
  'app/AccountScheme/DELETE_ACCOUNT_SCHEME_ROW_ERROR'
export const DELETE_ACCOUNT_SCHEME_ROW_SUCCESS =
  'app/AccountScheme/DELETE_ACCOUNT_SCHEME_ROW_SUCCESS'

export const GET_ACCOUNT_SCHEME = 'app/AccountScheme/GET_ACCOUNT_SCHEME'
export const GET_ACCOUNT_SCHEME_ERROR =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_ERROR'
export const GET_ACCOUNT_SCHEME_SUCCESS =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_SUCCESS'

export const GET_ACCOUNT_SCHEME_AND_TREE =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_AND_TREE'
export const GET_ACCOUNT_SCHEME_AND_TREE_ERROR =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_AND_TREE_ERROR'
export const GET_ACCOUNT_SCHEME_AND_TREE_SUCCESS =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_AND_TREE_SUCCESS'

export const GET_ACCOUNT_SCHEME_TREE =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_TREE'
export const GET_ACCOUNT_SCHEME_TREE_ERROR =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_TREE_ERROR'
export const GET_ACCOUNT_SCHEME_TREE_SUCCESS =
  'app/AccountScheme/GET_ACCOUNT_SCHEME_TREE_SUCCESS'

export const MOVE_ACCOUNT_SCHEME_ROW =
  'app/AccountScheme/MOVE_ACCOUNT_SCHEME_ROW'
export const MOVE_ACCOUNT_SCHEME_ROW_ERROR =
  'app/AccountScheme/MOVE_ACCOUNT_SCHEME_ROW_ERROR'
export const MOVE_ACCOUNT_SCHEME_ROW_SUCCESS =
  'app/AccountScheme/MOVE_ACCOUNT_SCHEME_ROW_SUCCESS'

export const SET_ACCOUNT_SCHEME_ACTIVE_ROW =
  'app/AccountScheme/SET_ACCOUNT_SCHEME_ACTIVE_ROW'

export const SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT =
  'app/AccountScheme/SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT'
export const SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR =
  'app/AccountScheme/SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR'
export const SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS =
  'app/AccountScheme/SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS'

export const TOGGLE_ACCOUNT_SCHEME_SHOW_SETTINGS =
  'app/AccountScheme/TOGGLE_ACCOUNT_SCHEME_SHOW_SETTINGS'

export const UPDATE_ACCOUNT_SCHEME_ROW =
  'app/AccountScheme/UPDATE_ACCOUNT_SCHEME_ROW'
export const UPDATE_ACCOUNT_SCHEME_ROW_ERROR =
  'app/AccountScheme/UPDATE_ACCOUNT_SCHEME_ROW_ERROR'
export const UPDATE_ACCOUNT_SCHEME_ROW_SUCCESS =
  'app/AccountScheme/UPDATE_ACCOUNT_SCHEME_ROW_SUCCESS'

export const ROW_FACTOR_POSITIVE = 1
export const ROW_FACTOR_NEGATIVE = -1
export const ROW_FACTORS = [ROW_FACTOR_POSITIVE, ROW_FACTOR_NEGATIVE]

export const AccountGroupTypes = [
  {
    text: 'Undefined',
    type: 'Undefined',
  },
  {
    text: 'Assets',
    type: 'Assets',
  },
  {
    text: 'Liabilities',
    type: 'Liabilities',
  },
  {
    text: 'Profit And Loss',
    type: 'ProfitAndLoss',
  },
  {
    text: 'Subtotal',
    type: 'Subtotal',
  },
]

export const ConcernAccount = [
  {
    name: 'Regular Account',
    value: false,
  },
  {
    name: 'Concern Account',
    value: true,
  },
]
