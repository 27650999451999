import { isNumber, isString } from 'lodash'

/**
 *  Checks if value matches to enum value or enum name. This works for "array enums"
 *
 * @param {string|number} value
 * @param {Array<string>} enumeration
 * @param {string} enumToMatch
 */
export const isValueEnumMatch = (value, enumeration, enumToMatch) =>
  value === enumToMatch || value === enumeration.indexOf(enumToMatch)

export const enumValueToEnumName = (value, enumeration) => {
  if (isNumber(value) === false) {
    throw new Error(`Value is not a number: ${value}`)
  }

  if (value >= enumeration.length) {
    throw new Error(`Enum value out of bounds: ${value}`)
  }

  return enumeration[value]
}

export const enumNameToEnumValue = (name, enumeration) => {
  if (!name || isString(name) === false) {
    throw new Error(`Name is not valid: ${name}`)
  }

  const enumValue = enumeration.indexOf(name)
  if (enumValue < 0) {
    throw new Error(`Could not find value with name ${name}`)
  }
  return enumValue
}
