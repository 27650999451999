/*
 *
 * RollingRule reducer
 *
 */

import { fromJS } from 'immutable'
import patch from 'immpatch'

import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_ERROR,
  GET_ACCOUNTS_SUCCESS,
} from 'containers/Accounts/constants'
import {
  createRollingRuleTree,
  createRollingRuleData,
} from 'containers/RollingRules/utils'

import {
  ADD_ACCOUNT_RANGE,
  ADD_ACCOUNT_RANGE_ERROR,
  ADD_ACCOUNT_RANGE_SUCCESS,
  GET_ROLLING_RULE_TREE_AND_DATA,
  GET_ROLLING_RULE_TREE_AND_DATA_ERROR,
  GET_ROLLING_RULE_TREE_AND_DATA_SUCCESS,
  REPLACE_ACCOUNT_RANGES,
  REPLACE_ACCOUNT_RANGES_ERROR,
  REPLACE_ACCOUNT_RANGES_SUCCESS,
  TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM,
  UPDATE_ROLLING_RULE_DATA,
  UPDATE_ROLLING_RULE_DATA_ERROR,
  UPDATE_ROLLING_RULE_DATA_SUCCESS,
} from './constants'

const initialState = fromJS({
  loading: false,
  error: false,
  showAddAccountRangeForm: false,
  rollingRule: {
    tree: undefined,
    data: undefined,
    loading: false,
    error: false,
  },
  accounts: {
    loading: false,
    error: false,
  },
})

const rollingRuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return state
        .setIn(['accounts', 'loading'], true)
        .setIn(['accounts', 'error'], false)

    case UPDATE_ROLLING_RULE_DATA:
    case GET_ROLLING_RULE_TREE_AND_DATA:
      return state
        .setIn(['rollingRule', 'loading'], true)
        .setIn(['rollingRule', 'error'], false)

    case REPLACE_ACCOUNT_RANGES:
    case ADD_ACCOUNT_RANGE:
      return state.set('loading', true).set('error', false)

    case GET_ROLLING_RULE_TREE_AND_DATA_ERROR:
    case UPDATE_ROLLING_RULE_DATA_ERROR:
      return state
        .setIn(['rollingRule', 'loading'], false)
        .setIn(['rollingRule', 'error'], action.error)

    case GET_ACCOUNTS_ERROR:
      return state
        .setIn(['accounts', 'loading'], false)
        .setIn(['accounts', 'error'], action.error)

    case REPLACE_ACCOUNT_RANGES_ERROR:
    case ADD_ACCOUNT_RANGE_ERROR:
      return state.set('loading', false).set('error', action.error)

    case TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM:
      return state.set(
        'showAddAccountRangeForm',
        !state.get('showAddAccountRangeForm')
      )

    case GET_ACCOUNTS_SUCCESS:
      return state.setIn(['accounts', 'loading'], false)

    case REPLACE_ACCOUNT_RANGES_SUCCESS:
      return state.set('loading', false)

    case UPDATE_ROLLING_RULE_DATA_SUCCESS:
      return state
        .setIn(['rollingRule', 'loading'], false)
        .setIn(
          ['rollingRule', 'data'],
          patch(state.getIn(['rollingRule', 'data']), action.data)
        )

    case GET_ROLLING_RULE_TREE_AND_DATA_SUCCESS:
      return state
        .setIn(['rollingRule', 'loading'], false)
        .setIn(['rollingRule', 'tree'], createRollingRuleTree(action.tree))
        .setIn(['rollingRule', 'data'], createRollingRuleData(action.data))

    case ADD_ACCOUNT_RANGE_SUCCESS:
      return state.set('loading', false).set('showAddAccountRangeForm', false)

    default:
      return state
  }
}

export default rollingRuleReducer
