import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import bankAccountApi from 'api/BankAccountApi'

import {
  getBankAccountsError,
  getBankAccountsSuccess,
  saveBankAccountsError,
  saveBankAccountsSuccess,
} from './actions'
import { GET_BANK_ACCOUNTS, SAVE_BANK_ACCOUNTS } from './constants'

export function* getBankAccounts(action) {
  const { companyCode } = action
  try {
    const bankAccounts = yield call(bankAccountApi.getBankAccounts, {
      companyCode,
    })
    yield put(getBankAccountsSuccess({ bankAccounts, companyCode }))
  } catch (error) {
    yield put(
      getBankAccountsError({
        error,
      })
    )
  }
}

export function* saveBankAccounts(action) {
  const { companyCode, selectedAccountIds } = action
  try {
    const selectedBankAccounts = yield call(bankAccountApi.saveBankAccounts, {
      companyCode,
      selectedAccountIds,
    })
    yield put(saveBankAccountsSuccess({ selectedBankAccounts, companyCode }))
  } catch (error) {
    yield put(
      saveBankAccountsError({
        error,
      })
    )
  }
}

export function* bankAccountSagas() {
  yield all([
    takeEvery(GET_BANK_ACCOUNTS, getBankAccounts),
    takeLatest(SAVE_BANK_ACCOUNTS, saveBankAccounts),
  ])
}

export default bankAccountSagas
