/*
 *
 * SubBudget constants
 *
 */
export const BEGIN_ADD_SUB_BUDGET_ROW = 'app/SubBudgetRowModal/BEGIN_ADD_ROW'

export const GET_SUB_BUDGET = 'app/SubBudget/GET_SUB_BUDGET'
export const GET_SUB_BUDGET_ERROR = 'app/SubBudget/GET_SUB_BUDGET_ERROR'
export const GET_SUB_BUDGET_SUCCESS = 'app/SubBudget/GET_SUB_BUDGET_SUCCESS'

export const GET_SUB_BUDGET_DATA = 'app/SubBudget/GET_SUB_BUDGET_DATA'
export const GET_SUB_BUDGET_DATA_ERROR =
  'app/SubBudget/GET_SUB_BUDGET_DATA_ERROR'
export const GET_SUB_BUDGET_DATA_SUCCESS =
  'app/SubBudget/GET_SUB_BUDGET_DATA_SUCCESS'

export const GET_SUB_BUDGET_TREE = 'app/SubBudget/GET_SUB_BUDGET_TREE'
export const GET_SUB_BUDGET_TREE_ERROR =
  'app/SubBudget/GET_SUB_BUDGET_TREE_ERROR'
export const GET_SUB_BUDGET_TREE_SUCCESS =
  'app/SubBudget/GET_SUB_BUDGET_TREE_SUCCESS'

export const GET_SUB_BUDGET_IMPORT_DATA =
  'app/SubBudget/GET_SUB_BUDGET_IMPORT_DATA'
export const GET_SUB_BUDGET_IMPORT_DATA_SUCCESS =
  'app/SubBudget/GET_SUB_BUDGET_IMPORT_DATA_SUCCESS'
export const GET_SUB_BUDGET_IMPORT_DATA_ERROR =
  'app/SubBudget/GET_SUB_BUDGET_IMPORT_DATA_ERROR'

export const UPDATE_SUB_BUDGET_CELL = 'app/SubBudget/UPDATE_SUB_BUDGET_CELL'
export const UPDATE_SUB_BUDGET_CELL_ERROR =
  'app/SubBudget/UPDATE_SUB_BUDGET_CELL_ERROR'
export const UPDATE_SUB_BUDGET_CELL_PATCH =
  'app/SubBudget/UPDATE_SUB_BUDGET_CELL_PATCH'
export const UPDATE_SUB_BUDGET_CELL_SUCCESS =
  'app/SubBudget/UPDATE_SUB_BUDGET_CELL_SUCCESS'

export const UPDATE_SUB_BUDGET_STATUS = 'app/SubBudget/UPDATE_SUB_BUDGET_STATUS'
export const UPDATE_SUB_BUDGET_STATUS_ERROR =
  'app/SubBudget/UPDATE_SUB_BUDGET_STATUS_ERROR'

export const EDIT_SUB_BUDGET_ROW = 'app/SubBudget/EDIT_SUB_BUDGET_ROW'

export const REMOVE_SUB_BUDGET_ROW = 'app/SubBudget/REMOVE_SUB_BUDGET_ROW'
export const REMOVE_SUB_BUDGET_ROW_ERROR =
  'app/SubBudget/REMOVE_SUB_BUDGET_ROW_ERROR'
export const REMOVE_SUB_BUDGET_ROW_SUCCESS =
  'app/SubBudget/REMOVE_SUB_BUDGET_ROW_SUCCESS'

export const MOVE_SUB_BUDGET_ROW = 'app/SubBudget/MOVE_SUB_BUDGET_ROW'
export const MOVE_SUB_BUDGET_ROW_ERROR =
  'app/SubBudget/MOVE_SUB_BUDGET_ROW_ERROR'
export const MOVE_SUB_BUDGET_ROW_SUCCESS =
  'app/SubBudget/MOVE_SUB_BUDGET_ROW_SUCCESS'

export const RESET_ALL_SUBBUDGETS = 'app/SubBudget/RESET_ALL_SUBBUDGETS'

export const ROW_POSITION_ABOVE = 'ROW_POSITION_ABOVE'
export const ROW_POSITION_BELOW = 'ROW_POSITION_BELOW'
export const ROW_POSITION_INSIDE = 'ROW_POSITION_INSIDE'

export const SHOW_SUB_BUDGET_ROW_NOTES =
  'app/SubBudget/SHOW_SUB_BUDGET_ROW_NOTES'

export const SHOW_SUB_BUDGET_IMPORT_MODAL =
  'app/SubBudget/SHOW_SUB_BUDGET_IMPORT_MODAL'

export const CLOSE_SUB_BUDGET_IMPORT_MODAL =
  'app/SubBudget/CLOSE_SUB_BUDGET_IMPORT_MODAL'

export const CLEAR_SUB_BUDGET_DATA = 'app/SubBudget/CLEAR_SUB_BUDGET_DATA'
