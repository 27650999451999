/*
 *
 * Customer reducer
 *
 */

import { fromJS } from 'immutable'

import {
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
} from './constants'

const initialState = fromJS({
  error: false,
  loading: false,
})

function customerReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER:
      return state.set('loading', true)

    case UPDATE_CUSTOMER_SUCCESS:
      return state.set('loading', false)

    case UPDATE_CUSTOMER_ERROR:
      return state.set('loading', false).set('error', action.error)

    default:
      return state
  }
}

export default customerReducer
