import { all, call, put, takeEvery } from 'redux-saga/effects'

import {
  createRoleClaimError,
  createRoleClaimSuccess,
  deleteRoleClaimError,
  deleteRoleClaimSuccess,
  getRoleClaimsError,
  getRoleClaimsSuccess,
} from './actions'
import {
  CREATE_ROLE_CLAIM,
  DELETE_ROLE_CLAIM,
  GET_ROLE_CLAIMS,
} from './constants'
import { handleError } from 'api/api-utils'
import roleClaimsApi from 'api/RoleClaimsApi'

export function* getRoleClaims(action) {
  const { roleId } = action
  try {
    const response = yield call(roleClaimsApi.getRoleClaims, {
      roleId,
    })
    yield put(getRoleClaimsSuccess({ claims: response.claims, roleId }))
  } catch (error) {
    yield put(handleError(error, getRoleClaimsError))
  }
}
export function* createRoleClaims(action) {
  const { role, claims } = action
  try {
    const response = yield call(roleClaimsApi.createRoleClaims, {
      roleId: role.id,
      claims,
    })
    yield put(
      createRoleClaimSuccess({
        roleId: response.roleId,
        claims: response.claims,
      })
    )
  } catch (error) {
    yield put(handleError(error, createRoleClaimError))
  }
}
export function* deleteRoleClaim(action) {
  const { role, claims } = action
  try {
    const response = yield call(roleClaimsApi.deleteRoleClaims, {
      roleId: role.id,
      claims,
    })
    yield put(
      deleteRoleClaimSuccess({
        roleId: response.roleId,
        claims: response.claims,
      })
    )
  } catch (error) {
    yield put(handleError(error, deleteRoleClaimError))
  }
}

export function* roleSaga() {
  yield all([
    takeEvery(GET_ROLE_CLAIMS, getRoleClaims),
    takeEvery(CREATE_ROLE_CLAIM, createRoleClaims),
    takeEvery(DELETE_ROLE_CLAIM, deleteRoleClaim),
  ])
}

// All sagas to be loaded
export default roleSaga
