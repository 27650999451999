import { createSelector } from 'reselect'
import selectCompanies from 'containers/Companies/selectors'

import {
  selectAllContents,
  selectAllContentTargetStatuses,
} from 'containers/Content/selectors'
import { getDataKey } from 'containers/Content/functions'
import { CONTENT_TYPE_COMPANY_INFO } from 'containers/Content/constants'

/**
 * Direct selector to the company state domain
 */
const selectCompanyDomain = (state) => state.get('editCompany')

/**
 * Other specific selectors
 */

const companyFilter = (state, props) =>
  props.params?.companyCode || props.companyCode

const selectScheduledIntegrationError = () =>
  createSelector(
    selectCompanyDomain,
    (company) => company.get('scheduledIntegrationError')
  )

const selectScheduledIntegrationLoading = () =>
  createSelector(
    selectCompanyDomain,
    (company) => company.get('scheduledIntegrationsLoading')
  )

const selectLogGroupsLoading = createSelector(
  selectCompanyDomain,
  (company) => company.get('logGroupsLoading')
)

const selectLinksLoading = () =>
  createSelector(
    [selectCompanyDomain],
    (substate) => substate.get('linksLoading')
  )

const selectCompany = createSelector(
  [companyFilter, selectCompanies],
  (companyCode, companies) => {
    if (companyCode && companies) {
      return companies.find((c) => c.code === companyCode)
    }

    return null
  }
)
const selectSelectedCompanyAccountNumberMax = createSelector(
  [selectCompany],
  (company) => (company && company.get('accountNumMAX')) || 999999
)
const selectSelectedCompanyAccountNumberMin = createSelector(
  [selectCompany],
  (company) => (company && company.get('accountNumMIN')) || 1000
)
const selectSelectedCompanyAllowLettersInAccountNumbers = createSelector(
  [selectCompany],
  (company) => (company && company.get('allowLettersInAccountNumbers')) || false
)
const selectLoading = createSelector(
  selectCompanyDomain,
  (company) => company.get('loading')
)

const selectCompanyDataLoading = createSelector(
  selectCompanyDomain,
  (company) => company.get('companyDataLoading')
)
const selectError = createSelector(
  selectCompanyDomain,
  (company) => company.get('error')
)

const selectRecalculatingCompanyBalances = () =>
  createSelector(
    selectCompanyDomain,
    (company) => company.get('recalculatingCompanyBalances')
  )

const selectSelectedAccountingPeriod = createSelector(
  selectCompanyDomain,
  (company) => company.get('selectedAccountingPeriod')
)

const selectFileUploadError = createSelector(
  selectCompanyDomain,
  (company) => company.get('fileUploadError')
)

const selectScheduledIntegrations = createSelector(
  selectCompanyDomain,
  (company) => company.get('scheduledIntegrations')
)

const selectFileUploadSuccess = createSelector(
  selectCompanyDomain,
  (company) => company.get('fileUploadResponse')
)

const selectFileUploadLoading = createSelector(
  selectCompanyDomain,
  (company) => company.get('fileUploadLoading')
)

const selectDataGroups = createSelector(
  selectCompanyDomain,
  (company) => company && company.get('dataGroups')
)

const selectDataGroupsLoading = () =>
  createSelector(
    selectCompanyDomain,
    (company) => company.get('dataGroups')
  )

const selectLogGroups = createSelector(
  selectCompanyDomain,
  (company) =>
    company.get('logGroups') &&
    company
      .get('logGroups')
      .sortBy((grp) => grp.created)
      .reverse()
)

const selectLogItemsByGroups = createSelector(
  selectCompanyDomain,
  (company) => company.get('logItemsByGroup')
)

const selectAdditionalCompanyInfo = createSelector(
  [selectAllContents(), selectCompany],
  (content, company) => {
    const companyId = !!company ? company.get('id') : undefined
    const key = getDataKey(CONTENT_TYPE_COMPANY_INFO, companyId)
    // key contains a List, with only one value.
    return content.has(key) ? content.get(key).get(0) : undefined
  }
)

const selectAdditionalCompanyInfoStatus = createSelector(
  [selectAllContentTargetStatuses, selectCompany],
  (statuses, company) => {
    const companyId = !!company ? company.get('id') : undefined
    const key = getDataKey(CONTENT_TYPE_COMPANY_INFO, companyId)
    return statuses.has(key) ? statuses.get(key) : undefined
  }
)

export default selectCompany
export {
  companyFilter,
  selectSelectedCompanyAccountNumberMax,
  selectSelectedCompanyAccountNumberMin,
  selectSelectedCompanyAllowLettersInAccountNumbers,
  selectCompanyDomain,
  selectCompanyDataLoading,
  selectLoading,
  selectError,
  selectRecalculatingCompanyBalances,
  selectSelectedAccountingPeriod,
  selectScheduledIntegrations,
  selectFileUploadLoading,
  selectFileUploadSuccess,
  selectFileUploadError,
  selectDataGroups,
  selectDataGroupsLoading,
  selectLogGroups,
  selectLogItemsByGroups,
  selectAdditionalCompanyInfo,
  selectAdditionalCompanyInfoStatus,
  selectLinksLoading,
  selectScheduledIntegrationError,
  selectScheduledIntegrationLoading,
  selectLogGroupsLoading,
}
