import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getReportingGroups = ({ companyCode }) =>
  axios
    .get(`${companyCode}/reportinggroups`, config())
    .then(returnData)
    .catch(catchError)

const createReportingGroup = ({ companyCode, reportingGroup }) =>
  axios
    .post(`${companyCode}/reportinggroups`, reportingGroup, config())
    .then(returnData)
    .catch(catchError)

const deleteReportingGroup = ({ companyCode, id }) =>
  axios
    .delete(`${companyCode}/reportinggroups/${id}`, config())
    .then(returnData)
    .catch(catchError)

const patchReportingGroup = ({ companyCode, id, patch }) =>
  axios
    .patch(`${companyCode}/reportinggroups/${id}`, patch, config())
    .then(returnData)
    .catch(catchError)

const addDimension = ({ companyCode, reportingGroupId, dimensionId }) =>
  axios
    .post(
      `${companyCode}/reportinggroups/${reportingGroupId}/dimensions/${dimensionId}`,
      {},
      config()
    )
    .then(returnData)
    .catch(catchError)

const addDimensionValue = ({
  companyCode,
  reportingGroupId,
  dimensionValueId,
}) =>
  axios
    .post(
      `${companyCode}/reportinggroups/${reportingGroupId}/dimensionvalues/${dimensionValueId}`,
      {},
      config()
    )
    .then(returnData)
    .catch(catchError)

const removeDimension = ({ companyCode, reportingGroupId, dimensionId }) =>
  axios
    .delete(
      `${companyCode}/reportinggroups/${reportingGroupId}/dimensions/${dimensionId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const removeDimensionValue = ({
  companyCode,
  reportingGroupId,
  dimensionValueId,
}) =>
  axios
    .delete(
      `${companyCode}/reportinggroups/${reportingGroupId}/dimensionvalues/${dimensionValueId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const reportingGroupApi = {
  addDimension,
  addDimensionValue,
  createReportingGroup,
  deleteReportingGroup,
  getReportingGroups,
  patchReportingGroup,
  removeDimension,
  removeDimensionValue,
}

export default reportingGroupApi
