/*
 *
 * Company constants
 *
 */

export const RECALCULATE_COMPANY_BALANCES =
  'app/Company/RECALCULATE_COMPANY_BALANCES'
export const RECALCULATE_COMPANY_BALANCES_SUCCESS =
  'app/Company/RECALCULATE_COMPANY_BALANCES_SUCCESS'
export const RECALCULATE_COMPANY_BALANCES_ERROR =
  'app/Company/RECALCULATE_COMPANY_BALANCES_ERROR'
export const UPDATE_COMPANY = 'app/Company/UPDATE_COMPANY'
export const UPDATE_COMPANY_SUCCESS = 'app/Company/UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_ERROR = 'app/Company/UPDATE_COMPANY_ERROR'

export const INTEGRATION_FILE_UPLOAD = 'app/Company/INTEGRATION_FILE_UPLOAD'
export const INTEGRATION_FILE_UPLOAD_SUCCESS =
  'app/Company/INTEGRATION_FILE_UPLOAD_SUCCESS'
export const INTEGRATION_FILE_UPLOAD_ERROR =
  'app/Company/INTEGRATION_FILE_UPLOAD_ERROR'
export const GET_USED_DATA_GROUPS = 'app/Company/GET_USED_DATA_GROUPS'
export const GET_USED_DATA_GROUPS_SUCCESS =
  'app/Company/GET_USED_DATA_GROUPS_SUCCESS'
export const ADD_USED_DATA_GROUP = 'app/Company/ADD_USED_DATA_GROUP'
export const GET_USED_DATA_GROUPS_ERROR =
  'app/Company/GET_USED_DATA_GROUPS_ERROR'
export const GET_INTEGRATION_LOG_GROUPS =
  'app/Company/GET_INTEGRATION_LOG_GROUPS'
export const GET_INTEGRATION_LOG_FOR_GROUPS =
  'app/Company/GET_INTEGRATION_LOG_FOR_GROUPS'
export const GET_INTEGRATION_LOG_GROUPS_SUCCESS =
  'app/Company/GET_INTEGRATION_LOG_GROUPS_SUCCESS'
export const GET_INTEGRATION_LOG_FOR_GROUPS_SUCCESS =
  'app/Company/GET_INTEGRATION_LOG_FOR_GROUPS_SUCCESS'
export const GET_SCHEDULED_INTEGRATIONS =
  'app/Company/GET_SCHEDULED_INTEGRATIONS'
export const GET_SCHEDULED_INTEGRATIONS_ERROR =
  'app/Company/GET_SCHEDULED_INTEGRATIONS_ERROR'
export const GET_SCHEDULED_INTEGRATIONS_SUCCESS =
  'app/Company/GET_SCHEDULED_INTEGRATIONS_SUCCESS'

export const DELETE_INVOICES = 'app/Company/DELETE_INVOICES'
export const DELETE_INVOICES_SUCCESS = 'app/Company/DELETE_INVOICES_SUCCESS'
export const DELETE_INVOICES_ERROR = 'app/Company/DELETE_INVOICES_ERROR'

export const DELETE_OPERATIVE_DATA = 'app/Company/DELETE_OPERATIVE_DATA'
export const DELETE_OPERATIVE_DATA_SUCCESS =
  'app/Company/DELETE_OPERATIVE_DATA_SUCCESS'
export const DELETE_OPERATIVE_DATA_ERROR =
  'app/Company/DELETE_OPERATIVE_DATA_ERROR'

export const DELETE_VOUCHERS = 'app/Company/DELETE_VOUCHERS'
export const DELETE_VOUCHERS_SUCCESS = 'app/Company/DELETE_VOUCHERS_SUCCESS'
export const DELETE_VOUCHERS_ERROR = 'app/Company/DELETE_VOUCHERS_ERROR'

export const INTEGRATION_FILE_UPLOAD_RESET =
  'app/Company/INTEGRATION_FILE_UPLOAD_RESET'

export const UPDATE_COMPANY_LINKS = 'app/Company/UPDATE_COMPANY_LINKS'
export const UPDATE_COMPANY_LINKS_SUCCESS =
  'app/Company/UPDATE_COMPANY_LINKS_SUCCESS'
export const UPDATE_COMPANY_LINKS_ERROR =
  'app/Company/UPDATE_COMPANY_LINKS_ERROR'
export const TRIGGER_SCHEDULED_INTEGRATION =
  'app/Company/TRIGGER_SCHEDULED_INTEGRATION'
export const TRIGGER_SCHEDULED_INTEGRATION_SUCCESS =
  'app/Company/TRIGGER_SCHEDULED_INTEGRATION_SUCCESS'
export const TRIGGER_SCHEDULED_INTEGRATION_ERROR =
  'app/Company/TRIGGER_SCHEDULED_INTEGRATION_ERROR'
