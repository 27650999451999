/*
 *
 * AccountSchemeTemplates actions
 *
 */

import {
  CREATE_ACCOUNT_SCHEME_TEMPLATE,
  CREATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  CREATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  DELETE_ACCOUNT_SCHEME_TEMPLATE,
  DELETE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  DELETE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  EDIT_ACCOUNT_SCHEME_TEMPLATE,
  GET_ACCOUNT_SCHEME_TEMPLATES,
  GET_ACCOUNT_SCHEME_TEMPLATES_ERROR,
  GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS,
  TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  COPY_ACCOUNT_SCHEME_TEMPLATE,
  COPY_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  COPY_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
} from './constants'

export const createAccountSchemeTemplate = ({
  companyCode,
  customerCode,
  name,
}) => ({
  type: CREATE_ACCOUNT_SCHEME_TEMPLATE,
  companyCode,
  customerCode,
  name,
})
export const createAccountSchemeTemplateError = (error) => ({
  type: CREATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  error,
})
export const createAccountSchemeTemplateSuccess = ({
  accountSchemeTemplate,
}) => ({ type: CREATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS, accountSchemeTemplate })

export const copyAccountSchemeTemplate = ({
  customerCode,
  companyCode,
  accountSchemeTemplateId,
  newName,
}) => ({
  type: COPY_ACCOUNT_SCHEME_TEMPLATE,
  customerCode,
  companyCode,
  accountSchemeTemplateId,
  newName,
})

export const copyAccountSchemeTemplateError = () => ({
  type: COPY_ACCOUNT_SCHEME_TEMPLATE_ERROR,
})

export const copyAccountSchemeTemplateSuccess = () => ({
  type: COPY_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
})

export const deleteAccountSchemeTemplate = ({
  companyCode,
  customerCode,
  id,
}) => ({ type: DELETE_ACCOUNT_SCHEME_TEMPLATE, companyCode, customerCode, id })
export const deleteAccountSchemeTemplateError = (error) => ({
  type: DELETE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  error,
})
export const deleteAccountSchemeTemplateSuccess = ({ id }) => ({
  type: DELETE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  id,
})

export const editAccountSchemeTemplate = ({ id }) => ({
  type: EDIT_ACCOUNT_SCHEME_TEMPLATE,
  id,
})

export const getAccountSchemeTemplates = ({ companyCode, customerCode }) => ({
  type: GET_ACCOUNT_SCHEME_TEMPLATES,
  companyCode,
  customerCode,
})
export const getAccountSchemeTemplatesError = (error) => ({
  type: GET_ACCOUNT_SCHEME_TEMPLATES_ERROR,
  error,
})
export const getAccountSchemeTemplatesSuccess = ({
  accountSchemeTemplates,
}) => ({ type: GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS, accountSchemeTemplates })

export const toggleShowAccountSchemeTemplateForm = () => ({
  type: TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM,
})

export const updateAccountSchemeTemplate = ({
  companyCode,
  customerCode,
  id,
  name,
}) => ({
  type: UPDATE_ACCOUNT_SCHEME_TEMPLATE,
  companyCode,
  customerCode,
  id,
  name,
})
export const updateAccountSchemeTemplateError = (error) => ({
  type: UPDATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  error,
})
export const updateAccountSchemeTemplateSuccess = ({
  accountSchemeTemplate,
}) => ({ type: UPDATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS, accountSchemeTemplate })
