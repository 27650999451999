/*
 *
 * Companies reducer
 *
 */

import { fromJS } from 'immutable'

import {
  TOGGLE_SHOW_CREATE_COMPANY_FORM,
  CREATE_COMPANY,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
} from './constants'

const initialState = fromJS({
  error: false,
  loading: false,
  showCreateCompanyForm: false,
  deleteIntegrationError: false,
  deleteIntegrationLoading: false,
})

function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_COMPANY_SUCCESS: {
      return state.set('showCreateCompanyForm', false).set('loading', false)
    }
    case DELETE_COMPANY_SUCCESS:
      return state.set('loading', false)

    case TOGGLE_SHOW_CREATE_COMPANY_FORM:
      return state.set(
        'showCreateCompanyForm',
        !state.get('showCreateCompanyForm')
      )
    case DELETE_COMPANY:
      return state.set('loading', true).set('error', false)

    case CREATE_COMPANY:
      return state.set('error', false)

    case DELETE_COMPANY_ERROR:
    case CREATE_COMPANY_ERROR:
      return state.set('loading', false).set('error', action.error)

    default:
      return state
  }
}

export default companiesReducer
