import {
  CREATE_ACCOUNTING_PERIOD,
  CREATE_ACCOUNTING_PERIOD_ERROR,
  CREATE_ACCOUNTING_PERIOD_SUCCESS,
  DELETE_ACCOUNTING_PERIOD,
  DELETE_ACCOUNTING_PERIOD_ERROR,
  DELETE_ACCOUNTING_PERIOD_SUCCESS,
  EDIT_ACCOUNTING_PERIOD,
  GET_ACCOUNTING_PERIODS,
  GET_ACCOUNTING_PERIODS_SUCCESS,
  GET_ACCOUNTING_PERIODS_ERROR,
  SET_ACTIVE_ACCOUNTING_PERIOD,
  SET_ACTIVE_ACCOUNTING_PERIOD_ERROR,
  SET_ACTIVE_ACCOUNTING_PERIOD_SUCCESS,
  UPDATE_ACCOUNTING_PERIOD,
  UPDATE_ACCOUNTING_PERIOD_ERROR,
  UPDATE_ACCOUNTING_PERIOD_SUCCESS,
} from './constants'

export const createAccountingPeriod = ({ end, companyCode, name, start }) => ({
  type: CREATE_ACCOUNTING_PERIOD,
  end,
  companyCode,
  name,
  start,
})
export const createAccountingPeriodSuccess = ({
  accountingPeriod,
  companyCode,
}) => ({
  type: CREATE_ACCOUNTING_PERIOD_SUCCESS,
  accountingPeriod,
  companyCode,
})
export const createAccountingPeriodError = (error) => ({
  type: CREATE_ACCOUNTING_PERIOD_ERROR,
  error,
})

export const deleteAccountingPeriod = ({ id, companyCode }) => ({
  type: DELETE_ACCOUNTING_PERIOD,
  id,
  companyCode,
})
export const deleteAccountingPeriodSuccess = ({ id, companyCode }) => ({
  type: DELETE_ACCOUNTING_PERIOD_SUCCESS,
  id,
  companyCode,
})
export const deleteAccountingPeriodError = (error) => ({
  type: DELETE_ACCOUNTING_PERIOD_ERROR,
  error,
})

export const getAccountingPeriods = ({ companyCode }) => ({
  type: GET_ACCOUNTING_PERIODS,
  companyCode,
})
export const getAccountingPeriodsSuccess = ({
  accountingPeriods,
  companyCode,
}) => ({
  type: GET_ACCOUNTING_PERIODS_SUCCESS,
  accountingPeriods,
  companyCode,
})
export const getAccountingPeriodsError = (error) => ({
  type: GET_ACCOUNTING_PERIODS_ERROR,
  error,
})

export const editAccountingPeriod = ({ id }) => ({
  type: EDIT_ACCOUNTING_PERIOD,
  id,
})

export const setActiveAccountingPeriod = ({ companyCode, id }) => ({
  type: SET_ACTIVE_ACCOUNTING_PERIOD,
  companyCode,
  id,
})
export const setActiveAccountingPeriodError = (error) => ({
  type: SET_ACTIVE_ACCOUNTING_PERIOD_ERROR,
  error,
})
export const setActiveAccountingPeriodSuccess = ({ companyCode, id }) => ({
  type: SET_ACTIVE_ACCOUNTING_PERIOD_SUCCESS,
  companyCode,
  id,
})

export const updateAccountingPeriod = ({
  companyCode,
  accountingPeriod,
  updatedValues,
}) => ({
  type: UPDATE_ACCOUNTING_PERIOD,
  companyCode,
  accountingPeriod,
  updatedValues,
})
export const updateAccountingPeriodSuccess = ({
  accountingPeriod,
  companyCode,
}) => ({
  type: UPDATE_ACCOUNTING_PERIOD_SUCCESS,
  accountingPeriod,
  companyCode,
})
export const updateAccountingPeriodError = (error) => ({
  type: UPDATE_ACCOUNTING_PERIOD_ERROR,
  error,
})
