import axios from 'axios'
import { returnData, config, apiConfig } from './api-utils'

const login = (username, password, apiUrl = apiConfig.backendUrl) => {
  return axios
    .post(
      '/token',
      {
        username,
        password,
        grant_type: 'password',
        resource: apiUrl,
        scope: 'offline_access profile email',
      },
      config(
        {
          withCredentials: true,
          transformRequest: axios.defaults.transformRequest.concat(
            (stringData) => {
              const requestData = JSON.parse(stringData)
              return Object.keys(requestData).reduce((acc, key) => {
                if (acc.length > 0) acc += '&'
                acc += `${key}=${encodeURIComponent(requestData[key])}`
                return acc
              }, '')
            }
          ),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
        apiUrl
      )
    )
    .then((response) => returnData(response))
}
const logout = () => {
  axios
    .post('/token/logout', {}, config())
    .then((response) => returnData(response))
}
const refreshToken = (
  access_token,
  refresh_token,
  apiUrl = apiConfig.backendUrl
) => {
  return axios
    .post(
      '/token',
      {
        grant_type: 'refresh_token',
        refresh_token,
        scope: 'offline_access profile email',
      },
      config(
        {
          withCredentials: true,
          transformRequest: axios.defaults.transformRequest.concat(
            (stringData) => {
              const requestData = JSON.parse(stringData)
              return Object.keys(requestData).reduce((acc, key) => {
                if (acc.length > 0) acc += '&'
                acc += `${key}=${encodeURIComponent(requestData[key])}`
                return acc
              }, '')
            }
          ),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer: ${access_token}`,
          },
        },
        apiUrl
      )
    )
    .then((response) => returnData(response))
}
const refreshAccessToken = (refresh_token, apiUrl = apiConfig.backendUrl) => {
  return axios
    .post(
      '/token',
      {
        grant_type: 'refresh_token',
        refresh_token,
      },
      config(
        {
          transformRequest: axios.defaults.transformRequest.concat(
            (stringData) => {
              const requestData = JSON.parse(stringData)
              return Object.keys(requestData).reduce((acc, key) => {
                if (acc.length > 0) acc += '&'
                acc += `${key}=${encodeURIComponent(requestData[key])}`
                return acc
              }, '')
            }
          ),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
        apiUrl
      )
    )
    .then((response) => returnData(response))
}

const loginWithFinvoicerIdToken = (
  { token, challengeVerifier },
  apiUrl = apiConfig.backendUrl
) => {
  return axios
    .post(
      '/token',
      {
        token,
        code_verifier: challengeVerifier,
        grant_type: 'finvoicer_id',
        scope: 'offline_access profile email',
      },
      config(
        {
          withCredentials: true,
          transformRequest: axios.defaults.transformRequest.concat(
            (stringData) => {
              const requestData = JSON.parse(stringData)
              return Object.keys(requestData).reduce((acc, key) => {
                if (acc.length > 0) acc += '&'
                acc += `${key}=${encodeURIComponent(requestData[key])}`
                return acc
              }, '')
            }
          ),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
        apiUrl
      )
    )
    .then((response) => returnData(response))
}

const authApi = {
  login,
  logout,
  refreshAccessToken,
  refreshToken,
  loginWithFinvoicerIdToken,
}
export default authApi
