import { Map, List } from 'immutable'
import { createSelector } from 'reselect'

import { getDataKey } from './functions'

export const selectContentDomain = () => (state) =>
  state.get('content') || new Map()

export const selectAllContents = () =>
  createSelector(
    selectContentDomain(),
    (content) => content.get('contents') || new Map()
  )

export const selectType = (state, props) => props.type
export const selectTargetId = (state, props) => props.targetId

export const selectContents = createSelector(
  [selectAllContents(), selectType, selectTargetId],
  (contents, contentType, targetId) => {
    const key = getDataKey(contentType, targetId)
    return contents.has(key) ? contents.get(key) : new List()
  }
)

export const selectAllStatuses = createSelector(
  selectContentDomain(),
  (content) => content.get('statuses') || new Map()
)

export const selectAllContentTargetStatuses = createSelector(
  selectContentDomain(),
  (content) => content.get('contentTargetStatuses')
)

export const selectContentTargetStatus = createSelector(
  [selectAllContentTargetStatuses, selectType, selectTargetId],
  (contentTargetStatuses, contentType, targetId) =>
    contentTargetStatuses?.get(getDataKey(contentType, targetId))
)
