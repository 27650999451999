import { Record } from 'immutable'

const BudgetBalanceRecord = Record({
  accountNumber: '',
  amount: undefined,
  dimensionCode: '',
  dimensionValueCode: '',
  month: undefined,
  year: undefined,
})

export default BudgetBalanceRecord
