import { fromJS, Map, List } from 'immutable'
import { flatten } from 'lodash'

import {
  mapRecurringPaymentToRecord,
  mapRecurringPaymentPaymentEventToRecord,
  mapRecurringPaymentOccurenceToRecord,
} from './functions'
import {
  GET_RECURRING_PAYMENTS_SUCCESS,
  GET_RECURRING_PAYMENTS,
  GET_RECURRING_PAYMENTS_ERROR,
  CREATE_RECURRING_PAYMENT,
  CREATE_RECURRING_PAYMENT_SUCCESS,
  CREATE_RECURRING_PAYMENT_ERROR,
  DELETE_RECURRING_PAYMENT,
  DELETE_RECURRING_PAYMENT_SUCCESS,
  DELETE_RECURRING_PAYMENT_ERROR,
  UPDATE_RECURRING_PAYMENT,
  UPDATE_RECURRING_PAYMENT_SUCCESS,
  UPDATE_RECURRING_PAYMENT_ERROR,
  GET_RECURRING_PAYMENT_PAYMENT_EVENTS_SUCCESS,
  GET_RECURRING_PAYMENT_OCCURENCES_SUCCESS,
} from './constants'
import { arrayToMapWithLists } from 'utils/immutable'

const initialState = fromJS({
  recurringPayments: Map(),
  recurringPaymentOccurences: Map(),
  recurringPaymentPaymentEvents: Map(),
  loading: false,
})

export const recurringPaymentPaymentEventsReducer = (
  recurringPaymentPaymentEvents,
  recurringPayments,
  action
) => {
  switch (action.type) {
    case GET_RECURRING_PAYMENT_PAYMENT_EVENTS_SUCCESS: {
      // enhance recurring payment - payment event with recurring payment description
      const withRecurringPayments = (recurringPaymentPaymentEvent) => {
        const recurringPayment = recurringPayments.get(
          String(recurringPaymentPaymentEvent.recurringPaymentId)
        )
        return mapRecurringPaymentPaymentEventToRecord({
          ...recurringPaymentPaymentEvent,
          description: recurringPayment ? recurringPayment.description : '',
        })
      }

      return arrayToMapWithLists(
        action.recurringPaymentPaymentEvents,
        'recurringPaymentId',
        withRecurringPayments
      )
    }

    default:
      return recurringPaymentPaymentEvents
  }
}

export const recurringPaymentOccurencesReducer = (
  recurringPaymentOccurences,
  action
) => {
  switch (action.type) {
    case GET_RECURRING_PAYMENTS_SUCCESS: {
      const overriddenPayments = flatten(
        action.recurringPayments.map((rp) => rp.overriddenPayments)
      )
      return arrayToMapWithLists(
        overriddenPayments,
        'recurringPaymentId',
        mapRecurringPaymentOccurenceToRecord
      )
    }

    case GET_RECURRING_PAYMENT_OCCURENCES_SUCCESS:
      return recurringPaymentOccurences.set(
        String(action.recurringPaymentId),
        List(
          action.recurringPaymentOccurences.map(
            mapRecurringPaymentOccurenceToRecord
          )
        )
      )

    default:
      return recurringPaymentOccurences
  }
}

export const recurringPaymentsReducer = (recurringPayments, action) => {
  switch (action.type) {
    case GET_RECURRING_PAYMENTS_SUCCESS:
      return action.recurringPayments.reduce(
        (working, curr) =>
          working.set(String(curr.id), mapRecurringPaymentToRecord(curr)),
        Map()
      )

    case CREATE_RECURRING_PAYMENT_SUCCESS:
      return recurringPayments.set(
        String(action.recurringPayment.id),
        mapRecurringPaymentToRecord(action.recurringPayment)
      )

    case DELETE_RECURRING_PAYMENT_SUCCESS:
      return recurringPayments.delete(String(action.recurringPaymentId))

    case UPDATE_RECURRING_PAYMENT_SUCCESS:
      return recurringPayments.set(
        String(action.recurringPayment.id),
        mapRecurringPaymentToRecord(action.recurringPayment)
      )

    default:
      return recurringPayments
  }
}

export const loadingReducer = (loading, action) => {
  switch (action.type) {
    case GET_RECURRING_PAYMENTS:
    case CREATE_RECURRING_PAYMENT:
    case DELETE_RECURRING_PAYMENT:
    case UPDATE_RECURRING_PAYMENT:
      return true

    case GET_RECURRING_PAYMENTS_SUCCESS:
    case GET_RECURRING_PAYMENTS_ERROR:
    case CREATE_RECURRING_PAYMENT_SUCCESS:
    case CREATE_RECURRING_PAYMENT_ERROR:
    case DELETE_RECURRING_PAYMENT_SUCCESS:
    case DELETE_RECURRING_PAYMENT_ERROR:
    case UPDATE_RECURRING_PAYMENT_SUCCESS:
    case UPDATE_RECURRING_PAYMENT_ERROR:
      return false

    default:
      return loading
  }
}

export const recurringPaymentsRootReducer = (state = initialState, action) =>
  state
    .set(
      'recurringPaymentPaymentEvents',
      recurringPaymentPaymentEventsReducer(
        state.get('recurringPaymentPaymentEvents'),
        state.get('recurringPayments'),
        action
      )
    )
    .set(
      'recurringPayments',
      recurringPaymentsReducer(state.get('recurringPayments'), action)
    )
    .set(
      'recurringPaymentOccurences',
      recurringPaymentOccurencesReducer(
        state.get('recurringPaymentOccurences'),
        action
      )
    )
    .set('loading', loadingReducer(state.get('loading'), action))

export default recurringPaymentsRootReducer
