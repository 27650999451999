import axios from 'axios'
import { catchError, config, returnData } from './api-utils'
import { fetchEventSource } from '@microsoft/fetch-event-source'

const importBudgetBalance = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  onError,
  onMessage,
  onProgress,
  ...action
}) => {
  const configObj = config()
  fetchEventSource(
    configObj.baseURL +
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}`,
    {
      headers: { ...configObj.headers, 'Content-Type': 'application/json' },
      method: 'POST',
      openWhenHidden: true,
      body: JSON.stringify(action),
      onmessage: (event) => {
        if (event.event === 'progress' && onProgress) {
          onProgress(event)
          return
        }
        if (event.event === 'error' && onError) {
          onError(event)
          return
        }
        if (event.event === 'keepalive') {
          return
        }
        if (onMessage) {
          onMessage(event)
          return
        }
      },
      onerror: (error) => {
        if (onError) {
          onError(error)
        }
        // Event source library atomaticly retries if error is not thrown
        throw new Error(error)
      },
    }
  ).catch(() => {})
}

const importBudgetBalanceAllDimensions = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  onError,
  onMessage,
  onProgress,
  ...action
}) => {
  const configObj = config()
  fetchEventSource(
    configObj.baseURL +
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}/allDimensions`,
    {
      headers: { ...configObj.headers, 'Content-Type': 'application/json' },
      method: 'POST',
      openWhenHidden: true,
      body: JSON.stringify(action),
      onmessage: (event) => {
        if (event.event === 'progress' && onProgress) {
          onProgress(event)
          return
        }
        if (event.event === 'error' && onError) {
          onError(event)
          return
        }
        if (event.event === 'keepalive') {
          return
        }
        if (onMessage) {
          onMessage(event)
          return
        }
      },
      onerror: (error) => {
        if (onError) {
          onError(error)
        }
        // Event source library atomaticly retries if error is not thrown
        throw new Error(error)
      },
    }
  ).catch(() => {})
}

const getBudgetBalancesPreview = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  ...action
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}/preview`,
      action,
      config()
    )
    .then(returnData)
    .catch(catchError)

const getBudgetBalancesPreviewAllDimensions = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  preview,
  ...action
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}/allDimensions/preview`,
      action,
      config()
    )
    .then(returnData)
    .catch(catchError)

const budgetBalanceApi = {
  importBudgetBalance,
  importBudgetBalanceAllDimensions,
  getBudgetBalancesPreview,
  getBudgetBalancesPreviewAllDimensions,
}

export default budgetBalanceApi
