/*
 * Report Messages
 *
 * This contains all the text for the Report component.
 */
import { defineMessages } from 'react-intl'
import {
  REPORT_SELECTION_TYPE_CUSTOMER,
  REPORT_SELECTION_TYPE_SYSTEM,
} from './constants'

export default defineMessages({
  currencyCode: {
    id: 'app.containers.ReportSelections.currencyCode',
    defaultMessage: 'Select currency',
    description: "Field caption for setting report's currency",
  },
  currencyCodeInfo: {
    id: 'app.containers.ReportSelections.currencyCodeInfo',
    defaultMessage:
      "Company's home currency is used when left blank or no conversion is selected.",
    description: "Info text for setting report's currency",
  },
  currencyCodeNoConversion: {
    id: 'app.containers.ReportSelections.currencyCodeNoConversion',
    defaultMessage: 'No conversion',
    description: "Dropdown list's text for an empty selection",
  },
  divisionOrMultiplicationIsUsed: {
    id: 'app.containers.ReportSelections.divisionOrMultiplicationIsUsed',
    defaultMessage:
      'Totals are not available because division or multiplication is used in report scheme.',
    description: 'Information to show when total selection fields are hidden',
  },
  selectCurrencyConversion: {
    id: 'app.containers.ReportSelections.selectCurrencyConversion',
    defaultMessage: 'No conversion',
    description: "Placeholder text for a field that sets report's currency",
  },
  showGrandTotalFieldLabel: {
    id: 'app.containers.ReportSelections.showGrandTotalFieldLabel',
    defaultMessage: 'Show grand total',
    description: 'Field label',
  },
  showTotalsFieldLabel: {
    id: 'app.containers.ReportSelections.showTotalsFieldLabel',
    defaultMessage: 'Show totals',
    description: 'Field label',
  },
  showTotalsHybridReportInfo: {
    id: 'app.containers.ReportSelections.showTotalsHybridReportInfo',
    defaultMessage:
      'Beware that quantities can differ in hybrid reports. I.e. one might see money in one row and number of sold items in the other. Totals can show strange results.',
    description:
      'Info text for setting totals when report scheme type is hybrid',
  },
  header: {
    id: 'app.containers.ReportSelections.header',
    defaultMessage: 'This is Report container !',
  },
  DateFormat: {
    id: 'app.containers.ReportSelections.DateFormat',
    description: 'DateFormat',
    defaultMessage: 'Date format',
  },
  IncludeDay: {
    id: 'app.containers.ReportSelections.IncludeDay',
    description: 'Include Day',
    defaultMessage: 'Year, Month and Day (yyyy/MM/dd)',
  },
  Month: {
    id: 'app.containers.ReportSelections.Month',
    description: 'This is word "Month"',
    defaultMessage: 'Month (MM)',
  },
  MonthAndYear: {
    id: 'app.containers.ReportSelections.MonthAndYear',
    description: 'MonthAndYear',
    defaultMessage: 'Month And Year (MM/yyyy)',
  },
  OverlappingMonths: {
    id: 'app.containers.ReportSelections.OverlappingMonths',
    description: 'Overlapping Months',
    defaultMessage: 'Overlapping Months',
  },
  YearAndMonth: {
    id: 'app.containers.ReportSelections.YearAndMonth',
    description: 'YearAndMonth',
    defaultMessage: 'Year And Month (yyyy/MM)',
  },
  DecimalsZero: {
    id: 'app.containers.ReportSelections.DecimalsZero',
    description: 'One of the decimal counts',
    defaultMessage: '0',
  },
  DecimalsTwo: {
    id: 'app.containers.ReportSelections.DecimalsTwo',
    description: 'One of the decimal counts',
    defaultMessage: '2 (0,01)',
  },
  DecimalsOne: {
    id: 'app.containers.ReportSelections.DecimalsOne',
    description: 'One of the decimal counts',
    defaultMessage: '1 (0,1)',
  },
  PrecisionOne: {
    id: 'app.containers.ReportSelections.PrecisionOne',
    description: 'One of the decimal precisions',
    defaultMessage: '1',
  },
  PrecisionThousand: {
    id: 'app.containers.ReportSelections.PrecisionThousand',
    description: 'One of the decimal precisions',
    defaultMessage: '1000',
  },
  PrecisionMillion: {
    id: 'app.containers.ReportSelections.PrecisionMillion',
    description: 'One of the decimal precisions',
    defaultMessage: '1 000 000',
  },
  Space: {
    id: 'app.containers.ReportSelections.Space',
    description: 'One of the separators',
    defaultMessage: 'Space',
  },
  Dot: {
    id: 'app.containers.ReportSelections.Dot',
    description: 'One of the separators',
    defaultMessage: '.',
  },
  Comma: {
    id: 'app.containers.ReportSelections.Comma',
    description: 'One of the separators',
    defaultMessage: ',',
  },
  Company: {
    id: 'app.containers.ReportSelections.Company',
    description: 'One of the orders',
    defaultMessage: 'Company',
  },
  Period: {
    id: 'app.containers.ReportSelections.Period',
    description: 'One of the orders',
    defaultMessage: 'Period',
  },
  DataSource: {
    id: 'app.containers.ReportSelections.DataSource',
    description: 'One of the orders',
    defaultMessage: 'Datasource',
  },
  Dimension: {
    id: 'app.containers.ReportSelections.Dimension',
    description: 'One of the orders',
    defaultMessage: 'Dimension',
  },
  duplicateDataSourceNameError: {
    id: 'app.containers.ReportSelections.duplicateDataSourceNameError',
    description: 'Data source with selected name already exists',
    defaultMessage: 'Data source with selected name already exists',
  },
  missingDatasourceError: {
    id: 'app.containers.ReportSelections.missingDataSourcError',
    description: 'Datasource is required',
    defaultMessage: 'Datasource is required',
  },
  missingBudgetError: {
    id: 'app.containers.DatasourceContainer.missingBudgetError',
    description: 'Budget is required',
    defaultMessage: 'Budget is required',
  },
  duplicateNameError: {
    id: 'app.containers.ReportSelections.duplicateNameError',
    description: 'Report with selected name already exists',
    defaultMessage: 'Report with given name already exists',
  },
  noNameError: {
    id: 'app.containers.ReportSelections.noNameError',
    description: 'Name is required',
    defaultMessage: 'Name is required',
  },
  missingCompanySource: {
    id: 'app.containers.ReportSelections.missingCompanySource',
    description:
      'Error message for missing company in report selection datasource',
    defaultMessage: 'At least one company has to be selected',
  },
  reportSchemesListIsEmpty: {
    id: 'app.containers.ReportSelections.reportSchemesListIsEmpty',
    description: 'Cannot find any suitable report scheme for used companies.',
    defaultMessage:
      'Cannot find any suitable report scheme for used companies.',
  },
  visualizationOverrideHintText: {
    id: 'app.containers.ReportSelections.visualizationOverrideHintText',
    description:
      'Displayed when some of the report selection selections are overridden by visualization override',
    defaultMessage:
      'This report has saved changes done in the report page. The settings override some of the report parameters done in this page.',
  },
  tags: {
    id: 'app.containers.ReportSelections.tags',
    description: 'Folders',
    defaultMessage: 'Folders',
  },
  tagHelpPopOver: {
    id: 'app.containers.ReportSelections.tagHelpPopOver',
    description: 'Text to tell what tags do',
    defaultMessage:
      'Adding report to customer or company folder automatically makes report visible to all users who have rights to view given folder. User folders are used for personal categorization.',
  },
  includeRowMeasuresHelpPopOver: {
    id: 'app.containers.ReportSelections.includeRowMeasuresHelpPopOver',
    description: 'Describe include row measures option',
    defaultMessage:
      'Significantly slows down reports opening, use only if needed. Adds last level of report scheme as a individual measure to data. This allows referencing them when adding calculated values using the "Fields" List.',
  },
  includeRowMeasures: {
    id: 'app.containers.ReportSelections.includeRowMeasures',
    description: 'Include Row measures title',
    defaultMessage: 'Row measures',
  },
  reportScheme: {
    id: 'app.containers.ReportSelections.reportScheme',
    description: 'reportScheme',
    defaultMessage: 'Report scheme',
  },
  selectReportScheme: {
    id: 'app.containers.ReportSelections.selectReportScheme',
    description: 'selectReportScheme',
    defaultMessage: 'Select report scheme',
  },
  preview: {
    id: 'app.containers.ReportSelections.preview',
    description: 'preview',
    defaultMessage: 'Preview',
  },
  saveAndClose: {
    id: 'app.containers.ReportSelections.saveAndClose',
    description: 'Save and close',
    defaultMessage: 'Save and Close',
  },
  saveAndOpen: {
    id: 'app.containers.ReportSelections.saveAndOpen',
    description: 'Save and open',
    defaultMessage: 'Save and Open',
  },
  title: {
    [REPORT_SELECTION_TYPE_CUSTOMER]: {
      id: 'app.components.ReportSelections.customerReports',
      defaultMessage: 'Customer Report',
      description: 'Breadcrumb link text',
    },
    [REPORT_SELECTION_TYPE_SYSTEM]: {
      id: 'app.components.ReportSelections.systemReports',
      defaultMessage: 'System Report',
      description: 'Breadcrumb link text',
    },
  },
})
