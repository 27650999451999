export const COPY_CUSTOMER_DASHBOARD =
  'app/CustomerDashboards/COPY_CUSTOMER_DASHBOARD'
export const COPY_CUSTOMER_DASHBOARD_ERROR =
  'app/CustomerDashboards/COPY_CUSTOMER_DASHBOARD_ERROR'
export const COPY_CUSTOMER_DASHBOARD_SUCCESS =
  'app/CustomerDashboards/COPY_CUSTOMER_DASHBOARD_SUCCESS'

export const COPY_CUSTOMER_DASHBOARD_WIDGET =
  'app/CustomerDashboards/COPY_CUSTOMER_DASHBOARD_WIDGET'
export const COPY_CUSTOMER_DASHBOARD_WIDGET_ERROR =
  'app/CustomerDashboards/COPY_CUSTOMER_DASHBOARD_WIDGET_ERROR'
export const COPY_CUSTOMER_DASHBOARD_WIDGET_SUCCESS =
  'app/CustomerDashboards/COPY_CUSTOMER_DASHBOARD_WIDGET_SUCCESS'

export const CREATE_CUSTOMER_DASHBOARD =
  'app/CustomerDashboards/CREATE_CUSTOMER_DASHBOARD'
export const CREATE_CUSTOMER_DASHBOARD_ERROR =
  'app/CustomerDashboards/CREATE_CUSTOMER_DASHBOARD_ERROR'
export const CREATE_CUSTOMER_DASHBOARD_SUCCESS =
  'app/CustomerDashboards/CREATE_CUSTOMER_DASHBOARD_SUCCESS'

export const CREATE_CUSTOMER_DASHBOARD_WIDGET =
  'app/CustomerDashboards/CREATE_CUSTOMER_DASHBOARD_WIDGET'
export const CREATE_CUSTOMER_DASHBOARD_WIDGET_ERROR =
  'app/CustomerDashboards/CREATE_CUSTOMER_DASHBOARD_WIDGET_ERROR'
export const CREATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS =
  'app/CustomerDashboards/CREATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS'

export const DELETE_CUSTOMER_DASHBOARD =
  'app/CustomerDashboards/DELETE_CUSTOMER_DASHBOARD'
export const DELETE_CUSTOMER_DASHBOARD_ERROR =
  'app/CustomerDashboards/DELETE_CUSTOMER_DASHBOARD_ERROR'
export const DELETE_CUSTOMER_DASHBOARD_SUCCESS =
  'app/CustomerDashboards/DELETE_CUSTOMER_DASHBOARD_SUCCESS'

export const DELETE_CUSTOMER_DASHBOARD_WIDGET =
  'app/CustomerDashboards/DELETE_CUSTOMER_DASHBOARD_WIDGET'
export const DELETE_CUSTOMER_DASHBOARD_WIDGET_ERROR =
  'app/CustomerDashboards/DELETE_CUSTOMER_DASHBOARD_WIDGET_ERROR'
export const DELETE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS =
  'app/CustomerDashboards/DELETE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS'

export const GET_CUSTOMER_DASHBOARDS =
  'app/CustomerDashboards/GET_CUSTOMER_DASHBOARDS'
export const GET_CUSTOMER_DASHBOARDS_ERROR =
  'app/CustomerDashboards/GET_CUSTOMER_DASHBOARDS_ERROR'
export const GET_CUSTOMER_DASHBOARDS_SUCCESS =
  'app/CustomerDashboards/GET_CUSTOMER_DASHBOARDS_SUCCESS'

export const UPDATE_CUSTOMER_DASHBOARD =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD'
export const UPDATE_CUSTOMER_DASHBOARD_ERROR =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_ERROR'
export const UPDATE_CUSTOMER_DASHBOARD_SUCCESS =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_SUCCESS'

export const UPDATE_CUSTOMER_DASHBOARD_WIDGET =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_WIDGET'
export const UPDATE_CUSTOMER_DASHBOARD_WIDGET_ERROR =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_WIDGET_ERROR'
export const UPDATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS'

export const UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS'
export const UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_ERROR =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_ERROR'
export const UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_SUCCESS =
  'app/CustomerDashboards/UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_SUCCESS'
