/*
 *
 * ReportScheme constants
 *
 */

export const GET_REPORT_SCHEME_TREE = 'app/ReportScheme/GET_REPORT_SCHEME_TREE'
export const GET_REPORT_SCHEME_TREE_ERROR =
  'app/ReportScheme/GET_REPORT_SCHEME_TREE_ERROR'
export const GET_REPORT_SCHEME_TREE_SUCCESS =
  'app/ReportScheme/GET_REPORT_SCHEME_TREE_SUCCESS'

export const CREATE_REPORT_SCHEME_ROW =
  'app/ReportScheme/CREATE_REPORT_SCHEME_ROW'
export const CREATE_REPORT_SCHEME_ROW_ERROR =
  'app/ReportScheme/CREATE_REPORT_SCHEME_ROW_ERROR'
export const CREATE_REPORT_SCHEME_ROW_SUCCESS =
  'app/ReportScheme/CREATE_REPORT_SCHEME_ROW_SUCCESS'

export const DELETE_REPORT_SCHEME_ROW =
  'app/ReportScheme/DELETE_REPORT_SCHEME_ROW'
export const DELETE_REPORT_SCHEME_ROW_ERROR =
  'app/ReportScheme/DELETE_REPORT_SCHEME_ROW_ERROR'
export const DELETE_REPORT_SCHEME_ROW_SUCCESS =
  'app/ReportScheme/DELETE_REPORT_SCHEME_ROW_SUCCESS'

export const TOGGLE_COLLAPSE_ALL_GROUPS =
  'app/ReportScheme/TOGGLE_COLLAPSE_ALL_GROUPS'

export const TOGGLE_COLLAPSE_GROUP = 'app/ReportScheme/TOGGLE_COLLAPSE_GROUP'

export const TOGGLE_COLLAPSE_GROUPS = 'app/ReportScheme/TOGGLE_COLLAPSE_GROUPS'

export const TOGGLE_SHOW_FORM = 'app/ReportScheme/TOGGLE_SHOW_FORM'

export const TOGGLE_PERCENTAGE_TARGET =
  'app/ReportScheme/TOGGLE_PERCENTAGE_TARGET'
export const TOGGLE_PERCENTAGE_TARGET_ERROR =
  'app/ReportScheme/TOGGLE_PERCENTAGE_TARGET_ERROR'
export const TOGGLE_PERCENTAGE_TARGET_SUCCESS =
  'app/ReportScheme/TOGGLE_PERCENTAGE_TARGET_SUCCESS'
export const TOGGLE_SHOW_SETTINGS_FORM =
  'app/ReportScheme/TOGGLE_SHOW_SETTINGS_FORM'

export const UPDATE_REPORT_SCHEME_ROW =
  'app/ReportScheme/UPDATE_REPORT_SCHEME_ROW'
export const UPDATE_REPORT_SCHEME_ROW_ERROR =
  'app/ReportScheme/UPDATE_REPORT_SCHEME_ROW_ERROR'
export const UPDATE_REPORT_SCHEME_ROW_SUCCESS =
  'app/ReportScheme/UPDATE_REPORT_SCHEME_ROW_SUCCESS'

export const REPORT_SCHEME_ROW_TYPE_HEADER = 'Header'

export const MOVE_REPORT_SCHEME_ROW = 'app/ReportScheme/MOVE_REPORT_SCHEME_ROW'
export const MOVE_REPORT_SCHEME_ROW_ERROR =
  'app/ReportScheme/MOVE_REPORT_SCHEME_ROW_ERROR'
export const MOVE_REPORT_SCHEME_ROW_SUCCESS =
  'app/ReportScheme/MOVE_REPORT_SCHEME_ROW_SUCCESS'

export const COPY_REPORT_SCHEME_ROW = 'app/ReportScheme/COPY_REPORT_SCHEME_ROW'
export const COPY_REPORT_SCHEME_ROW_ERROR =
  'app/ReportScheme/COPY_REPORT_SCHEME_ROW_ERROR'
export const COPY_REPORT_SCHEME_ROW_SUCCESS =
  'app/ReportScheme/COPY_REPORT_SCHEME_ROW_SUCCESS'

export const MOVE = 'move'
export const COPY = 'copy'
