/*
 *
 * SubBudgets constants
 *
 */

export const GET_ALL_SUB_BUDGETS = 'app/SubBudgets/GET_ALL_SUB_BUDGETS'
export const GET_ALL_SUB_BUDGETS_ERROR =
  'app/SubBudgets/GET_ALL_SUB_BUDGETS_ERROR'
export const GET_ALL_SUB_BUDGETS_SUCCESS =
  'app/SubBudgets/GET_ALL_SUB_BUDGETS_SUCCESS'

export const GET_BUDGET_SUB_BUDGETS = 'app/SubBudgets/GET_BUDGET_SUB_BUDGETS'
export const GET_BUDGET_SUB_BUDGETS_ERROR =
  'app/SubBudgets/GET_BUDGET_SUB_BUDGETS_ERROR'
export const GET_BUDGET_SUB_BUDGETS_SUCCESS =
  'app/SubBudgets/GET_BUDGET_SUB_BUDGETS_SUCCESS'

export const TOGGLE_SHOW_CREATE_SUB_BUDGET_FORM =
  'app/SubBudgets/TOGGLE_SHOW_CREATE_SUB_BUDGET_FORM'

export const CREATE_SUB_BUDGET = 'app/SubBudgets/CREATE_SUB_BUDGET'
export const CREATE_SUB_BUDGET_ERROR = 'app/SubBudgets/CREATE_SUB_BUDGET_ERROR'
export const CREATE_SUB_BUDGET_SUCCESS =
  'app/SubBudgets/CREATE_SUB_BUDGET_SUCCESS'

export const CHANGE_SELECTED_COMPANY = 'app/SubBudgets/CHANGE_SELECTED_COMPANY'

export const DELETE_SUB_BUDGET = 'app/SubBudgets/DELETE_SUB_BUDGET'
export const DELETE_SUB_BUDGET_ERROR = 'app/SubBudgets/DELETE_SUB_BUDGET_ERROR'
export const DELETE_SUB_BUDGET_SUCCESS =
  'app/SubBudgets/DELETE_SUB_BUDGET_SUCCESS'
export const DELETE_SUB_BUDGETS_SUCCESS =
  'app/SubBudgets/DELETE_SUB_BUDGETS_SUCCESS'

export const EDIT_SUB_BUDGET = 'app/SubBudgets/EDIT_SUB_BUDGET'

export const UPDATE_SUB_BUDGET = 'app/SubBudgets/UPDATE_SUB_BUDGET'
export const UPDATE_SUB_BUDGET_ERROR = 'app/SubBudgets/UPDATE_SUB_BUDGET_ERROR'
export const UPDATE_SUB_BUDGET_SUCCESS =
  'app/SubBudgets/UPDATE_SUB_BUDGET_SUCCESS'

export const NO_STATUS = 'NoStatus'
export const NOT_STARTED = 'NotStarted'
export const IN_PROGRESS = 'InProgress'
export const NEEDS_REWORK = 'NeedsRework'
export const DONE = 'Done'
export const APPROVED = 'Approved'

export const STATUS_TYPES = [
  NO_STATUS,
  NOT_STARTED,
  IN_PROGRESS,
  NEEDS_REWORK,
  DONE,
  APPROVED,
]

export const LIMITED_STATUS_TYPES = [IN_PROGRESS, DONE]
