/*
 *
 * ReportSchemes constants
 *
 */
export const CREATE_REPORT_SCHEME = 'app/ReportSchemes/CREATE_REPORT_SCHEME'
export const CREATE_REPORT_SCHEME_ERROR =
  'app/ReportSchemes/CREATE_REPORT_SCHEME_ERROR'
export const CREATE_REPORT_SCHEME_SUCCESS =
  'app/ReportSchemes/CREATE_REPORT_SCHEME_SUCCESS'

export const COPY_REPORT_SCHEME = 'app/ReportSchemes/COPY_REPORT_SCHEME'
export const COPY_REPORT_SCHEME_ERROR =
  'app/ReportSchemes/COPY_REPORT_SCHEME_ERROR'
export const COPY_REPORT_SCHEME_SUCCESS =
  'app/ReportSchemes/COPY_REPORT_SCHEME_SUCCESS'

export const DELETE_REPORT_SCHEME = 'app/ReportSchemes/DELETE_REPORT_SCHEME'
export const DELETE_REPORT_SCHEME_ERROR =
  'app/ReportSchemes/DELETE_REPORT_SCHEME_ERROR'
export const DELETE_REPORT_SCHEME_SUCCESS =
  'app/ReportSchemes/DELETE_REPORT_SCHEME_SUCCESS'

export const GET_REPORT_SCHEMES = 'app/ReportSchemes/GET_REPORT_SCHEMES'
export const GET_REPORT_SCHEMES_ERROR =
  'app/ReportSchemes/GET_REPORT_SCHEMES_ERROR'
export const GET_REPORT_SCHEMES_SUCCESS =
  'app/ReportSchemes/GET_REPORT_SCHEMES_SUCCESS'

export const TOGGLE_SHOW_CREATE_REPORT_SCHEME =
  'app/ReportSchemes/TOGGLE_SHOW_CREATE_REPORT_SCHEME'

export const UPDATE_REPORT_SCHEME = 'app/ReportSchemes/UPDATE_REPORT_SCHEME'
export const UPDATE_REPORT_SCHEME_ERROR =
  'app/ReportSchemes/UPDATE_REPORT_SCHEME_ERROR'
export const UPDATE_REPORT_SCHEME_SUCCESS =
  'app/ReportSchemes/UPDATE_REPORT_SCHEME_SUCCESS'
