import { RefreshButton } from 'components/Button'
import { Container, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import messages from '../messages'
import styles from '../styles.module.css'

const MissingAccountTransferError = ({ errorMessages }) => {
  return (
    <Container>
      <Card>
        <Card.Header as="h3" className={styles.errorHeader}>
          <FormattedMessage {...messages.missingAccountsTitle} />
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <FormattedMessage {...messages.missingAccountsText} />
            <div>
              <ul>
                {errorMessages.map((msg, i) => (
                  <li key={i}>{msg}</li>
                ))}
              </ul>
            </div>
          </Card.Text>
          <RefreshButton />
        </Card.Body>
      </Card>
    </Container>
  )
}

export default MissingAccountTransferError
