import {
  AADInvitationError,
  InvalidVoucherlineError,
  ForbiddenErrorComponent,
  InvalidReportError,
} from 'components/ErrorComponent/ErrorComponents'
import MissingAccountTransferError from 'components/ErrorComponent/ErrorComponents/MissingAccountTransferError'

const errorCodes = {
  'E-0001': null,
  'E-0002': null,
  'E-0003': null,
  'E-0004': null,
  'E-0005': null,
  'E-0006': null,
  'E-0007': null,
  'E-0008': null,
  'E-0009': null,
  'E-0010': null,
  'E-0011': null,
  'E-0012': null,
  'E-0013': null,
  'E-0014': ForbiddenErrorComponent,
  'E-0015': null,
  'E-0016': null,
  'E-0017': null,
  'E-0018': InvalidVoucherlineError,
  'E-0300': AADInvitationError,
  'E-0106': InvalidReportError,
  'E-0107': MissingAccountTransferError,
}

export default errorCodes
