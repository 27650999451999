import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Dropdown } from 'react-bootstrap'
import navimessages from './messages'
import { chooseProfile } from 'containers/App/actions'

/**
 * Used in dropdown
 */
export function UserInfoNaviItem({ settingsAction, logoutAction, subUsers }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  function handleSwitchUser() {
    navigate('/')
    dispatch(chooseProfile(subUsers, navigate))
  }
  return (
    <>
      <Dropdown.Item onClick={settingsAction}>
        <FormattedMessage {...navimessages.settings} />
      </Dropdown.Item>
      {subUsers?.filter((user) => user?.get('isActive'))?.size > 1 && (
        <Dropdown.Item onClick={() => handleSwitchUser()}>
          <FormattedMessage {...navimessages.switchUser} />
        </Dropdown.Item>
      )}
      <Dropdown.Item onClick={logoutAction} data-testing-id="menu-item-logout">
        <FormattedMessage {...navimessages.logout} />
      </Dropdown.Item>
    </>
  )
}
