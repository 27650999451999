/**
 *
 * ModalHeader
 *
 * Wrapper Component for Bootstrap Modal Header and Title
 *
 * This component is deprecated. Use Modal.Header directly.
 *
 */

import PropTypes from 'prop-types'

import React from 'react'
import { Modal } from 'react-bootstrap'

const ModalHeader = ({ children, title, ...props }) => (
  <Modal.Header {...props}>
    {title && <div className="modal-title">{title}</div>}
    {children}
  </Modal.Header>
)

ModalHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default ModalHeader
