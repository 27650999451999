import { Record } from 'immutable'

const AccountUpdateRecord = Record({
  isConsolidatedAccount: false,
  targetCompanyId: null,
  name: '',
  reportingCode: '',
})

export default AccountUpdateRecord
