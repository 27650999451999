import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getCompanies = ({ customerCode }) =>
  axios
    .get(`${customerCode}/companies`, config())
    .then(returnData)
    .catch(catchError)

const createCompany = ({ company, customerCode }) =>
  axios
    .post(`${customerCode}/companies`, company, config())
    .then(returnData)
    .catch(catchError)

const deleteCompany = ({ customerCode, companyCode }) =>
  axios
    .delete(`${customerCode}/companies/${companyCode}`, config())
    .then(returnData)
    .catch(catchError)

const patchCompany = ({ customerCode, companyCode, patch }) =>
  axios
    .patch(`${customerCode}/companies/${companyCode}`, patch, config())
    .then(returnData)
    .catch(catchError)

const recalculateCompanyBalances = ({ companyCode, end, start }) =>
  axios
    .get(
      `${companyCode}/realbalances/recalculate?start=${start}&end=${end}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const getDataGroups = ({ companyCode }) =>
  axios
    .get(`${companyCode}/operativeData/GetDataGroups`, config())
    .then(returnData)
    .catch(catchError)

const companyApi = {
  getCompanies,
  createCompany,
  deleteCompany,
  patchCompany,
  recalculateCompanyBalances,
  getDataGroups,
}
export default companyApi
