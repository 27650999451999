/*
 *
 * CalculationConstant constants
 *
 */
export const CREATE_CALCULATION_CONSTANT_VALUE =
  'app/CalculationConstant/CREATE_CALCULATION_CONSTANT_VALUE'
export const CREATE_CALCULATION_CONSTANT_VALUE_ERROR =
  'app/CalculationConstant/CREATE_CALCULATION_CONSTANT_VALUE_ERROR'
export const CREATE_CALCULATION_CONSTANT_VALUE_SUCCESS =
  'app/CalculationConstant/CREATE_CALCULATION_CONSTANT_VALUE_SUCCESS'

export const DELETE_CALCULATION_CONSTANT_VALUE =
  'app/CalculationConstant/DELETE_CALCULATION_CONSTANT_VALUE'
export const DELETE_CALCULATION_CONSTANT_VALUE_ERROR =
  'app/CalculationConstant/DELETE_CALCULATION_CONSTANT_VALUE_ERROR'
export const DELETE_CALCULATION_CONSTANT_VALUE_SUCCESS =
  'app/CalculationConstant/DELETE_CALCULATION_CONSTANT_VALUE_SUCCESS'

export const EDIT_CALCULATION_CONSTANT_VALUE =
  'app/CalculationConstant/EDIT_CALCULATION_CONSTANT_VALUE'

export const TOGGLE_SHOW_CALCULATION_CONSTANT_VALUE_FORM =
  'app/CalculationConstant/TOGGLE_SHOW_CALCULATION_CONSTANT_VALUE_FORM'

export const UPDATE_CALCULATION_CONSTANT_VALUE =
  'app/CalculationConstant/UPDATE_CALCULATION_CONSTANT_VALUE'
export const UPDATE_CALCULATION_CONSTANT_VALUE_ERROR =
  'app/CalculationConstant/UPDATE_CALCULATION_CONSTANT_VALUE_ERROR'
export const UPDATE_CALCULATION_CONSTANT_VALUE_SUCCESS =
  'app/CalculationConstant/UPDATE_CALCULATION_CONSTANT_VALUE_SUCCESS'
