/*
 *
 * Accounts actions
 *
 */

import {
  CANCEL_CREATE_ACCOUNT_SCHEME,
  CANCEL_IMPORT_ACCOUNTS,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_ERROR,
  DELETE_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT,
  GET_ACCOUNTS,
  GET_ACCOUNTS_ERROR,
  GET_ACCOUNTS_SUCCESS,
  IMPORT_ACCOUNTS,
  IMPORT_ACCOUNTS_ERROR,
  IMPORT_ACCOUNTS_SUCCESS,
  IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME,
  PARSE_ACCOUNTS,
  PARSE_ACCOUNTS_ERROR,
  PARSE_ACCOUNTS_SUCCESS,
  TOGGLE_SHOW_ACCOUNT_FORM,
  START_ACCOUNT_SCHEME_CREATION,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SUCCESS,
} from './constants'

export const cancelImportAccounts = () => ({ type: CANCEL_IMPORT_ACCOUNTS })
export const cancelCreateAccountScheme = () => ({
  type: CANCEL_CREATE_ACCOUNT_SCHEME,
})
export const createAccount = ({
  accountNumber,
  companyCode,
  isConsolidatedAccount,
  targetCompanyId,
  name,
  reportingCode,
}) => ({
  type: CREATE_ACCOUNT,
  accountNumber,
  companyCode,
  isConsolidatedAccount,
  targetCompanyId,
  name,
  reportingCode,
})
export const createAccountScheme = ({
  companyCode,
  customerCode,
  navigate,
}) => ({
  type: IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME,
  companyCode,
  customerCode,
  navigate,
})
export const createAccountError = (error) => ({
  type: CREATE_ACCOUNT_ERROR,
  error,
})
export const createAccountSuccess = ({ account, companyCode }) => ({
  type: CREATE_ACCOUNT_SUCCESS,
  account,
  companyCode,
})

export const deleteAccount = ({ accountNumber, companyCode, name }) => ({
  type: DELETE_ACCOUNT,
  accountNumber,
  companyCode,
  name,
})
export const deleteAccountError = (error) => ({
  type: DELETE_ACCOUNT_ERROR,
  error,
})
export const deleteAccountSuccess = ({ accountNumber, companyCode }) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  accountNumber,
  companyCode,
})

export const editAccount = ({ accountNumber, companyCode }) => ({
  type: EDIT_ACCOUNT,
  accountNumber,
  companyCode,
})

export const getAccounts = ({ companyCode }) => ({
  type: GET_ACCOUNTS,
  companyCode,
})
export const getAccountsError = (error) => ({ type: GET_ACCOUNTS_ERROR, error })
export const getAccountsSuccess = ({ accounts, companyCode }) => ({
  type: GET_ACCOUNTS_SUCCESS,
  accounts,
  companyCode,
})

export const importAccounts = ({ companyCode, validAccounts }) => ({
  type: IMPORT_ACCOUNTS,
  companyCode,
  validAccounts,
})
export const importAccountsError = (error) => ({
  type: IMPORT_ACCOUNTS_ERROR,
  error,
})
export const importAccountsSuccess = ({
  accounts,
  companyCode,
  parsedFile,
}) => ({
  type: IMPORT_ACCOUNTS_SUCCESS,
  accounts,
  companyCode,
  parsedFile,
})

export const parseAccounts = ({ file, allowLetters, min, max, companies }) => ({
  type: PARSE_ACCOUNTS,
  file,
  allowLetters,
  min,
  max,
  companies,
})
export const parseAccountsError = (error) => ({
  type: PARSE_ACCOUNTS_ERROR,
  error,
})
export const parseAccountsSuccess = ({ parsedAccounts, parsedFile }) => ({
  type: PARSE_ACCOUNTS_SUCCESS,
  parsedAccounts,
  parsedFile,
})
export const startAccountSchemeCreation = () => ({
  type: START_ACCOUNT_SCHEME_CREATION,
})
export const toggleShowAccountForm = () => ({
  type: TOGGLE_SHOW_ACCOUNT_FORM,
  toggleShowAccountForm,
})

export const updateAccount = ({ account, companyCode, updatedValues }) => ({
  type: UPDATE_ACCOUNT,
  account,
  companyCode,
  updatedValues,
})
export const updateAccountError = (error) => ({
  type: UPDATE_ACCOUNT_ERROR,
  error,
})
export const updateAccountSuccess = ({ account, companyCode }) => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  account,
  companyCode,
})
