/*
 *
 * DimensionValue reducer
 *
 */

import { fromJS } from 'immutable'

import { GET_DIMENSIONS_SUCCESS } from 'containers/Dimensions/constants'

import {
  UPDATE_DIMENSION_VALUE,
  UPDATE_DIMENSION_VALUE_ERROR,
} from './constants'

const initialState = fromJS({
  error: false,
  loading: false,
})

function dimensionValueReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DIMENSION_VALUE:
      return state.set('loading', true).set('error', false)

    case UPDATE_DIMENSION_VALUE_ERROR:
      return state.set('loading', false).set('error', action.error)

    // If updating dimension value was a success,
    // dimensions are fetched again
    case GET_DIMENSIONS_SUCCESS:
      return state.set('loading', false)

    default:
      return state
  }
}

export default dimensionValueReducer
