/*
 *
 * SubBudgets actions
 *
 */

import {
  CREATE_SUB_BUDGET,
  CREATE_SUB_BUDGET_ERROR,
  CREATE_SUB_BUDGET_SUCCESS,
  CHANGE_SELECTED_COMPANY,
  DELETE_SUB_BUDGET,
  DELETE_SUB_BUDGET_ERROR,
  DELETE_SUB_BUDGET_SUCCESS,
  DELETE_SUB_BUDGETS_SUCCESS,
  GET_ALL_SUB_BUDGETS,
  GET_ALL_SUB_BUDGETS_ERROR,
  GET_ALL_SUB_BUDGETS_SUCCESS,
  GET_BUDGET_SUB_BUDGETS,
  GET_BUDGET_SUB_BUDGETS_ERROR,
  GET_BUDGET_SUB_BUDGETS_SUCCESS,
  EDIT_SUB_BUDGET,
  TOGGLE_SHOW_CREATE_SUB_BUDGET_FORM,
  UPDATE_SUB_BUDGET,
  UPDATE_SUB_BUDGET_ERROR,
  UPDATE_SUB_BUDGET_SUCCESS,
} from './constants'

export const getAllSubBudgets = ({ companyCode }) => ({
  type: GET_ALL_SUB_BUDGETS,
  companyCode,
})
export const getAllSubBudgetsError = (error) => ({
  type: GET_ALL_SUB_BUDGETS_ERROR,
  error,
})
export const getAllSubBudgetsSuccess = ({ subBudgets, companyCode }) => ({
  type: GET_ALL_SUB_BUDGETS_SUCCESS,
  subBudgets,
  companyCode,
})

export const getBudgetSubBudgets = ({ companyCode, budgetId }) => ({
  type: GET_BUDGET_SUB_BUDGETS,
  companyCode,
  budgetId,
})
export const getBudgetSubBudgetsError = (error) => ({
  type: GET_BUDGET_SUB_BUDGETS_ERROR,
  error,
})
export const getBudgetSubBudgetsSuccess = ({
  subBudgets,
  budgetId,
  companyCode,
}) => ({
  type: GET_BUDGET_SUB_BUDGETS_SUCCESS,
  subBudgets,
  budgetId,
  companyCode,
})

export const changeSelectedCompany = (company) => ({
  type: CHANGE_SELECTED_COMPANY,
  company,
})

export const toggleShowCreateSubBudgetForm = (selectedCompany) => ({
  type: TOGGLE_SHOW_CREATE_SUB_BUDGET_FORM,
  selectedCompany,
})

export const createSubBudget = ({ companyCode, budgetId, subBudget }) => ({
  type: CREATE_SUB_BUDGET,
  companyCode,
  budgetId,
  subBudget,
})
export const createSubBudgetError = (error) => ({
  type: CREATE_SUB_BUDGET_ERROR,
  error,
})
export const createSubBudgetSuccess = ({
  subBudget,
  budgetId,
  companyCode,
}) => ({ type: CREATE_SUB_BUDGET_SUCCESS, subBudget, budgetId, companyCode })

export const deleteSubBudget = ({
  companyCode,
  budgetId,
  dv,
  end,
  start,
  subBudgetId,
  view,
}) => ({
  type: DELETE_SUB_BUDGET,
  companyCode,
  budgetId,
  dv,
  end,
  start,
  subBudgetId,
  view,
})
export const deleteSubBudgetError = (error) => ({
  type: DELETE_SUB_BUDGET_ERROR,
  error,
})
export const deleteSubBudgetSuccess = ({ budgetId, subBudgetId }) => ({
  type: DELETE_SUB_BUDGET_SUCCESS,
  budgetId,
  subBudgetId,
})
export const deleteSubBudgetsSuccess = ({ budgetId }) => ({
  type: DELETE_SUB_BUDGETS_SUCCESS,
  budgetId,
})

export const editSubBudget = ({ subBudgetId }) => ({
  type: EDIT_SUB_BUDGET,
  subBudgetId,
})

export const updateSubBudget = ({
  companyCode,
  budgetId,
  sourceSubBudget,
  updatedSubBudget,
}) => ({
  type: UPDATE_SUB_BUDGET,
  companyCode,
  budgetId,
  sourceSubBudget,
  updatedSubBudget,
})
export const updateSubBudgetError = (error) => ({
  type: UPDATE_SUB_BUDGET_ERROR,
  error,
})
export const updateSubBudgetSuccess = ({
  budgetId,
  subBudget,
  companyCode,
}) => ({ type: UPDATE_SUB_BUDGET_SUCCESS, budgetId, subBudget, companyCode })
