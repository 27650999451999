import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import accountSchemeApi from 'api/AccountSchemeApi'
import { findLastOrderNumber, fixLeadingSlash } from 'utils/schemeGroupUtils'

import {
  addAccountSchemeRowError,
  addAccountSchemeRowSuccess,
  addAccountSchemeRowGroupError,
  addAccountSchemeRowGroupSuccess,
  deleteAccountSchemeRowError,
  deleteAccountSchemeRowSuccess,
  getAccountSchemeError,
  getAccountSchemeSuccess,
  getAccountSchemeAndTreeError,
  getAccountSchemeAndTreeSuccess,
  getAccountSchemeTreeError,
  getAccountSchemeTreeSuccess,
  moveAccountSchemeRowError,
  moveAccountSchemeRowSuccess,
  setAccountSchemeSpecialAccountError,
  setAccountSchemeSpecialAccountSuccess,
  updateAccountSchemeRowError,
  updateAccountSchemeRowSuccess,
} from './actions'

import {
  ADD_ACCOUNT_SCHEME_ROW,
  ADD_ACCOUNT_SCHEME_ROW_GROUP,
  DELETE_ACCOUNT_SCHEME_ROW,
  GET_ACCOUNT_SCHEME,
  GET_ACCOUNT_SCHEME_AND_TREE,
  GET_ACCOUNT_SCHEME_TREE,
  MOVE_ACCOUNT_SCHEME_ROW,
  SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  UPDATE_ACCOUNT_SCHEME_ROW,
  ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP,
} from './constants'

import { Record } from 'immutable'
import * as Diff from 'immutablediff'

const UpdateAccountSchemeRowRecord = Record({
  rowFactor: undefined,
  defaultForecastingParameters: {}, // How do this smarter?
  accountRanges: [], // How do this smarter?
  name: undefined,
})

const increaseOrderPatches = (rows) =>
  rows.map((child) => ({
    op: 'replace',
    path: fixLeadingSlash(`${child.path}/order`),
    value: child.order + 1,
  }))

// Individual exports for testing
export function* addAccountSchemeRow(action) {
  try {
    const { activeRow, companyCode, id, row, parent } = action
    const { accountNumber, name, rowFactor, isConcernAccount } = row
    let path
    let orderPatches = []
    const value = {
      account: { accountNumber, name },
      rowFactor,
      isConcernAccount,
      rowType: 'Row',
    }

    // Just for now, put the new row above the selected row,
    // or in case group was selected, put the new row in the end of that group
    if (activeRow.rowType === 'Row') {
      const rowsAfter = parent.children.filter(
        (r) => r.order >= activeRow.order
      )
      orderPatches = increaseOrderPatches(rowsAfter.toJS())
      value.order = activeRow.order
      path = `${parent.path}/children/-`
    } else {
      path = `${activeRow.path}/children/-`
      value.order = findLastOrderNumber(activeRow) + 1
    }

    const patch = [{ op: 'add', path: fixLeadingSlash(path), value }].concat(
      orderPatches
    )
    const tree = yield call(accountSchemeApi.patchAccountSchemeTree, {
      companyCode,
      id,
      patch,
    })
    yield put(addAccountSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, addAccountSchemeRowError))
  }
}

//the same update is used for bot types of rows (header, row)
export function* updateAccountSchemeRowRow(action) {
  try {
    const { activeRow, companyCode, id, row } = action

    const patch = Diff(
      new UpdateAccountSchemeRowRecord(activeRow),
      new UpdateAccountSchemeRowRecord(row)
    ).map((operation) =>
      operation.update('path', (rowPath) => activeRow.path + rowPath)
    )

    const tree = yield call(accountSchemeApi.patchAccountSchemeTree, {
      companyCode,
      id,
      patch,
    })
    yield put(updateAccountSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, updateAccountSchemeRowError))
  }
}

export function* addAccountSchemeRowChildGroup(action) {
  try {
    const { activeRow, companyCode, id, rowGroup } = action
    const {
      accountGroupType,
      name,
      rowFactor,
      hideGroupSum,
      defaultForecastingParameters,
    } = rowGroup

    const path = `${activeRow.path}/children/0`

    const patch = [
      {
        op: 'add',
        path: fixLeadingSlash(path),
        value: {
          name,
          accountGroupType: accountGroupType.type,
          rowFactor,
          rowType: 'Header',
          order: 0,
          hideGroupSum,
          defaultForecastingParameters,
        },
      },
    ].concat(increaseOrderPatches(activeRow.children).toJS())

    const tree = yield call(accountSchemeApi.patchAccountSchemeTree, {
      companyCode,
      id,
      patch,
    })
    yield put(addAccountSchemeRowGroupSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, addAccountSchemeRowGroupError))
  }
}
export function* addAccountSchemeRowGroup(action) {
  try {
    const { activeRow, companyCode, id, rowGroup } = action
    const {
      accountGroupType,
      name,
      rowFactor,
      hideGroupSum,
      defaultForecastingParameters,
    } = rowGroup

    //increase paths last number by 1
    const path = activeRow.path.replace(/(\d+)$/, (_, num) => parseInt(num) + 1)

    const patch = [
      {
        op: 'add',
        path: fixLeadingSlash(path),
        value: {
          name,
          accountGroupType: accountGroupType.type,
          rowFactor,
          rowType: 'Header',
          order: 0,
          hideGroupSum,
          defaultForecastingParameters,
        },
      },
    ].concat(increaseOrderPatches(activeRow.children).toJS())

    const tree = yield call(accountSchemeApi.patchAccountSchemeTree, {
      companyCode,
      id,
      patch,
    })
    yield put(addAccountSchemeRowGroupSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, addAccountSchemeRowGroupError))
  }
}

export function* deleteAccountSchemeRow(action) {
  try {
    const { companyCode, id, path } = action
    const patch = [
      {
        op: 'remove',
        path: fixLeadingSlash(path),
      },
    ]
    const tree = yield call(accountSchemeApi.patchAccountSchemeTree, {
      companyCode,
      id,
      patch,
    })
    yield put(deleteAccountSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, deleteAccountSchemeRowError))
  }
}

export function* getAccountScheme(action) {
  try {
    const { companyCode, id } = action
    const accountScheme = yield call(accountSchemeApi.getAccountScheme, {
      companyCode,
      id,
    })
    yield put(getAccountSchemeSuccess({ accountScheme }))
  } catch (error) {
    yield put(handleError(error, getAccountSchemeError))
  }
}

export function* getAccountSchemeAndTree(action) {
  try {
    const { companyCode, id } = action
    const [accountScheme, tree] = yield all([
      call(accountSchemeApi.getAccountScheme, { companyCode, id }),
      call(accountSchemeApi.getAccountSchemeTree, { companyCode, id }),
    ])
    yield put(getAccountSchemeAndTreeSuccess({ accountScheme, tree }))
  } catch (error) {
    yield put(handleError(error, getAccountSchemeAndTreeError))
  }
}

export function* getAccountSchemeTree(action) {
  try {
    const { companyCode, id } = action
    const tree = yield call(accountSchemeApi.getAccountSchemeTree, {
      companyCode,
      id,
    })
    yield put(getAccountSchemeTreeSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, getAccountSchemeTreeError))
  }
}

export function* moveAccountSchemeRow(action) {
  try {
    const { companyCode, id, source, target, moveAfter } = action

    //To fix moving entity inside array sibling bug use copy remove & remove
    const patch = adjustTargetPathAndCheckPosition({
      source,
      target,
      moveAfter,
    })
      ? //Can't use this always, since if when moving to sibling before source, copy & remove will crash
        [
          {
            op: 'copy',
            from: `${source.path}`,
            path: `${target.path}`,
          },
          {
            op: 'remove',
            path: `${source.path}`,
          },
        ]
      : [
          {
            op: 'move',
            from: `${source.path}`,
            path: `${target.path}`,
          },
        ]
    const tree = yield call(accountSchemeApi.patchAccountSchemeTree, {
      companyCode,
      id,
      patch,
    })
    yield put(moveAccountSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, moveAccountSchemeRowError))
  }
}

const adjustTargetPathAndCheckPosition = ({ source, target, moveAfter }) => {
  const splitSource = source.path.split('/')
  const splitTarget = target.path.split('/')
  const pathComponents = target.path.split('/')
  const positionIndex = pathComponents.length - 1
  if (moveAfter) {
    pathComponents[positionIndex] = (
      parseInt(pathComponents[positionIndex]) + 1
    ).toString()
    target.path = pathComponents.join('/')
  }
  const isSibling = target.path.startsWith(splitSource.slice(0, -1).join('/'))
  const isMovingInside = splitTarget.length > splitSource.length
  const isBeforeInArray =
    splitTarget[splitSource.length - 1] > splitSource[splitSource.length - 1]

  if (
    isSibling &&
    isBeforeInArray &&
    !isNaN(positionIndex) &&
    !isMovingInside
  ) {
    pathComponents[positionIndex] = (
      parseInt(pathComponents[positionIndex]) - 1
    ).toString()
    target.path = pathComponents.join('/')
  }
  return isSibling && isMovingInside && isBeforeInArray
}

export function* setAccounSchemeSpecialAccount(action) {
  try {
    const { accountNumber, companyCode, id, specialAccount } = action

    const patch = [
      {
        op: 'replace',
        path: `/specialaccounts/${specialAccount}`,
        value: { accountNumber },
      },
    ]
    const accountScheme = yield call(accountSchemeApi.patchAccountScheme, {
      companyCode,
      id,
      patch,
    })
    yield put(setAccountSchemeSpecialAccountSuccess({ accountScheme }))
  } catch (error) {
    yield put(handleError(error, setAccountSchemeSpecialAccountError))
  }
}

// All sagas to be loaded
export function* accountSchemeSaga() {
  yield all([
    takeEvery(ADD_ACCOUNT_SCHEME_ROW, addAccountSchemeRow),
    takeEvery(ADD_ACCOUNT_SCHEME_ROW_GROUP, addAccountSchemeRowGroup),
    takeEvery(
      ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP,
      addAccountSchemeRowChildGroup
    ),
    takeEvery(DELETE_ACCOUNT_SCHEME_ROW, deleteAccountSchemeRow),
    takeLatest(GET_ACCOUNT_SCHEME, getAccountScheme),
    takeLatest(GET_ACCOUNT_SCHEME_TREE, getAccountSchemeTree),
    takeLatest(GET_ACCOUNT_SCHEME_AND_TREE, getAccountSchemeAndTree),
    takeEvery(MOVE_ACCOUNT_SCHEME_ROW, moveAccountSchemeRow),
    takeEvery(
      SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
      setAccounSchemeSpecialAccount
    ),
    takeEvery(UPDATE_ACCOUNT_SCHEME_ROW, updateAccountSchemeRowRow),
  ])
}
export default accountSchemeSaga
