import { Record } from 'immutable'

const AccountSchemeTemplateRowRecord = Record({
  editable: false,
  edit: false,
  endAccount: undefined,
  id: undefined,
  level: undefined,
  name: '',
  parentId: undefined,
  path: '',
  rowFactor: undefined,
  rowType: '',
  rowId: '',
  startAccount: undefined,
  showForm: undefined,
  order: 0,
  defaultForecastingParameters: null,
})

export default AccountSchemeTemplateRowRecord
