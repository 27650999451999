export const GET_REPORT_CONTEXT_DATA =
  'app/ReportContextDatas/GET_REPORT_CONTEXT_DATA'
export const GET_REPORT_CONTEXT_DATA_ERROR =
  'app/ReportContextDatas/GET_REPORT_CONTEXT_DATA_ERROR'
export const GET_REPORT_CONTEXT_DATA_SUCCESS =
  'app/ReportContextDatas/GET_REPORT_CONTEXT_DATA_SUCCESS'

export const GET_REPORT_CONTEXT_DATA_PROGRESS_SUCCESS =
  'app/ReportContextDatas/GET_REPORT_CONTEXT_DATA_PROGRESS_SUCCESS'

export const INVALIDATE_REPORT_CONTEXT_DATA_CACHE =
  'app/ReportContextDatas/INVALIDATE_REPORT_CONTEXT_DATA_CACHE'
export const INVALIDATE_REPORT_CONTEXT_DATA_CACHE_ERROR =
  'app/ReportContextDatas/INVALIDATE_REPORT_CONTEXT_DATA_CACHE_ERROR'
export const INVALIDATE_REPORT_CONTEXT_DATA_CACHE_SUCCESS =
  'app/ReportContextDatas/INVALIDATE_REPORT_CONTEXT_DATA_CACHE_SUCCESS'
