import { Record } from 'immutable'

const UserRoleRecord = Record({
  id: undefined,
  customerCode: undefined,
  companyCode: undefined,
  name: undefined,
})

export default UserRoleRecord
