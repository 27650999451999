/*
 *
 * ReportScheme actions
 *
 */

import {
  GET_REPORT_SCHEME_TREE,
  GET_REPORT_SCHEME_TREE_ERROR,
  GET_REPORT_SCHEME_TREE_SUCCESS,
  CREATE_REPORT_SCHEME_ROW,
  CREATE_REPORT_SCHEME_ROW_ERROR,
  CREATE_REPORT_SCHEME_ROW_SUCCESS,
  DELETE_REPORT_SCHEME_ROW,
  DELETE_REPORT_SCHEME_ROW_ERROR,
  DELETE_REPORT_SCHEME_ROW_SUCCESS,
  MOVE_REPORT_SCHEME_ROW,
  MOVE_REPORT_SCHEME_ROW_ERROR,
  MOVE_REPORT_SCHEME_ROW_SUCCESS,
  TOGGLE_COLLAPSE_ALL_GROUPS,
  TOGGLE_COLLAPSE_GROUP,
  TOGGLE_PERCENTAGE_TARGET,
  TOGGLE_PERCENTAGE_TARGET_ERROR,
  TOGGLE_PERCENTAGE_TARGET_SUCCESS,
  TOGGLE_SHOW_FORM,
  TOGGLE_SHOW_SETTINGS_FORM,
  UPDATE_REPORT_SCHEME_ROW,
  UPDATE_REPORT_SCHEME_ROW_ERROR,
  UPDATE_REPORT_SCHEME_ROW_SUCCESS,
  TOGGLE_COLLAPSE_GROUPS,
  COPY_REPORT_SCHEME_ROW,
  COPY_REPORT_SCHEME_ROW_ERROR,
  COPY_REPORT_SCHEME_ROW_SUCCESS,
} from './constants'

export const createReportSchemeRow = ({
  companyCode,
  customerCode,
  form,
  index,
  path,
  reportSchemeId,
  reportSchemeRowType,
}) => ({
  type: CREATE_REPORT_SCHEME_ROW,
  companyCode,
  customerCode,
  form,
  index,
  path,
  reportSchemeId,
  reportSchemeRowType,
})
export const createReportSchemeRowError = (error) => ({
  type: CREATE_REPORT_SCHEME_ROW_ERROR,
  error,
})
export const createReportSchemeRowSuccess = ({ tree }) => ({
  type: CREATE_REPORT_SCHEME_ROW_SUCCESS,
  tree,
})

export const deleteReportSchemeRow = ({
  companyCode,
  customerCode,
  path,
  reportSchemeId,
}) => ({
  type: DELETE_REPORT_SCHEME_ROW,
  companyCode,
  customerCode,
  path,
  reportSchemeId,
})
export const deleteReportSchemeRowError = (error) => ({
  type: DELETE_REPORT_SCHEME_ROW_ERROR,
  error,
})
export const deleteReportSchemeRowSuccess = ({ tree }) => ({
  type: DELETE_REPORT_SCHEME_ROW_SUCCESS,
  tree,
})

export const getReportSchemeTree = ({ companyCode, customerCode, id }) => ({
  companyCode,
  customerCode,
  type: GET_REPORT_SCHEME_TREE,
  id,
})
export const getReportSchemeTreeError = (error) => ({
  type: GET_REPORT_SCHEME_TREE_ERROR,
  error,
})
export const getReportSchemeTreeSuccess = ({ tree }) => ({
  type: GET_REPORT_SCHEME_TREE_SUCCESS,
  tree,
})

export const toggleCollapseAllGroups = ({ openGroupIds, tree }) => ({
  type: TOGGLE_COLLAPSE_ALL_GROUPS,
  openGroupIds,
  tree,
})

export const toggleCollapseGroup = ({ path, id, value }) => ({
  type: TOGGLE_COLLAPSE_GROUP,
  path,
  id,
  value,
})

export const toggleCollapseGroups = ({ rowIds, tree }) => ({
  type: TOGGLE_COLLAPSE_GROUPS,
  rowIds,
  tree,
})

export const togglePercentageTarget = ({
  companyCode,
  customerCode,
  groupId,
  operation,
  reportSchemeId,
}) => ({
  type: TOGGLE_PERCENTAGE_TARGET,
  companyCode,
  customerCode,
  groupId,
  operation,
  reportSchemeId,
})
export const togglePercentageTargetError = (error) => ({
  type: TOGGLE_PERCENTAGE_TARGET_ERROR,
  error,
})
export const togglePercentageTargetSuccess = ({
  companyCode,
  customerCode,
  result,
}) => ({
  type: TOGGLE_PERCENTAGE_TARGET_SUCCESS,
  companyCode,
  customerCode,
  result,
})

export const toggleShowForm = ({ edit = false, form, id, index, path }) => ({
  type: TOGGLE_SHOW_FORM,
  edit,
  form,
  id,
  index,
  path,
})
export const toggleShowSettingsForm = () => ({
  type: TOGGLE_SHOW_SETTINGS_FORM,
})

export const updateReportSchemeRow = ({
  companyCode,
  customerCode,
  form,
  index,
  path,
  reportSchemeId,
  reportSchemeRowType,
  row,
}) => ({
  type: UPDATE_REPORT_SCHEME_ROW,
  companyCode,
  customerCode,
  form,
  index,
  path,
  reportSchemeId,
  reportSchemeRowType,
  row,
})
export const updateReportSchemeRowError = (error) => ({
  type: UPDATE_REPORT_SCHEME_ROW_ERROR,
  error,
})
export const updateReportSchemeRowSuccess = ({ tree }) => ({
  type: UPDATE_REPORT_SCHEME_ROW_SUCCESS,
  tree,
})

export const moveReportSchemeRow = ({
  companyCode,
  customerCode,
  reportSchemeId,
  source,
  target,
  moveAfter,
}) => ({
  type: MOVE_REPORT_SCHEME_ROW,
  companyCode,
  customerCode,
  reportSchemeId,
  source,
  target,
  moveAfter,
})

export const moveReportSchemeRowError = (error) => ({
  type: MOVE_REPORT_SCHEME_ROW_ERROR,
  error,
})

export const moveReportSchemeRowSuccess = ({ tree }) => ({
  type: MOVE_REPORT_SCHEME_ROW_SUCCESS,
  tree,
})

export const copyReportSchemeRow = ({
  companyCode,
  customerCode,
  reportSchemeId,
  source,
  target,
  moveAfter,
}) => ({
  type: COPY_REPORT_SCHEME_ROW,
  companyCode,
  customerCode,
  reportSchemeId,
  source,
  target,
  moveAfter,
})

export const copyReportSchemeRowError = (error) => ({
  type: COPY_REPORT_SCHEME_ROW_ERROR,
  error,
})

export const copyReportSchemeRowSuccess = ({ tree }) => ({
  type: COPY_REPORT_SCHEME_ROW_SUCCESS,
  tree,
})
