import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router'

const NavItem = ({
  dataTestingId,
  formatMessage,
  messageId,
  onClick,
  to,
  ...rest
}) => {
  const navigate = useNavigate()
  return (
    <Dropdown.Item
      data-testing-id={dataTestingId}
      onClick={() => {
        if (to) navigate(to)
        if (onClick) onClick()
      }}
      {...rest}
    >
      {formatMessage(messageId)}
    </Dropdown.Item>
  )
}

export default NavItem
