import { Record } from 'immutable'

const AccountSchemeTemplateRowGroupRecord = Record({
  accountGroupType: undefined,
  children: undefined,
  editable: false,
  edit: false,
  hideGroupSum: false,
  id: undefined,
  level: undefined,
  name: '',
  order: '',
  parentId: undefined,
  path: '',
  rowFactor: undefined,
  rowId: '',
  rowType: '',
  showForm: undefined,
  defaultForecastingParameters: null,
})

export default AccountSchemeTemplateRowGroupRecord
