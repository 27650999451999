/*
 *
 * Dimension constants
 *
 */

export const GET_DIMENSION = 'app/Dimension/GET_DIMENSION'
export const GET_DIMENSION_ERROR = 'app/Dimension/GET_DIMENSION_ERROR'
export const GET_DIMENSION_SUCCESS = 'app/Dimension/GET_DIMENSION_SUCCESS'

export const UPDATE_DIMENSION = 'app/Dimension/UPDATE_DIMENSION'
export const UPDATE_DIMENSION_ERROR = 'app/Dimension/UPDATE_DIMENSION_ERROR'
export const UPDATE_DIMENSION_SUCCESS = 'app/Dimension/UPDATE_DIMENSION_SUCCESS'

export const DELETE_DIMENSION_VALUE = 'app/Dimension/DELETE_DIMENSION_VALUE'
export const DELETE_DIMENSION_VALUE_ERROR =
  'app/Dimension/DELETE_DIMENSION_VALUE_ERROR'
export const DELETE_DIMENSION_VALUE_SUCCESS =
  'app/Dimension/DELETE_DIMENSION_VALUE_SUCCESS'

export const CREATE_DIMENSION_VALUE = 'app/Dimension/CREATE_DIMENSION_VALUE'
export const CREATE_DIMENSION_VALUE_ERROR =
  'app/Dimension/CREATE_DIMENSION_VALUE_ERROR'
export const CREATE_DIMENSION_VALUE_SUCCESS =
  'app/Dimension/CREATE_DIMENSION_VALUE_SUCCESS'

export const TOGGLE_SHOW_DIMENSION_VALUE_FORM =
  'app/Dimension/TOGGLE_SHOW_DIMENSION_VALUE_FORM'
