import PropTypes from 'prop-types'
import React from 'react'

const withTheme = (Child, defaultStyle = {}, themeStyles = {}) =>
  class extends React.Component {
    static contextTypes = {
      theme: PropTypes.string.isRequired,
    }

    render() {
      return (
        <Child
          {...this.props}
          theme={this.context.theme}
          styles={{
            ...defaultStyle,
            ...themeStyles[this.context.theme],
          }}
        />
      )
    }
  }

export default withTheme
