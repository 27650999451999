import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.containers.SubBudgetSchemeImportModal.header',
    defaultMessage: 'Import Sub Budget Scheme',
  },
  name: {
    id: 'app.containers.SubBudgetSchemeImportModal.name',
    defaultMessage: 'Name',
  },
})
