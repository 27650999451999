export const CREATE_FAS_ACQUISITION_VOUCHERS =
  'app/FasFormSelection/CREATE_FAS_ACQUISITION_VOUCHERS'
export const CREATE_FAS_ACQUISITION_VOUCHERS_ERROR =
  'app/FasFormSelection/CREATE_FAS_ACQUISITION_VOUCHERS_ERROR'
export const CREATE_FAS_ACQUISITION_VOUCHERS_SUCCESS =
  'app/FasFormSelection/CREATE_FAS_ACQUISITION_VOUCHERS_SUCCESS'
export const GET_ACQUISITION_FORMS =
  'app/FasFormSelection/GET_ACQUISITION_FORMS'
export const GET_ACQUISITION_FORMS_ERROR =
  'app/FasFormSelection/GET_ACQUISITION_FORMS_ERROR'
export const GET_ACQUISITION_FORMS_SUCCESS =
  'app/FasFormSelection/GET_ACQUISITION_FORMS_SUCCESS'
export const GET_ALL_CREATED_ACQUISITIONS =
  'app/FasFormSelection/GET_ALL_CREATED_ACQUISITIONS'
export const GET_ALL_CREATED_ACQUISITIONS_ERROR =
  'app/FasFormSelection/GET_ALL_CREATED_ACQUISITIONS_ERROR'
export const GET_ALL_CREATED_ACQUISITIONS_SUCCESS =
  'app/FasFormSelection/GET_ALL_CREATED_ACQUISITIONS_SUCCESS'
export const CREATE_EDITED_FAS_ACQUISITION_VOUCHERS =
  'app/FasFormSelection/CREATE_EDITED_FAS_ACQUISITION_VOUCHERS'
export const CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_ERROR =
  'app/FasFormSelection/CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_ERROR'
export const CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_SUCCESS =
  'app/FasFormSelection/CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_SUCCESS'
export const DELETE_ACQUISITION = 'app/FasFormSelection/DELETE_ACQUISITION'
export const DELETE_ACQUISITION_ERROR =
  'app/FasFormSelection/DELETE_ACQUISITION_ERROR'
export const DELETE_ACQUISITION_SUCCESS =
  'app/FasFormSelection/DELETE_ACQUISITION_SUCCESS'
