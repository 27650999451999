import {
  GET_REPORT_CONTEXT_DATA,
  GET_REPORT_CONTEXT_DATA_ERROR,
  GET_REPORT_CONTEXT_DATA_SUCCESS,
  GET_REPORT_CONTEXT_DATA_PROGRESS_SUCCESS,
  INVALIDATE_REPORT_CONTEXT_DATA_CACHE,
  INVALIDATE_REPORT_CONTEXT_DATA_CACHE_ERROR,
  INVALIDATE_REPORT_CONTEXT_DATA_CACHE_SUCCESS,
} from './constants'

export const getReportContextData = ({
  companyCode,
  enableCaching,
  reportId,
}) => ({
  type: GET_REPORT_CONTEXT_DATA,
  companyCode,
  enableCaching,
  reportId,
})
export const getReportContextDataError = ({ companyCode, error, reportId }) => {
  return {
    type: GET_REPORT_CONTEXT_DATA_ERROR,
    companyCode,
    error,
    reportId,
  }
}
export const getReportContextDataSuccess = ({
  companyCode,
  report,
  reportId,
}) => ({
  type: GET_REPORT_CONTEXT_DATA_SUCCESS,
  companyCode,
  report,
  reportId,
})
export const getReportContextDataProgressSuccess = ({
  progress,
  reportId,
}) => ({
  type: GET_REPORT_CONTEXT_DATA_PROGRESS_SUCCESS,
  progress,
  reportId,
})
export const invalidateReportContextDataCache = ({
  companyCode,
  reportId,
}) => ({
  type: INVALIDATE_REPORT_CONTEXT_DATA_CACHE,
  companyCode,
  reportId,
})
export const invalidateReportContextDataCacheError = ({
  companyCode,
  error,
}) => ({
  type: INVALIDATE_REPORT_CONTEXT_DATA_CACHE_ERROR,
  error,
  companyCode,
})
export const invalidateReportContextDataCacheSuccess = ({
  companyCode,
  reportId,
}) => ({
  type: INVALIDATE_REPORT_CONTEXT_DATA_CACHE_SUCCESS,
  companyCode,
  reportId,
})
