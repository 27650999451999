import {
  COPY_CUSTOMER_DASHBOARD,
  COPY_CUSTOMER_DASHBOARD_ERROR,
  COPY_CUSTOMER_DASHBOARD_SUCCESS,
  COPY_CUSTOMER_DASHBOARD_WIDGET,
  COPY_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  COPY_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  CREATE_CUSTOMER_DASHBOARD,
  CREATE_CUSTOMER_DASHBOARD_ERROR,
  CREATE_CUSTOMER_DASHBOARD_SUCCESS,
  CREATE_CUSTOMER_DASHBOARD_WIDGET,
  CREATE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  CREATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  DELETE_CUSTOMER_DASHBOARD,
  DELETE_CUSTOMER_DASHBOARD_ERROR,
  DELETE_CUSTOMER_DASHBOARD_SUCCESS,
  DELETE_CUSTOMER_DASHBOARD_WIDGET,
  DELETE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  DELETE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  GET_CUSTOMER_DASHBOARDS,
  GET_CUSTOMER_DASHBOARDS_ERROR,
  GET_CUSTOMER_DASHBOARDS_SUCCESS,
  UPDATE_CUSTOMER_DASHBOARD,
  UPDATE_CUSTOMER_DASHBOARD_ERROR,
  UPDATE_CUSTOMER_DASHBOARD_SUCCESS,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_ERROR,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
} from './constants'

export const copyCustomerDashboard = ({
  companyIds,
  customerCode,
  description,
  id,
  name,
}) => ({
  type: COPY_CUSTOMER_DASHBOARD,
  companyIds,
  customerCode,
  description,
  id,
  name,
})
export const copyCustomerDashboardError = ({ error }) => ({
  type: COPY_CUSTOMER_DASHBOARD_ERROR,
  error,
})
export const copyCustomerDashboardSuccess = ({ customerCode, dashboard }) => ({
  type: COPY_CUSTOMER_DASHBOARD_SUCCESS,
  customerCode,
  dashboard,
})

export const createCustomerDashboard = ({
  companyIds,
  customerCode,
  description,
  name,
}) => ({
  type: CREATE_CUSTOMER_DASHBOARD,
  companyIds,
  customerCode,
  description,
  name,
})
export const createCustomerDashboardError = ({ error }) => ({
  type: CREATE_CUSTOMER_DASHBOARD_ERROR,
  error,
})
export const createCustomerDashboardSuccess = ({
  customerCode,
  dashboard,
}) => ({
  type: CREATE_CUSTOMER_DASHBOARD_SUCCESS,
  customerCode,
  dashboard,
})

export const copyCustomerDashboardWidget = ({
  customerCode,
  dashboardId,
  widget,
}) => ({
  type: COPY_CUSTOMER_DASHBOARD_WIDGET,
  customerCode,
  dashboardId,
  widget,
})
export const copyCustomerDashboardWidgetError = () => ({
  type: COPY_CUSTOMER_DASHBOARD_WIDGET_ERROR,
})

export const copyCustomerDashboardWidgetSuccess = ({
  customerCode,
  widget,
  temporaryWidgetId,
}) => ({
  type: COPY_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  customerCode,
  widget,
  temporaryWidgetId,
})

export const createCustomerDashboardWidget = ({
  customerCode,
  dashboardId,
  widget,
}) => ({
  type: CREATE_CUSTOMER_DASHBOARD_WIDGET,
  customerCode,
  dashboardId,
  widget,
})
export const createCustomerDashboardWidgetError = () => ({
  type: CREATE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
})

export const createCustomerDashboardWidgetSuccess = ({
  customerCode,
  widget,
  temporaryWidgetId,
}) => ({
  type: CREATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  customerCode,
  widget,
  temporaryWidgetId,
})

export const deleteCustomerDashboard = ({ customerCode, id, navigate }) => ({
  type: DELETE_CUSTOMER_DASHBOARD,
  customerCode,
  id,
  navigate,
})
export const deleteCustomerDashboardError = ({ id, error }) => ({
  type: DELETE_CUSTOMER_DASHBOARD_ERROR,
  id,
  error,
})
export const deleteCustomerDashboardSuccess = ({ customerCode, id }) => ({
  type: DELETE_CUSTOMER_DASHBOARD_SUCCESS,
  customerCode,
  id,
})

export const deleteCustomerDashboardWidget = ({
  customerCode,
  dashboardId,
  widgetId,
}) => ({
  type: DELETE_CUSTOMER_DASHBOARD_WIDGET,
  customerCode,
  dashboardId,
  widgetId,
})

export const deleteCustomerDashboardWidgetSuccess = () => ({
  type: DELETE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
})

export const deleteCustomerDashboardWidgetError = () => ({
  type: DELETE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
})

export const getCustomerDashboards = ({ customerCode }) => ({
  type: GET_CUSTOMER_DASHBOARDS,
  customerCode,
})
export const getCustomerDashboardsError = (error) => ({
  type: GET_CUSTOMER_DASHBOARDS_ERROR,
  error,
})
export const getCustomerDashboardsSuccess = ({ customerCode, dashboards }) => ({
  type: GET_CUSTOMER_DASHBOARDS_SUCCESS,
  customerCode,
  dashboards,
})

export const updateCustomerDashboard = ({
  customerCode,
  dashboard,
  updatedDashboard,
}) => ({
  type: UPDATE_CUSTOMER_DASHBOARD,
  customerCode,
  dashboard,
  updatedDashboard,
})
export const updateCustomerDashboardError = ({ error, dashboardId }) => ({
  type: UPDATE_CUSTOMER_DASHBOARD_ERROR,
  dashboardId,
  error,
})
export const updateCustomerDashboardSuccess = ({
  customerCode,
  dashboard,
}) => ({
  type: UPDATE_CUSTOMER_DASHBOARD_SUCCESS,
  customerCode,
  dashboard,
})

export const updateCustomerDashboardWidget = ({
  customerCode,
  dashboardId,
  editWidget,
  widget,
}) => ({
  type: UPDATE_CUSTOMER_DASHBOARD_WIDGET,
  customerCode,
  dashboardId,
  editWidget,
  widget,
})
export const updateCustomerDashboardWidgetError = ({ error, widgetId }) => ({
  type: UPDATE_CUSTOMER_DASHBOARD_WIDGET_ERROR,
  error,
  widgetId,
})
export const updateCustomerDashboardWidgetSuccess = ({
  customerCode,
  dashboardId,
  widget,
}) => ({
  type: UPDATE_CUSTOMER_DASHBOARD_WIDGET_SUCCESS,
  customerCode,
  dashboardId,
  widget,
})

export const updateCustomerDashboardWidgetParameters = ({
  customerCode,
  dashboardId,
  widgetId,
  parameters,
}) => ({
  type: UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS,
  customerCode,
  dashboardId,
  widgetId,
  parameters,
})

export const updateCustomerDashboardWidgetParametersSuccess = () => ({
  type: UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
})

export const updateCustomerDashboardWidgetParametersError = () => ({
  type: UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS_ERROR,
})
