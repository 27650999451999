import {
  SET_CASH_IN_HAND,
  SET_CASH_IN_HAND_ERROR,
  SET_CASH_IN_HAND_SUCCESS,
} from './constants'

export const setCashInHand = ({ companyCode, amount }) => ({
  type: SET_CASH_IN_HAND,
  companyCode,
  amount,
})

export const setCashInHandError = () => ({
  type: SET_CASH_IN_HAND_ERROR,
})

export const setCashInHandSuccess = ({ amount }) => ({
  type: SET_CASH_IN_HAND_SUCCESS,
  amount,
})
