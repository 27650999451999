import axios from 'axios'
import { List } from 'immutable'
import moment from 'moment'
import Papa from 'papaparse'
import qs from 'qs'

import {
  returnData,
  config,
  catchError,
  accountNumberRange,
  NUM_AND_CHAR_REGEX,
  NUM_ONLY_REGEX,
} from './api-utils'
import isNumeric from 'utils/isNumeric'

import {
  ParsedBudgetBalanceRecord,
  ParsedBudgetBalanceErrorRecord,
} from 'records'
import { fetchEventSource } from '@microsoft/fetch-event-source'

const paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' })

const createBudget = ({ companyCode, ...params }) =>
  axios
    .post(`${companyCode}/budgets`, params, config())
    .then(returnData)
    .catch(catchError)

const createBudgetFolder = ({ companyCode, ...params }) =>
  axios
    .post(`${companyCode}/budgets/folders`, params, config())
    .then(returnData)
    .catch(catchError)

const copyBudget = ({
  budgetId,
  companyCode,
  name,
  copyUserRights,
  copySubBudgets,
  copyData,
  copyNotes,
  copySubBudgetNotes,
  copySubBudgetRollingRules,
  copySubBudgetTags,
  copySubBudgetData,
  moveForMonths,
  dimensionIdWhitelist,
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/copy`,
      {
        name,
        copyUserRights,
        copySubBudgets,
        copyData,
        copyNotes,
        copySubBudgetNotes,
        copySubBudgetRollingRules,
        copySubBudgetTags,
        copySubBudgetData,
        moveForMonths,
        dimensionIdWhitelist,
      },
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteBudget = ({ companyCode, id }) =>
  axios
    .delete(`${companyCode}/budgets/${id}`, config())
    .then(returnData)
    .catch(catchError)

const deleteBudgetFolder = ({ companyCode, id }) =>
  axios
    .delete(`${companyCode}/budgets/folders/${id}`, config())
    .then(returnData)
    .catch(catchError)

const editBudgetFolder = ({ companyCode, id, name }) =>
  axios
    .patch(
      `${companyCode}/budgets/folders/${id}`,
      {
        name,
      },
      config()
    )
    .then(returnData)
    .catch(catchError)

const getBudgets = (companyCode) =>
  axios
    .get(`${companyCode}/budgets`, config())
    .then(returnData)
    .catch(catchError)

const getBudgetFolders = (companyCode) =>
  axios
    .get(`${companyCode}/budgets/folders`, config())
    .then(returnData)
    .catch(catchError)

const getBudgetTree = ({ budgetId, companyCode }) =>
  axios
    .get(`${companyCode}/budgets/${budgetId}/tree`, config())
    .then(returnData)
    .catch(catchError)

const importBudgetBalances = ({ budgetBalances, budgetId, companyCode }) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/balances/csv`,
      budgetBalances,
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchBudget = ({ budgetId, companyCode, patch }) =>
  axios
    .patch(`${companyCode}/budgets/${budgetId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const getBudgetData = ({
  budgetId,
  companyCode,
  end,
  start,
  dv,
  onProgress,
  onError,
  onMessage,
}) => {
  const configObj = config()
  let baseUrl = configObj.baseURL + `${companyCode}/budgets/${budgetId}/data?`
  if (end) baseUrl += `end=${end}&`
  if (start) baseUrl += `start=${start}&`
  if (Array.isArray(dv)) baseUrl += dv.map((dv) => `dv=${dv}&`).join('')
  else if (dv) baseUrl += `dv=${dv}&`
  fetchEventSource(baseUrl, {
    headers: { ...configObj.headers, 'Content-Type': 'application/json' },
    method: 'GET',
    openWhenHidden: true,
    onmessage: (event) => {
      if (event.event === 'progress' && onProgress) {
        onProgress(event)
        return
      }
      if (event.event === 'error' && onError) {
        onError(event)
        return
      }
      if (event.event === 'keepalive') {
        return
      }
      if (onMessage) {
        onMessage(event)
        return
      }
    },
    onerror: (error) => {
      if (onError) {
        onError(error)
      }
      // Event source library atomaticly retries if error is not thrown
      throw new Error(error)
    },
  }).catch(() => {})
}

const parseBudgetBalances = ({
  accountNumbers,
  allowLetters = false,
  max = accountNumberRange.MAX,
  min = accountNumberRange.MIN,
  endDate,
  file,
  startDate,
}) =>
  new Promise((resolve, reject) => {
    const parsedBudgetBalances = []
    const regex = allowLetters ? NUM_AND_CHAR_REGEX : NUM_ONLY_REGEX
    let parseError
    let rowNumber = 2 // First row is header

    Papa.parse(file, {
      dynamicTyping: true,
      header: true,
      skipEmptyLines: true,
      complete: () => {
        if (parseError) {
          reject(parseError)
        }
        resolve({ parsedBudgetBalances, parsedFile: file.name })
      },
      error: (error) => reject(error),
      step: (row, parser) => {
        const { data, meta, errors } = row
        if (rowNumber === 2 && meta.fields.length < 6) {
          parseError = {
            status: 'HeaderError',
            statusText: 'CSV header is not valid!',
          }
          parser.abort()
        }

        const accountNumber = data[0][meta.fields[0]]
        const validAccountNumber = regex.test(accountNumber)
        if (!validAccountNumber) {
          errors.push({
            type: 'FieldTypeError',
            code: 'NotAValidAccountNumber',
            message: `Account number ${accountNumber} is not a valid number!`,
            row: rowNumber,
          })
        } else if (!accountNumbers.includes(accountNumber.toString())) {
          errors.push({
            type: 'FieldTypeError',
            code: 'AccountNumberNotFound',
            message: `Account number ${accountNumber} does not exist`,
            row: rowNumber,
          })
        } else if (accountNumber < min || accountNumber > max) {
          errors.push({
            type: 'FieldTypeError',
            code: 'AccountNumberNotInRange',
            message: `Account number ${accountNumber} is not in between range (${min}-${max})!`,
            row: rowNumber,
          })
        }

        const year = data[0][meta.fields[1]]
        const validYear = moment(year, 'YYYY', true).isValid()
        if (!validYear) {
          errors.push({
            type: 'FieldTypeError',
            code: 'NotAValidYear',
            message: `Year ${year} is not a valid year!`,
            row: rowNumber,
          })
        }

        const month = data[0][meta.fields[2]]
        const validMonth = moment(month, 'M', true).isValid()
        if (!validMonth) {
          errors.push({
            type: 'FieldTypeError',
            code: 'NotAValidMonth',
            message: `Month ${month} is not a valid month!`,
            row: rowNumber,
          })
        }

        if (validYear && validMonth) {
          const date = moment(`${year}-${month}`)

          if (date.isBefore(startDate) || date.isAfter(endDate)) {
            errors.push({
              type: 'FieldTypeError',
              code: 'DateNotInRange',
              message: `Date (${year}-${month}) not in between budget start date (${startDate}) and end date (${endDate})!`,
              row: rowNumber,
            })
          }
        }

        const field = data[0][meta.fields[3]]
        const amount = parseFloat(
          typeof field === 'string' ? field.replace(',', '.') : field
        )
        const validAmount = isNumeric(amount)
        if (!validAmount) {
          errors.push({
            type: 'FieldTypeError',
            code: 'NotANumber',
            message: `Amount ${amount} is not a valid number!`,
            row: rowNumber,
          })
        }

        const dimensionCode = data[0][meta.fields[4]]
        const dimensionValueCode = data[0][meta.fields[5]]
        if (!dimensionCode && dimensionValueCode) {
          errors.push({
            type: 'FieldType',
            code: 'EmptyDimensionCodeField',
            message: 'Empty field: dimension code missing!',
            row: rowNumber,
          })
        } else if (dimensionCode && !dimensionValueCode) {
          errors.push({
            type: 'FieldType',
            code: 'EmptyDimensionValueCodeField',
            message: 'Empty field: dimension value code missing!',
            row: rowNumber,
          })
        }

        const balanceComparison = (balance) => {
          const {
            accountNumber: balanceAccountNumber,
            year: balanceYear,
            month: balanceMonth,
            dimensionCode: balanceDimensionCode,
            dimensionValueCode: balanceDimensionValueCode,
          } = balance
          return (
            balanceAccountNumber === accountNumber &&
            balanceYear === year &&
            balanceMonth === month &&
            balanceDimensionCode === dimensionCode &&
            balanceDimensionValueCode === dimensionValueCode
          )
        }

        if (
          parsedBudgetBalances.some((balance) => balanceComparison(balance))
        ) {
          errors.push({
            type: 'FieldType',
            code: 'Duplicate',
            message: 'Duplicate entry!',
            row: rowNumber,
          })
        }

        parsedBudgetBalances.push(
          new ParsedBudgetBalanceRecord({
            accountNumber,
            year,
            month,
            amount,
            dimensionCode,
            dimensionValueCode,
            errors: List(
              errors.map((error) => new ParsedBudgetBalanceErrorRecord(error))
            ),
            rowNumber,
          })
        )

        rowNumber += 1
      },
    })
  })

const patchBudgetData = ({ companyCode, budgetId, dv, start, end, patch }) =>
  axios
    .patch(
      `${companyCode}/budgets/${budgetId}/data`,
      patch,
      config({
        withCredentials: true,
        params: {
          start,
          end,
          dv,
        },
        paramsSerializer,
      })
    )
    .then(returnData)
    .catch(catchError)

const patchBudgetTree = ({ companyCode, budgetId, patch }) =>
  axios
    .patch(`${companyCode}/budgets/${budgetId}/tree`, patch, config())
    .then(returnData)
    .catch(catchError)

// AccountScheme -> Budget sync api

// Get a preview of the synced budget
const getBudgetSync = ({ companyCode, budgetId }) =>
  axios
    .get(`${companyCode}/budgets/${budgetId}/sync`, config())
    .then(returnData)
    .catch(catchError)

// Apply AccountScheme changes to budget (sync it)
const applyBudgetSync = ({ companyCode, budgetId }) =>
  axios
    .post(`${companyCode}/budgets/${budgetId}/sync`, undefined, config())
    .then(returnData)
    .catch(catchError)

const setDefault = ({ companyCode, id }) =>
  axios
    .post(`${companyCode}/budgets/${id}/default`, null, config())
    .then(returnData)
    .catch(catchError)

const budgetApi = {
  applyBudgetSync,
  copyBudget,
  createBudget,
  createBudgetFolder,
  editBudgetFolder,
  deleteBudget,
  deleteBudgetFolder,
  getBudgets,
  getBudgetFolders,
  getBudgetData,
  getBudgetSync,
  getBudgetTree,
  importBudgetBalances,
  parseBudgetBalances,
  patchBudget,
  patchBudgetData,
  patchBudgetTree,
  setDefault,
}

export default budgetApi
