/*
 *
 * CalculationConstant reducer
 *
 */
import { fromJS } from 'immutable'

import {
  CREATE_CALCULATION_CONSTANT_VALUE_ERROR,
  CREATE_CALCULATION_CONSTANT_VALUE_SUCCESS,
  DELETE_CALCULATION_CONSTANT_VALUE_ERROR,
  DELETE_CALCULATION_CONSTANT_VALUE_SUCCESS,
  EDIT_CALCULATION_CONSTANT_VALUE,
  TOGGLE_SHOW_CALCULATION_CONSTANT_VALUE_FORM,
  UPDATE_CALCULATION_CONSTANT_VALUE_ERROR,
  UPDATE_CALCULATION_CONSTANT_VALUE_SUCCESS,
} from './constants'

const initialState = fromJS({
  edit: undefined,
  error: false,
  loading: false,
  showForm: false,
})

const calculationConstantReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CALCULATION_CONSTANT_VALUE_SUCCESS:
      return state.set('showForm', false).set('loading', false)

    case CREATE_CALCULATION_CONSTANT_VALUE_ERROR:
    case DELETE_CALCULATION_CONSTANT_VALUE_ERROR:
    case UPDATE_CALCULATION_CONSTANT_VALUE_ERROR:
      return state.set('loading', false).set('error', action.error)

    case DELETE_CALCULATION_CONSTANT_VALUE_SUCCESS:
      return state

    case EDIT_CALCULATION_CONSTANT_VALUE:
      return state.set('edit', action.index)

    case TOGGLE_SHOW_CALCULATION_CONSTANT_VALUE_FORM:
      return state.set('showForm', !state.get('showForm'))

    case UPDATE_CALCULATION_CONSTANT_VALUE_SUCCESS:
      return state.set('edit', undefined)

    default:
      return state
  }
}

export default calculationConstantReducer
