import { Record } from 'immutable'

const CalculationConstantRecord = Record({
  id: undefined,
  name: '',
  symbol: '',
  unit: '',
  definedInScope: '',
  values: [],
})

export default CalculationConstantRecord
