/*
 *
 * AccountSchemeTemplates reducer
 *
 */
import { fromJS, List } from 'immutable'

import {
  COPY_ACCOUNT_SCHEME_TEMPLATE,
  CREATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  CREATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  DELETE_ACCOUNT_SCHEME_TEMPLATE,
  DELETE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  DELETE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  EDIT_ACCOUNT_SCHEME_TEMPLATE,
  GET_ACCOUNT_SCHEME_TEMPLATES,
  GET_ACCOUNT_SCHEME_TEMPLATES_ERROR,
  GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS,
  TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
} from './constants'
import { AccountSchemeTemplateRecord } from 'records'

const initialState = fromJS({
  accountSchemeTemplates: undefined,
  edit: undefined,
  error: false,
  loading: true,
  showAccountSchemeTemplateForm: false,
})

function accountSchemeTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_ACCOUNT_SCHEME_TEMPLATE:
    case COPY_ACCOUNT_SCHEME_TEMPLATE:
    case UPDATE_ACCOUNT_SCHEME_TEMPLATE:
      return state.set('loading', true).set('error', false)

    case CREATE_ACCOUNT_SCHEME_TEMPLATE_ERROR:
    case DELETE_ACCOUNT_SCHEME_TEMPLATE_ERROR:
    case GET_ACCOUNT_SCHEME_TEMPLATES_ERROR:
    case UPDATE_ACCOUNT_SCHEME_TEMPLATE_ERROR:
      return state.set('loading', false).set('error', action.error)

    case CREATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS:
      return state
        .set('showAccountSchemeTemplateForm', false)
        .set('loading', false)
        .set(
          'accountSchemeTemplates',
          state
            .get('accountSchemeTemplates')
            .push(new AccountSchemeTemplateRecord(action.accountSchemeTemplate))
        )

    case DELETE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'accountSchemeTemplates',
          state
            .get('accountSchemeTemplates')
            .filter(
              (accountSchemeTemplate) => accountSchemeTemplate.id !== action.id
            )
        )

    case EDIT_ACCOUNT_SCHEME_TEMPLATE:
      return state
        .set('edit', action.id)
        .set('showAccountSchemeTemplateForm', false)

    case GET_ACCOUNT_SCHEME_TEMPLATES:
      return state.set('error', false).set('loading', true)

    case GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS:
      return state
        .set(
          'accountSchemeTemplates',
          List(
            action.accountSchemeTemplates.map(
              (accountSchemeTemplate) =>
                new AccountSchemeTemplateRecord(accountSchemeTemplate)
            )
          )
        )
        .set('loading', false)

    case TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM:
      return state
        .set('edit', undefined)
        .set(
          'showAccountSchemeTemplateForm',
          !state.get('showAccountSchemeTemplateForm')
        )

    case UPDATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS: {
      const accountSchemeTemplates = state.get('accountSchemeTemplates')
      const index = accountSchemeTemplates.findIndex(
        (item) => item.id === action.accountSchemeTemplate.id
      )

      return state
        .set('loading', false)
        .set('edit', undefined)
        .set(
          'accountSchemeTemplates',
          accountSchemeTemplates.setIn(
            [index],
            new AccountSchemeTemplateRecord({ ...action.accountSchemeTemplate })
          )
        )
    }
    default:
      return state
  }
}

export default accountSchemeTemplatesReducer
