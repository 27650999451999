import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'

import { handleError } from 'api/api-utils'
import reportingGroupApi from 'api/ReportingGroupApi'

import {
  ADD_DIMENSIONS_TO_REPORTING_GROUP,
  ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS,
  CREATE_REPORTING_GROUP,
  CREATE_REPORTING_GROUP_SUCCESS,
  DELETE_REPORTING_GROUP,
  DELETE_REPORTING_GROUP_SUCCESS,
  GET_REPORTING_GROUPS,
  REMOVE_DIMENSION,
  REMOVE_DIMENSION_VALUE,
  UPDATE_REPORTING_GROUP,
  UPDATE_REPORTING_GROUP_SUCCESS,
} from './constants'
import {
  addDimensionsToReportingGroupError,
  addDimensionsToReportingGroupSuccess,
  createReportingGroupError,
  createReportingGroupSuccess,
  deleteReportingGroupError,
  deleteReportingGroupSuccess,
  getReportingGroupsError,
  getReportingGroupsSuccess,
  removeDimensionError,
  removeDimensionSuccess,
  removeDimensionValueError,
  removeDimensionValueSuccess,
  updateReportingGroupSuccess,
  updateReportingGroupError,
} from './actions'

const UpdateReportingGroupRecord = Record({
  name: undefined,
  parentId: undefined,
})

export function* deleteReportingGroup(action) {
  const { companyCode, id } = action

  try {
    yield call(reportingGroupApi.deleteReportingGroup, { companyCode, id })
    yield put(deleteReportingGroupSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteReportingGroupError))
  }
}

export function* createReportingGroup(action) {
  const { companyCode, reportingGroup, dimensions, dimensionValues } = action

  try {
    // First create the reporting group
    const { id } = yield call(reportingGroupApi.createReportingGroup, {
      companyCode,
      reportingGroup,
    })
    // After that, add all optional dimensions to that group
    const dimensionCalls = dimensions
      ? dimensions.map((d) =>
          call(reportingGroupApi.addDimension, {
            companyCode,
            reportingGroupId: id,
            dimensionId: d.id,
          })
        )
      : []
    // After that, add all optional dimensionValues to that group
    const dimensionValueCalls = dimensionValues
      ? dimensionValues.map((d) =>
          call(reportingGroupApi.addDimensionValue, {
            companyCode,
            reportingGroupId: id,
            dimensionValueId: d.id,
          })
        )
      : []
    // Execute the calls
    yield dimensionCalls.concat(dimensionValueCalls)
    yield put(createReportingGroupSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, createReportingGroupError))
  }
}

export function* getReportingGroups(action) {
  const { companyCode } = action

  try {
    const reportingGroups = yield call(reportingGroupApi.getReportingGroups, {
      companyCode,
    })
    yield put(getReportingGroupsSuccess({ reportingGroups, companyCode }))
  } catch (error) {
    yield put(handleError(error, getReportingGroupsError))
  }
}

export function* updateReportingGroup(action) {
  const { companyCode, newReportingGroup, oldReportingGroup } = action

  const patch = Diff(
    new UpdateReportingGroupRecord(oldReportingGroup),
    new UpdateReportingGroupRecord(newReportingGroup)
  ).toJS()

  try {
    yield call(reportingGroupApi.patchReportingGroup, {
      companyCode,
      id: oldReportingGroup.id,
      patch,
    })
    yield put(updateReportingGroupSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, updateReportingGroupError))
  }
}

export function* addDimensions(action) {
  const { companyCode, reportingGroupId, dimensions, dimensionValues } = action

  try {
    const dimensionCalls = dimensions
      ? dimensions.map((d) =>
          call(reportingGroupApi.addDimension, {
            companyCode,
            reportingGroupId,
            dimensionId: d.id,
          })
        )
      : []
    const dimensionValueCalls = dimensionValues
      ? dimensionValues.map((d) =>
          call(reportingGroupApi.addDimensionValue, {
            companyCode,
            reportingGroupId,
            dimensionValueId: d.id,
          })
        )
      : []
    yield dimensionCalls.concat(dimensionValueCalls)
    yield put(addDimensionsToReportingGroupSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, addDimensionsToReportingGroupError))
  }
}

export function* removeDimension(action) {
  const { companyCode, reportingGroupId, dimensionId } = action

  try {
    yield call(reportingGroupApi.removeDimension, {
      companyCode,
      reportingGroupId,
      dimensionId,
    })
    yield put(
      removeDimensionSuccess({ reportingGroupId, dimensionId, companyCode })
    )
  } catch (error) {
    yield put(handleError(error, removeDimensionError))
  }
}

export function* removeDimensionValue(action) {
  const { companyCode, reportingGroupId, dimensionValueId } = action

  try {
    yield call(reportingGroupApi.removeDimensionValue, {
      companyCode,
      reportingGroupId,
      dimensionValueId,
    })
    yield put(
      removeDimensionValueSuccess({
        reportingGroupId,
        dimensionValueId,
        companyCode,
      })
    )
  } catch (error) {
    yield put(handleError(error, removeDimensionValueError))
  }
}

export function* reportingGroupsSaga() {
  yield all([
    takeEvery(GET_REPORTING_GROUPS, getReportingGroups),
    takeEvery(CREATE_REPORTING_GROUP, createReportingGroup),
    takeEvery(DELETE_REPORTING_GROUP, deleteReportingGroup),
    takeLatest(CREATE_REPORTING_GROUP_SUCCESS, getReportingGroups),
    takeEvery(ADD_DIMENSIONS_TO_REPORTING_GROUP, addDimensions),

    takeLatest(ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS, getReportingGroups),
    takeEvery(REMOVE_DIMENSION, removeDimension),
    takeEvery(REMOVE_DIMENSION_VALUE, removeDimensionValue),
    takeEvery(UPDATE_REPORTING_GROUP, updateReportingGroup),
    takeLatest(DELETE_REPORTING_GROUP_SUCCESS, getReportingGroups),
    takeLatest(UPDATE_REPORTING_GROUP_SUCCESS, getReportingGroups),
  ])
}

export default reportingGroupsSaga
