import { isInteger } from 'lodash'

export const createPathPrefix = (customerCode, companyCode, shortFlag) => {
  if (!customerCode && !companyCode) {
    return '/'
  }
  if (shortFlag) {
    if (companyCode) return `${companyCode}/`
    return customerCode ? `${customerCode}/` : ''
  }
  return companyCode ? `/${customerCode}/${companyCode}/` : `/${customerCode}/`
}
export const parseContextFromPathName = (pathname) => {
  const parts = pathname.split('/').filter((a) => a !== '')
  if (parts.length === 0) {
    return { customerCode: undefined, companyCode: undefined }
  }

  const customerCode = parts[0]
  const isValidCustomerCode = parseNumberAndValidate(customerCode)
  if (isValidCustomerCode === false) {
    return { customerCode: undefined, companyCode: undefined }
  }

  if (parts.length === 1 && isValidCustomerCode) {
    return { customerCode, companyCode: undefined }
  }

  const companyCode = parts[1]
  if (isValidCompanyCode(companyCode)) {
    return { customerCode, companyCode }
  }
  return { customerCode, companyCode: undefined }
}

const isValidCompanyCode = (companyCode) => {
  const companyCodeParts = companyCode.split('-')
  if (companyCodeParts.length !== 2) {
    return false
  }

  if (
    parseNumberAndValidate(companyCodeParts[0]) === false ||
    parseNumberAndValidate(companyCodeParts[1]) === false
  ) {
    return false
  }

  return true
}

export const hasCompanyCode = () => {
  const context = parseContextFromPathName(window.location.pathname)
  return typeof context.companyCode !== 'undefined'
}

const parseNumberAndValidate = (text) => isInteger(parseInt(text, 10))

export const isWindows = /Win/i.test(window.navigator.platform)
