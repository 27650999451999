import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const baseUrl = 'customerdashboards'

const getCustomerDashboards = ({ customerCode }) =>
  axios
    .get(`${customerCode ? customerCode : ''}/${baseUrl}`, config())
    .then(returnData)
    .catch(catchError)

const copyCustomerDashboard = ({ customerCode, customerDashboard, id }) =>
  axios
    .post(`${customerCode}/${baseUrl}/${id}/copy`, customerDashboard, config())
    .then(returnData)
    .catch(catchError)

const createCustomerDashboard = ({ customerCode, customerDashboard }) =>
  axios
    .post(`${customerCode}/${baseUrl}`, customerDashboard, config())
    .then(returnData)
    .catch(catchError)

const createCustomerDashboardWidget = ({ customerCode, dashboardId, widget }) =>
  axios
    .post(`${customerCode}/${baseUrl}/${dashboardId}/widget`, widget, config())
    .then(returnData)
    .catch(catchError)

const patchCustomerDashboard = ({ customerCode, dashboardId, patch }) =>
  axios
    .patch(`${customerCode}/${baseUrl}/${dashboardId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const deleteCustomerDashboard = ({ customerCode, dashboardId }) =>
  axios
    .delete(`${customerCode}/${baseUrl}/${dashboardId}`, config())
    .then(returnData)
    .catch(catchError)

const deleteCustomerDashboardWidget = ({
  customerCode,
  dashboardId,
  widgetId,
}) =>
  axios
    .delete(
      `${customerCode}/${baseUrl}/${dashboardId}/widget/${widgetId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchCustomerDashboardWidget = ({
  customerCode,
  dashboardId,
  patch,
  widgetId,
}) =>
  axios
    .patch(
      `${customerCode}/${baseUrl}/${dashboardId}/widget/${widgetId}`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const upsertWidgetParameters = ({
  customerCode,
  dashboardId,
  widgetId,
  parameters,
}) =>
  axios
    .post(
      `${customerCode}/${baseUrl}/${dashboardId}/widget/${widgetId}/parameters`,
      parameters,
      config()
    )
    .then(returnData)
    .catch(catchError)

const customerDashboardApi = {
  copyCustomerDashboard,
  createCustomerDashboard,
  createCustomerDashboardWidget,
  deleteCustomerDashboard,
  deleteCustomerDashboardWidget,
  getCustomerDashboards,
  patchCustomerDashboard,
  patchCustomerDashboardWidget,
  upsertWidgetParameters,
}

export default customerDashboardApi
