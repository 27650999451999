import { Record, List } from 'immutable'

const RollingRule = Record({
  id: undefined,
  name: '',
  rollingRuleType: undefined,
  dimensionId: undefined,
  accountRanges: List(),
  totalShare: undefined,
  percentageMode: undefined,
  sourceDimensionValueId: undefined,
  budgetRowIds: List(),
  subBudgetRowIds: List(),
})

export default RollingRule
