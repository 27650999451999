/*
 *
 * Accounting period -constants
 *
 */

export const GET_ACCOUNTING_PERIODS =
  'app/AccountingPeriods/GET_ACCOUNTING_PERIODS'
export const GET_ACCOUNTING_PERIODS_SUCCESS =
  'app/AccountingPeriods/GET_ACCOUNTING_PERIODS_SUCCESS'
export const GET_ACCOUNTING_PERIODS_ERROR =
  'app/AccountingPeriods/GET_ACCOUNTING_PERIODS_ERROR'

export const DELETE_ACCOUNTING_PERIOD =
  'app/AccountingPeriods/DELETE_ACCOUNTING_PERIOD'
export const DELETE_ACCOUNTING_PERIOD_SUCCESS =
  'app/AccountingPeriods/DELETE_ACCOUNTING_PERIOD_SUCCESS'
export const DELETE_ACCOUNTING_PERIOD_ERROR =
  'app/AccountingPeriods/DELETE_ACCOUNTING_PERIOD_ERROR'

export const CREATE_ACCOUNTING_PERIOD =
  'app/AccountingPeriods/CREATE_ACCOUNTING_PERIOD'
export const CREATE_ACCOUNTING_PERIOD_SUCCESS =
  'app/AccountingPeriods/CREATE_ACCOUNTING_PERIOD_SUCCESS'
export const CREATE_ACCOUNTING_PERIOD_ERROR =
  'app/AccountingPeriods/CREATE_ACCOUNTING_PERIOD_ERROR'

export const SET_ACTIVE_ACCOUNTING_PERIOD =
  'app/CalculationConstants/SET_ACTIVE_ACCOUNTING_PERIOD'
export const SET_ACTIVE_ACCOUNTING_PERIOD_SUCCESS =
  'app/CalculationConstants/SET_ACTIVE_ACCOUNTING_PERIOD_SUCCESS'
export const SET_ACTIVE_ACCOUNTING_PERIOD_ERROR =
  'app/CalculationConstants/SET_ACTIVE_ACCOUNTING_PERIOD_ERROR'

export const UPDATE_ACCOUNTING_PERIOD =
  'app/CalculationConstants/UPDATE_ACCOUNTING_PERIOD'
export const UPDATE_ACCOUNTING_PERIOD_SUCCESS =
  'app/CalculationConstants/UPDATE_ACCOUNTING_PERIOD_SUCCESS'
export const UPDATE_ACCOUNTING_PERIOD_ERROR =
  'app/CalculationConstants/UPDATE_ACCOUNTING_PERIOD_ERROR'

export const EDIT_ACCOUNTING_PERIOD =
  'app/CalculationConstants/EDIT_ACCOUNTING_PERIOD'
