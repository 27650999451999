import axios from 'axios'
import { returnData, config, catchError } from './api-utils'
import { fetchEventSource } from '@microsoft/fetch-event-source'

const getReport = ({
  companyCode,
  customerCode,
  reportId,
  enableCaching = false,
  onMessage,
  onProgress,
  onError,
}) => {
  const context = companyCode || customerCode
  const url = context
    ? `${context}/reportdata/${reportId}`
    : `reportdata/${reportId}`
  const urlWithCaching = enableCaching === true ? `${url}/true` : url
  const configObj = config()

  fetchEventSource(configObj.baseURL + urlWithCaching, {
    headers: { ...configObj.headers, 'Content-Type': 'application/json' },
    openWhenHidden: true,
    method: 'GET',
    onmessage: (event) => {
      if (event.event === 'progress' && onProgress) {
        onProgress(event)
        return
      }
      if (event.event === 'error' && onError) {
        onError(event)
        return
      }
      if (event.event === 'keepalive') {
        return
      }
      if (onMessage) {
        onMessage(event)
        return
      }
    },
    onerror: (error) => {
      if (onError) {
        onError(error)
      }
      // Event source library atomaticly retries if error is not thrown
      throw new Error(error)
    },
  }).catch(() => {})
}

const invalidateReport = ({ reportId }) =>
  axios
    .post(`reportdata/${reportId}/invalidate`, {}, config())
    .then(returnData)
    .catch(catchError)

const getReportSelections = ({ companyCode }) => {
  const url = companyCode
    ? `${companyCode}/reportselections`
    : 'reportselections'
  return axios
    .get(url, config())
    .then(returnData)
    .catch(catchError)
}

const deleteReportBalance = ({ realBalanceId, reportId }) => {
  const url = `reportbalance/${realBalanceId}/?reportId=${reportId}`
  return axios
    .delete(url, config())
    .then(returnData)
    .catch(catchError)
}

const getReportBalances = ({ reportId, companyCode }) => {
  const url = `${companyCode}/reportbalance/${reportId}`
  return axios
    .get(url, config())
    .then(returnData)
    .catch(catchError)
}

const getReportBalancesList = ({
  reportBalanceIds,
  reportId,
  reportSchemeId,
}) => {
  const url = `reportbalance/list?reportSchemeId=${reportSchemeId}&reportId=${reportId}`
  return axios
    .post(url, reportBalanceIds, config())
    .then(returnData)
    .catch(catchError)
}

const createReportBalance = (props) => {
  const url = `reportbalance`
  return axios
    .post(url, props, config())
    .then(returnData)
    .catch(catchError)
}

const deleteAllReportBalances = ({ reportId }) => {
  const url = `reportbalance/all/${reportId}`
  return axios
    .delete(url, config())
    .then(returnData)
    .catch(catchError)
}

const updateReportBalance = (action) => {
  const url = `reportbalance/${action.id}`
  return axios
    .put(url, action, config())
    .then(returnData)
    .catch(catchError)
}

const reportApi = {
  getReport,
  getReportSelections,
  invalidateReport,
  getReportBalances,
  deleteReportBalance,
  getReportBalancesList,
  createReportBalance,
  deleteAllReportBalances,
  updateReportBalance,
}
export default reportApi
