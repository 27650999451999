import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  copyRoleError,
  copyRoleSuccess,
  createRoleError,
  createRoleSuccess,
  deleteRoleError,
  deleteRoleSuccess,
  editRoleError,
  editRoleSuccess,
  getRolesError,
  getRolesSuccess,
  getRolesUnderCustomerSuccess,
} from './actions'

import { handleError } from 'api/api-utils'
import rolesApi from 'api/RolesApi'

import {
  COPY_ROLE,
  CREATE_ROLE,
  DELETE_ROLE,
  EDIT_ROLE,
  GET_ROLES,
  GET_ROLES_UNDER_CUSTOMER,
} from './constants'
import userRolesApi from 'api/UserRolesApi'
import { createUserRoleSuccess } from 'containers/User/actions'

export function* deleteRole(action) {
  const { customerCode, companyCode, roleId } = action
  try {
    yield call(rolesApi.deleteRole, { customerCode, companyCode, roleId })
    yield put(deleteRoleSuccess({ customerCode, companyCode, roleId }))
  } catch (error) {
    yield put(handleError(error, deleteRoleError))
  }
}

export function* createRole(action) {
  const { customerCode, companyCode, roleName, userId, autoAssign } = action
  try {
    const created = yield call(rolesApi.createRole, {
      customerCode,
      companyCode,
      roleName,
    })
    const { id } = created
    yield put(
      createRoleSuccess({ id, customerCode, companyCode, name: roleName })
    )
    if (autoAssign) {
      const userRole = yield call(userRolesApi.createUserRole, {
        customerCode,
        companyCode,
        userId,
        role: roleName,
      })
      yield put(createUserRoleSuccess(userRole))
    }
  } catch (error) {
    yield put(handleError(error, createRoleError))
  }
}

export function* copyRole(action) {
  const { customerCode, companyCode, roleId, copyRoleName } = action
  try {
    const copied = yield call(rolesApi.copyRole, {
      customerCode,
      companyCode,
      roleId,
      copyRoleName,
    })
    const { id } = copied
    yield put(
      copyRoleSuccess({ id, customerCode, companyCode, name: copyRoleName })
    )
  } catch (error) {
    yield put(handleError(error, copyRoleError))
  }
}

export function* editRole(action) {
  const { customerCode, companyCode, roleId, editedRoleName } = action
  try {
    const edited = yield call(rolesApi.editRole, {
      customerCode,
      companyCode,
      roleId,
      editedRoleName,
    })
    const { id, name } = edited
    yield put(editRoleSuccess({ id, customerCode, companyCode, name }))
  } catch (error) {
    yield put(handleError(error, editRoleError))
  }
}

export function* getRoles({ customerCode, companyCode }) {
  try {
    const roles = yield call(
      rolesApi.getCompanyRoles,
      customerCode,
      companyCode
    )
    yield put(getRolesSuccess(roles, companyCode, customerCode))
  } catch (error) {
    yield put(handleError(error, getRolesError))
  }
}

export function* getRolesUnderCustomer({ customerCode, companyCode }) {
  try {
    const roles = yield call(rolesApi.getRolesUnderCustomer, customerCode)
    yield put(getRolesUnderCustomerSuccess(roles))
  } catch (error) {
    yield put(handleError(error, getRolesError))
  }
}

export function* rolesSagas() {
  yield all([
    takeLatest(GET_ROLES, getRoles),
    takeLatest(GET_ROLES_UNDER_CUSTOMER, getRolesUnderCustomer),
    takeEvery(CREATE_ROLE, createRole),
    takeEvery(DELETE_ROLE, deleteRole),
    takeEvery(EDIT_ROLE, editRole),
    takeEvery(COPY_ROLE, copyRole),
  ])
}

// All sagas to be loaded
export default rolesSagas
