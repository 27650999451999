export const GET_REPORT_DATA = 'app/ReportDatas/GET_REPORT_DATA'
export const GET_REPORT_DATA_ERROR = 'app/ReportDatas/GET_REPORT_DATA_ERROR'
export const GET_REPORT_DATA_SUCCESS = 'app/ReportDatas/GET_REPORT_DATA_SUCCESS'

export const GET_REPORT_PROGRESS = 'app/ReportDatas/GET_REPORT_PROGRESS'
export const GET_REPORT_PROGRESS_ERROR =
  'app/ReportDatas/GET_REPORT_PROGRESS_ERROR'
export const GET_REPORT_PROGRESS_SUCCESS =
  'app/ReportDatas/GET_REPORT_PROGRESS_SUCCESS'

export const INVALIDATE_REPORT_DATA_CACHE =
  'app/ReportDatas/INVALIDATE_REPORT_DATA_CACHE'
export const INVALIDATE_REPORT_DATA_CACHE_ERROR =
  'app/ReportDatas/INVALIDATE_REPORT_DATA_CACHE_ERROR'
export const INVALIDATE_REPORT_DATA_CACHE_SUCCESS =
  'app/ReportDatas/INVALIDATE_REPORT_DATA_CACHE_SUCCESS'

export const CLEAR_REPORT_DATA_ERROR = 'app/ReportDatas/CLEAR_REPORT_DATA_ERROR'
