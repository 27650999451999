/*
 *
 * BudgetBalanceImportModal reducer
 *
 */

import { fromJS, List } from 'immutable'
import {
  IMPORT_BUDGET_BALANCES,
  IMPORT_BUDGET_BALANCES_ERROR,
  IMPORT_BUDGET_BALANCES_SUCCESS,
  PARSE_BUDGET_BALANCES,
  PARSE_BUDGET_BALANCES_ERROR,
  PARSE_BUDGET_BALANCES_SUCCESS,
  RESET_IMPORT_BUDGET_BALANCE_MODAL,
} from './constants'

const initialState = fromJS({
  error: undefined,
  importResponse: undefined,
  loading: undefined,
  parsedBudgetBalances: undefined,
  parsedFile: undefined,
})

const BudgetBalanceImportModal = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_BUDGET_BALANCES:
      return state.set('loading', true)
    case IMPORT_BUDGET_BALANCES_ERROR:
      return state.set('loading', false).set('error', action.error)
    case IMPORT_BUDGET_BALANCES_SUCCESS:
      return state
        .set('loading', false)
        .set('importResponse', action.importResponse)
    case PARSE_BUDGET_BALANCES:
      return state
        .set('loading', true)
        .set('importResponse', undefined)
        .set('parsedBudgetBalances', undefined)
        .set('parsedFile', undefined)
    case PARSE_BUDGET_BALANCES_ERROR:
      return state.set('loading', false).set('error', action.error)
    case PARSE_BUDGET_BALANCES_SUCCESS:
      return state
        .set('loading', false)
        .set('parsedBudgetBalances', List(action.parsedBudgetBalances))
        .set('parsedFile', action.parsedFile)
    case RESET_IMPORT_BUDGET_BALANCE_MODAL:
      return initialState
    default:
      return state
  }
}

export default BudgetBalanceImportModal
