import { all, call, put, takeLatest } from 'redux-saga/effects'

import { SELECT_COMPANY } from './constants'

import { getAccountingPeriodsSuccess } from 'containers/AccountingPeriods/actions'
import { getAccountSchemesSuccess } from 'containers/AccountSchemes/actions'
import { getAccountSchemeTemplatesSuccess } from 'containers/AccountSchemeTemplates/actions'
import { getCalculationConstantsSuccess } from 'containers/CalculationConstants/actions'
import { resetFileUpload } from 'containers/Company/actions'
import { getCompanyDataSuccess, getCompanyDataFailed } from './actions'

import accountingPeriodApi from 'api/AccountingPeriodApi'
import accountApi from 'api/AccountApi'
import accountSchemeApi from 'api/AccountSchemeApi'
import accountSchemeTemplateApi from 'api/AccountSchemeTemplateApi'
import calculationConstantApi from 'api/CalculationConstantApi'
import { handleError } from 'api/api-utils'
import { getAccountsSuccess } from '../Accounts/actions'

// TODO: Revisit this later. This is probably not the best way to do this.
export function* companyFlow(action) {
  const { companyCode } = action
  try {
    const [
      accountingPeriods,
      accounts,
      accountSchemes,
      accountSchemeTemplates,
      calculationConstants,
    ] = yield all([
      call(accountingPeriodApi.getAccountingPeriods, { companyCode }),
      call(accountApi.getAccounts, { companyCode }),
      call(accountSchemeApi.getAccountSchemes, { companyCode }),
      call(accountSchemeTemplateApi.getAccountSchemeTemplates, { companyCode }),
      call(calculationConstantApi.getCalculationConstants, { companyCode }),
    ])
    yield put(getAccountingPeriodsSuccess({ accountingPeriods, companyCode }))
    yield put(getAccountsSuccess({ accounts, companyCode }))
    yield put(getAccountSchemesSuccess({ accountSchemes }))
    yield put(getAccountSchemeTemplatesSuccess({ accountSchemeTemplates }))
    yield put(getCalculationConstantsSuccess({ calculationConstants }))
    yield put(getCompanyDataSuccess({ companyCode }))
    yield put(resetFileUpload())
  } catch (err) {
    yield put(handleError(err, getCompanyDataFailed))
  }
}

export function* companyIndexSagas() {
  yield takeLatest(SELECT_COMPANY, companyFlow)
}

// All sagas to be loaded
export default companyIndexSagas
