import { createSelector } from 'reselect'

/**
 * Direct selector to the accountSchemeTemplate state domain
 */
const selectAccountSchemeTemplateDomain = (state) =>
  state.get('accountSchemeTemplate')

/**
 * Other specific selectors
 */

const selectAccountSchemeTemplateError = createSelector(
  selectAccountSchemeTemplateDomain,
  (accountSchemeTemplate) => accountSchemeTemplate.get('error')
)

const selectAccountSchemeTemplateLoading = createSelector(
  selectAccountSchemeTemplateDomain,
  (accountSchemeTemplate) => accountSchemeTemplate.get('loading')
)

const selectAccountSchemeTemplateTreeNode = (path) =>
  createSelector(
    selectAccountSchemeTemplateDomain,
    (accountSchemeTemplate) => accountSchemeTemplate.getIn(path)
  )

/**
 * Default selector used by AccountSchemeTemplate
 */
const selectAccountSchemeTemplateTree = createSelector(
  selectAccountSchemeTemplateDomain,
  (accountSchemeTemplate) => accountSchemeTemplate.get('tree')
)

export default selectAccountSchemeTemplateTree
export {
  selectAccountSchemeTemplateDomain,
  selectAccountSchemeTemplateError,
  selectAccountSchemeTemplateLoading,
  selectAccountSchemeTemplateTree,
  selectAccountSchemeTemplateTreeNode,
}
