import { call, put, takeEvery } from 'redux-saga/effects'
import integrationSourceSystemApi from 'api/IntegrationApi'
import { GET_INTEGRATION_LOGS } from './constants'
import { getIntegrationLogsSuccess, getIntegrationLogsError } from './actions'
import { handleError } from 'api/api-utils'

// Individual exports for testing
export function* getIntegrationLogs(action) {
  const { companyCode, start, end, page, pageSize, itemType } = action

  const startDate = start.toISOString().substr(0, 10)
  const endPlusDay = new Date(end)
  endPlusDay.setDate(end.getDate() + 1) //add 1 day to include ending day
  const endDate = endPlusDay.toISOString().substr(0, 10)

  try {
    const logs = yield call(integrationSourceSystemApi.getIntegrationLogs, {
      companyCode,
      start: startDate,
      end: endDate,
      page,
      pageSize,
      itemType,
    })
    yield put(getIntegrationLogsSuccess({ logs, page, pageSize }))
  } catch (error) {
    yield put(handleError(error, getIntegrationLogsError))
  }
}

// All sagas to be loaded
export function* integrationLogSaga() {
  yield takeEvery(GET_INTEGRATION_LOGS, getIntegrationLogs)
}

export default integrationLogSaga
