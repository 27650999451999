/*
 *
 * Vouchers actions
 *
 */

import {
  COPY_VOUCHER_ERROR,
  COPY_VOUCHER_SUCCESS,
  COPY_VOUCHER,
  CREATE_VOUCHER,
  CREATE_VOUCHER_ERROR,
  CREATE_VOUCHER_SUCCESS,
  DELETE_VOUCHER,
  DELETE_VOUCHER_ERROR,
  DELETE_VOUCHER_SUCCESS,
  GET_VOUCHER_LIST,
  GET_VOUCHER_LIST_ERROR,
  GET_VOUCHER_LIST_SUCCESS,
  GET_EXPORT_DATA,
  GET_EXPORT_DATA_ERROR,
  GET_EXPORT_DATA_SUCCESS,
  TOGGLE_SHOW_VOUCHER_FORM,
} from './constants'
export const copyVoucher = ({ companyCode, id }) => ({
  type: COPY_VOUCHER,
  companyCode,
  id,
})
export const copyVoucherError = (error) => ({
  type: COPY_VOUCHER_ERROR,
  error,
})
export const copyVoucherSuccess = ({ sourceId, voucher }) => ({
  type: COPY_VOUCHER_SUCCESS,
  sourceId,
  voucher,
})

export const createVoucher = ({
  companyCode,
  date,
  description,
  voucherlines,
  voucherType,
}) => ({
  type: CREATE_VOUCHER,
  companyCode,
  date,
  description,
  voucherlines,
  voucherType,
})
export const createVoucherError = (error) => ({
  type: CREATE_VOUCHER_ERROR,
  error,
})
export const createVoucherSuccess = ({ vouchers }) => ({
  type: CREATE_VOUCHER_SUCCESS,
  vouchers,
})

export const deleteVoucher = ({ companyCode, id }) => ({
  type: DELETE_VOUCHER,
  companyCode,
  id,
})
export const deleteVoucherError = (error) => ({
  type: DELETE_VOUCHER_ERROR,
  error,
})
export const deleteVoucherSuccess = ({ id }) => ({
  type: DELETE_VOUCHER_SUCCESS,
  id,
})

export const getVoucherList = ({
  companyCode,
  endDate,
  startDate,
  voucherType,
}) => ({
  type: GET_VOUCHER_LIST,
  companyCode,
  endDate,
  startDate,
  voucherType,
})
export const getVoucherListError = (error) => ({
  type: GET_VOUCHER_LIST_ERROR,
  error,
})
export const getVoucherListSuccess = ({ vouchers }) => ({
  type: GET_VOUCHER_LIST_SUCCESS,
  vouchers,
})

export const toggleShowVoucherForm = () => ({
  type: TOGGLE_SHOW_VOUCHER_FORM,
  toggleShowVoucherForm,
})

export const getExportData = ({
  companyCode,
  companyName,
  startDate,
  endDate,
  voucherType,
  exportType,
  accountFilter,
}) => ({
  type: GET_EXPORT_DATA,
  companyCode,
  startDate,
  endDate,
  voucherType,
  exportType,
  companyName,
  accountFilter,
})

export const getExportDataError = (error) => ({
  type: GET_EXPORT_DATA_ERROR,
  error,
})

export const getExportDataSuccess = () => ({
  type: GET_EXPORT_DATA_SUCCESS,
})
