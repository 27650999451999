/*
 *
 * Voucher reducer
 *
 */

import { fromJS } from 'immutable'

import { VoucherRecord } from 'records'

import {
  GET_VOUCHER,
  GET_VOUCHER_ERROR,
  GET_VOUCHER_SUCCESS,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_ERROR,
  UPDATE_VOUCHER_SUCCESS,
} from './constants'

const initialState = fromJS({
  error: false,
  loading: false,
  voucher: undefined,
})

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VOUCHER:
    case UPDATE_VOUCHER:
      return state.set('loading', true)

    case GET_VOUCHER_ERROR:
    case UPDATE_VOUCHER_ERROR:
      return state.set('loading', false).set('error', action.error)

    case GET_VOUCHER_SUCCESS:
      return state
        .set('loading', false)
        .set('voucher', new VoucherRecord(action.voucher))

    case UPDATE_VOUCHER_SUCCESS:
      return state
        .set('loading', false)
        .set('voucher', new VoucherRecord(action.voucher))
    default:
      return state
  }
}

export default voucherReducer
