import { Record } from 'immutable'

const WidgetRecord = Record({
  id: '',
  dashboardId: '',
  parameters: new Map(),
  type: 0,
})

export default WidgetRecord
