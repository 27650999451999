/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl'

import enLocaleData from 'react-intl/locale-data/en'
import enTranslationMessages from './translations/en.json'

export const appLocales = ['en']

addLocaleData(enLocaleData)

const formatTranslationMessages = (messages) => {
  const formattedMessages = {}
  /* eslint-disable no-restricted-syntax */
  //eslint-disable-next-line no-unused-vars
  for (const message of messages) {
    formattedMessages[message.id] = message.message || message.defaultMessage
  }
  /* eslint-enable no-restricted-syntax */

  return formattedMessages
}

export const translationMessages = {
  en: formatTranslationMessages(enTranslationMessages),
}
