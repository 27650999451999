/* eslint-disable no-undef */

/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS } from 'immutable'
import createSagaMiddleware from 'redux-saga'
import createReducer from './reducers'
import rootSagas from './sagas'

const sagaMiddleware = createSagaMiddleware()
const devtools = window.__REDUX_DEVTOOLS_EXTENSION__ || (() => (noop) => noop) //eslint-disable-line no-underscore-dangle

export default function configureStore(initialState = {}) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  const middlewares = [sagaMiddleware]

  const enhancers = [applyMiddleware(...middlewares), devtools()]

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    compose(...enhancers)
  )

  // Create hook for async sagas
  store.runSaga = sagaMiddleware.run

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    import('./reducers').then((reducerModule) => {
      const createReducers = reducerModule.default
      const nextReducers = createReducers(store)

      store.replaceReducer(nextReducers)
    })
  }

  // Start application level sagas.
  sagaMiddleware.run(rootSagas)

  return store
}
