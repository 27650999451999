import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const contextPrefix = ({ companyCode, customerCode }) =>
  companyCode ? companyCode + '/' : customerCode ? customerCode + '/' : ''

const getNotifications = ({ companyCode, customerCode }) =>
  axios
    .get(
      `${contextPrefix({ companyCode, customerCode })}notifications`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const createNotification = ({ customerCode, companyCode, notification }) =>
  axios
    .post(
      `${contextPrefix({ companyCode, customerCode })}notifications`,
      notification,
      config()
    )
    .then(returnData)
    .catch(catchError)

const dismissNotification = ({ companyCode, customerCode, notificationId }) =>
  axios
    .get(
      `${contextPrefix({
        companyCode,
        customerCode,
      })}notifications/${notificationId}/dismiss`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const dismissAllNotifications = ({ companyCode, customerCode }) =>
  axios
    .get(
      `${contextPrefix({
        companyCode,
        customerCode,
      })}notifications/dismissAll`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteNotification = ({ companyCode, customerCode, notificationId }) =>
  axios
    .delete(
      `${contextPrefix({
        companyCode,
        customerCode,
      })}notifications/${notificationId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const notificationsApi = {
  getNotifications,
  createNotification,
  dismissNotification,
  dismissAllNotifications,
  deleteNotification,
}
export default notificationsApi
