/*
 *
 * Dimensions actions
 *
 */

import {
  BULK_DELETE_DIMENSION,
  BULK_DELETE_DIMENSION_ERROR,
  BULK_DELETE_DIMENSION_SUCCESS,
  CREATE_DIMENSION,
  CREATE_DIMENSION_ERROR,
  CREATE_DIMENSION_SUCCESS,
  DELETE_DIMENSION,
  DELETE_DIMENSION_ERROR,
  DELETE_DIMENSION_SUCCESS,
  GET_DIMENSIONS,
  GET_DIMENSIONS_ERROR,
  GET_DIMENSIONS_SUCCESS,
  GET_DIMENSIONS_SEARCH,
  GET_DIMENSIONS_SEARCH_SUCCESS,
  GET_BY_DIMENSIONVALUE,
  GET_BY_DIMENSIONVALUE_SUCCESS,
  DISMISS_BULK_DELETE_ERROR,
} from './constants'

export const getByDimensionValue = ({ dvId }) => ({
  type: GET_BY_DIMENSIONVALUE,
  dvId,
})

export const getByDimensionValueSuccess = ({ companyCode, value }) => ({
  type: GET_BY_DIMENSIONVALUE_SUCCESS,
  companyCode,
  value,
})

export const getDimensionSearch = ({
  companyCode,
  search,
  includeOperative = true,
  limit = 2000,
}) => ({
  type: GET_DIMENSIONS_SEARCH,
  companyCode,
  search,
  limit,
  includeOperative,
})

export const getDimensionSearchSuccess = ({
  companyCode,
  search,
  dimensions,
}) => ({
  type: GET_DIMENSIONS_SEARCH_SUCCESS,
  dimensions,
  companyCode,
  search,
})

export const getDimensions = ({
  companyCode,
  limit = 2000,
  includeOperative = true,
}) => ({
  type: GET_DIMENSIONS,
  companyCode,
  includeOperative,
  limit,
})

export const getDimensionsError = ({ companyCode, ...error }) => ({
  type: GET_DIMENSIONS_ERROR,
  error,
  companyCode,
})

export const getDimensionsSuccess = ({
  dimensions,
  companyCode,
  includeOperative,
}) => ({
  type: GET_DIMENSIONS_SUCCESS,
  dimensions,
  companyCode,
  includeOperative,
})

export const deleteDimension = ({ companyCode, dimensionId }) => ({
  type: DELETE_DIMENSION,
  companyCode,
  dimensionId,
})

export const deleteDimensionSuccess = ({ dimensionId, companyCode }) => ({
  type: DELETE_DIMENSION_SUCCESS,
  dimensionId,
  companyCode,
})

export const deleteDimensionError = ({ companyCode, ...error }) => ({
  type: DELETE_DIMENSION_ERROR,
  error,
  companyCode,
})

export const createDimension = ({
  code,
  companyCode,
  dimensionType,
  displayName,
  hideFromBudgeting,
  hideFromReporting,
  useGeneratedDisplayName,
  name,
  parentId,
}) => ({
  type: CREATE_DIMENSION,
  code,
  companyCode,
  dimensionType,
  displayName,
  hideFromBudgeting,
  hideFromReporting,
  useGeneratedDisplayName,
  name,
  parentId,
})

export const createDimensionError = ({ companyCode, ...error }) => ({
  type: CREATE_DIMENSION_ERROR,
  error,
  companyCode,
})

export const createDimensionSuccess = ({ dimension, companyCode }) => ({
  type: CREATE_DIMENSION_SUCCESS,
  dimension,
  companyCode,
})

export const bulkDeleteDimension = ({ companyCode, dimensionId }) => ({
  type: BULK_DELETE_DIMENSION,
  companyCode,
  dimensionId,
})

export const bulkDeleteDimensionError = ({
  companyCode,
  dimensionId,
  ...error
}) => ({
  type: BULK_DELETE_DIMENSION_ERROR,
  error,
  companyCode,
  dimensionId,
})

export const bulkDeleteDimensionSuccess = ({ companyCode, dimensionId }) => ({
  type: BULK_DELETE_DIMENSION_SUCCESS,
  companyCode,
  dimensionId,
})

export const dismissBulkDeleteError = ({ companyCode, dimensionId }) => ({
  type: DISMISS_BULK_DELETE_ERROR,
  companyCode,
  dimensionId,
})
