import diff from 'immutablediff'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import calculationConstantApi from 'api/CalculationConstantApi'
import { UpdateCalculationConstantRecord } from 'records'

import {
  createCalculationConstantError,
  createCalculationConstantSuccess,
  deleteCalculationConstantError,
  deleteCalculationConstantSuccess,
  getCalculationConstantsError,
  getCalculationConstantsSuccess,
  updateCalculationConstantError,
  updateCalculationConstantSuccess,
} from './actions'
import {
  CREATE_CALCULATION_CONSTANT,
  DELETE_CALCULATION_CONSTANT,
  GET_CALCULATION_CONSTANTS,
  UPDATE_CALCULATION_CONSTANT,
} from './constants'
import { selectEditedCalculationConstant } from './selectors'

// Individual exports for testing
export function* createCalculationConstant(action) {
  const { budgetId, companyCode, customerCode, name, symbol, unit } = action
  try {
    const calculationConstant = yield call(
      calculationConstantApi.createCalculationConstant,
      { budgetId, companyCode, customerCode, name, symbol, unit }
    )
    yield put(createCalculationConstantSuccess({ calculationConstant }))
  } catch (error) {
    yield put(handleError(error, createCalculationConstantError))
  }
}

export function* deleteCalculationConstant(action) {
  const {
    budgetId,
    companyCode,
    customerCode,
    calculationConstant,
    calculationConstant: { id },
  } = action
  try {
    yield call(calculationConstantApi.deleteCalculationConstant, {
      budgetId,
      companyCode,
      customerCode,
      calculationConstant,
    })
    yield put(deleteCalculationConstantSuccess({ id }))
  } catch (error) {
    yield put(handleError(error, deleteCalculationConstantError))
  }
}

export function* getCalculationConstants(action) {
  const { budgetId, companyCode, customerCode } = action
  try {
    const calculationConstants = yield call(
      calculationConstantApi.getCalculationConstants,
      { budgetId, companyCode, customerCode }
    )
    yield put(getCalculationConstantsSuccess({ calculationConstants }))
  } catch (error) {
    yield put(handleError(error, getCalculationConstantsError))
  }
}

export function* updateCalculationConstant(action) {
  const {
    budgetId,
    calculationConstantId,
    companyCode,
    customerCode,
    updatedFields,
  } = action
  const currentCalculationConstant = yield select(
    selectEditedCalculationConstant
  )
  const definedInScope = currentCalculationConstant.definedInScope
  const patch = diff(
    new UpdateCalculationConstantRecord(currentCalculationConstant),
    new UpdateCalculationConstantRecord(updatedFields)
  ).toJS()

  try {
    const updatedCalculationConstant = yield call(
      calculationConstantApi.patchCalculationConstant,
      {
        budgetId,
        calculationConstantId,
        companyCode,
        customerCode,
        definedInScope,
        patch,
      }
    )
    yield put(
      updateCalculationConstantSuccess({
        calculationConstant: updatedCalculationConstant,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateCalculationConstantError))
  }
}

// All sagas to be loaded
export function* calculationConstantsSaga() {
  yield all([
    takeEvery(CREATE_CALCULATION_CONSTANT, createCalculationConstant),
    takeEvery(DELETE_CALCULATION_CONSTANT, deleteCalculationConstant),
    takeLatest(GET_CALCULATION_CONSTANTS, getCalculationConstants),
    takeEvery(UPDATE_CALCULATION_CONSTANT, updateCalculationConstant),
  ])
}

export default calculationConstantsSaga
