import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import voucherApi from 'api/VoucherApi'

import {
  copyVoucherError,
  copyVoucherSuccess,
  createVoucherError,
  createVoucherSuccess,
  getVoucherList as getVoucherListAction,
  getVoucherListError,
  getVoucherListSuccess,
  deleteVoucherSuccess,
  getExportDataSuccess,
  getExportDataError,
  deleteVoucherError,
} from './actions'
import {
  COPY_VOUCHER,
  CREATE_VOUCHER,
  GET_VOUCHER_LIST,
  DELETE_VOUCHER,
  VOUCHER_TYPE_FINAZILLA,
  GET_EXPORT_DATA,
} from './constants'
import exportFile from 'utils/export'
import _ from 'lodash'

export function* copyVoucher(action) {
  try {
    const source = yield call(voucherApi.getVoucher, action)
    const createActionParams = {
      companyCode: action.companyCode,
      date: source.date,
      description: source.description,
      voucherlines: source.voucherLines,
      voucherType: 'Finazilla',
    }
    const message = yield call(voucherApi.createVoucher, createActionParams)
    const getVoucherParams = {
      companyCode: action.companyCode,
      id: message.id,
    }
    const newVoucher = yield call(voucherApi.getVoucher, getVoucherParams)
    yield put(copyVoucherSuccess({ voucher: newVoucher, sourceId: source.id }))
  } catch (error) {
    yield put(handleError(error, copyVoucherError))
  }
}

export function* createVoucher(action) {
  try {
    const { companyCode, date } = action
    const message = yield call(voucherApi.createVoucher, action)
    yield put(createVoucherSuccess({ message }))
    yield put(
      getVoucherListAction({
        companyCode,
        endDate: date,
        startDate: date,
        voucherType: VOUCHER_TYPE_FINAZILLA,
      })
    )
  } catch (error) {
    yield put(createVoucherError(handleError(error, createVoucherError)))
  }
}

export function* deleteVoucher(action) {
  try {
    yield call(voucherApi.deleteVoucher, action)
    yield put(deleteVoucherSuccess({ id: action.id }))
  } catch (error) {
    yield put(handleError(error, deleteVoucherError))
  }
}

export function* getVoucherList(action) {
  try {
    const vouchers = yield call(voucherApi.getVoucherList, action)

    yield put(getVoucherListSuccess({ vouchers }))
  } catch (error) {
    yield put(getVoucherListError(handleError(error, getVoucherListError)))
  }
}

export function* getExportData(action) {
  try {
    const data = yield call(voucherApi.getExportData, action)

    exportFile({
      data,
      fileType: 'xlsx',
      name: `${action.companyName} - ${_.startCase(
        action.exportType
      )} - ${new Date().toLocaleDateString()}`,
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      autoBOM: true,
    })

    yield put(getExportDataSuccess())
  } catch (error) {
    yield put(getExportDataError(handleError(error, getExportDataError)))
  }
}

// Individual exports for testing
export function* vouchersSaga() {
  yield all([
    takeLatest(COPY_VOUCHER, copyVoucher),
    takeEvery(CREATE_VOUCHER, createVoucher),
    takeEvery(DELETE_VOUCHER, deleteVoucher),
    takeLatest(GET_VOUCHER_LIST, getVoucherList),
    takeLatest(GET_EXPORT_DATA, getExportData),
  ])
}

// All sagas to be loaded
export default vouchersSaga
