import { get } from 'lodash'
import { List } from 'immutable'
import ContentRecord from 'records/content'
import ContentDataRecord from 'records/contentData'
import ContentDataRecordText from 'records/contentDataText'
import DimensionTargetRecord from 'records/dimension-target'

import { enumValueToEnumName } from 'utils/enum'
import { CONTENT_TYPES } from 'containers/Content/constants'

export const getDataKey = (contentType, targetId) =>
  `${contentType}-${targetId}`

export const mapDimensionTarget = (dimensionTarget) =>
  dimensionTarget
    ? new DimensionTargetRecord({
        ...dimensionTarget,
        dimensionValueIds: List(
          dimensionTarget.dimensionValues?.map((dv) => parseInt(dv.id, 10))
        ),
      })
    : undefined

export const mapToContentRecord = (content) => {
  return new ContentRecord({
    ...content,
    type: enumValueToEnumName(content.type, CONTENT_TYPES),
    data: mapToContentDataRecord(
      content.data ? JSON.parse(content.data) : null
    ),
    dimensionTarget: mapDimensionTarget(content.dimensionTarget),
  })
}
export const mapToContentDataRecord = (contentData) =>
  contentData
    ? new ContentDataRecord({
        text: new ContentDataRecordText({
          body: getBody(contentData),
          title: getTitle(contentData),
        }),
      })
    : null

export const jsToContentRecord = (content) => {
  return new ContentRecord({
    ...content,
    data: mapToContentDataRecord(content.data ? content.data : null),
    dimensionTarget: mapDimensionTarget(content.dimensionTarget),
  })
}
export const getBody = (contentData) => get(contentData, ['text', 'body'], null)
export const getTitle = (contentData) => get(contentData, ['text', 'title'], '')
