import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  take,
} from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import monitorApi from 'api/BudgetMonitorApi'

import { GET_BUDGET_BALANCE_FOR_ROW, SYNC_BUDGET } from './constants'
import {
  getBudgetBalanceForRow as getBudgetBalanceForRowAction,
  getBudgetBalanceForRowError,
  getBudgetBalanceForRowSuccess,
  syncBudgetError,
  getBudgetMonitorProgressSuccess,
  syncBudgetSuccess,
  getBudgetBalanceTreeSuccess,
} from './actions'
import { selectDrilledRows } from './selectors'
import { eventChannel, END } from 'redux-saga'
import { tryParseErrorJson } from 'utils/parseError'

export function* syncBudget(action) {
  const { companyCode, budgetId, start, end } = action

  try {
    const progressChannel = eventChannel((eventEmmit) => {
      const onMessage = (event) => {
        try {
          eventEmmit(syncBudgetSuccess({ data: JSON.parse(event.data)?.data }))
          eventEmmit(END)
        } catch (error) {
          eventEmmit(handleError(error, syncBudgetError))
          eventEmmit(END)
        }
      }

      const onProgress = (event) => {
        eventEmmit(getBudgetMonitorProgressSuccess(event.data))
      }

      const onError = (event) => {
        console.error('Error in syncBudget', event)
        eventEmmit(
          handleError({ data: tryParseErrorJson(event.data) }, syncBudgetError)
        )
        eventEmmit(END)
      }

      monitorApi.getBudgetBalanceData({
        companyCode,
        budgetId,
        start,
        end,
        onMessage,
        onProgress,
        onError,
      })

      return () => {}
    })
    while (true) {
      const event = yield take(progressChannel)
      if (event === END) break
      yield put(event)
    }
  } catch (error) {
    yield put(handleError(error, syncBudgetError))
  }
}

function treeHasRowRecursive(tree, row) {
  if (!tree) return false
  if (tree.rowId === row) return true
  if (tree.children) {
    return tree.children.some((child) => treeHasRowRecursive(child, row))
  }
}

export function* syncBudgetBalanceTree(action) {
  const { companyCode, budgetId, start, end } = action
  try {
    const tree = yield call(monitorApi.getBudgetBalanceTree, {
      companyCode,
      budgetId,
    })

    //Get drilled rows before saving new tree as saving tree clears drillings.
    const drilledRows = yield select(selectDrilledRows())

    yield put(getBudgetBalanceTreeSuccess(tree))
    //Send action to refetch all drilled rows
    if (drilledRows) {
      const actions = drilledRows
        .filter((row, rowId) => treeHasRowRecursive(tree.schemeGroup, rowId))
        .map((row, rowId) =>
          put(
            getBudgetBalanceForRowAction({
              companyCode,
              budgetId,
              start,
              end,
              rowId,
              path: row.get('path'),
            })
          )
        )
        .toArray()
      yield all(actions)
    }
  } catch (error) {
    yield put(handleError(error, syncBudgetError))
  }
}

export function* getBudgetBalanceForRow(action) {
  const { companyCode, budgetId, start, end, rowId, path } = action
  try {
    const { data, tree } = yield call(monitorApi.getBudgetBalanceForRow, {
      companyCode,
      budgetId,
      start,
      end,
      rowId,
    })

    yield put(getBudgetBalanceForRowSuccess({ data, tree, path, rowId }))
  } catch (error) {
    yield put(handleError(error, getBudgetBalanceForRowError))
  }
}

export function* budgetMonitorSaga() {
  yield all([
    takeEvery(GET_BUDGET_BALANCE_FOR_ROW, getBudgetBalanceForRow),
    takeLatest(SYNC_BUDGET, syncBudget),
    takeLatest(SYNC_BUDGET, syncBudgetBalanceTree),
  ])
}

// All sagas to be loaded
export default budgetMonitorSaga
