/*
 *
 * SubBudget actions
 *
 */

import {
  BEGIN_ADD_SUB_BUDGET_ROW,
  EDIT_SUB_BUDGET_ROW,
  GET_SUB_BUDGET,
  GET_SUB_BUDGET_ERROR,
  GET_SUB_BUDGET_SUCCESS,
  GET_SUB_BUDGET_DATA,
  GET_SUB_BUDGET_DATA_ERROR,
  GET_SUB_BUDGET_DATA_SUCCESS,
  GET_SUB_BUDGET_TREE,
  GET_SUB_BUDGET_TREE_ERROR,
  GET_SUB_BUDGET_TREE_SUCCESS,
  REMOVE_SUB_BUDGET_ROW,
  REMOVE_SUB_BUDGET_ROW_ERROR,
  REMOVE_SUB_BUDGET_ROW_SUCCESS,
  RESET_ALL_SUBBUDGETS,
  UPDATE_SUB_BUDGET_CELL,
  UPDATE_SUB_BUDGET_CELL_ERROR,
  UPDATE_SUB_BUDGET_CELL_SUCCESS,
  UPDATE_SUB_BUDGET_CELL_PATCH,
  UPDATE_SUB_BUDGET_STATUS,
  MOVE_SUB_BUDGET_ROW,
  MOVE_SUB_BUDGET_ROW_ERROR,
  MOVE_SUB_BUDGET_ROW_SUCCESS,
  SHOW_SUB_BUDGET_ROW_NOTES,
  GET_SUB_BUDGET_IMPORT_DATA,
  GET_SUB_BUDGET_IMPORT_DATA_ERROR,
  GET_SUB_BUDGET_IMPORT_DATA_SUCCESS,
  UPDATE_SUB_BUDGET_STATUS_ERROR,
  SHOW_SUB_BUDGET_IMPORT_MODAL,
  CLOSE_SUB_BUDGET_IMPORT_MODAL,
  CLEAR_SUB_BUDGET_DATA,
} from './constants'
export const beginAddSubBudgetRow = ({ parentRow, order }) => ({
  type: BEGIN_ADD_SUB_BUDGET_ROW,
  parentRow,
  order,
})

export const editSubBudgetRow = ({ row }) => ({
  type: EDIT_SUB_BUDGET_ROW,
  row,
})

export const getSubBudget = ({
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  forceRecalculate,
}) => ({
  type: GET_SUB_BUDGET,
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  forceRecalculate,
})
export const getSubBudgetError = (error) => ({
  type: GET_SUB_BUDGET_ERROR,
  error,
})
export const getSubBudgetSuccess = ({ subBudgetId, dv, tree, data }) => ({
  type: GET_SUB_BUDGET_SUCCESS,
  subBudgetId,
  dv,
  tree,
  data,
})

export const getSubBudgetImportData = ({
  companyCode,
  budgetId,
  subBudgetId,
  subBudgetRowId,
  dimensionValues,
  sourceStart,
  sourceEnd,
  targetStart,
  targetEnd,
  viewStart,
  viewEnd,
  multiplier,
  additionAmount,
  dataGroup,
  persist,
  rowFilterDimensionValues,
  dataType,
  importType,
  importMode,
  accountId,
}) => ({
  type: GET_SUB_BUDGET_IMPORT_DATA,
  companyCode,
  budgetId,
  subBudgetId,
  subBudgetRowId,
  dimensionValues,
  sourceStart,
  sourceEnd,
  targetStart,
  targetEnd,
  viewStart,
  viewEnd,
  multiplier,
  additionAmount,
  dataGroup,
  persist,
  rowFilterDimensionValues,
  dataType,
  importType,
  importMode,
  accountId,
})
export const getSubBudgetImportDataError = (error) => ({
  type: GET_SUB_BUDGET_IMPORT_DATA_ERROR,
  error,
})
export const getSubBudgetImportDataSuccess = ({
  subBudgetId,
  dimensionValues,
  tree,
  data,
}) => ({
  type: GET_SUB_BUDGET_IMPORT_DATA_SUCCESS,
  subBudgetId,
  dimensionValues,
  tree,
  data,
})

export const getSubBudgetData = ({
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
}) => ({
  type: GET_SUB_BUDGET_DATA,
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
})
export const getSubBudgetDataError = (error) => ({
  type: GET_SUB_BUDGET_DATA_ERROR,
  error,
})
export const getSubBudgetDataSuccess = ({ subBudgetId, dv, data }) => ({
  type: GET_SUB_BUDGET_DATA_SUCCESS,
  subBudgetId,
  dv,
  data,
})

export const getSubBudgetTree = ({ companyCode, budgetId, subBudgetId }) => ({
  type: GET_SUB_BUDGET_TREE,
  companyCode,
  budgetId,
  subBudgetId,
})
export const getSubBudgetTreeError = (error) => ({
  type: GET_SUB_BUDGET_TREE_ERROR,
  error,
})
export const getSubBudgetTreeSuccess = ({ subBudgetId, tree }) => ({
  type: GET_SUB_BUDGET_TREE_SUCCESS,
  subBudgetId,
  tree,
})

export const removeSubBudgetRow = ({
  companyCode,
  budgetId,
  path,
  rowId,
  subBudgetId,
  start,
  end,
  dv,
}) => ({
  type: REMOVE_SUB_BUDGET_ROW,
  companyCode,
  budgetId,
  path,
  rowId,
  subBudgetId,
  start,
  end,
  dv,
})
export const removeSubBudgetRowError = (error) => ({
  type: REMOVE_SUB_BUDGET_ROW_ERROR,
  error,
})
export const removeSubBudgetRowSuccess = ({
  subBudgetId,
  dv,
  tree,
  data,
  budgetId,
  rowId,
}) => ({
  type: REMOVE_SUB_BUDGET_ROW_SUCCESS,
  subBudgetId,
  dv,
  tree,
  data,
  budgetId,
  rowId,
})

export const updateSubBudgetCell = ({
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  selection,
  value,
  active,
  rowHasData,
}) => ({
  type: UPDATE_SUB_BUDGET_CELL,
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  selection,
  value,
  active,
  rowHasData,
})
export const updateSubBudgetCellError = (error) => ({
  type: UPDATE_SUB_BUDGET_CELL_ERROR,
  error,
})
export const updateSubBudgetCellPatch = ({
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  patches,
}) => ({
  type: UPDATE_SUB_BUDGET_CELL_PATCH,
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  patches,
})
export const updateSubBudgetCellSuccess = ({ subBudgetId, dv, dataPatch }) => ({
  type: UPDATE_SUB_BUDGET_CELL_SUCCESS,
  subBudgetId,
  dv,
  dataPatch,
})

export const updateSubBudgetStatus = ({
  sourceSubBudget,
  budgetId,
  companyCode,
  status,
}) => ({
  type: UPDATE_SUB_BUDGET_STATUS,
  companyCode,
  budgetId,
  sourceSubBudget,
  status,
})

export const updateSubBudgetStatusError = (error) => ({
  type: UPDATE_SUB_BUDGET_STATUS_ERROR,
  error,
})

export const resetSubBudgetTrees = () => ({
  type: RESET_ALL_SUBBUDGETS,
})

export const moveSubBudgetRow = ({
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  sourcePath,
  targetPath,
}) => ({
  type: MOVE_SUB_BUDGET_ROW,
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  sourcePath,
  targetPath,
})

export const moveSubBudgetRowError = (error) => ({
  type: MOVE_SUB_BUDGET_ROW_ERROR,
  error,
})

export const moveSubBudgetRowSuccess = ({ subBudgetId, dv, tree, data }) => ({
  type: MOVE_SUB_BUDGET_ROW_SUCCESS,
  subBudgetId,
  dv,
  tree,
  data,
})

export const showSubBudgetRowNotes = ({ row, show }) => ({
  type: SHOW_SUB_BUDGET_ROW_NOTES,
  row,
  show,
})

export const showSubBudgetImportModal = ({ row }) => ({
  type: SHOW_SUB_BUDGET_IMPORT_MODAL,
  row,
})

export const closeSubBudgetImportModal = () => ({
  type: CLOSE_SUB_BUDGET_IMPORT_MODAL,
})

export const clearSubBudgetData = ({ subBudgetId, dv }) => ({
  type: CLEAR_SUB_BUDGET_DATA,
  subBudgetId,
  dv,
})
