export const TOGGLE_BRANCH = 'app/Share/TOGGLE_BRANCH'
export const EXPAND_BRANCH = 'app/Share/EXPAND_BRANCH'
export const TOGGLE_ITEM_SELECTION = 'app/Share/SELECT_ITEM_SELECTION'
export const SET_ITEM_SELECTION = 'app/Share/SET_ITEM_SELECTION'

export const GET_USERS = 'app/Share/GET_USERS'
export const GET_USERS_SUCCESS = 'app/Share/GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'app/Share/GET_USERS_ERROR'

export const SET_TREE_ITEM_STATUS = 'app/Share/TREE_ITEM_STATUS'
export const SET_INITIAL_USER_SELECTIONS =
  'app/SHARE/SET_INITIAL_USER_SELECTIONS'
export const RESET_TREE = 'app/Share/RESET_TREE'

export const TREE_ITEM_TYPE_CUSTOMER = 'customer'
export const TREE_ITEM_TYPE_COMPANY = 'company'
export const TREE_ITEM_TYPE_USER = 'user'
export const TREE_ITEM_TYPE_ROOT = 'root'
export const ROOT_ITEM_ID = '0'
