import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { createStructuredSelector } from 'reselect'

import AlertModal from 'components/AlertModal'
import ConfirmModal from 'components/ConfirmModal'
import PromptModal from 'components/PromptModal'

import {
  selectShowAlert,
  selectAlertTitle,
  selectAlertDetails,
  selectShowConfirm,
  selectConfirmTitle,
  selectConfirmDetails,
  selectShowPrompt,
  selectPromptTitle,
  selectPromptDetails,
} from './selectors'

import { dismissAlert, dismissConfirm, dismissPrompt } from './actions'

const Dialogs = (props) => {
  const { showAlert, alertTitle, alertDetails, onDismissAlert } = props
  const { showConfirm, confirmTitle, confirmDetails, onDismissConfirm } = props
  const { showPrompt, promptTitle, promptDetails, onDismissPrompt } = props
  return (
    <div>
      <AlertModal
        show={showAlert}
        onDismiss={onDismissAlert}
        title={alertTitle}
        body={alertDetails}
      />
      <ConfirmModal
        show={showConfirm}
        onConfirm={() => onDismissConfirm(true)}
        onCancel={() => onDismissConfirm(false)}
        title={confirmTitle}
        body={confirmDetails}
      />
      <PromptModal
        show={showPrompt}
        onSuccess={(input) => onDismissPrompt(true, input)}
        onCancel={() => onDismissPrompt(false)}
        title={promptTitle}
        body={promptDetails}
      />
    </div>
  )
}

Dialogs.propTypes = {
  // alert
  alertTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  alertDetails: PropTypes.string,
  showAlert: PropTypes.bool.isRequired,
  onDismissAlert: PropTypes.func.isRequired,
  // confirm
  confirmTitle: PropTypes.string,
  confirmDetails: PropTypes.string,
  showConfirm: PropTypes.bool.isRequired,
  onDismissConfirm: PropTypes.func.isRequired,
  // prompt
  promptTitle: PropTypes.string,
  promptDetails: PropTypes.string,
  showPrompt: PropTypes.bool.isRequired,
  onDismissPrompt: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const props = createStructuredSelector({
    // alert
    showAlert: selectShowAlert(),
    alertTitle: selectAlertTitle(),
    alertDetails: selectAlertDetails(),
    // confirm
    showConfirm: selectShowConfirm(),
    confirmTitle: selectConfirmTitle(),
    confirmDetails: selectConfirmDetails(),
    // prompt
    showPrompt: selectShowPrompt(),
    promptTitle: selectPromptTitle(),
    promptDetails: selectPromptDetails(),
  })(state, ownProps)
  return props
}

function mapDispatchToProps(dispatch) {
  return {
    onDismissAlert: () => dispatch(dismissAlert()),
    onDismissConfirm: (result) => dispatch(dismissConfirm(result)),
    onDismissPrompt: (result, input) => dispatch(dismissPrompt(result, input)),
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dialogs)
