import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'
import { isArray } from 'lodash'

import { handleError } from 'api/api-utils'
import subBudgetApi from 'api/SubBudgetsApi'
import {
  createSubBudgetRowError,
  createSubBudgetRowSuccess,
  copySubBudgetRowError,
  copySubBudgetRowSuccess,
  updateSubBudgetRowError,
  updateSubBudgetRowSuccess,
} from 'containers/SubBudgetRowModal/actions'
import {
  CREATE_SUB_BUDGET_ROW,
  COPY_SUB_BUDGET_ROW,
  UPDATE_SUB_BUDGET_ROW,
} from 'containers/SubBudgetRowModal/constants'
import { fixLeadingSlash } from 'utils/schemeGroupUtils'

import {
  GET_SUB_BUDGET,
  GET_SUB_BUDGET_DATA,
  GET_SUB_BUDGET_TREE,
  REMOVE_SUB_BUDGET_ROW,
  UPDATE_SUB_BUDGET_CELL,
  UPDATE_SUB_BUDGET_CELL_PATCH,
  MOVE_SUB_BUDGET_ROW,
  GET_SUB_BUDGET_IMPORT_DATA,
  UPDATE_SUB_BUDGET_STATUS,
} from './constants'
import {
  getSubBudgetError,
  getSubBudgetSuccess,
  getSubBudgetDataError,
  getSubBudgetDataSuccess,
  getSubBudgetTreeError,
  getSubBudgetTreeSuccess,
  removeSubBudgetRowError,
  removeSubBudgetRowSuccess,
  updateSubBudgetCellError,
  updateSubBudgetCellSuccess,
  moveSubBudgetRowError,
  moveSubBudgetRowSuccess,
  getSubBudgetImportDataError,
  getSubBudgetImportDataSuccess,
  updateSubBudgetStatusError,
  clearSubBudgetData,
} from './actions'
import { clearBudgetData } from 'containers/Budget/actions'
import { addUsedDataGroup } from 'containers/Company/actions'
import { DUPLICATE_SUB_BUDGET_ROW } from 'containers/SubBudgetRowModal/constants'
import {
  DUPLICATE_BY_TARGETS,
  DUPLICATE_BY_VALUES,
  DUPLICATE_SINGLE,
} from 'containers/SubBudgetDuplicateModal'
import { updateSubBudgetSuccess } from 'containers/SubBudgets/actions'
import { ALL_DIMENSION_TARGETS } from 'containers/BudgetBalanceModal/constants'
import { getDimensions } from 'containers/Dimensions/actions'
import { debounce } from 'utils/sagaDebounce'

const BUFFER_DELAY_IN_MS = 1000

const UpdateSubBudgetInputRowRecord = Record({
  accountId: undefined,
  actualAccountIds: undefined,
  formula: undefined,
  name: undefined,
  inputType: 'None',
  operativeDimensionValues: undefined,
  additionalDimensionValues: undefined,
  formulaReferenceDimensionValues: undefined,
  passDimensionTargetToChildren: false,
  rowType: undefined,
  rollingRuleId: undefined,
  dataGroupId: undefined,
  importBudgetId: undefined,
  importBudgetRowId: undefined,
  importSubBudgetId: undefined,
  importSubBudgetRowId: undefined,
  includeAllDimensionValues: false,
})

const UpdateSubBudgetRowRecord = Record({
  accountId: undefined,
  actualAccountIds: undefined,
  actualAccountGroupIds: undefined,
  actualAccountRangeSchemeRows: undefined,
  formula: undefined,
  name: undefined,
  rowType: undefined,
  inputType: undefined,
  rollingRuleId: undefined,
  dataGroupId: undefined,
  operativeDimensionValues: undefined,
  additionalDimensionValues: undefined,
  formulaReferenceDimensionValues: undefined,
  passDimensionTargetToChildren: false,
  importBudgetId: undefined,
  importBudgetRowId: undefined,
  importSubBudgetId: undefined,
  importSubBudgetRowId: undefined,
  includeAllDimensionValues: false,
})

const mapSelectionToPatch = (selection, value) =>
  selection
    .map((cell) => ({
      op: 'replace',
      path: `/rows/${cell.row}/${cell.column}/amount`,
      value,
    }))
    .toJS()

const mapMultipleCellValuesToPatch = (values) =>
  values.map((value) => ({
    op: 'replace',
    path: `/rows/${value.row}/${value.column}/amount`,
    value: value.value,
  }))

const combineActions = (oldAction, newAction) => {
  const currentPatch = oldAction?.patches || []

  const { selection, value } = newAction
  const newPatch = isArray(value)
    ? mapMultipleCellValuesToPatch(value)
    : mapSelectionToPatch(selection, value)
  return {
    ...newAction,
    patches: [...currentPatch, ...newPatch],
  }
}

export function* getSubBudget(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    start,
    end,
    dv,
    forceRecalculate,
  } = action
  try {
    const [tree, data] = yield all([
      call(subBudgetApi.getSubBudgetTree, {
        companyCode,
        budgetId,
        subBudgetId,
      }),
      call(subBudgetApi.getSubBudgetData, {
        companyCode,
        budgetId,
        subBudgetId,
        start,
        end,
        dv,
        forceRecalculate,
      }),
    ])
    yield put(getSubBudgetSuccess({ subBudgetId, dv, tree, data }))
  } catch (error) {
    yield put(handleError(error, getSubBudgetError))
  }
}

export function* getSubBudgetData(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    start,
    end,
    dv,
    forceRecalculate,
  } = action
  try {
    const data = yield call(subBudgetApi.getSubBudgetData, {
      companyCode,
      budgetId,
      subBudgetId,
      start,
      end,
      dv,
      forceRecalculate,
    })
    yield put(getSubBudgetDataSuccess({ subBudgetId, dv, data }))
  } catch (error) {
    yield put(handleError(error, getSubBudgetDataError))
  }
}

export function* getSubBudgetTree(action) {
  const { companyCode, budgetId, subBudgetId } = action

  try {
    const tree = yield call(subBudgetApi.getSubBudgetTree, {
      companyCode,
      budgetId,
      subBudgetId,
    })
    yield put(getSubBudgetTreeSuccess({ subBudgetId, tree }))
  } catch (error) {
    yield put(handleError(error, getSubBudgetTreeError))
  }
}

// There is a lot of magic happening here... needs refactor on server side too
export function* copySubBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    name,
    order,
    start,
    end,
    dv,
    parentRow,
    copy,
  } = action
  const newPath = fixLeadingSlash(`${parentRow.path}/children/${order}`)
  const fromPath = fixLeadingSlash(`${copy.path}`)
  const copyPatch = [
    {
      op: 'copy',
      from: fromPath,
      path: newPath,
    },
    {
      op: 'replace',
      path: `${newPath}/Name`,
      value: name,
    },
  ]

  try {
    const tree = yield call(subBudgetApi.patchSubBudgetTree, {
      companyCode,
      budgetId,
      subBudgetId,
      patch: copyPatch,
    })

    const data = yield call(subBudgetApi.getSubBudgetData, {
      companyCode,
      budgetId,
      subBudgetId,
      start,
      end,
      dv,
    })

    yield put(copySubBudgetRowSuccess({ subBudgetId, dv, tree, data }))
  } catch (error) {
    yield put(handleError(error, copySubBudgetRowError))
  }
}

const createDuplicationPatch = ({
  dimensionValues,
  duplicationMode,
  name,
  targetDimension,
  fromPath,
  newPath,
  matrixes,
  matrixDimensionNames,
}) => {
  if (duplicationMode === DUPLICATE_SINGLE) {
    return [
      {
        op: 'copy',
        from: fromPath,
        path: newPath(0),
      },
      {
        op: 'replace',
        path: `${newPath(0)}/Name`,
        value: name,
      },
    ]
  }

  const patch = []
  if (duplicationMode === DUPLICATE_BY_TARGETS) {
    matrixes.forEach((matrix, i) => {
      patch.push({
        op: 'copy',
        from: fromPath,
        path: newPath(i),
      })
      patch.push({
        op: 'replace',
        path: `${newPath(i)}/Name`,
        value: matrix.name,
      })
      patch.push({
        op: 'replace',
        path: `${newPath(i)}/DimensionEvents`,
        value: matrixDimensionNames
          .map((name, dimensionIndex) => ({
            dimensionName: name,
            dimensionValueName: matrix.dimensionValues[dimensionIndex],
          }))
          .filter((event) => event.dimensionValueName && event.dimensionName),
      })
    })
  }

  if (duplicationMode === DUPLICATE_BY_VALUES) {
    dimensionValues.forEach((value, i) => {
      patch.push({
        op: 'copy',
        from: fromPath,
        path: newPath(i),
      })
      patch.push({
        op: 'replace',
        path: `${newPath(i)}/Name`,
        value: value.name,
      })
      if (targetDimension) {
        patch.push({
          op: 'replace',
          path: `${newPath(i)}/${
            value.type === 0
              ? 'additionalDimensionValues'
              : 'operativeDimensionValues'
          }`,
          value: [value.id],
        })
      }
    })
  }
  return patch
}

export function* duplicateSubBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    start,
    end,
    dv,
    copy,
    dimensionValues,
    duplicationMode,
    name,
    targetDimension,
    matrixes,
    matrixDimensionNames,
  } = action
  const fromPath = fixLeadingSlash(`${copy.path}`)
  const newPath = (childIndex) => {
    const pathArray = fromPath.split('/')
    pathArray[pathArray.length - 1] =
      parseInt(pathArray[pathArray.length - 1]) + (childIndex + 1)
    return pathArray.join('/')
  }
  const copyPatch = createDuplicationPatch({
    dimensionValues,
    duplicationMode,
    name,
    targetDimension,
    fromPath,
    newPath,
    matrixes,
    matrixDimensionNames,
  })

  try {
    const tree = yield call(subBudgetApi.patchSubBudgetTree, {
      companyCode,
      budgetId,
      subBudgetId,
      patch: copyPatch,
    })

    const data = yield call(subBudgetApi.getSubBudgetData, {
      companyCode,
      budgetId,
      subBudgetId,
      start,
      end,
      dv,
    })

    if (duplicationMode === DUPLICATE_BY_TARGETS) {
      yield put(getDimensions({ companyCode }))
    }

    yield put(copySubBudgetRowSuccess({ subBudgetId, dv, tree, data }))
  } catch (error) {
    yield put(handleError(error, copySubBudgetRowError))
  }
}

export function* createSubBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    row,
    start,
    end,
    dv,
    parentRow,
  } = action
  const order = row ? row.order : '-'
  const patch = [
    {
      op: 'add',
      path: fixLeadingSlash(`${parentRow.path}/children/${order}`),
      value: row,
    },
  ]
  try {
    yield call(subBudgetApi.patchSubBudgetTree, {
      companyCode,
      budgetId,
      subBudgetId,
      patch,
    })

    // FIXME: Tree must be fetched again, because if `accountId` was given,
    // the patch endpoint will not return the `account` property.
    const [tree, data] = yield all([
      call(subBudgetApi.getSubBudgetTree, {
        companyCode,
        budgetId,
        subBudgetId,
      }),
      call(subBudgetApi.getSubBudgetData, {
        companyCode,
        budgetId,
        subBudgetId,
        start,
        end,
        dv,
      }),
    ])

    if (row.dataGroupId) yield put(addUsedDataGroup(row.dataGroupId))

    yield put(createSubBudgetRowSuccess({ subBudgetId, dv, tree, data }))
  } catch (error) {
    yield put(handleError(error, createSubBudgetRowError))
  }
}

export function* removeSubBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    path,
    rowId,
    subBudgetId,
    start,
    end,
    dv,
  } = action

  const patch = [
    {
      op: 'remove',
      path: fixLeadingSlash(path),
    },
  ]

  try {
    const tree = yield call(subBudgetApi.patchSubBudgetTree, {
      companyCode,
      budgetId,
      subBudgetId,
      patch,
    })
    const data = yield call(subBudgetApi.getSubBudgetData, {
      companyCode,
      budgetId,
      subBudgetId,
      start,
      end,
      dv,
    })

    yield put(
      removeSubBudgetRowSuccess({
        subBudgetId,
        dv,
        tree,
        data,
        budgetId,
        rowId,
      })
    )
  } catch (error) {
    yield put(handleError(error, removeSubBudgetRowError))
  }
}

export function* moveSubBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    start,
    end,
    dv,
    sourcePath,
    targetPath,
  } = action
  const resolvedTargetPath = resolveTarget({ sourcePath, targetPath })

  const patch = [
    {
      op: 'move',
      from: sourcePath,
      path: resolvedTargetPath,
    },
  ]

  try {
    const tree = yield call(subBudgetApi.patchSubBudgetTree, {
      companyCode,
      budgetId,
      subBudgetId,
      patch,
    })
    const data = yield call(subBudgetApi.getSubBudgetData, {
      companyCode,
      budgetId,
      subBudgetId,
      start,
      end,
      dv,
    })

    yield put(
      moveSubBudgetRowSuccess({
        subBudgetId,
        dv,
        tree,
        data,
      })
    )
  } catch (error) {
    yield put(handleError(error, moveSubBudgetRowError))
  }
}

const resolveTarget = ({ sourcePath, targetPath }) => {
  const splitSource = sourcePath.split('/')
  const splitTarget = targetPath.split('/')

  const isMovingInside = splitSource.length < splitTarget.length
  // this is done to update the index correctly
  // patch 'move' removes the element to move from the array first, then tries to add to the select index
  if (isMovingInside) {
    splitTarget[splitSource.length - 1] =
      splitTarget[splitSource.length - 1] - 1
  }

  if (splitTarget[splitSource.length - 1] < 0)
    splitTarget[splitSource.length - 1] = 0

  return splitTarget.join('/')
}

export function* updateSubBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    start,
    end,
    dv,
    oldRow,
    newRow,
  } = action
  const isNewRowTypeInput =
    newRow.rowType === 'Input' || newRow.rowType === 'Formula'
  const oldRowRecord = isNewRowTypeInput
    ? new UpdateSubBudgetInputRowRecord(oldRow)
    : new UpdateSubBudgetRowRecord(oldRow)
  const newRowRecord = isNewRowTypeInput
    ? new UpdateSubBudgetInputRowRecord(newRow)
    : new UpdateSubBudgetRowRecord(newRow)
  const patch = Diff(oldRowRecord, newRowRecord)
    .map((patchUnit) =>
      patchUnit.set('path', `${oldRow.path}${patchUnit.get('path')}`)
    )
    .toJS()
  try {
    yield call(subBudgetApi.patchSubBudgetTree, {
      companyCode,
      budgetId,
      subBudgetId,
      patch,
    })
    // parseint calls are because types differ. Old rows' tagIds are integers. newRow's are strings. or the other way round
    const removedTags = oldRow.tagIds.filter(
      (oldTagId) =>
        !newRow.tagIds.find(
          (tagId) => parseInt(oldTagId, 10) === parseInt(tagId, 10)
        )
    )
    const addedTags = newRow.tagIds.filter(
      (tagId) =>
        !oldRow.tagIds.find(
          (oldTagId) => parseInt(tagId, 10) === parseInt(oldTagId, 10)
        )
    )
    const shouldUpdateTags = removedTags.concat(addedTags).length > 0

    if (shouldUpdateTags) {
      const addCalls = addedTags.map((tagId) =>
        call(subBudgetApi.addTag, {
          companyCode,
          budgetId,
          subBudgetId,
          rowId: oldRow.id,
          tagId,
        })
      )
      const removeCalls = removedTags.map((tagId) =>
        call(subBudgetApi.deleteTag, {
          companyCode,
          budgetId,
          subBudgetId,
          rowId: oldRow.id,
          tagId,
        })
      )
      yield addCalls.concat(removeCalls)
    }

    // FIXME: Tree must be fetched again, because if `accountId` was given,
    // the patch endpoint will not return the `account` property.
    const [tree, data] = yield all([
      call(subBudgetApi.getSubBudgetTree, {
        companyCode,
        budgetId,
        subBudgetId,
      }),
      call(subBudgetApi.getSubBudgetData, {
        companyCode,
        budgetId,
        subBudgetId,
        start,
        end,
        dv,
      }),
    ])

    yield put(updateSubBudgetRowSuccess({ data, tree, dv, subBudgetId }))
  } catch (error) {
    yield put(handleError(error, updateSubBudgetRowError))
  }
}

export function* updateSubBudgetCell(action) {
  const { companyCode, budgetId, subBudgetId, start, end, dv, patches } = action

  try {
    const dataPatch = yield call(subBudgetApi.patchSubBudgetData, {
      companyCode,
      budgetId,
      subBudgetId,
      patch: patches,
      dv,
      start,
      end,
    })
    yield put(updateSubBudgetCellSuccess({ subBudgetId, dv, dataPatch }))
    yield put(clearBudgetData())
  } catch (error) {
    yield put(handleError(error, updateSubBudgetCellError))
  }
}

export function* updateSubBudgetStatus(action) {
  const {
    companyCode,
    budgetId,
    sourceSubBudget: { id: subBudgetId },
    status,
  } = action

  try {
    const subBudget = yield call(subBudgetApi.patchSubBudgetStatus, {
      companyCode,
      budgetId,
      subBudgetId,
      status,
    })

    yield put(updateSubBudgetSuccess({ budgetId, subBudget, companyCode }))
  } catch (error) {
    yield put(handleError(error, updateSubBudgetStatusError))
  }
}

export function* getSubBudgetImportData(action) {
  const {
    companyCode,
    budgetId,
    subBudgetId,
    subBudgetRowId,
    sourceStart,
    sourceEnd,
    targetStart,
    targetEnd,
    viewStart,
    viewEnd,
    dimensionValues,
    multiplier,
    additionAmount,
    persist,
    rowFilterDimensionValues,
    dataGroup,
    dataType,
    importType,
    importMode,
    accountId,
  } = action

  try {
    const data = yield call(subBudgetApi.getSubBudgetImportData, {
      companyCode,
      budgetId,
      subBudgetId,
      subBudgetRowId,
      sourceStart,
      sourceEnd,
      targetStart,
      targetEnd,
      viewStart,
      viewEnd,
      dimensionValues,
      multiplier,
      additionAmount,
      persist,
      rowFilterDimensionValues,
      dataGroup,
      dataType,
      importType,
      importMode,
      accountId,
    })
    if (persist) {
      yield put(
        // Data was saved to db so we store data normally to redux
        getSubBudgetDataSuccess({ data, dv: dimensionValues, subBudgetId })
      )
      yield put(clearBudgetData())
      if (importMode === ALL_DIMENSION_TARGETS) {
        yield put(clearSubBudgetData({ subBudgetId, dv: dimensionValues }))
        yield getSubBudgetData({
          companyCode,
          budgetId,
          subBudgetId,
          start: viewStart,
          end: viewEnd,
          dv: dimensionValues,
        })
      }
    } else {
      yield put(
        // Just a preview, store to specific preview spot on redux
        getSubBudgetImportDataSuccess({
          data,
          dv: dimensionValues,
          subBudgetId,
        })
      )
    }
  } catch (error) {
    yield put(handleError(error, getSubBudgetImportDataError))
  }
}

// Individual exports for testing
export function* subBudgetSaga() {
  yield all([
    takeLatest(GET_SUB_BUDGET, getSubBudget),
    takeLatest(GET_SUB_BUDGET_DATA, getSubBudgetData),
    takeEvery(UPDATE_SUB_BUDGET_CELL_PATCH, updateSubBudgetCell),
    takeEvery(UPDATE_SUB_BUDGET_STATUS, updateSubBudgetStatus),
    takeEvery(GET_SUB_BUDGET_TREE, getSubBudgetTree),
    takeEvery(CREATE_SUB_BUDGET_ROW, createSubBudgetRow),
    takeEvery(REMOVE_SUB_BUDGET_ROW, removeSubBudgetRow),
    takeEvery(COPY_SUB_BUDGET_ROW, copySubBudgetRow),
    takeEvery(DUPLICATE_SUB_BUDGET_ROW, duplicateSubBudgetRow),
    takeLatest(UPDATE_SUB_BUDGET_ROW, updateSubBudgetRow),
    takeEvery(MOVE_SUB_BUDGET_ROW, moveSubBudgetRow),
    takeLatest(GET_SUB_BUDGET_IMPORT_DATA, getSubBudgetImportData),
    debounce(
      BUFFER_DELAY_IN_MS,
      UPDATE_SUB_BUDGET_CELL,
      updateSubBudgetCell,
      combineActions
    ),
  ])
}

// All sagas to be loaded
export default subBudgetSaga
