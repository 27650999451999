import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import usersApi from 'api/UsersApi'
import userRoleApi from 'api/UserRolesApi'

import {
  CREATE_USER,
  DELETE_AAD_USER,
  DELETE_USER,
  GET_ALL_USERS,
  GET_USERS,
  INVITE_USER,
  SET_USER_ROLES,
  UNLINK_AAD_USER,
} from './constants'

import {
  createUserSuccess,
  createUserError,
  deleteAADUserSuccess,
  deleteAADUserError,
  deleteUserSuccess,
  deleteUserError,
  getUsersSuccess,
  getUsersError,
  getAllUsersSuccess,
  getAllUsersError,
  inviteUserError,
  inviteUserSuccess,
  unlinkAADUserSuccess,
  unlinkAADUserError,
  setUserRolesSuccess,
  setUserRolesError,
} from './actions'
import { logout, removeAADProfile } from '../App/actions'

export function* getUsers(action) {
  try {
    const { companyCode, customerCode, role } = action
    const users = yield call(usersApi.getUsers, {
      companyCode,
      customerCode,
      role,
    })
    yield put(getUsersSuccess(users))
  } catch (error) {
    yield put(handleError(error, getUsersError))
  }
}

export function* getAllsers() {
  try {
    const users = yield call(usersApi.getAllsers)
    yield put(getAllUsersSuccess(users))
  } catch (error) {
    yield put(handleError(error, getAllUsersError))
  }
}

export function* setUserRoles(action) {
  try {
    const { companyCode, customerCode, roles, userId } = action
    yield call(userRoleApi.setUserRoles, {
      companyCode,
      customerCode,
      roles,
      userId,
    })
    yield put(setUserRolesSuccess())
  } catch (error) {
    yield put(handleError(error, setUserRolesError))
  }
}

export function* createUser(action) {
  try {
    const {
      companyCode,
      customerCode,
      user: { email },
      role,
    } = action
    const user = yield call(usersApi.createUser, {
      companyCode,
      customerCode,
      user: action.user,
    })
    // "User" user role is given to the user by default in backend.
    // "Admin" user role must be given explicitly.
    if (role === 'admin') {
      const userRole = { userid: user.id, email, roles: [role] }
      yield call(userRoleApi.createUserRole, {
        companyCode,
        customerCode,
        role: userRole,
      })
    }
    yield put(createUserSuccess(user))
  } catch (error) {
    yield put(handleError(error, createUserError))
  }
}

export function* deleteUser(action) {
  try {
    const { userId } = action //Finaz id
    const userData = yield call(usersApi.getLoggedInUser)
    yield call(usersApi.deleteUser, { userId })
    yield put(deleteUserSuccess(userId))
    //dispatch logout if user was the current user.
    if (userId === userData.id) yield put(logout())
  } catch (error) {
    yield put(handleError(error, deleteUserError))
  }
}

export function* deleteAADUser(action) {
  try {
    const { userId } = action //AAD id
    const userData = yield call(usersApi.getLoggedInUser)
    yield call(usersApi.deleteAADUser, { userId })
    yield put(deleteAADUserSuccess(userId))
    yield put(removeAADProfile(userId, true))
    //dispatch logout if user was the current user.
    if (userId === userData.id) yield put(logout())
  } catch (error) {
    yield put(handleError(error, deleteAADUserError))
  }
}
export function* inviteUser(action) {
  try {
    const { user, email } = action
    const id = user.id
    yield call(usersApi.inviteUser, { userId: id, inviteEmail: email })
    const users = yield call(usersApi.getAllsers)
    yield put(inviteUserSuccess(users))
  } catch (error) {
    yield put(handleError(error, inviteUserError))
  }
}
export function* unlinkAADUser(action) {
  try {
    const { userId, allUsersView } = action
    yield call(usersApi.unlinkAADUser, { userId })
    yield put(unlinkAADUserSuccess(userId, allUsersView))
    yield put(removeAADProfile(userId, false))
    //dispatch logout if user was the current user.
    // if (userId === userData.id) yield put(logout())
  } catch (error) {
    yield put(handleError(error, unlinkAADUserError))
  }
}

export function* usersSaga() {
  yield all([
    takeLatest(GET_USERS, getUsers),
    takeLatest(GET_ALL_USERS, getAllsers),
    takeEvery(CREATE_USER, createUser),
    takeEvery(DELETE_USER, deleteUser),
    takeEvery(DELETE_AAD_USER, deleteAADUser),
    takeEvery(UNLINK_AAD_USER, unlinkAADUser),
    takeEvery(INVITE_USER, inviteUser),
    takeEvery(SET_USER_ROLES, setUserRoles),
  ])
}

// All sagas to be loaded
export default usersSaga
