import { Record } from 'immutable'

const BudgetDataQuery = Record({
  companyCode: undefined,
  budgetId: undefined,
  dv: undefined,
  end: '2016-12',
  start: '2016-01',
})

export default BudgetDataQuery
