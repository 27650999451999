import { Map } from 'immutable'
import diff from 'immutablediff'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import companyApi from 'api/CompanyApi'
import integrationApi from 'api/IntegrationApi'
import invoiceApi from 'api/InvoiceApi'

import {
  recalculateCompanyBalancesSuccess,
  updateCompanySuccess,
  updateCompanyError,
  getScheduledIntegrationsSuccess,
  getScheduledIntegrationsError,
  dropInFileError,
  dropInFileSuccess,
  getDataGroupsSuccess,
  getDataGroupsError,
  getIntegrationLogGroupsSuccess,
  getIntegrationLogsForGroupSuccess,
  deleteInvoicesSuccess,
  deleteInvoicesError,
  updateLinksSuccess,
  triggerScheduledIntegrationSuccess,
  triggerScheduledIntegrationError,
  deleteAccountsError,
  deleteAccountsSuccess,
} from './actions'
import {
  UPDATE_COMPANY,
  RECALCULATE_COMPANY_BALANCES,
  GET_SCHEDULED_INTEGRATIONS,
  INTEGRATION_FILE_UPLOAD,
  GET_USED_DATA_GROUPS,
  GET_INTEGRATION_LOG_GROUPS,
  GET_INTEGRATION_LOG_FOR_GROUPS,
  DELETE_INVOICES,
  DELETE_VOUCHERS,
  DELETE_OPERATIVE_DATA,
  TRIGGER_SCHEDULED_INTEGRATION,
  UPDATE_COMPANY_LINKS,
} from './constants'
import {
  INTEGRATION_FILE_UPLOAD_DATA_TYPE_OPERATIVE,
  INTEGRATION_FILE_UPLOAD_DATA_TYPE_SUB_BUDGET,
  INTEGRATION_FILE_UPLOAD_DATA_TYPE_VOUCHER,
} from 'components/IntegrationFileUploadForm/constants'
import voucherApi from 'api/VoucherApi'
import operativeDataApi from 'api/OperativeDataApi'
import accountApi from 'api/AccountApi'
import { DELETE_ACCOUNTS } from 'containers/Accounts/constants'

export function* getScheduledIntegrations(action) {
  const { ovt } = action
  try {
    const scheduledIntegrations = yield call(
      integrationApi.getScheduledIntegrations,
      { ovt }
    )
    yield put(getScheduledIntegrationsSuccess(scheduledIntegrations))
  } catch (error) {
    const data = { ...error.data, code: error.data?.Message }
    yield put(handleError({ ...error, data }, getScheduledIntegrationsError))
  }
}

export function* triggerScheduledIntegration(action) {
  const { ovt, id } = action
  try {
    const scheduledIntegration = yield call(
      integrationApi.triggerScheduledIntegration,
      { ovt, id }
    )
    yield put(triggerScheduledIntegrationSuccess({ scheduledIntegration }))
  } catch (error) {
    const data = { ...error.data, code: 'E-0014' }
    yield put(handleError({ ...error, data }, triggerScheduledIntegrationError))
  }
}

export function* recalculateCompanyBalances(action) {
  const { companyCode, end, start } = action
  try {
    const updatedCompany = yield call(companyApi.recalculateCompanyBalances, {
      companyCode,
      end,
      start,
    })
    yield put(
      recalculateCompanyBalancesSuccess({
        updatedCompany,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateCompanyError))
  }
}

export function* updateCompany(action) {
  const { company, customerCode, updatedValues } = action
  const patch = diff(company, company.merge(Map(updatedValues))).toJS()
  try {
    const updatedCompany = yield call(companyApi.patchCompany, {
      customerCode,
      companyCode: company.code,
      patch,
    })

    yield put(
      updateCompanySuccess({
        customerCode,
        updatedCompany,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateCompanyError))
  }
}

export function* updateCompanyLinks(action) {
  const { company, customerCode, links } = action
  try {
    const updatedCompany = yield call(companyApi.patchCompany, {
      customerCode,
      companyCode: company.code,
      patch: [
        {
          op: 'replace',
          path: '/links',
          value: links,
        },
      ],
    })

    yield put(
      updateCompanySuccess({
        customerCode,
        updatedCompany,
      })
    )
    yield put(
      updateLinksSuccess({
        company,
        links,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateCompanyError))
  }
}

export function* integrationFileUpload(action) {
  const {
    uploadedFile,
    ovt,
    dataType,
    dataGroup,
    overrideSourceSystem,
    companyCode,
  } = action
  try {
    let response
    switch (dataType) {
      case INTEGRATION_FILE_UPLOAD_DATA_TYPE_OPERATIVE:
        response = yield call(integrationApi.uploadOperativeFile, {
          uploadedFile,
          ovt,
          dataGroup,
          overrideSourceSystem,
        })
        yield getUsedDataGroups({ companyCode })
        break
      case INTEGRATION_FILE_UPLOAD_DATA_TYPE_VOUCHER:
        response = yield call(integrationApi.uploadVoucherFile, {
          uploadedFile,
          ovt,
          overrideSourceSystem,
        })
        break
      case INTEGRATION_FILE_UPLOAD_DATA_TYPE_SUB_BUDGET:
        response = yield call(integrationApi.uploadSubBudgetFile, {
          uploadedFile,
          ovt,
          overrideSourceSystem,
          budgetId: action.budgetId,
          personnelExpenseAccountNumber: action.personnelExpenseAccountNumber,
          startMonth: action.startMonth,
          startYear: action.startYear,
          endMonth: action.endMonth,
          endYear: action.endYear,
          holidaySalaryCalculateConstantSymbol:
            action.holidaySalaryCalculateConstantSymbol,
          filePassword: action.filePassword,
        })
        break
      default:
        throw new Error('Invalid data type selection')
    }
    yield put(dropInFileSuccess(response))
    yield getIntegrationLogGroups({ companyCode })
  } catch (error) {
    yield getIntegrationLogGroups({ companyCode })
    yield put(handleError(error, dropInFileError))
  }
}

export function* getUsedDataGroups(action) {
  const { companyCode } = action
  try {
    const response = yield call(companyApi.getDataGroups, { companyCode })
    yield put(getDataGroupsSuccess(response))
  } catch (error) {
    yield put(handleError(error, getDataGroupsError))
  }
}

export function* getIntegrationLogGroups(action) {
  const { companyCode } = action
  try {
    const response = yield call(integrationApi.getIntegrationLogGroups, {
      companyCode,
    })
    yield put(getIntegrationLogGroupsSuccess(response))
  } catch (error) {
    yield put(handleError(error, dropInFileError))
  }
}

export function* getIntegrationLogsForGroup(action) {
  const { groupId, companyCode } = action
  try {
    const response = yield call(integrationApi.getIntegrationLogs, {
      companyCode,
      groupId,
    })
    //After individual update fetch group list in order to keep them in sync.
    yield getUsedDataGroups({ companyCode })
    yield put(
      getIntegrationLogsForGroupSuccess({ items: response, groupId: groupId })
    )
  } catch (error) {
    yield put(handleError(error, dropInFileError))
  }
}

export function* deleteInvoices(action) {
  const { companyCode } = action
  try {
    yield call(invoiceApi.deleteInvoices, {
      companyCode,
    })
    yield put(deleteInvoicesSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteInvoicesError))
  }
}

export function* deleteVouchers(action) {
  const { companyCode } = action
  try {
    yield call(voucherApi.deleteVouchers, {
      companyCode,
    })
    yield put(deleteInvoicesSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteInvoicesError))
  }
}

export function* deleteOperativeData(action) {
  const { companyCode } = action
  try {
    yield call(operativeDataApi.deleteOperativeDatas, {
      companyCode,
    })
    yield put(deleteInvoicesSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteInvoicesError))
  }
}

export function* deleteAccounts(action) {
  const { companyCode } = action
  try {
    yield call(accountApi.deleteAccounts, { companyCode })
    yield put(deleteAccountsSuccess({ companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteAccountsError))
  }
}

export function* companySaga() {
  yield all([
    takeEvery(UPDATE_COMPANY, updateCompany),
    takeEvery(RECALCULATE_COMPANY_BALANCES, recalculateCompanyBalances),
    takeLatest(INTEGRATION_FILE_UPLOAD, integrationFileUpload),
    takeLatest(GET_USED_DATA_GROUPS, getUsedDataGroups),
    takeLatest(GET_INTEGRATION_LOG_GROUPS, getIntegrationLogGroups),
    takeLatest(GET_SCHEDULED_INTEGRATIONS, getScheduledIntegrations),
    takeLatest(DELETE_INVOICES, deleteInvoices),
    takeLatest(DELETE_ACCOUNTS, deleteAccounts),
    takeLatest(DELETE_VOUCHERS, deleteVouchers),
    takeLatest(DELETE_OPERATIVE_DATA, deleteOperativeData),
    takeLatest(GET_INTEGRATION_LOG_FOR_GROUPS, getIntegrationLogsForGroup),
    takeLatest(TRIGGER_SCHEDULED_INTEGRATION, triggerScheduledIntegration),
    takeLatest(UPDATE_COMPANY_LINKS, updateCompanyLinks),
  ])
}

// All sagas to be loaded
export default companySaga
