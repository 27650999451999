import { Record } from 'immutable'

const ReportingGroupRecord = Record({
  id: undefined,
  companyId: undefined,
  name: undefined,
  parentId: undefined,
  parent: undefined,
  isCollapsed: true,
  reportingGroups: undefined,
  reportingGroupDimensionIds: undefined,
  reportingGroupDimensionValueIds: undefined,
  // level tells how deep in the hierarchy this reportingGroup is, e.g. 0,1,2,etc.
  level: undefined,
  dimensionValueNamesAndDimension: undefined,
})

export default ReportingGroupRecord
