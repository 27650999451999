/*
 *
 * RollingRules constants
 *
 */
export const CHANGE_ROLLING_RULE_FORM_TYPE =
  'app/RollingRules/CHANGE_ROLLING_RULE_FORM_TYPE'
export const CHANGE_EDIT_ROLLING_RULE_FORM_TYPE =
  'app/RollingRules/CHANGE_EDIT_ROLLING_RULE_FORM_TYPE'

export const CREATE_ROLLINGRULE = 'app/RollingRules/CREATE_ROLLINGRULE'
export const CREATE_ROLLINGRULE_SUCCESS =
  'app/RollingRules/CREATE_ROLLINGRULE_SUCCESS'
export const CREATE_ROLLINGRULE_ERROR =
  'app/RollingRules/CREATE_ROLLINGRULE_ERROR'

export const DELETE_ROLLINGRULE = 'app/RollingRules/DELETE_ROLLINGRULE'
export const DELETE_ROLLINGRULE_SUCCESS =
  'app/RollingRules/DELETE_ROLLINGRULE_SUCCESS'
export const DELETE_ROLLINGRULE_ERROR =
  'app/RollingRules/DELETE_ROLLINGRULE_ERROR'

export const EDIT_ROLLING_RULE = 'app/RollingRules/EDIT_ROLLING_RULE'

export const GET_INIT_TO_ROLLING_SUCCESS =
  'app/RollingRules/GET_INIT_TO_ROLLING_SUCCESS'
export const GET_INIT_TO_ROLLING_FAILED =
  'app/RollingRules/GET_INIT_TO_ROLLING_FAILED'
export const GET_INIT_TO_ROLLING = 'app/RollingRules/GET_INIT_TO_ROLLING'

export const GET_ROLLINGRULES = 'app/RollingRules/GET_ROLLINGRULES'
export const GET_ROLLINGRULES_SUCCESS =
  'app/RollingRules/GET_ROLLINGRULES_SUCCESS'
export const GET_ROLLINGRULES_ERROR = 'app/RollingRules/GET_ROLLINGRULES_ERROR'

export const TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM =
  'app/RollingRules/TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM'

export const UPDATE_ROLLING_RULE = 'app/RollingRules/UPDATE_ROLLING_RULE'
export const UPDATE_ROLLING_RULE_ERROR =
  'app/RollingRules/UPDATE_ROLLING_RULE_ERROR'
export const UPDATE_ROLLING_RULE_SUCCESS =
  'app/RollingRules/UPDATE_ROLLING_RULE_SUCCESS'
