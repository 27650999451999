import { all, call, put, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import voucherApi from 'api/VoucherApi'

import { GET_VOUCHER, UPDATE_VOUCHER } from './constants'
import {
  getVoucherError,
  getVoucherSuccess,
  updateVoucherError,
  updateVoucherSuccess,
} from './actions'

// Individual exports for testing
export function* getVoucher({ companyCode, id }) {
  try {
    const voucher = yield call(voucherApi.getVoucher, {
      companyCode,
      id,
    })
    yield put(getVoucherSuccess({ voucher }))
  } catch (error) {
    yield put(handleError(error, getVoucherError))
  }
}

export function* updateVoucher({ companyCode, voucher }) {
  try {
    const updatedVoucher = yield call(voucherApi.updateVoucher, {
      companyCode,
      voucher,
    })
    yield put(updateVoucherSuccess({ voucher: updatedVoucher }))
  } catch (error) {
    yield put(handleError(error, updateVoucherError))
  }
}

// All sagas to be loaded
export function* voucherSaga() {
  yield all([
    takeLatest(GET_VOUCHER, getVoucher),
    takeLatest(UPDATE_VOUCHER, updateVoucher),
  ])
}

export default voucherSaga
