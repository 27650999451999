import { useEffect } from 'react'

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
// This hook allows you to detect clicks outside of React component
export default function useOutsideAlerter(ref, action) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(event)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, action])
}
