import diff from 'immutablediff'
import { all, call, put, takeEvery, take } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import reportSelectionApi from 'api/ReportSelectionApi'

import {
  saveReportSelectionError,
  saveReportSelectionSuccess,
  getReportProgressForPreviewSuccess,
  getReportDataPreviewSuccess,
  getReportDataPreviewError,
} from './actions'
import ReportSelectionUpdateRecord from 'records/ReportSelection/report-selection-update'
import {
  OPEN_REPORT_WITHOUT_SAVING,
  SAVE_REPORT,
  GET_REPORT_DATA_PREVIEW,
} from './constants'
import { eventChannel, END } from 'redux-saga'
import { tryParseErrorJson } from 'utils/parseError'

export function* saveReportSelection(action) {
  const {
    customerCode,
    companyCode,
    oldReportSelection,
    newReportSelection,
  } = action
  try {
    const currentReport = new ReportSelectionUpdateRecord(oldReportSelection)
    const incomingReport = new ReportSelectionUpdateRecord(newReportSelection)
    const patch = diff(currentReport, incomingReport)
    const responseValue = yield call(reportSelectionApi.patchReportSelection, {
      customerCode,
      companyCode,
      patch,
      reportSelectionId: currentReport.get('id'),
    })
    yield put(
      saveReportSelectionSuccess({
        customerCode,
        companyCode,
        reportSelection: responseValue,
      })
    )
  } catch (error) {
    yield put(handleError(error, saveReportSelectionError))
  }
}

export function* getReportDataPreview(action) {
  const { companyCode, customerCode, reportSelection } = action

  try {
    const channel = eventChannel((emitter) => {
      const onMessage = (event) => {
        try {
          const data = JSON.parse(event.data).data
          emitter(getReportDataPreviewSuccess({ report: data }))
          emitter(END)
        } catch (error) {
          emitter(handleError(error, getReportDataPreviewError))
          emitter(END)
        }
      }

      const onProgress = (event) => {
        emitter(
          getReportProgressForPreviewSuccess({
            progress: event.data,
            companyCode,
            customerCode,
          })
        )
      }

      const onError = (event) => {
        emitter(
          handleError(
            { data: tryParseErrorJson(event.data) },
            getReportDataPreviewError
          )
        )
        emitter(END)
      }

      reportSelectionApi.openReportByReportSelection({
        companyCode,
        customerCode,
        reportSelection,
        onMessage,
        onProgress,
        onError,
      })

      return () => {}
    })

    while (true) {
      const event = yield take(channel)
      if (event === END) break
      yield put(event)
    }
  } catch (error) {
    yield put(handleError(error, getReportDataPreviewError))
  }
}

export function* reportSelectionsSaga() {
  yield all([
    takeEvery(GET_REPORT_DATA_PREVIEW, getReportDataPreview),
    takeEvery(OPEN_REPORT_WITHOUT_SAVING, getReportDataPreview),
    takeEvery(SAVE_REPORT, saveReportSelection),
  ])
}

// All sagas to be loaded
export default reportSelectionsSaga
