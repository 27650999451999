import moment from 'moment'

import RecurringPaymentRecord from 'records/RecurringPayment/recurringPayment'
import RecurringPaymentPaymentEventRecord from 'records/RecurringPayment/recurringPaymentPaymentEvent'
import RecurringPaymentOccurenceRecord from 'records/RecurringPayment/recurringPaymentOccurence'

import { enumValueToEnumName } from 'utils/enumObj'
import { listToMapWithLists } from 'utils/immutable'
import { RECURRING_PAYMENT_FREQUENCIES, PAYMENT_TYPES } from './constants'

export const mapRecurringPaymentToRecord = (recurringPayment) =>
  RecurringPaymentRecord({
    id: recurringPayment.id,
    companyId: recurringPayment.companyId,
    dueDate: recurringPayment.dueDate,
    repeatCount: recurringPayment.repeatCount,
    frequency: enumValueToEnumName(
      recurringPayment.frequency,
      RECURRING_PAYMENT_FREQUENCIES
    ),
    amount: recurringPayment.amount,
    paymentType: enumValueToEnumName(
      recurringPayment.paymentType,
      PAYMENT_TYPES
    ),
    description: recurringPayment.description,
  })

export const mapRecurringPaymentPaymentEventToRecord = (
  recurringPaymentPaymentEvent
) =>
  RecurringPaymentPaymentEventRecord({
    dueDate: recurringPaymentPaymentEvent.dueDate,
    amountTaxIncluded: recurringPaymentPaymentEvent.amountTaxIncluded,
    paymentType: enumValueToEnumName(
      recurringPaymentPaymentEvent.paymentType,
      PAYMENT_TYPES
    ),
    recurringPaymentId: recurringPaymentPaymentEvent.recurringPaymentId,
    nthOccurence: recurringPaymentPaymentEvent.nthOccurence,
    isRemoved: recurringPaymentPaymentEvent.isRemoved,
    recurringPaymentOccurenceId:
      recurringPaymentPaymentEvent.recurringPaymentOccurenceId,
    description: recurringPaymentPaymentEvent.description,
  })

export const mapRecurringPaymentOccurenceToRecord = (occurence) =>
  RecurringPaymentOccurenceRecord({
    recurringPaymentId: occurence.recurringPaymentId,
    amount: occurence.amount,
    nthOccurence: occurence.nthOccurence,
    isRemoved: occurence.isRemoved,
    id: occurence.id,
  })

export const getRecurringPaymentPaymentEventsByDate = (
  recurringPaymentPaymentEvents,
  today
) => {
  const todayInMoment = moment(today)
  return listToMapWithLists(
    recurringPaymentPaymentEvents
      .toList()
      .flatten(1)
      .filter((paymentEvent) =>
        moment(paymentEvent.dueDate).isSameOrAfter(todayInMoment, 'day')
      ),
    'dueDate'
  ).mapKeys((key) => moment(key).format('YYYY-MM-DD'))
}

export const translateOptionName = (
  option,
  formatMessage,
  translationKeyStart,
  messages
) => ({
  name: formatMessage(messages[`${translationKeyStart}${option.name}`]),
  value: option.value,
})
