import { Record } from 'immutable'

const AccountSchemeTemplateUpdateRowRecord = Record({
  endAccount: '',
  rowFactor: '',
  startAccount: '',
  defaultForecastingParameters: null,
})

export default AccountSchemeTemplateUpdateRowRecord
