import { useEffect, useState } from 'react'
import axios from 'axios'

import meta from 'meta.json'

const INTERVAL_IN_SECONDS = 15
const CACHE_INTERVAL = INTERVAL_IN_SECONDS * 1000

export default function useUpdateAvailable() {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(`${process.env.PUBLIC_URL}/meta.json`, {
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        })
        .then((result) => {
          const buildTime = result?.data?.buildTime
          if (buildTime && buildTime !== meta.buildTime) {
            setUpdateAvailable(true)
          }
        })
        .catch(function(error) {
          // handle error
          console.error('Error loading meta.json', error)
        })
    }, CACHE_INTERVAL)
    return () => clearInterval(interval)
  }, [])
  return updateAvailable
}
