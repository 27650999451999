import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'react-bootstrap'

import { OkButton } from 'components/Button'
import ModalHeader from '../ModalHeader'
import ModalFooter from '../ModalFooter'

const AlertModal = ({ show, onDismiss, title, body }) => (
  <div className="static-modal">
    <Modal backdrop="static" show={show} onHide={onDismiss}>
      {title && <ModalHeader title={title} />}
      <Modal.Body>{body}</Modal.Body>
      <ModalFooter>
        <OkButton onClick={onDismiss} />
      </ModalFooter>
    </Modal>
  </div>
)

AlertModal.propTypes = {
  show: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

AlertModal.defaultProps = {
  show: false,
}

export default AlertModal
