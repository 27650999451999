import { List } from 'immutable'
import messages from './messages'

export const flattenChildren = (dimensionValue) => {
  const flattenedChildren = dimensionValue.children.flatMap(flattenChildren)

  return List([dimensionValue]).concat(flattenedChildren)
}

export const flattenDimensionValues = (dimension) =>
  dimension.values.flatMap(flattenChildren)

export const getVisibilityMessage = (val) => {
  if (val.hideFromBudgeting && val.hideFromReporting) return messages.hideBoth
  if (val.hideFromBudgeting) return messages.hideBudget
  if (val.hideFromReporting) return messages.hideReporting
  return messages.hideNone
}
