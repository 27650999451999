/*
 *
 * BudgetTableTree reducer
 *
 */

import { fromJS, Set, Map } from 'immutable'

import { GET_BUDGET_BALANCE_FOR_ROW } from 'containers/BudgetMonitor/constants'

import { flattenChildren } from 'utils/schemeGroupUtils'

import {
  COLLAPSE_ALL,
  COLLAPSE_ROW,
  EXPAND_ALL,
  TARGET_MONITOR,
} from './constants'

const findChildless = (tree) =>
  flattenChildren(tree).filter((t) => !t.children.isEmpty())

// expandedGroups is a data structure of a following kind:
//
// expandedGroups: {
//   budget: {
//     "1": [ "grp1", "grp2", ... ],
//     "2": [ "grp324", ... ],
//   },
//   monitor: {
//     "1": ["grp1"],
//   },
//   sub_budget: {
//     ...
//   }
// }
//
// where the leaf values are the groups that are expanded, e.g. "grp1" and "grp2".
// `budgetId` is used as the key for the inner Maps.
//
const initialState = fromJS({
  expandedGroups: {
    budget: Map(),
    monitor: Map(),
    sub_budget: Map(),
  },
  allGroupsAreCollapsed: false,
  allGroupsAreExpanded: false,
})

const budgetTableTreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLAPSE_ROW: {
      const newState = state.updateIn(
        ['expandedGroups', action.target, action.budgetId],
        (expandedGroups) => {
          if (!expandedGroups) {
            return Set([action.rowId])
          }

          return action.collapse
            ? expandedGroups.delete(action.rowId)
            : expandedGroups.add(action.rowId)
        }
      )
      const expandedGroups = newState.getIn([
        'expandedGroups',
        action.target,
        action.budgetId,
      ])

      return newState
        .set(
          'allGroupsAreCollapsed',
          !action.tree.children.find((c) => expandedGroups.includes(c.rowId))
        )
        .set(
          'allGroupsAreExpanded',
          action.collapse
            ? false
            : findChildless(action.tree).every((childless) =>
                expandedGroups.includes(childless.rowId)
              )
        )
    }

    case COLLAPSE_ALL:
      return state
        .set('allGroupsAreCollapsed', true)
        .set('allGroupsAreExpanded', false)
        .updateIn(
          ['expandedGroups', action.target, action.budgetId],
          (expandedGroups) => {
            const rowIds = action.tree.children
              .flatMap(flattenChildren)
              .map((tree) => tree.rowId)

            return expandedGroups.filter((rowId) => !rowIds.includes(rowId))
          }
        )

    case EXPAND_ALL:
      return state
        .set('allGroupsAreExpanded', true)
        .set('allGroupsAreCollapsed', false)
        .updateIn(['expandedGroups', action.target, action.budgetId], () =>
          Set(flattenChildren(action.tree).map((tree) => tree.rowId))
        )

    // Special case for budget monitor.
    // When drilling to a row, set that row expanded.
    case GET_BUDGET_BALANCE_FOR_ROW:
      return state.updateIn(
        ['expandedGroups', TARGET_MONITOR, action.budgetId],
        (expandedGroups) => expandedGroups.add(action.rowId)
      )

    default:
      return state
  }
}

export default budgetTableTreeReducer
