/*
 *
 * Vouchers reducer
 *
 */
import { fromJS, List } from 'immutable'

import { VoucherRecord } from 'records'

import {
  COPY_VOUCHER,
  COPY_VOUCHER_ERROR,
  COPY_VOUCHER_SUCCESS,
  CREATE_VOUCHER,
  CREATE_VOUCHER_ERROR,
  CREATE_VOUCHER_SUCCESS,
  DELETE_VOUCHER,
  DELETE_VOUCHER_ERROR,
  DELETE_VOUCHER_SUCCESS,
  GET_VOUCHER_LIST,
  GET_VOUCHER_LIST_ERROR,
  GET_VOUCHER_LIST_SUCCESS,
  TOGGLE_SHOW_VOUCHER_FORM,
  GET_EXPORT_DATA,
  GET_EXPORT_DATA_ERROR,
  GET_EXPORT_DATA_SUCCESS,
} from './constants'
import { SELECT_COMPANY } from '../CompanyIndex/constants'
import {
  DELETE_ACQUISITION,
  DELETE_ACQUISITION_SUCCESS,
  CREATE_FAS_ACQUISITION_VOUCHERS,
} from 'containers/FasFormSelection/constants'

const initialState = fromJS({
  error: false,
  loading: false,
  vouchers: new List(),
  showVoucherForm: false,
  showConsolidatedForm: false,
})

const vouchersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_VOUCHER:
      return state
        .set('loading', true)
        .set('error', false)
        .set('showVoucherForm', !state.get('showVoucherForm'))

    case CREATE_FAS_ACQUISITION_VOUCHERS:
    case GET_EXPORT_DATA_ERROR:
    case DELETE_ACQUISITION:
      return state.set('loading', true).set('error', false)

    case DELETE_ACQUISITION_SUCCESS:
      return state.set('loading', false).set('error', false)

    case COPY_VOUCHER_ERROR:
    case CREATE_VOUCHER_ERROR:
    case DELETE_VOUCHER_ERROR:
    case GET_VOUCHER_LIST_ERROR: {
      const error = action.error.error ? action.error.error : action.error
      return state.set('loading', false).set('error', error)
    }

    case COPY_VOUCHER_SUCCESS: {
      const list = state.get('vouchers')
      const newVoucher = new VoucherRecord(action.voucher)
      const sourceId = action.sourceId
      const index = list.findIndex((i) => i.id === sourceId) + 1
      const newList = list.insert(index, newVoucher)
      return state
        .set('loading', false)
        .set('error', false)
        .set('vouchers', newList)
    }
    case CREATE_VOUCHER_SUCCESS:
      return state.set('loading', false).set('error', false)

    case COPY_VOUCHER:
    case DELETE_VOUCHER:
      return state.set('loading', true).set('error', false)

    case DELETE_VOUCHER_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'vouchers',
          state.get('vouchers').filter((voucher) => voucher.id !== action.id)
        )

    case GET_EXPORT_DATA:
    case GET_VOUCHER_LIST:
      return state.set('loading', true).set('error', false)

    case GET_VOUCHER_LIST_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set(
          'vouchers',
          List(action.vouchers.map((voucher) => new VoucherRecord(voucher)))
        )
    case SELECT_COMPANY: {
      return state
        .set('loading', false)
        .set('error', false)
        .set('vouchers', new List())
    }
    case GET_EXPORT_DATA_SUCCESS:
      return state.set('loading', false)

    case TOGGLE_SHOW_VOUCHER_FORM:
      return state.set('showVoucherForm', !state.get('showVoucherForm'))

    default:
      return state
  }
}

export default vouchersReducer
