/*
 *
 * SubBudgetRowModal constants
 *
 */

export const CLOSE_MODAL = 'app/SubBudgetRowModal/CLOSE_MODAL'

export const DUPLICATE_SUB_BUDGET_ROW =
  'app/SubBudgetRowModal/DUPLICATE_SUB_BUDGET_ROW'
export const COPY_SUB_BUDGET_ROW = 'app/SubBudgetRowModal/COPY_SUB_BUDGET_ROW'
export const COPY_SUB_BUDGET_ROW_ERROR =
  'app/SubBudgetRowModal/COPY_SUB_BUDGET_ROW_ERROR'
export const COPY_SUB_BUDGET_ROW_SUCCESS =
  'app/SubBudgetRowModal/COPY_SUB_BUDGET_ROW_SUCCESS'

export const CREATE_SUB_BUDGET_ROW =
  'app/SubBudgetRowModal/CREATE_SUB_BUDGET_ROW'
export const CREATE_SUB_BUDGET_ROW_ERROR =
  'app/SubBudgetRowModal/CREATE_SUB_BUDGET_ROW_ERROR'
export const CREATE_SUB_BUDGET_ROW_SUCCESS =
  'app/SubBudgetRowModal/CREATE_SUB_BUDGET_ROW_SUCCESS'

export const UPDATE_SUB_BUDGET_ROW =
  'app/SubBudgetRowModal/UPDATE_SUB_BUDGET_ROW'
export const UPDATE_SUB_BUDGET_ROW_ERROR =
  'app/SubBudgetRowModal/UPDATE_SUB_BUDGET_ROW_ERROR'
export const UPDATE_SUB_BUDGET_ROW_SUCCESS =
  'app/SubBudgetRowModal/UPDATE_SUB_BUDGET_ROW_SUCCESS'

export const ROW_INPUT_TYPE_BALANCE = 'Balance'
export const ROW_INPUT_TYPE_QUANTITY = 'Quantity'
export const INPUT_TYPES = [ROW_INPUT_TYPE_BALANCE, ROW_INPUT_TYPE_QUANTITY]

export const ROW_TYPE_FORMULA = 'Formula'
export const ROW_TYPE_INPUT_PERCENTAGE = 'InputPercentage'
export const ROW_TYPE_HEADER = 'Header'
export const ROW_TYPE_INPUT = 'Input'
export const ROW_TYPE_ACTUAL = 'Actual'
export const ROW_TYPE_OPERATIVE = 'Operative'
export const ROW_TYPE_MAIN_BUDGET = 'BudgetImport'
export const ROW_TYPE_SUB_BUDGET_ROW = 'SubBudgetImport'

export const ROW_TYPES = [
  ROW_TYPE_INPUT,
  ROW_TYPE_HEADER,
  ROW_TYPE_FORMULA,
  ROW_TYPE_INPUT_PERCENTAGE,
  ROW_TYPE_ACTUAL,
  ROW_TYPE_OPERATIVE,
  ROW_TYPE_MAIN_BUDGET,
  ROW_TYPE_SUB_BUDGET_ROW,
]
