import { Record, List } from 'immutable'
import DatasourceUpdateRecord from './datasource-update'

class ReportSelectionUpdateRecord extends Record({
  currencyCode: '',
  dateFormat: '',
  decimals: undefined,
  decimalSeparatorType: undefined,
  id: 0,
  name: '',
  // ownerId: undefined,
  precisionType: undefined,
  reportDataSources: List(), // this.reportDataSources.map((ds) => ds.name), // <-- WIP
  reportSchemeId: undefined,
  // reportSelectionUsers: undefined,
  showPercentageColumn: undefined,
  showSubTotals: undefined,
  showTotalColumn: undefined,
  showInRows: undefined,
  showInColumns: undefined,
  thousandSeparatorType: undefined,
  useOverlappingMonths: undefined,
  reportSelectionTagIds: List(),
  includeLastSchemeLevelAsDataRow: false,
  visualizationOverride: undefined,
  // customerId: undefined
  // userId: undefined,
}) {
  constructor(values) {
    const updateRecord = super(values)
    return updateRecord.set(
      'reportDataSources',
      updateRecord.reportDataSources?.map(
        (ds) => new DatasourceUpdateRecord(ds)
      )
    )
  }
}

export default ReportSelectionUpdateRecord
