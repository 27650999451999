/*
 *
 * Accounting period root sagas
 *
 */
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import api from 'api/AccountingPeriodApi'
import { handleError } from 'api/api-utils'
import { Record } from 'immutable'
import diff from 'immutablediff'

import {
  SET_ACTIVE_ACCOUNTING_PERIOD,
  CREATE_ACCOUNTING_PERIOD,
  GET_ACCOUNTING_PERIODS,
  DELETE_ACCOUNTING_PERIOD,
  UPDATE_ACCOUNTING_PERIOD,
} from './constants'

import {
  setActiveAccountingPeriodSuccess,
  setActiveAccountingPeriodError,
  createAccountingPeriodSuccess,
  createAccountingPeriodError,
  getAccountingPeriodsSuccess,
  getAccountingPeriodsError,
  deleteAccountingPeriodError,
  deleteAccountingPeriodSuccess,
  updateAccountingPeriodError,
  updateAccountingPeriodSuccess,
} from './actions'

const UpdateAccountingPeriodRecord = Record({
  name: undefined,
  start: undefined,
  end: undefined,
})

export function* createAccountingPeriod({ end, companyCode, name, start }) {
  try {
    const accountingPeriod = yield call(api.createAccountingPeriod, {
      end,
      companyCode,
      name,
      start,
    })
    yield put(createAccountingPeriodSuccess({ accountingPeriod, companyCode }))
  } catch (err) {
    yield put(handleError(err, createAccountingPeriodError))
  }
}

export function* deleteAccountingPeriod({ companyCode, id }) {
  try {
    yield call(api.deleteAccountingPeriod, { companyCode, id })
    yield put(deleteAccountingPeriodSuccess({ companyCode, id }))
  } catch (err) {
    yield put(handleError(err, deleteAccountingPeriodError))
  }
}

export function* getAccountingPeriods({ companyCode }) {
  try {
    const accountingPeriods = yield call(api.getAccountingPeriods, {
      companyCode,
    })
    yield put(getAccountingPeriodsSuccess({ accountingPeriods, companyCode }))
  } catch (err) {
    yield put(handleError(err, getAccountingPeriodsError))
  }
}

export function* setActiveAccountingPeriod({ companyCode, id }) {
  try {
    yield call(api.setActiveAccountingPeriod, { companyCode, id })
    yield put(setActiveAccountingPeriodSuccess({ companyCode, id }))
  } catch (err) {
    yield put(handleError(err, setActiveAccountingPeriodError))
  }
}

export function* updateAccountingPeriod({
  accountingPeriod,
  updatedValues,
  companyCode,
}) {
  const patch = diff(
    new UpdateAccountingPeriodRecord(accountingPeriod),
    new UpdateAccountingPeriodRecord(updatedValues)
  ).toJS()

  try {
    const updatedAccountingPeriod = yield call(api.patchAccountingPeriod, {
      companyCode,
      id: accountingPeriod.id,
      patch,
    })
    yield put(
      updateAccountingPeriodSuccess({
        accountingPeriod: updatedAccountingPeriod,
        companyCode,
      })
    )
  } catch (err) {
    yield put(handleError(err, updateAccountingPeriodError))
  }
}

// All sagas to be loaded
export function* accountingPeriodsSaga() {
  yield all([
    takeEvery(CREATE_ACCOUNTING_PERIOD, createAccountingPeriod),
    takeEvery(DELETE_ACCOUNTING_PERIOD, deleteAccountingPeriod),
    takeLatest(GET_ACCOUNTING_PERIODS, getAccountingPeriods),
    takeLatest(SET_ACTIVE_ACCOUNTING_PERIOD, setActiveAccountingPeriod),
    takeEvery(UPDATE_ACCOUNTING_PERIOD, updateAccountingPeriod),
  ])
}

export default accountingPeriodsSaga
