/*
 *
 * Budgets reducer
 *
 */
import { fromJS, List, Map } from 'immutable'

import {
  CREATE_BUDGET,
  CREATE_BUDGET_SUCCESS,
  CREATE_BUDGET_ERROR,
  CREATE_BUDGET_FOLDER,
  CREATE_BUDGET_FOLDER_ERROR,
  CREATE_BUDGET_FOLDER_SUCCESS,
  DELETE_BUDGET,
  DELETE_BUDGET_ERROR,
  DELETE_BUDGET_SUCCESS,
  DELETE_BUDGET_FOLDER,
  DELETE_BUDGET_FOLDER_ERROR,
  DELETE_BUDGET_FOLDER_SUCCESS,
  EDIT_BUDGET,
  EDIT_BUDGET_FOLDER,
  EDIT_BUDGET_FOLDER_ERROR,
  EDIT_BUDGET_FOLDER_SUCCESS,
  COPY_BUDGET,
  GET_BUDGETS,
  GET_BUDGETS_ERROR,
  GET_BUDGETS_SUCCESS,
  GET_BUDGET_FOLDERS,
  GET_BUDGET_FOLDERS_ERROR,
  GET_BUDGET_FOLDERS_SUCCESS,
  SET_DEFAULT_BUDGET,
  SET_DEFAULT_BUDGET_ERROR,
  SET_DEFAULT_BUDGET_SUCCESS,
  UPDATE_BUDGET,
  UPDATE_BUDGET_ERROR,
  UPDATE_BUDGET_SUCCESS,
  CREATE_BUDGET_COPY,
  CREATE_BUDGET_COPY_ERROR,
  CREATE_BUDGET_COPY_SUCCESS,
} from './constants'

import { GET_BUDGET_TREE_SUCCESS, GET_BUDGET_TREE } from '../Budget/constants'
import { BudgetRecord, FolderRecord } from 'records'
import { mapBudgetTree } from '../Budget/functions'

const getIndexToStoreBudgetIn = (companyBudgets, budgetId) => {
  if (!companyBudgets || !companyBudgets.findIndex) {
    return 0
  }
  const index = companyBudgets.findIndex(
    (budgetItem) => budgetItem.id === budgetId
  )
  if (index === -1) {
    return 0
  }
  return index
}

const initialState = fromJS({
  budgets: new Map(),
  error: false,
  loading: false,
  folders: new Map(),
})

function budgetsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUDGET_FOLDERS:
    case CREATE_BUDGET:
      return state.set('loading', true)

    case CREATE_BUDGET_FOLDER_SUCCESS:
      return state
        .set('loading', false)
        .updateIn(['folders', action.companyCode], (folders) => {
          const newFolder = new FolderRecord(action.budgetFolder)
          if (!folders) {
            return new List([newFolder])
          }
          return new List([...folders].concat([newFolder]))
        })

    case CREATE_BUDGET_SUCCESS:
      return state
        .set('loading', false)
        .updateIn(['budgets', action.companyCode], (budgets) => {
          const newBudget = new BudgetRecord(action.budget)
          if (!budgets) {
            return new List([newBudget])
          }
          return new List([...budgets].concat([newBudget]))
        })
    case EDIT_BUDGET:
      return state
        .set('editBudgetId', action.id)
        .set('copyBudgetId', undefined)
        .set('deleteBudgetId', undefined)

    case COPY_BUDGET:
      return state
        .set('copyBudgetId', action.id)
        .set('deleteBudgetId', undefined)
        .set('editBudgetId', undefined)

    case CREATE_BUDGET_COPY:
      return state.set('loading', true)

    case CREATE_BUDGET_COPY_SUCCESS:
      return state
        .set('copyBudgetId', undefined)
        .set('loading', false)
        .updateIn(['budgets', action.companyCode], (budgets) => {
          const newBudget = new BudgetRecord(action.budget)
          if (!budgets) {
            return new List([newBudget])
          }
          return new List([...budgets].concat([newBudget]))
        })

    case CREATE_BUDGET_COPY_ERROR:
      return state.set('loading', false).set('error', action.error)

    case EDIT_BUDGET_FOLDER:
    case CREATE_BUDGET_FOLDER:
    case DELETE_BUDGET_FOLDER:
    case SET_DEFAULT_BUDGET:
    case UPDATE_BUDGET:
    case DELETE_BUDGET:
    case GET_BUDGETS:
      return state.set('loading', true).set('error', false)

    case SET_DEFAULT_BUDGET_ERROR:
    case EDIT_BUDGET_FOLDER_ERROR:
    case CREATE_BUDGET_FOLDER_ERROR:
    case UPDATE_BUDGET_ERROR:
    case CREATE_BUDGET_ERROR:
    case DELETE_BUDGET_FOLDER_ERROR:
    case DELETE_BUDGET_ERROR:
    case GET_BUDGETS_ERROR:
    case GET_BUDGET_FOLDERS_ERROR:
      return state.set('loading', false).set('error', action.error)

    case EDIT_BUDGET_FOLDER_SUCCESS: {
      const newCompanyFolders = state.getIn(['folders', action.companyCode])
      const newFolderMap = newCompanyFolders.map((folder) =>
        folder.id !== action.folder.id
          ? folder
          : new FolderRecord(action.folder)
      )
      return state
        .set('loading', false)
        .setIn(['folders', action.companyCode], newFolderMap)
    }

    case DELETE_BUDGET_FOLDER_SUCCESS: {
      const newCompanyFolders = state.getIn(['folders', action.companyCode])
      const newFolderMap = newCompanyFolders.filter(
        (folder) => folder.id !== action.id
      )
      const newCompanyBudgets = state.getIn(['budgets', action.companyCode])
      const newBudgetMap = newCompanyBudgets.map((budget) =>
        budget.folderId !== action.id ? budget : budget.set('folderId', 0)
      )
      return state
        .set('loading', false)
        .setIn(['folders', action.companyCode], newFolderMap)
        .setIn(['budgets', action.companyCode], newBudgetMap)
    }

    case DELETE_BUDGET_SUCCESS: {
      const newCompanyBudgets = state.getIn(['budgets', action.companyCode])
      const newBudgetMap = newCompanyBudgets.filter(
        (budget) => budget.id !== action.id
      )
      return state
        .set('loading', false)
        .setIn(['budgets', action.companyCode], newBudgetMap)
    }
    case GET_BUDGETS_SUCCESS:
      return state
        .set('loading', false)
        .setIn(
          ['budgets', action.companyCode],
          List(action.budgets.map((budget) => new BudgetRecord(budget)))
        )

    case GET_BUDGET_TREE:
      return state.set('loadingTree', true)
    case GET_BUDGET_TREE_SUCCESS: {
      const companyBudgets = state.getIn(['budgets', action.companyCode])

      const index = getIndexToStoreBudgetIn(companyBudgets, action.budgetId)

      const query = {
        companyCode: action.companyCode,
        budgetId: action.budgetId,
        dv: [],
        view: 'ProfitAndLoss',
      }
      return state
        .set('loadingTree', false)
        .setIn(
          ['budgets', action.companyCode, index, 'tree'],
          mapBudgetTree(action.tree.schemeGroup, query)
        )
    }
    case SET_DEFAULT_BUDGET_SUCCESS: {
      const list = state.getIn(['budgets', action.companyCode])
      const currentIndex = list.findIndex((budget) => budget.isDefault)
      const newIndex = list.findIndex((budget) => budget.id === action.id)
      return state
        .set('loading', false)
        .setIn(
          ['budgets', action.companyCode],
          list
            .updateIn([currentIndex, 'isDefault'], () => false)
            .updateIn([newIndex, 'isDefault'], () => true)
        )
    }

    case GET_BUDGET_FOLDERS_SUCCESS:
      return state
        .set('loading', false)
        .setIn(
          ['folders', action.companyCode],
          List(action.folders.map((folder) => new FolderRecord(folder)))
        )

    case UPDATE_BUDGET_SUCCESS: {
      const budgets = state.getIn(['budgets', action.companyCode])
      const budgetIndex = budgets.findIndex(
        (budget) => budget.id === action.budget.id
      )
      const newBudget = new BudgetRecord(action.budget)
      const newBudgets = budgets.set(budgetIndex, newBudget)
      return state
        .set('editBudgetId', undefined)
        .set('loading', false)
        .setIn(['budgets', action.companyCode], newBudgets)
    }

    default:
      return state
  }
}

export default budgetsReducer
