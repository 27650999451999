import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Dropdown, NavDropdown } from 'react-bootstrap'
import styles from './styles.module.scss'

import { UserInfoNaviItem } from './UserInfoNaviItem.js'
import useAD from 'utils/featureFlagReaders/useAD'

const UserDropdown = (props) => {
  const {
    settingsText,
    logoutText,
    onLogout,
    username,
    header,
    onSettings,
    subUsers,
  } = props
  const [open, setOpen] = useState(false)

  return (
    <NavDropdown
      title={
        <>
          <span className={styles.header}>{header}</span>
          {username}
        </>
      }
      open={open}
      onToggle={(state) => setOpen(state)}
      id="nav-user-dropdown"
      data-testing-id="nav-user-dropdown"
      data-hj-suppress
      className={open ? styles.activeLogin : styles.deactiveLogin}
    >
      {useAD && (
        <UserInfoNaviItem
          settingsAction={onSettings}
          subUsers={subUsers}
          logoutAction={onLogout}
        />
      )}

      {!useAD && (
        <>
          <Dropdown.Item
            onClick={onSettings}
            data-testing-id="menu-item-settings"
          >
            {settingsText}
          </Dropdown.Item>
          <Dropdown.Item onClick={onLogout} data-testing-id="menu-item-logout">
            {logoutText}
          </Dropdown.Item>
        </>
      )}
    </NavDropdown>
  )
}

UserDropdown.propTypes = {
  logoutText: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subUsers: useAD ? PropTypes.object.isRequired : PropTypes.object,
}

export default UserDropdown
