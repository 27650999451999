/*
 *
 * Role reducer
 *
 */
import { fromJS, List, Map } from 'immutable'
import ClaimRecord from 'records/claim'
import {
  CREATE_ROLE_CLAIM,
  CREATE_ROLE_CLAIM_ERROR,
  CREATE_ROLE_CLAIM_SUCCESS,
  GET_ROLE_CLAIMS,
  GET_ROLE_CLAIMS_ERROR,
  GET_ROLE_CLAIMS_SUCCESS,
  DELETE_ROLE_CLAIM_SUCCESS,
} from './constants'

const initialState = fromJS({
  loading: false,
  error: false,
  claims: new Map(),
  showCreateRoleClaimForm: false,
  showPermissionSelectionForm: false,
})

const mapClaim = (props) => {
  const { type, value } = props
  return new ClaimRecord({
    type,
    value,
  })
}

function roleReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ROLE_CLAIM:
    case GET_ROLE_CLAIMS:
      return state.set('loading', true).set('error', false)
    case CREATE_ROLE_CLAIM_SUCCESS:
    case DELETE_ROLE_CLAIM_SUCCESS:
    case GET_ROLE_CLAIMS_SUCCESS:
      const { roleId, claims } = action
      return state
        .set('loading', false)
        .set('error', false)
        .setIn(['claims', roleId], List(claims.map(mapClaim)))
        .set('showCreateRoleClaimForm', false)
    case GET_ROLE_CLAIMS_ERROR:
    case CREATE_ROLE_CLAIM_ERROR:
      return state.set('loading', false).set('error', action.error)
    default:
      return state
  }
}

export default roleReducer
