const getValue = (obj, propertyNames) => {
  for (const property of propertyNames) {
    if (obj.get && obj.get(property)) return obj.get(property)
    if (obj[property]) return obj[property]
  }
  return ''
}
export const alphabeticallyAscendingComparer = (
  a,
  b,
  propertyNames = ['nameToDisplay', 'displayName', 'name']
) => {
  if (a && b) {
    const aValue = getValue(a, propertyNames)
    const bValue = getValue(b, propertyNames)
    if (aValue && bValue) {
      return aValue.toLowerCase().localeCompare(bValue.toLowerCase())
    }
  }
  return -1
}
