import { List, Record } from 'immutable'

const DimensionValueRecord = Record({
  id: undefined,
  code: undefined,
  displayName: undefined,
  name: undefined,
  externalId: undefined,
  children: List(),
  hideFromReporting: undefined,
  hideFromBudgeting: undefined,
  type: 'dimensionValue',
  // dimensionId refers to the root Dimension of this DimensionValue
  dimensionId: undefined,
  // dimensionName is the name of the dimension, that `dimensionId` refers to
  dimensionName: undefined,
  // id of the parent DimensionValue
  parentId: undefined,
  // Name of the root dimension in the hierarchy.
  //
  // E.g.
  // Site
  //   - TRE (rootName: Site, dimensionName: Site)
  //   - JKL (rootName: Site, dimensionName: Site)
  //     - Microsoft (JKL) (rootName: Site, dimensionName: Group)
  // Group
  //   - Microsoft (JKL) (rootName: Group, dimensionName: Group)
  rootName: undefined,
  // The depth of this DimensionValue.
  // Children of this dimensionValue are in level + 1.
  level: 1,
  // Combination of code, name and displayname depending on dimension settings and what of the previous values is defined
  nameToDisplay: undefined,
  dimensionValueId: undefined,
  reportinGroup: undefined,
})

export default DimensionValueRecord
