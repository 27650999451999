/*
 *
 * CompanyCreationWizard reducer
 *
 */

import { fromJS, List } from 'immutable'
import {
  CLOSE_MODAL,
  COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME,
  COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_ERROR,
  COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_SUCCESS,
  COMPANY_CREATION_WIZARD_CREATE_BUDGET,
  COMPANY_CREATION_WIZARD_CREATE_BUDGET_ERROR,
  COMPANY_CREATION_WIZARD_CREATE_BUDGET_SUCCESS,
  COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR,
  COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS,
  GET_ACCOUNT_LIST_CHOICES,
  GET_ACCOUNT_LIST_CHOICES_SUCCESS,
  GET_ACCOUNT_LIST_FILE,
  GET_ACCOUNT_LIST_FILE_SUCCESS,
  IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS,
  IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
  SET_SPECIAL_ACCOUNTS,
  SET_SPECIAL_ACCOUNTS_ERROR,
  SET_SPECIAL_ACCOUNTS_SUCCESS,
  START_CREATE_BUDGET,
} from './constants'
import { CREATE_COMPANY, CREATE_COMPANY_SUCCESS } from '../Companies/constants'

import {
  CREATE_ACCOUNTING_PERIOD,
  CREATE_ACCOUNTING_PERIOD_SUCCESS,
} from '../AccountingPeriods/constants'
import {
  GET_ACCOUNT_SCHEME_TEMPLATES,
  GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS,
} from '../AccountSchemeTemplates/constants'

const initialState = fromJS({
  createdCompanyCode: '',
  error: false,
  loading: false,
  inCompleteActions: new List(),
  showCreateAccountSchemeForm: false,
  showSelectAccountList: false,
  showBudgetForm: false,
  selectedAccountList: undefined,
  selectedAccountSchemeTemplate: undefined,
  accountListChoices: new List(),
})
const addValueToIncompleteActions = (state, value) =>
  state.set('inCompleteActions', state.get('inCompleteActions').push(value))
const removeValueFromIncompleteActions = (state, value) =>
  state.set(
    'inCompleteActions',
    removeValueFromList(state.get('inCompleteActions'), value)
  )
const removeValueFromList = (list, value) => {
  const index = list.indexOf(value)
  if (index >= 0) {
    return new List(list.delete(index))
  }
  return list
}
function companyCreationWizardReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_MODAL:
      return initialState
    case COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT:
    case CREATE_ACCOUNTING_PERIOD:
    case GET_ACCOUNT_LIST_CHOICES:
    case GET_ACCOUNT_SCHEME_TEMPLATES:
    case IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS:
    case PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS:
    case SET_SPECIAL_ACCOUNTS:
      return addValueToIncompleteActions(state, action.type)
    case COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME:
      return addValueToIncompleteActions(state, action.type).set(
        'showCreateAccountSchemeForm',
        false
      )
    case COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_ERROR:
      return removeValueFromIncompleteActions(
        state,
        COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME
      ).set('error', action.error)
    case COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_SUCCESS:
      return removeValueFromIncompleteActions(
        state,
        COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME
      )
    case COMPANY_CREATION_WIZARD_CREATE_BUDGET:
      return addValueToIncompleteActions(state, action.type).set(
        'showBudgetForm',
        false
      )
    case COMPANY_CREATION_WIZARD_CREATE_BUDGET_ERROR:
      return removeValueFromIncompleteActions(
        state,
        COMPANY_CREATION_WIZARD_CREATE_BUDGET
      ).set('error', action.error)
    case COMPANY_CREATION_WIZARD_CREATE_BUDGET_SUCCESS:
      return removeValueFromIncompleteActions(
        state,
        COMPANY_CREATION_WIZARD_CREATE_BUDGET
      )
    case COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR:
      return removeValueFromIncompleteActions(
        state,
        COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT
      ).set('error', action.error)
    case COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS:
      return removeValueFromIncompleteActions(
        state,
        COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT
      )
    case CREATE_COMPANY:
      return state
        .set('inCompleteActions', new List([CREATE_COMPANY]))
        .set('error', false)
        .set('show', true)
        .set('loading', true)
    case CREATE_COMPANY_SUCCESS: {
      return removeValueFromIncompleteActions(state, CREATE_COMPANY)
        .set('loading', false)
        .set('createdCompanyCode', action.company.code)
    }
    case CREATE_ACCOUNTING_PERIOD_SUCCESS: {
      return removeValueFromIncompleteActions(state, CREATE_ACCOUNTING_PERIOD)
    }
    case GET_ACCOUNT_LIST_CHOICES_SUCCESS: {
      return removeValueFromIncompleteActions(state, GET_ACCOUNT_LIST_CHOICES)
        .set('showSelectAccountList', true)
        .set('accountListChoices', new List(action.accountListChoices))
    }
    case GET_ACCOUNT_LIST_FILE: {
      return addValueToIncompleteActions(state, action.type)
        .set('showSelectAccountList', false)
        .set('selectedAccountList', action.choice)
    }
    case GET_ACCOUNT_LIST_FILE_SUCCESS:
      return removeValueFromIncompleteActions(state, GET_ACCOUNT_LIST_FILE)
    case GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS:
      return removeValueFromIncompleteActions(
        state,
        GET_ACCOUNT_SCHEME_TEMPLATES
      )
    case IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS:
      return removeValueFromIncompleteActions(
        state,
        IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS
      ).set('showCreateAccountSchemeForm', true)
    case PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR:
      return state.set('loading', false).set('error', action.error)
    case PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS:
      return removeValueFromIncompleteActions(
        state,
        PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS
      )
    case SET_SPECIAL_ACCOUNTS_ERROR:
      return removeValueFromIncompleteActions(state, SET_SPECIAL_ACCOUNTS).set(
        'error',
        action.error
      )
    case SET_SPECIAL_ACCOUNTS_SUCCESS:
      return removeValueFromIncompleteActions(state, SET_SPECIAL_ACCOUNTS)
    case START_CREATE_BUDGET:
      return state.set('showBudgetForm', true)
    default:
      return state
  }
}

export default companyCreationWizardReducer
