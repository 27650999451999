import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { checkLogin } from './actions'
import { selectUserIsLoggedIn } from './selectors'

import Login from 'components/Login'
import LoggedInUser from 'components/LoggedInUser'

import moment from 'moment'
import 'moment/locale/fi'
import numberLocalizer from 'react-widgets-simple-number'
import useUpdateAvailable from 'utils/hooks/useUpdateAvailable'
import { useNavigate } from 'react-router'

import useLocationUpdater from 'utils/hooks/useLocationUpdater'

moment.locale('fi')
numberLocalizer()

const App = (props) => {
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userIsLoggedIn) {
      dispatch(checkLogin({ navigate }))
    }
  }, [dispatch, navigate, userIsLoggedIn])

  const updateAvailable = useUpdateAvailable()
  useLocationUpdater(updateAvailable)

  if (userIsLoggedIn) return <LoggedInUser {...props} />
  return <Login {...props} />
}

export default App
