import { fromJS, List, Map } from 'immutable'

import {
  EXECUTE_INTEGRATION,
  EXECUTE_INTEGRATION_SUCCESS,
  EXECUTE_INTEGRATION_ERROR,
  GET_INTEGRATION_EVENTS_SUCCESS,
  GET_INTEGRATION_EVENTS_ERROR,
  GET_INTEGRATION_CONNECTIONS_SUCCESS,
  DELETE_INTEGRATION_CONNECTION,
  DELETE_INTEGRATION_CONNECTION_SUCCESS,
  PUT_INTEGRATION_CONNECTION,
  PUT_INTEGRATION_CONNECTION_SUCCESS,
  GET_INTEGRATION_CONNECTIONS_ERROR,
  PUT_INTEGRATION_CONNECTION_ERROR,
  GET_INTEGRATION_CONNECTIONS,
  GET_INTEGRATION_EVENTS,
  CREATE_INTEGRATION_CONNECTION,
  CREATE_INTEGRATION_CONNECTION_ERROR,
} from './constants'

import IntegrationEventRecord from 'records/integrationEvent'

const initialState = fromJS({
  error: false,
  integrationEventList: List(),
  integrationEventsLoading: false,
  integrationEventsError: false,
  integrationConnectionsLoading: false,
  integrationExecutionError: undefined,
  integrationExecutionLoading: undefined,
  integrationConnections: Map(),
  //  {
  //   [integrationCode]: {
  //     loading: false,
  //     error: false,
  //     connection: {...},
  //   },
  // }
})

function integrationConnectionsReducer(state = initialState, action) {
  switch (action.type) {
    case PUT_INTEGRATION_CONNECTION:
      return state
        .setIn(
          ['integrationConnections', action.integrationCode, 'loading'],
          true
        )
        .setIn(
          ['integrationConnections', action.integrationCode, 'error'],
          false
        )
        .set('integrationConnectionsLoading', true)

    case PUT_INTEGRATION_CONNECTION_SUCCESS:
      return state
        .setIn(
          ['integrationConnections', action.integrationCode, 'loading'],
          false
        )
        .setIn(
          ['integrationConnections', action.integrationCode, 'connection'],
          action.connection
        )
        .set('integrationConnectionsLoading', false)
    case DELETE_INTEGRATION_CONNECTION:
    case CREATE_INTEGRATION_CONNECTION:
      return state.set('integrationConnectionsLoading', true)
    case CREATE_INTEGRATION_CONNECTION_ERROR:
      return state
        .setIn(
          ['integrationConnections', action.integrationCode, 'loading'],
          false
        )
        .setIn(
          ['integrationConnections', action.integrationCode, 'error'],
          action.error
        )
        .set('error', action.error)
    case PUT_INTEGRATION_CONNECTION_ERROR:
      return state
        .setIn(
          ['integrationConnections', action.integrationCode, 'loading'],
          false
        )
        .setIn(
          ['integrationConnections', action.integrationCode, 'error'],
          action.error
        )
        .set('error', action.error)
    case DELETE_INTEGRATION_CONNECTION_SUCCESS:
      return state
        .deleteIn(['integrationConnections', action.integrationCode])
        .set('integrationConnectionsLoading', false)
    case GET_INTEGRATION_CONNECTIONS:
      return state
        .delete('integrationConnections')
        .set('integrationConnectionsLoading', true)

    case GET_INTEGRATION_CONNECTIONS_SUCCESS:
      let newState = state
      action.connections?.forEach((connection) => {
        newState = newState
          .setIn(
            [
              'integrationConnections',
              connection?.integrationCode,
              'connection',
            ],
            connection
          )
          .setIn(
            ['integrationConnections', connection?.integrationCode, 'loading'],
            false
          )
          .setIn(
            ['integrationConnections', connection?.integrationCode, 'error'],
            false
          )
      })
      return newState.set('integrationConnectionsLoading', false)

    case GET_INTEGRATION_CONNECTIONS_ERROR:
    case EXECUTE_INTEGRATION_ERROR:
      return state
        .set('integrationConnectionsLoading', false)
        .set('integrationExecutionLoading', false)
        .set('integrationExecutionError', action.error)
    case EXECUTE_INTEGRATION:
      return state.set('integrationExecutionLoading', true)
    case EXECUTE_INTEGRATION_SUCCESS:
      return state.set('integrationExecutionLoading', false)

    case GET_INTEGRATION_EVENTS:
      return state.set('integrationEventsLoading', true)
    case GET_INTEGRATION_EVENTS_SUCCESS:
      return state.set('integrationEventsLoading', false).set(
        'integrationEventList',
        List(
          action.eventList
            .filter((e) => e.status !== 0) //events with status 0 are meaningless to client
            .map((event) => new IntegrationEventRecord(event))
        )
      )
    case GET_INTEGRATION_EVENTS_ERROR:
      return state
        .set('loading', false)
        .set('integrationEventsError', action.error)

    default:
      return state
  }
}

export default integrationConnectionsReducer
