import { all, call, put, takeEvery } from 'redux-saga/effects'
import integrationSourceSystemApi from 'api/IntegrationApi'
import {
  CREATE_INTEGRATION_SOURCE_SYSTEM,
  DELETE_INTEGRATION_SOURCE_SYSTEM,
  // DELETE_INTEGRATION_SOURCE_SYSTEM_SUCCESS,
  GET_INTEGRATION_SOURCE_SYSTEMS,
  UPDATE_INTEGRATION_SOURCE_SYSTEM,
} from './constants'
import {
  deleteIntegrationSourceSystemError,
  deleteIntegrationSourceSystemSuccess,
  getIntegrationSourceSystemsSuccess,
  getIntegrationSourceSystemsError,
} from './actions'
import { handleError } from 'api/api-utils'
import diff from 'immutablediff'
import { Record } from 'immutable'

export function* getIntegrationSourceSystems(action) {
  const { companyCode, customerCode } = action
  try {
    const sourceSystems = yield call(
      integrationSourceSystemApi.getIntegrationSourceSystems,
      { context: companyCode || customerCode }
    )
    yield put(getIntegrationSourceSystemsSuccess({ sourceSystems }))
  } catch (error) {
    yield put(handleError(error, getIntegrationSourceSystemsError))
  }
}

export function* createIntegrationSourceSystem(action) {
  const { sourceSystem } = action
  try {
    yield call(integrationSourceSystemApi.createIntegrationSourceSystem, {
      sourceSystem,
    })
    const sourceSystems = yield call(
      integrationSourceSystemApi.getIntegrationSourceSystems,
      {}
    )
    yield put(getIntegrationSourceSystemsSuccess({ sourceSystems }))
  } catch (error) {
    yield put(handleError(error, getIntegrationSourceSystemsError))
  }
}

export function* deleteIntegrationSourceSystem(action) {
  const { id } = action
  try {
    yield call(integrationSourceSystemApi.deleteIntegrationSourceSystem, { id })
    yield put(deleteIntegrationSourceSystemSuccess({ id }))
  } catch (error) {
    yield put(handleError(error, deleteIntegrationSourceSystemError))
  }
}

const UpdateSourceSystemRecord = Record({
  name: undefined,
  displayName: undefined,
  openingBalanceType: undefined,
  description: undefined,
  whitelistedCustomers: [],
  requiredSettings: [],
  materialTypes: [],
  implementationType: undefined,
  microserviceUrl: undefined,
})
export function* updateIntegrationSourceSystem(action) {
  const { oldSourceSystem, newSourceSystem } = action
  try {
    const patch = diff(
      new UpdateSourceSystemRecord(oldSourceSystem),
      new UpdateSourceSystemRecord(newSourceSystem)
    ).toJS()
    yield call(integrationSourceSystemApi.patchIntegrationSourceSystem, {
      id: oldSourceSystem.id,
      patch,
    })
    const sourceSystems = yield call(
      integrationSourceSystemApi.getIntegrationSourceSystems,
      {}
    )
    yield put(getIntegrationSourceSystemsSuccess({ sourceSystems }))
  } catch (error) {
    yield put(handleError(error, getIntegrationSourceSystemsError))
  }
}

export function* integrationSourceSystemsSaga() {
  yield all([
    takeEvery(GET_INTEGRATION_SOURCE_SYSTEMS, getIntegrationSourceSystems),
    takeEvery(CREATE_INTEGRATION_SOURCE_SYSTEM, createIntegrationSourceSystem),
    takeEvery(DELETE_INTEGRATION_SOURCE_SYSTEM, deleteIntegrationSourceSystem),
    takeEvery(UPDATE_INTEGRATION_SOURCE_SYSTEM, updateIntegrationSourceSystem),
  ])
}

// All sagas to be loaded
export default integrationSourceSystemsSaga
