import { createSelector } from 'reselect'

/**
 * Direct selector to the company state domain
 */
const selectCompany = () => (state) => state.get('company')

/**
 * Other specific selectors
 */

const selectcompanyCode = () =>
  createSelector(
    selectCompany(),
    (company) => company.get('companyCode')
  )

const selectError = createSelector(
  selectCompany(),
  (company) => company && company.get('error')
)

const selectLoading = createSelector(
  selectCompany(),
  (company) => company && company.get('loading')
)

/**
 * Default selector used by Company
 */

export default selectCompany
export { selectCompany, selectcompanyCode, selectError, selectLoading }
