/*
 *
 * BudgetRollingRules constants
 *
 */

export const ADD_ACCOUNT_RANGE = 'app/BudgetRollingRules/ADD_ACCOUNT_RANGE'
export const ADD_ACCOUNT_RANGE_ERROR =
  'app/BudgetRollingRules/ADD_ACCOUNT_RANGE_ERROR'
export const ADD_ACCOUNT_RANGE_SUCCESS =
  'app/BudgetRollingRules/ADD_ACCOUNT_RANGE_SUCCESS'

export const CREATE_BUDGET_ROLLING_RULE =
  'app/BudgetRollingRules/CREATE_BUDGET_ROLLING_RULE'
export const CREATE_BUDGET_ROLLING_RULE_ERROR =
  'app/BudgetRollingRules/CREATE_BUDGET_ROLLING_RULE_ERROR'
export const CREATE_BUDGET_ROLLING_RULE_SUCCESS =
  'app/BudgetRollingRules/CREATE_BUDGET_ROLLING_RULE_SUCCESS'

export const DELETE_BUDGET_ROLLING_RULE =
  'app/BudgetRollingRules/DELETE_BUDGET_ROLLING_RULE'
export const DELETE_BUDGET_ROLLING_RULE_ERROR =
  'app/BudgetRollingRules/DELETE_BUDGET_ROLLING_RULE_ERROR'
export const DELETE_BUDGET_ROLLING_RULE_SUCCESS =
  'app/BudgetRollingRules/DELETE_BUDGET_ROLLING_RULE_SUCCESS'

export const GET_BUDGET_ROLLING_RULES =
  'app/BudgetRollingRules/GET_BUDGET_ROLLING_RULES'
export const GET_BUDGET_ROLLING_RULES_ERROR =
  'app/BudgetRollingRules/GET_BUDGET_ROLLING_RULES_ERROR'
export const GET_BUDGET_ROLLING_RULES_SUCCESS =
  'app/BudgetRollingRules/GET_BUDGET_ROLLING_RULES_SUCCESS'

export const GET_BUDGET_ROLLING_RULE_TREE_AND_DATA =
  'app/BudgetRollingRules/GET_BUDGET_ROLLING_RULE_TREE_AND_DATA'
export const GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_ERROR =
  'app/BudgetRollingRules/GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_ERROR'
export const GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_SUCCESS =
  'app/BudgetRollingRules/GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_SUCCESS'

export const TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM =
  'app/BudgetRollingRules/TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM'

export const TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM =
  'app/BudgetRollingRules/TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM'

export const SHOW_BUDGET_ROLLING_RULE_ACCOUNTS =
  'app/BudgetRollingRules/SHOW_BUDGET_ROLLING_RULE_ACCOUNTS'

export const SHOW_EDIT_ROLLING_RULE_FORM =
  'app/BudgetRollingRules/SHOW_EDIT_ROLLING_RULE_FORM'

export const UPDATE_BUDGET_ROLLING_RULE =
  'app/BudgetRollingRules/UPDATE_BUDGET_ROLLING_RULE'
export const UPDATE_BUDGET_ROLLING_RULE_ERROR =
  'app/BudgetRollingRules/UPDATE_BUDGET_ROLLING_RULE_ERROR'
export const UPDATE_BUDGET_ROLLING_RULE_SUCCESS =
  'app/BudgetRollingRules/UPDATE_BUDGET_ROLLING_RULE_SUCCESS'

export const UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER =
  'app/BudgetRollingRules/UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER'
export const UPDATE_BUDGET_ROLLING_RULE_DATA =
  'app/BudgetRollingRules/UPDATE_BUDGET_ROLLING_RULE_DATA'
export const UPDATE_BUDGET_ROLLING_RULE_DATA_ERROR =
  'app/BudgetRollingRules/UPDATE_BUDGET_ROLLING_RULE_DATA_ERROR'
export const UPDATE_BUDGET_ROLLING_RULE_DATA_SUCCESS =
  'app/BudgetRollingRules/UPDATE_BUDGET_ROLLING_RULE_DATA_SUCCESS'

export const REPLACE_ACCOUNT_RANGES =
  'app/BudgetRollingRules/REPLACE_ACCOUNT_RANGES'
export const REPLACE_ACCOUNT_RANGES_ERROR =
  'app/BudgetRollingRules/REPLACE_ACCOUNT_RANGES_ERROR'
export const REPLACE_ACCOUNT_RANGES_SUCCESS =
  'app/BudgetRollingRules/REPLACE_ACCOUNT_RANGES_SUCCESS'
