export const GET_CONTENTS = 'app/Content/GET_CONTENTS'
export const GET_CONTENTS_SUCCESS = 'app/Content/GET_CONTENTS_SUCCESS'
export const GET_CONTENTS_ERROR = 'app/Content/GET_CONTENTS_ERROR'

export const DELETE_BUDGET_CONTENT = 'app/Content/DELETE_BUDGET_CONTENT'
export const DELETE_BUDGET_CONTENT_SUCCESS =
  'app/Content/DELETE_BUDGET_CONTENT_SUCCESS'
export const DELETE_BUDGET_CONTENT_ERROR =
  'app/Content/DELETE_BUDGET_CONTENT_ERROR'

export const DELETE_SUBBUDGET_CONTENT = 'app/Content/DELETE_SUBBUDGET_CONTENT'

export const DELETE_CONTENT = 'app/Content/DELETE_CONTENT'
export const DELETE_CONTENT_SUCCESS = 'app/Content/DELETE_CONTENT_SUCCESS'
export const DELETE_CONTENT_ERROR = 'app/Content/DELETE_CONTENT_ERROR'

export const UPDATE_BUDGET_CONTENT = 'app/Content/UPDATE_BUDGET_CONTENT'
export const UPDATE_BUDGET_CONTENT_SUCCESS =
  'app/Content/UPDATE_BUDGET_CONTENT_SUCCESS'
export const UPDATE_BUDGET_CONTENT_ERROR =
  'app/Content/UPDATE_BUDGET_CONTENT_ERROR'

export const UPDATE_SUBBUDGET_CONTENT = 'app/Content/UPDATE_SUBBUDGET_CONTENT'
export const UPDATE_SUBBUDGET_CONTENT_SUCCESS =
  'app/Content/UPDATE_SUBBUDGET_CONTENT_SUCCESS'
export const UPDATE_SUBBUDGET_CONTENT_ERROR =
  'app/Content/UPDATE_SUBBUDGET_CONTENT_ERROR'

export const UPDATE_CONTENT = 'app/Content/UPDATE_CONTENT'
export const UPDATE_CONTENT_SUCCESS = 'app/Content/UPDATE_CONTENT_SUCCESS'
export const UPDATE_CONTENT_ERROR = 'app/Content/UPDATE_CONTENT_ERROR'

export const CREATE_CONTENT = 'app/Content/CREATE_CONTENT'
export const CREATE_CONTENT_SUCCESS = 'app/Content/CREATE_CONTENT_SUCCESS'
export const CREATE_CONTENT_ERROR = 'app/Content/CREATE_CONTENT_ERROR'

export const CONTENT_STATUS_LOADING = 'loading'
export const CONTENT_STATUS_ERROR = 'error'
export const CLEAR_CONTENT_TARGET_STATUS =
  'app/Content/CLEAR_CONTENT_TARGET_STATUS'

export const CONTENT_TYPE_WIDGET = 'ContentWidget'
export const CONTENT_TYPE_BUDGET = 'Budget'
export const CONTENT_TYPE_SUB_BUDGET = 'SubBudget'
export const CONTENT_TYPE_BUDGET_ROW = 'BudgetRow'
export const CONTENT_TYPE_SUB_BUDGET_ROW = 'SubBudgetRow'
export const CONTENT_TYPE_SYSTEM_WIDGET = 'SystemContentWidget'
export const CONTENT_TYPE_COMPANY_INFO = 'CompanyInfo'
export const CONTENT_TYPE_CUSTOMER_WIDGET = 'CustomerContentWidget'
export const CONTENT_TYPES = [
  CONTENT_TYPE_WIDGET,
  CONTENT_TYPE_BUDGET,
  CONTENT_TYPE_SYSTEM_WIDGET,
  CONTENT_TYPE_BUDGET_ROW,
  CONTENT_TYPE_SUB_BUDGET_ROW,
  CONTENT_TYPE_COMPANY_INFO,
  CONTENT_TYPE_CUSTOMER_WIDGET,
  CONTENT_TYPE_SUB_BUDGET,
]
