/*
 *
 * BudgetRollingRules actions
 *
 */

import {
  ADD_ACCOUNT_RANGE,
  ADD_ACCOUNT_RANGE_ERROR,
  ADD_ACCOUNT_RANGE_SUCCESS,
  CREATE_BUDGET_ROLLING_RULE,
  CREATE_BUDGET_ROLLING_RULE_ERROR,
  CREATE_BUDGET_ROLLING_RULE_SUCCESS,
  DELETE_BUDGET_ROLLING_RULE,
  DELETE_BUDGET_ROLLING_RULE_ERROR,
  DELETE_BUDGET_ROLLING_RULE_SUCCESS,
  GET_BUDGET_ROLLING_RULES,
  GET_BUDGET_ROLLING_RULES_ERROR,
  GET_BUDGET_ROLLING_RULES_SUCCESS,
  GET_BUDGET_ROLLING_RULE_TREE_AND_DATA,
  GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_ERROR,
  GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_SUCCESS,
  REPLACE_ACCOUNT_RANGES,
  REPLACE_ACCOUNT_RANGES_ERROR,
  REPLACE_ACCOUNT_RANGES_SUCCESS,
  TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM,
  TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM,
  SHOW_BUDGET_ROLLING_RULE_ACCOUNTS,
  SHOW_EDIT_ROLLING_RULE_FORM,
  UPDATE_BUDGET_ROLLING_RULE,
  UPDATE_BUDGET_ROLLING_RULE_ERROR,
  UPDATE_BUDGET_ROLLING_RULE_SUCCESS,
  UPDATE_BUDGET_ROLLING_RULE_DATA,
  UPDATE_BUDGET_ROLLING_RULE_DATA_ERROR,
  UPDATE_BUDGET_ROLLING_RULE_DATA_SUCCESS,
  UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER,
} from './constants'

export const addAccountRange = ({
  companyCode,
  startAccount,
  endAccount,
  ruleId,
  budgetId,
}) => ({
  type: ADD_ACCOUNT_RANGE,
  companyCode,
  startAccount,
  endAccount,
  ruleId,
  budgetId,
})
export const addAccountRangeError = (error) => ({
  type: ADD_ACCOUNT_RANGE_ERROR,
  error,
})
export const addAccountRangeSuccess = ({ rollingRule }) => ({
  type: ADD_ACCOUNT_RANGE_SUCCESS,
  rollingRule,
})

export const createBudgetRollingRule = ({
  companyCode,
  budgetId,
  ...rest
}) => ({
  type: CREATE_BUDGET_ROLLING_RULE,
  companyCode,
  budgetId,
  ...rest,
})
export const createBudgetRollingRuleError = (error) => ({
  type: CREATE_BUDGET_ROLLING_RULE_ERROR,
  error,
})
export const createBudgetRollingRuleSuccess = ({ rollingRule }) => ({
  type: CREATE_BUDGET_ROLLING_RULE_SUCCESS,
  rollingRule,
})

export const deleteBudgetRollingRule = ({ companyCode, budgetId, ruleId }) => ({
  type: DELETE_BUDGET_ROLLING_RULE,
  companyCode,
  budgetId,
  ruleId,
})
export const deleteBudgetRollingRuleError = (error) => ({
  type: DELETE_BUDGET_ROLLING_RULE_ERROR,
  error,
})
export const deleteBudgetRollingRuleSuccess = ({ ruleId }) => ({
  type: DELETE_BUDGET_ROLLING_RULE_SUCCESS,
  ruleId,
})

export const getBudgetRollingRules = ({ companyCode, budgetId }) => ({
  type: GET_BUDGET_ROLLING_RULES,
  companyCode,
  budgetId,
})
export const getBudgetRollingRulesError = (error) => ({
  type: GET_BUDGET_ROLLING_RULES_ERROR,
  error,
})
export const getBudgetRollingRulesSuccess = ({ rollingRules }) => ({
  type: GET_BUDGET_ROLLING_RULES_SUCCESS,
  rollingRules,
})

export const getBudgetRollingRuleTreeAndData = ({
  companyCode,
  budgetId,
  ruleId,
  start,
  end,
}) => ({
  type: GET_BUDGET_ROLLING_RULE_TREE_AND_DATA,
  companyCode,
  budgetId,
  ruleId,
  start,
  end,
})
export const getBudgetRollingRuleTreeAndDataError = (error) => ({
  type: GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_ERROR,
  error,
})
export const getBudgetRollingRuleTreeAndDataSuccess = ({ tree, data }) => ({
  type: GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_SUCCESS,
  tree,
  data,
})

export const replaceAccountRanges = ({
  companyCode,
  ruleId,
  budgetId,
  accountRanges,
}) => ({
  type: REPLACE_ACCOUNT_RANGES,
  companyCode,
  ruleId,
  budgetId,
  accountRanges,
})
export const replaceAccountRangesError = (error) => ({
  type: REPLACE_ACCOUNT_RANGES_ERROR,
  error,
})
export const replaceAccountRangesSuccess = ({ rollingRule }) => ({
  type: REPLACE_ACCOUNT_RANGES_SUCCESS,
  rollingRule,
})

export const toggleShowCreateRollingRuleForm = () => ({
  type: TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM,
})

export const toggleShowAddAccountRangeForm = () => ({
  type: TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM,
})

export const showBudgetRollingRuleAccounts = ({ ruleId, show }) => ({
  type: SHOW_BUDGET_ROLLING_RULE_ACCOUNTS,
  ruleId,
  show,
})

export const showEditRollingRuleForm = (ruleId) => ({
  type: SHOW_EDIT_ROLLING_RULE_FORM,
  ruleId,
})

export const updateBudgetRollingRule = ({
  companyCode,
  budgetId,
  oldRollingRule,
  newRollingRule,
}) => ({
  type: UPDATE_BUDGET_ROLLING_RULE,
  companyCode,
  budgetId,
  oldRollingRule,
  newRollingRule,
})
export const updateBudgetRollingRuleError = (error) => ({
  type: UPDATE_BUDGET_ROLLING_RULE_ERROR,
  error,
})
export const updateBudgetRollingRuleSuccess = ({ rollingRule }) => ({
  type: UPDATE_BUDGET_ROLLING_RULE_SUCCESS,
  rollingRule,
})

export const bufferUpdateBudgetRollingRuleData = ({
  companyCode,
  budgetId,
  ruleId,
  start,
  end,
  patches,
}) => ({
  type: UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER,
  companyCode,
  budgetId,
  ruleId,
  start,
  end,
  patches,
})

export const updateBudgetRollingRuleData = ({
  companyCode,
  budgetId,
  ruleId,
  start,
  end,
  patches,
}) => ({
  type: UPDATE_BUDGET_ROLLING_RULE_DATA,
  companyCode,
  budgetId,
  ruleId,
  start,
  end,
  patches,
})
export const updateBudgetRollingRuleDataError = (error) => ({
  type: UPDATE_BUDGET_ROLLING_RULE_DATA_ERROR,
  error,
})
export const updateBudgetRollingRuleDataSuccess = ({ data }) => ({
  type: UPDATE_BUDGET_ROLLING_RULE_DATA_SUCCESS,
  data,
})
