import diff from 'immutablediff'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import accountSchemeTemplateApi from 'api/AccountSchemeTemplateApi'
import {
  AccountSchemeTemplateUpdateRowGroupRecord,
  AccountSchemeTemplateUpdateRowRecord,
} from 'records'
import { fixLeadingSlash } from 'utils/schemeGroupUtils'
import {
  addGroupError,
  addGroupSuccess,
  addRowError,
  addRowSuccess,
  deleteRowError,
  deleteRowSuccess,
  getAccountSchemeTemplateError,
  getAccountSchemeTemplateSuccess,
  updateGroupError,
  updateGroupSuccess,
  updateRowError,
  updateRowSuccess,
} from './actions'
import {
  ADD_CHILD_GROUP,
  ADD_GROUP,
  ADD_ROW,
  DELETE_ROW,
  DELETE_ROWS,
  GET_ACCOUNT_SCHEME_TEMPLATE,
  UPDATE_GROUP,
  UPDATE_ROW,
} from './constants'
import { getKeyPath } from './functions'
import { selectAccountSchemeTemplateTreeNode } from './selectors'

// Individual exports for testing
export function* addChildGroup(action) {
  try {
    const {
      accountGroupType,
      accountSchemeTemplateId,
      companyCode,
      customerCode,
      hideGroupSum,
      name,
      path,
      rowFactor,
      defaultForecastingParameters,
    } = action

    // server side paths are rooted to /schemeGroup
    const newPath = `${path}/Children/0`

    const patch = [
      {
        op: 'add',
        path: fixLeadingSlash(newPath),
        value: {
          accountGroupType,
          hideGroupSum,
          name,
          order: 0,
          rowType: 'Header',
          rowFactor,
          defaultForecastingParameters,
        },
      },
    ]
    const tree = yield call(
      accountSchemeTemplateApi.patchAccountSchemeTemplateTree,
      { companyCode, customerCode, id: accountSchemeTemplateId, patch }
    )
    yield put(addGroupSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, addGroupError))
  }
}
export function* addGroup(action) {
  try {
    const {
      accountGroupType,
      accountSchemeTemplateId,
      companyCode,
      customerCode,
      hideGroupSum,
      name,
      path,
      rowFactor,
      defaultForecastingParameters,
    } = action

    const keyPath = getKeyPath(path)
    const currentGroup = yield select(
      selectAccountSchemeTemplateTreeNode(keyPath)
    )
    const index = currentGroup.children
      ? currentGroup.children.filter((child) => child.rowType === 'Header').size
      : 0
    keyPath[0] = 'SchemeGroup'
    const num = parseInt(keyPath[keyPath.length - 1], 10)
    keyPath[keyPath.length - 1] = num + 1
    // server side paths are rooted to /schemeGroup
    const newPath = keyPath.join('/')

    const patch = [
      {
        op: 'add',
        path: fixLeadingSlash(newPath),
        value: {
          accountGroupType,
          hideGroupSum,
          name,
          order: index,
          rowType: 'Header',
          rowFactor,
          defaultForecastingParameters,
        },
      },
    ]
    const tree = yield call(
      accountSchemeTemplateApi.patchAccountSchemeTemplateTree,
      { companyCode, customerCode, id: accountSchemeTemplateId, patch }
    )
    yield put(addGroupSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, addGroupError))
  }
}

export function* addRow(action) {
  try {
    const {
      accountSchemeTemplateId,
      endAccount,
      companyCode,
      customerCode,
      path,
      rowFactor,
      startAccount,
      defaultForecastingParameters,
    } = action

    const keyPath = getKeyPath(path)
    const currentGroup = yield select(
      selectAccountSchemeTemplateTreeNode(keyPath)
    )
    const index = currentGroup.children ? currentGroup.children.size : 0

    // server side paths are rooted to /schemeGroup
    const newPath = `${path}/Children/-`

    const patch = [
      {
        op: 'add',
        path: fixLeadingSlash(newPath),
        value: {
          endAccount,
          order: index,
          rowType: 'Row',
          rowFactor,
          startAccount,
          defaultForecastingParameters,
        },
      },
    ]
    const tree = yield call(
      accountSchemeTemplateApi.patchAccountSchemeTemplateTree,
      { companyCode, customerCode, id: accountSchemeTemplateId, patch }
    )
    yield put(addRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, addRowError))
  }
}

export function* deleteRow(action) {
  try {
    const { accountSchemeTemplateId, companyCode, customerCode, path } = action
    const patch = [
      {
        op: 'remove',
        path: fixLeadingSlash(path),
      },
    ]
    const tree = yield call(
      accountSchemeTemplateApi.patchAccountSchemeTemplateTree,
      {
        companyCode,
        customerCode,
        id: accountSchemeTemplateId,
        patch,
      }
    )
    yield put(deleteRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, deleteRowError))
  }
}

export function* deleteRows(action) {
  try {
    const {
      accountSchemeTemplateId,
      companyCode,
      customerCode,
      rowIds,
    } = action
    const tree = yield call(
      accountSchemeTemplateApi.accountSchemeTemplateBulkDelete,
      {
        companyCode,
        customerCode,
        id: accountSchemeTemplateId,
        rowIds,
      }
    )
    yield put(deleteRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, deleteRowError))
  }
}

export function* getAccountSchemeTemplate({ companyCode, customerCode, id }) {
  try {
    const tree = yield call(
      accountSchemeTemplateApi.getAccountSchemeTemplateTree,
      {
        companyCode,
        customerCode,
        id,
      }
    )
    yield put(getAccountSchemeTemplateSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, getAccountSchemeTemplateError))
  }
}

export function* updateGroup(action) {
  try {
    const {
      accountGroupType,
      accountSchemeTemplateId: id,
      companyCode,
      customerCode,
      hideGroupSum,
      name,
      path,
      rowFactor,
      defaultForecastingParameters,
    } = action

    const keyPath = getKeyPath(path)
    const currentGroup = yield select(
      selectAccountSchemeTemplateTreeNode(keyPath)
    )

    const patch = diff(
      new AccountSchemeTemplateUpdateRowGroupRecord(currentGroup),
      new AccountSchemeTemplateUpdateRowGroupRecord({
        accountGroupType,
        hideGroupSum,
        name,
        rowFactor,
        defaultForecastingParameters,
      })
    )
      .toJS()
      .map((object) => ({ ...object, path: `${path}${object.path}` }))

    const tree = yield call(
      accountSchemeTemplateApi.patchAccountSchemeTemplateTree,
      { companyCode, customerCode, id, patch }
    )
    yield put(updateGroupSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, updateGroupError))
  }
}

export function* updateRow(action) {
  try {
    const {
      accountSchemeTemplateId,
      endAccount,
      companyCode,
      customerCode,
      path,
      rowFactor,
      startAccount,
      defaultForecastingParameters,
    } = action

    const keyPath = getKeyPath(path)
    const currentRow = yield select(
      selectAccountSchemeTemplateTreeNode(keyPath)
    )

    const patch = diff(
      new AccountSchemeTemplateUpdateRowRecord(currentRow),
      new AccountSchemeTemplateUpdateRowRecord({
        endAccount,
        rowFactor,
        startAccount,
        defaultForecastingParameters,
      })
    )
      .toJS()
      .map((object) => ({ ...object, path: `${path}${object.path}` }))

    const tree = yield call(
      accountSchemeTemplateApi.patchAccountSchemeTemplateTree,
      { companyCode, customerCode, id: accountSchemeTemplateId, patch }
    )
    yield put(updateRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, updateRowError))
  }
}

// All sagas to be loaded
export function* accountSchemeTemplateSaga() {
  yield all([
    takeEvery(ADD_CHILD_GROUP, addChildGroup),
    takeEvery(ADD_GROUP, addGroup),
    takeEvery(ADD_ROW, addRow),
    takeEvery(DELETE_ROW, deleteRow),
    takeEvery(DELETE_ROWS, deleteRows),
    takeLatest(GET_ACCOUNT_SCHEME_TEMPLATE, getAccountSchemeTemplate),
    takeEvery(UPDATE_GROUP, updateGroup),
    takeEvery(UPDATE_ROW, updateRow),
  ])
}

export default accountSchemeTemplateSaga
