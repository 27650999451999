import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'

import { handleError } from 'api/api-utils'
import dimensionsApi from 'api/DimensionsApi'

import { GET_DIMENSION_WITH_VALUE, UPDATE_DIMENSION_VALUE } from './constants'
import {
  getDimensionWithValueError,
  getDimensionWithValueSuccess,
  updateDimensionValueError,
  updateDimensionValueSuccess,
} from './actions'

// When updating a DimensionValue, we don't want to change just any property
const UpdateDimensionValueRecord = Record({
  name: undefined,
  code: undefined,
  displayName: undefined,
  parentId: undefined,
  hideFromBudgeting: undefined,
  hideFromReporting: undefined,
})

export function* getDimensionWithValue(action) {
  const { companyCode, dimensionId, dimensionValueId } = action

  try {
    const dimension = yield call(
      dimensionsApi.getDimension,
      companyCode,
      dimensionId
    )
    yield put(getDimensionWithValueSuccess({ dimension, dimensionValueId }))
  } catch (error) {
    yield put(handleError(error, getDimensionWithValueError))
  }
}

export function* updateDimensionValue(action) {
  const {
    companyCode,
    dimensionId,
    dimensionValue,
    updatedDimensionValue,
  } = action

  const patch = Diff(
    new UpdateDimensionValueRecord(dimensionValue),
    new UpdateDimensionValueRecord(updatedDimensionValue)
  ).toJS()

  try {
    const updatedApiDimensionValue = yield call(
      dimensionsApi.patchDimensionValue,
      companyCode,
      dimensionValue.id,
      patch
    )
    yield put(
      updateDimensionValueSuccess({
        companyCode,
        dimensionId,
        dimensionValue: updatedApiDimensionValue,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateDimensionValueError))
  }
}

// Individual exports for testing
export function* dimensionValueSaga() {
  yield all([
    takeEvery(UPDATE_DIMENSION_VALUE, updateDimensionValue),
    takeLatest(GET_DIMENSION_WITH_VALUE, getDimensionWithValue),
  ])
}

// All sagas to be loaded
export default dimensionValueSaga
