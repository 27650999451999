import { all, call, takeEvery, put } from 'redux-saga/effects'
import {
  companyCreationWizardCreateAccountSchemeError,
  companyCreationWizardCreateAccountSchemeSuccess,
  companyCreationWizardCreateBudgetError,
  companyCreationWizardCreateBudgetSuccess,
  companyCreationWizardSetAccountSchemeSpecialAccount,
  companyCreationWizardSetAccountSchemeSpecialAccountError,
  companyCreationWizardSetAccountSchemeSpecialAccountSuccess,
  getAccountListChoicesSuccess,
  getAccountListChoicesError,
  getAccountListFileError,
  getAccountListFileSuccess,
  importCompanyCreationWizardAccounts,
  importCompanyCreationWizardAccountsError,
  importCompanyCreationWizardAccountsSuccess,
  parseCompanyCreationWizardAccounts,
  parseCompanyCreationWizardAccountsError,
  parseCompanyCreationWizardAccountsSuccess,
  setSpecialAccounts,
  setSpecialAccountsError,
  setSpecialAccountsSuccess,
  startCreateBudget,
} from './actions'
import {
  createAccountSchemeSuccess,
  resetAccountSchemesState,
} from '../AccountSchemes/actions'

import { createBudgetSuccess } from '../Budgets/actions'

import { List } from 'immutable'
import accountApi from 'api/AccountApi'
import accountSchemeApi from 'api/AccountSchemeApi'
import budgetApi from 'api/BudgetApi'
import {
  COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME,
  COMPANY_CREATION_WIZARD_CREATE_BUDGET,
  COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  GET_ACCOUNT_LIST_CHOICES,
  GET_ACCOUNT_LIST_FILE,
  IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
  SET_SPECIAL_ACCOUNTS,
} from './constants'
import { AccountRecord } from 'records'
import { handleError } from 'api/api-utils'
import accountParser from 'utils/file-parsers/accountParser'

export function* loadAccountListChoices() {
  try {
    const accountListChoices = yield call(accountApi.getAccountListNames)
    //['Netvisor', 'Procountor', 'Muut'];
    yield put(getAccountListChoicesSuccess({ accountListChoices }))
  } catch (error) {
    yield put(handleError(error, getAccountListChoicesError))
  }
}
export function* loadAccountListFile(action) {
  try {
    const accountList = yield call(accountApi.getAccountListFile, {
      name: action.choice,
    })
    const companyCode = action.companyCode
    yield put(getAccountListFileSuccess())
    yield put(
      parseCompanyCreationWizardAccounts({ companyCode, file: accountList })
    )
  } catch (error) {
    yield put(handleError(error, getAccountListFileError))
  }
}
export function* loadCompanyCreationWizardAccounts(action) {
  const { file, companyCode } = action
  try {
    const { parsedAccounts, parsedFile } = yield accountParser({
      file,
    })
    yield put(
      parseCompanyCreationWizardAccountsSuccess({
        companyCode,
        parsedAccounts,
        parsedFile,
      })
    )
  } catch (error) {
    yield put(handleError(error, parseCompanyCreationWizardAccountsError))
  }
}
export function* createAccountScheme(action) {
  const { accountListName, accountSchemeTemplateId, companyCode, name } = action

  try {
    yield put(resetAccountSchemesState())
    const accountScheme = yield call(accountSchemeApi.createAccountScheme, {
      accountSchemeTemplateId,
      companyCode,
      name,
    })
    yield put(createAccountSchemeSuccess({ accountScheme })) //This is to make sure account schemes are as they should in store.
    yield put(
      companyCreationWizardCreateAccountSchemeSuccess({ accountScheme })
    )
    yield put(
      setSpecialAccounts({ accountListName, accountScheme, companyCode })
    )
  } catch (error) {
    yield put(handleError(error, companyCreationWizardCreateAccountSchemeError))
  }
}
export function* startImportAccounts(action) {
  try {
    const { parsedAccounts, companyCode } = action
    const validAccounts = List(parsedAccounts)
    yield put(
      importCompanyCreationWizardAccounts({ companyCode, validAccounts })
    )
  } catch (error) {
    yield put(handleError(error, importCompanyCreationWizardAccountsError))
  }
}
export function* sendAccounts(action) {
  try {
    const { validAccounts, companyCode } = action
    const accounts = validAccounts
      .map((account) => new AccountRecord(account))
      .toJS()
    yield call(accountApi.importAccounts, { accounts, companyCode })
    yield put(
      importCompanyCreationWizardAccountsSuccess({
        companyCode,
        validAccounts,
      })
    )
  } catch (error) {
    yield put(handleError(error, importCompanyCreationWizardAccountsError))
  }
}
export function* sendBudget(action) {
  const {
    accountSchemeId,
    budgetType,
    companyCode,
    displayDecimalsAmount,
    endDate,
    lockDate,
    name,
    startDate,
    useAccountingLockDate,
    presentationType,
    realBalanceEndDate,
    timeRangeStart,
    timeRangeEnd,
  } = action
  try {
    const isDefault = true
    const budget = yield call(budgetApi.createBudget, {
      accountSchemeId,
      budgetType,
      companyCode,
      displayDecimalsAmount,
      endDate,
      isDefault,
      lockDate,
      name,
      startDate,
      useAccountingLockDate,
      presentationType,
      realBalanceEndDate,
      timeRangeStart,
      timeRangeEnd,
    })
    yield put(createBudgetSuccess({ budget, companyCode }))
    yield put(companyCreationWizardCreateBudgetSuccess({ budget, companyCode }))
  } catch (error) {
    yield put(handleError(error, companyCreationWizardCreateBudgetError))
  }
}
export function* sendSpecialAccounts(action) {
  try {
    const id = action.accountScheme.id
    const companyCode = action.companyCode
    const accountListName = action.accountListName
    yield put(
      companyCreationWizardSetAccountSchemeSpecialAccount({
        accountNumber: '2370',
        companyCode,
        id,
        specialAccount: 'profit',
      })
    )
    yield put(
      companyCreationWizardSetAccountSchemeSpecialAccount({
        accountNumber: '1910',
        companyCode,
        id,
        specialAccount: 'reconciliation',
      })
    )
    yield put(
      companyCreationWizardSetAccountSchemeSpecialAccount({
        accountNumber: accountListName === 'Procountor' ? '2250' : '2251',
        companyCode,
        id,
        specialAccount: 'retainedEarnings',
      })
    )
    yield put(
      companyCreationWizardSetAccountSchemeSpecialAccount({
        accountNumber: accountListName === 'Procountor' ? '2260' : '2261',
        companyCode,
        id,
        specialAccount: 'profitDistribution',
      })
    )

    yield put(setSpecialAccountsSuccess())
    yield put(startCreateBudget())
  } catch (error) {
    yield put(handleError(error, setSpecialAccountsError))
  }
}
export function* setAccounSchemeSpecialAccount(action) {
  try {
    const { accountNumber, companyCode, id, specialAccount } = action

    const patch = [
      {
        op: 'replace',
        path: `/specialaccounts/${specialAccount}`,
        value: { accountNumber },
      },
    ]
    const accountScheme = yield call(accountSchemeApi.patchAccountScheme, {
      companyCode,
      id,
      patch,
    })
    yield put(
      companyCreationWizardSetAccountSchemeSpecialAccountSuccess({
        accountScheme,
      })
    )
  } catch (error) {
    yield put(
      handleError(
        error,
        companyCreationWizardSetAccountSchemeSpecialAccountError
      )
    )
  }
}
export function* companyCreationWizardSagas() {
  yield all([
    takeEvery(
      COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME,
      createAccountScheme
    ),
    takeEvery(COMPANY_CREATION_WIZARD_CREATE_BUDGET, sendBudget),
    takeEvery(
      COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
      setAccounSchemeSpecialAccount
    ),
    takeEvery(GET_ACCOUNT_LIST_CHOICES, loadAccountListChoices),
    takeEvery(GET_ACCOUNT_LIST_FILE, loadAccountListFile),
    takeEvery(
      PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS,
      loadCompanyCreationWizardAccounts
    ),
    takeEvery(
      PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
      startImportAccounts
    ),
    takeEvery(IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS, sendAccounts),
    takeEvery(SET_SPECIAL_ACCOUNTS, sendSpecialAccounts),
  ])
}

// All sagas to be loaded
export default companyCreationWizardSagas
