/*
 *
 * RollingRules reducer
 *
 */

import { fromJS, List, Map } from 'immutable'
import {
  CHANGE_EDIT_ROLLING_RULE_FORM_TYPE,
  CHANGE_ROLLING_RULE_FORM_TYPE,
  CREATE_ROLLINGRULE,
  CREATE_ROLLINGRULE_ERROR,
  CREATE_ROLLINGRULE_SUCCESS,
  DELETE_ROLLINGRULE,
  DELETE_ROLLINGRULE_ERROR,
  DELETE_ROLLINGRULE_SUCCESS,
  EDIT_ROLLING_RULE,
  GET_ROLLINGRULES,
  GET_ROLLINGRULES_ERROR,
  GET_ROLLINGRULES_SUCCESS,
  TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM,
  UPDATE_ROLLING_RULE,
  UPDATE_ROLLING_RULE_ERROR,
  UPDATE_ROLLING_RULE_SUCCESS,
} from './constants'
import {
  ADD_ACCOUNT_RANGE_SUCCESS,
  DISTRIBUTION,
  REPLACE_ACCOUNT_RANGES_SUCCESS,
} from 'containers/RollingRule/constants'

import { mapRollingRules, createRollingRule } from './utils'

const initialState = fromJS({
  loading: false,
  error: false,
  rollings: new Map(),
  createRollingRuleForm: {
    showForm: false,
    selectedRuleType: DISTRIBUTION,
  },
  editRollingRuleForm: {
    ruleId: undefined,
    selectedRuleType: undefined,
  },
})

export function rollingRulesReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_EDIT_ROLLING_RULE_FORM_TYPE:
      return state.setIn(
        ['editRollingRuleForm', 'selectedRuleType'],
        action.ruleType
      )

    case EDIT_ROLLING_RULE:
      return state.setIn(['editRollingRuleForm', 'ruleId'], action.ruleId)

    case DELETE_ROLLINGRULE_SUCCESS: {
      const newRollingRules = state.getIn(['rollings', action.companyCode])
      const newRuleMap = newRollingRules.filter((r) => r.id !== action.ruleId)
      return state
        .set('loading', false)
        .setIn(['rollings', action.companyCode], newRuleMap)
    }

    case GET_ROLLINGRULES_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .setIn(
          ['rollings', action.companyCode],
          mapRollingRules(action.rollingRules)
        )
    case TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM:
      return state.setIn(
        ['createRollingRuleForm', 'showForm'],
        !state.getIn(['createRollingRuleForm', 'showForm'])
      )

    case CHANGE_ROLLING_RULE_FORM_TYPE:
      return state.setIn(
        ['createRollingRuleForm', 'selectedRuleType'],
        action.ruleType
      )

    case GET_ROLLINGRULES:
    case UPDATE_ROLLING_RULE:
    case DELETE_ROLLINGRULE:
    case CREATE_ROLLINGRULE:
      return state.set('loading', true).set('error', false)

    case CREATE_ROLLINGRULE_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .setIn(['createRollingRuleForm', 'showForm'], false)
        .updateIn(['rollings', action.companyCode], (rules) => {
          const newRule = createRollingRule(action.rollingRule)
          if (!rules) {
            return new List([newRule])
          }
          return new List([...rules].concat([newRule]))
        })
    case GET_ROLLINGRULES_ERROR:
    case UPDATE_ROLLING_RULE_ERROR:
    case DELETE_ROLLINGRULE_ERROR:
    case CREATE_ROLLINGRULE_ERROR:
      return state.set('loading', false).set('error', action.error)

    case REPLACE_ACCOUNT_RANGES_SUCCESS:
    case ADD_ACCOUNT_RANGE_SUCCESS:
    case UPDATE_ROLLING_RULE_SUCCESS: {
      const rollings = state.getIn(['rollings', action.companyCode])
      const rollingRuleIndex = rollings.findIndex(
        (r) => r.id === action.rollingRule.id
      )
      const newRollingRule = createRollingRule(action.rollingRule)
      const newRollingRules = rollings.set(rollingRuleIndex, newRollingRule)
      return state
        .set('loading', false)
        .setIn(['rollings', action.companyCode], newRollingRules)
        .setIn(['editRollingRuleForm', 'ruleId'], undefined)
    }

    default:
      return state
  }
}

export default rollingRulesReducer
