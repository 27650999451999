import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'react-bootstrap'

import { CancelButton, ConfirmButton } from 'components/Button'

const ConfirmModal = ({
  dataTestingId,
  show,
  onConfirm,
  onCancel,
  title,
  body,
}) => (
  <div className="static-modal">
    <Modal
      backdrop="static"
      data-testing-id={dataTestingId}
      show={show}
      onHide={onCancel}
    >
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {body && <Modal.Body>{body}</Modal.Body>}
      {(onConfirm || onCancel) && (
        <Modal.Footer>
          {onCancel && <CancelButton onClick={onCancel} />}
          {onConfirm && <ConfirmButton onClick={onConfirm} />}
        </Modal.Footer>
      )}
    </Modal>
  </div>
)

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
}

ConfirmModal.defaultProps = {
  show: false,
}

export default ConfirmModal
