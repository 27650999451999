import { SuccessButton } from 'components/Button'
import { CancelButton } from 'components/Button'
import { ALLOW_SIBLINGS_LOGIC } from 'components/DimensionTool/constants'
import { DimensionTool } from 'components/DimensionTool'
import { selectDimensionForSelectTool } from 'containers/Dimensions/selectors'
import React, { useMemo, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import FormText from 'react-bootstrap/FormText'
import * as yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'

import { useSelector } from 'react-redux'
import styles from './styles.module.css'
import messages from './messages'
import { selectCompanyLoading } from 'containers/Dimensions/selectors'
import Form from 'react-formal'
import FormField from 'components/FormField'
import { flattenChildren } from 'utils/schemeGroupUtils'
import { useParams } from 'react-router'
import SubBudgetSchemeImportForm from 'containers/SubBudgetSchemeImportForm'
import { useIsSystemUser } from 'utils/hooks/useHasClaim'

export const DUPLICATE_SINGLE = 'copySingle'
export const DUPLICATE_BY_VALUES = 'copyValues'
export const DUPLICATE_BY_TARGETS = 'copyMatrixes'

const RECOMMENDED_MAX_ROW_COUNT = 5000

const getAfterDuplicateRowCount = ({
  form,
  currentRowsCount,
  duplicateTargetRowCount,
}) => {
  if (form.duplicationMode === DUPLICATE_SINGLE)
    return currentRowsCount + duplicateTargetRowCount
  return (
    currentRowsCount + form.dimensionValues.length * duplicateTargetRowCount
  )
}

const schema = yup.object({
  duplicationMode: yup.string().default(DUPLICATE_BY_VALUES),
  name: yup.string(),
  dimensionValues: yup.array().default([]),
  targetDimension: yup.boolean().default(true),
  matrixDimensionNames: yup
    .array()
    .of(yup.string())
    .default([]),
  matrixes: yup
    .array()
    .of(
      yup.object({
        name: yup.string(),
        dimensionValues: yup
          .array()
          .of(yup.string().nullable())
          .nullable(),
      })
    )
    .default([]),
})

const SubBudgetDuplicateRowModal = ({
  intl: { formatMessage },
  onCancel,
  onSubmit,
  row,
  tree,
}) => {
  const { companyCode } = useParams()
  const [form, setForm] = useState(schema.default())
  const isSystemUser = useIsSystemUser()
  const dimensionsLoading = useSelector(selectCompanyLoading({ companyCode }))
  var currentRowsCount = useMemo(() => flattenChildren(tree)?.size, [tree])
  var duplicateTargetRowCount = useMemo(() => flattenChildren(row)?.size, [row])
  var afterDuplicationRowCount = getAfterDuplicateRowCount({
    form,
    currentRowsCount,
    duplicateTargetRowCount,
  })
  const dimensionData = useSelector(
    selectDimensionForSelectTool({
      companyCode,
      budgeting: true,
      rootOnly: true,
    })
  )
  const submitForm = () =>
    onSubmit({
      ...form,
      copy: row,
    })
  return (
    <Modal show size="lg">
      <ModalHeader>
        <h4>
          <FormattedMessage {...messages.header} />
          {row.name ? ' - ' + row.name : ''}
        </h4>
      </ModalHeader>
      <ModalBody>
        <Form value={form} onChange={(form) => setForm(form)} schema={schema}>
          <FormField
            name="duplicationMode"
            label={formatMessage(messages.duplicationMode)}
            textField={(item) => formatMessage(messages[item])}
            data={
              isSystemUser
                ? [DUPLICATE_BY_VALUES, DUPLICATE_SINGLE, DUPLICATE_BY_TARGETS]
                : [DUPLICATE_BY_VALUES, DUPLICATE_SINGLE]
            }
            type="selectlist"
          />
          {form.duplicationMode === DUPLICATE_BY_VALUES && (
            <>
              <div className={styles.formGroup}>
                <label>
                  {formatMessage(messages.duplicateForDimensionValues)}
                </label>
                <DimensionTool
                  onDimensionChange={(_, values) =>
                    setForm({ ...form, dimensionValues: values })
                  }
                  selectedDimensionIds={form.dimensionValues.map((dv) => dv.id)}
                  dimensionData={dimensionData}
                  busy={dimensionsLoading}
                  selectionLogic={ALLOW_SIBLINGS_LOGIC}
                  includeOperative
                />
              </div>
              <FormField
                name="targetDimension"
                label={formatMessage(messages.targetDimension)}
              />
            </>
          )}
          {form.duplicationMode === DUPLICATE_SINGLE && (
            <FormField
              autoFocus
              label={formatMessage(messages.name)}
              placeholder={formatMessage(messages.typeName)}
              name="name"
            />
          )}
          {form.duplicationMode === DUPLICATE_BY_TARGETS && (
            <SubBudgetSchemeImportForm
              tree={tree}
              form={form}
              setForm={setForm}
            />
          )}
          {afterDuplicationRowCount > RECOMMENDED_MAX_ROW_COUNT && (
            <FormText>
              <FormattedMessage {...messages.overRecommendedSize} />{' '}
            </FormText>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={onCancel} />
        <SuccessButton onClick={submitForm}>
          <FormattedMessage {...messages.header} />
        </SuccessButton>
      </ModalFooter>
    </Modal>
  )
}

export default injectIntl(SubBudgetDuplicateRowModal)
