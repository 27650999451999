import { all } from 'redux-saga/effects'

import accountsSagas from './containers/Accounts/sagas'
import accountingPeriodsSagas from './containers/AccountingPeriods/sagas'
import accountSchemesSagas from './containers/AccountSchemes/sagas'
import accountSchemeSagas from './containers/AccountScheme/sagas'
import accountSchemeTemplateSagas from './containers/AccountSchemeTemplate/sagas'
import accountSchemeTemplatesSagas from './containers/AccountSchemeTemplates/sagas'
import appSagas from './containers/App/sagas'
import bankAccountSagas from './containers/BankAccount/sagas'
import budgetRollinRulesSagas from './containers/BudgetRollingRules/sagas'
import budgetSagas from './containers/Budget/sagas'
import budgetBalanceImportModalSagas from './containers/BudgetBalanceImportModal/sagas'
import budgetBalanceModalSagas from './containers/BudgetBalanceModal/sagas'
import budgetMonitorSagas from './containers/BudgetMonitor/sagas'
import budgetsSagas from './containers/Budgets/sagas'
import calculationConstantSagas from './containers/CalculationConstant/sagas'
import calculationConstantsSagas from './containers/CalculationConstants/sagas'
import cashflowSagas from './containers/CashFlowContainer/sagas'
import cashInHandSagas from './containers/CashInHand/sagas'
import companiesSagas from './containers/Companies/sagas'
import companySagas from './containers/Company/sagas'
import companyIndexSagas from './containers/CompanyIndex/sagas'
import companyCreationWizardSagas from './containers/CompanyCreationWizard/sagas'
import acquisitionSagas from './containers/FasFormSelection/sagas'
import contentSagas from './containers/Content/sagas'
import customerSagas from './containers/Customer/sagas'
import customerDashboardsSagas from './containers/CustomerDashboards/sagas'
import customersSagas from './containers/Customers/sagas'
import dashboardsSagas from './containers/Dashboards/sagas'
import datasourceContainerSagas from './containers/DatasourceContainer/sagas'
import dimensionSagas from './containers/Dimension/sagas'
import dimensionsSagas from './containers/Dimensions/sagas'
import dimensionValueSagas from './containers/DimensionValue/sagas'
import integrationLogsSagas from './containers/IntegrationLogs/sagas'
import integrationSourceSystemsSagas from './containers/IntegrationSourceSystems/sagas'
import recurringPaymentsSagas from './containers/RecurringPayments/sagas'
import reportSagas from './containers/Report/sagas'
import reportContextDatasSagas from './containers/ReportContextDatas/sagas'
import reportDatasSagas from './containers/ReportDatas/sagas'
import reportingGroupsSagas from './containers/ReportingGroups/sagas'
import reportsSagas from './containers/Reports/sagas'
import reportSchemeSagas from './containers/ReportScheme/sagas'
import reportSchemesSagas from './containers/ReportSchemes/sagas'
import reportSelectionsSagas from './containers/ReportSelections/sagas'
import roleSagas from './containers/Role/sagas'
import rolesSagas from './containers/Roles/sagas'
import rollingRuleSagas from './containers/RollingRule/sagas'
import rollingRulesSagas from './containers/RollingRules/sagas'
import shareSagas from './containers/Share/sagas'
import subBudgetSagas from './containers/SubBudget/sagas'
import subBudgetsSagas from './containers/SubBudgets/sagas'
import subBudgetTagsSagas from './containers/SubBudgetTags/sagas'
import systemDashboardsSagas from './containers/SystemDashboards/sagas'
import userSagas from './containers/User/sagas'
import usersSagas from './containers/Users/sagas'
import voucherSagas from './containers/Voucher/sagas'
import vouchersSagas from './containers/Vouchers/sagas'
import { notificationsSaga } from 'containers/Notifications/sagas'
import integrationSagas from 'containers/IntegrationConnections/sagas'

export default function* rootSaga() {
  yield all([
    accountsSagas(),
    accountingPeriodsSagas(),
    accountSchemeSagas(),
    accountSchemesSagas(),
    accountSchemeTemplateSagas(),
    accountSchemeTemplatesSagas(),
    acquisitionSagas(),
    appSagas(),
    bankAccountSagas(),
    budgetRollinRulesSagas(),
    budgetSagas(),
    budgetBalanceImportModalSagas(),
    budgetBalanceModalSagas(),
    budgetMonitorSagas(),
    budgetsSagas(),
    calculationConstantSagas(),
    calculationConstantsSagas(),
    cashflowSagas(),
    cashInHandSagas(),
    companiesSagas(),
    companySagas(),
    companyIndexSagas(),
    companyCreationWizardSagas(),
    contentSagas(),
    customerSagas(),
    customerDashboardsSagas(),
    customersSagas(),
    dashboardsSagas(),
    datasourceContainerSagas(),
    dimensionSagas(),
    dimensionsSagas(),
    dimensionValueSagas(),
    integrationSagas(),
    integrationLogsSagas(),
    integrationSourceSystemsSagas(),
    notificationsSaga(),
    recurringPaymentsSagas(),
    reportSagas(),
    reportContextDatasSagas(),
    reportDatasSagas(),
    reportingGroupsSagas(),
    reportsSagas(),
    reportSchemeSagas(),
    reportSchemesSagas(),
    reportSelectionsSagas(),
    roleSagas(),
    rolesSagas(),
    rollingRuleSagas(),
    rollingRulesSagas(),
    shareSagas(),
    subBudgetSagas(),
    subBudgetsSagas(),
    subBudgetTagsSagas(),
    systemDashboardsSagas(),
    userSagas(),
    usersSagas(),
    voucherSagas(),
    vouchersSagas(),
  ])
}
