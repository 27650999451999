import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { getByDimensionValue } from 'containers/Dimensions/actions'
import { uniq } from 'lodash'

import {
  selectDimensionValueLoading,
  selectAllDimensions,
} from 'containers/Dimensions/selectors'
import { selectLoading } from 'containers/Dimensions/selectors'

const getMissingValues = (dimensions, requiredDimensions) =>
  requiredDimensions.filter(
    (dvId) =>
      !dimensions
        .valueSeq()
        .flatMap((d) => d.dimensionValuesById.keySeq())
        .includes(parseInt(dvId))
  )

//Fetch given values if they are not already present in the store.
//NOTE that this will cause component rerender on any changes to dimensions in store
const selectAllNonOperativeDimensions = selectAllDimensions(false)
export default function useDimensionValueFetchCheckStore(
  requiredDimensionValues = []
) {
  const dispatch = useDispatch()
  const loading = useSelector(selectDimensionValueLoading())
  const dimensions = useSelector(selectAllNonOperativeDimensions)
  const dimensionsLoading = useSelector(selectLoading())
  const [fetchedValues, setFetchedValues] = useState([])

  useEffect(() => {
    if (!requiredDimensionValues || !dimensions || dimensionsLoading) return
    const missing = getMissingValues(
      dimensions.flatten(true),
      requiredDimensionValues
    )
    const notYetFetched = uniq(
      [...missing].filter((dv) => !fetchedValues.includes(dv))
    )
    if (notYetFetched.length === 0) return
    notYetFetched.forEach((dvId) => dispatch(getByDimensionValue({ dvId })))
    setFetchedValues([...fetchedValues, ...notYetFetched])
  }, [
    requiredDimensionValues,
    dispatch,
    setFetchedValues,
    fetchedValues,
    dimensions,
    dimensionsLoading,
  ])

  const reFetch = () => setFetchedValues([])
  return [loading || dimensionsLoading, reFetch]
}
