import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const createPath = ({ budgetId, companyCode, customerCode }) => {
  // This is calculateConstants in API for historic reasons
  // CalculationConstant is used in frontend
  let path = 'calculateconstants'

  if (budgetId && companyCode) {
    path = `${companyCode}/budgets/${budgetId}/${path}`
  } else if (companyCode) {
    path = `${companyCode}/${path}`
  } else if (customerCode) {
    path = `${customerCode}/${path}`
  }

  return path
}

const createPathFromDefinedInScope = ({
  budgetId,
  companyCode,
  definedInScope,
  customerCode,
}) => {
  if (definedInScope === 'Budget') {
    return createPath({ budgetId, companyCode })
  } else if (definedInScope === 'Company') {
    return createPath({ companyCode })
  } else if (definedInScope === 'Customer') {
    return createPath({ customerCode })
  }

  return createPath({})
}

const createCalculationConstant = ({
  budgetId,
  companyCode,
  name,
  symbol,
  unit,
  values = [],
  customerCode,
}) => {
  const path = createPath({ budgetId, companyCode, customerCode })

  return axios
    .post(path, { name, symbol, unit, values }, config())
    .then(returnData)
    .catch(catchError)
}

const deleteCalculationConstant = ({
  budgetId,
  companyCode,
  customerCode,
  calculationConstant,
}) => {
  const { definedInScope, id } = calculationConstant
  const path = createPathFromDefinedInScope({
    budgetId,
    companyCode,
    customerCode,
    definedInScope,
  })

  return axios
    .delete(`${path}/${id}`, config())
    .then(returnData)
    .catch(catchError)
}

const getCalculationConstants = ({ budgetId, companyCode, customerCode }) => {
  const path = createPath({ budgetId, companyCode, customerCode })

  return axios
    .get(path, config())
    .then(returnData)
    .catch(catchError)
}

const patchCalculationConstant = ({
  budgetId,
  calculationConstantId,
  companyCode,
  customerCode,
  definedInScope,
  patch,
}) => {
  const path = `${createPathFromDefinedInScope({
    budgetId,
    companyCode,
    customerCode,
    definedInScope,
  })}/${calculationConstantId}`

  return axios
    .patch(path, patch, config())
    .then(returnData)
    .catch(catchError)
}

const calculateConstantApi = {
  getCalculationConstants,
  createCalculationConstant,
  deleteCalculationConstant,
  patchCalculationConstant,
}

export default calculateConstantApi
