/*
 *
 * BudgetMonitor constants
 *
 */
export const GET_BUDGET_BALANCE_FOR_ROW =
  'app/BudgetMonitor/GET_BUDGET_BALANCE_FOR_ROW'
export const GET_BUDGET_BALANCE_FOR_ROW_ERROR =
  'app/BudgetMonitor/GET_BUDGET_BALANCE_FOR_ROW_ERROR'
export const GET_BUDGET_BALANCE_FOR_ROW_SUCCESS =
  'app/BudgetMonitor/GET_BUDGET_BALANCE_FOR_ROW_SUCCESS'

export const SYNC_BUDGET = 'app/BudgetMonitor/SYNC_BUDGET'
export const SYNC_BUDGET_SUCCESS = 'app/BudgetMonitor/SYNC_BUDGET_SUCCESS'
export const SYNC_BUDGET_ERROR = 'app/BudgetMonitor/SYNC_BUDGET_ERROR'

export const GET_BUDGET_BALANCE_TREE_SUCCESS =
  'app/BudgetMonitor/GET_BUDGET_BALANCE_TREE_SUCCESS'

export const GET_BUDGET_MONITOR_PROGRESS_SUCCESS =
  'app/BudgetMonitor/GET_BUDGET_MONITOR_PROGRESS_SUCCESS'

export const SHOW_BUDGET_MONITOR_ROW_NOTES =
  'app/Budget/SHOW_BUDGET_MONITOR_ROW_NOTES'
