import PropTypes from 'prop-types'

import React from 'react'
import { BsFillStopFill } from 'react-icons/bs'
const isDimension = (item) => (item.values ? true : false)
//this gets either dimensions or their values
const TagItem = (props) => {
  const { color, path } = props.item
  const name = path.join(' ')
  const style = `color-${color}`

  return (
    <span className={isDimension(props.item) ? ' selectableDimension' : ''}>
      <BsFillStopFill className={style} />
      {name}
    </span>
  )
}

TagItem.propTypes = {
  item: PropTypes.object,
  onDelete: PropTypes.func,
  disableDelete: PropTypes.bool,
}

export default TagItem
