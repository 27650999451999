import { createSelector } from 'reselect'

const selectNotificationsDomain = (state) => state.get('notifications')

const selectNotifications = createSelector(
  selectNotificationsDomain,
  (domain) => domain?.get('notifications')
)

export default selectNotifications
export { selectNotifications }
