/*
 *
 * DatasourceContainer constants
 *
 */

export const GET_DATASOURCES = 'app/DatasourceContainer/GET_DATASOURCES'
export const GET_DATASOURCES_ERROR =
  'app/DatasourceContainer/GET_DATASOURCES_ERROR'
export const GET_DATASOURCES_SUCCESS =
  'app/DatasourceContainer/GET_DATASOURCES_SUCCESS'

export const DEFAULT_BUDGET = { name: 'Use default budget', id: null }
