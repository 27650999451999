/*
 *
 * Role actions
 *
 */

import {
  CREATE_ROLE_CLAIM,
  CREATE_ROLE_CLAIM_ERROR,
  CREATE_ROLE_CLAIM_SUCCESS,
  DELETE_ROLE_CLAIM,
  DELETE_ROLE_CLAIM_ERROR,
  DELETE_ROLE_CLAIM_SUCCESS,
  GET_ROLE_CLAIMS,
  GET_ROLE_CLAIMS_ERROR,
  GET_ROLE_CLAIMS_SUCCESS,
} from './constants'

export const createRoleClaims = ({ role, claims }) => ({
  type: CREATE_ROLE_CLAIM,
  role,
  claims,
})
export const createRoleClaimError = (error) => ({
  type: CREATE_ROLE_CLAIM_ERROR,
  error
})
export const createRoleClaimSuccess = ({ roleId, claims }) => ({
  type: CREATE_ROLE_CLAIM_SUCCESS,
  roleId,
  claims,
})
export const deleteRoleClaim = ({ role, claims }) => ({
  type: DELETE_ROLE_CLAIM,
  role,
  claims,
})
export const deleteRoleClaimError = () => ({
  type: DELETE_ROLE_CLAIM_ERROR,
})
export const deleteRoleClaimSuccess = ({ roleId, claims }) => ({
  type: DELETE_ROLE_CLAIM_SUCCESS,
  roleId,
  claims,
})

export const getRoleClaims = ({ roleId }) => ({
  type: GET_ROLE_CLAIMS,
  roleId,
})

export const getRoleClaimsError = () => ({
  type: GET_ROLE_CLAIMS_ERROR,
})

export const getRoleClaimsSuccess = ({ claims, roleId }) => ({
  type: GET_ROLE_CLAIMS_SUCCESS,
  claims,
  roleId,
})
