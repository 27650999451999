import { Record } from 'immutable'

const ParsedAccountRecord = Record({
  accountNumber: undefined,
  name: undefined,
  errors: undefined,
  reportingCode: undefined,
  rowNumber: undefined,
  isConsolidatedAccount: false,
  targetCompanyId: null,
})

export default ParsedAccountRecord
