/*
 * Button Messages
 *
 * This contains all the text for the Button2 component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  back: {
    id: 'app.containers.Budget.back',
    defaultMessage: 'Back',
  },
  cancel: {
    id: 'app.components.CancelButton.text',
    defaultMessage: 'Cancel',
    description: 'Cancel Button Text',
  },
  create: {
    id: 'app.components.CreateButton.text',
    defaultMessage: 'Create',
    description: 'Create Button Text',
  },
  confirm: {
    id: 'app.components.ConfirmButton.text',
    defaultMessage: 'Confirm',
    description: 'Confirm Button Text',
  },
  delete: {
    id: 'app.components.DeleteButton.text',
    defaultMessage: 'Delete',
    description: 'Delete Button Text',
  },
  deleteModalTitleWithItemType: {
    id: 'app.components.DeleteModal.modalTitleWithItemType',
    defaultMessage: 'Delete {itemType}',
    description: 'Delete Modal Title With Item Type',
  },
  deleteModalTitle: {
    id: 'app.components.DeleteModal.modalTitle',
    defaultMessage: 'Delete',
    description: 'Delete Modal Title',
  },
  deleteModalDefaultMessageWithItemType: {
    id: 'app.components.DeleteModal.defaultMessageWithItemType',
    defaultMessage:
      'Deleting "{itemType}" will permanently remove it from Finazilla.',
    description: 'Delete Modal Text With Item Type',
  },
  deleteModalDefaultMessage: {
    id: 'app.components.DeleteModal.defaultMessage',
    defaultMessage: 'Delete will permanently remove item from Finazilla.',
    description: 'Delete Modal Text',
  },
  edit: {
    id: 'app.components.CancelButton.Text',
    defaultMessage: 'Edit',
    description: 'Edit Button Text',
  },
  new: {
    id: 'app.components.NewButton.text',
    defaultMessage: 'New',
    description: 'New Button Text',
  },
  copy: {
    id: 'app.components.CopyButton.text',
    defaultMessage: 'Copy',
    description: 'Copy Button Text',
  },
  ok: {
    id: 'app.components.OkButton.text',
    defaultMessage: 'OK',
    description: 'OK Button Text',
  },
  save: {
    id: 'app.components.SaveButton.text',
    defaultMessage: 'Save',
    description: 'Save Button Text',
  },
  submit: {
    id: 'app.components.SaveButton.text',
    defaultMessage: 'Submit',
    description: 'Submit Button Text',
  },
  update: {
    id: 'app.components.UpdateButton.text',
    defaultMessage: 'Update',
    description: 'Update Button Text',
  },
  select: {
    id: 'app.components.SelectButton.text',
    defaultMessage: 'Select',
    description: 'Select Button Text',
  },
  expandAll: {
    id: 'app.containers.Budget.expandAll',
    defaultMessage: 'Expand all',
  },
  exportBudget: {
    id: 'app.containers.Budget.exportBudget',
    defaultMessage: 'Export balances to CSV',
  },
  collapseAll: {
    id: 'app.containers.Budget.collapseAll',
    defaultMessage: 'Collapse all',
  },
  rollingRules: {
    id: 'app.containers:Budget.rollingRules',
    defaultMessage: 'Rolling rules',
  },
  importInternal: {
    id: 'app.containers.Budget.importInternal',
    defaultMessage: 'Import balances',
  },
  importExternal: {
    id: 'app.containers.Budget.importExternal',
    defaultMessage: 'Import balances from CSV',
  },
  monitor: {
    id: 'app.containers.Budget.monitor',
    defaultMessage: 'All dimension targets view',
  },
  notes: {
    id: 'app.containers.Budget.notes',
    defaultMessage: 'Notes',
  },
  sync: {
    id: 'app.containers.Budget.sync',
    defaultMessage: 'Synchronize accounts from account scheme',
  },
  filter: {
    id: 'app.containers.Budget.filter',
    defaultMessage: 'Filter out rows with no input or voucher data',
  },
  newRow: {
    id: 'app.containers.Budget.newrow',
    defaultMessage: 'Add new row',
  },
  showFixed: {
    id: 'app.containers.Budget.showFixed',
    defaultMessage: 'Show fixed column',
  },
  hideFixed: {
    id: 'app.containers.Budget.hideFixed',
    defaultMessage: 'Hide fixed column',
  },
  refresh: {
    id: 'app.containers.Budget.refresh',
    defaultMessage: 'Refresh page',
  },
  addTags: {
    id: 'app.components.Button.TagsButton.addTags',
    description: 'Aria-label for the button that opens the tags',
    defaultMessage: 'Add tags',
  },
  singleDimensionView: {
    id: 'app.components.Button.singleDimensionView',
    defaultMessage: 'Return to normal view',
  },
})
