import moment from 'moment'
import { includeCompareAll } from 'utils/yupMethods'
import * as yup from 'yup'

includeCompareAll()

export const convertDateTimeToString = (dateTime, format = 'YYYY-MM-DD') => {
  if (dateTime) {
    return moment(dateTime).format(format)
  }

  return null
}

export const convertUTCTimeToLocal = (dateTime, format = 'YYYY-MM-DD') => {
  var date = moment.utc(dateTime).format(format)
  var stillUtc = moment.utc(date).toDate()
  var local = moment(stillUtc)
    .local()
    .format(format)
  return local
}

export const lastDayOfMonth = (dateTime, format = 'YYYY-MM-DD') => {
  if (dateTime) {
    return dateTime.endOf('month').format(format)
  }
}

export const convertUTCDateTimeToString = (dateTime, format = 'YYYY-MM-DD') => {
  if (dateTime) {
    return moment.utc(dateTime).format(format)
  }

  return null
}

export const createDateValidation = ({
  date,
  minDate,
  maxDate,
  minDateError,
  maxDateError,
  errorMessage,
}) => {
  if (date) {
    return yup
      .date()
      .min(minDate, minDateError)
      .max(maxDate, maxDateError)
      .nullable()
      .typeError(errorMessage)
      .default(moment(date).toDate())
  }

  return yup
    .date()
    .min(minDate)
    .max(maxDate)
    .nullable()
    .typeError(errorMessage)
}

export const createDateComparisonValidation = ({
  date,
  refDate,
  errorMessage,
}) =>
  yup
    .date()
    .nullable()
    .compareAll(
      [yup.ref('startDate'), yup.ref(refDate), yup.ref('endDate')],
      errorMessage,
      ([startDate, refDateValue, endDate]) =>
        (refDateValue == null ||
          startDate == null ||
          startDate <= refDateValue) &&
        (refDateValue == null || endDate == null || refDateValue <= endDate)
    )
    .typeError(errorMessage)
    .default(date ? moment(date).toDate() : undefined)
