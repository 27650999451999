import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'

import { handleError } from 'api/api-utils'
import api from 'api/BudgetTagApi'

import {
  createBudgetTagError,
  createBudgetTagSuccess,
  getBudgetTagsError,
  getBudgetTagsSuccess,
  removeBudgetTagError,
  removeBudgetTagSuccess,
  updateBudgetTagError,
  updateBudgetTagSuccess,
} from './actions'
import {
  GET_BUDGET_TAGS,
  REMOVE_BUDGET_TAG,
  CREATE_BUDGET_TAG,
  UPDATE_BUDGET_TAG,
} from './constants'

const UpdateBudgetTagRecord = Record({
  name: undefined,
  forecolor: undefined,
  backcolor: undefined,
  symbol: undefined,
})

export function* getBudgetTags(action) {
  const { companyCode, budgetId } = action
  try {
    const tags = yield call(api.getBudgetTags, { companyCode, budgetId })
    yield put(getBudgetTagsSuccess({ budgetId, tags }))
  } catch (error) {
    yield put(handleError(error, getBudgetTagsError))
  }
}

export function* createBudgetTag(action) {
  const { companyCode, budgetId, name, forecolor, backcolor, symbol } = action
  try {
    const tag = yield call(api.createBudgetTag, {
      companyCode,
      budgetId,
      tag: { name, forecolor, backcolor, symbol, budgetId },
    })
    yield put(createBudgetTagSuccess({ budgetId, tag }))
  } catch (error) {
    yield put(handleError(error, createBudgetTagError))
  }
}

export function* updateBudgetTag(action) {
  const { companyCode, budgetId, oldTag, newTag } = action
  const patch = Diff(
    new UpdateBudgetTagRecord(oldTag),
    new UpdateBudgetTagRecord(newTag)
  ).toJS()

  try {
    const tag = yield call(api.patchBudgetTag, {
      companyCode,
      budgetId,
      tagId: oldTag.id,
      patch,
    })
    yield put(updateBudgetTagSuccess({ budgetId, tag }))
  } catch (error) {
    yield put(handleError(error, updateBudgetTagError))
  }
}

export function* removeBudgetTag(action) {
  const { companyCode, budgetId, tagId } = action
  try {
    yield call(api.deleteBudgetTag, { companyCode, budgetId, tagId })
    yield put(removeBudgetTagSuccess({ budgetId, tagId }))
  } catch (error) {
    yield put(handleError(error, removeBudgetTagError))
  }
}

// Individual exports for testing
export function* tagsSaga() {
  yield all([
    takeLatest(GET_BUDGET_TAGS, getBudgetTags),
    takeEvery(CREATE_BUDGET_TAG, createBudgetTag),
    takeEvery(REMOVE_BUDGET_TAG, removeBudgetTag),
    takeLatest(UPDATE_BUDGET_TAG, updateBudgetTag),
  ])
}

// All sagas to be loaded
export default tagsSaga
