import { call, put, takeLatest } from 'redux-saga/effects'

import cashInHandApi from 'api/CashInHandApi'

import { setCashInHandSuccess, setCashInHandError } from './actions'
import { getCashFlow } from 'containers/CashFlowContainer/actions'
import { SET_CASH_IN_HAND } from './constants'

export function* setCashInHand(action) {
  const { companyCode, amount } = action
  try {
    yield call(cashInHandApi.setCashInHand, {
      companyCode,
      amount,
    })
    yield put(setCashInHandSuccess({ amount }))
    yield put(getCashFlow({ companyCode }))
  } catch (error) {
    yield put(setCashInHandError({ error }))
  }
}

export function* cashInHandSaga() {
  yield takeLatest(SET_CASH_IN_HAND, setCashInHand)
}

export default cashInHandSaga
