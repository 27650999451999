/*
 *
 * Voucher constants
 *
 */

export const GET_VOUCHER = 'app/Voucher/GET_VOUCHER'
export const GET_VOUCHER_ERROR = 'app/Voucher/GET_VOUCHER_ERROR'
export const GET_VOUCHER_SUCCESS = 'app/Voucher/GET_VOUCHER_SUCCESS'

export const UPDATE_VOUCHER = 'app/Voucher/UPDATE_VOUCHER'
export const UPDATE_VOUCHER_ERROR = 'app/Voucher/UPDATE_VOUCHER_ERROR'
export const UPDATE_VOUCHER_SUCCESS = 'app/Voucher/UPDATE_VOUCHER_SUCCESS'
