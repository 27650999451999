export const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: 'fa-bold' },
  { label: 'Italic', style: 'ITALIC', icon: 'fa-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'fa-underline' },
]

export const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote', icon: 'fa-quote-right' },
  { label: 'UL', style: 'unordered-list-item', icon: 'fa-list-ul' },
  { label: 'OL', style: 'ordered-list-item', icon: 'fa-list-ol' },
]

export const RICH_TEXT_EDITOR_STATUS_NORMAL = 'normal'
export const RICH_TEXT_EDITOR_STATUS_DISABLED = 'disabled'
export const RICH_TEXT_EDITOR_STATUS_ERROR = 'error'
export const RICH_TEXT_EDITOR_STATUS_PROCESSING = 'processing'
