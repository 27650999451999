/*
 *
 * IntegrationSourceSystems constants
 *
 */

export const GET_INTEGRATION_SOURCE_SYSTEMS =
  'app/IntegrationSourceSystems/GET_INTEGRATION_SOURCE_SYSTEMS'

export const GET_INTEGRATION_SOURCE_SYSTEMS_ERROR =
  'app/IntegrationSourceSystems/GET_INTEGRATION_SOURCE_SYSTEMS_ERROR'
export const GET_INTEGRATION_SOURCE_SYSTEMS_SUCCESS =
  'app/IntegrationSourceSystems/GET_INTEGRATION_SOURCE_SYSTEMS_SUCCESS'

export const CREATE_INTEGRATION_SOURCE_SYSTEM =
  'app/IntegrationSourceSystems/CREATE_INTEGRATION_SOURCE_SYSTEM'
export const DELETE_INTEGRATION_SOURCE_SYSTEM =
  'app/IntegrationSourceSystems/DELETE_INTEGRATION_SOURCE_SYSTEM'
export const DELETE_INTEGRATION_SOURCE_SYSTEM_ERROR =
  'app/IntegrationSourceSystems/DELETE_INTEGRATION_SOURCE_SYSTEM_ERROR'
export const DELETE_INTEGRATION_SOURCE_SYSTEM_SUCCESS =
  'app/IntegrationSourceSystems/DELETE_INTEGRATION_SOURCE_SYSTEM_SUCCESS'
export const UPDATE_INTEGRATION_SOURCE_SYSTEM =
  'app/IntegrationSourceSystems/UPDATE_INTEGRATION_SOURCE_SYSTEM'

export const CUMULATIVE_FOREVER = 'cumulativeForever'
export const CUMULATIVE_FOR_ACCOUNTING_PERIOD = 'cumulativeForAccountingPeriod'
export const DO_NOT_CALCULATE = 'doNotCalculate'
export const OPENING_BALANCE_PER_MONTH = 'openingBalancePerMonth'

export const BALANCE_TYPES = [
  CUMULATIVE_FOREVER,
  CUMULATIVE_FOR_ACCOUNTING_PERIOD,
  DO_NOT_CALCULATE,
  OPENING_BALANCE_PER_MONTH,
]

export const MICROSERVICE = 'microservice'
export const INTEGRATIONSERViCE = 'integrationService'

export const IMPLEMENTATION_TYPES = [INTEGRATIONSERViCE, MICROSERVICE]
