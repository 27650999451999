import { Children, Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import { selectUserTheme } from 'containers/App/selectors'
const DEFAULT_THEME = 'light'
/**
 * Adds theme to the context
 */
class ThemeProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    theme: PropTypes.string,
  }

  static childContextTypes = {
    theme: PropTypes.string,
  }

  getChildContext() {
    return {
      theme: this.props.theme ?? DEFAULT_THEME,
    }
  }

  componentDidMount() {
    const { theme } = this.props ?? DEFAULT_THEME
    this.setTheme(theme)
  }

  componentDidUpdate(prevProps) {
    const hasThemeChanged = prevProps.theme !== this.props.theme
    if (hasThemeChanged === false) {
      return
    }

    this.setTheme(this.props.theme)
  }

  UNSAFE_componentWillReceiveProps() {
    this.removeThemeClasses()
  }

  setTheme(theme) {
    if (theme === '') theme = DEFAULT_THEME
    this.removeThemeClasses()
    this.addThemeClass(theme)
  }

  removeThemeClasses() {
    this.removeThemeClass('light')
    this.removeThemeClass('dark')
  }

  hasThemeClass(themeToCheck) {
    return document.body.classList.contains(themeToCheck)
  }

  removeThemeClass(themeClassToRemove) {
    document.body.classList.remove(themeClassToRemove)
  }

  addThemeClass(themeClassToAdd) {
    document.body.classList.add(themeClassToAdd)
  }

  render() {
    return Children.only(this.props.children)
  }
}

const mapStateToProps = createStructuredSelector({
  theme: selectUserTheme,
})

export default connect(mapStateToProps)(ThemeProvider)
