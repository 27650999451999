import { createSelector } from 'reselect'

const selectDialogsDomain = () => (state) => state.get('dialogs')

/* Alert */

const selectAlertDialog = () =>
  createSelector(
    selectDialogsDomain(),
    (state) => state.get('alert')
  )

const selectShowAlert = () =>
  createSelector(
    selectAlertDialog(),
    (state) => state.get('show')
  )

const selectAlertTitle = () =>
  createSelector(
    selectAlertDialog(),
    (state) => state.get('alert')
  )

const selectAlertDetails = () =>
  createSelector(
    selectAlertDialog(),
    (state) => state.get('details')
  )

/* Confirm */

const selectConfirmDialog = () =>
  createSelector(
    selectDialogsDomain(),
    (state) => state.get('confirm')
  )

const selectShowConfirm = () =>
  createSelector(
    selectConfirmDialog(),
    (state) => state.get('show')
  )

const selectConfirmTitle = () =>
  createSelector(
    selectConfirmDialog(),
    (state) => state.get('title')
  )

const selectConfirmDetails = () =>
  createSelector(
    selectConfirmDialog(),
    (state) => state.get('details')
  )

/* Prompt */

const selectPromptDialog = () =>
  createSelector(
    selectDialogsDomain(),
    (state) => state.get('prompt')
  )

const selectShowPrompt = () =>
  createSelector(
    selectPromptDialog(),
    (state) => state.get('show')
  )

const selectPromptTitle = () =>
  createSelector(
    selectPromptDialog(),
    (state) => state.get('title')
  )

const selectPromptDetails = () =>
  createSelector(
    selectPromptDialog(),
    (state) => state.get('details')
  )

export {
  selectShowAlert,
  selectAlertTitle,
  selectAlertDetails,
  selectShowConfirm,
  selectConfirmTitle,
  selectConfirmDetails,
  selectShowPrompt,
  selectPromptTitle,
  selectPromptDetails,
}
