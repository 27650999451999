import { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { getDimensionSearch } from 'containers/Dimensions/actions'

import { selectDimensionSearchLoading } from 'containers/Dimensions/selectors'

import useDebounce from './useDebounce'

//Tool to perform dimension search. Takes parameter to include operative dimensions insearch.
//Returns [setSearchTerm(searchString), isCurrentlySearching, currentSearchTerms]
export default function useDimensionSearch({
  companyCode,
  debounce = 500,
  includeOperative = false,
  limit = 2000,
} = {}) {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, debounce)

  const dispatch = useDispatch()
  const isSearching = useSelector(selectDimensionSearchLoading({ companyCode }))

  useEffect(() => {
    if (debouncedSearchTerm && companyCode) {
      dispatch(
        getDimensionSearch({
          companyCode,
          limit,
          includeOperative,
          search: debouncedSearchTerm,
        })
      )
    }
  }, [debouncedSearchTerm, companyCode, dispatch, includeOperative, limit])

  return [setSearchTerm, isSearching, searchTerm]
}
