import qs from 'qs'
import token from './TokenStorage'

export const ERROR_401 = 'app/utils/ERROR_401'

export const ERROR_UNAUTHORIZED = 'unauthorized'
export const ERROR_SERVER_ERROR = 'server-error'
export const ERROR_NOT_FOUND = 'not-found'
export const ERROR_BAD_REQUEST = 'bad-request'

export const apiConfig = {
  backendUrl: process.env.REACT_APP_BACKEND_API,
  gtUrl: process.env.REACT_APP_GT_API,
}

export const statusCodeToError = (statusCode) => {
  switch (statusCode) {
    case 400:
      return ERROR_BAD_REQUEST
    case 401:
    case 403:
      return ERROR_UNAUTHORIZED
    case 404:
      return ERROR_NOT_FOUND
    case 500:
      return ERROR_SERVER_ERROR
    default:
      return ERROR_SERVER_ERROR
  }
}

export const handleError = (error, failedAction, additionalData = {}) => {
  const errorType = statusCodeToError(error?.status || error?.data?.status)
  const { error401, otherError } = parseError(error)
  if (error401) {
    return {
      type: ERROR_401,
    }
  }
  const returnValue = failedAction({
    ...otherError,
    errorType,
    ...additionalData,
  })
  return returnValue
}

export const catchError = (error) => {
  if (error.response) {
    return Promise.reject(error.response)
  }
  return Promise.reject(error)
}

export const config = (override, apiUrl = apiConfig.backendUrl) => {
  try {
    if (apiUrl) {
      const { access_token } = token.getTokens()
      return Object.assign(
        {},
        {
          baseURL: apiUrl,
          headers: {
            Authorization: `Bearer ${access_token}`,
            'x-finazilla.request.client.user.guid': token.getProfileId(),
          },
        },
        override
      )
    } else {
      throw new Error(
        'API URL undefined. Add REACT_APP_BACKEND_API environment variable and run build again.'
      )
    }
  } catch (error) {
    console.error(error)
  }
}

export const paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' })

export const parseError = (err) => {
  if (err.status === 401) return { error401: true }
  return err instanceof Error
    ? {
        otherError: {
          jsError: err.toString(),
        },
      }
    : {
        otherError: {
          ...err,
        },
      }
}

export const returnData = (axiosResponse) =>
  axiosResponse.data.data !== undefined
    ? axiosResponse.data.data
    : axiosResponse.data

export const NUM_ONLY_REGEX = /^([0-9]+|Infinity)$/
export const NUM_AND_CHAR_REGEX = /^[0-9A-Za-z._&]{1,9999999999}$/
export const accountNumberRange = {
  MAX: 999999,
  MIN: 1000,
}
