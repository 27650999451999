/*
 *
 * AccountSchemes actions
 *
 */

import {
  CANCEL_CREATE_ACCOUNT_SCHEME,
  CREATE_ACCOUNT_SCHEME,
  CREATE_ACCOUNT_SCHEME_ERROR,
  CREATE_ACCOUNT_SCHEME_SUCCESS,
  EDIT_ACCOUNT_SCHEME,
  DELETE_ACCOUNT_SCHEME,
  DELETE_ACCOUNT_SCHEME_ERROR,
  DELETE_ACCOUNT_SCHEME_SUCCESS,
  GET_ACCOUNT_SCHEMES,
  GET_ACCOUNT_SCHEMES_ERROR,
  GET_ACCOUNT_SCHEMES_SUCCESS,
  RESET_STATE,
  SET_DEFAULT_ACCOUNT_SCHEME,
  SET_DEFAULT_ACCOUNT_SCHEME_ERROR,
  SET_DEFAULT_ACCOUNT_SCHEME_SUCCESS,
  TOGGLE_SHOW_CREATE_ACCOUNT_SCHEME,
  UPDATE_ACCOUNT_SCHEME,
  UPDATE_ACCOUNT_SCHEME_ERROR,
  UPDATE_ACCOUNT_SCHEME_SUCCESS,
} from './constants'

export const cancelCreateAccountScheme = () => ({
  type: CANCEL_CREATE_ACCOUNT_SCHEME,
})

export const createAccountScheme = ({
  accountSchemeTemplateId,
  companyCode,
  mapReportingCodes,
  name,
}) => ({
  type: CREATE_ACCOUNT_SCHEME,
  accountSchemeTemplateId,
  companyCode,
  mapReportingCodes,
  name,
})
export const createAccountSchemeError = (error) => ({
  type: CREATE_ACCOUNT_SCHEME_ERROR,
  error,
})
export const createAccountSchemeSuccess = ({ accountScheme }) => ({
  type: CREATE_ACCOUNT_SCHEME_SUCCESS,
  accountScheme,
})

export const editAccountScheme = ({ companyCode, id, name }) => ({
  type: EDIT_ACCOUNT_SCHEME,
  companyCode,
  id,
  name,
})

export const deleteAccountScheme = ({ companyCode, id }) => ({
  type: DELETE_ACCOUNT_SCHEME,
  companyCode,
  id,
})
export const deleteAccountSchemeError = (error) => ({
  type: DELETE_ACCOUNT_SCHEME_ERROR,
  error,
})
export const deleteAccountSchemeSuccess = ({ id }) => ({
  type: DELETE_ACCOUNT_SCHEME_SUCCESS,
  id,
})

export const getAccountSchemes = ({ companyCode }) => ({
  type: GET_ACCOUNT_SCHEMES,
  companyCode,
})
export const getAccountSchemesError = (error) => ({
  type: GET_ACCOUNT_SCHEMES_ERROR,
  error,
})
export const getAccountSchemesSuccess = ({ accountSchemes }) => ({
  type: GET_ACCOUNT_SCHEMES_SUCCESS,
  accountSchemes,
})
export const resetAccountSchemesState = () => ({
  type: RESET_STATE,
})
export const setDefaultAccountScheme = ({ companyCode, id }) => ({
  type: SET_DEFAULT_ACCOUNT_SCHEME,
  companyCode,
  id,
})
export const setDefaultAccountSchemeError = (error) => ({
  type: SET_DEFAULT_ACCOUNT_SCHEME_ERROR,
  error,
})
export const setDefaultAccountSchemeSuccess = ({ id }) => ({
  type: SET_DEFAULT_ACCOUNT_SCHEME_SUCCESS,
  id,
})

export const toggleShowCreateAccountScheme = () => ({
  type: TOGGLE_SHOW_CREATE_ACCOUNT_SCHEME,
})

export const updateAccountScheme = ({ companyCode, id, name }) => ({
  type: UPDATE_ACCOUNT_SCHEME,
  companyCode,
  id,
  name,
})
export const updateAccountSchemeError = (error) => ({
  type: UPDATE_ACCOUNT_SCHEME_ERROR,
  error,
})
export const updateAccountSchemeSuccess = ({ accountScheme }) => ({
  type: UPDATE_ACCOUNT_SCHEME_SUCCESS,
  accountScheme,
})
