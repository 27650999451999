import { isInteger, isString } from 'lodash'

/**
 *  Checks if value matches to enum value or enum name. This works for "object enums"
 *
 * @param {string|number} value
 * @param {object} enumeration
 * @param {string} enumToMatch
 */
export const isValueEnumMatch = (value, enumeration, enumToMatch) => {
  if (enumeration[value] === enumToMatch) {
    return true
  }

  if (getKeyByObjectLiteralValue(enumeration, value)) {
    return true
  }

  return false
}

export const enumValueToEnumName = (value, enumeration) => {
  if (isInteger(value) === false) {
    throw new Error(`Value is not a number: ${value}`)
  }

  const name = enumeration[value]
  if (!name) {
    throw new Error(`Could not find value with value ${value}`)
  }

  return name
}

export const enumNameToEnumValue = (name, enumeration) => {
  if (!name || isString(name) === false) {
    throw new Error(`Name is not valid: ${name}`)
  }

  const key = getKeyByObjectLiteralValue(enumeration, name)
  if (isInteger(Number(key)) === false) {
    throw new Error(
      `Could not get number presentation for the enum value: ${key}`
    )
  }
  return Number(key)
}

export const objectEnumToList = (
  enumeration,
  keyPropertyName,
  valuePropertyName
) =>
  Object.keys(enumeration).map((key) => ({
    [keyPropertyName]: Number(key),
    [valuePropertyName]: enumeration[key],
  }))

const getKeyByObjectLiteralValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value)
