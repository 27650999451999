import { Record } from 'immutable'

const SchemeGroupRecord = Record({
  account: undefined,
  accountGroupType: undefined,
  accountSchemeRowId: undefined,
  accountId: undefined,
  actualAccounts: undefined,
  actualAccountIds: undefined,
  actualAccountGroupIds: undefined,
  actualAccountGroups: undefined,
  actualAccountRangeSchemeRows: undefined,
  children: undefined,
  formula: '',
  hasData: false,
  accountHasVoucherData: false,
  id: undefined,
  inputType: undefined,
  level: undefined,
  name: '',
  normalizedFormula: '',
  editable: false,
  operativeDimensionValues: undefined,
  additionalDimensionValues: undefined,
  inheritedOperativeDimensionValues: undefined,
  inheritedAdditionalDimensionValues: undefined,
  formulaReferenceDimensionValues: undefined,
  dataGroupId: undefined,
  order: undefined,
  path: '',
  rowFactor: 1,
  rowId: '',
  rowType: '',
  rollingInputDimensionTarget: undefined,
  rollingRuleId: undefined,
  sourceBudget: undefined,
  sourceCompany: undefined,
  tagIds: undefined,
  tags: undefined,
  importBudgetId: undefined,
  importBudgetRowId: undefined,
  importBudgetCompany: undefined,
  importBudgetName: undefined,
  importBudgetRowName: undefined,
  importSubBudgetId: undefined,
  importSubBudgetRowId: undefined,
  importSubBudgetName: undefined,
  importSubBudgetRowName: undefined,
  includeAllDimensionValues: false,
  schemeValidationErrors: [],
})

export default SchemeGroupRecord
