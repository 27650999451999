import PropTypes from 'prop-types'
import { BsFillStopFill } from 'react-icons/bs'
import React from 'react'

const GroupComponent = (props) => {
  const color = `color-${props.item.group[0]}`
  const name = props.item.group[1]

  return (
    <div>
      <BsFillStopFill className={color}></BsFillStopFill>
      {name}
    </div>
  )
}
GroupComponent.propTypes = { item: PropTypes.object }

export default GroupComponent
