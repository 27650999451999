import { fromJS, List } from 'immutable'

import {
  GET_ACQUISITION_FORMS,
  GET_ACQUISITION_FORMS_ERROR,
  GET_ACQUISITION_FORMS_SUCCESS,
  GET_ALL_CREATED_ACQUISITIONS,
  GET_ALL_CREATED_ACQUISITIONS_ERROR,
  GET_ALL_CREATED_ACQUISITIONS_SUCCESS,
  DELETE_ACQUISITION,
  DELETE_ACQUISITION_ERROR,
  DELETE_ACQUISITION_SUCCESS,
} from './constants'

const initialState = fromJS({
  acquisitionForms: new List(),
  allCreatedAcquisitions: new List(),
  error: false,
  loading: false,
})

const acquisitionFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACQUISITION_FORMS:
    case GET_ALL_CREATED_ACQUISITIONS:
    case DELETE_ACQUISITION:
      return state.set('loading', true).set('error', false)
    case GET_ACQUISITION_FORMS_ERROR:
    case GET_ALL_CREATED_ACQUISITIONS_ERROR:
    case DELETE_ACQUISITION_ERROR:
      return state.set('error', action.error).set('loading', false)
    case GET_ACQUISITION_FORMS_SUCCESS:
      return state.set('acquisitionForms', action.forms).set('loading', false)
    case GET_ALL_CREATED_ACQUISITIONS_SUCCESS:
      return state
        .set('allCreatedAcquisitions', action.forms)
        .set('loading', false)
    case DELETE_ACQUISITION_SUCCESS:
      return state
        .update('acquisitionForms', (acquisitionForms) =>
          acquisitionForms.filter((form) => form.id !== action.id)
        )
        .set('loading', false)
    default:
      return state
  }
}

export default acquisitionFormReducer
