/*
 *
 * SubBudgets reducer
 *
 */

import { fromJS, List, Map } from 'immutable'
import { SubBudgetRecord } from 'records'
import { mapDimensionTarget } from '../Content/functions'
import {
  CREATE_SUB_BUDGET,
  CREATE_SUB_BUDGET_ERROR,
  CREATE_SUB_BUDGET_SUCCESS,
  CHANGE_SELECTED_COMPANY,
  DELETE_SUB_BUDGET,
  DELETE_SUB_BUDGET_ERROR,
  DELETE_SUB_BUDGET_SUCCESS,
  DELETE_SUB_BUDGETS_SUCCESS,
  GET_ALL_SUB_BUDGETS,
  GET_ALL_SUB_BUDGETS_ERROR,
  GET_ALL_SUB_BUDGETS_SUCCESS,
  GET_BUDGET_SUB_BUDGETS,
  GET_BUDGET_SUB_BUDGETS_ERROR,
  GET_BUDGET_SUB_BUDGETS_SUCCESS,
  EDIT_SUB_BUDGET,
  TOGGLE_SHOW_CREATE_SUB_BUDGET_FORM,
  UPDATE_SUB_BUDGET,
  UPDATE_SUB_BUDGET_ERROR,
  UPDATE_SUB_BUDGET_SUCCESS,
} from './constants'

const mapSubBudget = ({
  companyCode,
  subBudget: { dimensionTarget, ...rest },
}) =>
  new SubBudgetRecord({
    ...rest,
    companyCode,
    dimensionTarget: mapDimensionTarget(dimensionTarget),
  })

// `subBudgets` are mapped by budgetId
const initialState = fromJS({
  subBudgets: undefined,
  loading: false,
  error: false,
  createSubBudgetForm: {
    show: false,
    selectedCompany: undefined,
  },
  editSubBudgetId: undefined,
})

function subBudgetsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUB_BUDGET:
    case DELETE_SUB_BUDGET:
    case CREATE_SUB_BUDGET:
    case GET_ALL_SUB_BUDGETS:
    case GET_BUDGET_SUB_BUDGETS:
      return state.set('error', false).set('loading', true)

    case UPDATE_SUB_BUDGET_ERROR:
    case DELETE_SUB_BUDGET_ERROR:
    case CREATE_SUB_BUDGET_ERROR:
    case GET_ALL_SUB_BUDGETS_ERROR:
    case GET_BUDGET_SUB_BUDGETS_ERROR:
      return state.set('loading', false).set('error', action.error)

    case GET_BUDGET_SUB_BUDGETS_SUCCESS: {
      const companyCode = action.companyCode
      return state
        .set('loading', false)
        .set(
          'subBudgets',
          state
            .get('subBudgets')
            .set(
              action.budgetId,
              List(
                action.subBudgets.map((subBudget) =>
                  mapSubBudget({ subBudget, companyCode })
                )
              )
            )
        )
    }

    case CREATE_SUB_BUDGET_SUCCESS: {
      const companyCode = action.companyCode
      const subBudget = mapSubBudget({
        subBudget: action.subBudget,
        companyCode,
      })
      return state
        .set('loading', false)
        .updateIn(['subBudgets', action.budgetId], (list) =>
          list ? list.push(subBudget) : List([subBudget])
        )
        .setIn(['createSubBudgetForm', 'show'], false)
    }

    case GET_ALL_SUB_BUDGETS_SUCCESS: {
      const companyCode = action.companyCode
      const subBudgets = List(
        action.subBudgets.map((subBudget) =>
          mapSubBudget({ subBudget, companyCode })
        )
      )
      const subBudgetsById = subBudgets.groupBy((sb) => sb.budgetId)
      return state
        .set('loading', false)
        .set(
          'subBudgets',
          state.get('subBudgets')
            ? state.get('subBudgets').merge(subBudgetsById)
            : new Map(subBudgetsById)
        )
    }
    case DELETE_SUB_BUDGETS_SUCCESS: {
      return state
        .set('loading', false)
        .updateIn(['subBudgets', action.budgetId], () => List())
    }
    case DELETE_SUB_BUDGET_SUCCESS:
      return state
        .set('loading', false)
        .updateIn(['subBudgets', action.budgetId], (list) =>
          list.filter((sb) => sb.id !== action.subBudgetId)
        )

    case UPDATE_SUB_BUDGET_SUCCESS: {
      const companyCode = action.companyCode
      const subBudget = mapSubBudget({
        subBudget: action.subBudget,
        companyCode,
      })
      return state
        .set('loading', false)
        .set('editSubBudgetId', undefined)
        .updateIn(['subBudgets', action.budgetId], (list) =>
          list.map((sb) => (sb.id === subBudget.id ? subBudget : sb))
        )
    }

    case EDIT_SUB_BUDGET:
      return state.set('editSubBudgetId', action.subBudgetId)

    case TOGGLE_SHOW_CREATE_SUB_BUDGET_FORM: {
      const isGoingToBeShown = !state.getIn(['createSubBudgetForm', 'show'])
      return state
        .setIn(['createSubBudgetForm', 'show'], isGoingToBeShown)
        .setIn(
          ['createSubBudgetForm', 'selectedCompany'],
          isGoingToBeShown ? action.selectedCompany : undefined
        )
    }

    case CHANGE_SELECTED_COMPANY:
      return state.setIn(
        ['createSubBudgetForm', 'selectedCompany'],
        action.company
      )

    default:
      return state
  }
}

export default subBudgetsReducer
