/*
 *
 * Accounting Periods reducer
 *
 */
import { List, fromJS, Map } from 'immutable'

import {
  SET_ACTIVE_ACCOUNTING_PERIOD,
  SET_ACTIVE_ACCOUNTING_PERIOD_ERROR,
  SET_ACTIVE_ACCOUNTING_PERIOD_SUCCESS,
  CREATE_ACCOUNTING_PERIOD,
  CREATE_ACCOUNTING_PERIOD_ERROR,
  CREATE_ACCOUNTING_PERIOD_SUCCESS,
  DELETE_ACCOUNTING_PERIOD,
  DELETE_ACCOUNTING_PERIOD_ERROR,
  DELETE_ACCOUNTING_PERIOD_SUCCESS,
  EDIT_ACCOUNTING_PERIOD,
  GET_ACCOUNTING_PERIODS,
  GET_ACCOUNTING_PERIODS_SUCCESS,
  GET_ACCOUNTING_PERIODS_ERROR,
  UPDATE_ACCOUNTING_PERIOD,
  UPDATE_ACCOUNTING_PERIOD_ERROR,
  UPDATE_ACCOUNTING_PERIOD_SUCCESS,
} from './constants'

import { AccountingPeriodRecord } from 'records'

const setDeletableRights = (accountingPeriods) => {
  const lastIndex = accountingPeriods.size - 1
  return accountingPeriods.map((accountingPeriod, index) => {
    if ((index === 0 || index === lastIndex) && !accountingPeriod.active) {
      return accountingPeriod.set('deletable', true)
    }
    return accountingPeriod.set('deletable', false)
  })
}

const initialState = fromJS({
  accountingPeriods: new Map(),
  loading: false,
  edit: undefined,
  error: false,
})

export const accountingPeriodReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACCOUNTING_PERIOD:
    case DELETE_ACCOUNTING_PERIOD:
    case GET_ACCOUNTING_PERIODS:
    case SET_ACTIVE_ACCOUNTING_PERIOD:
      return state.set('loading', true).set('error', false)

    case CREATE_ACCOUNTING_PERIOD_ERROR:
    case DELETE_ACCOUNTING_PERIOD_ERROR:
    case GET_ACCOUNTING_PERIODS_ERROR:
    case SET_ACTIVE_ACCOUNTING_PERIOD_ERROR:
    case UPDATE_ACCOUNTING_PERIOD_ERROR:
      return state.set('loading', false).set('error', action.error)

    case CREATE_ACCOUNTING_PERIOD_SUCCESS: {
      let currentState = state
      if (
        !state.hasIn(['accountingPeriods', action.companyCode]) &&
        action.companyCode
      ) {
        currentState = state.setIn(
          ['accountingPeriods', action.companyCode],
          new List()
        )
      }

      const accountingPeriods = currentState
        .getIn(['accountingPeriods', action.companyCode])
        .push(new AccountingPeriodRecord(action.accountingPeriod))
        .sortBy((accountingPeriod) => accountingPeriod.start)

      return state
        .set('loading', false)
        .setIn(
          ['accountingPeriods', action.companyCode],
          setDeletableRights(accountingPeriods)
        )
    }

    case DELETE_ACCOUNTING_PERIOD_SUCCESS: {
      const { companyCode, id } = action
      const accountingPeriods = state
        .getIn(['accountingPeriods', companyCode])
        .filter((accountingPeriod) => accountingPeriod.id !== id)

      return state
        .set('loading', false)
        .setIn(
          ['accountingPeriods', companyCode],
          setDeletableRights(accountingPeriods)
        )
    }

    case EDIT_ACCOUNTING_PERIOD:
      return state.set('edit', action.id)

    case GET_ACCOUNTING_PERIODS_SUCCESS: {
      if (action.companyCode) {
        return state
          .set('loading', false)
          .setIn(
            ['accountingPeriods', action.companyCode],
            List(
              action.accountingPeriods.map(
                (accountingPeriod) =>
                  new AccountingPeriodRecord(accountingPeriod)
              )
            )
          )
      }
      return state.set('loading', false)
    }
    case SET_ACTIVE_ACCOUNTING_PERIOD_SUCCESS: {
      const { companyCode, id } = action
      const accountingPeriods = state.getIn(['accountingPeriods', companyCode])
      const currentIndex = accountingPeriods.findIndex(
        (accountingPeriod) => accountingPeriod.active
      )
      const newIndex = accountingPeriods.findIndex(
        (accountingPeriod) => accountingPeriod.id === id
      )

      return state
        .set('loading', false)
        .setIn(
          ['accountingPeriods', companyCode],
          setDeletableRights(
            accountingPeriods
              .updateIn([currentIndex, 'active'], () => false)
              .updateIn([newIndex, 'active'], () => true)
          )
        )
    }

    case UPDATE_ACCOUNTING_PERIOD:
      return state
        .set('loading', true)
        .set('error', false)
        .set('edit', undefined)

    case UPDATE_ACCOUNTING_PERIOD_SUCCESS: {
      const accountingPeriods = state.getIn([
        'accountingPeriods',
        action.companyCode,
      ])
      return state
        .set('loading', false)
        .setIn(
          ['accountingPeriods', action.companyCode],
          accountingPeriods.update(
            accountingPeriods.findIndex(
              (accountingPeriod) =>
                accountingPeriod.id === action.accountingPeriod.id
            ),
            () => new AccountingPeriodRecord(action.accountingPeriod)
          )
        )
    }

    default:
      return state
  }
}

export default accountingPeriodReducer
