import PropTypes from 'prop-types'
import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const OverlayPopoverWrapper = ({
  delayShow,
  placement,
  message,
  messageParams,
  ...props
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={
      <Popover id={message.id}>
        <FormattedMessage {...message} values={messageParams} />
      </Popover>
    }
    delayShow={delayShow}
    {...props}
  >
    {props.children}
  </OverlayTrigger>
)

OverlayPopoverWrapper.propTypes = {
  placement: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  delayShow: PropTypes.number,
}

export default OverlayPopoverWrapper
