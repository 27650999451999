import {
  TOGGLE_ITEM_SELECTION,
  TOGGLE_BRANCH,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  SET_TREE_ITEM_STATUS,
  SET_INITIAL_USER_SELECTIONS,
  RESET_TREE,
  EXPAND_BRANCH,
  SET_ITEM_SELECTION,
} from './constants'

export const getUsers = ({
  customerCode,
  companyCode,
  id,
  itemType,
  selectFetchedUsers = false,
}) => ({
  type: GET_USERS,
  id,
  itemType,
  customerCode,
  companyCode,
  selectFetchedUsers,
})

export const getUsersSuccess = ({
  users,
  companyCode,
  customerCode,
  selfId,
}) => ({
  type: GET_USERS_SUCCESS,
  users,
  customerCode,
  companyCode,
  selfId,
})

export const getUsersError = (error) => ({
  type: GET_USERS_ERROR,
  error,
})

export const toggleItemSelection = ({ id, itemType }) => ({
  type: TOGGLE_ITEM_SELECTION,
  id,
  itemType,
})

export const toggleBranch = (id) => ({
  type: TOGGLE_BRANCH,
  id,
})

export const expandBranch = (id) => ({
  type: EXPAND_BRANCH,
  id,
})

export const setItemSelection = ({ ids, isSelected }) => ({
  type: SET_ITEM_SELECTION,
  ids,
  isSelected,
})

export const setTreeItemStatus = ({ id, status, itemType }) => ({
  type: SET_TREE_ITEM_STATUS,
  id,
  status,
  itemType,
})

export const setInitialUserSelections = (userIds) => ({
  type: SET_INITIAL_USER_SELECTIONS,
  userIds,
})

export const resetTree = () => ({
  type: RESET_TREE,
})
