/*
 *
 * SubBudgetTags actions
 *
 */

import {
  GET_BUDGET_TAGS,
  GET_BUDGET_TAGS_ERROR,
  GET_BUDGET_TAGS_SUCCESS,
  EDIT_BUDGET_TAG,
  CREATE_BUDGET_TAG,
  CREATE_BUDGET_TAG_ERROR,
  CREATE_BUDGET_TAG_SUCCESS,
  REMOVE_BUDGET_TAG,
  REMOVE_BUDGET_TAG_ERROR,
  REMOVE_BUDGET_TAG_SUCCESS,
  UPDATE_BUDGET_TAG,
  UPDATE_BUDGET_TAG_ERROR,
  UPDATE_BUDGET_TAG_SUCCESS,
  TOGGLE_SHOW_CREATE_TAG_FORM,
} from './constants'

export const getBudgetTags = ({ companyCode, budgetId }) => ({
  type: GET_BUDGET_TAGS,
  companyCode,
  budgetId,
})
export const getBudgetTagsError = (error) => ({
  type: GET_BUDGET_TAGS_ERROR,
  error,
})
export const getBudgetTagsSuccess = ({ budgetId, tags }) => ({
  type: GET_BUDGET_TAGS_SUCCESS,
  budgetId,
  tags,
})

export const editBudgetTag = (id) => ({ type: EDIT_BUDGET_TAG, id })

export const createBudgetTag = ({
  companyCode,
  budgetId,
  name,
  backcolor,
  forecolor,
  symbol,
}) => ({
  type: CREATE_BUDGET_TAG,
  companyCode,
  budgetId,
  name,
  backcolor,
  forecolor,
  symbol,
})
export const createBudgetTagError = (error) => ({
  type: CREATE_BUDGET_TAG_ERROR,
  error,
})
export const createBudgetTagSuccess = ({ budgetId, tag }) => ({
  type: CREATE_BUDGET_TAG_SUCCESS,
  budgetId,
  tag,
})

export const removeBudgetTag = ({ companyCode, budgetId, tagId }) => ({
  type: REMOVE_BUDGET_TAG,
  companyCode,
  budgetId,
  tagId,
})
export const removeBudgetTagError = (error) => ({
  type: REMOVE_BUDGET_TAG_ERROR,
  error,
})
export const removeBudgetTagSuccess = ({ budgetId, tagId }) => ({
  type: REMOVE_BUDGET_TAG_SUCCESS,
  budgetId,
  tagId,
})

export const updateBudgetTag = ({ companyCode, budgetId, oldTag, newTag }) => ({
  type: UPDATE_BUDGET_TAG,
  companyCode,
  budgetId,
  oldTag,
  newTag,
})
export const updateBudgetTagError = (error) => ({
  type: UPDATE_BUDGET_TAG_ERROR,
  error,
})
export const updateBudgetTagSuccess = ({ budgetId, tag }) => ({
  type: UPDATE_BUDGET_TAG_SUCCESS,
  budgetId,
  tag,
})

export const toggleShowCreateTagForm = () => ({
  type: TOGGLE_SHOW_CREATE_TAG_FORM,
})
