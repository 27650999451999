import { all, call, put, takeEvery, take } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import reportApi from 'api/ReportApi'

import {
  getReportContextDataError,
  getReportContextDataSuccess,
  getReportContextDataProgressSuccess,
  invalidateReportContextDataCacheError,
  invalidateReportContextDataCacheSuccess,
} from './actions'
import {
  GET_REPORT_CONTEXT_DATA,
  INVALIDATE_REPORT_CONTEXT_DATA_CACHE,
} from './constants'
import { eventChannel } from 'redux-saga'
import { tryParseErrorJson } from 'utils/parseError'
import { END } from 'redux-saga'

let payloadCache = []
export function* getReportContextData(action) {
  const { companyCode, customerCode, enableCaching, reportId } = action
  const cacheKey = reportId
  if (payloadCache.includes(cacheKey)) return

  try {
    payloadCache = [...payloadCache, cacheKey]
    const channel = eventChannel((eventEmmit) => {
      const onMessage = (event) => {
        try {
          eventEmmit(
            getReportContextDataSuccess({
              report: JSON.parse(event.data).data,
              reportId,
              customerCode,
              companyCode,
            })
          )
          eventEmmit(END)
        } catch (error) {
          eventEmmit(
            handleError(error, getReportContextDataError, {
              reportId,
            })
          )
          eventEmmit(END)
        }
      }

      const onProgress = (event) => {
        eventEmmit(
          getReportContextDataProgressSuccess({
            progress: event.data,
            ...action,
          })
        )
      }

      const onError = (event) => {
        console.error('Error in getReportContextData', event)
        eventEmmit(
          handleError(
            { data: tryParseErrorJson(event.data) },
            getReportContextDataError,
            {
              reportId,
            }
          )
        )
        eventEmmit(END)
      }

      reportApi.getReport({
        companyCode,
        customerCode,
        enableCaching,
        reportId,
        onMessage,
        onProgress,
        onError,
      })

      return () => {}
    })
    while (true) {
      const event = yield take(channel)
      if (event === END) break
      yield put(event)
    }
  } catch (error) {
    yield put(handleError(error, getReportContextDataError, { reportId }))
  } finally {
    payloadCache = payloadCache.filter((key) => key !== cacheKey)
  }
}
export function* invalidateReportContextDataCache(action) {
  const { companyCode, reportId } = action
  try {
    yield call(reportApi.invalidateReport, { reportId })
    yield put(
      invalidateReportContextDataCacheSuccess({ companyCode, reportId })
    )
  } catch (error) {
    yield put(
      handleError(error, invalidateReportContextDataCacheError, { companyCode })
    )
  }
}

export function* reportContextDatasSaga() {
  yield all([
    takeEvery(GET_REPORT_CONTEXT_DATA, getReportContextData),
    takeEvery(
      INVALIDATE_REPORT_CONTEXT_DATA_CACHE,
      invalidateReportContextDataCache
    ),
  ])
}

export default reportContextDatasSaga
