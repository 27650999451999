import { List, Map } from 'immutable'
import { mapValues } from 'lodash'
import {
  RollingRule,
  AccountRangeValue,
  AccountRecord,
  RollingRuleSchemeTree,
  RollingRuleData,
  RollingRuleValue,
  ColumnSpecRecord,
} from 'records'

function mapAccountRangeValues(accountRanges) {
  const values = []
  let rowid = 1
  if (accountRanges) {
    accountRanges.forEach((c) => {
      values.push(new AccountRangeValue({ ...c, rowid }))
      rowid += 1
    })
  }
  return List(values)
}

export function createRollingRuleTree(tree) {
  if (!tree) return null
  return recurseMapTree(tree)
}

export function createRollingRule(rule) {
  if (rule) {
    let accountRanges = []
    if (rule.accountRanges) {
      accountRanges = mapAccountRangeValues(rule.accountRanges)
    }
    return new RollingRule({
      ...rule,
      accountRanges,
      dimensionId: rule.dimensionId
        ? parseInt(rule.dimensionId, 10)
        : undefined,
      sourceDimensionValueId: rule.sourceDimensionValueId
        ? parseInt(rule.sourceDimensionValueId, 10)
        : undefined,
    })
  }
  return null
}

export function createAccount(data) {
  return new AccountRecord(data)
}

export function mapRollingRules(rules) {
  const items = rules.map((c) => createRollingRule(c))
  return List(items)
}

export function mapAccounts(accounts) {
  const items = []
  accounts.forEach((c) => items.push(createAccount(c)))
  return List(items)
}

const mapRollingRuleColumns = (columns) =>
  List(columns.map((col) => new ColumnSpecRecord({ ...col })))

const mapRollingRuleRows = (rows) =>
  Map(
    mapValues(rows, (row) =>
      Map(mapValues(row, (col) => new RollingRuleValue(col)))
    )
  )

export function createRollingRuleValueFromPatch(patch) {
  /* RoliingRuleValue
    amount: undefined,
    rowId: '',
    columnId: '',
  */
  /* PATCH
    "replace"
    path:"/rows/dv7/rolling-default/amount"
    value:2
  */
  if (patch && patch.path) {
    const splitted = patch.path.split('/')
    if (splitted && splitted.length >= 4) {
      return new RollingRuleValue({
        amount: patch.value,
        rowId: splitted[2],
        columnId: splitted[3],
      })
    }
  }
  return null
}

export function patchData(list, values) {
  let items = []
  if (list) {
    items = list.toArray()

    values.forEach((rawpatch) => {
      const patch = createRollingRuleValueFromPatch(rawpatch)
      const index = items.findIndex(
        (c) => c.rowId === patch.rowId && c.columnId === patch.columnId
      )
      if (index < 0) {
        items.push(patch)
      } else {
        items[index] = patch
      }
    })
  }
  return new List(items)
}

export function createRollingRuleData(data) {
  if (data) {
    return new RollingRuleData({
      columns: mapRollingRuleColumns(data.columns),
      rows: mapRollingRuleRows(data.rows),
    })
  }

  return null
}

export function addOrReplace(list, value) {
  const index = list.findIndex((c) => c.id === value.id)
  return index === -1 ? list.push(value) : list.set(index, value)
}

// Rollingrules budget edit functions
export function mapRollingRulesScheme(tree) {
  return recurseMapTree(tree)
}

const recurseMapTree = (schemeGroup) => {
  const dimensionValueId = schemeGroup.dimensionValueId
    ? parseInt(schemeGroup.dimensionValueId, 10)
    : -1
  let mapped = new RollingRuleSchemeTree({
    ...schemeGroup,
    dimensionValueId,
  })
  mapped = mapped.set(
    'children',
    List(schemeGroup.children.map((x) => recurseMapTree(x)))
  )
  return mapped
}

export function mapRollingRulesData(data) {
  return createRollingRuleData(data)
}
