import { Record } from 'immutable'

const RecurringPaymentOccurenceRecord = Record({
  id: 0,
  recurringPaymentId: 0,
  amount: null,
  nthOccurence: 0,
  isRemoved: false,
})

export default RecurringPaymentOccurenceRecord
