import { Record } from 'immutable'

const parsedBudgetBalanceRecord = Record({
  accountNumber: undefined,
  year: undefined,
  month: undefined,
  amount: undefined,
  dimensionCode: undefined,
  dimensionValueCode: undefined,
  errors: undefined,
  rowNumber: undefined,
})

export default parsedBudgetBalanceRecord
