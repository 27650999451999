import { Map, List } from 'immutable'

/**
 * Updates a list item from a list that matches predicate
 *
 * @param list List to search for a item
 * @param predicate Callback function to find an item
 * @param path String or Array pointing to the property to be updated
 * @param value Value to set
 * @returns Copy of the source list with the change applied (assuming the predicate matched)
 */
export const updateListValue = (list, predicate, path, value) => {
  const index = list.findIndex(predicate)
  const pathWithIndex = Array.isArray(path) ? [index, ...path] : [index, path]
  return index === -1 ? list : list.setIn(pathWithIndex, value)
}

export const replaceItemInList = (list, predicate, newItem) => {
  const index = list.findIndex(predicate)
  return list.set(index, newItem)
}

/**
 *
 * Adds item to the end of the list without mutating
 *
 * @param list Immutable list
 * @param item Item to add to the Immutable list
 */
export const addToList = (list, item) => list.set(list.size, item)

/**
 * If property exists (has id) then property will deleted,
 * If property doesn't exist, it will be added
 *
 * @param map Immutable Map / OrderedMap
 * @param id to search
 * @param value to set
 */
export const toggleProperty = (map, id, value) =>
  map.has(id) ? map.delete(id) : map.set(id, value)

/**
 * Converts Array -> Map of Lists
 * @param array Source array
 * @param keyName Property name to use as a key
 * @param mapper Optional: function that maps an item before putting it to the collection
 */
export const arrayToMapWithLists = (array, keyName, mapper = (item) => item) =>
  array.reduce((working, curr) => {
    const key = String(curr[keyName])
    const existingList = working.get(key)
    return existingList
      ? working.set(key, addToList(existingList, mapper(curr)))
      : working.set(key, List([mapper(curr)]))
  }, Map())

/**
 * Converts List -> Map of Lists
 * @param list Source List
 * @param keyName Property name to use as a key
 * @param mapper Optional: function that maps an item before putting it to the collection
 */
export const listToMapWithLists = (list, keyName, mapper = (item) => item) =>
  list.reduce((working, curr) => {
    const key = String(curr.get(keyName))
    const existingList = working.get(key)
    return existingList
      ? working.set(key, addToList(existingList, mapper(curr)))
      : working.set(key, List([mapper(curr)]))
  }, Map())
