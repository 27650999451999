import { List, Map } from 'immutable'

import {
  CREATE_CONTENT_SUCCESS,
  GET_CONTENTS,
  GET_CONTENTS_SUCCESS,
  DELETE_CONTENT,
  DELETE_CONTENT_ERROR,
  DELETE_CONTENT_SUCCESS,
  UPDATE_BUDGET_CONTENT,
  UPDATE_SUBBUDGET_CONTENT,
  UPDATE_CONTENT,
  UPDATE_BUDGET_CONTENT_SUCCESS,
  UPDATE_CONTENT_ERROR,
  UPDATE_CONTENT_SUCCESS,
  CREATE_CONTENT,
  CREATE_CONTENT_ERROR,
  CONTENT_TYPES,
  CONTENT_TYPE_BUDGET,
  CONTENT_STATUS_LOADING,
  GET_CONTENTS_ERROR,
  CONTENT_STATUS_ERROR,
  CLEAR_CONTENT_TARGET_STATUS,
  UPDATE_SUBBUDGET_CONTENT_SUCCESS,
} from './constants'
import { REMOVE_BUDGET_ROW_SUCCESS } from 'containers/Budget/constants'
import { REMOVE_SUB_BUDGET_ROW_SUCCESS } from 'containers/SubBudget/constants'
import {
  RICH_TEXT_EDITOR_STATUS_PROCESSING,
  RICH_TEXT_EDITOR_STATUS_ERROR,
} from 'components/RichTextEditor/constants'
import { getDataKey, mapToContentRecord } from './functions'
import { addToList, updateListValue, replaceItemInList } from 'utils/immutable'
import { enumValueToEnumName } from 'utils/enum'
import ContentDataRecord from 'records/contentData'
import ContentDataTextRecord from 'records/contentDataText'

const initialState = new Map({
  contents: new Map(),
  statuses: new Map(), // single text block save status
  contentTargetStatuses: new Map(), // fetching status of target, for example, budget
})

export const contentsReducer = (contents, action) => {
  switch (action.type) {
    case GET_CONTENTS_SUCCESS:
      return contents.set(
        getDataKey(action.contentType, action.targetId),
        List((action.contents || []).map(mapToContentRecord))
      )

    case CREATE_CONTENT_SUCCESS: {
      const { content, parentTargetId } = action
      const contentRecord = mapToContentRecord(content)
      const key = parentTargetId
        ? getDataKey(CONTENT_TYPE_BUDGET, parentTargetId)
        : getDataKey(
            enumValueToEnumName(content.type, CONTENT_TYPES),
            content.targetId
          )
      return contents.has(key)
        ? contents.set(key, addToList(contents.get(key), contentRecord))
        : contents.set(key, new List(contentRecord))
    }

    case UPDATE_BUDGET_CONTENT_SUCCESS:
    case UPDATE_SUBBUDGET_CONTENT_SUCCESS: {
      const { contentId, contentType, targetId, content } = action
      const contentRecord = mapToContentRecord(content)
      const key = getDataKey(contentType, targetId)
      const currentContents = contents.get(key)
      return contents.set(
        key,
        replaceItemInList(
          currentContents,
          (c) => c.id === contentId,
          contentRecord
        )
      )
    }

    case UPDATE_CONTENT: {
      const { data, contentId, contentType, targetId } = action
      const key = getDataKey(contentType, targetId)
      const currentContents = contents.get(key)
      return contents.set(
        key,
        updateListValue(
          currentContents,
          (c) => c.id === contentId,
          'data',
          new ContentDataRecord({
            text: new ContentDataTextRecord({
              body: data.text.body,
              title: data.text.title,
            }),
          })
        )
      )
    }

    case DELETE_CONTENT_SUCCESS: {
      const { contentType, targetId, contentId, parentTargetId } = action
      const key = parentTargetId
        ? getDataKey(CONTENT_TYPE_BUDGET, parentTargetId)
        : getDataKey(contentType, targetId)
      if (contents.has(key)) {
        return contents.set(
          key,
          contents.get(key).filter((c) => c.id !== contentId)
        )
      }
      return contents
    }

    case REMOVE_BUDGET_ROW_SUCCESS:
    case REMOVE_SUB_BUDGET_ROW_SUCCESS: {
      const { budgetId, rowId } = action
      const key = getDataKey(CONTENT_TYPE_BUDGET, budgetId)
      if (contents.has(key)) {
        return contents.set(
          key,
          contents.get(key).filter((c) => c.targetId !== rowId.toString())
        )
      }
      return contents
    }

    default:
      return contents
  }
}

export const contentTargetStatusesReducer = (contentTargetStatuses, action) => {
  switch (action.type) {
    case GET_CONTENTS_SUCCESS:
    case CREATE_CONTENT_SUCCESS:
    case DELETE_CONTENT_SUCCESS:
    case UPDATE_CONTENT_SUCCESS:
    case UPDATE_BUDGET_CONTENT_SUCCESS:
    case UPDATE_SUBBUDGET_CONTENT_SUCCESS:
    case CLEAR_CONTENT_TARGET_STATUS:
      return contentTargetStatuses.delete(
        action.parentTargetId
          ? getDataKey(CONTENT_TYPE_BUDGET, action.parentTargetId)
          : getDataKey(action.contentType, action.targetId)
      )

    case CREATE_CONTENT:
    case DELETE_CONTENT:
    case GET_CONTENTS:
    case UPDATE_BUDGET_CONTENT:
    case UPDATE_SUBBUDGET_CONTENT:
    case UPDATE_CONTENT:
      return contentTargetStatuses.set(
        action.parentTargetId
          ? getDataKey(CONTENT_TYPE_BUDGET, action.parentTargetId)
          : getDataKey(action.contentType, action.targetId),
        CONTENT_STATUS_LOADING
      )

    case DELETE_CONTENT_ERROR:
    case CREATE_CONTENT_ERROR:
    case GET_CONTENTS_ERROR:
    case UPDATE_CONTENT_ERROR:
      return contentTargetStatuses.set(
        action.parentTargetId
          ? getDataKey(CONTENT_TYPE_BUDGET, action.parentTargetId)
          : getDataKey(action.contentType, action.targetId),
        { type: CONTENT_STATUS_ERROR, error: action.error }
      )

    default:
      return contentTargetStatuses
  }
}

export const statusesReducer = (statuses, action) => {
  switch (action.type) {
    case UPDATE_CONTENT:
    case UPDATE_BUDGET_CONTENT:
    case UPDATE_SUBBUDGET_CONTENT:
      return statuses.set(action.contentId, RICH_TEXT_EDITOR_STATUS_PROCESSING)

    case CREATE_CONTENT:
      return statuses.set(
        action.temporaryContentId,
        RICH_TEXT_EDITOR_STATUS_PROCESSING
      )

    case UPDATE_CONTENT_ERROR:
      return statuses.set(action.contentId, RICH_TEXT_EDITOR_STATUS_ERROR)

    case CREATE_CONTENT_ERROR:
      return statuses.set(
        action.temporaryContentId,
        RICH_TEXT_EDITOR_STATUS_ERROR
      )

    case UPDATE_CONTENT_SUCCESS:
    case UPDATE_BUDGET_CONTENT_SUCCESS:
    case UPDATE_SUBBUDGET_CONTENT_SUCCESS:
      return statuses.delete(action.contentId)

    case CREATE_CONTENT_SUCCESS:
      return statuses.delete(action.temporaryContentId)

    default:
      return statuses
  }
}

export const contentReducer = (state = initialState, action) =>
  state
    .set('contents', contentsReducer(state.get('contents'), action))
    .set('statuses', statusesReducer(state.get('statuses'), action))
    .set(
      'contentTargetStatuses',
      contentTargetStatusesReducer(state.get('contentTargetStatuses'), action)
    )

export default contentReducer
