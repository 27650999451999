import axios from 'axios'
import { returnData, config, catchError } from './api-utils'
import { fetchEventSource } from '@microsoft/fetch-event-source'

const getBudgetBalanceData = ({
  companyCode,
  budgetId,
  start,
  end,
  onError,
  onMessage,
  onProgress,
}) => {
  const configObj = config()
  fetchEventSource(
    configObj.baseURL +
      `${companyCode}/budgets/${budgetId}/data/dimensionsTotal?end=${end}&start=${start}`,
    {
      headers: { ...configObj.headers, 'Content-Type': 'application/json' },
      method: 'GET',
      openWhenHidden: true,
      onmessage: (event) => {
        if (event.event === 'progress' && onProgress) {
          onProgress(event)
          return
        }
        if (event.event === 'error' && onError) {
          onError(event)
          return
        }
        if (event.event === 'keepalive') {
          return
        }
        if (onMessage) {
          onMessage(event)
          return
        }
      },
      onerror: (error) => {
        if (onError) {
          onError(error)
        }
        // Event source library atomaticly retries if error is not thrown
        throw new Error(error)
      },
    }
  ).catch(() => {})
}

const getBudgetCalculationJob = ({ companyCode, budgetId, start, end }) =>
  axios
    .get(
      `${companyCode}/budgets/${budgetId}/balances/CalculationJob`,
      config({ params: { end, start } })
    )
    .then(returnData)
    .catch(catchError)

const getBudgetBalanceTree = ({ companyCode, budgetId }) =>
  axios
    .get(`${companyCode}/budgets/${budgetId}/balances/tree`, config())
    .then(returnData)
    .catch(catchError)

const getBudgetBalanceForRow = ({ companyCode, budgetId, start, end, rowId }) =>
  axios
    .get(
      `${companyCode}/budgets/${budgetId}/balances`,
      config({ params: { start, end, rowId } })
    )
    .then(returnData)
    .catch(catchError)

const budgetMonitorApi = {
  getBudgetBalanceData,
  getBudgetBalanceTree,
  getBudgetBalanceForRow,
  getBudgetCalculationJob,
}

export default budgetMonitorApi
