import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import cashFlowApi from 'api/CashFlowApi'

import { handleError } from 'api/api-utils'

import {
  GET_INVOICES,
  GET_CASH_FLOW,
  GET_INVOICES_BY_DATE,
  GET_EXCLUDED_DAYS,
  GET_EXCLUDED_INVOICES,
  SET_EXCLUDED_DAYS,
  SET_EXCLUDED_INVOICES,
} from './constants'
import {
  getCashFlow as getCashFlowAction,
  getCashFlowSuccess,
  getCashFlowError,
  getInvoicesSuccess,
  getInvoicesError,
  getInvoicesByDateError,
  getInvoicesByDateSuccess,
  getExcludedDays as getExcludedDaysAction,
  getExcludedDaysError,
  getExcludedDaysSuccess,
  getExcludedInvoices as getExcludedInvoicesAction,
  getExcludedInvoicesError,
  getExcludedInvoicesSuccess,
  setExcludedDaysError,
  setExcludedDaysSuccess,
  setExcludedInvoicesError,
  setExcludedInvoicesSuccess,
} from './actions'

export function* getExcludedDays(action) {
  const { companyCode } = action
  try {
    const excludedDays = yield call(
      cashFlowApi.getCashFlowDayExclusions,
      companyCode
    )
    yield put(getExcludedDaysSuccess(excludedDays))
  } catch (error) {
    yield put(getExcludedDaysError())
  }
}

export function* getExcludedInvoices(action) {
  const { companyCode } = action
  try {
    const excludedInvoices = yield call(
      cashFlowApi.getCashFlowInvoiceExclusions,
      companyCode
    )
    yield put(getExcludedInvoicesSuccess(excludedInvoices))
  } catch (error) {
    yield put(getExcludedInvoicesError())
  }
}

export function* setExcludedDays(action) {
  const { companyCode, days } = action
  try {
    const excludedDays = yield call(cashFlowApi.setCashFlowDayExclusions, {
      companyCode,
      days,
    })
    yield put(setExcludedDaysSuccess(excludedDays))
    yield put(getCashFlowAction({ companyCode }))
    yield put(getExcludedDaysAction({ companyCode }))
  } catch (error) {
    yield put(setExcludedDaysError())
  }
}

export function* setExcludedInvoices(action) {
  const { companyCode, sourceInvoiceIds } = action
  try {
    const excludedInvoices = yield call(
      cashFlowApi.setCashFlowInvoiceExclusions,
      {
        companyCode,
        sourceInvoiceIds,
      }
    )
    yield put(setExcludedInvoicesSuccess(excludedInvoices))
    yield put(getCashFlowAction({ companyCode }))
    yield put(getExcludedInvoicesAction({ companyCode }))
  } catch (error) {
    yield put(setExcludedInvoicesError())
  }
}

export function* getInvoicesByDate(action) {
  const { companyCode, date } = action
  try {
    const invoices = yield call(
      cashFlowApi.getInvoicesByDate,
      companyCode,
      date
    )
    yield put(getInvoicesByDateSuccess(invoices))
  } catch (error) {
    yield put(handleError(error, getInvoicesByDateError))
  }
}

export function* getInvoices(action) {
  const { companyCode, startDate, endDate } = action
  try {
    const invoices = yield call(
      cashFlowApi.getInvoices,
      companyCode,
      startDate,
      endDate
    )
    yield put(getInvoicesSuccess(invoices))
  } catch (error) {
    yield put(handleError(error, getInvoicesError))
  }
}

export function* getCashFlow(action) {
  const { companyCode } = action
  try {
    const cashFlow = yield call(cashFlowApi.getCashFlow, companyCode)
    yield put(getCashFlowSuccess({ cashFlow }))
  } catch (error) {
    yield put(handleError(error, getCashFlowError))
  }
}

export function* cashFlowSaga() {
  yield all([
    takeLatest(GET_INVOICES, getInvoices),
    takeEvery(GET_CASH_FLOW, getCashFlow),
    takeLatest(GET_INVOICES_BY_DATE, getInvoicesByDate),
    takeLatest(GET_EXCLUDED_DAYS, getExcludedDays),
    takeLatest(GET_EXCLUDED_INVOICES, getExcludedInvoices),
    takeLatest(SET_EXCLUDED_DAYS, setExcludedDays),
    takeLatest(SET_EXCLUDED_INVOICES, setExcludedInvoices),
  ])
}

export default cashFlowSaga
