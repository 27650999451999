import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import * as Diff from 'immutablediff'
import { Record } from 'immutable'

import budgetApi from 'api/rollingrule-budget-api'

import { resetSubBudgetTrees } from 'containers/SubBudget/actions'

import {
  ADD_ACCOUNT_RANGE,
  CREATE_BUDGET_ROLLING_RULE,
  DELETE_BUDGET_ROLLING_RULE,
  GET_BUDGET_ROLLING_RULE_TREE_AND_DATA,
  GET_BUDGET_ROLLING_RULES,
  REPLACE_ACCOUNT_RANGES,
  UPDATE_BUDGET_ROLLING_RULE,
  UPDATE_BUDGET_ROLLING_RULE_DATA,
  UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER,
} from './constants'
import {
  addAccountRangeError,
  addAccountRangeSuccess,
  createBudgetRollingRuleError,
  createBudgetRollingRuleSuccess,
  deleteBudgetRollingRuleError,
  deleteBudgetRollingRuleSuccess,
  getBudgetRollingRulesError,
  getBudgetRollingRulesSuccess,
  getBudgetRollingRuleTreeAndDataError,
  getBudgetRollingRuleTreeAndDataSuccess,
  replaceAccountRangesError,
  replaceAccountRangesSuccess,
  updateBudgetRollingRuleError,
  updateBudgetRollingRuleSuccess,
  updateBudgetRollingRuleDataError,
  updateBudgetRollingRuleDataSuccess,
  updateBudgetRollingRuleData,
} from './actions'
import { delay } from 'redux-saga'
import { clearBudgetData } from 'containers/Budget/actions'

const BUFFER_DELAY_IN_MS = 1600

const UpdateBudgetRollingRuleRecord = Record({
  name: undefined,
  budgetRowIds: undefined,
  subBudgetRowIds: undefined,
  accountRanges: undefined,
})

export function* getBudgetRollingRules(action) {
  const { companyCode, budgetId } = action

  try {
    const rollingRules = yield call(
      budgetApi.getBudgetRollingRules,
      companyCode,
      budgetId
    )
    yield put(getBudgetRollingRulesSuccess({ rollingRules }))
  } catch (error) {
    yield put(handleError(error, getBudgetRollingRulesError))
  }
}

export function* getBudgetRollingRuleTreeAndData(action) {
  const { companyCode, budgetId, ruleId, start, end } = action
  try {
    const [tree, data] = yield all([
      call(budgetApi.getRollingRuleBudgetTree, companyCode, budgetId, ruleId),
      call(
        budgetApi.getRollingRuleBudgetData,
        companyCode,
        budgetId,
        ruleId,
        start,
        end
      ),
    ])
    yield put(getBudgetRollingRuleTreeAndDataSuccess({ tree, data }))
  } catch (error) {
    yield put(handleError(error, getBudgetRollingRuleTreeAndDataError))
  }
}

export function* deleteBudgetRollingRule(action) {
  const { ruleId, companyCode, budgetId } = action
  try {
    yield call(budgetApi.deleteBudgetRollingRule, companyCode, budgetId, ruleId)
    yield put(deleteBudgetRollingRuleSuccess({ ruleId }))
    yield put(resetSubBudgetTrees())
    yield put(clearBudgetData())
  } catch (error) {
    yield put(handleError(error, deleteBudgetRollingRuleError))
  }
}

export function* createBudgetRollingRule(action) {
  const { companyCode, budgetId, ...rest } = action
  try {
    const rollingRule = yield call(
      budgetApi.createBudgetRollingRule,
      companyCode,
      budgetId,
      rest
    )
    yield put(createBudgetRollingRuleSuccess({ rollingRule }))
    yield put(resetSubBudgetTrees())
    yield put(clearBudgetData())
  } catch (error) {
    yield put(handleError(error, createBudgetRollingRuleError))
  }
}

export function* addBudgetRollingRuleAccountRange(action) {
  const { companyCode, budgetId, ruleId, startAccount, endAccount } = action
  const patch = [
    {
      op: 'add',
      path: '/accountRanges/-',
      value: { startAccount, endAccount },
    },
  ]
  try {
    const rollingRule = yield call(
      budgetApi.patchBudgetRollingRule,
      companyCode,
      budgetId,
      ruleId,
      patch
    )
    yield put(addAccountRangeSuccess({ rollingRule }))
    yield put(resetSubBudgetTrees())
    yield put(clearBudgetData())
  } catch (error) {
    yield put(handleError(error, addAccountRangeError))
  }
}

export function* replaceBudgetRollingRuleAccountRanges(action) {
  const { companyCode, ruleId, budgetId, accountRanges } = action
  const patch = [
    {
      op: 'replace',
      path: '/accountRanges',
      value: accountRanges,
    },
  ]
  try {
    const rollingRule = yield call(
      budgetApi.patchBudgetRollingRule,
      companyCode,
      budgetId,
      ruleId,
      patch
    )
    yield put(replaceAccountRangesSuccess({ rollingRule }))
    yield put(resetSubBudgetTrees())
    yield put(clearBudgetData())
  } catch (error) {
    yield put(handleError(error, replaceAccountRangesError))
  }
}

export function* updateBudgetRollingRule(action) {
  const { companyCode, budgetId, oldRollingRule, newRollingRule } = action
  try {
    const patch = Diff(
      new UpdateBudgetRollingRuleRecord(oldRollingRule),
      new UpdateBudgetRollingRuleRecord(newRollingRule)
    ).toJS()
    const rollingRule = yield call(
      budgetApi.patchBudgetRollingRule,
      companyCode,
      budgetId,
      oldRollingRule.id,
      patch
    )
    yield put(updateBudgetRollingRuleSuccess({ rollingRule }))
    yield put(resetSubBudgetTrees())
    yield put(clearBudgetData())
  } catch (err) {
    yield put(handleError(err, updateBudgetRollingRuleError))
  }
}

export function* updateBudgetRollingRuleDataPatch(action) {
  try {
    const { companyCode, budgetId, ruleId, start, end, patches } = action
    const data = yield call(budgetApi.patchBudgetRollingRuleData, {
      companyCode,
      budgetId,
      ruleId,
      start,
      end,
      patches,
    })
    yield put(updateBudgetRollingRuleDataSuccess({ data }))
    yield put(resetSubBudgetTrees())
    yield put(clearBudgetData())
  } catch (error) {
    yield put(handleError(error, updateBudgetRollingRuleDataError))
  }
}

let patchBuffer = []
export function* bufferUpdateBudgetRollingRuleData(action) {
  patchBuffer.push(...action?.patches)
  yield call(delay, BUFFER_DELAY_IN_MS)
  yield put(updateBudgetRollingRuleData({ ...action, patches: patchBuffer }))
  patchBuffer = []
}

export function* defaultSaga() {
  yield all([
    takeLatest(GET_BUDGET_ROLLING_RULES, getBudgetRollingRules),
    takeEvery(DELETE_BUDGET_ROLLING_RULE, deleteBudgetRollingRule),
    takeLatest(CREATE_BUDGET_ROLLING_RULE, createBudgetRollingRule),
    takeLatest(UPDATE_BUDGET_ROLLING_RULE, updateBudgetRollingRule),
    takeLatest(
      UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER,
      bufferUpdateBudgetRollingRuleData
    ),
    takeEvery(
      UPDATE_BUDGET_ROLLING_RULE_DATA,
      updateBudgetRollingRuleDataPatch
    ),
    takeLatest(
      GET_BUDGET_ROLLING_RULE_TREE_AND_DATA,
      getBudgetRollingRuleTreeAndData
    ),
    takeEvery(ADD_ACCOUNT_RANGE, addBudgetRollingRuleAccountRange),
    takeLatest(REPLACE_ACCOUNT_RANGES, replaceBudgetRollingRuleAccountRanges),
  ])
}

// All sagas to be loaded
export default defaultSaga
