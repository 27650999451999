/**
 *
 * ModalFooter
 *
 * Wrapper Component for Bootstrap Modal Footer
 */

import React from 'react'

const ModalFooter = (props) => <div className="modal-footer" {...props} />

export default ModalFooter
