import {
  GET_COMPANY_DATA,
  GET_COMPANY_DATA_FAILED,
  GET_COMPANY_DATA_SUCCESS,
  SELECT_COMPANY,
} from './constants'

export const selectCompany = (companyCode) => ({
  type: SELECT_COMPANY,
  companyCode,
})

export const getCompanyData = () => ({ type: GET_COMPANY_DATA })
export const getCompanyDataFailed = (error) => ({
  type: GET_COMPANY_DATA_FAILED,
  error,
})
export const getCompanyDataSuccess = ({ companyCode }) => ({
  type: GET_COMPANY_DATA_SUCCESS,
  companyCode,
})
