export const parseUrl = (url) => {
  if (!url) return {}

  try {
    const newURL = new URL(url)
    // eslint-disable-next-line no-restricted-globals
    return newURL.origin !== location.origin
      ? { externalLink: newURL.href }
      : { internalLink: `${newURL.pathname}${newURL.search}` }
  } catch (e) {
    return {}
  }
}

export const convertInternalLinkToUrl = (pathname) => {
  if (!pathname) return

  // eslint-disable-next-line no-restricted-globals
  return new URL(pathname, location.origin)?.href
}

export const removeDomainIfExists = (url) => {
  if (!url) return
  try {
    const parsedURL = new URL(url)
    return `${parsedURL.pathname}${parsedURL.search}`
  } catch {
    return url
  }
}

export default parseUrl
