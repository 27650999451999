import { Record, Stack, Set } from 'immutable'

const BudgetDataRecord = Record({
  columns: undefined,
  rows: undefined,
  undo: Stack(),
  redo: Stack(),
  selectedCellSet: Set(),
  activeCell: Set(),
})

export default BudgetDataRecord
