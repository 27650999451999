import { all, call, takeEvery, take, fork, race } from 'redux-saga/effects'
import { delay } from 'redux-saga'

export function* debounce(ms, pattern, task, combineActions) {
  let currentAction = null
  yield all([
    takeEvery(pattern, (action) => {
      // Always aggregate actions into currentAction
      currentAction = combineActions(currentAction, action)
    }),
    fork(function*() {
      while (true) {
        yield take(pattern) // Take first action or pause until data
        while (true) {
          const { debounced } = yield race({
            debounced: delay(ms), // we bounce after buffer delay
            latestAction: take(pattern), // we get new data and start bouncing from the start
          })

          if (debounced) {
            const actionToProcess = currentAction
            currentAction = null
            yield call(task, actionToProcess)
            if (!currentAction) {
              break // we are done bouncing only if no new data during processing
            }
          }
        }
      }
    }),
  ])
}
