import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const baseUrl = 'systemdashboards'

const getSystemDashboards = () =>
  axios
    .get(baseUrl, config())
    .then(returnData)
    .catch(catchError)

const copySystemDashboard = ({ id, systemDashboard }) =>
  axios
    .post(`${baseUrl}/${id}/copy`, systemDashboard, config())
    .then(returnData)
    .catch(catchError)

const createSystemDashboard = (systemDashboard) =>
  axios
    .post(baseUrl, systemDashboard, config())
    .then(returnData)
    .catch(catchError)

const createSystemDashboardWidget = ({ dashboardId, widget }) =>
  axios
    .post(`${baseUrl}/${dashboardId}/widget`, widget, config())
    .then(returnData)
    .catch(catchError)

const patchSystemDashboard = ({ dashboardId, patch }) =>
  axios
    .patch(`${baseUrl}/${dashboardId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const deleteSystemDashboard = ({ dashboardId }) =>
  axios
    .delete(`${baseUrl}/${dashboardId}`, config())
    .then(returnData)
    .catch(catchError)

const deleteSystemDashboardWidget = ({ dashboardId, widgetId }) =>
  axios
    .delete(`${baseUrl}/${dashboardId}/widget/${widgetId}`, config())
    .then(returnData)
    .catch(catchError)

const patchSystemDashboardWidget = ({ dashboardId, patch, widgetId }) =>
  axios
    .patch(`${baseUrl}/${dashboardId}/widget/${widgetId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const upsertWidgetParameters = ({ dashboardId, widgetId, parameters }) =>
  axios
    .post(
      `${baseUrl}/${dashboardId}/widget/${widgetId}/parameters`,
      parameters,
      config()
    )
    .then(returnData)
    .catch(catchError)

const systemDashboardApi = {
  copySystemDashboard,
  createSystemDashboard,
  createSystemDashboardWidget,
  deleteSystemDashboard,
  deleteSystemDashboardWidget,
  getSystemDashboards,
  patchSystemDashboard,
  patchSystemDashboardWidget,
  upsertWidgetParameters,
}
export default systemDashboardApi
