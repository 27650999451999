if (window.Cypress) {
  /* eslint-disable no-underscore-dangle */
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__ =
    window.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__

  window.__REDUX_DEVTOOLS_EXTENSION__ =
    window.parent.__REDUX_DEVTOOLS_EXTENSION__
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ =
    window.parent.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  /* eslint-enable */
}
