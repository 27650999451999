import PropTypes from 'prop-types'
import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { HasClaim } from 'utils/Authorization'
import { parseContextFromPathName } from 'utils/context'

import {
  selectAllPermissions,
  getContextPermissions,
} from 'containers/App/selectors'

const propTypes = {
  children: PropTypes.node.isRequired,
  claim: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  // some components should not receive all props, for example, <a> doesn't need
  // redux dispatch, with this object you can define the props to pass
  propsToPass: PropTypes.object,
}

const defaultProps = {
  propsToPass: null,
}

const IfClaim = (props) => {
  const {
    children,
    claim,
    permissions,
    propsToPass,
    claimContext,
    ...rest
  } = props
  const context =
    claimContext || parseContextFromPathName(window.location.pathname)
  return HasClaim({
    permissions: getContextPermissions({
      permissions,
      customerCode: context.customerCode,
      companyCode: context.companyCode,
    }),
    claim,
  })
    ? React.cloneElement(children, propsToPass || rest)
    : null
}

const mapStateToProps = () =>
  createStructuredSelector({
    permissions: selectAllPermissions,
  })

IfClaim.propTypes = propTypes
IfClaim.defaultProps = defaultProps

export default connect(
  mapStateToProps,
  null
)(IfClaim)
