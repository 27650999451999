import * as yup from 'yup'

const includeSameAs = () => {
  yup.addMethod(yup.mixed, 'sameAs', function a(ref, message) {
    return this.test('sameAs', message, function b(value) {
      const other = this.resolve(ref)
      return !other || !value || value === other
    })
  })
}

const includeCompare = () => {
  yup.addMethod(yup.mixed, 'compare', function a(
    ref,
    message,
    compareFunction
  ) {
    return this.test('compare', message, function b(value) {
      const other = this.resolve(ref)
      return compareFunction(other, value)
    })
  })
}

const includeCompareAll = () => {
  yup.addMethod(yup.mixed, 'compareAll', function a(
    refs,
    message,
    compareFunction
  ) {
    return this.test('compareAll', message, function b(value) {
      const others = refs.map((ref) => this.resolve(ref))
      return compareFunction(others, value)
    })
  })
}

const includeIsValidUrl = () => {
  yup.addMethod(yup.string, 'isValidUrl', function(message) {
    return this.test('isValidUrl', message, function(urlString) {
      try {
        const url = new URL(urlString)
        return !!url
      } catch {
        return false
      }
    })
  })
}

export { includeCompare, includeCompareAll, includeSameAs, includeIsValidUrl }
