/*
 * Login Messages
 *
 * This contains all the text for the Login component.
 */
import {
  CHECK_LOGIN_STATE,
  CHOOSE_PROFILE_STATE,
  GET_LOGGED_IN_USER_INFO_STATE,
} from 'containers/App/constants'
import { defineMessages } from 'react-intl'

export default defineMessages({
  aadAccountExistsText: {
    id: 'app.containers.Login.aadAccountExistsText',
    defaultMessage:
      'This username is now converted but we did not send any redundant emails this time. You may sign in using the email address you just gave.',
  },
  aadAccountExistsTitle: {
    id: 'app.containers.Login.aadAccountExistsTitle',
    defaultMessage: 'You already have an account',
  },
  cancelAccountConversion: {
    id: 'app.containers.Login.cancelAccountConversion',
    defaultMessage: 'Skip for now',
  },
  confirmation: {
    id: 'app.containers.Login.confirmation',
    defaultMessage:
      'After conversion is started, you cannot sign in with email and password anymore.',
  },
  continue: {
    id: 'app.containers.Login.continueToConversion',
    defaultMessage: 'Start Conversion',
  },
  chooseProfileTitle: {
    id: 'app.containers.Login.chooseProfileTitle',
    defaultMessage: 'Who is using Finazilla?',
  },
  conversionHeaderPt1: {
    id: 'app.containers.Login.conversionHeaderPt1',
    defaultMessage: 'Convert to improved sign in process',
  },
  conversionHeaderPt2: {
    id: 'app.containers.Login.conversionHeaderPt2',
    defaultMessage: 'Great choice!',
  },
  conversionHeaderPt3: {
    id: 'app.containers.Login.conversionHeaderPt2',
    defaultMessage: 'Thank you!',
  },
  doAccountConversion: {
    id: 'app.containers.Login.doAccountConversion',
    defaultMessage: 'Convert',
  },
  emailLoginSubmitBtnTxt: {
    id: 'app.containers.Login.emailLoginSubmitBtnTxt',
    defaultMessage: 'Sign in with email',
  },
  error: {
    id: 'app.containers.Login.error',
    defaultMessage: 'Invalid username or password. Please try again.',
  },
  forceConvert: {
    id: 'app.containers.Login.forceConvertLegacyAccount',
    defaultMessage:
      'Multi-factor authentication has been chosen as a mandatory security measure for all users. Conversion takes only a couple of minutes and makes multi-factor authentication possible.',
    description:
      'The message that is displayed when legacy account is required to convert into Azure AD account.',
  },
  header: {
    id: 'app.containers.Login.header',
    defaultMessage: 'Welcome to Finazilla!',
  },
  mfaInfo: {
    id: 'app.containers.Login.MMFAInfo',
    defaultMessage: `
      - Multi-Factor Authentication adds an extra layer of 
      security to your account. {br}
      - Using Multi-Factor Authentication you can force a sign-out on all devices signed into with the account.`,
    description: 'Information about multi-factor authentication',
  },
  subUserNotFoundError: {
    id: 'app.containers.Login.subUserNotFoundError',
    defaultMessage: 'Unfortunately there are no valid users for your account.',
    description:
      'Message that is displayed after user has decided to switch into AAD',
  },
  thankYouForConverting: {
    id: 'app.containers.Login.thankYouForConverting',
    defaultMessage:
      'You will receive an invitation mail shortly. From now on sign in with Microsoft. ',
    description:
      'Message that is displayed after user has decided to switch into AAD',
  },
  useLinkedAccount: {
    id: 'app.containers.Login.useLinkedAccount',
    defaultMessage: 'Sign in with a work or school account',
    description: 'Text for a link that forwards user to AD login flow.',
  },
  validationErrorEmail: {
    id: 'app.containers.Login.validationErrorEmail',
    defaultMessage: 'Username must be a valid email',
    description: 'The message that is displayed when email is incorrect.',
  },
  validationErrorUsername: {
    id: 'app.containers.Login.validationErrorUsername',
    defaultMessage: 'Username is a required field',
    description: 'The message that is displayed when username is missing.',
  },
  validationErrorPassword: {
    id: 'app.containers.Login.validationErrorPassword',
    defaultMessage: 'Password is a required field',
    description: 'The message that is displayed when password is missing.',
  },
  validationErrorInvitationEmail: {
    id: 'app.containers.Login.validationErrorInvitationEmail',
    defaultMessage: 'Use a valid email address please',
    description:
      'The message that is displayed when invitation email is incorrect.',
  },
  wannaConvertLegacyAccount: {
    id: 'app.containers.Login.wannaConvertLegacyAccount',
    defaultMessage:
      'Would you like to convert your account into more secure one? Conversion takes only a couple of minutes and makes multi-factor authentication possible.',
    description:
      'The message that is displayed when legacy account should be converted into Azure AD account.',
  },
  wannaConvertLegacyAccountPart2: {
    id: 'app.containers.Login.wannaConvertLegacyAccountPart2',
    defaultMessage:
      'Please, fill in a valid email address. You will receive an invitation mail after clicking OK.',
    description:
      'The message that is displayed when legacy account should be converted into Azure AD account.',
  },
  signOut: {
    id: 'app.containers.Login.signOut',
    defaultMessage: 'Sign Out',
  },
  retry: {
    id: 'app.containers.Login.retry',
    defaultMessage: 'Retry',
  },
  unableToFetchUser: {
    id: 'app.containers.Login.unableToFetchUser',
    defaultMessage: 'Unable to fetch users for',
  },
  noUsersFound: {
    id: 'app.containers.Login.noUsersFound',
    defaultMessage: 'No users found for account',
  },
  [CHOOSE_PROFILE_STATE]: {
    id: 'app.containers.Login.subUsersLoading',
    defaultMessage: 'Loading sub users...',
  },
  [CHECK_LOGIN_STATE]: {
    id: 'app.containers.App.checkLoginState',
    defaultMessage: 'Checking login...',
    description: 'Message for checking login state.',
  },
  [GET_LOGGED_IN_USER_INFO_STATE]: {
    id: 'app.containers.App.getLoggedInUserInfoState',
    defaultMessage: 'Fetching user ',
    description: 'Message for fetching logged in user info.',
  },
  useLemonLogin: {
    id: 'app.containers.Login.useLemonLogin',
    defaultMessage: 'Sign in with Lemonsoft',
  },
  noCompanyFound: {
    id: 'app.containers.Login.noCompanyFound',
    defaultMessage: 'No company found for account',
  },
  initializeCompanyFromLemonsoft: {
    id: 'app.containers.Login.initializeCompanyFromLemonsoft',
    defaultMessage:
      'You can setup Finazilla using Lemonsoft. This will take a few minutes.',
  },
  selectOrganization: {
    id: 'app.containers.Login.selectOrganization',
    defaultMessage: 'Select Lemonsoft organization',
  },
  noLicense: {
    id: 'app.containers.Login.noLicense',
    defaultMessage:
      'No Finazilla license found for lemonsoft user. Contact organization admin.',
  },
  lemonErrorTitle: {
    id: 'app.containers.Login.lemonErrorTitle',
    defaultMessage: 'Finazilla for Lemonsoft error',
  },
})
