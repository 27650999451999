import { fromJS } from 'immutable'

import {
  SHOW_ALERT_DIALOG,
  ALERT_DIALOG_DISMISSED,
  SHOW_CONFIRM_DIALOG,
  CONFIRM_DIALOG_RESULT,
  SHOW_PROMPT_DIALOG,
  PROMPT_DIALOG_RESULT,
} from './constants'

const initialState = fromJS({
  alert: {
    show: false,
    alert: undefined,
    details: undefined,
  },
  confirm: {
    show: false,
    title: undefined,
    details: undefined,
  },
  prompt: {
    show: false,
    title: undefined,
    details: undefined,
  },
})

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT_DIALOG:
      return state
        .setIn(['alert', 'show'], true)
        .setIn(['alert', 'alert'], action.alert)
        .setIn(['alert', 'details'], action.details)

    case ALERT_DIALOG_DISMISSED:
      return state
        .setIn(['alert', 'show'], false)
        .setIn(['alert', 'alert'], undefined)
        .setIn(['alert', 'details'], undefined)

    case SHOW_CONFIRM_DIALOG:
      return state
        .setIn(['confirm', 'show'], true)
        .setIn(['confirm', 'title'], action.title)
        .setIn(['confirm', 'details'], action.details)

    case CONFIRM_DIALOG_RESULT:
      return state
        .setIn(['confirm', 'show'], false)
        .setIn(['confirm', 'title'], undefined)
        .setIn(['confirm', 'details'], undefined)

    case SHOW_PROMPT_DIALOG:
      return state
        .setIn(['prompt', 'show'], true)
        .setIn(['prompt', 'title'], action.title)
        .setIn(['prompt', 'details'], action.details)

    case PROMPT_DIALOG_RESULT:
      return state
        .setIn(['prompt', 'show'], false)
        .setIn(['prompt', 'title'], undefined)
        .setIn(['prompt', 'details'], undefined)

    default:
      return state
  }
}

export default dialogReducer
