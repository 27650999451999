/*
 *
 * Budgets actions
 *
 */

import {
  CREATE_BUDGET,
  CREATE_BUDGET_ERROR,
  CREATE_BUDGET_SUCCESS,
  CREATE_BUDGET_FOLDER,
  CREATE_BUDGET_FOLDER_ERROR,
  CREATE_BUDGET_FOLDER_SUCCESS,
  DELETE_BUDGET,
  DELETE_BUDGET_ERROR,
  DELETE_BUDGET_SUCCESS,
  DELETE_BUDGET_FOLDER,
  DELETE_BUDGET_FOLDER_ERROR,
  DELETE_BUDGET_FOLDER_SUCCESS,
  EDIT_BUDGET,
  EDIT_BUDGET_FOLDER,
  EDIT_BUDGET_FOLDER_ERROR,
  EDIT_BUDGET_FOLDER_SUCCESS,
  COPY_BUDGET,
  GET_BUDGETS,
  GET_BUDGETS_ERROR,
  GET_BUDGETS_SUCCESS,
  GET_BUDGET_FOLDERS,
  GET_BUDGET_FOLDERS_ERROR,
  GET_BUDGET_FOLDERS_SUCCESS,
  SET_DEFAULT_BUDGET,
  SET_DEFAULT_BUDGET_ERROR,
  SET_DEFAULT_BUDGET_SUCCESS,
  UPDATE_BUDGET,
  UPDATE_BUDGET_ERROR,
  UPDATE_BUDGET_SUCCESS,
  CREATE_BUDGET_COPY,
  CREATE_BUDGET_COPY_ERROR,
  CREATE_BUDGET_COPY_SUCCESS,
} from './constants'

export const copyBudget = ({ id }) => ({ type: COPY_BUDGET, id })

export const createBudgetFolder = ({ companyCode, name }) => ({
  type: CREATE_BUDGET_FOLDER,
  companyCode,
  name,
})
export const createBudgetFolderError = (error) => ({
  type: CREATE_BUDGET_FOLDER_ERROR,
  error,
})

export const createBudgetFolderSuccess = ({ budgetFolder, companyCode }) => ({
  type: CREATE_BUDGET_FOLDER_SUCCESS,
  budgetFolder,
  companyCode,
})

export const createBudget = (budget) => ({
  type: CREATE_BUDGET,
  ...budget,
})

export const createBudgetError = (error) => ({
  type: CREATE_BUDGET_ERROR,
  error,
})
export const createBudgetSuccess = ({ budget, companyCode }) => ({
  type: CREATE_BUDGET_SUCCESS,
  budget,
  companyCode,
})

export const createBudgetCopy = ({
  budgetId,
  companyCode,
  name,
  copyUserRights,
  copySubBudgets,
  copyData,
  copyNotes,
  copySubBudgetNotes,
  copySubBudgetRollingRules,
  copySubBudgetTags,
  copySubBudgetData,
  moveForMonths,
  dimensionIdWhitelist,
}) => ({
  type: CREATE_BUDGET_COPY,
  budgetId,
  companyCode,
  name,
  copyUserRights,
  copySubBudgets,
  copyData,
  copyNotes,
  copySubBudgetNotes,
  copySubBudgetRollingRules,
  copySubBudgetTags,
  copySubBudgetData,
  moveForMonths,
  dimensionIdWhitelist,
})
export const createBudgetCopyError = (error) => ({
  type: CREATE_BUDGET_COPY_ERROR,
  error,
})
export const createBudgetCopySuccess = ({ budget, companyCode }) => ({
  type: CREATE_BUDGET_COPY_SUCCESS,
  budget,
  companyCode,
})

export const deleteBudget = ({ companyCode, id }) => ({
  type: DELETE_BUDGET,
  companyCode,
  id,
})
export const deleteBudgetError = (error) => ({
  type: DELETE_BUDGET_ERROR,
  error,
})
export const deleteBudgetSuccess = ({ companyCode, id }) => ({
  type: DELETE_BUDGET_SUCCESS,
  companyCode,
  id,
})

export const deleteBudgetFolder = ({ companyCode, id }) => ({
  type: DELETE_BUDGET_FOLDER,
  companyCode,
  id,
})

export const deleteBudgetFolderError = (error) => ({
  type: DELETE_BUDGET_FOLDER_ERROR,
  error,
})

export const deleteBudgetFolderSuccess = ({ companyCode, id }) => ({
  type: DELETE_BUDGET_FOLDER_SUCCESS,
  companyCode,
  id,
})

export const editBudget = ({ id }) => ({ type: EDIT_BUDGET, id })

export const editBudgetFolder = ({ companyCode, id, name }) => ({
  type: EDIT_BUDGET_FOLDER,
  companyCode,
  id,
  name,
})

export const editBudgetFolderError = (error) => ({
  type: EDIT_BUDGET_FOLDER_ERROR,
  error,
})

export const editBudgetFolderSuccess = ({ folder, companyCode }) => ({
  type: EDIT_BUDGET_FOLDER_SUCCESS,
  folder,
  companyCode,
})

export const getBudgets = ({ companyCode }) => ({
  type: GET_BUDGETS,
  companyCode,
})
export const getBudgetsError = (error) => ({ type: GET_BUDGETS_ERROR, error })
export const getBudgetsSuccess = ({ budgets, companyCode }) => ({
  type: GET_BUDGETS_SUCCESS,
  budgets,
  companyCode,
})

export const getBudgetFolders = ({ companyCode }) => ({
  type: GET_BUDGET_FOLDERS,
  companyCode,
})

export const getBudgetFoldersError = (error) => ({
  type: GET_BUDGET_FOLDERS_ERROR,
  error,
})

export const getBudgetFoldersSuccess = ({ folders, companyCode }) => ({
  type: GET_BUDGET_FOLDERS_SUCCESS,
  folders,
  companyCode,
})

export const setDefaultBudget = ({ companyCode, id }) => ({
  type: SET_DEFAULT_BUDGET,
  companyCode,
  id,
})
export const setDefaultBudgetError = (error) => ({
  type: SET_DEFAULT_BUDGET_ERROR,
  error,
})
export const setDefaultBudgetSuccess = ({ companyCode, id }) => ({
  type: SET_DEFAULT_BUDGET_SUCCESS,
  companyCode,
  id,
})

export const updateBudget = ({ budget, companyCode, updatedValues }) => ({
  type: UPDATE_BUDGET,
  budget,
  companyCode,
  updatedValues,
})
export const updateBudgetError = (error) => ({
  type: UPDATE_BUDGET_ERROR,
  error,
})
export const updateBudgetSuccess = ({ budget, companyCode }) => ({
  type: UPDATE_BUDGET_SUCCESS,
  budget,
  companyCode,
})
