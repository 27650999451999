/*
 *
 * Report constants
 *
 */

export const GET_REPORT_DATA_PREVIEW =
  'app/ReportSelections/GET_REPORT_DATA_PREVIEW'
export const GET_REPORT_DATA_PREVIEW_SUCCESS =
  'app/ReportSelections/GET_REPORT_DATA_PREVIEW_SUCCESS'
export const GET_REPORT_DATA_PREVIEW_ERROR =
  'app/ReportSelections/GET_REPORT_DATA_PREVIEW_ERROR'
export const CLEAR_REPORT_DATA_PREVIEW_ERROR =
  'app/ReportSelections/CLEAR_REPORT_DATA_PREVIEW_ERROR'

export const CLOSE_REPORT_PREVIEW = 'app/ReportSelections/CLOSE_REPORT_PREVIEW'
export const CLEAR_REPORT_DATA_PREVIEW =
  'app/ReportSelections/CLEAR_REPORT_DATA_PREVIEW'

export const GET_REPORT_SELECTION_FOR_EDITING =
  'app/ReportSelections/GET_REPORT_SELECTION_FOR_EDITING'
export const GET_REPORT_SELECTION_FOR_EDITING_ERROR =
  'app/ReportSelections/GET_REPORT_SELECTION_FOR_EDITING_ERROR'
export const GET_REPORT_SELECTION_FOR_EDITING_SUCCESS =
  'app/ReportSelections/GET_REPORT_SELECTION_FOR_EDITING_SUCCESS'

export const OPEN_REPORT_WITHOUT_SAVING =
  'app/ReportSelections/OPEN_REPORT_WITHOUT_SAVING'
export const OPEN_REPORT_WITHOUT_SAVING_ERROR =
  'app/ReportSelections/OPEN_REPORT_WITHOUT_SAVING_ERROR'
export const OPEN_REPORT_WITHOUT_SAVING_SUCCESS =
  'app/ReportSelections/OPEN_REPORT_WITHOUT_SAVING_SUCCESS'

export const GET_REPORT_PROGRESS_PREVIEW =
  'app/ReportSelections/GET_REPORT_PROGRESS_PREVIEW'
export const GET_REPORT_PROGRESS_PREVIEW_ERROR =
  'app/ReportSelections/GET_REPORT_PROGRESS_PREVIEW_ERROR'
export const GET_REPORT_PROGRESS_PREVIEW_SUCCESS =
  'app/ReportSelections/GET_REPORT_PROGRESS_PREVIEW_SUCCESS'

export const SAVE_REPORT = 'app/ReportSelections/SAVE_REPORT'
export const SAVE_REPORT_SELECTION_ERROR =
  'app/ReportSelections/SAVE_REPORT_SELECTION_ERROR'
export const SAVE_REPORT_SELECTION_SUCCESS =
  'app/ReportSelections/SAVE_REPORT_SELECTION_SUCCESS'

export const DATE_FORMAT = 'DateFormat'
export const MONTH = 'Month'
export const MONTH_AND_YEAR = 'MonthAndYear'
export const YEAR_AND_MONTH = 'YearAndMonth'
export const OVERLAPPING_MONTHS = 'OverlappingMonths'
export const INCLUDE_DAY = 'IncludeDay'

export const DATEFORMAT_VALUES = {
  MONTH: 'MM',
  MONTH_AND_YEAR: 'MM/yyyy',
  YEAR_AND_MONTH: 'yyyy/MM',
  INCLUDE_DAY: 'yyyy/MM/dd',
}

export const DECIMALS_ZERO = 'DecimalsZero'
export const DECIMALS_ONE = 'DecimalsOne'
export const DECIMALS_TWO = 'DecimalsTwo'

export const PRECISION_ONE = 'PrecisionOne'
export const PRECISION_THOUSAND = 'PrecisionThousand'
export const PRECISION_MILLION = 'PrecisionMillion'

export const SEPARATOR_SPACE = 'Space'
export const SEPARATOR_DOT = 'Dot'
export const SEPARATOR_COMMA = 'Comma'

export const ORDER_DATASOURCE = 'DataSource'
export const ORDER_PERIOD = 'Period'
export const ORDER_COMPANY = 'Company'
export const ORDER_DIMENSION = 'Dimension'

export const DATATYPE_ACTUAL = 'ActualData'
export const DATATYPE_BUDGET = 'BudgetData'
export const DATATYPE_FORMULA = 'FormulaData'

export const COMPANY_PRES_TYPE_COMPANY_PER_COLUMN = 'CompanyPerColumn'
export const COMPANY_PRES_TYPE_COMPANY_PER_COLUMN_CONSOLIDATED =
  'CompanyPerColumnConsolidated'
export const COMPANY_PRES_TYPE_COMPANIES_TOTAL = 'CompaniesTotal'
export const COMPANY_PRES_TYPE_CONSOLIDATED_TOTAL = 'ConsolidatedTotal'

export const DIMENSION_PRES_TYPE_INCLUDE_DIMENSIONS = 'IncludeDimensions'
export const DIMENSION_PRES_TYPE_DIMENSIONS_TOTAL = 'DimensionsTotal'

export const OTHERS = 'Muut'

export const TOP_AND_OTHERS_DEFAULT = 0
export const TOP_1_AND_OTHERS = 1
export const TOP_2_AND_OTHERS = 2
export const TOP_3_AND_OTHERS = 3
export const TOP_4_AND_OTHERS = 4
export const TOP_5_AND_OTHERS = 5
export const TOP_6_AND_OTHERS = 6
export const TOP_7_AND_OTHERS = 7
export const TOP_8_AND_OTHERS = 8
export const TOP_9_AND_OTHERS = 9
export const TOP_10_AND_OTHERS = 10
export const TOP_11_AND_OTHERS = 11
export const TOP_12_AND_OTHERS = 12
export const TOP_13_AND_OTHERS = 13
export const TOP_14_AND_OTHERS = 14
export const TOP_15_AND_OTHERS = 15
export const TOP_16_AND_OTHERS = 16
export const TOP_17_AND_OTHERS = 17
export const TOP_18_AND_OTHERS = 18
export const TOP_19_AND_OTHERS = 19

export const TOP_AND_OTHERS_MAPPING_ARRAY = [
  TOP_AND_OTHERS_DEFAULT,
  TOP_1_AND_OTHERS,
  TOP_2_AND_OTHERS,
  TOP_3_AND_OTHERS,
  TOP_4_AND_OTHERS,
  TOP_5_AND_OTHERS,
  TOP_6_AND_OTHERS,
  TOP_7_AND_OTHERS,
  TOP_8_AND_OTHERS,
  TOP_9_AND_OTHERS,
  TOP_10_AND_OTHERS,
  TOP_11_AND_OTHERS,
  TOP_12_AND_OTHERS,
  TOP_13_AND_OTHERS,
  TOP_14_AND_OTHERS,
  TOP_15_AND_OTHERS,
  TOP_16_AND_OTHERS,
  TOP_17_AND_OTHERS,
  TOP_18_AND_OTHERS,
  TOP_19_AND_OTHERS,
]

export const DIMENSION_PRES_TYPE_DIMENSION_MAPPING_ARRAY = [
  DIMENSION_PRES_TYPE_INCLUDE_DIMENSIONS,
  DIMENSION_PRES_TYPE_DIMENSIONS_TOTAL,
]

export const REPORT_PERIOD_TYPE_DATE = 'Date'
export const REPORT_PERIOD_TYPE_ACCOUNTING_PERIOD = 'AccountingPeriod'
export const REPORT_PERIOD_TYPE_MONTHS_FROM_DYNAMIC_DATE =
  'MonthsFromDynamicDate'

export const ACCOUNTING_PERIOD_TYPE_CURRENT = 'CurrentPeriod'
export const ACCOUNTING_PERIOD_TYPE_PREVIOUS = 'PreviousPeriod'
export const ACCOUNTING_PERIOD_TYPE_NEXT = 'NextPeriod'

export const DYNAMIC_END_DATE_ACCOUNTING_PERIOD_END_DATE =
  'AccountingPeriodEndDate'
export const DYNAMIC_END_DATE_ACCOUNTING_LOCK_DATE = 'AccountingLockDate'
export const DYNAMIC_END_DATE_ACCOUNTING_CURRENT_MONTH = 'CurrentMonth'
export const DYNAMIC_END_DATE_BUDGET_LOCK_DATE = 'BudgetLockDate'

export const DYNAMIC_START_DATE_ACCOUNTING_PERIOD_START_DATE =
  'AccountingPeriodStartDate'
export const DYNAMIC_START_DATE_CURRENT_MONTH = 'CurrentMonth'
export const DYNAMIC_START_DATE_LOCKED_MONTH = 'LockedMonth'
export const DYNAMIC_START_DATE_NEXT_MONTH = 'NextMonth'
export const DYNAMIC_START_DATE_NEXT_OPEN_MONTH = 'NextOpenMonth'
export const DYNAMIC_START_DATE_OPEN_BUDGET_START_DATE = 'OpenBudgetStartDate'

export const PERIOD_PRES_TYPE_MONTH_PER_COLUMN = 'MonthPerColumn'
export const PERIOD_PRES_TYPE_MONTH_PER_COLUMN_CUMULATIVE =
  'MonthPerColumnCumulative'
export const PERIOD_PRES_TYPE_ACCOUNTING_PERIOD_PER_COLUMN =
  'AccountingPeriodPerColumn'
export const PERIOD_PRES_TYPE_REPORTING_PERIOD_TOTAL = 'ReportingPeriodTotal'
export const PERIOD_PRES_TYPE_QUARTER_PER_COLUMN = 'QuarterPerColumn'
export const PERIOD_PRES_TYPE_DAY_PER_COLUMN = 'DayPerColumn'

export const COMPARE_TYPE_SUMMARY = 'Summary'
export const COMPARE_TYPE_PERCENTAGE = 'Percentage'
export const COMPARE_TYPE_AMOUNT = 'Amount'

export const COMPARE_COLUMN_LAYOUT_OVERLAPPING = 'Overlapping'
export const COMPARE_COLUMN_LAYOUT_CONSECUTIVE = 'Consecutive'

export const FORMULA_OPERATOR_PLUS = 'FormulaOperatorChoicePlus'
export const FORMULA_OPERATOR_MINUS = 'FormulaOperatorChoiceMinus'
export const FORMULA_OPERATOR_DIVIDE = 'FormulaOperatorChoiceDivide'
export const FORMULA_OPERATOR_TIMES = 'FormulaOperatorChoiceTimes'
export const FORMULA_OPERATOR_DIFF_PERCENTAGE =
  'FormulaOperatorChoicePercentageDiff'
export const FORMULA_OPERATOR_COMPLEX = 'FormulaOperatorChoiceComplex'

export const REPORT_SELECTION_TYPE_USER = 0
export const REPORT_SELECTION_TYPE_SYSTEM = 1
export const REPORT_SELECTION_TYPE_CUSTOMER = 2

export const THOUSAND_SEPARATOR_MAPPING_ARRAY = [SEPARATOR_SPACE, SEPARATOR_DOT]

export const DECIMAL_COUNT_MAPPING_ARRAY = [
  DECIMALS_ZERO,
  DECIMALS_ONE,
  DECIMALS_TWO,
]
export const DECIMAL_SEPARATOR_MAPPING_ARRAY = [SEPARATOR_COMMA, SEPARATOR_DOT]
export const PRECISION_MAPPING_ARRAY = [
  PRECISION_ONE,
  PRECISION_THOUSAND,
  PRECISION_MILLION,
]
export const SHOW_IN_COLUMNS_OR_ROWS_MAPPINGS_ARRAY = [
  ORDER_DATASOURCE,
  ORDER_PERIOD,
  ORDER_COMPANY,
  ORDER_DIMENSION,
]
export const COMPANY_PRES_TYPE_MAPPING_ARRAY = [
  COMPANY_PRES_TYPE_COMPANY_PER_COLUMN,
  COMPANY_PRES_TYPE_COMPANY_PER_COLUMN_CONSOLIDATED,
  COMPANY_PRES_TYPE_COMPANIES_TOTAL,
  COMPANY_PRES_TYPE_CONSOLIDATED_TOTAL,
]
export const ACCOUNTING_PERIOD_TYPE_MAPPING_ARRAY = [
  ACCOUNTING_PERIOD_TYPE_PREVIOUS,
  ACCOUNTING_PERIOD_TYPE_CURRENT,
  ACCOUNTING_PERIOD_TYPE_NEXT,
]
export const DYNAMIC_END_DATE_MAPPING_ARRAY = [
  DYNAMIC_END_DATE_ACCOUNTING_PERIOD_END_DATE,
  DYNAMIC_END_DATE_ACCOUNTING_LOCK_DATE,
  DYNAMIC_END_DATE_ACCOUNTING_CURRENT_MONTH,
  DYNAMIC_END_DATE_BUDGET_LOCK_DATE,
]
// export const DYNAMIC_START_DATE_MAPPING_ARRAY = [
//   DYNAMIC_START_DATE_CURRENT_MONTH,
//   DYNAMIC_START_DATE_LOCKED_MONTH,
// ]
// the order of values is locked by the indexes in BE
export const DYNAMIC_START_DATE_MAPPING_ARRAY = [
  DYNAMIC_START_DATE_CURRENT_MONTH,
  DYNAMIC_START_DATE_LOCKED_MONTH,
  DYNAMIC_START_DATE_ACCOUNTING_PERIOD_START_DATE,
  DYNAMIC_START_DATE_NEXT_MONTH,
  DYNAMIC_START_DATE_NEXT_OPEN_MONTH,
  DYNAMIC_START_DATE_OPEN_BUDGET_START_DATE,
]
export const DATATYPE_MAPPING_ARRAY = [
  DATATYPE_ACTUAL,
  DATATYPE_BUDGET,
  DATATYPE_FORMULA,
]

export const FORMULA_OPERATORS_MAPPING_ARRAY = [
  FORMULA_OPERATOR_PLUS,
  FORMULA_OPERATOR_MINUS,
  FORMULA_OPERATOR_DIVIDE,
  FORMULA_OPERATOR_TIMES,
  FORMULA_OPERATOR_DIFF_PERCENTAGE,
]
export const PERIOD_PRES_TYPE_MAPPING_ARRAY = [
  PERIOD_PRES_TYPE_MONTH_PER_COLUMN,
  PERIOD_PRES_TYPE_MONTH_PER_COLUMN_CUMULATIVE,
  PERIOD_PRES_TYPE_ACCOUNTING_PERIOD_PER_COLUMN,
  PERIOD_PRES_TYPE_REPORTING_PERIOD_TOTAL,
  PERIOD_PRES_TYPE_QUARTER_PER_COLUMN,
  PERIOD_PRES_TYPE_DAY_PER_COLUMN,
]
export const REPORT_PERIOD_TYPE_MAPPING_ARRAY = [
  REPORT_PERIOD_TYPE_DATE,
  REPORT_PERIOD_TYPE_ACCOUNTING_PERIOD,
  REPORT_PERIOD_TYPE_MONTHS_FROM_DYNAMIC_DATE,
]
