import { call, put, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import userApi from 'api/UsersApi'
import token from 'api/TokenStorage'

import { GET_USERS } from './constants'
import {
  TREE_ITEM_FETCHING,
  TREE_ITEM_NORMAL,
  TREE_ITEM_FETCHING_ERROR,
} from 'records/tree-item'
import {
  getUsersSuccess,
  getUsersError,
  setTreeItemStatus,
  setItemSelection,
} from './actions'

export function* getUsers(action) {
  const { companyCode, customerCode, id, itemType, selectFetchedUsers } = action
  try {
    yield put(setTreeItemStatus({ id, status: TREE_ITEM_FETCHING, itemType }))
    const users = yield call(userApi.getUsers, {
      companyCode,
      customerCode,
    })
    const userId = token.getUserId()
    yield put(
      getUsersSuccess({ users, companyCode, customerCode, selfId: userId })
    )
    yield put(setTreeItemStatus({ id, status: TREE_ITEM_NORMAL, itemType }))
    if (selectFetchedUsers) {
      yield put(
        setItemSelection({ ids: users.map((u) => u.id), isSelected: true })
      )
    }
  } catch (error) {
    yield put(handleError(error, getUsersError))
    yield put(
      setTreeItemStatus({ id, status: TREE_ITEM_FETCHING_ERROR, itemType })
    )
  }
}

export function* shareSaga() {
  yield takeEvery(GET_USERS, getUsers)
}

export default shareSaga
