import { Map, List } from 'immutable'
import { mapValues, isNaN, omitBy, isUndefined } from 'lodash'

import DashboardRecord from 'records/Dashboard/dashboard'
import WidgetRecord from 'records/Dashboard/widget'

const string = 'string'
const number = 'number'

const parameterDefinitions = {
  visualization: {
    type: string,
    defaultValue: 'line',
  },
  x: {
    type: number,
    defaultValue: 0,
  },
  y: {
    type: number,
    defaultValue: 0,
  },
  width: {
    type: number,
    defaultValue: 4,
  },
  height: {
    type: number,
    defaultValue: 2,
  },
}

export const calculateNewWidgetPositionY = (widgets) =>
  widgets.reduce(
    (sum, widget) => sum + widget.getIn(['parameters', 'height']),
    0
  )

export const mapDashboard = (dashboard) =>
  new DashboardRecord({
    name: dashboard.name,
    id: String(dashboard.id),
    isOwner: dashboard.isOwner,
    // only in system dashboards
    description: dashboard.description,
    integrationSourceSystemIds: List(
      (dashboard.integrationSourceSystemIds || []).map(
        (integrationSourceSystemId) => String(integrationSourceSystemId)
      )
    ),
    isSystemDashboard: dashboard.isSystemDashboard,
  })

export const mapWidget = ({ widget, dashboardId }) =>
  new WidgetRecord({
    id: String(widget.id),
    dashboardId: String(dashboardId),
    type: widget.type,
    parameters: Map(mapParameters(widget.parameters)),
  })

export const mapParameters = (parameters) =>
  mapValues(parameters, (value, name) => {
    const definitions = parameterDefinitions[name]
    if (typeof definitions === 'undefined') {
      return value
    }

    const defaultValue = definitions.defaultValue

    switch (definitions.type) {
      case string:
        return typeof value === 'undefined' || value === null
          ? defaultValue
          : value

      case number: {
        const valueAsInt = parseInt(value, 10)
        return isNaN(valueAsInt) ? defaultValue : valueAsInt
      }

      default:
        return value
    }
  })

export const mapLayoutToWidgetParameterUpserts = (layout, widgets) =>
  layout.map((cell) => {
    const widget = widgets.find((w) => w.id === cell.i)
    if (!widget) {
      return null
    }

    const parameters = {
      x: cell.x === widget.parameters.get('x') ? undefined : cell.x,
      y: cell.y === widget.parameters.get('y') ? undefined : cell.y,
      width: cell.w === widget.parameters.get('width') ? undefined : cell.w,
      height: cell.h === widget.parameters.get('height') ? undefined : cell.h,
    }

    const cleaned = omitBy(parameters, isUndefined)

    return {
      dashboardId: widget.dashboardId,
      widgetId: widget.id,
      parameters: cleaned,
    }
  })

export const parseLocation = ({ id } = {}) => {
  const base = '/systemdashboards'
  return id ? `${base}/${id}` : base
}
