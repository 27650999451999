import { Record } from 'immutable'

const ColumnSpecRecord = Record({
  id: undefined,
  editable: false,
  period: undefined,
  lastLockedMonth: false,
  title: '',
  type: '',
})

export default ColumnSpecRecord
