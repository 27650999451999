import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import api from 'api/rollingrule-api'

import { handleError } from 'api/api-utils'

import {
  ADD_ACCOUNT_RANGE,
  GET_ROLLING_RULE_TREE_AND_DATA,
  UPDATE_ROLLING_RULE_DATA,
  REPLACE_ACCOUNT_RANGES,
} from './constants'
import {
  addAccountRangeError,
  addAccountRangeSuccess,
  getRollingRuleTreeAndDataError,
  getRollingRuleTreeAndDataSuccess,
  replaceAccountRangesError,
  replaceAccountRangesSuccess,
  updateRollingRuleDataError,
  updateRollingRuleDataSuccess,
} from './actions'

export function* replaceAccountRanges(action) {
  const { companyCode, ruleId, accountRanges } = action
  const patch = [
    {
      op: 'replace',
      path: '/accountRanges',
      value: accountRanges,
    },
  ]
  try {
    const rollingRule = yield call(
      api.patchRollingRule,
      companyCode,
      patch,
      ruleId
    )
    yield put(replaceAccountRangesSuccess({ rollingRule, companyCode }))
  } catch (error) {
    yield put(handleError(error, replaceAccountRangesError))
  }
}

export function* addAccountRange(action) {
  const { companyCode, ruleId, startAccount, endAccount } = action
  const patch = [
    {
      op: 'add',
      path: '/accountRanges/-',
      value: { startAccount, endAccount },
    },
  ]
  try {
    const rollingRule = yield call(
      api.patchRollingRule,
      companyCode,
      patch,
      ruleId
    )
    yield put(addAccountRangeSuccess({ rollingRule, companyCode }))
  } catch (error) {
    yield put(handleError(error, addAccountRangeError))
  }
}

export function* getRollingRuleTreeAndData(action) {
  const { companyCode, ruleId } = action
  try {
    const [data, tree] = yield all([
      call(api.getRollingRuleData, companyCode, ruleId),
      call(api.getRollingRuleTree, companyCode, ruleId),
    ])
    yield put(getRollingRuleTreeAndDataSuccess({ tree, data }))
  } catch (err) {
    yield put(handleError(err, getRollingRuleTreeAndDataError))
  }
}

export function* updateRollingRuleData(action) {
  const { companyCode, ruleId, rowData } = action
  const patch = rowData.map((row) => ({
    op: 'replace',
    path: `/rows/${row.rowId}/rolling-default/amount`,
    value: row.value,
  }))
  try {
    const data = yield call(
      api.patchRollingRuleSchemeData,
      companyCode,
      patch,
      ruleId
    )
    yield put(updateRollingRuleDataSuccess({ data }))
  } catch (error) {
    yield put(handleError(error, updateRollingRuleDataError))
  }
}

export function* rollingRuleSaga() {
  yield all([
    takeEvery(ADD_ACCOUNT_RANGE, addAccountRange),
    takeEvery(REPLACE_ACCOUNT_RANGES, replaceAccountRanges),
    takeLatest(GET_ROLLING_RULE_TREE_AND_DATA, getRollingRuleTreeAndData),
    takeLatest(UPDATE_ROLLING_RULE_DATA, updateRollingRuleData),
  ])
}

// All sagas to be loaded
export default rollingRuleSaga
