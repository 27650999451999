import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const deleteInvoices = ({ companyCode }) =>
  axios
    .delete(`${companyCode}/invoices`, config())
    .then(returnData)
    .catch(catchError)

const invoiceApi = {
  deleteInvoices,
}

export default invoiceApi
