import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { Record } from 'immutable'
import diff from 'immutablediff'

import customerDashboardsApi from 'api/CustomerDashboardApi'
import { handleError } from 'api/api-utils'

import {
  copyCustomerDashboardError,
  copyCustomerDashboardSuccess,
  copyCustomerDashboardWidgetError,
  copyCustomerDashboardWidgetSuccess,
  createCustomerDashboardError,
  createCustomerDashboardSuccess,
  createCustomerDashboardWidgetError,
  createCustomerDashboardWidgetSuccess,
  deleteCustomerDashboardError,
  deleteCustomerDashboardSuccess,
  deleteCustomerDashboardWidgetError,
  deleteCustomerDashboardWidgetSuccess,
  getCustomerDashboardsError,
  getCustomerDashboardsSuccess,
  updateCustomerDashboardError,
  updateCustomerDashboardSuccess,
  updateCustomerDashboardWidgetError,
  updateCustomerDashboardWidgetSuccess,
  updateCustomerDashboardWidgetParametersError,
  updateCustomerDashboardWidgetParametersSuccess,
} from './actions'
import {
  COPY_CUSTOMER_DASHBOARD,
  COPY_CUSTOMER_DASHBOARD_WIDGET,
  CREATE_CUSTOMER_DASHBOARD,
  CREATE_CUSTOMER_DASHBOARD_WIDGET,
  DELETE_CUSTOMER_DASHBOARD,
  DELETE_CUSTOMER_DASHBOARD_WIDGET,
  GET_CUSTOMER_DASHBOARDS,
  UPDATE_CUSTOMER_DASHBOARD,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET,
  UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS,
} from './constants'
import { parseLocation } from './functions'

const UpdateDashboardRecord = Record({
  name: undefined,
  companyIds: undefined,
  description: undefined,
})

const UpdateWidgetRecord = Record({
  parameters: undefined,
  type: undefined,
})

export function* copyCustomerDashboard(action) {
  const { description, companyIds, customerCode, id, name } = action

  try {
    const dashboard = yield call(customerDashboardsApi.copyCustomerDashboard, {
      customerCode,
      id,
      customerDashboard: {
        description,
        companyIds,
        name,
      },
    })
    yield put(copyCustomerDashboardSuccess({ customerCode, dashboard }))
  } catch (error) {
    yield put(handleError(error, copyCustomerDashboardError))
  }
}

export function* createCustomerDashboard(action) {
  const { description, companyIds, customerCode, name } = action

  try {
    const dashboard = yield call(
      customerDashboardsApi.createCustomerDashboard,
      {
        customerCode,
        customerDashboard: {
          description,
          companyIds,
          name,
        },
      }
    )
    yield put(createCustomerDashboardSuccess({ customerCode, dashboard }))
  } catch (error) {
    yield put(handleError(error, createCustomerDashboardError))
  }
}

export function* copyCustomerDashboardWidget(action) {
  const { customerCode, dashboardId, widget } = action

  try {
    const newWidget = yield call(
      customerDashboardsApi.createCustomerDashboardWidget,
      {
        customerCode,
        dashboardId,
        widget,
      }
    )
    yield put(
      copyCustomerDashboardWidgetSuccess({
        customerCode,
        widget: newWidget,
        temporaryWidgetId: widget.id,
      })
    )
  } catch (error) {
    yield put(handleError(error, copyCustomerDashboardWidgetError))
  }
}

export function* createCustomerDashboardWidget(action) {
  const { customerCode, dashboardId, widget } = action

  try {
    const newWidget = yield call(
      customerDashboardsApi.createCustomerDashboardWidget,
      {
        customerCode,
        dashboardId,
        widget,
      }
    )
    yield put(
      createCustomerDashboardWidgetSuccess({
        customerCode,
        widget: newWidget,
        temporaryWidgetId: widget.id,
      })
    )
  } catch (error) {
    yield put(handleError(error, createCustomerDashboardWidgetError))
  }
}

export function* deleteCustomerDashboard(action) {
  const { customerCode, id, navigate } = action

  try {
    yield call(customerDashboardsApi.deleteCustomerDashboard, {
      customerCode,
      dashboardId: id,
    })
    yield put(deleteCustomerDashboardSuccess({ customerCode, id }))
    //yield put(push(parseLocation({ customerCode })))
    navigate(parseLocation({ customerCode }))
  } catch (error) {
    yield put(deleteCustomerDashboardError({ error, id }))
  }
}

export function* deleteCustomerDashboardWidget(action) {
  const { customerCode, dashboardId, widgetId } = action

  try {
    yield call(customerDashboardsApi.deleteCustomerDashboardWidget, {
      customerCode,
      dashboardId,
      widgetId,
    })
    yield put(deleteCustomerDashboardWidgetSuccess())
  } catch (error) {
    yield put(deleteCustomerDashboardWidgetError(error))
  }
}

export function* getCustomerDashboards(action) {
  const { customerCode } = action
  try {
    const dashboards = yield call(customerDashboardsApi.getCustomerDashboards, {
      customerCode,
    })
    yield put(getCustomerDashboardsSuccess({ customerCode, dashboards }))
  } catch (error) {
    yield put(handleError(error, getCustomerDashboardsError))
  }
}

export function* updateCustomerDashboard(action) {
  const { customerCode, dashboard, updatedDashboard } = action
  const { id: dashboardId } = dashboard
  const patch = diff(
    new UpdateDashboardRecord(dashboard),
    new UpdateDashboardRecord(updatedDashboard)
  ).toJS()

  try {
    const updatedDashboard = yield call(
      customerDashboardsApi.patchCustomerDashboard,
      {
        customerCode,
        dashboardId,
        patch,
      }
    )
    yield put(
      updateCustomerDashboardSuccess({
        customerCode,
        dashboard: updatedDashboard,
      })
    )
  } catch (error) {
    yield put(updateCustomerDashboardError({ error, dashboardId }))
  }
}

export function* updateCustomerDashboardWidget(action) {
  const { customerCode, dashboardId, editWidget, widget } = action
  const {
    parameters: { x, y, width, height },
  } = editWidget.toJS()

  try {
    const patch = diff(
      new UpdateWidgetRecord(editWidget),
      new UpdateWidgetRecord({
        ...widget,
        parameters: {
          x,
          y,
          width,
          height,
          ...widget.parameters,
        },
      })
    ).toJS()

    const updatedWidget = yield call(
      customerDashboardsApi.patchCustomerDashboardWidget,
      {
        customerCode,
        dashboardId,
        widgetId: editWidget.id,
        patch,
      }
    )
    yield put(
      updateCustomerDashboardWidgetSuccess({
        customerCode,
        dashboardId,
        widget: updatedWidget,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateCustomerDashboardWidgetError))
  }
}

export function* updateCustomerDashboardWidgetParameters(action) {
  const { customerCode, dashboardId, widgetId, parameters } = action

  try {
    yield call(customerDashboardsApi.upsertWidgetParameters, {
      customerCode,
      dashboardId,
      widgetId,
      parameters,
    })
    yield put(updateCustomerDashboardWidgetParametersSuccess())
  } catch (error) {
    yield put(handleError(error, updateCustomerDashboardWidgetParametersError))
  }
}

export function* customerDashboards() {
  yield all([
    takeEvery(COPY_CUSTOMER_DASHBOARD, copyCustomerDashboard),
    takeEvery(COPY_CUSTOMER_DASHBOARD_WIDGET, copyCustomerDashboardWidget),
    takeEvery(CREATE_CUSTOMER_DASHBOARD, createCustomerDashboard),
    takeEvery(CREATE_CUSTOMER_DASHBOARD_WIDGET, createCustomerDashboardWidget),
    takeEvery(DELETE_CUSTOMER_DASHBOARD, deleteCustomerDashboard),
    takeEvery(DELETE_CUSTOMER_DASHBOARD_WIDGET, deleteCustomerDashboardWidget),
    takeEvery(UPDATE_CUSTOMER_DASHBOARD_WIDGET, updateCustomerDashboardWidget),
    takeEvery(
      UPDATE_CUSTOMER_DASHBOARD_WIDGET_PARAMETERS,
      updateCustomerDashboardWidgetParameters
    ),
    takeLatest(GET_CUSTOMER_DASHBOARDS, getCustomerDashboards),
    takeLatest(UPDATE_CUSTOMER_DASHBOARD, updateCustomerDashboard),
  ])
}

export default customerDashboards
