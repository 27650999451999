/*
 *
 * Reports actions
 *
 */

import {
  ADD_REPORT_SELECTION_SHARE,
  ADD_REPORT_SELECTION_SHARE_ERROR,
  ADD_REPORT_SELECTION_SHARE_SUCCESS,
  COPY_REPORT_SELECTION,
  COPY_REPORT_SELECTION_ERROR,
  COPY_REPORT_SELECTION_SUCCESS,
  CREATE_REPORT_SELECTION,
  CREATE_REPORT_SELECTION_ERROR,
  CREATE_REPORT_SELECTION_SUCCESS,
  CREATE_REPORT_TAG,
  CREATE_REPORT_TAG_SUCCESS,
  DELETE_REPORT_SELECTION,
  DELETE_REPORT_SELECTION_ERROR,
  DELETE_REPORT_SELECTION_SHARE,
  DELETE_REPORT_SELECTION_SHARE_ERROR,
  DELETE_REPORT_SELECTION_SHARE_SUCCESS,
  DELETE_REPORT_SELECTION_SUCCESS,
  DELETE_REPORT_TAG,
  DELETE_REPORT_TAG_SUCCESS,
  EDIT_REPORT_TAG,
  EDIT_REPORT_TAG_SUCCESS,
  GET_REPORT_SELECTION,
  GET_REPORT_SELECTIONS,
  GET_REPORT_SELECTIONS_ERROR,
  GET_REPORT_SELECTIONS_SUCCESS,
  GET_REPORT_SELECTION_ERROR,
  GET_REPORT_SELECTION_SHARES,
  GET_REPORT_SELECTION_SHARES_ERROR,
  GET_REPORT_SELECTION_SHARES_SUCCESS,
  GET_REPORT_SELECTION_SUCCESS,
  GET_REPORT_TAGS,
  GET_REPORT_TAGS_SUCCESS,
  RESET_REPORT_SETTINGS,
  RESET_REPORT_SETTINGS_SUCCESS,
  RESET_REPORT_SETTINGS_ERROR,
  SAVE_REPORT_SETTINGS,
  SAVE_REPORT_SETTINGS_ERROR,
  SAVE_REPORT_SETTINGS_SUCCESS,
  UPDATE_REPORT_SELECTION,
  UPDATE_REPORT_SELECTION_ERROR,
  UPDATE_REPORT_SELECTION_SUCCESS,
  UPDATE_REPORT_SELECTION_SHARE,
  UPDATE_REPORT_SELECTION_SHARE_ERROR,
  UNSHARE_REPORT_SELECTION,
  UNSHARE_REPORT_SELECTION_SUCCESS,
  UNSHARE_REPORT_SELECTION_ERROR,
  DELETE_REPORT_TAG_ERROR,
} from './constants'

export const addReportSelectionShare = ({ reportSelectionId, userIds }) => ({
  type: ADD_REPORT_SELECTION_SHARE,
  reportSelectionId,
  userIds,
})
export const addReportSelectionShareSuccess = ({ reportSelectionId }) => ({
  type: ADD_REPORT_SELECTION_SHARE_SUCCESS,
  reportSelectionId,
})
export const addReportSelectionShareError = ({ reportSelectionId }) => ({
  type: ADD_REPORT_SELECTION_SHARE_ERROR,
  reportSelectionId,
})

export const copyReportSelection = ({
  reportSelectionId,
  name,
  customerCode,
  companyCode,
}) => ({
  type: COPY_REPORT_SELECTION,
  reportSelectionId,
  name,
  customerCode,
  companyCode,
})

export const copyReportSelectionError = (error) => ({
  type: COPY_REPORT_SELECTION_ERROR,
  error,
})

export const copyReportSelectionSuccess = ({ reportSelection }) => ({
  type: COPY_REPORT_SELECTION_SUCCESS,
  reportSelection,
})

export const createReportSelection = ({
  companyCode,
  customerCode,
  reportSelection,
  open,
  navigate,
  beta = false,
  setLoading = true,
}) => ({
  type: CREATE_REPORT_SELECTION,
  companyCode,
  customerCode,
  reportSelection,
  open,
  navigate,
  beta,
  setLoading,
})
export const createReportSelectionError = (error) => ({
  type: CREATE_REPORT_SELECTION_ERROR,
  error,
})
export const createReportSelectionSuccess = ({
  companyCode,
  customerCode,
  reportSelection,
}) => ({
  type: CREATE_REPORT_SELECTION_SUCCESS,
  companyCode,
  customerCode,
  reportSelection,
})

export const createReportTag = (tag) => ({
  type: CREATE_REPORT_TAG,
  tag,
})
export const createReportTagSuccess = ({ tag }) => ({
  type: CREATE_REPORT_TAG_SUCCESS,
  tag,
})

export const deleteReportSelection = ({ companyCode, reportSelectionId }) => ({
  type: DELETE_REPORT_SELECTION,
  companyCode,
  reportSelectionId,
})
export const deleteReportSelectionError = (error) => ({
  type: DELETE_REPORT_SELECTION_ERROR,
  error,
})
export const deleteReportSelectionSuccess = ({ reportSelectionId }) => ({
  type: DELETE_REPORT_SELECTION_SUCCESS,
  reportSelectionId,
})

export const deleteReportSelectionShare = ({ reportSelectionId, userIds }) => ({
  type: DELETE_REPORT_SELECTION_SHARE,
  reportSelectionId,
  userIds,
})
export const deleteReportSelectionShareSuccess = ({ reportSelectionId }) => ({
  type: DELETE_REPORT_SELECTION_SHARE_SUCCESS,
  reportSelectionId,
})
export const deleteReportSelectionShareError = ({ reportSelectionId }) => ({
  type: DELETE_REPORT_SELECTION_SHARE_ERROR,
  reportSelectionId,
})

export const deleteReportTag = (tagId) => ({
  type: DELETE_REPORT_TAG,
  tagId,
})
export const deleteReportTagSuccess = ({ tagId }) => ({
  type: DELETE_REPORT_TAG_SUCCESS,
  tagId,
})

export const deleteReportTagError = (error) => ({
  type: DELETE_REPORT_TAG_ERROR,
  error,
})

export const editReportTag = ({ tag, oldTag }) => ({
  type: EDIT_REPORT_TAG,
  tag,
  oldTag,
})
export const editReportTagSuccess = ({ tag }) => ({
  type: EDIT_REPORT_TAG_SUCCESS,
  tag,
})

export const getReportSelection = ({ reportId }) => ({
  type: GET_REPORT_SELECTION,
  reportId: reportId?.toString(),
})
export const getReportSelectionError = (error) => ({
  type: GET_REPORT_SELECTION_ERROR,
  error,
})
export const getReportSelectionSuccess = ({ reportId, reportSelection }) => ({
  type: GET_REPORT_SELECTION_SUCCESS,
  reportId,
  reportSelection,
})

export const getReportSelections = ({ companyCode }) => ({
  companyCode,
  type: GET_REPORT_SELECTIONS,
})
export const getReportSelectionsError = (error) => ({
  type: GET_REPORT_SELECTIONS_ERROR,
  error,
})
export const getReportSelectionsSuccess = ({ reportSelections }) => ({
  type: GET_REPORT_SELECTIONS_SUCCESS,
  reportSelections,
})

export const getReportSelectionShares = (id) => ({
  type: GET_REPORT_SELECTION_SHARES,
  id,
})
export const getReportSelectionSharesError = () => ({
  type: GET_REPORT_SELECTION_SHARES_ERROR,
})
export const getReportSelectionSharesSuccess = ({
  reportSelectionId,
  userIds,
}) => ({
  type: GET_REPORT_SELECTION_SHARES_SUCCESS,
  reportSelectionId,
  userIds,
})

export const getReportTags = () => ({
  type: GET_REPORT_TAGS,
})
export const getReportTagsSuccess = ({ tags }) => ({
  type: GET_REPORT_TAGS_SUCCESS,
  tags,
})

export const resetReportSettings = ({ companyCode, reportSelectionId }) => ({
  type: RESET_REPORT_SETTINGS,
  companyCode,
  reportSelectionId,
})
export const resetReportSettingsError = ({ error, reportSelectionId }) => ({
  type: RESET_REPORT_SETTINGS_ERROR,
  error,
  reportSelectionId,
})
export const resetReportSettingsSuccess = ({
  reportSelection,
  reportSelectionId,
}) => ({
  type: RESET_REPORT_SETTINGS_SUCCESS,
  reportSelection,
  reportSelectionId,
})

export const saveReportSettings = ({
  companyCode,
  reportSelectionId,
  reportSettings,
}) => ({
  type: SAVE_REPORT_SETTINGS,
  companyCode,
  reportSelectionId,
  reportSettings,
})
export const saveReportSettingsError = ({ error, reportSelectionId }) => ({
  type: SAVE_REPORT_SETTINGS_ERROR,
  error,
  reportSelectionId,
})
export const saveReportSettingsSuccess = ({
  reportSelection,
  reportSelectionId,
}) => ({
  type: SAVE_REPORT_SETTINGS_SUCCESS,
  reportSelectionId,
  reportSelection,
})

export const updateReportSelection = ({
  companyCode,
  customerCode,
  reportSelection,
  updatedReportSelection,
  open,
  navigate,
  beta = false,
  refetchData = true,
  setLoading = true,
}) => ({
  type: UPDATE_REPORT_SELECTION,
  companyCode,
  customerCode,
  reportSelection,
  updatedReportSelection,
  open,
  navigate,
  beta,
  refetchData,
  setLoading,
})
export const updateReportSelectionError = (error) => ({
  type: UPDATE_REPORT_SELECTION_ERROR,
  error,
})
export const updateReportSelectionSuccess = ({
  companyCode,
  customerCode,
  reportSelection,
  refetchData = true,
}) => ({
  type: UPDATE_REPORT_SELECTION_SUCCESS,
  companyCode,
  customerCode,
  reportSelection,
  refetchData,
})

export const updateReportSelectionShare = ({
  reportSelectionId,
  userIdsToAdd,
  userIdsToRemove,
}) => ({
  type: UPDATE_REPORT_SELECTION_SHARE,
  reportSelectionId,
  userIdsToAdd,
  userIdsToRemove,
})
export const updateReportSelectionShareError = ({ reportSelectionId }) => ({
  type: UPDATE_REPORT_SELECTION_SHARE_ERROR,
  reportSelectionId,
})

export const unshareReportSelection = ({ reportSelectionId, userId }) => ({
  type: UNSHARE_REPORT_SELECTION,
  reportSelectionId,
  userId,
})

export const unshareReportSelectionSuccess = ({
  reportSelectionId,
  userId,
}) => ({
  type: UNSHARE_REPORT_SELECTION_SUCCESS,
  reportSelectionId,
  userId,
})

export const unshareReportSelectionError = ({ reportSelectionId }) => ({
  type: UNSHARE_REPORT_SELECTION_ERROR,
  reportSelectionId,
})
