/*
 *
 * BudgetRollingRules reducer
 *
 */

import { fromJS, List } from 'immutable'
import patch from 'immpatch'

import {
  mapRollingRules,
  createRollingRule,
  mapRollingRulesScheme,
  mapRollingRulesData,
} from 'containers/RollingRules/utils'
import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_ERROR,
  GET_ACCOUNTS_SUCCESS,
} from 'containers/Accounts/constants'

import {
  ADD_ACCOUNT_RANGE,
  ADD_ACCOUNT_RANGE_ERROR,
  ADD_ACCOUNT_RANGE_SUCCESS,
  CREATE_BUDGET_ROLLING_RULE,
  CREATE_BUDGET_ROLLING_RULE_ERROR,
  CREATE_BUDGET_ROLLING_RULE_SUCCESS,
  DELETE_BUDGET_ROLLING_RULE,
  DELETE_BUDGET_ROLLING_RULE_ERROR,
  DELETE_BUDGET_ROLLING_RULE_SUCCESS,
  GET_BUDGET_ROLLING_RULE_TREE_AND_DATA,
  GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_ERROR,
  GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_SUCCESS,
  GET_BUDGET_ROLLING_RULES,
  GET_BUDGET_ROLLING_RULES_ERROR,
  GET_BUDGET_ROLLING_RULES_SUCCESS,
  REPLACE_ACCOUNT_RANGES,
  REPLACE_ACCOUNT_RANGES_ERROR,
  REPLACE_ACCOUNT_RANGES_SUCCESS,
  TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM,
  TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM,
  SHOW_BUDGET_ROLLING_RULE_ACCOUNTS,
  SHOW_EDIT_ROLLING_RULE_FORM,
  UPDATE_BUDGET_ROLLING_RULE,
  UPDATE_BUDGET_ROLLING_RULE_ERROR,
  UPDATE_BUDGET_ROLLING_RULE_SUCCESS,
  UPDATE_BUDGET_ROLLING_RULE_DATA_ERROR,
  UPDATE_BUDGET_ROLLING_RULE_DATA_SUCCESS,
  UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER,
} from './constants'

const initialState = fromJS({
  budgetRollingRules: List(),
  showCreateRollingRuleForm: false,
  editRollingRuleId: undefined,
  rollingRuleDetails: {
    ruleId: undefined,
    tree: undefined,
    data: undefined,
    showAccounts: false,
    showAddAccountRangeForm: false,
    loading: false,
    error: false,
  },
  error: false,
  loading: false,
})

const budgetRollingRulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BUDGET_ROLLING_RULE_DATA_BUFFER:
      return state.updateIn(['rollingRuleDetails', 'data'], (data) =>
        patch(data, action.patches)
      )

    case UPDATE_BUDGET_ROLLING_RULE:
    case DELETE_BUDGET_ROLLING_RULE:
    case CREATE_BUDGET_ROLLING_RULE:
    case GET_BUDGET_ROLLING_RULES:
      return state.set('loading', true).set('error', false)

    case UPDATE_BUDGET_ROLLING_RULE_ERROR:
    case DELETE_BUDGET_ROLLING_RULE_ERROR:
    case CREATE_BUDGET_ROLLING_RULE_ERROR:
    case GET_BUDGET_ROLLING_RULES_ERROR:
      return state.set('loading', false).set('error', action.error)

    // case UPDATE_BUDGET_ROLLING_RULE_DATA:
    case ADD_ACCOUNT_RANGE:
    case REPLACE_ACCOUNT_RANGES:
    case GET_ACCOUNTS:
    case GET_BUDGET_ROLLING_RULE_TREE_AND_DATA:
      return state
        .setIn(['rollingRuleDetails', 'ruleId'], action.ruleId)
        .setIn(['rollingRuleDetails', 'loading'], true)
        .setIn(['rollingRuleDetails', 'error'], false)

    case UPDATE_BUDGET_ROLLING_RULE_DATA_ERROR:
    case ADD_ACCOUNT_RANGE_ERROR:
    case REPLACE_ACCOUNT_RANGES_ERROR:
    case GET_ACCOUNTS_ERROR:
    case GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_ERROR:
      return state
        .setIn(['rollingRuleDetails', 'loading'], false)
        .setIn(['rollingRuleDetails', 'error'], action.error)

    case GET_ACCOUNTS_SUCCESS:
      return state.setIn(['rollingRuleDetails', 'loading'], false)

    case GET_BUDGET_ROLLING_RULES_SUCCESS:
      return state
        .set('loading', false)
        .set('budgetRollingRules', mapRollingRules(action.rollingRules))

    case CREATE_BUDGET_ROLLING_RULE_SUCCESS:
      return state
        .set('loading', false)
        .set('showCreateRollingRuleForm', false)
        .set(
          'budgetRollingRules',
          state
            .get('budgetRollingRules')
            .push(createRollingRule(action.rollingRule))
        )

    case DELETE_BUDGET_ROLLING_RULE_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'budgetRollingRules',
          state.get('budgetRollingRules').filter((r) => r.id !== action.ruleId)
        )

    case REPLACE_ACCOUNT_RANGES_SUCCESS:
    case ADD_ACCOUNT_RANGE_SUCCESS: {
      const rollingRules = state.get('budgetRollingRules')
      const ruleIndex = rollingRules.findIndex(
        (r) => r.id === action.rollingRule.id
      )
      return state
        .setIn(['rollingRuleDetails', 'loading'], false)
        .set(
          'budgetRollingRules',
          rollingRules.set(ruleIndex, createRollingRule(action.rollingRule))
        )
        .setIn(['rollingRuleDetails', 'showAddAccountRangeForm'], false)
    }

    case GET_BUDGET_ROLLING_RULE_TREE_AND_DATA_SUCCESS:
      return state
        .setIn(['rollingRuleDetails', 'loading'], false)
        .setIn(['rollingRuleDetails', 'showAccounts'], false)
        .setIn(
          ['rollingRuleDetails', 'tree'],
          mapRollingRulesScheme(action.tree)
        )
        .setIn(['rollingRuleDetails', 'data'], mapRollingRulesData(action.data))

    case UPDATE_BUDGET_ROLLING_RULE_SUCCESS: {
      const rollingRules = state.get('budgetRollingRules')
      const index = rollingRules.findIndex(
        (r) => r.id === action.rollingRule.id
      )
      return state
        .set('loading', false)
        .set('editRollingRuleId', undefined)
        .set(
          'budgetRollingRules',
          rollingRules.set(index, createRollingRule(action.rollingRule))
        )
    }

    case UPDATE_BUDGET_ROLLING_RULE_DATA_SUCCESS:
      return state
        .setIn(['rollingRuleDetails', 'loading'], false)
        .setIn(
          ['rollingRuleDetails', 'data'],
          patch(state.getIn(['rollingRuleDetails', 'data']), action.data)
        )

    case SHOW_BUDGET_ROLLING_RULE_ACCOUNTS:
      return state
        .setIn(['rollingRuleDetails', 'ruleId'], action.ruleId)
        .setIn(['rollingRuleDetails', 'showAccounts'], action.show)
        .setIn(['rollingRuleDetails', 'tree'], undefined)
        .setIn(['rollingRuleDetails', 'data'], undefined)

    case TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM:
      return state.set(
        'showCreateRollingRuleForm',
        !state.get('showCreateRollingRuleForm')
      )

    case TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM:
      return state.setIn(
        ['rollingRuleDetails', 'showAddAccountRangeForm'],
        !state.getIn(['rollingRuleDetails', 'showAddAccountRangeForm'])
      )

    case SHOW_EDIT_ROLLING_RULE_FORM:
      return state.set('editRollingRuleId', action.ruleId)

    default:
      return state
  }
}

export default budgetRollingRulesReducer
