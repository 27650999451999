/*
 *
 * RollingRules actions
 *
 */

import {
  CHANGE_EDIT_ROLLING_RULE_FORM_TYPE,
  CHANGE_ROLLING_RULE_FORM_TYPE,
  CREATE_ROLLINGRULE,
  CREATE_ROLLINGRULE_ERROR,
  CREATE_ROLLINGRULE_SUCCESS,
  DELETE_ROLLINGRULE,
  DELETE_ROLLINGRULE_ERROR,
  DELETE_ROLLINGRULE_SUCCESS,
  EDIT_ROLLING_RULE,
  GET_ROLLINGRULES,
  GET_ROLLINGRULES_ERROR,
  GET_ROLLINGRULES_SUCCESS,
  TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM,
  UPDATE_ROLLING_RULE,
  UPDATE_ROLLING_RULE_ERROR,
  UPDATE_ROLLING_RULE_SUCCESS,
} from './constants'

export const createRollingRule = ({ rule, companyCode }) => ({
  type: CREATE_ROLLINGRULE,
  rule,
  companyCode,
})
export const createRollingRuleSuccess = ({ companyCode, rollingRule }) => ({
  type: CREATE_ROLLINGRULE_SUCCESS,
  companyCode,
  rollingRule,
})
export const createRollingRuleError = (error) => ({
  type: CREATE_ROLLINGRULE_ERROR,
  error,
})

export const changeRollingRuleFormType = (ruleType) => ({
  type: CHANGE_ROLLING_RULE_FORM_TYPE,
  ruleType,
})
export const changeEditRollingRuleFormType = (ruleType) => ({
  type: CHANGE_EDIT_ROLLING_RULE_FORM_TYPE,
  ruleType,
})

export const deleteRollingRule = ({ ruleId, companyCode }) => ({
  type: DELETE_ROLLINGRULE,
  ruleId,
  companyCode,
})
export const deleteRollingRuleSuccess = ({ companyCode, ruleId }) => ({
  type: DELETE_ROLLINGRULE_SUCCESS,
  companyCode,
  ruleId,
})
export const deleteRollingRuleError = (error) => ({
  type: DELETE_ROLLINGRULE_ERROR,
  error,
})

export const editRollingRule = ({ ruleId }) => ({
  type: EDIT_ROLLING_RULE,
  ruleId,
})

export const getRollingRules = ({ companyCode }) => ({
  type: GET_ROLLINGRULES,
  companyCode,
})
export const getRollingRulesError = (error) => ({
  type: GET_ROLLINGRULES_ERROR,
  error,
})
export const getRollingRulesSuccess = ({ companyCode, rollingRules }) => ({
  type: GET_ROLLINGRULES_SUCCESS,
  companyCode,
  rollingRules,
})

export const toggleShowCreateRollingRuleForm = () => ({
  type: TOGGLE_SHOW_CREATE_ROLLING_RULE_FORM,
})

export const updateRollingRule = ({
  companyCode,
  oldRollingRule,
  newRollingRule,
}) => ({
  type: UPDATE_ROLLING_RULE,
  companyCode,
  oldRollingRule,
  newRollingRule,
})
export const updateRollingRuleError = (error) => ({
  type: UPDATE_ROLLING_RULE_ERROR,
  error,
})
export const updateRollingRuleSuccess = ({ companyCode, rollingRule }) => ({
  type: UPDATE_ROLLING_RULE_SUCCESS,
  companyCode,
  rollingRule,
})
