import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

import { createPathPrefix } from 'utils/context'

/**
 * @returns ONE subuser or profile.
 * This function is used in sagas. getUser is not.
 * TODO: Rename this back to getUser if possible
 */
const getLoggedInUser = () =>
  axios
    .get('user', config())
    .then(returnData)
    .catch(catchError)

const getSubUsers = () =>
  axios
    .get('useraccount', config())
    .then(returnData)
    .catch(catchError)

// const getUser = (userId) =>
//   axios
//     .get('users/' + userId, config())
//     .then(returnData)
//     .catch(catchError)

// Implements System/Customer/Company level Users
const getUsers = ({ customerCode, companyCode }) =>
  axios
    .get(`${createPathPrefix(customerCode, companyCode, true)}users/`, config())
    .then(returnData)
    .catch(catchError)

const getAllsers = () =>
  axios
    .get('/users/all', config())
    .then(returnData)
    .catch(catchError)

const createUser = ({ customerCode, companyCode, user }) =>
  axios
    .post(
      `${createPathPrefix(customerCode, companyCode, true)}users`,
      user,
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteUser = ({ userId }) =>
  axios
    .delete(`users/${userId}`, config())
    .then(returnData)
    .catch(catchError)

const deleteAADUser = ({ userId }) =>
  axios
    .delete(`/users/aadusers/${userId}`, config())
    .then(returnData)
    .catch(catchError)

const unlinkAADUser = ({ userId }) =>
  axios
    .delete(`/users/aadusers/unlink/${userId}`, config())
    .then(returnData)
    .catch(catchError)

const invite = ({ inviteEmail }) =>
  axios
    .post('users/invite', { inviteEmail }, config())
    .then(returnData)
    .catch(catchError)

const inviteUser = ({ inviteEmail, userId }) =>
  axios
    .post('users/inviteuser', { inviteEmail, idToInvite: userId }, config())
    .then(returnData)
    .catch(catchError)

const patchUser = ({ userId, patch }) =>
  axios
    .patch(`users/${userId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const updatePassword = ({ userId, email, currentPassword, newPassword }) =>
  axios
    .post(
      'users/password',
      { userId, email, currentPassword, newPassword },
      config()
    )
    .then(returnData)
    .catch(catchError)

const resetPassword = ({ userId, email, newPassword }) =>
  axios
    .post('users/resetPassword', { userId, email, newPassword }, config())
    .then(returnData)
    .catch(catchError)

const usersApi = {
  getLoggedInUser,
  getSubUsers,
  // getUser,
  getAllsers,
  getUsers,
  createUser,
  deleteUser,
  deleteAADUser,
  invite,
  inviteUser,
  patchUser,
  unlinkAADUser,
  updatePassword,
  resetPassword,
}

export default usersApi
