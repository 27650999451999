import { Record } from 'immutable'

const AccountSchemeRecord = Record({
  id: undefined,
  isDefault: false,
  name: '',
  type: 'account-scheme',
})

export default AccountSchemeRecord
