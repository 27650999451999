import {
  SHOW_ALERT_DIALOG,
  ALERT_DIALOG_DISMISSED,
  SHOW_CONFIRM_DIALOG,
  CONFIRM_DIALOG_RESULT,
  SHOW_PROMPT_DIALOG,
  PROMPT_DIALOG_RESULT,
} from './constants'

export const alert = (alertText, details) => ({
  type: SHOW_ALERT_DIALOG,
  alert: alertText,
  details,
})
export const dismissAlert = () => ({ type: ALERT_DIALOG_DISMISSED })
export const confirm = (title, details) => ({
  type: SHOW_CONFIRM_DIALOG,
  title,
  details,
})
export const dismissConfirm = (result) => ({
  type: CONFIRM_DIALOG_RESULT,
  result,
})
export const prompt = (title, details) => ({
  type: SHOW_PROMPT_DIALOG,
  title,
  details,
})
export const dismissPrompt = (result, input) => ({
  type: PROMPT_DIALOG_RESULT,
  result,
  input,
})
