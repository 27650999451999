import axios from 'axios'
import qs from 'qs'
import { returnData, config, catchError } from './api-utils'

const paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' })

const createVoucher = ({
  companyCode,
  date,
  description,
  voucherlines,
  voucherType,
}) =>
  axios
    .post(
      `${companyCode}/vouchers`,
      {
        class: voucherType,
        date,
        description,
        voucherlines,
      },
      config()
    )
    .then(returnData)
    .catch(catchError)

const getVoucher = ({ companyCode, id }) =>
  axios
    .get(`${companyCode}/vouchers/${id}`, config())
    .then(returnData)
    .catch(catchError)

const deleteVoucher = ({ companyCode, id }) =>
  axios
    .delete(`${companyCode}/vouchers/${id}`, config())
    .then(returnData)
    .catch(catchError)

const deleteVouchers = ({ companyCode }) =>
  axios
    .delete(`${companyCode}/vouchers`, config())
    .then(returnData)
    .catch(catchError)

const getVoucherList = ({ companyCode, endDate, startDate, voucherType }) =>
  axios
    .get(
      `${companyCode}/vouchers/list`,
      config({
        params: {
          from: startDate,
          to: endDate,
          class: voucherType,
        },
        paramsSerializer,
      })
    )
    .then(returnData)
    .catch(catchError)

const getExportData = ({
  companyCode,
  startDate,
  endDate,
  voucherType,
  exportType,
  accountFilter,
}) =>
  axios
    .get(
      `${companyCode}/vouchers/exportData`,
      config({
        params: {
          from: startDate,
          to: endDate,
          class: voucherType,
          exportType,
          accountFilterOption: accountFilter,
        },
        responseType: 'blob',
      })
    )
    .then(returnData)
    .catch(catchError)

const getVouchers = ({ realBalanceIds, reportSchemeId }) =>
  axios
    .post(
      `vouchers/?reportSchemeId=${reportSchemeId}`,
      realBalanceIds,
      config()
    )
    .then(returnData)
    .catch((error) => catchError(error))

const updateVoucher = ({ companyCode, voucher }) =>
  axios
    .put(`${companyCode}/vouchers`, voucher, config())
    .then(returnData)
    .catch(catchError)

const voucherApi = {
  createVoucher,
  getVoucher,
  deleteVoucher,
  deleteVouchers,
  getVoucherList,
  getExportData,
  getVouchers,
  updateVoucher,
}
export default voucherApi
