import { Record } from 'immutable'

const AccountSchemeTemplateUpdateRowGroupRecord = Record({
  accountGroupType: '',
  hideGroupSum: false,
  name: '',
  rowFactor: '',
  defaultForecastingParameters: null,
})

export default AccountSchemeTemplateUpdateRowGroupRecord
