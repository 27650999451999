import { saveAs } from 'file-saver'

const exportFile = ({
  data,
  fileType = 'csv',
  name = 'export',
  type = 'text/csv;charset=utf-8',
  autoBOM = true,
}) => {
  const blob = new Blob([data], { autoBOM, type })
  saveAs(blob, `${name}.${fileType}`)
}

export default exportFile
