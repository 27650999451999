import { Record } from 'immutable'

const ParsedAccountErrorRecord = Record({
  type: undefined,
  code: undefined,
  message: undefined,
  rowNumber: 0,
})

export default ParsedAccountErrorRecord
