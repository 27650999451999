/*
 *
 * IntegrationSourceSystems actions
 *
 */

import {
  CREATE_INTEGRATION_SOURCE_SYSTEM,
  DELETE_INTEGRATION_SOURCE_SYSTEM,
  DELETE_INTEGRATION_SOURCE_SYSTEM_ERROR,
  DELETE_INTEGRATION_SOURCE_SYSTEM_SUCCESS,
  GET_INTEGRATION_SOURCE_SYSTEMS,
  GET_INTEGRATION_SOURCE_SYSTEMS_ERROR,
  GET_INTEGRATION_SOURCE_SYSTEMS_SUCCESS,
  UPDATE_INTEGRATION_SOURCE_SYSTEM,
} from './constants'

export const getIntegrationSourceSystems = ({ companyCode, customerCode }) => ({
  type: GET_INTEGRATION_SOURCE_SYSTEMS,
  companyCode,
  customerCode,
})
export const getIntegrationSourceSystemsSuccess = ({ sourceSystems }) => ({
  sourceSystems,
  type: GET_INTEGRATION_SOURCE_SYSTEMS_SUCCESS,
})
export const getIntegrationSourceSystemsError = (error) => ({
  type: GET_INTEGRATION_SOURCE_SYSTEMS_ERROR,
  error,
})
export const createIntegrationSourceSystem = ({ sourceSystem }) => ({
  type: CREATE_INTEGRATION_SOURCE_SYSTEM,
  sourceSystem,
})
export const deleteIntegrationSourceSystem = ({ id }) => ({
  type: DELETE_INTEGRATION_SOURCE_SYSTEM,
  id,
})
export const deleteIntegrationSourceSystemError = (error) => ({
  type: DELETE_INTEGRATION_SOURCE_SYSTEM_ERROR,
  error,
})
export const deleteIntegrationSourceSystemSuccess = ({ id }) => ({
  type: DELETE_INTEGRATION_SOURCE_SYSTEM_SUCCESS,
  id,
})
export const updateIntegrationSourceSystem = ({
  oldSourceSystem,
  newSourceSystem,
}) => ({
  type: UPDATE_INTEGRATION_SOURCE_SYSTEM,
  oldSourceSystem,
  newSourceSystem,
})
