import { Record } from 'immutable'

const AccountSchemeTemplateRecord = Record({
  id: undefined,
  name: '',
  definedInScope: undefined,
  type: 'account-scheme-template',
})

export default AccountSchemeTemplateRecord
