import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getCashFlow = (companyCode) =>
  axios
    .get(`${companyCode}/cashflow`, config())
    .then(returnData)
    .catch(catchError)
// GET invoices for a date
const getInvoicesByDate = (companyCode, date) =>
  axios
    .get(
      `${companyCode}/invoices?onlyOpen=true&start=${date}&end=${date}&invoiceDateType=DueDate`,
      config()
    )
    .then(returnData)
    .catch(catchError)
// GET invoices for date range
const getInvoices = (companyCode, startDate, endDate) =>
  axios
    .get(
      `${companyCode}/invoices?onlyOpen=true&start=${startDate}&end=${endDate}&invoiceDateType=DueDate`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const getCashFlowDayExclusions = (companyCode) =>
  axios
    .get(`${companyCode}/cashflow/exclude/days`, config())
    .then(returnData)
    .catch(catchError)

const getCashFlowInvoiceExclusions = (companyCode) =>
  axios
    .get(`${companyCode}/cashflow/exclude/invoices`, config())
    .then(returnData)
    .catch(catchError)

const setCashFlowDayExclusions = ({ companyCode, days }) =>
  axios
    .post(`${companyCode}/cashflow/exclude/days`, { days }, config())
    .then(returnData)
    .catch(catchError)

const setCashFlowInvoiceExclusions = ({ companyCode, sourceInvoiceIds }) =>
  axios
    .post(
      `${companyCode}/cashflow/exclude/invoices`,
      { sourceInvoiceIds },
      config()
    )
    .then(returnData)
    .catch(catchError)

const cashFlowApi = {
  getCashFlow,
  getInvoices,
  getInvoicesByDate,
  getCashFlowDayExclusions,
  getCashFlowInvoiceExclusions,
  setCashFlowDayExclusions,
  setCashFlowInvoiceExclusions,
}

export default cashFlowApi
