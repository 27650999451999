import { Record } from 'immutable'

class CalculationConstantValueRecord extends Record({
  id: undefined,
  start: undefined,
  end: undefined,
  value: undefined,
}) {
  constructor(params) {
    super({
      ...params,
      // end might return null from backend and in that case we really
      // want it to be undefined
      // e.g. https://momentjs.com/docs/#/query/is-between/
      end: params.end ? params.end : undefined,
    })
  }
}

export default CalculationConstantValueRecord
