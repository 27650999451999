import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const createAccountingPeriod = ({ end, companyCode, name, start }) =>
  axios
    .post(`${companyCode}/accountingperiods/`, { end, name, start }, config())
    .then(returnData)
    .catch(catchError)

const deleteAccountingPeriod = ({ companyCode, id }) =>
  axios
    .delete(`${companyCode}/accountingperiods/${id}`, config())
    .then(returnData)
    .catch(catchError)

const getAccountingPeriods = ({ companyCode }) =>
  axios
    .get(`${companyCode}/accountingperiods`, config())
    .then(returnData)
    .catch(catchError)

const patchAccountingPeriod = ({ companyCode, id, patch }) =>
  axios
    .patch(`${companyCode}/accountingperiods/${id}`, patch, config())
    .then(returnData)
    .catch(catchError)

const setActiveAccountingPeriod = ({ companyCode, id }) =>
  axios
    .post(`${companyCode}/accountingperiods/${id}/active`, null, config())
    .then(returnData)
    .catch(catchError)

const accountingPeriodApi = {
  createAccountingPeriod,
  deleteAccountingPeriod,
  getAccountingPeriods,
  patchAccountingPeriod,
  setActiveAccountingPeriod,
}

export default accountingPeriodApi
