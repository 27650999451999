/*
 *
 * DimensionValue actions
 *
 */

import {
  GET_DIMENSION_WITH_VALUE,
  GET_DIMENSION_WITH_VALUE_ERROR,
  GET_DIMENSION_WITH_VALUE_SUCCESS,
  UPDATE_DIMENSION_VALUE,
  UPDATE_DIMENSION_VALUE_ERROR,
  UPDATE_DIMENSION_VALUE_SUCCESS,
} from './constants'

export const getDimensionWithValue = ({
  companyCode,
  dimensionId,
  dimensionValueId,
}) => ({
  type: GET_DIMENSION_WITH_VALUE,
  companyCode,
  dimensionId,
  dimensionValueId,
})

export const getDimensionWithValueError = (error) => ({
  type: GET_DIMENSION_WITH_VALUE_ERROR,
  error,
})

export const getDimensionWithValueSuccess = ({
  dimension,
  dimensionValueId,
}) => ({
  type: GET_DIMENSION_WITH_VALUE_SUCCESS,
  dimension,
  dimensionValueId,
})

export const updateDimensionValue = ({
  companyCode,
  dimensionId,
  dimensionValue,
  updatedDimensionValue,
  hideFromBudgeting,
  hideFromReporting,
}) => ({
  type: UPDATE_DIMENSION_VALUE,
  companyCode,
  dimensionId,
  dimensionValue,
  updatedDimensionValue,
  hideFromBudgeting,
  hideFromReporting,
})

export const updateDimensionValueError = (error) => ({
  type: UPDATE_DIMENSION_VALUE_ERROR,
  error,
})

export const updateDimensionValueSuccess = ({
  companyCode,
  dimensionId,
  dimensionValue,
}) => ({
  type: UPDATE_DIMENSION_VALUE_SUCCESS,
  companyCode,
  dimensionId,
  dimensionValue,
})
