/*
 *
 * AccountSchemeTemplate actions
 *
 */

import {
  ADD_CHILD_GROUP,
  ADD_GROUP,
  ADD_GROUP_ERROR,
  ADD_GROUP_SUCCESS,
  ADD_ROW,
  ADD_ROW_ERROR,
  ADD_ROW_SUCCESS,
  DELETE_ROW,
  DELETE_ROWS,
  DELETE_ROW_ERROR,
  DELETE_ROW_SUCCESS,
  GET_ACCOUNT_SCHEME_TEMPLATE,
  GET_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  GET_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  UPDATE_GROUP,
  UPDATE_GROUP_ERROR,
  UPDATE_GROUP_SUCCESS,
  UPDATE_ROW,
  UPDATE_ROW_ERROR,
  UPDATE_ROW_SUCCESS,
} from './constants'

export const addChildGroup = ({
  accountGroupType,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  hideGroupSum,
  name,
  path,
  rowFactor,
  defaultForecastingParameters,
}) => ({
  type: ADD_CHILD_GROUP,
  accountGroupType,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  hideGroupSum,
  name,
  path,
  rowFactor,
  defaultForecastingParameters,
})
export const addGroup = ({
  accountGroupType,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  hideGroupSum,
  name,
  path,
  rowFactor,
  defaultForecastingParameters,
}) => ({
  type: ADD_GROUP,
  accountGroupType,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  hideGroupSum,
  name,
  path,
  rowFactor,
  defaultForecastingParameters,
})
export const addGroupError = (error) => ({ type: ADD_GROUP_ERROR, error })
export const addGroupSuccess = ({ tree }) => ({
  type: ADD_GROUP_SUCCESS,
  tree,
})

export const addRow = ({
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  endAccount,
  path,
  rowFactor,
  startAccount,
  defaultForecastingParameters,
}) => ({
  type: ADD_ROW,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  endAccount,
  path,
  rowFactor,
  startAccount,
  defaultForecastingParameters,
})
export const addRowError = (error) => ({ type: ADD_ROW_ERROR, error })
export const addRowSuccess = ({ tree }) => ({ type: ADD_ROW_SUCCESS, tree })

export const deleteRow = ({
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  path,
}) => ({
  type: DELETE_ROW,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  path,
})

export const deleteRowError = (error) => ({ type: DELETE_ROW_ERROR, error })
export const deleteRowSuccess = ({ tree }) => ({
  type: DELETE_ROW_SUCCESS,
  tree,
})

export const deleteRows = ({
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  rowIds,
}) => ({
  type: DELETE_ROWS,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  rowIds,
})

export const getAccountSchemeTemplate = ({
  companyCode,
  customerCode,
  id,
}) => ({ type: GET_ACCOUNT_SCHEME_TEMPLATE, companyCode, customerCode, id })
export const getAccountSchemeTemplateError = (error) => ({
  type: GET_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  error,
})
export const getAccountSchemeTemplateSuccess = ({ tree }) => ({
  type: GET_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  tree,
})

export const updateGroup = ({
  accountGroupType,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  hideGroupSum,
  name,
  path,
  rowFactor,
  defaultForecastingParameters,
}) => ({
  type: UPDATE_GROUP,
  accountGroupType,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  hideGroupSum,
  name,
  path,
  rowFactor,
  defaultForecastingParameters,
})
export const updateGroupError = (error) => ({ type: UPDATE_GROUP_ERROR, error })
export const updateGroupSuccess = ({ tree }) => ({
  type: UPDATE_GROUP_SUCCESS,
  tree,
})

export const updateRow = ({
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  endAccount,
  path,
  rowFactor,
  startAccount,
  defaultForecastingParameters,
}) => ({
  type: UPDATE_ROW,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  endAccount,
  path,
  rowFactor,
  startAccount,
  defaultForecastingParameters,
})
export const updateRowError = (error) => ({ type: UPDATE_ROW_ERROR, error })
export const updateRowSuccess = ({ tree }) => ({
  type: UPDATE_ROW_SUCCESS,
  tree,
})
