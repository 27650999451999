/*
 *
 * Customers actions
 *
 */

import {
  TOGGLE_SHOW_CREATE_CUSTOMER_FORM,
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_ERROR,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ERROR,
  SET_CUSTOMERS_LOADING,
} from './constants'

export const toggleShowCreateCustomerForm = () => ({
  type: TOGGLE_SHOW_CREATE_CUSTOMER_FORM,
})

export const getCustomers = () => ({ type: GET_CUSTOMERS })
export const getCustomersSuccess = ({ customers }) => ({
  type: GET_CUSTOMERS_SUCCESS,
  customers,
})
export const getCustomersError = (error) => ({
  type: GET_CUSTOMERS_ERROR,
  error,
})

export const createCustomer = ({ customer, customerCode }) => ({
  type: CREATE_CUSTOMER,
  customer,
  customerCode,
})
export const createCustomerSuccess = ({ customer }) => ({
  type: CREATE_CUSTOMER_SUCCESS,
  customer,
})
export const createCustomerError = (error) => ({
  type: CREATE_CUSTOMER_ERROR,
  error,
})

export const deleteCustomer = ({ customerCode }) => ({
  type: DELETE_CUSTOMER,
  customerCode,
})
export const deleteCustomerSuccess = ({ customerCode }) => ({
  type: DELETE_CUSTOMER_SUCCESS,
  customerCode,
})
export const deleteCustomerError = (error) => ({
  type: DELETE_CUSTOMER_ERROR,
  error,
})

export const setCustomersLoading = () => ({ type: SET_CUSTOMERS_LOADING })
