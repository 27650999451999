/*
 *
 * DimensionValue constants
 *
 */

export const GET_DIMENSION_WITH_VALUE =
  'app/DimensionValue/GET_DIMENSION_WITH_VALUE'
export const GET_DIMENSION_WITH_VALUE_ERROR =
  'app/DimensionValue/GET_DIMENSION_WITH_VALUE_ERROR'
export const GET_DIMENSION_WITH_VALUE_SUCCESS =
  'app/DimensionValue/GET_DIMENSION_WITH_VALUE_SUCCESS'

export const UPDATE_DIMENSION_VALUE =
  'app/DimensionValue/UPDATE_DIMENSION_VALUE'
export const UPDATE_DIMENSION_VALUE_ERROR =
  'app/DimensionValue/UPDATE_DIMENSION_VALUE_ERROR'
export const UPDATE_DIMENSION_VALUE_SUCCESS =
  'app/DimensionValue/UPDATE_DIMENSION_VALUE_SUCCESS'
