export const getScope = ({ companyCode, customerCode }) =>
  companyCode || customerCode || 'System'

export const calculateDefinedInScope = (reportScheme) => {
  const COMPANY_SCOPE = 'Company'
  const CUSTOMER_SCOPE = 'Customer'
  const SYSTEM_SCOPE = 'System'
  let definedInScope = SYSTEM_SCOPE
  if (reportScheme.companies.length > 0) {
    definedInScope = COMPANY_SCOPE
  } else if (reportScheme.customers.length > 0) {
    definedInScope = CUSTOMER_SCOPE
  }
  return definedInScope
}
