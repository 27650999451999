import { Record } from 'immutable'

// This is needed because class is a keyword
class VoucherRecord extends Record({
  id: undefined,
  number: undefined,
  class: undefined,
  date: undefined,
  description: undefined,
  externalId: undefined,
  voucherLines: [],
  voucherType: undefined,
  generatorId: undefined,
}) {
  constructor(params) {
    const {
      id,
      number,
      date,
      description,
      externalId,
      voucherLines,
      generatorId,
    } = params
    super({
      id,
      number,
      date,
      description: description || 'No Description',
      externalId,
      voucherLines,
      voucherType: params.class,
      generatorId,
    })
  }
}

export default VoucherRecord
