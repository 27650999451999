import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import dimensionsApi from 'api/DimensionsApi'
import {
  UPDATE_DIMENSION_SUCCESS,
  CREATE_DIMENSION_VALUE_SUCCESS,
  DELETE_DIMENSION_VALUE_SUCCESS,
} from 'containers/Dimension/constants'
import { UPDATE_DIMENSION_VALUE_SUCCESS } from 'containers/DimensionValue/constants'

import {
  BULK_DELETE_DIMENSION,
  BULK_DELETE_DIMENSION_ERROR,
  CREATE_DIMENSION,
  DELETE_DIMENSION,
  GET_BY_DIMENSIONVALUE,
  GET_DIMENSIONS,
  GET_DIMENSIONS_SEARCH,
} from './constants'
import {
  createDimensionError,
  createDimensionSuccess,
  deleteDimensionError,
  deleteDimensionSuccess,
  getDimensionsSuccess,
  getDimensionsError,
  getDimensionSearchSuccess,
  getByDimensionValueSuccess,
  bulkDeleteDimensionSuccess,
  bulkDeleteDimensionError,
} from './actions'

export function* deleteDimension(action) {
  const { companyCode, dimensionId } = action

  try {
    yield call(dimensionsApi.deleteDimension, companyCode, dimensionId)
    yield put(deleteDimensionSuccess({ dimensionId, companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteDimensionError, { companyCode }))
  }
}

export function* createDimension(action) {
  const {
    code,
    companyCode,
    dimensionType,
    displayName,
    name,
    hideFromBudgeting,
    hideFromReporting,
    useGeneratedDisplayName,
    parentId,
  } = action

  try {
    const dimension = yield call(dimensionsApi.createDimension, companyCode, {
      code,
      dimensionType,
      displayName,
      hideFromBudgeting,
      hideFromReporting,
      useGeneratedDisplayName,
      name,
      parentId,
    })
    yield put(createDimensionSuccess({ dimension, companyCode }))
  } catch (error) {
    yield put(handleError(error, createDimensionError, { companyCode }))
  }
}

export function* bulkDimensionDelete(action) {
  const { companyCode, dimensionId } = action
  try {
    yield call(dimensionsApi.deleteDimension, companyCode, dimensionId)
    yield put(bulkDeleteDimensionSuccess({ dimensionId, companyCode }))
  } catch (error) {
    yield put(
      handleError(error, bulkDeleteDimensionError, { companyCode, dimensionId })
    )
  }
}

export function* getDimensions(action) {
  const { companyCode, limit = 200, includeOperative = true } = action
  try {
    const dimensions = yield call(
      dimensionsApi.getDimensions,
      companyCode,
      includeOperative,
      undefined,
      limit
    )
    yield put(
      getDimensionsSuccess({ dimensions, companyCode, includeOperative })
    )
  } catch (error) {
    yield put(handleError(error, getDimensionsError, { companyCode }))
  }
}

export function* getDimensionsSearch(action) {
  const { companyCode, search, limit, includeOperative = true } = action

  try {
    const dimensions = yield call(
      dimensionsApi.getDimensions,
      companyCode,
      includeOperative,
      search,
      limit
    )

    yield put(
      getDimensionSearchSuccess({
        dimensions,
        companyCode,
        includeOperative,
        search,
      })
    )
  } catch (error) {
    yield put(handleError(error, getDimensionsError, { companyCode }))
  }
}

export function* getDimensionsByValue(action) {
  const { dvId } = action
  try {
    const [dimensionValue, companyCode] = yield call(
      dimensionsApi.getDimensionValue,
      dvId
    )
    yield put(
      getByDimensionValueSuccess({
        companyCode,
        value: dimensionValue,
      })
    )
  } catch (error) {
    yield put(handleError(error, getDimensionsError))
  }
}

const getDimensionByValueFromUpdateAction = ({ dimensionValue }) =>
  getDimensionsByValue({ dvId: dimensionValue.id })

export function* dimensionsSaga() {
  yield all([
    takeEvery(GET_DIMENSIONS, getDimensions),
    takeEvery(CREATE_DIMENSION, createDimension),
    takeEvery(DELETE_DIMENSION, deleteDimension),
    takeEvery(BULK_DELETE_DIMENSION, bulkDimensionDelete),
    takeLatest(BULK_DELETE_DIMENSION_ERROR, getDimensions),
    takeLatest(UPDATE_DIMENSION_SUCCESS, getDimensions),
    takeLatest(UPDATE_DIMENSION_VALUE_SUCCESS, getDimensions),
    takeLatest(CREATE_DIMENSION_VALUE_SUCCESS, getDimensions),
    takeLatest(DELETE_DIMENSION_VALUE_SUCCESS, getDimensions),
    takeLatest(
      UPDATE_DIMENSION_VALUE_SUCCESS,
      getDimensionByValueFromUpdateAction
    ),
    takeLatest(
      CREATE_DIMENSION_VALUE_SUCCESS,
      getDimensionByValueFromUpdateAction
    ),
    takeEvery(GET_BY_DIMENSIONVALUE, getDimensionsByValue),
    takeLatest(GET_DIMENSIONS_SEARCH, getDimensionsSearch),
  ])
}

export default dimensionsSaga
