import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  getCustomersSuccess,
  getCustomersError,
  createCustomerSuccess,
  createCustomerError,
  deleteCustomerSuccess,
  deleteCustomerError,
} from './actions'

import { handleError } from 'api/api-utils'
import customerApi from 'api/customer-management-api'

import { GET_CUSTOMERS, CREATE_CUSTOMER, DELETE_CUSTOMER } from './constants'

export function* deleteCustomer(action) {
  const { customerCode } = action

  try {
    yield call(customerApi.deleteCustomer, customerCode)
    yield put(deleteCustomerSuccess({ customerCode }))
  } catch (error) {
    yield put(handleError(error, deleteCustomerError))
  }
}

export function* createCustomer(action) {
  const { customer } = action

  try {
    const createdCustomer = yield call(customerApi.addCustomer, customer)
    yield put(createCustomerSuccess({ customer: createdCustomer }))
  } catch (error) {
    yield put(handleError(error, createCustomerError))
  }
}

export function* getCustomers() {
  try {
    const customers = yield call(customerApi.getCustomers)
    yield put(getCustomersSuccess({ customers }))
  } catch (error) {
    yield put(handleError(error, getCustomersError))
  }
}

export function* customersSaga() {
  yield all([
    takeLatest(GET_CUSTOMERS, getCustomers),
    takeEvery(CREATE_CUSTOMER, createCustomer),
    takeEvery(DELETE_CUSTOMER, deleteCustomer),
  ])
}

// All sagas to be loaded
export default customersSaga
