import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getRoleClaims = ({ roleId }) =>
  axios
    .get(`roleClaims/${roleId}`, config())
    .then(returnData)
    .catch(catchError)

const createRoleClaims = ({ roleId, claims }) =>
  axios
    .post(`roleClaims/${roleId}`, { claims }, config())
    .then(returnData)
    .catch(catchError)

const deleteRoleClaims = ({ roleId, claims }) =>
  axios // TODO: delete should use id but backend does not provide claim's id at the moment. We have to put claim into message's body.
    .delete(`roleclaims/${roleId}`, config({ data: { roleId, claims } }))
    .then(returnData)
    .catch(catchError)

const roleClaimsApi = {
  createRoleClaims,
  deleteRoleClaims,
  getRoleClaims,
}

export default roleClaimsApi
