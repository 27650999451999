/*
 *
 * CalculationConstant actions
 *
 */

import {
  CREATE_CALCULATION_CONSTANT_VALUE,
  CREATE_CALCULATION_CONSTANT_VALUE_ERROR,
  CREATE_CALCULATION_CONSTANT_VALUE_SUCCESS,
  DELETE_CALCULATION_CONSTANT_VALUE,
  DELETE_CALCULATION_CONSTANT_VALUE_ERROR,
  DELETE_CALCULATION_CONSTANT_VALUE_SUCCESS,
  EDIT_CALCULATION_CONSTANT_VALUE,
  TOGGLE_SHOW_CALCULATION_CONSTANT_VALUE_FORM,
  UPDATE_CALCULATION_CONSTANT_VALUE,
  UPDATE_CALCULATION_CONSTANT_VALUE_ERROR,
  UPDATE_CALCULATION_CONSTANT_VALUE_SUCCESS,
} from './constants'

export const toggleShowCalculationConstantValueForm = () => ({
  type: TOGGLE_SHOW_CALCULATION_CONSTANT_VALUE_FORM,
})

export const createCalculationConstantValue = ({
  budgetId,
  calculationConstantId,
  companyCode,
  customerCode,
  definedInScope,
  start,
  value,
}) => ({
  type: CREATE_CALCULATION_CONSTANT_VALUE,
  budgetId,
  calculationConstantId,
  companyCode,
  customerCode,
  definedInScope,
  start,
  value,
})
export const createCalculationConstantValueError = (error) => ({
  type: CREATE_CALCULATION_CONSTANT_VALUE_ERROR,
  error,
})
export const createCalculationConstantValueSuccess = () => ({
  type: CREATE_CALCULATION_CONSTANT_VALUE_SUCCESS,
})

export const deleteCalculationConstantValue = ({
  budgetId,
  calculationConstantId,
  companyCode,
  customerCode,
  definedInScope,
  index,
}) => ({
  type: DELETE_CALCULATION_CONSTANT_VALUE,
  budgetId,
  calculationConstantId,
  companyCode,
  customerCode,
  definedInScope,
  index,
})
export const deleteCalculationConstantValueError = (error) => ({
  type: DELETE_CALCULATION_CONSTANT_VALUE_ERROR,
  error,
})
export const deleteCalculationConstantValueSuccess = () => ({
  type: DELETE_CALCULATION_CONSTANT_VALUE_SUCCESS,
})

export const editCalculationConstantValue = ({ index }) => ({
  type: EDIT_CALCULATION_CONSTANT_VALUE,
  index,
})

export const updateCalculationConstantValue = ({
  budgetId,
  calculationConstant,
  companyCode,
  customerCode,
  edit,
  start,
  value,
}) => ({
  type: UPDATE_CALCULATION_CONSTANT_VALUE,
  budgetId,
  calculationConstant,
  companyCode,
  customerCode,
  edit,
  start,
  value,
})
export const updateCalculationConstantValueError = (error) => ({
  type: UPDATE_CALCULATION_CONSTANT_VALUE_ERROR,
  error,
})
export const updateCalculationConstantValueSuccess = () => ({
  type: UPDATE_CALCULATION_CONSTANT_VALUE_SUCCESS,
})
