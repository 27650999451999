/*
 *
 * IntegrationLogs constants
 *
 */

export const GET_INTEGRATION_LOGS = 'app/IntegrationLogs/GET_INTEGRATION_LOGS'

export const GET_INTEGRATION_LOGS_ERROR =
  'app/IntegrationLogs/GET_INTEGRATION_LOGS_ERROR'
export const GET_INTEGRATION_LOGS_SUCCESS =
  'app/IntegrationLogs/GET_INTEGRATION_LOGS_SUCCESS'

export const INTEGRATION_LOG_DEFAULT_PAGE_SIZE = 100

export const INTEGRATION_LOG_ITEM_TYPE_ALL = 'All'
export const INTEGRATION_LOG_ITEM_TYPES = [
  INTEGRATION_LOG_ITEM_TYPE_ALL,
  'Information',
  'Error',
]
