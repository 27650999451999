import { Record, List, Map, fromJS } from 'immutable'

import AccountingPeriodRecord from './AccountingPeriod/AccountingPeriodRecord'
import AccountRangeValue from './RollingRules/AccountRangeValue'
import AccountRecord from './Account/AccountRecord'
import AccountUpdateRecord from './Account/AccountUpdateRecord'
import AccountSchemeRecord from './AccountScheme/AccountSchemeRecord'
import AccountSchemeGroupRecord from './AccountScheme/AccountSchemeGroupRecord'
import AccountSchemeTemplateRecord from './AccountSchemeTemplate/AccountSchemeTemplateRecord'
import AccountSchemeTemplateRowGroupRecord from './AccountSchemeTemplate/AccountSchemeTemplateRowGroupRecord'
import AccountSchemeTemplateRowRecord from './AccountSchemeTemplate/AccountSchemeTemplateRowRecord'
import AccountSchemeTemplateUpdateRowGroupRecord from './AccountSchemeTemplate/AccountSchemeTemplateUpdateRowGroupRecord'
import AccountSchemeTemplateUpdateRowRecord from './AccountSchemeTemplate/AccountSchemeTemplateUpdateRowRecord'
import BudgetBalanceSchemeNode from './budget-balance-scheme-node'
import BudgetDataRecord from './budget-data'
import BudgetDataQueryRecord from './budget-data-query'
import BudgetRecord from './budget'
import BudgetBalanceRecord from './Budget/BudgetBalanceRecord'
import BudgetValueRecord from './budget-value'
import CalculationConstantRecord from './CalculationConstants/CalculationConstantRecord'
import CalculationConstantValueRecord from './CalculationConstants/CalculationConstantValueRecord'
import ColumnSpecRecord from './column-spec'
import CompanyRecord from './Company/CompanyRecord'
import CustomerRecord from './customer'
import DatasourceRecord from './datasource'
import DimensionRecord from './dimension'
import DimensionTargetRecord from './dimension-target'
import DimensionValueRecord from './dimension-value'
import ParsedAccountRecord from './Account/ParsedAccountRecord'
import ParsedAccountErrorRecord from './Account/ParsedAccountErrorRecord'
import ParsedBudgetBalanceRecord from './Budget/ParsedBudgetBalanceRecord'
import ParsedBudgetBalanceErrorRecord from './Budget/ParsedBudgetBalanceErrorRecord'
import PeriodRecord from './period'
import PermissionRecord from './permission'
import ReportingGroupRecord from './reporting-group'
import ReportRecord from './report'
import ReportSchemeRecord from './ReportScheme/ReportSchemeRecord'
import ReportSchemeRowGroupRecord from './ReportScheme/ReportSchemeRowGroupRecord'
import ReportSchemeRowRecord from './ReportScheme/ReportSchemeRowRecord'
import ReportSchemeRowUpdateRecord from './ReportScheme/ReportSchemeRowUpdateRecord'
import RoleRecord from './role'
import RollingRule from './RollingRules/RollingRule'
import RollingRuleSchemeTree from './RollingRules/RollingRuleSchemeTree'
import RollingRuleData from './RollingRules/RollingRuleData'
import RollingRuleValue from './RollingRules/RollingRuleValue'
import SchemeGroupRecord from './scheme-group'
import SubBudgetRecord from './sub-budget'
import TagRecord from './tag'
import UpdateCalculationConstantRecord from './CalculationConstants/UpdateCalculationConstantRecord'
import UserRecord from './user'
import UserRoleRecord from './user-role'
import VoucherRecord from './Voucher/VoucherRecord'
import FolderRecord from './folder'

// disable some rules for this trickery!
/* eslint-disable func-names, prefer-arrow-callback */
Record.constructor.prototype.fromJS = function(values) {
  const nested = fromJS(
    values,
    function(key, value) {
      // See https://facebook.github.io/immutable-js/docs/#/fromJS for docs on custom reviver functions
      if (this.prototype[key]) {
        if (this.prototype[key].constructor.prototype instanceof Record) {
          return this.prototype[key].constructor.fromJS(value.toJS()) // use toJS() here if nest more than once
        } else if (List.isList(this.prototype[key].constructor.prototype)) {
          return List(value.toJS())
        } else if (Map.isMap(this.prototype[key].constructor.prototype)) {
          return Map(value.toJS()) // use toJS() here if nest more than onc
        }
        return value
      }
      return value // suspicious.. perhaps not.
    }.bind(this)
  )
  return this(nested)
}
/* eslint-enable func-names, prefer-arrow-callback */

export {
  AccountingPeriodRecord,
  AccountRangeValue,
  AccountRecord,
  AccountUpdateRecord,
  AccountSchemeRecord,
  AccountSchemeGroupRecord,
  AccountSchemeTemplateRecord,
  AccountSchemeTemplateRowGroupRecord,
  AccountSchemeTemplateRowRecord,
  AccountSchemeTemplateUpdateRowGroupRecord,
  AccountSchemeTemplateUpdateRowRecord,
  BudgetBalanceSchemeNode,
  BudgetBalanceRecord,
  BudgetDataRecord,
  BudgetDataQueryRecord,
  BudgetRecord,
  BudgetValueRecord,
  CalculationConstantRecord,
  CalculationConstantValueRecord,
  ColumnSpecRecord,
  CompanyRecord,
  CustomerRecord,
  DatasourceRecord,
  DimensionRecord,
  DimensionTargetRecord,
  DimensionValueRecord,
  ParsedAccountRecord,
  ParsedAccountErrorRecord,
  ParsedBudgetBalanceRecord,
  ParsedBudgetBalanceErrorRecord,
  PeriodRecord,
  PermissionRecord,
  ReportingGroupRecord,
  ReportSchemeRowGroupRecord,
  ReportSchemeRowRecord,
  ReportSchemeRowUpdateRecord,
  RoleRecord,
  RollingRule,
  RollingRuleSchemeTree,
  RollingRuleData,
  RollingRuleValue,
  ReportRecord,
  ReportSchemeRecord,
  SchemeGroupRecord,
  SubBudgetRecord,
  TagRecord,
  UpdateCalculationConstantRecord,
  UserRecord,
  UserRoleRecord,
  VoucherRecord,
  FolderRecord,
}
