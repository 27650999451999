/*
 *
 * RollingRule constants
 *
 */

export const ADD_ACCOUNT_RANGE = 'app/RollingRule/ADD_ACCOUNT_RANGE'
export const ADD_ACCOUNT_RANGE_ERROR = 'app/RollingRule/ADD_ACCOUNT_RANGE_ERROR'
export const ADD_ACCOUNT_RANGE_SUCCESS =
  'app/RollingRule/ADD_ACCOUNT_RANGE_SUCCESS'

export const GET_ROLLING_RULE_TREE_AND_DATA =
  'app/RollingRule/GET_ROLLING_RULE_TREE_AND_DATA'
export const GET_ROLLING_RULE_TREE_AND_DATA_ERROR =
  'app/RollingRule/GET_ROLLING_RULE_TREE_AND_DATA_ERROR'
export const GET_ROLLING_RULE_TREE_AND_DATA_SUCCESS =
  'app/RollingRule/GET_ROLLING_RULE_TREE_AND_DATA_SUCCESS'

export const UPDATE_ROLLING_RULE_DATA =
  'app/RollingRule/UPDATE_ROLLING_RULE_DATA'
export const UPDATE_ROLLING_RULE_DATA_ERROR =
  'app/RollingRule/UPDATE_ROLLING_RULE_DATA_ERROR'
export const UPDATE_ROLLING_RULE_DATA_SUCCESS =
  'app/RollingRule/UPDATE_ROLLING_RULE_DATA_SUCCESS'

// Not implemented
export const DISTRIBUTION_BY_ACCOUNTS = 'DistributionByAccounts'

export const DISTRIBUTION = 'Distribution'
export const DISTRIBUTION_TO_SIBLINGS = 'DistributionToSiblings'

export const ROLLING_RULE_TYPES = [DISTRIBUTION, DISTRIBUTION_TO_SIBLINGS]

export const TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM =
  'app/RollingRule/TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM'

export const REPLACE_ACCOUNT_RANGES = 'app/RollingRule/REPLACE_ACCOUNT_RANGES'
export const REPLACE_ACCOUNT_RANGES_ERROR =
  'app/RollingRule/REPLACE_ACCOUNT_RANGES_ERROR'
export const REPLACE_ACCOUNT_RANGES_SUCCESS =
  'app/RollingRule/REPLACE_ACCOUNT_RANGES_SUCCESS'
