import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getBudgetTags = ({ companyCode, budgetId }) =>
  axios
    .get(`${companyCode}/budgets/${budgetId}/tags`, config())
    .then(returnData)
    .catch(catchError)

const createBudgetTag = ({ companyCode, budgetId, tag }) =>
  axios
    .post(`${companyCode}/budgets/${budgetId}/tags`, tag, config())
    .then(returnData)
    .catch(catchError)

const deleteBudgetTag = ({ companyCode, budgetId, tagId }) =>
  axios
    .delete(`${companyCode}/budgets/${budgetId}/tags/${tagId}`, config())
    .then(returnData)
    .catch(catchError)

const patchBudgetTag = ({ companyCode, budgetId, tagId, patch }) =>
  axios
    .patch(`${companyCode}/budgets/${budgetId}/tags/${tagId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const budgetTagApi = {
  getBudgetTags,
  createBudgetTag,
  deleteBudgetTag,
  patchBudgetTag,
}

export default budgetTagApi
