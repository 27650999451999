/*
 * Dimensions Messages
 *
 * This contains all the text for the Dimensions component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  actions: {
    id: 'app.containers.Dimensions.createDimension',
    defaultMessage: 'Actions',
  },
  createDimension: {
    id: 'app.containers.Dimensions.createDimension',
    defaultMessage: 'Create dimension',
  },
  delete: {
    id: 'app.containers.Dimensions.delete',
    defaultMessage: 'Delete',
  },
  deleteFailed: {
    id: 'app.containers.Dimensions.deleteFailed',
    defaultMessage: 'Delete failed',
  },
  header: {
    id: 'app.containers.Dimensions.header',
    defaultMessage: 'Dimensions',
  },
  headerDimensions: {
    id: 'app.containers.Dimensions.headerDimensions',
    defaultMessage: 'dimensions',
  },
  editDimension: {
    id: 'app.containers.Dimension.editDimension',
    defaultMessage: 'Update Dimension',
  },
  bulkDeleteConfirmation: {
    id: 'app.containers.Dimensions.bulkDeleteConfirmation',
    defaultMessage: 'Are you sure you want to delete the selected dimensions?',
  },
  noName: {
    id: 'app.containers.Dimensions.noName',
    defaultMessage: 'No Dimension Name',
    description: 'Text to show when there is no dimension name',
  },
  newDimension: {
    id: 'app.containers.Dimensions.newDimension',
    defaultMessage: 'New Dimension',
  },
  reportingGroups: {
    id: 'app.containers.Dimensions.reportingGroups',
    defaultMessage: 'Reporting Groups',
  },
  tableDimension: {
    id: 'app.containers.Dimensions.tableDimension',
    defaultMessage: 'Name',
  },
  tableDimensionType: {
    id: 'app.containers.Dimensions.tableDimensionType',
    defaultMessage: 'Type',
  },
  tableDimensionValues: {
    id: 'app.containers.Dimensions.tableDimensionValues',
    defaultMessage: 'Dimension values',
  },
  tableDimensionCode: {
    id: 'app.containers.Dimensions.tableDimensionCode',
    defaultMessage: 'Code',
  },
  tableParent: {
    id: 'app.containers.Dimensions.tableParent',
    defaultMessage: 'Parent',
  },
  type0: {
    id: 'app.containers.Dimensions.type0',
    defaultMessage: 'Balance',
    description: 'DimensionType: Dimension',
  },
  type1: {
    id: 'app.containers.Dimensions.type1',
    defaultMessage: 'Operative',
    description: 'DimensionType: Dimension',
  },

  typeDimension: {
    id: 'app.containers.Dimensions.typeDimension',
    defaultMessage: 'Balance',
    description: 'DimensionType: Dimension',
  },
  typeOperativeItemDimension: {
    id: 'app.containers.Dimensions.typeOperativeItemDimension',
    defaultMessage: 'Operative',
    description: 'DimensionType: Dimension',
  },
  hideBoth: {
    id: 'app.containers.Dimensions.hideBoth',
    defaultMessage: 'Both',
  },
  hideBudget: {
    id: 'app.containers.Dimensions.hideBudget',
    defaultMessage: 'Budgeting',
  },
  hideReporting: {
    id: 'app.containers.Dimensions.hideReporting',
    defaultMessage: 'Reporting',
  },
  hideNone: {
    id: 'app.containers.Dimensions.hideNone',
    defaultMessage: '-',
  },
  tableDimensionVisibility: {
    id: 'app.containers.Dimensions.tableDimensionVisibility',
    defaultMessage: 'Hidden in',
  },
})
