import diff from 'immutablediff'
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import reportSchemeApi from 'api/ReportSchemeApi'

import {
  createReportSchemeRowError,
  createReportSchemeRowSuccess,
  deleteReportSchemeRowError,
  deleteReportSchemeRowSuccess,
  getReportSchemeTreeError,
  getReportSchemeTreeSuccess,
  togglePercentageTargetSuccess,
  togglePercentageTargetError,
  updateReportSchemeRowError,
  updateReportSchemeRowSuccess,
  moveReportSchemeRowSuccess,
  moveReportSchemeRowError,
  copyReportSchemeRowSuccess,
  copyReportSchemeRowError,
} from './actions'
import {
  COPY_REPORT_SCHEME_ROW,
  CREATE_REPORT_SCHEME_ROW,
  DELETE_REPORT_SCHEME_ROW,
  GET_REPORT_SCHEME_TREE,
  MOVE_REPORT_SCHEME_ROW,
  TOGGLE_PERCENTAGE_TARGET,
  UPDATE_REPORT_SCHEME_ROW,
} from './constants'

import { ReportSchemeRowUpdateRecord } from 'records'
import { Map } from 'immutable'

const getOrderAndPath = ({ index, path }) => {
  if (index === undefined) {
    return {
      order: 0,
      path: `${path}/Children/0`,
    }
  }

  const order = index + 1
  return {
    order,
    path: `${path
      .split('/')
      .slice(0, -1)
      .join('/')}/${order}`,
  }
}

// Individual exports for testing
export function* createReportSchemeRow(action) {
  try {
    const {
      companyCode,
      customerCode,
      form,
      index,
      reportSchemeId,
      reportSchemeRowType,
    } = action

    // TODO: Order should be done by patch automatically and this should be
    // fixed in the backend
    const { order, path } = getOrderAndPath({ index, path: action.path })
    const patch = [
      {
        op: 'add',
        path,
        value: {
          ...form,
          order,
          reportSchemeRowType,
        },
      },
    ]

    // TODO: This would be better optimez if the call returned patch
    // instead of the whole tree
    const tree = yield call(reportSchemeApi.patchReportSchemeTree, {
      companyCode,
      customerCode,
      patch,
      reportSchemeId,
    })

    yield put(createReportSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, createReportSchemeRowError))
  }
}

const adjustTargetPathAndCheckPosition = ({ source, target, moveAfter }) => {
  const splitSource = source.path.split('/')
  const splitTarget = target.path.split('/')
  const pathComponents = target.path.split('/')
  const positionIndex = pathComponents.length - 1
  if (moveAfter) {
    pathComponents[positionIndex] = (
      parseInt(pathComponents[positionIndex]) + 1
    ).toString()
    target.path = pathComponents.join('/')
  }
  const isSibling = target.path.startsWith(splitSource.slice(0, -1).join('/'))
  const isMovingInside = splitTarget.length > splitSource.length
  const isBeforeInArray =
    splitTarget[splitSource.length - 1] > splitSource[splitSource.length - 1]

  if (
    isSibling &&
    isBeforeInArray &&
    !isNaN(positionIndex) &&
    !isMovingInside
  ) {
    pathComponents[positionIndex] = (
      parseInt(pathComponents[positionIndex]) - 1
    ).toString()
    target.path = pathComponents.join('/')
  }
  return isSibling && isMovingInside && isBeforeInArray
}

export function* deleteReportSchemeRow(action) {
  try {
    const { companyCode, customerCode, reportSchemeId, path } = action
    const patch = [
      {
        op: 'remove',
        path,
      },
    ]
    const tree = yield call(reportSchemeApi.patchReportSchemeTree, {
      companyCode,
      customerCode,
      patch,
      reportSchemeId,
    })
    yield put(deleteReportSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, deleteReportSchemeRowError))
  }
}

export function* copyReportSchemeRow(action) {
  try {
    const {
      companyCode,
      customerCode,
      reportSchemeId,
      source,
      target,
      moveAfter,
    } = action

    if (moveAfter) {
      const pathComponents = target.path.split('/')
      const positionIndex = pathComponents.length - 1
      pathComponents[positionIndex] = (
        parseInt(pathComponents[positionIndex]) + 1
      ).toString()
      target.path = pathComponents.join('/')
    }
    const patch = [
      {
        op: 'copy',
        from: `${source.path}`,
        path: `${target.path}`,
      },
      {
        op: 'replace',
        path: `${target.path}/name`,
        value: `${source.name} (copy)`,
      },
    ]
    const tree = yield call(reportSchemeApi.patchReportSchemeTree, {
      companyCode,
      customerCode,
      patch,
      reportSchemeId,
      removeIdsAfterCopy: true,
    })
    yield put(copyReportSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, copyReportSchemeRowError))
  }
}

export function* moveReportSchemeRow(action) {
  try {
    const {
      companyCode,
      customerCode,
      reportSchemeId,
      source,
      target,
      moveAfter,
    } = action
    //To fix moving entity inside array sibling bug use copy remove & remove
    const patch = adjustTargetPathAndCheckPosition({
      source,
      target,
      moveAfter,
    })
      ? //Can't use this always, since if when moving to sibling before source, copy & remove will crash
        [
          {
            op: 'copy',
            from: `${source.path}`,
            path: `${target.path}`,
          },
          {
            op: 'remove',
            path: `${source.path}`,
          },
        ]
      : [
          {
            op: 'move',
            from: `${source.path}`,
            path: `${target.path}`,
          },
        ]
    const tree = yield call(reportSchemeApi.patchReportSchemeTree, {
      companyCode,
      customerCode,
      patch,
      reportSchemeId,
    })
    yield put(moveReportSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, moveReportSchemeRowError))
  }
}

export function* getReportSchemeTree({ companyCode, customerCode, id }) {
  try {
    const tree = yield call(reportSchemeApi.getReportSchemeTree, {
      companyCode,
      customerCode,
      id,
    })
    yield put(getReportSchemeTreeSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, getReportSchemeTreeError))
  }
}
export function* togglePercentageTarget({
  companyCode,
  customerCode,
  groupId,
  operation,
  reportSchemeId,
}) {
  try {
    const patch = [
      {
        op: operation,
        path: '/percentageTargets/-',
        value: {
          name: '%', // Business said that %-sign is enough at the moment
          schemeGroupId: groupId,
        },
      },
    ]
    const result = yield call(reportSchemeApi.patchReportScheme, {
      companyCode,
      customerCode,
      patch,
      reportSchemeId,
    })
    yield put(
      togglePercentageTargetSuccess({
        companyCode,
        customerCode,
        result,
      })
    )
  } catch (error) {
    yield put(togglePercentageTargetError(error))
  }
}

export function* updateReportSchemeRow(action) {
  try {
    const {
      companyCode,
      customerCode,
      form,
      // index,
      reportSchemeId,
      reportSchemeRowType,
      row,
    } = action

    const patch = diff(
      new ReportSchemeRowUpdateRecord({ ...row.toJS() }),
      new Map({ ...form, reportSchemeRowType })
    )
      .filter((item) => !isOperationRemoveChildren(item, reportSchemeRowType))
      .toJS()
      .map((item) => ({
        ...item,
        path: `${action.path}${item.path}`,
      }))

    const tree = yield call(reportSchemeApi.patchReportSchemeTree, {
      companyCode,
      customerCode,
      patch,
      reportSchemeId,
    })

    yield put(updateReportSchemeRowSuccess({ tree }))
  } catch (error) {
    yield put(handleError(error, updateReportSchemeRowError))
  }
}

const isOperationRemoveChildren = (item) =>
  item.get('op') === 'remove' && item.get('path') === '/children'

// All sagas to be loaded
export function* reportSchemeSaga() {
  yield all([
    takeEvery(CREATE_REPORT_SCHEME_ROW, createReportSchemeRow),
    takeEvery(DELETE_REPORT_SCHEME_ROW, deleteReportSchemeRow),
    takeEvery(MOVE_REPORT_SCHEME_ROW, moveReportSchemeRow),
    takeLatest(GET_REPORT_SCHEME_TREE, getReportSchemeTree),
    takeEvery(TOGGLE_PERCENTAGE_TARGET, togglePercentageTarget),
    takeEvery(UPDATE_REPORT_SCHEME_ROW, updateReportSchemeRow),
    takeEvery(COPY_REPORT_SCHEME_ROW, copyReportSchemeRow),
  ])
}

export default reportSchemeSaga
