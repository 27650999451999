import diff from 'immutablediff'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from 'api/api-utils'
import accountApi from 'api/AccountApi'
import { AccountRecord, AccountUpdateRecord } from 'records'
import accountParser from 'utils/file-parsers/accountParser'

import {
  createAccountError,
  createAccountSuccess,
  deleteAccountError,
  deleteAccountSuccess,
  getAccountsError,
  getAccountsSuccess,
  importAccountsError,
  importAccountsSuccess,
  parseAccountsError,
  parseAccountsSuccess,
  startAccountSchemeCreation,
  updateAccountError,
  updateAccountSuccess,
} from './actions'
import {
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  GET_ACCOUNTS,
  IMPORT_ACCOUNTS,
  IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME,
  PARSE_ACCOUNTS,
  UPDATE_ACCOUNT,
} from './constants'
import {
  toggleShowCreateAccountScheme,
  resetAccountSchemesState,
} from '../AccountSchemes/actions'

// Individual exports for testing
export function* createAccount(action) {
  const {
    companyCode,
    accountNumber,
    isConsolidatedAccount,
    targetCompanyId,
    name,
    reportingCode,
  } = action

  try {
    const account = yield call(accountApi.createAccount, {
      accountNumber,
      companyCode,
      isConsolidatedAccount,
      targetCompanyId,
      name,
      reportingCode,
    })
    yield put(createAccountSuccess({ account, companyCode }))
  } catch (error) {
    yield put(handleError(error, createAccountError))
  }
}
export function* deleteAccount(action) {
  const { accountNumber, companyCode } = action

  try {
    yield call(accountApi.deleteAccount, {
      accountNumber,
      companyCode,
    })
    yield put(deleteAccountSuccess({ accountNumber, companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteAccountError))
  }
}

export function* getAccounts(action) {
  const { companyCode } = action

  try {
    const accounts = yield call(accountApi.getAccounts, { companyCode })
    yield put(getAccountsSuccess({ accounts, companyCode }))
  } catch (error) {
    yield put(handleError(error, getAccountsError))
  }
}

export function* importAccounts(action) {
  const { validAccounts, companyCode } = action

  const accounts = validAccounts
    .map((account) => new AccountRecord(account))
    .toJS()

  try {
    yield call(accountApi.importAccounts, { accounts, companyCode })
    yield put(importAccountsSuccess({ accounts, companyCode }))
    yield put(startAccountSchemeCreation())
  } catch (error) {
    yield put(handleError(error, importAccountsError))
  }
}
export function* moveToAccountSchemes(action) {
  try {
    yield put(resetAccountSchemesState())
    yield put(toggleShowCreateAccountScheme())
    action?.navigate(
      `/${action.customerCode}/${action.companyCode}/accountschemes`
    )
  } catch (error) {
    yield put(handleError(error, importAccountsError))
  }
}
export function* parseAccounts(action) {
  const { file, allowLetters, min, max, companies } = action

  try {
    const { parsedAccounts, parsedFile } = yield accountParser({
      file,
      allowLetters,
      min,
      max,
      companies,
    })

    yield put(parseAccountsSuccess({ parsedAccounts, parsedFile }))
  } catch (error) {
    yield put(handleError(error, parseAccountsError))
  }
}

export function* updateAccount(action) {
  const { account, companyCode, updatedValues } = action

  const patch = diff(
    new AccountUpdateRecord(account),
    new AccountUpdateRecord(updatedValues)
  ).toJS()

  try {
    const updatedAccount = yield call(accountApi.patchAccount, {
      accountNumber: account.accountNumber,
      companyCode,
      patch,
    })
    yield put(updateAccountSuccess({ account: updatedAccount, companyCode }))
  } catch (error) {
    yield put(handleError(error, updateAccountError))
  }
}

// All sagas to be loaded
export function* accountsSaga() {
  yield all([
    takeEvery(CREATE_ACCOUNT, createAccount),
    takeEvery(DELETE_ACCOUNT, deleteAccount),
    takeEvery(GET_ACCOUNTS, getAccounts),
    takeEvery(IMPORT_ACCOUNTS, importAccounts),
    takeEvery(PARSE_ACCOUNTS, parseAccounts),
    takeEvery(UPDATE_ACCOUNT, updateAccount),
    takeEvery(
      IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME,
      moveToAccountSchemes
    ),
  ])
}

export default accountsSaga
