import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import accountSchemeApi from 'api/AccountSchemeApi'

import {
  createAccountSchemeError,
  createAccountSchemeSuccess,
  deleteAccountSchemeError,
  deleteAccountSchemeSuccess,
  getAccountSchemesError,
  getAccountSchemesSuccess,
  setDefaultAccountSchemeError,
  setDefaultAccountSchemeSuccess,
  updateAccountSchemeError,
  updateAccountSchemeSuccess,
} from './actions'

import {
  CREATE_ACCOUNT_SCHEME,
  DELETE_ACCOUNT_SCHEME,
  GET_ACCOUNT_SCHEMES,
  SET_DEFAULT_ACCOUNT_SCHEME,
  UPDATE_ACCOUNT_SCHEME,
} from './constants'

// Individual exports for testing
export function* createAccountScheme(action) {
  const {
    accountSchemeTemplateId,
    companyCode,
    mapReportingCodes,
    name,
  } = action

  try {
    const accountScheme = yield call(accountSchemeApi.createAccountScheme, {
      accountSchemeTemplateId,
      companyCode,
      mapReportingCodes,
      name,
    })
    yield put(createAccountSchemeSuccess({ accountScheme }))
  } catch (error) {
    yield put(handleError(error, createAccountSchemeError))
  }
}

export function* deleteAccountScheme(action) {
  const { id, companyCode } = action

  try {
    yield call(accountSchemeApi.deleteAccountScheme, {
      id,
      companyCode,
    })
    yield put(deleteAccountSchemeSuccess({ id }))
  } catch (error) {
    yield put(handleError(error, deleteAccountSchemeError))
  }
}

export function* getAccountSchemes(action) {
  const { companyCode } = action

  try {
    const accountSchemes = yield call(accountSchemeApi.getAccountSchemes, {
      companyCode,
    })
    yield put(getAccountSchemesSuccess({ accountSchemes }))
  } catch (error) {
    yield put(handleError(error, getAccountSchemesError))
  }
}

export function* setDefaultAccountScheme({ companyCode, id }) {
  try {
    yield call(accountSchemeApi.setDefaultAccountScheme, { companyCode, id })
    yield put(setDefaultAccountSchemeSuccess({ id }))
  } catch (err) {
    yield put(handleError(err, setDefaultAccountSchemeError))
  }
}

export function* updateAccountScheme(action) {
  const { id, companyCode, name } = action
  const patch = [
    {
      op: 'replace',
      path: '/name',
      value: name,
    },
  ]

  try {
    const updatedAccountScheme = yield call(
      accountSchemeApi.patchAccountScheme,
      { id, companyCode, patch }
    )
    yield put(
      updateAccountSchemeSuccess({
        accountScheme: updatedAccountScheme,
      })
    )
  } catch (error) {
    yield put(handleError(error, updateAccountSchemeError))
  }
}

// All sagas to be loaded
export function* accountSchemesSaga() {
  yield all([
    takeLatest(CREATE_ACCOUNT_SCHEME, createAccountScheme),
    takeEvery(DELETE_ACCOUNT_SCHEME, deleteAccountScheme),
    takeLatest(GET_ACCOUNT_SCHEMES, getAccountSchemes),
    takeEvery(SET_DEFAULT_ACCOUNT_SCHEME, setDefaultAccountScheme),
    takeEvery(UPDATE_ACCOUNT_SCHEME, updateAccountScheme),
  ])
}

export default accountSchemesSaga
