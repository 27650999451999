import { Record } from 'immutable'

const AccountSchemeGroupRecord = Record({
  account: undefined,
  accountGroupType: undefined,
  children: undefined,
  editable: false,
  formula: '',
  id: undefined,
  name: '',
  normalizedFormula: '',
  order: 0,
  parentId: undefined,
  path: '',
  rowId: '',
  rows: undefined,
  rowFactor: undefined,
  rowType: '',
  type: '',
  level: 0,
  defaultForecastingParameters: undefined,
  accountRanges: [],
})

export default AccountSchemeGroupRecord
