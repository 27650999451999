import PropTypes from 'prop-types'
import React from 'react'
import { NavDropdown, Dropdown } from 'react-bootstrap'
import { injectIntl, intlShape } from 'react-intl'
import { HasClaim } from 'utils/Authorization'
import NavItem from './NavItem'

import messages from './messages'
import styles from './styles.module.scss'

const propTypes = {
  companyCode: PropTypes.string.isRequired,
  customerCode: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  permissions: PropTypes.array,
}

const CompanyDropdownMenu = ({
  companyCode,
  customerCode,
  intl: { formatMessage },
  permissions,
  navigate,
  setOpen,
  hasParent,
  open,
}) => {
  const path = `/${customerCode}/${companyCode}`
  const companyDropdownItems = [
    {
      to: `${path}/accounts`,
      messageId: messages.accounts,
      claim: HasClaim({ permissions, claim: 'Accounts' }),
    },
    {
      to: `${path}/accountingperiods`,
      messageId: messages.accountingPeriods,
      claim: HasClaim({ permissions, claim: 'AccountingPeriodsRead' }),
    },
    {
      to: `${path}/accountschemes`,
      messageId: messages.accountSchemes,
      claim: HasClaim({ permissions, claim: 'AccountSchemesRead' }),
    },
    {
      to: `${path}/accountschemetemplates`,
      messageId: messages.accountSchemeTemplates,
      claim: HasClaim({ permissions, claim: 'AccountSchemeTemplates' }),
    },
    {
      to: `${path}/calculationconstants`,
      messageId: messages.calculationConstants,
      claim: HasClaim({ permissions, claim: 'CalculationConstants' }),
    },
    {
      to: `${path}/dimensions`,
      messageId: messages.dimensions,
      claim:
        HasClaim({ permissions, claim: 'DimensionRead' }) ||
        HasClaim({ permissions, claim: 'DimensionValueRead' }),
    },
    {
      to: `${path}/reportinggroups`,
      messageId: messages.reportingGroups,
      claim: HasClaim({ permissions, claim: 'ReportingGroupRead' }),
    },
    {
      to: `${path}/reportSchemes`,
      messageId: messages.reportSchemes,
      claim: HasClaim({ permissions, claim: 'ReportsSchemesRead' }),
    },
    {
      to: `${path}/rollingrules`,
      messageId: messages.rollingRules,
      claim: HasClaim({ permissions, claim: 'RollingRuleTemplatesRead' }),
    },
    {
      to: `${path}/vouchers`,
      messageId: messages.vouchers,
      claim: HasClaim({ permissions, claim: 'VoucherRead' }),
    },
    {
      divider:
        HasClaim({ permissions, claim: 'RoleRead' }) ||
        HasClaim({ permissions, claim: 'UserRead' }) ||
        HasClaim({ permissions, claim: 'CompanyEdit' }),
      key: 1,
    },
    {
      to: `${path}/roles`,
      messageId: messages.roles,
      claim: HasClaim({ permissions, claim: 'RoleRead' }),
    },
    {
      to: `${path}/users`,
      messageId: messages.users,
      claim: HasClaim({ permissions, claim: 'UserRead' }),
    },
    {
      to: `/${customerCode}/companies/${companyCode}`,
      messageId: messages.settings,
      claim: HasClaim({ permissions, claim: 'CompanyEdit' }),
    },
    {
      divider: HasClaim({ permissions, claim: 'CompanyEdit' }),
      key: 2,
    },
    {
      to: `${path}/integrationLogs`,
      messageId: messages.integrationLogs,
      claim: HasClaim({ permissions, claim: 'CompanyEdit' }),
    },
  ]

  const claims = companyDropdownItems.map((item) => item.claim)
  if (claims.indexOf(true) === -1) {
    return null
  }

  return (
    <NavDropdown
      id="company-nav-manage-dropdown"
      title={
        hasParent
          ? formatMessage(messages.companyNavHeader)
          : formatMessage(messages.settings)
      }
      onToggle={(state) => setOpen(state)}
      open={open}
      className={
        hasParent
          ? styles.settingsTitleDropped
          : !open
          ? styles.settingsTitleInNav
          : `${styles.settingsTitleInNav} ${styles.active}`
      }
    >
      {companyDropdownItems.map((item) => {
        if (item.divider) {
          return <Dropdown.Divider key={`Divider${item.key}`} />
        }
        if (!item.claim) return null
        return (
          <NavItem
            formatMessage={formatMessage}
            to={item.to}
            messageId={item.messageId}
            divider={item.divider}
            key={item.messageId.id}
          />
        )
      })}
    </NavDropdown>
  )
}

CompanyDropdownMenu.propTypes = propTypes

export default injectIntl(CompanyDropdownMenu)
