const HasClaim = ({
  permissions = false,
  claim = '*',
  value, //If no value given check for any value. Use value = '*' if checkin for ALL-value
  allowAll = true,
}) => {
  //Permissions are valid
  if (!permissions || !Array.isArray(permissions)) {
    return false
  }

  // Convert value to string if it's not already
  if (value !== undefined && value !== null && typeof value !== 'string') {
    value = value.toString()
  }

  //Check that permissions array contains claim that matches requirements
  return permissions.some(
    (c) =>
      //Check if claims type matches required value OR claim is *-type and those are allowed
      (c.type === claim || (c.type === '*' && allowAll)) &&
      //Check if claim value is required OR value matches required value OR value is *
      (!value || c.value === value || c.value === '*')
  )
}

export { HasClaim }
