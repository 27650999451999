/*
 *
 * Dimensions reducer
 *
 */

import {
  DELETE_DIMENSION_VALUE,
  UPDATE_DIMENSION_ERROR,
  UPDATE_DIMENSION_SUCCESS,
} from 'containers/Dimension/constants'
import { fromJS, List, Map } from 'immutable'

import {
  CREATE_DIMENSION,
  CREATE_DIMENSION_ERROR,
  CREATE_DIMENSION_SUCCESS,
  DELETE_DIMENSION,
  DELETE_DIMENSION_ERROR,
  DELETE_DIMENSION_SUCCESS,
  GET_DIMENSIONS,
  GET_DIMENSIONS_ERROR,
  GET_DIMENSIONS_SUCCESS,
  GET_DIMENSIONS_SEARCH,
  GET_DIMENSIONS_SEARCH_SUCCESS,
  GET_BY_DIMENSIONVALUE,
  GET_BY_DIMENSIONVALUE_SUCCESS,
  BULK_DELETE_DIMENSION_ERROR,
  BULK_DELETE_DIMENSION,
  BULK_DELETE_DIMENSION_SUCCESS,
  DISMISS_BULK_DELETE_ERROR,
} from './constants'
import {
  UPDATE_DIMENSION_VALUE,
  UPDATE_DIMENSION_VALUE_ERROR,
} from 'containers/DimensionValue/constants'

const toMapById = (list, itemTransform = (item) => item) =>
  Map(list.map((item) => [item.id, itemTransform(item)]))

// `dimensions' are mapped by companyCode
const initialState = fromJS({
  dimensions: Map(),
  //inside the map is
  // companyCode: {
  //   dimensions: {
  //      [DimensionId]: {
  //          dimension: {...}
  //          values: {
  //                [DimensionValueId]: {...}
  //             } // Note that we can have values without the dimension. If they are still loading
  //        },
  // }
  //   searchedDimensions: {...}, same as above, but with searched values. Seperated since these might lose needed values on search change
  //   searchTerm: string, Search term that above map is based on
  //   dimensionSearchLoading: false,
  //   dimensionsLoading: false
  //   includeOperative: boolean, weather
  // },
  error: undefined,
  loading: false, //Todo refactor all places that use this top level loading to use specific company loading
  dimensionValueLoading: false,
  loadingDimensions: List(),
  errorDimensions: Map(),
})

function dimensionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DIMENSIONS:
      return state
        .set('loading', true)
        .setIn(['dimensions', action.companyCode, 'loading'], true)
        .set('error', undefined)

    case GET_DIMENSIONS_SEARCH:
      return state.setIn(
        ['dimensions', action.companyCode, 'dimensionSearchLoading'],
        true
      )

    case GET_DIMENSIONS_SEARCH_SUCCESS:
      const dimensions = toMapById(action.dimensions, (dimension) =>
        Map({
          values: toMapById(dimension.values),
          dimension: { ...dimension, values: undefined }, //Remove values from dimension models to save space
        })
      )
      return state
        .setIn(
          ['dimensions', action.companyCode, 'dimensionSearchLoading'],
          false
        )
        .setIn(
          //we can just overwrite them in search
          ['dimensions', action.companyCode, 'searchedDimensions'],
          dimensions
        )
    case UPDATE_DIMENSION_SUCCESS: {
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .updateIn(
          ['dimensions', action.companyCode, 'dimensions', action.dimension.id],
          (dimension) =>
            Map({
              values: toMapById(action.dimension.values),
              dimension: { ...action.dimension, values: undefined },
            })
        )
    }
    case GET_DIMENSIONS_SUCCESS: {
      const dimensions = toMapById(action.dimensions, (dimensions) =>
        Map({
          values: toMapById(dimensions.values),
          dimension: { ...dimensions, values: undefined }, //Remove values from dimension models to save space
        })
      )
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .setIn(['dimensions', action.companyCode, 'dimensions'], dimensions)
        .setIn(
          ['dimensions', action.companyCode, 'includeOperative'],
          action.includeOperative
        )
    }

    case GET_BY_DIMENSIONVALUE:
    case UPDATE_DIMENSION_VALUE:
      return state.set('dimensionValueLoading', true).set('error', false)

    case GET_BY_DIMENSIONVALUE_SUCCESS:
      return state
        .set('dimensionValueLoading', false)
        .setIn(
          [
            'dimensions',
            action.companyCode,
            'dimensions',
            action.value.dimensionId,
            'values',
            action.value.id,
          ],
          action.value
        )

    case CREATE_DIMENSION:
      return state.set('loading', true)

    case DELETE_DIMENSION:
      return state.set('loading', true)
    case BULK_DELETE_DIMENSION_SUCCESS:
    case DELETE_DIMENSION_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .deleteIn([
          'dimensions',
          action.companyCode,
          'dimensions',
          action.dimensionId,
        ])

    case CREATE_DIMENSION_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .setIn(
          ['dimensions', action.companyCode, 'dimensions', action.dimension.id],
          Map({ dimension: action.dimension, values: Map() })
        )
    case UPDATE_DIMENSION_VALUE_ERROR:
      return state.set('dimensionValueLoading', false)
    case BULK_DELETE_DIMENSION_ERROR:
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .update('loadingDimensions', (loadingDimensions) =>
          loadingDimensions.filter(
            (dimension) => dimension.id === action.dimensionId
          )
        )
        .setIn(
          ['errorDimensions', action.dimensionId],
          action.error.data.Message
        )

    case BULK_DELETE_DIMENSION:
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .update('loadingDimensions', (loadingDimensions) =>
          loadingDimensions.push(action.dimensionId)
        )
        .deleteIn(['errorDimensions', action.dimensionId])
    case DISMISS_BULK_DELETE_ERROR:
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .deleteIn(['errorDimensions', action.dimensionId])
    case UPDATE_DIMENSION_ERROR:
    case DELETE_DIMENSION_ERROR:
    case CREATE_DIMENSION_ERROR:
    case GET_DIMENSIONS_ERROR:
      return state
        .set('loading', false)
        .setIn(['dimensions', action.companyCode, 'loading'], false)
        .set('error', action.error)

    case DELETE_DIMENSION_VALUE:
      return state
        .deleteIn([
          'dimensions',
          action.companyCode,
          'dimensions',
          parseInt(action.dimensionId),
          'values',
          action.dimensionValueId,
        ])
        .deleteIn([
          'dimensions',
          action.companyCode,
          'searchedDimensions',
          parseInt(action.dimensionId),
          'values',
          action.dimensionValueId,
        ])

    default:
      return state
  }
}

export default dimensionsReducer
