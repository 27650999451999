export const COPY_SYSTEM_DASHBOARD =
  'app/SystemDashboards/COPY_SYSTEM_DASHBOARD'
export const COPY_SYSTEM_DASHBOARD_ERROR =
  'app/SystemDashboards/COPY_SYSTEM_DASHBOARD_ERROR'
export const COPY_SYSTEM_DASHBOARD_SUCCESS =
  'app/SystemDashboards/COPY_SYSTEM_DASHBOARD_SUCCESS'

export const COPY_SYSTEM_DASHBOARD_WIDGET =
  'app/SystemDashboards/COPY_SYSTEM_DASHBOARD_WIDGET'
export const COPY_SYSTEM_DASHBOARD_WIDGET_ERROR =
  'app/SystemDashboards/COPY_SYSTEM_DASHBOARD_WIDGET_ERROR'
export const COPY_SYSTEM_DASHBOARD_WIDGET_SUCCESS =
  'app/SystemDashboards/COPY_SYSTEM_DASHBOARD_WIDGET_SUCCESS'

export const CREATE_SYSTEM_DASHBOARD =
  'app/SystemDashboards/CREATE_SYSTEM_DASHBOARD'
export const CREATE_SYSTEM_DASHBOARD_ERROR =
  'app/SystemDashboards/CREATE_SYSTEM_DASHBOARD_ERROR'
export const CREATE_SYSTEM_DASHBOARD_SUCCESS =
  'app/SystemDashboards/CREATE_SYSTEM_DASHBOARD_SUCCESS'

export const CREATE_SYSTEM_DASHBOARD_WIDGET =
  'app/SystemDashboards/CREATE_SYSTEM_DASHBOARD_WIDGET'
export const CREATE_SYSTEM_DASHBOARD_WIDGET_ERROR =
  'app/SystemDashboards/CREATE_SYSTEM_DASHBOARD_WIDGET_ERROR'
export const CREATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS =
  'app/SystemDashboards/CREATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS'

export const DELETE_SYSTEM_DASHBOARD =
  'app/SystemDashboards/DELETE_SYSTEM_DASHBOARD'
export const DELETE_SYSTEM_DASHBOARD_ERROR =
  'app/SystemDashboards/DELETE_SYSTEM_DASHBOARD_ERROR'
export const DELETE_SYSTEM_DASHBOARD_SUCCESS =
  'app/SystemDashboards/DELETE_SYSTEM_DASHBOARD_SUCCESS'

export const DELETE_SYSTEM_DASHBOARD_WIDGET =
  'app/SystemDashboards/DELETE_SYSTEM_DASHBOARD_WIDGET'
export const DELETE_SYSTEM_DASHBOARD_WIDGET_ERROR =
  'app/SystemDashboards/DELETE_SYSTEM_DASHBOARD_WIDGET_ERROR'
export const DELETE_SYSTEM_DASHBOARD_WIDGET_SUCCESS =
  'app/SystemDashboards/DELETE_SYSTEM_DASHBOARD_WIDGET_SUCCESS'

export const GET_SYSTEM_DASHBOARDS =
  'app/SystemDashboards/GET_SYSTEM_DASHBOARDS'
export const GET_SYSTEM_DASHBOARDS_ERROR =
  'app/SystemDashboards/GET_SYSTEM_DASHBOARDS_ERROR'
export const GET_SYSTEM_DASHBOARDS_SUCCESS =
  'app/SystemDashboards/GET_SYSTEM_DASHBOARDS_SUCCESS'

export const UPDATE_SYSTEM_DASHBOARD =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD'
export const UPDATE_SYSTEM_DASHBOARD_ERROR =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_ERROR'
export const UPDATE_SYSTEM_DASHBOARD_SUCCESS =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_SUCCESS'

export const UPDATE_SYSTEM_DASHBOARD_WIDGET =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_WIDGET'
export const UPDATE_SYSTEM_DASHBOARD_WIDGET_ERROR =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_WIDGET_ERROR'
export const UPDATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS'

export const UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS'
export const UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_ERROR =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_ERROR'
export const UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_SUCCESS =
  'app/SystemDashboards/UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_SUCCESS'
export const REPORT_SELECTION_TYPE_SYSTEM = 1
