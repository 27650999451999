import classNames from 'classnames'
import _ from 'lodash'
import './styles.scss'

/**
 *
 * Button - Bootstrap Button Wrapper
 * - http://getbootstrap.com/css/#buttons
 *
 */

import PropTypes from 'prop-types'

import React, { forwardRef } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { BiRefresh, BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router'
import styles from './styles.module.scss'
import Icon from 'components/Icon'
import OverlayPopoverWrapper from 'components/OverlayPopoverWrapper'
import DeleteButtonWithConfirmation from './deleteButtonWithConfirmation'

import messages from './messages'

const Button = forwardRef(
  (
    {
      active,
      block,
      className,
      disabled,
      option,
      tagType,
      size,
      overlay,
      message,
      showOverlay,
      ...props
    },
    ref
  ) => {
    const Tag = tagType || 'button'

    const defaultTagProps = {
      a: { role: 'button' },
      button: { type: 'button' },
      input: { type: 'button' },
    }

    const extendedTagProps = _.extend(
      {},
      defaultTagProps[Tag],
      disabled && tagType !== 'a' && { disabled: true },
      props
    )

    const classes = classNames(
      'btn',
      (option && `btn-${option}`) || 'btn-default',
      size && `btn-${size}`,
      block && 'btn-block',
      active && 'active',
      disabled && tagType === 'a' && 'disabled',
      className
    )

    return overlay && !disabled ? (
      <OverlayPopoverWrapper
        placement={overlay.placement}
        message={message}
        delayShow={overlay.delayShow}
        disabled={disabled}
      >
        <Tag {...extendedTagProps} className={classes} />
      </OverlayPopoverWrapper>
    ) : (
      <Tag {...extendedTagProps} className={classes} ref={ref} />
    )
  }
)

Button.propTypes = {
  active: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  option: PropTypes.string,
  tagType: PropTypes.string,
  size: PropTypes.string,
  dataTestingId: PropTypes.string,
  overlay: PropTypes.object,
  message: PropTypes.object,
}

export default Button

/*
 * Default Bootstrap Buttons
 */
// Button Options: http://getbootstrap.com/css/?#buttons-options
export const PrimaryButton = (props) => <Button option="primary" {...props} />
export const SecondaryButton = (props) => (
  <Button option="secondary" {...props} />
)
export const SuccessButton = (props) => <Button option="success" {...props} />
export const InfoButton = (props) => <Button option="info" {...props} />
export const WarningButton = (props) => <Button option="warning" {...props} />
export const DangerButton = (props) => <Button option="danger" {...props} />
export const LinkButton = (props) => <Button option="link" {...props} />
// Button Sizes
export const LargeButton = (props) => <Button size="lg" {...props} />
export const SmallButton = (props) => <Button size="sm" {...props} />
export const ExtraSmallButton = (props) => <Button size="xs" {...props} />

/*
 * App buttons
 */

export const BackButton = (props) => {
  const navigate = useNavigate()
  return (
    <PrimaryButton {...props} onClick={() => navigate(-1)}>
      <BiArrowBack /> <FormattedMessage {...messages.back} />
    </PrimaryButton>
  )
}

export const CancelButton = ({ cancelButtonMessage, ...props }) => {
  const messageObject = cancelButtonMessage || messages.cancel
  return (
    <Button {...props}>
      <FormattedMessage {...messageObject} />
    </Button>
  )
}

export const CollapseButton = ({ name, iconStyles, className, ...props }) => {
  const classes = classNames(className, iconStyles && styles.iconStyles)

  return (
    <Button {...props} className={classes}>
      <Icon name={name} />
    </Button>
  )
}
CollapseButton.propTypes = {
  name: PropTypes.string,
}

export const CollapseAllButton = (props) => {
  const { message, ...rest } = props
  return (
    <Button
      {...rest}
      overlay={{ placement: 'top', delayShow: 800 }}
      message={message ?? messages.collapseAll}
    >
      <Icon name="compress" set="fa" />
    </Button>
  )
}

export const ConfirmButton = (props) => (
  <PrimaryButton {...props}>
    <FormattedMessage {...messages.confirm} />
  </PrimaryButton>
)

export const CreateButton = (props) => (
  <SuccessButton type="submit" {...props}>
    <FormattedMessage {...messages.create} /> {props.children}
  </SuccessButton>
)
CreateButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export const DeleteButton = forwardRef((props, ref) => (
  <DeleteButtonWithConfirmation {...props} ref={ref}>
    {props.children}
  </DeleteButtonWithConfirmation>
))
DeleteButton.propTypes = {
  children: PropTypes.node,
}

export const PaddinglessButton = (props) => (
  <Button style={{ padding: 0, border: 'none' }}>{props.children}</Button>
)

export const AddOnButton = (props) => (
  <Button disabled style={{ opacity: 1 }}>
    {props.children}
  </Button>
)

export const EditButton = (props) => (
  <Button {...props}>
    <FormattedMessage {...messages.edit} /> {props.children}
  </Button>
)
EditButton.propTypes = {
  children: PropTypes.element,
}

export const ExpandAllButton = (props) => {
  const { message, ...rest } = props
  return (
    <Button
      {...rest}
      overlay={{ placement: 'top', delayShow: 800 }}
      message={message ?? messages.expandAll}
    >
      <Icon name="expand" set="fa" />
    </Button>
  )
}

export const ExportButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.exportBudget}
  >
    <Icon name="export" />
  </Button>
)

export const FilterButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.filter}
  >
    <i className="fa fa-filter" aria-hidden="true" />
  </Button>
)
export const ImportButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.importInternal}
  >
    <Icon name="copy" />
  </Button>
)

export const ImportExternalButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.importExternal}
  >
    <Icon name="import" />
  </Button>
)

export const HideOrShowButton = ({ isHidden, ...props }) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={isHidden ? messages.showFixed : messages.hideFixed}
  >
    {isHidden ? (
      <Icon name="eye" set="fa" />
    ) : (
      <Icon name="eye-slash" set="fa" />
    )}
  </Button>
)

export const NotesButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.notes}
  >
    <Icon name="pencil" />
  </Button>
)

export const NewButton = (props) => (
  <SuccessButton {...props}>
    <FormattedMessage {...messages.new} /> {props.children}
  </SuccessButton>
)
NewButton.propTypes = {
  children: PropTypes.element,
}

export const CopyButton = (props) => (
  <Button {...props}>
    <FormattedMessage {...messages.copy} /> {props.children}
  </Button>
)

export const OkButton = (props) => (
  <PrimaryButton {...props}>
    <FormattedMessage {...messages.ok} />
  </PrimaryButton>
)

export const SaveButton = (props) => (
  <PrimaryButton {...props}>
    <FormattedMessage {...messages.save} />
  </PrimaryButton>
)

export const SubmitButton = ({ update, ...props }) =>
  update ? <UpdateButton {...props} /> : <CreateButton {...props} />
SubmitButton.propTypes = {
  update: PropTypes.bool,
}

export const CloseButton = (props) => (
  <ExtraSmallButton {...props}>
    <i className="fa fa-times" aria-hidden="true" />
  </ExtraSmallButton>
)

export const SyncButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={props.message ? props.message : messages.sync}
  >
    <i className="fa fa-refresh" />
  </Button>
)

export const BudgetMonitorButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.monitor}
  >
    <i className="fa fa-television" aria-hidden="true" />
  </Button>
)

export const BudgetSingleDimensionsButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.singleDimensionView}
  >
    <i className="fa fa-television" aria-hidden="true" />
  </Button>
)

export const RollingRulesButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.rollingRules}
  >
    <i className="fa fa-tasks" aria-hidden="true" />
  </Button>
)

export const NewRowAboveButton = (props) => (
  <Button {...props}>
    <i className="fa fa-arrow-up" aria-hidden="true" />
  </Button>
)

export const NewRowBelowButton = (props) => (
  <Button {...props}>
    <i className="fa fa-arrow-down" aria-hidden="true" />
  </Button>
)

export const NewRowButton = (props) => (
  <Button
    {...props}
    overlay={{ placement: 'top', delayShow: 800 }}
    message={messages.newRow}
  >
    <i className="fa fa-plus" aria-hidden="true" />
  </Button>
)

const TagsButtonIntl = ({ intl: { formatMessage }, ...props }) => (
  <Button aria-label={formatMessage(messages.addTags)} {...props}>
    <i className="fa fa-tags" aria-hidden="true" />
  </Button>
)

export const TagsButton = injectIntl(TagsButtonIntl)

export const SwapButton = (props) => (
  <Button {...props}>
    <i className="fa fa-arrows-h" aria-hidden="true" />
  </Button>
)

export const UpdateButton = (props) => (
  <SuccessButton type="submit" {...props}>
    <FormattedMessage {...messages.update} /> {props.children}
  </SuccessButton>
)

UpdateButton.propTypes = {
  children: PropTypes.element,
}

export const SelectButton = (props) => (
  <SuccessButton type="submit" {...props}>
    <FormattedMessage {...messages.select} /> {props.children}
  </SuccessButton>
)

export const RefreshButton = () => {
  const navigate = useNavigate()
  return (
    <PrimaryButton onClick={() => navigate(0)}>
      <FormattedMessage {...messages.refresh} /> <BiRefresh />
    </PrimaryButton>
  )
}

SelectButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}
