/*
 *
 * RollingRule actions
 *
 */

import {
  ADD_ACCOUNT_RANGE,
  ADD_ACCOUNT_RANGE_ERROR,
  ADD_ACCOUNT_RANGE_SUCCESS,
  GET_ROLLING_RULE_TREE_AND_DATA,
  GET_ROLLING_RULE_TREE_AND_DATA_ERROR,
  GET_ROLLING_RULE_TREE_AND_DATA_SUCCESS,
  UPDATE_ROLLING_RULE_DATA,
  UPDATE_ROLLING_RULE_DATA_ERROR,
  UPDATE_ROLLING_RULE_DATA_SUCCESS,
  REPLACE_ACCOUNT_RANGES,
  REPLACE_ACCOUNT_RANGES_ERROR,
  REPLACE_ACCOUNT_RANGES_SUCCESS,
  TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM,
} from './constants'

export const addAccountRange = ({
  companyCode,
  startAccount,
  endAccount,
  ruleId,
}) => ({
  type: ADD_ACCOUNT_RANGE,
  companyCode,
  startAccount,
  endAccount,
  ruleId,
})
export const addAccountRangeError = (error) => ({
  type: ADD_ACCOUNT_RANGE_ERROR,
  error,
})
export const addAccountRangeSuccess = ({ rollingRule, companyCode }) => ({
  type: ADD_ACCOUNT_RANGE_SUCCESS,
  rollingRule,
  companyCode,
})

export const getRollingRuleTreeAndData = ({ companyCode, ruleId }) => ({
  type: GET_ROLLING_RULE_TREE_AND_DATA,
  companyCode,
  ruleId,
})
export const getRollingRuleTreeAndDataError = (error) => ({
  type: GET_ROLLING_RULE_TREE_AND_DATA_ERROR,
  error,
})
export const getRollingRuleTreeAndDataSuccess = ({ tree, data }) => ({
  type: GET_ROLLING_RULE_TREE_AND_DATA_SUCCESS,
  tree,
  data,
})

export const updateRollingRuleData = ({ companyCode, ruleId, rowData }) => ({
  type: UPDATE_ROLLING_RULE_DATA,
  companyCode,
  ruleId,
  rowData,
})
export const updateRollingRuleDataError = (error) => ({
  type: UPDATE_ROLLING_RULE_DATA_ERROR,
  error,
})
export const updateRollingRuleDataSuccess = ({ data }) => ({
  type: UPDATE_ROLLING_RULE_DATA_SUCCESS,
  data,
})

export const replaceAccountRanges = ({
  companyCode,
  ruleId,
  accountRanges,
}) => ({ type: REPLACE_ACCOUNT_RANGES, companyCode, ruleId, accountRanges })
export const replaceAccountRangesError = (error) => ({
  type: REPLACE_ACCOUNT_RANGES_ERROR,
  error,
})
export const replaceAccountRangesSuccess = ({ rollingRule, companyCode }) => ({
  type: REPLACE_ACCOUNT_RANGES_SUCCESS,
  rollingRule,
  companyCode,
})

export const toggleShowAddAccountRangeForm = () => ({
  type: TOGGLE_SHOW_ADD_ACCOUNT_RANGE_FORM,
})
