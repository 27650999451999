import { createSelector } from 'reselect'
import { selectBudgets } from 'containers/Budgets/selectors'
import { applyFilters } from 'containers/Budget/functions'

/**
 * Direct selector to the budgetMonitor state domain
 */
const selectBudgetMonitorDomain = () => (state) => state.get('budgetMonitor')

/**
 * Other specific selectors
 */

const budgetFilter = (state, props) => props.params.budgetId
const accountGroupFilter = (state, props) =>
  props.location ? props.location.query.view : undefined

/**
 * Default selector used by BudgetMonitor
 */

const selectBudgetMonitor = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (substate) => substate.toJS()
  )

const selectBudgetMonitorError = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (budgetMonitor) => budgetMonitor.get('error')
  )

const selectBudgetMonitorDataError = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (budgetMonitor) => budgetMonitor.get('dataError')
  )

const selectBudgetMonitorLoading = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (budgetMonitor) => budgetMonitor.get('loading')
  )

const selectBudgetMonitorProgress = createSelector(
  selectBudgetMonitorDomain(),
  (budgetMonitor) => budgetMonitor.get('progress')
)

const selectBudgetMonitorData = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (budgetMonitor) => budgetMonitor.get('data')
  )

const selectBudgetRecord = () =>
  createSelector(
    [selectBudgets, budgetFilter],
    (budgets, budgetId) => budgets.find((b) => b.id === budgetId)
  )

const selectFilters = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (budget) => budget.get('filters')
  )

const selectBudgetMonitorTree = () =>
  createSelector(
    [selectBudgetMonitorDomain(), selectFilters(), accountGroupFilter],
    (budgetMonitor, filters, accountGroup = 'All') =>
      applyFilters({ tree: budgetMonitor.get('tree'), filters, accountGroup })
  )

const selectBudgetMonitorMonthRange = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (budgetMonitor) => budgetMonitor.get('monthRange')
  )

const selectDrilledRows = (budgetId) =>
  createSelector(
    [selectBudgetMonitorDomain()],
    (budgetMonitor) => budgetMonitor.get('drilledRows')
  )

const selectBudgetMonitorRowNotes = () =>
  createSelector(
    selectBudgetMonitorDomain(),
    (budgetMonitor) => budgetMonitor.get('rowNotes')
  )

export default selectBudgetMonitor
export {
  selectBudgetMonitorError,
  selectBudgetMonitorDataError,
  selectBudgetMonitorData,
  selectBudgetMonitorDomain,
  selectBudgetMonitorLoading,
  selectBudgetMonitorProgress,
  selectBudgetMonitorMonthRange,
  selectBudgetMonitorTree,
  selectBudgetRecord,
  selectDrilledRows,
  selectBudgetMonitorRowNotes,
}
