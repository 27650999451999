import { Record } from 'immutable'

const IntegrationSourceSystemRecord = Record({
  id: undefined,
  name: '',
  displayName: '',
  openingBalanceType: 'OpeningBalancePerMonth',
  whitelistedCustomers: [],
  requiredSettings: [],
  materialTypes: [],
  description: '',
  implementationType: '',
  microserviceUrl: '',
})

export default IntegrationSourceSystemRecord
