import { all, call, put, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import budgetApi from 'api/BudgetApi'

import {
  importBudgetBalancesError,
  importBudgetBalancesSuccess,
  parseBudgetBalancesError,
  parseBudgetBalancesSuccess,
} from './actions'
import { IMPORT_BUDGET_BALANCES, PARSE_BUDGET_BALANCES } from './constants'
import { BudgetBalanceRecord } from 'records'

// Individual exports for testing
export function* importBudgetBalances(action) {
  const { budgetId, companyCode, validBudgetBalances } = action

  const budgetBalances = validBudgetBalances
    .map((budgetBalance) => {
      const { accountNumber, amount, month, year } = budgetBalance
      const dimensionCode =
        budgetBalance.dimensionCode === null ? '' : budgetBalance.dimensionCode
      const dimensionValueCode =
        budgetBalance.dimensionValueCode === null
          ? ''
          : budgetBalance.dimensionValueCode

      return new BudgetBalanceRecord({
        amount,
        accountNumber: `${accountNumber}`,
        dimensionCode: `${dimensionCode}`,
        dimensionValueCode: `${dimensionValueCode}`,
        month,
        year,
      })
    })
    .toJS()

  try {
    const importResponse = yield call(budgetApi.importBudgetBalances, {
      budgetBalances,
      budgetId,
      companyCode,
    })
    yield put(importBudgetBalancesSuccess({ importResponse }))
  } catch (error) {
    yield put(handleError(error, importBudgetBalancesError))
  }
}

export function* parseBudgetBalances(action) {
  const {
    accountNumbers,
    allowLetters,
    endDate,
    file,
    max,
    min,
    startDate,
  } = action

  try {
    const { parsedBudgetBalances, parsedFile } = yield call(
      budgetApi.parseBudgetBalances,
      {
        accountNumbers,
        allowLetters,
        endDate,
        file,
        max,
        min,
        startDate,
      }
    )

    yield put(parseBudgetBalancesSuccess({ parsedBudgetBalances, parsedFile }))
  } catch (error) {
    yield put(handleError(error, parseBudgetBalancesError))
  }
}

// All sagas to be loaded
export function* budgetBudgetBalanceImportModalSaga() {
  yield all([
    takeEvery(IMPORT_BUDGET_BALANCES, importBudgetBalances),
    takeEvery(PARSE_BUDGET_BALANCES, parseBudgetBalances),
  ])
}

export default budgetBudgetBalanceImportModalSaga
