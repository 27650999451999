import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const createPath = ({ companyCode, customerCode } = {}) => {
  const path = 'accountschemetemplates'

  if (companyCode) {
    return `${companyCode}/${path}`
  }
  if (customerCode) {
    return `${customerCode}/${path}`
  }

  return path
}

const createAccountSchemeTemplate = ({ companyCode, customerCode, name }) => {
  const path = createPath({ companyCode, customerCode })

  return axios
    .post(path, { name }, config())
    .then(returnData)
    .catch(catchError)
}

const accountSchemeTemplateBulkDelete = ({
  companyCode,
  customerCode,
  id,
  rowIds,
}) =>
  axios
    .delete(
      `${createPath({ companyCode, customerCode })}/${id}/bulkdelete`,
      config({
        data: rowIds,
      })
    )
    .then(returnData)
    .catch(catchError)

const deleteAccountSchemeTemplate = ({ companyCode, customerCode, id }) =>
  axios
    .delete(`${createPath({ companyCode, customerCode })}/${id}`, config())
    .then(returnData)
    .catch(catchError)

const getAccountSchemeTemplate = ({ companyCode, customerCode, id }) =>
  axios
    .get(`${createPath({ companyCode, customerCode })}/${id}`, config())
    .then(returnData)
    .catch(catchError)

const getAccountSchemeTemplateTree = ({ companyCode, customerCode, id }) =>
  axios
    .get(`${createPath({ companyCode, customerCode })}/${id}/tree`, config())
    .then(returnData)
    .catch(catchError)

const getAccountSchemeTemplates = ({ companyCode, customerCode }) =>
  axios
    .get(createPath({ companyCode, customerCode }), config())
    .then(returnData)
    .catch(catchError)

const patchAccountSchemeTemplate = ({ companyCode, customerCode, id, patch }) =>
  axios
    .patch(
      `${createPath({ companyCode, customerCode })}/${id}`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchAccountSchemeTemplateTree = ({
  companyCode,
  customerCode,
  id,
  patch,
}) =>
  axios
    .patch(
      `${createPath({ companyCode, customerCode })}/${id}/tree`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const copyAccountSchemeTemplate = ({
  companyCode,
  customerCode,
  newName,
  accountSchemeTemplateId,
}) =>
  axios
    .post(
      `${createPath({
        companyCode,
        customerCode,
      })}/${accountSchemeTemplateId}/copy`,
      { name: newName },
      config()
    )
    .then(returnData)
    .catch(catchError)

const accountSchemeTemplateApi = {
  accountSchemeTemplateBulkDelete,
  createAccountSchemeTemplate,
  deleteAccountSchemeTemplate,
  getAccountSchemeTemplate,
  getAccountSchemeTemplates,
  getAccountSchemeTemplateTree,
  patchAccountSchemeTemplate,
  patchAccountSchemeTemplateTree,
  copyAccountSchemeTemplate,
}

export default accountSchemeTemplateApi
