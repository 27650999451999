/*
 *
 * DatasourceContainer reducer
 *
 */

import { fromJS, Map, List } from 'immutable'
import { BudgetRecord, DatasourceRecord } from 'records'
import { GET_BUDGETS_SUCCESS } from 'containers/Budgets/constants'

import {
  GET_DATASOURCES,
  GET_DATASOURCES_ERROR,
  GET_DATASOURCES_SUCCESS,
} from './constants'

// Datasources are mapped by reportSchemeIds
const initialState = fromJS({
  datasources: Map(),
  error: undefined,
  loading: false,
  // TODO: Move budgets under containers/Budgets/reducer, and implement the Map there
  // `budgets` are mapped by companyCode
  budgets: Map(),
})

const mapDatasource = (datasource) => new DatasourceRecord(datasource)

function datasourceContainerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUDGETS_SUCCESS:
      return state.setIn(
        ['budgets', action.companyCode],
        List(action.budgets.map((budget) => new BudgetRecord(budget)))
      )

    case GET_DATASOURCES:
      return state.set('loading', true).set('error', undefined)

    case GET_DATASOURCES_ERROR:
      return state.set('loading', false).set('error', action.error)

    case GET_DATASOURCES_SUCCESS:
      return state
        .set('loading', false)
        .setIn(
          ['datasources', action.reportSchemeId],
          List(action.datasources.map(mapDatasource))
        )

    default:
      return state
  }
}

export default datasourceContainerReducer
