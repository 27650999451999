import { fromJS } from 'immutable'

import { UPDATE_REPORT_SELECTION_SUCCESS } from 'containers/Reports/constants'

import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_ERROR,
  GET_REPORT_DATA_SUCCESS,
  INVALIDATE_REPORT_DATA_CACHE,
  INVALIDATE_REPORT_DATA_CACHE_ERROR,
  INVALIDATE_REPORT_DATA_CACHE_SUCCESS,
  GET_REPORT_PROGRESS_SUCCESS,
  CLEAR_REPORT_DATA_ERROR,
} from './constants'
import {
  GET_REPORT_DATA_PREVIEW,
  GET_REPORT_DATA_PREVIEW_SUCCESS,
} from 'containers/ReportSelections/constants'

const initialState = fromJS({})

const reportDatasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_DATA:
      return state
        .update(action.reportId, (current) => ({
          data: action.setLoading ? undefined : current?.data,
          error: false,
          loading: action.setLoading,
          progress: null,
        }))
        .set('recalculating', !action.setLoading)

    case GET_REPORT_DATA_PREVIEW:
      return state.set('recalculating', !action.setLoading)

    case GET_REPORT_DATA_PREVIEW_SUCCESS:
      return state.set('recalculating', false)

    case GET_REPORT_DATA_ERROR:
    case INVALIDATE_REPORT_DATA_CACHE_ERROR:
      return state
        .set(action.error.reportId, {
          loading: false,
          error: action.error,
          progress: null,
        })
        .set('recalculating', false)

    case CLEAR_REPORT_DATA_ERROR:
      return state
        .update(action.reportId, (report) => ({
          ...report,
          error: undefined,
        }))
        .set('recalculating', false)

    case GET_REPORT_DATA_SUCCESS:
      return state
        .set(action.reportId, {
          data: action.report,
          error: false,
          loading: false,
          progress: null,
          companyCode: action.companyCode,
        })
        .set('recalculating', false)

    case INVALIDATE_REPORT_DATA_CACHE:
      return state.set(action.reportId, {
        error: false,
        loading: true,
      })

    case INVALIDATE_REPORT_DATA_CACHE_SUCCESS:
      return state.delete(action.reportId)

    case UPDATE_REPORT_SELECTION_SUCCESS:
      if (!action.refetchData) return state
      return state.delete(action.reportSelection.id.toString())

    case GET_REPORT_PROGRESS_SUCCESS:
      const { progress, reportId } = action
      return state.update(reportId, (current) => ({ ...current, progress }))

    default:
      return state
  }
}

export default reportDatasReducer
