import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const createPath = ({ companyCode, customerCode } = {}) => {
  const path = 'reportschemes'

  if (companyCode) {
    return `${companyCode}/${path}`
  }
  if (customerCode) {
    return `${customerCode}/${path}`
  }

  return path
}

const createReportScheme = ({
  accountGroupType,
  accountSchemeTemplateId,
  companyCode,
  customerCode,
  description,
  hideAccountIntervals,
  makeTemplate,
  name,
  reportSchemeTemplateId,
  reportType,
  sourceSystems,
  accountSchemeId,
}) => {
  if (accountSchemeId) {
    return axios
      .post(
        `${createPath({
          companyCode,
          customerCode,
        })}/createfromaccountscheme`,
        {
          accountGroupType,
          accountSchemeId,
          description,
          hideAccountIntervals,
          isTemplate: makeTemplate,
          name,
          reportType,
          sourceSystems,
        },
        config()
      )
      .then(returnData)
      .catch(catchError)
  }

  if (accountSchemeTemplateId) {
    return axios
      .post(
        `${createPath({
          companyCode,
          customerCode,
        })}/createfromaccountschemetemplate`,
        {
          accountGroupType,
          accountSchemeTemplateId,
          description,
          hideAccountIntervals,
          isTemplate: makeTemplate,
          name,
          reportType,
          sourceSystems,
        },
        config()
      )
      .then(returnData)
      .catch(catchError)
  }

  if (reportSchemeTemplateId) {
    return axios
      .post(
        `${createPath({
          companyCode,
          customerCode,
        })}/${reportSchemeTemplateId}/copy`,
        {
          description,
          makeTemplate,
          name,
          sourceSystems,
        },
        config()
      )
      .then(returnData)
      .catch(catchError)
  }

  return axios
    .post(
      `${createPath({ companyCode, customerCode })}`,
      {
        description,
        hideAccountIntervals,
        isTemplate: makeTemplate,
        name,
        reportType,
        sourceSystems,
      },
      config()
    )
    .then(returnData)
    .catch(catchError)
}

const deleteReportScheme = ({ companyCode, customerCode, id }) =>
  axios
    .delete(`${createPath({ companyCode, customerCode })}/${id}`, config())
    .then(returnData)
    .catch(catchError)

const copyReportScheme = ({
  companyCode,
  customerCode,
  id,
  name,
  makeTemplate = false,
}) =>
  axios
    .post(
      `${createPath({ companyCode, customerCode })}/${id}/copy`,
      { name, makeTemplate },
      config()
    )
    .then(returnData)
    .catch(catchError)

const getReportSchemes = ({ companyCode, customerCode }) =>
  axios
    .get(`${createPath({ companyCode, customerCode })}`, config())
    .then(returnData)
    .catch(catchError)

const getReportSchemeTree = ({ companyCode, customerCode, id }) =>
  axios
    .get(`${createPath({ companyCode, customerCode })}/${id}/tree`, config())
    .then(returnData)
    .catch(catchError)

const patchReportSchemeTree = ({
  companyCode,
  customerCode,
  patch,
  reportSchemeId,
  removeIdsAfterCopy = false,
}) =>
  axios
    .patch(
      `${createPath({
        companyCode,
        customerCode,
      })}/${reportSchemeId}/tree?removeIdsAfterCopy=${removeIdsAfterCopy}`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchReportScheme = ({
  companyCode,
  customerCode,
  patch,
  reportSchemeId,
}) =>
  axios
    .patch(
      `${createPath({ companyCode, customerCode })}/${reportSchemeId}`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const reportSchemeApi = {
  createReportScheme,
  copyReportScheme,
  deleteReportScheme,
  getReportSchemes,
  getReportSchemeTree,
  patchReportSchemeTree,
  patchReportScheme,
}

export default reportSchemeApi
