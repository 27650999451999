import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import consolidationApi from 'api/AcquisitionApi'

import {
  createFASAcquisitionVouchersError,
  createFASAcquisitionVouchersSuccess,
  createEditedFASAcquisitionVouchersError,
  createEditedFASAcquisitionVouchersSuccess,
  deleteAcquisitionSuccess,
  deleteAcquisitionError,
  getAcquisitionFormsSuccess,
  getAcquisitionFormsError,
  getAllCreatedAcquisitionsSuccess,
  getAllCreatedAcquisitionsError,
} from './actions'

import {
  CREATE_FAS_ACQUISITION_VOUCHERS,
  CREATE_EDITED_FAS_ACQUISITION_VOUCHERS,
  DELETE_ACQUISITION,
  GET_ACQUISITION_FORMS,
  GET_ALL_CREATED_ACQUISITIONS,
} from './constants'

import { getVoucherList as getVoucherListAction } from 'containers/Vouchers/actions'

import { VOUCHER_TYPE_ACQUISITION } from 'containers/Vouchers/constants'

export function* createFASAcquisitionVouchers(action) {
  try {
    const { companyCode, date } = action
    yield call(consolidationApi.createFASAcquisitionVouchers, action)
    yield put(createFASAcquisitionVouchersSuccess())
    yield put(
      getVoucherListAction({
        companyCode,
        endDate: date,
        startDate: date,
        voucherType: VOUCHER_TYPE_ACQUISITION,
      })
    )
  } catch (error) {
    yield put(
      createFASAcquisitionVouchersError(
        handleError(error, createFASAcquisitionVouchersError)
      )
    )
  }
}

export function* createEditedFASAcquisitionVouchers(action) {
  try {
    const { companyCode, date } = action
    yield call(consolidationApi.createEditedFASAcquisitionVouchers, action)
    yield put(createEditedFASAcquisitionVouchersSuccess())
    yield put(
      getVoucherListAction({
        companyCode,
        endDate: date,
        startDate: date,
        voucherType: VOUCHER_TYPE_ACQUISITION,
      })
    )
  } catch (error) {
    yield put(
      createEditedFASAcquisitionVouchersError(
        handleError(error, createEditedFASAcquisitionVouchersError)
      )
    )
  }
}

export function* deleteAcquisition(action) {
  const { companyCode, id } = action
  try {
    yield call(consolidationApi.deleteAcquisition, {
      companyCode,
      id,
    })
    yield put(deleteAcquisitionSuccess({ companyCode, id }))
    yield put(
      getVoucherListAction({
        companyCode,
        // endDate: date,
        // startDate: date,
        voucherType: VOUCHER_TYPE_ACQUISITION,
      })
    )
  } catch (error) {
    yield put(
      deleteAcquisitionError(handleError(error, deleteAcquisitionError))
    )
  }
}

export function* getAcquisitionForms(action) {
  try {
    const { companyCode, generatorId } = action
    const forms = yield call(consolidationApi.getAcquisitionForms, {
      companyCode,
      generatorId,
    })

    yield put(getAcquisitionFormsSuccess({ companyCode, forms }))
  } catch (error) {
    yield put(
      getAcquisitionFormsError(handleError(error, getAcquisitionFormsError))
    )
  }
}

export function* getAllCreatedAcquisitionForms(action) {
  try {
    const { companyCode } = action
    const forms = yield call(consolidationApi.getAllCreatedAcquisitionForms, {
      companyCode,
    })

    yield put(getAllCreatedAcquisitionsSuccess({ companyCode, forms }))
  } catch (error) {
    yield put(
      getAllCreatedAcquisitionsError(
        handleError(error, getAcquisitionFormsError)
      )
    )
  }
}

export function* fasAcquisitionSaga() {
  yield all([
    takeLatest(
      CREATE_EDITED_FAS_ACQUISITION_VOUCHERS,
      createEditedFASAcquisitionVouchers
    ),
    takeLatest(CREATE_FAS_ACQUISITION_VOUCHERS, createFASAcquisitionVouchers),
    takeEvery(DELETE_ACQUISITION, deleteAcquisition),
    takeEvery(GET_ACQUISITION_FORMS, getAcquisitionForms),
    takeEvery(GET_ALL_CREATED_ACQUISITIONS, getAllCreatedAcquisitionForms),
  ])
}

export default fasAcquisitionSaga
