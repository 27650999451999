import classNames from 'classnames'

/**
 *
 * Icon
 * - name (omit set name eg. glyphicon-icon -> icon)
 * - default tagType <span>
 * - default set is glyphicon
 *
 * Wrapper for Bootstrap set of Glyphicon Halflings and Font Awesome icons
 * - http://getbootstrap.com/components/#glyphicons
 * - http://fontawesome.io/icons/
 *
 */

import PropTypes from 'prop-types'

import React, { forwardRef } from 'react'

const propTypes = {
  name: PropTypes.string.isRequired,
  set: PropTypes.string,
  tagType: PropTypes.string,
  'aria-hidden': PropTypes.bool,
}
const defaultProps = {
  'aria-hidden': true,
  set: 'glyphicon',
  tagType: 'span',
}

const Icon = forwardRef(({ className, name, set, tagType, ...props }, ref) => {
  const Tag = tagType

  const classes = classNames(className, set, name && `${set}-${name}`)

  return <Tag className={classes} {...props} ref={ref} />
})

Icon.propTypes = propTypes
Icon.defaultProps = defaultProps

export default Icon
