import axios from 'axios'
import qs from 'qs'
import { returnData, config, catchError } from './api-utils'

const paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' })

const getAllSubBudgets = ({ companyCode }) =>
  axios
    .get(`${companyCode}/subbudgets`, config())
    .then(returnData)
    .catch(catchError)

const getBudgetSubBudgets = ({ companyCode, budgetId }) =>
  axios
    .get(`${companyCode}/budgets/${budgetId}/subbudgets`, config())
    .then(returnData)
    .catch(catchError)

const getSubBudgetData = ({
  companyCode,
  budgetId,
  subBudgetId,
  start,
  end,
  dv,
  forceRecalculate,
}) =>
  axios
    .get(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/data?forceRecalculate=${!!forceRecalculate}`,
      config({
        params: { dv, start, end },
        paramsSerializer,
      })
    )
    .then(returnData)
    .catch(catchError)

const getSubBudgetImportData = ({
  companyCode,
  budgetId,
  subBudgetId,
  subBudgetRowId,
  persist,
  ...rest
}) =>
  axios
    .get(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/row/${subBudgetRowId}/import${
        persist ? '' : '/preview'
      }`,
      config({
        params: {
          ...rest,
        },
        paramsSerializer,
      })
    )
    .then(returnData)
    .catch(catchError)

const patchSubBudgetData = ({
  companyCode,
  budgetId,
  subBudgetId,
  dv,
  start,
  end,
  patch,
}) =>
  axios
    .patch(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/data`,
      patch,
      config({
        params: { start, end, dv },
        paramsSerializer,
      })
    )
    .then(returnData)
    .catch(catchError)

const patchSubBudgetTree = ({ companyCode, budgetId, subBudgetId, patch }) =>
  axios
    .patch(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/tree`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const getSubBudgetTree = ({ companyCode, budgetId, subBudgetId }) =>
  axios
    .get(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/tree`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const createSubBudget = ({ companyCode, budgetId, subBudget }) =>
  axios
    .post(`${companyCode}/budgets/${budgetId}/subbudgets`, subBudget, config())
    .then(returnData)
    .catch(catchError)

const createSubBudgetWithCopy = ({
  companyCode,
  budgetId,
  subBudget,
  sourceSubBudgetId,
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/subbudgets/${sourceSubBudgetId}/copy`,
      subBudget,
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteSubBudget = ({ companyCode, budgetId, subBudgetId }) =>
  axios
    .delete(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchSubBudget = ({ companyCode, budgetId, subBudgetId, patch }) =>
  axios
    .patch(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchSubBudgetStatus = ({ companyCode, budgetId, subBudgetId, status }) =>
  axios
    .patch(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/status`,
      `status=${status}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const addTag = ({ companyCode, budgetId, subBudgetId, rowId, tagId }) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/tag`,
      { rowId, tagId },
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteTag = ({ companyCode, budgetId, subBudgetId, rowId, tagId }) =>
  axios
    .delete(
      `${companyCode}/budgets/${budgetId}/subbudgets/${subBudgetId}/tag`,
      config({ data: { rowId, tagId } })
    )
    .then(returnData)
    .catch(catchError)

const subBudgetsApi = {
  createSubBudget,
  createSubBudgetWithCopy,
  deleteSubBudget,
  getAllSubBudgets,
  getBudgetSubBudgets,
  getSubBudgetImportData,
  getSubBudgetData,
  getSubBudgetTree,
  patchSubBudget,
  patchSubBudgetData,
  patchSubBudgetTree,
  patchSubBudgetStatus,
  addTag,
  deleteTag,
}

export default subBudgetsApi
