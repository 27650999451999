import Papa from 'papaparse'

export const parseSubBudgetScheme = ({ file, complete }) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete,
      delimiter: ';',
      transform: (value, header) => value.trim(),
      transformHeader: (header) => header.trim(), // not yet availiable TODO: Updata papa
    })
  })
