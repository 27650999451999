import { createSelector } from 'reselect'
import { alphabeticallyAscendingComparer } from 'utils/sort'

/**
 * Direct selector to the customers state domain
 */
const selectCustomersDomain = (state) => state.get('customers')

/**
 * Other specific selectors
 */
const selectShowCreateCustomerForm = createSelector(
  selectCustomersDomain,
  (customers) => customers.get('showCreateCustomerForm')
)

const selectCustomersError = createSelector(
  selectCustomersDomain,
  (customers) => customers.get('error')
)

const selectCustomersLoading = createSelector(
  selectCustomersDomain,
  (customers) => customers.get('loading')
)

/**
 * Default selector used by Customers
 */
const selectCustomers = createSelector(
  selectCustomersDomain,
  (customers) =>
    customers.get('customers').sort(alphabeticallyAscendingComparer)
)

// From all customers flatmap their companies.
// Not sure if this should be here or in containers/Companies/selectors.js
const selectAllCompanies = createSelector(
  selectCustomers,
  (customers) =>
    customers.flatMap((c) => c.companies).sort(alphabeticallyAscendingComparer)
)

export default selectCustomers
export {
  selectCustomersDomain,
  selectShowCreateCustomerForm,
  selectCustomersError,
  selectCustomersLoading,
  selectAllCompanies,
}
