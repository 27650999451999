import { useLocation, useNavigate, useOutlet, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    if (result[key]) {
      if (!Array.isArray(result[key])) {
        result[key] = [result[key], value]
      } else {
        result[key].push(value)
      }
    } else {
      result[key] = value
    }
  }
  return result
}

const withRouterProps = (Component) => {
  const ComponentwithRouterProps = (props) => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [search, setSearchParams] = useSearchParams()
    const searchObj = paramsToObject(search)
    const children = useOutlet()
    return (
      <Component
        {...props}
        params={params}
        location={{ ...location, query: searchObj }}
        navigate={navigate}
        children={children}
        search={search}
        setSearchParams={setSearchParams}
      />
    )
  }
  return ComponentwithRouterProps
}

export default withRouterProps
