import selectCustomer from 'containers/Customer/selectors'
import { createSelector } from 'reselect'
import { List } from 'immutable'

import { alphabeticallyAscendingComparer } from 'utils/sort'

/**
 * Direct selector to the companies state domain
 */
const selectCompaniesDomain = (state) => state.get('companies')

/**
 * Other specific selectors
 */
const selectCurrentCompanyCode = (state, props) => {
  const paramsCompanyCode = props.params && props.params.companyCode
  const companyCode = props.companyCode

  return paramsCompanyCode || companyCode
}

/**
 * Default selector used by Companies
 */
const selectCompanies = createSelector(
  selectCustomer,
  (customer) =>
    customer &&
    (customer.get('companies') || List()).sort(alphabeticallyAscendingComparer)
)

const selectCurrentCompany = createSelector(
  [selectCurrentCompanyCode, selectCompanies],
  (companyCode, companies) => {
    if (companyCode && companies) {
      return companies.find((c) => c.code === companyCode)
    }

    return undefined
  }
)

const selectShowCreateCompanyForm = createSelector(
  selectCompaniesDomain,
  (companies) => companies.get('showCreateCompanyForm')
)

const selectCompaniesError = createSelector(
  selectCompaniesDomain,
  (companies) => companies.get('error')
)

const selectCompaniesLoading = createSelector(
  selectCompaniesDomain,
  (companies) => companies.get('loading')
)

const selectDeleteIntegrationLoading = createSelector(
  selectCompaniesDomain,
  (companies) => companies.get('deleteIntegrationLoading')
)

export default selectCompanies
export {
  selectCompaniesDomain,
  selectCurrentCompany,
  selectShowCreateCompanyForm,
  selectCompaniesError,
  selectCompaniesLoading,
  selectDeleteIntegrationLoading,
}
