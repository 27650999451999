/*
 *
 * SubBudgetTags reducer
 *
 */

import { fromJS, Map, List } from 'immutable'

import { TagRecord } from 'records'
import {
  GET_BUDGET_TAGS,
  GET_BUDGET_TAGS_ERROR,
  GET_BUDGET_TAGS_SUCCESS,
  EDIT_BUDGET_TAG,
  CREATE_BUDGET_TAG,
  CREATE_BUDGET_TAG_ERROR,
  CREATE_BUDGET_TAG_SUCCESS,
  REMOVE_BUDGET_TAG,
  REMOVE_BUDGET_TAG_ERROR,
  REMOVE_BUDGET_TAG_SUCCESS,
  TOGGLE_SHOW_CREATE_TAG_FORM,
  UPDATE_BUDGET_TAG,
  UPDATE_BUDGET_TAG_ERROR,
  UPDATE_BUDGET_TAG_SUCCESS,
} from './constants'

export const mapTag = ({ forecolor, backcolor, ...tag }) =>
  new TagRecord({
    ...tag,
    forecolor: `#${forecolor}`,
    backcolor: `#${backcolor}`,
  })

// `tags` is mapped by budgetId.
// E.g.
// [{ '1' => [{ tag1, tag2 }] }]
const initialState = fromJS({
  tags: Map(),
  loading: false,
  error: false,
  showCreateTagForm: false,
  editTagId: undefined,
})

const subBudgetTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BUDGET_TAG:
    case CREATE_BUDGET_TAG:
    case REMOVE_BUDGET_TAG:
    case GET_BUDGET_TAGS:
      return state.set('loading', true).set('error', false)

    case UPDATE_BUDGET_TAG_ERROR:
    case CREATE_BUDGET_TAG_ERROR:
    case REMOVE_BUDGET_TAG_ERROR:
    case GET_BUDGET_TAGS_ERROR:
      return state.set('loading', false).set('error', action.error)

    case GET_BUDGET_TAGS_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['tags', action.budgetId], List(action.tags.map(mapTag)))

    case REMOVE_BUDGET_TAG_SUCCESS:
      return state
        .set('loading', false)
        .updateIn(['tags', action.budgetId], (list) =>
          list.filter((tag) => tag.id !== action.tagId)
        )

    case CREATE_BUDGET_TAG_SUCCESS: {
      const tags = state.getIn(['tags', action.budgetId]) || List()
      return state
        .set('loading', false)
        .setIn(['tags', action.budgetId], tags.push(mapTag(action.tag)))
    }

    case UPDATE_BUDGET_TAG_SUCCESS:
      return state
        .set('loading', false)
        .set('editTagId', undefined)
        .updateIn(['tags', action.budgetId], (tags) =>
          tags.map((tag) =>
            tag.id === action.tag.id ? mapTag(action.tag) : tag
          )
        )

    case TOGGLE_SHOW_CREATE_TAG_FORM:
      return state.set('showCreateTagForm', !state.get('showCreateTagForm'))

    case EDIT_BUDGET_TAG:
      return state.set('editTagId', action.id)

    default:
      return state
  }
}

export default subBudgetTagsReducer
