/*
 *
 * AccountSchemeTemplate reducer
 *
 */
import { fromJS, List } from 'immutable'

import {
  AccountSchemeTemplateRowGroupRecord,
  AccountSchemeTemplateRowRecord,
} from 'records'

import {
  ADD_GROUP_ERROR,
  ADD_GROUP_SUCCESS,
  ADD_ROW_ERROR,
  ADD_ROW_SUCCESS,
  DELETE_ROW_ERROR,
  DELETE_ROW_SUCCESS,
  DELETE_ROWS,
  GET_ACCOUNT_SCHEME_TEMPLATE,
  GET_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  GET_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  UPDATE_GROUP_ERROR,
  UPDATE_GROUP_SUCCESS,
  UPDATE_ROW_ERROR,
  UPDATE_ROW_SUCCESS,
} from './constants'

const initialState = fromJS({
  error: false,
  loading: true,
  tree: undefined,
})

const mapAccountSchemeTemplateTree = ({ node, level = 0 }) => {
  if (node.rowType === 'Header') {
    return new AccountSchemeTemplateRowGroupRecord(node)
      .set(
        'children',
        List(
          node.children.map((childNode) =>
            mapAccountSchemeTemplateTree({
              node: childNode,
              level: level + 1,
            })
          )
        )
      )
      .set('level', level)
  }

  return new AccountSchemeTemplateRowRecord(node).set('level', level)
}

const accountSchemeTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GROUP_ERROR:
    case ADD_ROW_ERROR:
    case DELETE_ROW_ERROR:
    case GET_ACCOUNT_SCHEME_TEMPLATE_ERROR:
    case UPDATE_GROUP_ERROR:
    case UPDATE_ROW_ERROR:
      return state.set('loading', false).set('error', action.error)

    case ADD_GROUP_SUCCESS:
    case ADD_ROW_SUCCESS:
    case DELETE_ROW_SUCCESS:
    case GET_ACCOUNT_SCHEME_TEMPLATE_SUCCESS:
    case UPDATE_GROUP_SUCCESS:
    case UPDATE_ROW_SUCCESS: {
      const tree = mapAccountSchemeTemplateTree({
        node: action.tree.schemeGroup,
      })
      return state.set('loading', false).set('tree', tree)
    }
    case DELETE_ROWS:
    case GET_ACCOUNT_SCHEME_TEMPLATE:
      return state.set('loading', true)

    default:
      return state
  }
}

export default accountSchemeTemplateReducer
