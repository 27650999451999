import { DimensionRecord, DimensionValueRecord } from 'records'
import { List, Map } from 'immutable'
import { getDimensionName } from 'containers/Dimension/functions'

const mapDimensionValue = ({
  dimensions,
  dimensionValue,
  dimension,
  level = 1,
}) => {
  const children = new List(
    dimensionValue.children.map((dimensionValue) =>
      mapDimensionValue({
        dimensions,
        dimensionValue,
        dimension,
        level: level + 1,
      })
    )
  )

  return new DimensionValueRecord({
    ...dimensionValue,
    rootName: getDimensionName(dimension),
    dimensionName: getDimensionName(
      dimensions.find((d) => d.id === dimensionValue.dimensionId)
    ),
    children,
    level,
  })
}

const flattenDimensionValueHierarchy = (dimensionValue) => {
  const self = new List([dimensionValue])
  const children = dimensionValue.children.flatMap(
    flattenDimensionValueHierarchy
  )

  return self.concat(children)
}

const mapDimension = ({ dimension, dimensions }) => {
  const values = new List(
    dimension.values
      ? dimension.values.map((dimensionValue) =>
          mapDimensionValue({ dimensions, dimensionValue, dimension, level: 1 })
        )
      : []
  )
  const parent = dimension.parent
    ? mapDimension({ dimension: dimension.parent, dimensions })
    : null
  const dimensionValuesById = Map(
    values
      .flatMap(flattenDimensionValueHierarchy)
      .map((value) => [value.id, value])
  )
  return new DimensionRecord({
    ...dimension,
    values,
    parent,
    dimensionValuesById,
  })
}

const mapDimensions = (dimensions) =>
  List(dimensions.map((dimension) => mapDimension({ dimension, dimensions })))

export { mapDimensions, mapDimension }
