/*
 *
 * BudgetMonitor reducer
 *
 */

import { List, fromJS, Map } from 'immutable'
import patch from 'immpatch'

import {
  createBudgetData,
  getBudgetMonthRange,
  mapBudgetRows,
} from 'containers/Budget/reducer'

import { fixLeadingSlash } from 'utils/schemeGroupUtils'

import { BudgetBalanceSchemeNode, DimensionTargetRecord } from 'records'
import {
  GET_BUDGET_BALANCE_FOR_ROW,
  GET_BUDGET_BALANCE_FOR_ROW_ERROR,
  GET_BUDGET_BALANCE_FOR_ROW_SUCCESS,
  SYNC_BUDGET,
  SYNC_BUDGET_SUCCESS,
  SYNC_BUDGET_ERROR,
  GET_BUDGET_MONITOR_PROGRESS_SUCCESS,
  SHOW_BUDGET_MONITOR_ROW_NOTES,
  GET_BUDGET_BALANCE_TREE_SUCCESS,
} from './constants'

const mapDimensionTarget = ({ dimensionValues, ...otherProps }) =>
  new DimensionTargetRecord({
    // Pass other props to constructor
    ...otherProps,
    // Convert to immutable List of DimensionValueRecord
    dimensionValueIds: List(dimensionValues.map((dv) => parseInt(dv.id, 10))),
  })

const mapBudgetSchemeGroup = (schemeGroup) =>
  new BudgetBalanceSchemeNode(schemeGroup)
    .set('children', List(schemeGroup.children.map(mapBudgetSchemeGroup)))
    .set(
      'dimensionTarget',
      schemeGroup.dimensionTarget
        ? mapDimensionTarget(schemeGroup.dimensionTarget)
        : undefined
    )

const attachBalanceTree = (oldTree, newTree, pathToAttach) => {
  const correctedPath = pathToAttach.replace('SchemeGroup/', '').toLowerCase()
  const schemeGroupRecordsToAdd = mapBudgetSchemeGroup(newTree).children

  return patch(oldTree, [
    {
      op: 'replace',
      path: fixLeadingSlash(`${correctedPath}/children`),
      value: schemeGroupRecordsToAdd,
    },
  ])
}

const attachBalanceData = (oldData, newRows) => {
  const newRowRecords = mapBudgetRows(newRows)
  const oldRowRecords = oldData.get('rows')
  const combinedRowRecords = newRowRecords.concat(oldRowRecords)

  return patch(oldData, [
    { op: 'replace', path: '/rows', value: combinedRowRecords },
  ])
}

const initialState = fromJS({
  error: false,
  dataError: false,
  loading: false,
  data: undefined,
  monthRange: {
    start: null,
    end: null,
  },
  tree: undefined,
  drilledRows: Map(),
  filters: [],
  progress: undefined,
  rowNotes: {
    show: false,
    row: undefined,
  },
})

function budgetMonitorReducer(state = initialState, action) {
  switch (action.type) {
    case SYNC_BUDGET:
      return state
        .set('loading', true)
        .set('progress', undefined)
        .set('dataError', false)

    case GET_BUDGET_BALANCE_FOR_ROW:
      return state
        .setIn(['drilledRows', action.rowId, 'loading'], true)
        .setIn(['drilledRows', action.rowId, 'path'], action.path)

    case GET_BUDGET_BALANCE_FOR_ROW_ERROR:
      return state.set('loading', false).set('error', action.error)

    case SYNC_BUDGET_ERROR:
      return state.set('loading', false).set('dataError', action.error)

    case SYNC_BUDGET_SUCCESS:
      return state
        .set('loading', false)
        .set('data', createBudgetData(action.data))
        .set('monthRange', getBudgetMonthRange(action.data))

    case GET_BUDGET_BALANCE_TREE_SUCCESS:
      return state
        .set('tree', mapBudgetSchemeGroup(action.tree.schemeGroup))
        .set('drilledRows', Map()) //Wipe drillings because tree could have changed

    case GET_BUDGET_BALANCE_FOR_ROW_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['drilledRows', action.rowId, 'loading'], false)
        .set(
          'tree',
          attachBalanceTree(
            state.get('tree'),
            action.tree.schemeGroup,
            action.path
          )
        )
        .set('data', attachBalanceData(state.get('data'), action.data.rows))

    case GET_BUDGET_MONITOR_PROGRESS_SUCCESS:
      return state.set('progress', action.progress)

    case SHOW_BUDGET_MONITOR_ROW_NOTES:
      return state
        .setIn(['rowNotes', 'row'], action.row)
        .setIn(['rowNotes', 'show'], action.show)

    default:
      return state
  }
}

export default budgetMonitorReducer
