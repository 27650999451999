import { Map, fromJS } from 'immutable'

import {
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_ERROR,
  SAVE_BANK_ACCOUNTS_SUCCESS,
  SAVE_BANK_ACCOUNTS_ERROR,
  GET_BANK_ACCOUNTS,
  SAVE_BANK_ACCOUNTS,
} from './constants'

const initialState = fromJS({
  bankAccounts: Map(),
  loading: false,
})

const bankAccountsReducer = (bankAccounts, action) => {
  switch (action.type) {
    case GET_BANK_ACCOUNTS_SUCCESS:
      return bankAccounts.set(action.companyCode, fromJS(action.bankAccounts))

    case SAVE_BANK_ACCOUNTS_SUCCESS:
      return bankAccounts.set(
        action.companyCode,
        fromJS(action.selectedBankAccounts)
      )

    default:
      return bankAccounts
  }
}

const loadingReducer = (loading, action) => {
  switch (action.type) {
    case GET_BANK_ACCOUNTS:
    case SAVE_BANK_ACCOUNTS:
      return true

    case GET_BANK_ACCOUNTS_SUCCESS:
    case GET_BANK_ACCOUNTS_ERROR:
    case SAVE_BANK_ACCOUNTS_SUCCESS:
    case SAVE_BANK_ACCOUNTS_ERROR:
      return false

    default:
      return loading
  }
}

const bankAccountReducer = (state = initialState, action) =>
  state
    .set('bankAccounts', bankAccountsReducer(state.get('bankAccounts'), action))
    .set('loading', loadingReducer(state.get('loading'), action))

export default bankAccountReducer
