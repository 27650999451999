/*
 *
 * BudgetTableTree constants
 *
 */

export const COLLAPSE_ROW = 'app/BudgetTableTree/COLLAPSE_ROW'

export const COLLAPSE_ALL = 'app/BudgetTableTree/COLLAPSE_ALL'

export const EXPAND_ALL = 'app/BudgetTableTree/EXPAND_ALL'

export const TARGET_MONITOR = 'monitor'
export const TARGET_SUB_BUDGET = 'sub_budget'
export const TARGET_BUDGET = 'budget'

export const TARGET_IMPORT_PREVIEW = 'preview'
export const TARGET_ROLLING_RULE = 'rolling_rule'
