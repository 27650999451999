import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getBudgetRollingRules = (companyCode, budgetId) =>
  axios
    .get(`${companyCode}/budgets/${budgetId}/rollingrules`, config())
    .then(returnData)
    .catch(catchError)

const getBudgetRollingRule = (companyCode, budgetId, ruleid) =>
  axios
    .get(`${companyCode}/budgets/${budgetId}/rollingrules/${ruleid}`, config())
    .then(returnData)
    .catch(catchError)

const createBudgetRollingRule = (companyCode, budgetId, data) =>
  axios
    .post(`${companyCode}/budgets/${budgetId}/rollingrules`, data, config())
    .then(returnData)
    .catch(catchError)

const patchBudgetRollingRule = (companyCode, budgetId, ruleid, diff) =>
  axios
    .patch(
      `${companyCode}/budgets/${budgetId}/rollingrules/${ruleid}`,
      diff,
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteBudgetRollingRule = (companyCode, budgetId, ruleid) =>
  axios
    .delete(
      `${companyCode}/budgets/${budgetId}/rollingrules/${ruleid}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

// Budget tree & data GET

const getRollingRuleBudgetTree = (companyCode, budgetId, ruleId) =>
  axios
    .get(
      `${companyCode}/budgets/${budgetId}/rollingrules/${ruleId}/tree`,
      config()
    )
    .then(returnData)
    .catch(catchError)
// /api/100-100/budgets/1/rollingrules/11/tree
const getRollingRuleBudgetData = (companyCode, budgetId, ruleId, start, end) =>
  axios
    .get(
      `${companyCode}/budgets/${budgetId}/rollingrules/${ruleId}/data?start=${start}&end=${end}`,
      config()
    )
    .then(returnData)
    .catch(catchError)
// {{base_url}}/api/100-100/budgets/1/rollingrules/11/data?start=2016-01&end=2016-12

// /api/100-100/budgets/1/rollingrules/3/data?start=2016-01&end=2016-12
const patchBudgetRollingRuleData = ({
  companyCode,
  budgetId,
  ruleId,
  start,
  end,
  patches,
}) =>
  axios
    .patch(
      `${companyCode}/budgets/${budgetId}/rollingrules/${ruleId}/data?start=${start}&end=${end}`,
      patches,
      config()
    )
    .then(returnData)
    .catch(catchError)

const rollingRuleApi = {
  getBudgetRollingRule,
  getBudgetRollingRules,
  createBudgetRollingRule,
  patchBudgetRollingRule,
  deleteBudgetRollingRule,
  getRollingRuleBudgetTree,
  getRollingRuleBudgetData,
  patchBudgetRollingRuleData,
}

export default rollingRuleApi
