/*
 *
 * CalculationConstants constants
 *
 */
export const CREATE_CALCULATION_CONSTANT =
  'app/CalculationConstants/CREATE_CALCULATION_CONSTANT'
export const CREATE_CALCULATION_CONSTANT_ERROR =
  'app/CalculationConstants/CREATE_CALCULATION_CONSTANT_ERROR'
export const CREATE_CALCULATION_CONSTANT_SUCCESS =
  'app/CalculationConstants/CREATE_CALCULATION_CONSTANT_SUCCESS'

export const DELETE_CALCULATION_CONSTANT =
  'app/CalculationConstants/DELETE_CALCULATION_CONSTANT'
export const DELETE_CALCULATION_CONSTANT_ERROR =
  'app/CalculationConstants/DELETE_CALCULATION_CONSTANT_ERROR'
export const DELETE_CALCULATION_CONSTANT_SUCCESS =
  'app/CalculationConstants/DELETE_CALCULATION_CONSTANT_SUCCESS'

export const EDIT_CALCULATION_CONSTANT =
  'app/CalculationConstants/EDIT_CALCULATION_CONSTANT'

export const GET_CALCULATION_CONSTANTS =
  'app/CalculationConstants/GET_CALCULATION_CONSTANTS'
export const GET_CALCULATION_CONSTANTS_ERROR =
  'app/CalculationConstants/GET_CALCULATION_CONSTANTS_ERROR'
export const GET_CALCULATION_CONSTANTS_SUCCESS =
  'app/CalculationConstants/GET_CALCULATION_CONSTANTS_SUCCESS'

export const TOGGLE_SHOW_CREATE_CALCULATION_CONSTANTS =
  'app/CalculationConstants/TOGGLE_SHOW_CREATE_CALCULATION_CONSTANTS'

export const UPDATE_CALCULATION_CONSTANT =
  'app/CalculationConstants/UPDATE_CALCULATION_CONSTANT'
export const UPDATE_CALCULATION_CONSTANT_ERROR =
  'app/CalculationConstants/UPDATE_CALCULATION_CONSTANT_ERROR'
export const UPDATE_CALCULATION_CONSTANT_SUCCESS =
  'app/CalculationConstants/UPDATE_CALCULATION_CONSTANT_SUCCESS'
