/*
 *
 * SubBudgetRowModal reducer
 *
 */

import { fromJS } from 'immutable'
import {
  BEGIN_ADD_SUB_BUDGET_ROW,
  EDIT_SUB_BUDGET_ROW,
} from 'containers/SubBudget/constants'

import {
  CLOSE_MODAL,
  COPY_SUB_BUDGET_ROW,
  COPY_SUB_BUDGET_ROW_ERROR,
  COPY_SUB_BUDGET_ROW_SUCCESS,
  CREATE_SUB_BUDGET_ROW,
  CREATE_SUB_BUDGET_ROW_ERROR,
  CREATE_SUB_BUDGET_ROW_SUCCESS,
  UPDATE_SUB_BUDGET_ROW,
  UPDATE_SUB_BUDGET_ROW_ERROR,
  UPDATE_SUB_BUDGET_ROW_SUCCESS,
} from './constants'

const initialState = fromJS({
  loading: false,
  error: false,
  show: false,
  rowPath: undefined,
  parentRow: undefined,
  order: undefined,
  editRow: undefined,
})

const subBudgetRowModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_ADD_SUB_BUDGET_ROW:
      return state
        .set('loading', false)
        .set('show', true)
        .set('parentRow', action.parentRow)
        .set('order', action.order)
        .set('editRow', undefined)

    case CREATE_SUB_BUDGET_ROW_SUCCESS:
    case COPY_SUB_BUDGET_ROW_SUCCESS:
    case UPDATE_SUB_BUDGET_ROW_SUCCESS:
    case CLOSE_MODAL:
      return state
        .set('show', false)
        .set('loading', false)
        .set('parentRow', undefined)
        .set('order', undefined)

    case UPDATE_SUB_BUDGET_ROW:
    case COPY_SUB_BUDGET_ROW:
    case CREATE_SUB_BUDGET_ROW:
      return state.set('loading', true).set('error', false)

    case UPDATE_SUB_BUDGET_ROW_ERROR:
    case COPY_SUB_BUDGET_ROW_ERROR:
    case CREATE_SUB_BUDGET_ROW_ERROR:
      return state.set('loading', false).set('error', action.error)

    case EDIT_SUB_BUDGET_ROW:
      return state
        .set('loading', false)
        .set('error', false)
        .set('editRow', action.row)
        .set('show', true)

    default:
      return state
  }
}

export default subBudgetRowModalReducer
