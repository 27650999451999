import axios from 'axios'
import { returnData, config, catchError } from './api-utils'
import { fetchEventSource } from '@microsoft/fetch-event-source'

const getCustomersAndPermissions = () =>
  axios
    .all([getCustomers(), getPermissions()])
    .then(
      axios.spread((customers, permissions) => ({ customers, permissions }))
    )

const getCustomers = () =>
  axios
    .get('/customers', config())
    .then(returnData)
    .catch(catchError)

const getPermissions = () =>
  axios
    .get('/permissions', config())
    .then(returnData)
    .catch(catchError)

const initializeLemonsoftUser = ({
  businessUnit,
  businessId,
  onMessage,
  onProgress,
  onError,
}) => {
  const configObj = config()
  fetchEventSource(configObj.baseURL + 'lemonsoft/initializeUser', {
    method: 'POST',
    openWhenHidden: true,
    headers: {
      ...configObj.headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ businessUnit, businessId }),
    onmessage: (event) => {
      if (event.event === 'progress' && onProgress) {
        onProgress(event)
        return
      }
      if (event.event === 'error' && onError) {
        onError(event)
        return
      }
      if (event.event === 'keepalive') {
        return
      }
      if (onMessage) {
        onMessage(event)
        return
      }
    },
    onerror: (error) => {
      if (onError) {
        onError(error)
      }
      // Event source library atomaticly retries if error is not thrown
      throw new Error(error)
    },
  }).catch(() => {})
}

const getOrganizations = () =>
  axios
    .get('/lemonsoft/lemonsoftOrganizations', config())
    .then(returnData)
    .catch(catchError)

const api = {
  getCustomersAndPermissions,
  getPermissions,
  initializeLemonsoftUser,
  getOrganizations,
}

export default api
