import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getRecurringPayments = ({ companyCode }) =>
  axios
    .get(`${companyCode}/recurringpayments`, config())
    .then(returnData)
    .catch(catchError)

const createRecurringPayment = ({ recurringPayment, companyCode }) =>
  axios
    .post(`${companyCode}/recurringpayments`, recurringPayment, config())
    .then(returnData)
    .catch(catchError)

const patchRecurringPayment = ({ recurringPaymentId, patch, companyCode }) =>
  axios
    .patch(
      `${companyCode}/recurringpayments/${recurringPaymentId}`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteRecurringPayment = ({ recurringPaymentId, companyCode }) =>
  axios
    .delete(`${companyCode}/recurringpayments/${recurringPaymentId}`, config())
    .then(returnData)
    .catch(catchError)

const getRecurringPaymentOccurences = ({ recurringPaymentId, companyCode }) =>
  axios
    .get(
      `${companyCode}/recurringpayments/${recurringPaymentId}/occurences`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchRecurringPaymentOccurence = ({
  recurringPaymentId,
  patch,
  companyCode,
  id,
}) =>
  axios
    .patch(
      `${companyCode}/recurringpayments/${recurringPaymentId}/occurences/${id}`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const createRecurringPaymentOccurence = ({
  recurringPaymentId,
  recurringPaymentOccurence,
  companyCode,
}) =>
  axios
    .post(
      `${companyCode}/recurringpayments/${recurringPaymentId}/occurences`,
      recurringPaymentOccurence,
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteRecurringPaymentOccurence = ({
  recurringPaymentId,
  recurringPaymentOccurenceId,
  companyCode,
}) =>
  axios
    .post(
      `${companyCode}/recurringpayments/${recurringPaymentId}/occurences/${recurringPaymentOccurenceId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const getRecurringPaymentPaymentEvents = ({ companyCode }) =>
  axios
    .get(`${companyCode}/recurringpayments/paymentevents`, config())
    .then(returnData)
    .catch(catchError)

const recurringPaymentApi = {
  getRecurringPayments,
  createRecurringPayment,
  patchRecurringPayment,
  deleteRecurringPayment,
  getRecurringPaymentOccurences,
  createRecurringPaymentOccurence,
  patchRecurringPaymentOccurence,
  deleteRecurringPaymentOccurence,
  getRecurringPaymentPaymentEvents,
}

export default recurringPaymentApi
