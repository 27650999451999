/*
  FILTER_SELECTED_ROOT_LOGIC - remove root and children of the root
  ALLOW_SIBLINGS_LOGIC - allow selection of siblings, disable parent
*/
export const FILTER_SELECTED_ROOT_LOGIC = 'filter_selected_root'
export const ALLOW_SIBLINGS_LOGIC = 'allow_siblings'

export const FILTER_TYPE_CUSTOM = 'custom'

export const BUDGETING_CONTEXT = 'budgeting'
export const REPORTING_CONTEXT = 'reporting'
