import {
  COPY_SYSTEM_DASHBOARD,
  COPY_SYSTEM_DASHBOARD_ERROR,
  COPY_SYSTEM_DASHBOARD_SUCCESS,
  COPY_SYSTEM_DASHBOARD_WIDGET,
  COPY_SYSTEM_DASHBOARD_WIDGET_ERROR,
  COPY_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  CREATE_SYSTEM_DASHBOARD,
  CREATE_SYSTEM_DASHBOARD_ERROR,
  CREATE_SYSTEM_DASHBOARD_SUCCESS,
  CREATE_SYSTEM_DASHBOARD_WIDGET,
  CREATE_SYSTEM_DASHBOARD_WIDGET_ERROR,
  CREATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  DELETE_SYSTEM_DASHBOARD,
  DELETE_SYSTEM_DASHBOARD_ERROR,
  DELETE_SYSTEM_DASHBOARD_SUCCESS,
  DELETE_SYSTEM_DASHBOARD_WIDGET,
  DELETE_SYSTEM_DASHBOARD_WIDGET_ERROR,
  DELETE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  GET_SYSTEM_DASHBOARDS,
  GET_SYSTEM_DASHBOARDS_ERROR,
  GET_SYSTEM_DASHBOARDS_SUCCESS,
  UPDATE_SYSTEM_DASHBOARD,
  UPDATE_SYSTEM_DASHBOARD_ERROR,
  UPDATE_SYSTEM_DASHBOARD_SUCCESS,
  UPDATE_SYSTEM_DASHBOARD_WIDGET,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_ERROR,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_ERROR,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
} from './constants'

export const copySystemDashboard = ({
  description,
  id,
  integrationSourceSystemIds,
  name,
}) => ({
  type: COPY_SYSTEM_DASHBOARD,
  description,
  id,
  integrationSourceSystemIds,
  name,
})
export const copySystemDashboardError = ({ error }) => ({
  type: COPY_SYSTEM_DASHBOARD_ERROR,
  error,
})
export const copySystemDashboardSuccess = ({ dashboard }) => ({
  type: COPY_SYSTEM_DASHBOARD_SUCCESS,
  dashboard,
})

export const copySystemDashboardWidget = ({ dashboardId, widget }) => ({
  type: COPY_SYSTEM_DASHBOARD_WIDGET,
  dashboardId,
  widget,
})
export const copySystemDashboardWidgetError = () => ({
  type: COPY_SYSTEM_DASHBOARD_WIDGET_ERROR,
})

export const copySystemDashboardWidgetSuccess = ({
  widget,
  temporaryWidgetId,
}) => ({
  type: COPY_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  widget,
  temporaryWidgetId,
})

export const createSystemDashboard = ({
  description,
  integrationSourceSystemIds,
  name,
}) => ({
  type: CREATE_SYSTEM_DASHBOARD,
  description,
  integrationSourceSystemIds,
  name,
})
export const createSystemDashboardError = ({ error }) => ({
  type: CREATE_SYSTEM_DASHBOARD_ERROR,
  error,
})
export const createSystemDashboardSuccess = ({ dashboard }) => ({
  type: CREATE_SYSTEM_DASHBOARD_SUCCESS,
  dashboard,
})

export const createSystemDashboardWidget = ({ dashboardId, widget }) => ({
  type: CREATE_SYSTEM_DASHBOARD_WIDGET,
  dashboardId,
  widget,
})
export const createSystemDashboardWidgetError = () => ({
  type: CREATE_SYSTEM_DASHBOARD_WIDGET_ERROR,
})

export const createSystemDashboardWidgetSuccess = ({
  widget,
  temporaryWidgetId,
}) => ({
  type: CREATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  widget,
  temporaryWidgetId,
})

export const deleteSystemDashboard = ({ id, navigate }) => ({
  type: DELETE_SYSTEM_DASHBOARD,
  id,
  navigate,
})
export const deleteSystemDashboardError = ({ id, error }) => ({
  type: DELETE_SYSTEM_DASHBOARD_ERROR,
  id,
  error,
})
export const deleteSystemDashboardSuccess = ({ id }) => ({
  type: DELETE_SYSTEM_DASHBOARD_SUCCESS,
  id,
})

export const deleteSystemDashboardWidget = ({ dashboardId, widgetId }) => ({
  type: DELETE_SYSTEM_DASHBOARD_WIDGET,
  widgetId,
  dashboardId,
})

export const deleteSystemDashboardWidgetSuccess = () => ({
  type: DELETE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
})

export const deleteSystemDashboardWidgetError = () => ({
  type: DELETE_SYSTEM_DASHBOARD_WIDGET_ERROR,
})

export const getSystemDashboards = () => ({
  type: GET_SYSTEM_DASHBOARDS,
})
export const getSystemDashboardsError = (error) => ({
  type: GET_SYSTEM_DASHBOARDS_ERROR,
  error,
})
export const getSystemDashboardsSuccess = ({ dashboards }) => ({
  type: GET_SYSTEM_DASHBOARDS_SUCCESS,
  dashboards,
})

export const updateSystemDashboard = ({ dashboard, updatedDashboard }) => ({
  type: UPDATE_SYSTEM_DASHBOARD,
  dashboard,
  updatedDashboard,
})
export const updateSystemDashboardError = ({ error, dashboardId }) => ({
  type: UPDATE_SYSTEM_DASHBOARD_ERROR,
  dashboardId,
  error,
})
export const updateSystemDashboardSuccess = ({ dashboard }) => ({
  type: UPDATE_SYSTEM_DASHBOARD_SUCCESS,
  dashboard,
})

export const updateSystemDashboardWidget = ({
  dashboardId,
  editWidget,
  widget,
}) => ({
  type: UPDATE_SYSTEM_DASHBOARD_WIDGET,
  dashboardId,
  editWidget,
  widget,
})
export const updateSystemDashboardWidgetError = ({ error, widgetId }) => ({
  type: UPDATE_SYSTEM_DASHBOARD_WIDGET_ERROR,
  error,
  widgetId,
})
export const updateSystemDashboardWidgetSuccess = ({
  dashboardId,
  widget,
}) => ({
  type: UPDATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  dashboardId,
  widget,
})

export const updateSystemDashboardWidgetParameters = ({
  dashboardId,
  widgetId,
  parameters,
}) => ({
  type: UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS,
  dashboardId,
  widgetId,
  parameters,
})

export const updateSystemDashboardWidgetParametersSuccess = () => ({
  type: UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
})

export const updateSystemDashboardWidgetParametersError = () => ({
  type: UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_ERROR,
})
