import React, { useState, useMemo, useRef, useEffect } from 'react'
import { Badge, ButtonGroup, Col, NavItem, Row } from 'react-bootstrap'
import { MdNotifications, MdNotificationsNone } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { dismissAllNotifications, getNotifications } from './actions'
import selectNotifications from './selectors'
import styles from './styles.module.scss'
import SendNotification from './NotificationForm'
import { SuccessButton } from 'components/Button'
import Notification from './Notification'
import { orderBy } from 'lodash'
import {
  NOTIFICATION_STATUS_ERROR,
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_WARNING,
} from './constants'
import useInterval from 'utils/hooks/useInterval'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { useEscPress } from 'utils/hooks/useKey'
import LoadingCircle from 'components/LoadingIndicator/LoadingCircle'
import { combineSimilar } from './functions'
import useHasClaim from 'utils/hooks/useHasClaim'
import useOutsideAlerter from 'utils/hooks/useOutsideAlerter'
import { PrimaryButton } from 'components/Button'

const getNotificationDot = (notifications) => {
  if (
    notifications.some(
      (notification) => notification.status === NOTIFICATION_STATUS_ERROR
    )
  ) {
    return (
      <Badge pill className={styles.notificationDot} bg="danger">
        {notifications?.length}
      </Badge>
    )
  }
  if (
    notifications.some(
      (notification) => notification.status === NOTIFICATION_STATUS_PENDING
    )
  ) {
    return (
      <div className={styles.notificationDotPending}>
        <LoadingCircle
          className={styles.loadingDot}
          size="small"
          dataTestingId="notifications-loading"
        />
      </div>
    )
  }
  if (
    notifications.some(
      (notification) => notification.status === NOTIFICATION_STATUS_WARNING
    )
  ) {
    return (
      <Badge pill className={styles.notificationDot} bg="warning">
        {notifications?.length}
      </Badge>
    )
  }
  return (
    <Badge
      pill
      className={`${styles.notificationDot} ${styles.primary}`}
      bg="custom"
    >
      {notifications?.length}
    </Badge>
  )
}

const NOTIFICATION_UPDATE_SECONDS =
  process.env.REACT_APP_NOTIFICATIONS_UPDATE_IN_SECONDS || 5

const Notifications = ({ user, customerCode, companyCode }) => {
  const dispatch = useDispatch()
  //Dispatch periodicly
  useInterval(
    () =>
      !document.hidden &&
      dispatch(getNotifications({ customerCode, companyCode })),
    NOTIFICATION_UPDATE_SECONDS * 1000,
    false
  )
  //Dispatch on context change
  useEffect(() => {
    dispatch(getNotifications({ customerCode, companyCode }))
  }, [companyCode, customerCode, dispatch])
  const notifications = useSelector(selectNotifications)
  const hasCreateClaimInScope = useHasClaim({
    claim: 'NotificationCreate',
    companyCode,
    customerCode,
  })
  const [showCreate, setShowCreate] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  useEscPress(() => setShowNotifications(false))
  const modal = useRef()
  const navBarNode = useRef()
  useOutsideAlerter(
    modal,
    (event) =>
      !document?.body?.classList?.contains('modal-open') && // don't close on delete modal confirm
      !navBarNode.current.contains(event.target) && // don't close on notification bell click (onClick handler closes)
      setShowNotifications(false)
  )
  const notificationsOrderedAndCombined = useMemo(
    () => combineSimilar(orderBy(notifications, 'created', 'desc')),
    [notifications]
  )

  return (
    <>
      <NavItem
        id="notifications"
        className={`${showNotifications ? styles.active : ''}`}
        ref={navBarNode}
        onClick={() => setShowNotifications((current) => !current)}
      >
        <div
          data-testing-id="notifications-icon"
          className={styles.navigationContainer}
        >
          {!showNotifications ? (
            <MdNotificationsNone size="25px" className={styles.icon} />
          ) : (
            <MdNotifications size="25px" className={styles.icon} />
          )}
          {notifications?.length > 0 &&
            getNotificationDot(notificationsOrderedAndCombined)}
        </div>
      </NavItem>
      {showNotifications && (
        <div className={styles.notificationsBlade} ref={modal}>
          <Row className={styles.titleRow}>
            <Col md={8}>
              <h3>
                <FormattedMessage {...messages.notifications} />
              </h3>
            </Col>
            <Col md={3}>
              <ButtonGroup className="pull-right">
                {!showCreate && hasCreateClaimInScope && (
                  <SuccessButton
                    size="sm"
                    onClick={() => setShowCreate(true)}
                    className={styles.createButton}
                  >
                    <FormattedMessage {...messages.createNotifications} />
                  </SuccessButton>
                )}
                <PrimaryButton
                  size="sm"
                  className={styles.createButton}
                  onClick={() =>
                    dispatch(
                      dismissAllNotifications({ companyCode, customerCode })
                    )
                  }
                >
                  <FormattedMessage {...messages.dissMissAll} />
                </PrimaryButton>
              </ButtonGroup>
            </Col>
          </Row>
          {showCreate && (
            <SendNotification
              onClose={() => setShowCreate(false)}
              companyCode={companyCode}
              customerCode={customerCode}
            />
          )}
          <hr className={styles.titelHr} />
          {notificationsOrderedAndCombined?.map((notification) => (
            <Notification
              {...notification}
              key={notification.id}
              companyCode={companyCode}
              customerCode={customerCode}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default Notifications
