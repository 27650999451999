/*
 *
 * IntegrationSourceSystems reducer
 *
 */
import { fromJS, List } from 'immutable'
import IntegrationSourceSystemRecord from 'records/integrationSourceSystem'
import {
  CREATE_INTEGRATION_SOURCE_SYSTEM,
  DELETE_INTEGRATION_SOURCE_SYSTEM,
  DELETE_INTEGRATION_SOURCE_SYSTEM_ERROR,
  DELETE_INTEGRATION_SOURCE_SYSTEM_SUCCESS,
  GET_INTEGRATION_SOURCE_SYSTEMS,
  GET_INTEGRATION_SOURCE_SYSTEMS_ERROR,
  GET_INTEGRATION_SOURCE_SYSTEMS_SUCCESS,
  UPDATE_INTEGRATION_SOURCE_SYSTEM,
} from './constants'

const initialState = fromJS({
  integrationSourceSystems: undefined,
  loading: false,
  error: undefined,
})

function integrationSourceSystemsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_INTEGRATION_SOURCE_SYSTEM:
    case DELETE_INTEGRATION_SOURCE_SYSTEM:
    case UPDATE_INTEGRATION_SOURCE_SYSTEM:
    case GET_INTEGRATION_SOURCE_SYSTEMS:
      return state.set('loading', true)
    case DELETE_INTEGRATION_SOURCE_SYSTEM_ERROR:
    case GET_INTEGRATION_SOURCE_SYSTEMS_ERROR: {
      return state.set('error', action.error).set('loading', false)
    }
    case GET_INTEGRATION_SOURCE_SYSTEMS_SUCCESS: {
      const newList = new List(
        action.sourceSystems.data.data.map(
          (integrationSourceSystem) =>
            new IntegrationSourceSystemRecord(integrationSourceSystem)
        )
      )
      return state
        .set('integrationSourceSystems', newList)
        .set('loading', false)
    }
    case DELETE_INTEGRATION_SOURCE_SYSTEM_SUCCESS: {
      return state
        .set('loading', false)
        .set(
          'integrationSourceSystems',
          state
            .get('integrationSourceSystems')
            .filter(
              (integrationSourceSystem) =>
                integrationSourceSystem.id !== action.id
            )
        )
    }
    default:
      return state
  }
}

export default integrationSourceSystemsReducer
