import { Record } from 'immutable'

const TagRecord = Record({
  id: undefined,
  name: '',
  forecolor: '',
  backcolor: '',
  symbol: '',
  budgetId: '',
})

export default TagRecord
