import { Record } from 'immutable'

const ReportSchemeRecord = Record({
  companies: undefined,
  customers: undefined,
  customerId: undefined,
  definedInScope: undefined,
  description: undefined,
  id: undefined,
  isChartReport: undefined,
  isTemplate: undefined,
  name: undefined,
  percentageTargets: undefined,
  reportType: undefined,
  sourceSystems: undefined,
})

export default ReportSchemeRecord
