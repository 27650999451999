import { FormCheck, OverlayTrigger, Popover } from 'react-bootstrap'
import React, { useState } from 'react'
import Form from 'react-formal'
import { injectIntl, FormattedMessage } from 'react-intl'
import * as yup from 'yup'

import { DangerButton, SuccessButton } from 'components/Button'
import FormField from 'components/FormField'

import messages from './messages'
import './styles.css'

import AADLogin from './AADLogin'
import ErrorComponent from 'components/ErrorComponent'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInviteError,
  selectUserIsForcedToConvert,
} from 'containers/App/selectors'
import { cancelAccountConversion, sendInvite } from 'containers/App/actions'
import { useNavigate } from 'react-router-dom'

const inviteSchema = ({ formatMessage }) =>
  yup.object({
    inviteEmail: yup
      .string()
      .email(formatMessage(messages.validationErrorInvitationEmail))
      .required(formatMessage(messages.validationErrorInvitationEmail)),
  })
const InviteErrorComponent = ({ inviteError }) => {
  if (inviteError?.data?.Code === 'E-0300' && inviteError?.status === 409) {
    return (
      <>
        <h3>
          <FormattedMessage {...messages.aadAccountExistsTitle} />
        </h3>
        <p>
          <FormattedMessage {...messages.aadAccountExistsText} />
        </p>
        <AADLogin />
      </>
    )
  }
  return <ErrorComponent {...inviteError} />
}

const CONVERSION_STATE_SUGGEST = 'SUGGEST_CONVERSION'
const GIVE_EMAIL_ADDRESS_STATE = 'GIVE_EMAIL_ADDRESS'
const DONE_STATE = 'DONE'

const AccountConversion = ({ intl: { formatMessage } }) => {
  const navigate = useNavigate()
  const forceConvert = useSelector(selectUserIsForcedToConvert)
  const inviteError = useSelector(selectInviteError)
  const [phase, setPhase] = useState(CONVERSION_STATE_SUGGEST)
  const [confirmState, setConfirmState] = useState(false)
  const dispatch = useDispatch()
  const handleCancelAccountConversion = () =>
    dispatch(cancelAccountConversion(navigate))
  const handleSubmit = ({ inviteEmail }) => {
    dispatch(sendInvite(inviteEmail))
    setPhase(DONE_STATE)
  }
  const schema = inviteSchema({ formatMessage })
  const getHeaderText = () => {
    if (phase === CONVERSION_STATE_SUGGEST) {
      return (
        <>
          <FormattedMessage {...messages.conversionHeaderPt1} />
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="top"
            overlay={
              <Popover id={messages.mfaInfo.id}>
                <FormattedMessage
                  {...messages.mfaInfo}
                  values={{ br: <br /> }}
                />
              </Popover>
            }
          >
            <span
              className="glyphicon glyphicon-info-sign"
              style={{ marginLeft: '10px' }}
            />
          </OverlayTrigger>
        </>
      )
    }
    if (phase === GIVE_EMAIL_ADDRESS_STATE) {
      return formatMessage(messages.conversionHeaderPt2)
    }
    if (phase === DONE_STATE) {
      return formatMessage(messages.conversionHeaderPt3)
    }
  }
  if (inviteError) {
    return <InviteErrorComponent inviteError={inviteError} />
  }
  const RenderCurrentState = () => {
    if (phase === CONVERSION_STATE_SUGGEST) {
      return (
        <div className="conversion-actions">
          <FormattedMessage
            {...messages[
              forceConvert ? 'forceConvert' : 'wannaConvertLegacyAccount'
            ]}
          />
          {!forceConvert && (
            <>
              <p />
              <FormattedMessage {...messages.confirmation} />
              <p />
              <FormCheck
                type="checkbox"
                label={formatMessage(messages.continue)}
                onChange={() => setConfirmState(!confirmState)}
                checked={confirmState}
              />
            </>
          )}

          <div className={forceConvert ? 'centered-button' : ''}>
            {!forceConvert && (
              <DangerButton onClick={handleCancelAccountConversion}>
                <FormattedMessage {...messages.cancelAccountConversion} />
              </DangerButton>
            )}
            <SuccessButton
              disabled={!confirmState && !forceConvert}
              className={!forceConvert ? 'pull-right' : 'center-block'}
              onClick={() => setPhase(GIVE_EMAIL_ADDRESS_STATE)}
            >
              <FormattedMessage {...messages.doAccountConversion} />
            </SuccessButton>
          </div>
        </div>
      )
    }

    if (phase === GIVE_EMAIL_ADDRESS_STATE) {
      return (
        <Form
          schema={schema}
          defaultValue={schema.default()}
          onSubmit={handleSubmit}
        >
          <div className="conversion-actions">
            <p>
              <FormattedMessage {...messages.wannaConvertLegacyAccountPart2} />
            </p>
            <FormField name="inviteEmail" placeholder="Your email address" />
            <div className={forceConvert ? 'centered-button' : ''}>
              {!forceConvert && (
                <DangerButton onClick={handleCancelAccountConversion}>
                  Cancel
                </DangerButton>
              )}
              <SuccessButton className="pull-right" type="submit">
                Ok
              </SuccessButton>
            </div>
          </div>
        </Form>
      )
    }

    return (
      <div>
        <FormattedMessage {...messages.thankYouForConverting} />
      </div>
    )
  }
  return (
    <>
      <h3>{getHeaderText()}</h3>
      <RenderCurrentState />
    </>
  )
}

export default injectIntl(AccountConversion)
