/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable'

import accountsReducer from './containers/Accounts/reducer'
import accountingPeriodsReducer from './containers/AccountingPeriods/reducer'
import accountSchemeReducer from './containers/AccountScheme/reducer'
import accountSchemesReducer from './containers/AccountSchemes/reducer'
import accountSchemeTemplateReducer from './containers/AccountSchemeTemplate/reducer'
import accountSchemeTemplatesReducer from './containers/AccountSchemeTemplates/reducer'
import acquisitionReducer from './containers/FasFormSelection/reducer'
import bankAccountReducer from './containers/BankAccount/reducer'
import budgetRollingRulesReducer from './containers/BudgetRollingRules/reducer'
import budgetReducer from './containers/Budget/reducer'
import budgetBalanceImportModalReducer from './containers/BudgetBalanceImportModal/reducer'
import budgetBalanceModalReducer from './containers/BudgetBalanceModal/reducer'
import budgetMonitorReducer from './containers/BudgetMonitor/reducer'
import budgetsReducer from './containers/Budgets/reducer'
import budgetTableReducer from './containers/BudgetTableTree/reducer'
import calculationConstantReducer from './containers/CalculationConstant/reducer'
import calculationConstantsReducer from './containers/CalculationConstants/reducer'
import cashflowReducer from './containers/CashFlowContainer/reducer'
import companiesReducer from './containers/Companies/reducer'
import companyReducer from './containers/CompanyIndex/reducer'
import editCompanyReducer from './containers/Company/reducer'
import companyCreationWizardReducer from './containers/CompanyCreationWizard/reducer'
import contentReducer from './containers/Content/reducer'
import customerReducer from './containers/Customer/reducer'
import customerDashboardsStoreReducer from './containers/CustomerDashboards/reducer'
import customersReducer from './containers/Customers/reducer'
import dashboardsStoreReducer from './containers/Dashboards/reducer'
import datasourcesReducer from './containers/DatasourceContainer/reducer'
import dialogsReducer from './containers/Dialogs/reducer'
import dimensionReducer from './containers/Dimension/reducer'
import dimensionsReducer from './containers/Dimensions/reducer'
import dimensionValueReducer from './containers/DimensionValue/reducer'
import globalReducer from './containers/App/reducer'
import integrationLogsReducer from './containers/IntegrationLogs/reducer'
import integrationSourceSystemsReducer from './containers/IntegrationSourceSystems/reducer'
import languageReducer from './containers/LanguageProvider/reducer'
import recurringPaymentsReducer from './containers/RecurringPayments/reducer'
import reportReducer from './containers/Report/reducer'
import reportContextDatasReducer from 'containers/ReportContextDatas/reducer'
import reportDatasReducer from 'containers/ReportDatas/reducer'
import reportingGroupsReducer from './containers/ReportingGroups/reducer'
import reportsReducer from './containers/Reports/reducer'
import reportSchemeReducer from './containers/ReportScheme/reducer'
import reportSchemesStoreReducer from './containers/ReportSchemes/reducer'
import reportSelectionsReducer from './containers/ReportSelections/reducer'
import roleReducer from './containers/Role/reducer'
import rolesReducer from './containers/Roles/reducer'
import rollingRuleReducer from './containers/RollingRule/reducer'
import rollingrulesReducer from './containers/RollingRules/reducer'
import shareReducer from './containers/Share/reducer'
import subBudgetReducer from './containers/SubBudget/reducer'
import subBudgetRowModalReducer from './containers/SubBudgetRowModal/reducer'
import subBudgetsReducer from './containers/SubBudgets/reducer'
import subBudgetTagsReducer from './containers/SubBudgetTags/reducer'
import systemDashboardsStoreReducer from './containers/SystemDashboards/reducer'
import userReducer from './containers/User/reducer'
import usersReducer from './containers/Users/reducer'
import vouchersReducer from './containers/Vouchers/reducer'
import voucherReducer from './containers/Voucher/reducer'
import notificationReducer from 'containers/Notifications/reducers'

import { CHOOSE_PROFILE, LOGOUT_SUCCESS } from './containers/App/constants'
import integrationConnectionsReducer from 'containers/IntegrationConnections/reducer'

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const createReducer = () => (state, action) =>
  combineReducers({
    // Key will be the name of the store
    accountingPeriods: accountingPeriodsReducer,
    accounts: accountsReducer,
    accountScheme: accountSchemeReducer,
    accountSchemes: accountSchemesReducer,
    accountSchemeTemplate: accountSchemeTemplateReducer,
    accountSchemeTemplates: accountSchemeTemplatesReducer,
    acquisition: acquisitionReducer,
    bankAccount: bankAccountReducer,
    budget: budgetReducer,
    budgetBalanceImportModal: budgetBalanceImportModalReducer,
    budgetBalanceModal: budgetBalanceModalReducer,
    budgetMonitor: budgetMonitorReducer,
    budgetRollingRules: budgetRollingRulesReducer,
    budgets: budgetsReducer,
    budgetTable: budgetTableReducer,
    calculationConstant: calculationConstantReducer,
    calculationConstants: calculationConstantsReducer,
    cashflow: cashflowReducer,
    companies: companiesReducer,
    company: companyReducer,
    companyCreationWizard: companyCreationWizardReducer,
    content: contentReducer,
    customer: customerReducer,
    customerDashboardsStore: customerDashboardsStoreReducer,
    customers: customersReducer,
    dashboardsStore: dashboardsStoreReducer,
    datasources: datasourcesReducer,
    dialogs: dialogsReducer,
    dimension: dimensionReducer,
    dimensions: dimensionsReducer,
    dimensionValue: dimensionValueReducer,
    editCompany: editCompanyReducer,
    notifications: notificationReducer,
    global: globalReducer,
    integrationConnections: integrationConnectionsReducer,
    integrationLogs: integrationLogsReducer,
    integrationSourceSystems: integrationSourceSystemsReducer,
    language: languageReducer,
    recurringPayments: recurringPaymentsReducer,
    report: reportReducer,
    reportContextDatas: reportContextDatasReducer,
    reportDatasStore: reportDatasReducer,
    reportingGroups: reportingGroupsReducer,
    reports: reportsReducer,
    reportScheme: reportSchemeReducer,
    reportSchemesStore: reportSchemesStoreReducer,
    reportSelections: reportSelectionsReducer,
    role: roleReducer,
    roles: rolesReducer,
    rollingRule: rollingRuleReducer,
    rollingrules: rollingrulesReducer,
    share: shareReducer,
    subBudget: subBudgetReducer,
    subBudgetRowModal: subBudgetRowModalReducer,
    subBudgets: subBudgetsReducer,
    subBudgetTags: subBudgetTagsReducer,
    systemDashboardsStore: systemDashboardsStoreReducer,
    user: userReducer,
    users: usersReducer,
    voucher: voucherReducer,
    vouchers: vouchersReducer,
  })(
    action.type === LOGOUT_SUCCESS || action.type === CHOOSE_PROFILE
      ? undefined
      : state,
    action
  )

export default createReducer
