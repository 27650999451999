/*
 *
 * SubBudgetRowModal actions
 *
 */

import {
  CLOSE_MODAL,
  COPY_SUB_BUDGET_ROW,
  COPY_SUB_BUDGET_ROW_ERROR,
  COPY_SUB_BUDGET_ROW_SUCCESS,
  CREATE_SUB_BUDGET_ROW,
  CREATE_SUB_BUDGET_ROW_ERROR,
  CREATE_SUB_BUDGET_ROW_SUCCESS,
  DUPLICATE_SUB_BUDGET_ROW,
  UPDATE_SUB_BUDGET_ROW,
  UPDATE_SUB_BUDGET_ROW_ERROR,
  UPDATE_SUB_BUDGET_ROW_SUCCESS,
} from './constants'

export const closeModal = () => ({ type: CLOSE_MODAL })

export const duplicateSubBudgetRow = ({
  companyCode,
  budgetId,
  subBudgetId,
  dimensionValues,
  name,
  duplicationMode,
  matrixDimensionNames,
  matrixes,
  targetDimension,
  start,
  end,
  dv,
  copy,
}) => ({
  type: DUPLICATE_SUB_BUDGET_ROW,
  companyCode,
  budgetId,
  subBudgetId,
  dimensionValues,
  name,
  duplicationMode,
  matrixDimensionNames,
  matrixes,
  targetDimension,
  start,
  end,
  dv,
  copy,
})
export const copySubBudgetRow = ({
  companyCode,
  budgetId,
  subBudgetId,
  name,
  order,
  start,
  end,
  dv,
  parentRow,
  copy,
}) => ({
  type: COPY_SUB_BUDGET_ROW,
  companyCode,
  budgetId,
  subBudgetId,
  name,
  order,
  start,
  end,
  dv,
  parentRow,
  copy,
})
export const copySubBudgetRowError = (error) => ({
  type: COPY_SUB_BUDGET_ROW_ERROR,
  error,
})
export const copySubBudgetRowSuccess = ({ subBudgetId, dv, tree, data }) => ({
  type: COPY_SUB_BUDGET_ROW_SUCCESS,
  subBudgetId,
  dv,
  tree,
  data,
})

export const createSubBudgetRow = ({
  companyCode,
  budgetId,
  subBudgetId,
  row,
  start,
  end,
  dv,
  parentRow,
}) => ({
  type: CREATE_SUB_BUDGET_ROW,
  companyCode,
  budgetId,
  subBudgetId,
  row,
  start,
  end,
  dv,
  parentRow,
})
export const createSubBudgetRowError = (error) => ({
  type: CREATE_SUB_BUDGET_ROW_ERROR,
  error,
})
export const createSubBudgetRowSuccess = ({
  subBudgetId,
  dv,
  tree,
  data,
  row,
}) => ({
  type: CREATE_SUB_BUDGET_ROW_SUCCESS,
  subBudgetId,
  dv,
  tree,
  data,
  row,
})

export const updateSubBudgetRow = ({
  companyCode,
  budgetId,
  subBudgetId,
  oldRow,
  newRow,
  start,
  end,
  dv,
}) => ({
  type: UPDATE_SUB_BUDGET_ROW,
  companyCode,
  budgetId,
  subBudgetId,
  oldRow,
  newRow,
  start,
  end,
  dv,
})
export const updateSubBudgetRowError = (error) => ({
  type: UPDATE_SUB_BUDGET_ROW_ERROR,
  error,
})
export const updateSubBudgetRowSuccess = ({ subBudgetId, dv, tree, data }) => ({
  type: UPDATE_SUB_BUDGET_ROW_SUCCESS,
  subBudgetId,
  dv,
  tree,
  data,
})
