/*
 *
 * ReportingGroups actions
 *
 */

import {
  ADD_DIMENSIONS_TO_REPORTING_GROUP,
  ADD_DIMENSIONS_TO_REPORTING_GROUP_ERROR,
  ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS,
  ADD_ROW,
  EDIT_ROW,
  CREATE_REPORTING_GROUP,
  CREATE_REPORTING_GROUP_ERROR,
  CREATE_REPORTING_GROUP_SUCCESS,
  DELETE_REPORTING_GROUP,
  DELETE_REPORTING_GROUP_ERROR,
  DELETE_REPORTING_GROUP_SUCCESS,
  GET_REPORTING_GROUPS,
  GET_REPORTING_GROUPS_ERROR,
  GET_REPORTING_GROUPS_SUCCESS,
  REMOVE_DIMENSION,
  REMOVE_DIMENSION_ERROR,
  REMOVE_DIMENSION_SUCCESS,
  REMOVE_DIMENSION_VALUE,
  REMOVE_DIMENSION_VALUE_ERROR,
  REMOVE_DIMENSION_VALUE_SUCCESS,
  TOGGLE_SHOW_REPORTING_GROUP_FORM,
  TOGGLE_COLLAPSE,
  UPDATE_REPORTING_GROUP,
  UPDATE_REPORTING_GROUP_ERROR,
  UPDATE_REPORTING_GROUP_SUCCESS,
} from './constants'

export const addDimensionsToReportingGroup = ({
  companyCode,
  reportingGroupId,
  dimensions,
  dimensionValues,
}) => ({
  type: ADD_DIMENSIONS_TO_REPORTING_GROUP,
  companyCode,
  reportingGroupId,
  dimensions,
  dimensionValues,
})
export const addDimensionsToReportingGroupError = (error) => ({
  type: ADD_DIMENSIONS_TO_REPORTING_GROUP_ERROR,
  error,
})
export const addDimensionsToReportingGroupSuccess = ({ companyCode }) => ({
  type: ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS,
  companyCode,
})

export const addRow = ({ reportingGroupId }) => ({
  type: ADD_ROW,
  reportingGroupId,
})

export const editRow = ({ reportingGroupId }) => ({
  type: EDIT_ROW,
  reportingGroupId,
})

export const createReportingGroup = ({
  companyCode,
  reportingGroup,
  dimensions,
  dimensionValues,
}) => ({
  type: CREATE_REPORTING_GROUP,
  companyCode,
  reportingGroup,
  dimensions,
  dimensionValues,
})
export const createReportingGroupError = (error) => ({
  type: CREATE_REPORTING_GROUP_ERROR,
  error,
})
export const createReportingGroupSuccess = ({ companyCode }) => ({
  type: CREATE_REPORTING_GROUP_SUCCESS,
  companyCode,
})

export const deleteReportingGroup = ({ companyCode, id }) => ({
  type: DELETE_REPORTING_GROUP,
  companyCode,
  id,
})
export const deleteReportingGroupError = (error) => ({
  type: DELETE_REPORTING_GROUP_ERROR,
  error,
})
export const deleteReportingGroupSuccess = ({ companyCode }) => ({
  type: DELETE_REPORTING_GROUP_SUCCESS,
  companyCode,
})

export const getReportingGroups = ({ companyCode }) => ({
  type: GET_REPORTING_GROUPS,
  companyCode,
})
export const getReportingGroupsError = (error) => ({
  type: GET_REPORTING_GROUPS_ERROR,
  error,
})
export const getReportingGroupsSuccess = ({
  reportingGroups,
  companyCode,
}) => ({ type: GET_REPORTING_GROUPS_SUCCESS, reportingGroups, companyCode })

export const removeDimension = ({
  companyCode,
  reportingGroupId,
  dimensionId,
}) => ({ type: REMOVE_DIMENSION, companyCode, reportingGroupId, dimensionId })
export const removeDimensionError = (error) => ({
  type: REMOVE_DIMENSION_ERROR,
  error,
})
export const removeDimensionSuccess = ({
  reportingGroupId,
  dimensionId,
  companyCode,
}) => ({
  type: REMOVE_DIMENSION_SUCCESS,
  reportingGroupId,
  dimensionId,
  companyCode,
})

export const removeDimensionValue = ({
  companyCode,
  reportingGroupId,
  dimensionValueId,
}) => ({
  type: REMOVE_DIMENSION_VALUE,
  companyCode,
  reportingGroupId,
  dimensionValueId,
})
export const removeDimensionValueError = (error) => ({
  type: REMOVE_DIMENSION_VALUE_ERROR,
  error,
})
export const removeDimensionValueSuccess = ({
  reportingGroupId,
  dimensionValueId,
  companyCode,
}) => ({
  type: REMOVE_DIMENSION_VALUE_SUCCESS,
  reportingGroupId,
  dimensionValueId,
  companyCode,
})

export const toggleShowReportingGroupForm = (state) => ({
  type: TOGGLE_SHOW_REPORTING_GROUP_FORM,
  state,
})

export const toggleCollapse = ({ reportingGroupId, companyCode }) => ({
  type: TOGGLE_COLLAPSE,
  reportingGroupId,
  companyCode,
})

export const updateReportingGroup = ({
  companyCode,
  newReportingGroup,
  oldReportingGroup,
}) => ({
  type: UPDATE_REPORTING_GROUP,
  companyCode,
  newReportingGroup,
  oldReportingGroup,
})
export const updateReportingGroupError = (error) => ({
  type: UPDATE_REPORTING_GROUP_ERROR,
  error,
})
export const updateReportingGroupSuccess = ({ companyCode }) => ({
  type: UPDATE_REPORTING_GROUP_SUCCESS,
  companyCode,
})
