import { createSelector } from 'reselect'
import { List } from 'immutable'

import selectCustomers from 'containers/Customers/selectors'
import { alphabeticallyAscendingComparer } from 'utils/sort'

/**
 * Direct selector to the customer state domain
 */
const selectCustomerDomain = () => (state) => state.get('customer')

/**
 * Other specific selectors
 */

const customerFilter = (state, props) =>
  props?.customerCode ?? props?.params?.customerCode

/**
 * Default selector used by Customer
 */

const selectCustomer = createSelector(
  [customerFilter, selectCustomers],
  (customerCode, customers) => {
    const customer = customers?.find((c) => c.code === customerCode)
    return customer
  }
)

const selectLoading = createSelector(
  selectCustomerDomain(),
  (customer) => customer.get('loading')
)

const selectError = createSelector(
  selectCustomerDomain(),
  (customer) => customer.get('error')
)

const selectCompaniesByCustomerCode = createSelector(
  [customerFilter, selectCustomers],
  (customerCode, customers) => {
    const customer = customers?.find((c) => c.code === customerCode)
    return (customer?.companies || List()).sort(alphabeticallyAscendingComparer)
  }
)

export default selectCustomer
export {
  customerFilter,
  selectCompaniesByCustomerCode,
  selectCustomerDomain,
  selectLoading,
  selectError,
}
