import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import React from 'react'
import { Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import LoadingCircle from './LoadingCircle'
import styles from './LoadingCircle.module.css'

const propTypes = {
  className: PropTypes.string,
  messages: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.array]),
}

const LoadingIndicator = ({
  className,
  messages = [],
  progress,
  size = 'normal',
}) => {
  if (!!progress) {
    messages.push({ defaultMessage: progress })
  }
  if (!messages || messages.length === 0) {
    return <LoadingCircle className={className} />
  }
  return (
    <Row>
      <Col md={12} className={`headerWrapper ${styles.fontColor}`}>
        <LoadingCircle className={className} size={size} />
        {messages.map((message, i) => (
          <div
            className={classNames(styles.loadingMessage, className)}
            key={message.defaultMessage + i}
          >
            {message.defaultMessage}
          </div>
        ))}
      </Col>
    </Row>
  )
}
LoadingIndicator.propTypes = propTypes

export default LoadingIndicator
