import { fromJS, List, Map } from 'immutable'
import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_CASH_FLOW,
  GET_CASH_FLOW_ERROR,
  GET_CASH_FLOW_SUCCESS,
  GET_INVOICES_BY_DATE,
  GET_INVOICES_BY_DATE_ERROR,
  GET_INVOICES_BY_DATE_SUCCESS,
  GET_EXCLUDED_DAYS,
  GET_EXCLUDED_DAYS_ERROR,
  GET_EXCLUDED_DAYS_SUCCESS,
  GET_EXCLUDED_INVOICES,
  GET_EXCLUDED_INVOICES_ERROR,
  GET_EXCLUDED_INVOICES_SUCCESS,
  SET_EXCLUDED_DAYS,
  SET_EXCLUDED_DAYS_ERROR,
  SET_EXCLUDED_DAYS_SUCCESS,
  SET_EXCLUDED_INVOICES,
  SET_EXCLUDED_INVOICES_ERROR,
  SET_EXCLUDED_INVOICES_SUCCESS,
} from './constants'

const initialState = fromJS({
  invoices: null,
  loading: false,
  error: false,
  daySummaries: Map(),
  dateRanges: List(),
  cashInHand: undefined,
  excludedDays: List(),
  excludedInvoices: List(),
})

export const cashFlowContainerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASH_FLOW:
    case GET_INVOICES:
    case GET_INVOICES_BY_DATE:
    case GET_EXCLUDED_DAYS:
    case GET_EXCLUDED_INVOICES:
    case SET_EXCLUDED_DAYS:
    case SET_EXCLUDED_INVOICES:
      return state.set('loading', true).set('error', false)

    case GET_CASH_FLOW_SUCCESS:
      return state
        .set('loading', false)
        .set('daySummaries', fromJS(action.cashFlow.daySummaries))
        .set('dateRanges', fromJS(action.cashFlow.dateRanges))
        .set('cashInHand', action.cashFlow.cashInHand)

    case GET_EXCLUDED_DAYS_SUCCESS:
      return state
        .set('excludedDays', fromJS(action.excludedDays.days))
        .set('loading', false)

    case GET_EXCLUDED_INVOICES_SUCCESS:
      return state
        .set(
          'excludedInvoices',
          fromJS(action.excludedInvoices.sourceInvoiceIds)
        )
        .set('loading', false)

    case SET_EXCLUDED_DAYS_SUCCESS:
      return state
        .set('excludedDays', fromJS(action.excludedDays.days))
        .set('loading', false)

    case SET_EXCLUDED_INVOICES_SUCCESS:
      return state
        .set(
          'excludedInvoices',
          fromJS(action.excludedInvoices.sourceInvoiceIds)
        )
        .set('loading', false)

    case GET_INVOICES_SUCCESS:
      return state
        .set('invoices', fromJS(action.invoices))
        .set('loading', false)

    case GET_INVOICES_BY_DATE_SUCCESS:
      return state
        .set('invoicesByDate', fromJS(action.invoices))
        .set('loading', false)

    case GET_CASH_FLOW_ERROR:
    case GET_INVOICES_ERROR:
    case GET_INVOICES_BY_DATE_ERROR:
    case GET_EXCLUDED_DAYS_ERROR:
    case SET_EXCLUDED_DAYS_ERROR:
    case GET_EXCLUDED_INVOICES_ERROR:
    case SET_EXCLUDED_INVOICES_ERROR:
      return state.set('loading', false).set('error', action.error)

    default:
      return state
  }
}

export default cashFlowContainerReducer
