/*
 * DatePicker Messages
 *
 * This contains all the text for the DatePicker component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  clearSelection: {
    id: 'app.components.DimensionTool.clearSelection',
    description:
      'Aria-label for the button that clears the selection in the dimension tool',
    defaultMessage: 'Clear selection',
  },
  emptyFilter: {
    id: 'app.components.DimensionTool.emptyfilter',
    description:
      'Text to display when the current filter does not return any results',
    defaultMessage: 'The filter returned no results',
  },
  emptyList: {
    id: 'app.components.DimensionTool.emptylist',
    description: 'Text to display when there are no items in a list',
    defaultMessage: 'There are no items in this list',
  },
  placeholder: {
    id: 'app.components.DimensionTool.placeholder',
    description: 'Placeholder text for multiselect input field',
    defaultMessage: 'Select Dimension...',
  },
  customFilterPlaceholder: {
    id: 'app.components.DimensionTool.customFilterPlaceholder',
    description: 'Placeholder text for multiselect custom filter field',
    defaultMessage: 'Set dimension filter...',
  },
})
