/*
 *
 * Customers reducer
 *
 */

import { fromJS, List } from 'immutable'
import { CompanyRecord, CustomerRecord } from 'records'

import { UPDATE_CUSTOMER_SUCCESS } from 'containers/Customer/constants'
import {
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
} from 'containers/Companies/constants'
import { UPDATE_COMPANY_SUCCESS } from 'containers/Company/constants'

import {
  TOGGLE_SHOW_CREATE_CUSTOMER_FORM,
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ERROR,
  SET_CUSTOMERS_LOADING,
} from './constants'

const initialState = fromJS({
  customers: List(),
  error: false,
  loading: false,
  showCreateCustomerForm: false,
})
/**
 * Inserts currentCompany to a hierarchy of companies.
 * Companies are an immutable list of CompanyRecords.
 * Calculates levels. Starts from 1 by default
 * @returns a new immutable list of CompanyRecords
 */
const addCompanyToHierarchy = (companies, currentCompany, level = 1) => {
  // console.log('addCompanyToHierarchy', companies)
  const companiesWithLevel = List(
    companies?.map((company) => {
      // console.log('addCompanyToHierarchy loop', company, List.isList(company.children))
      let tmpChildren = List.isList(company.children)
        ? company.children
        : List(company.children) //Children are not necessarily Lists but now they are
      if (company.id === currentCompany?.parentId) {
        tmpChildren = tmpChildren.push(currentCompany)
      }
      const children = addCompanyToHierarchy(
        tmpChildren,
        currentCompany,
        level + 1
      ) //Children are not necessarily records
      const jsCompany = convertToJS(company)
      const newComp = new CompanyRecord({ ...jsCompany, level, children }) //Now they are
      return newComp
    })
  )
  return companiesWithLevel
}
const findCompanyFromHierarchy = (companies, id) => {
  let foundCompany = companies.find((c) => c.id === id)
  if (foundCompany) {
    return foundCompany
  }
  companies.forEach((c) => {
    //loop through all children. This is recursive
    if (c.children && !foundCompany) {
      foundCompany = findCompanyFromHierarchy(c.children, id)
    }
    if (foundCompany) {
      return foundCompany
    }
  })
  return foundCompany
}
const convertToJS = (company) => {
  const jsCompany = company.toJS ? company.toJS() : company
  return jsCompany
}
const removeFromHierarchy = ({ deletedCompany, root }) => {
  //1. Find parent
  const parent = findCompanyFromHierarchy(root, deletedCompany.parentId)
  if (!parent) {
    return root.filter((c) => c.code !== deletedCompany.code)
  }
  //2. Reform parent's children
  let newChildren = parent.children.filter(
    (c) => c.code !== deletedCompany.code
  )
  //Reconstruct family tree
  let newParent = new CompanyRecord({
    ...convertToJS(parent),
    children: newChildren,
  })
  // parent.set('children', newChildren)
  let MAXITERATIONS = 10
  while (newParent.parentId && MAXITERATIONS--) {
    const grandParent = findCompanyFromHierarchy(root, newParent.parentId)
    newChildren = updateListById(grandParent.children, newParent)
    newParent = new CompanyRecord({
      ...convertToJS(grandParent),
      children: newChildren,
    })
  }
  root = updateListById(root, newParent)
  return root
}
const updateListById = (paramList, item) => {
  const list = List.isList(paramList) ? paramList : List()
  const index = list.findIndex((i) => i.id === item.id)
  return list.set(index, item)
}
const mapCustomer = ({ companies, ...otherProps }) =>
  new CustomerRecord({
    ...otherProps,
    companies: companies
      ? List(companies.map((c) => new CompanyRecord(c)))
      : List(),
  })

// From `customers` finds the customer with `customerCode`,
// applies the `updateFunction` on the companies of the customer,
// and then returns the customers with updated companies.
const updateCompanies = (customers, customerCode, updateFunction) => {
  const customer = customers.find((c) => c.code === customerCode)
  const customerIndex = customers.findIndex((c) => c.code === customerCode)

  return customers.set(
    customerIndex,
    customer.update('companies', updateFunction)
  )
}

function customersReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CUSTOMER_SUCCESS:
      return state
        .set('showCreateCustomerForm', false)
        .set('loading', false)
        .set(
          'customers',
          state.get('customers').push(mapCustomer(action.customer))
        )

    case DELETE_CUSTOMER_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'customers',
          state
            .get('customers')
            .filter((customer) => customer.code !== action.customerCode)
        )

    case TOGGLE_SHOW_CREATE_CUSTOMER_FORM:
      return state.set(
        'showCreateCustomerForm',
        !state.get('showCreateCustomerForm')
      )

    case DELETE_CUSTOMER:
    case CREATE_CUSTOMER:
    case GET_CUSTOMERS:
    case SET_CUSTOMERS_LOADING:
      return state.set('loading', true).set('error', false)

    case GET_CUSTOMERS_SUCCESS:
      return state
        .set('loading', false)
        .set('customers', List(action.customers.map(mapCustomer)))

    case DELETE_CUSTOMER_ERROR:
    case CREATE_CUSTOMER_ERROR:
    case GET_CUSTOMERS_ERROR:
      return state.set('loading', false).set('error', action.error)

    // Actions from different containers

    case UPDATE_CUSTOMER_SUCCESS: {
      const customers = state.get('customers')
      return state.set(
        'customers',
        customers
          .filter((c) => c.code !== action.customer.code)
          .push(mapCustomer(action.customer))
      )
    }

    case CREATE_COMPANY_SUCCESS: {
      const customers = state.get('customers')

      return state.set(
        'customers',
        updateCompanies(customers, action.customerCode, (companies) => {
          const company = new CompanyRecord(action.company)
          const allCompanies = companies.push(company)
          if (
            company.parentId ||
            companies.some((company) => company.parentId)
          ) {
            const topLevelCompanies = allCompanies?.filter(
              (company) => !company.parentId
            )
            const modifiedTopLvlCompanies = addCompanyToHierarchy(
              topLevelCompanies,
              company
            )
            let returnValue = allCompanies
            modifiedTopLvlCompanies?.forEach((topLvlCompany) => {
              returnValue = updateListById(returnValue, topLvlCompany)
            })
            return returnValue
          }
          return allCompanies
        })
      )
    }

    case DELETE_COMPANY_SUCCESS: {
      const customers = state.get('customers')

      return state.set(
        'customers',
        updateCompanies(customers, action.customerCode, (companies) => {
          //1. remove from root
          let returnValue = companies.filter(
            (c) => c.code !== action.companyCode
          )
          //2. remove from hierarchy
          const deletedCompany = companies.find(
            (c) => c.code === action.companyCode
          )
          if (deletedCompany.parentId) {
            const topLevelCompanies = companies?.filter(
              (company) => !company.parentId
            )
            const modifiedTopLvlCompanies = removeFromHierarchy({
              root: topLevelCompanies,
              deletedCompany,
            })
            modifiedTopLvlCompanies?.forEach((topLvlCompany) => {
              returnValue = updateListById(returnValue, topLvlCompany)
            })
          }
          return returnValue
        })
      )
    }

    case UPDATE_COMPANY_SUCCESS: {
      const customers = state.get('customers')
      const { customerCode, updatedCompany } = action

      return state.set(
        'customers',
        updateCompanies(customers, customerCode, (companies) => {
          const companyIndex = companies.findIndex(
            (company) => company.code === updatedCompany.code
          )
          const deletedCompany = companies.get(companyIndex) //deletedCompany now holds the old parent id
          const updatedCompanyRecord = new CompanyRecord(updatedCompany)
          let newToplevel = companies.set(companyIndex, updatedCompanyRecord) //old parentId is now gone
          if (deletedCompany.parentId) {
            const modifiedTopLvlCompanies = removeFromHierarchy({
              root: newToplevel?.filter((company) => !company.parentId),
              deletedCompany,
            })
            modifiedTopLvlCompanies?.forEach((topLvlCompany) => {
              newToplevel = updateListById(newToplevel, topLvlCompany)
            })
          }
          if (updatedCompany.parentId) {
            const modifiedTopLvlCompanies = addCompanyToHierarchy(
              newToplevel?.filter((company) => !company.parentId),
              updatedCompany
            )
            modifiedTopLvlCompanies?.forEach((topLvlCompany) => {
              newToplevel = updateListById(newToplevel, topLvlCompany)
            })
          }
          return newToplevel
        })
      )
    }

    default:
      return state
  }
}

export default customersReducer
