import { Record } from 'immutable'

const RecurringPaymentRecord = Record({
  id: 0,
  companyId: 0,
  dueDate: null,
  repeatCount: null,
  frequency: '',
  amount: 0.0,
  paymentType: '',
  description: '',
})

export default RecurringPaymentRecord
