import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { Record } from 'immutable'
import diff from 'immutablediff'

import systemDashboardsApi from 'api/SystemDashboardApi'
import { handleError } from 'api/api-utils'

import {
  copySystemDashboardError,
  copySystemDashboardSuccess,
  copySystemDashboardWidgetError,
  copySystemDashboardWidgetSuccess,
  createSystemDashboardError,
  createSystemDashboardSuccess,
  createSystemDashboardWidgetError,
  createSystemDashboardWidgetSuccess,
  deleteSystemDashboardError,
  deleteSystemDashboardSuccess,
  deleteSystemDashboardWidgetError,
  deleteSystemDashboardWidgetSuccess,
  getSystemDashboardsError,
  getSystemDashboardsSuccess,
  updateSystemDashboardError,
  updateSystemDashboardSuccess,
  updateSystemDashboardWidgetError,
  updateSystemDashboardWidgetSuccess,
  updateSystemDashboardWidgetParametersError,
  updateSystemDashboardWidgetParametersSuccess,
} from './actions'
import {
  COPY_SYSTEM_DASHBOARD,
  COPY_SYSTEM_DASHBOARD_WIDGET,
  CREATE_SYSTEM_DASHBOARD,
  CREATE_SYSTEM_DASHBOARD_WIDGET,
  DELETE_SYSTEM_DASHBOARD,
  DELETE_SYSTEM_DASHBOARD_WIDGET,
  GET_SYSTEM_DASHBOARDS,
  UPDATE_SYSTEM_DASHBOARD,
  UPDATE_SYSTEM_DASHBOARD_WIDGET,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS,
} from './constants'

import { parseLocation } from './functions'

const UpdateDashboardRecord = Record({
  name: undefined,
  integrationSourceSystemIds: undefined,
  description: undefined,
})

const UpdateWidgetRecord = Record({
  parameters: undefined,
  type: undefined,
})

export function* copySystemDashboard(action) {
  const { description, id, integrationSourceSystemIds, name } = action
  try {
    const dashboard = yield call(systemDashboardsApi.copySystemDashboard, {
      id,
      systemDashboard: {
        description,
        integrationSourceSystemIds,
        name,
      },
    })
    yield put(copySystemDashboardSuccess({ dashboard }))
  } catch (error) {
    yield put(handleError(error, copySystemDashboardError))
  }
}

export function* createSystemDashboard(action) {
  const { description, integrationSourceSystemIds, name } = action

  try {
    const dashboard = yield call(systemDashboardsApi.createSystemDashboard, {
      description,
      integrationSourceSystemIds,
      name,
    })
    yield put(createSystemDashboardSuccess({ dashboard }))
  } catch (error) {
    yield put(handleError(error, createSystemDashboardError))
  }
}

export function* copySystemDashboardWidget(action) {
  const { dashboardId, widget } = action

  try {
    const newWidget = yield call(
      systemDashboardsApi.createSystemDashboardWidget,
      {
        dashboardId,
        widget,
      }
    )
    yield put(
      copySystemDashboardWidgetSuccess({
        widget: newWidget,
        temporaryWidgetId: widget.id,
      })
    )
  } catch (error) {
    yield put(handleError(error, copySystemDashboardWidgetError))
  }
}

export function* createSystemDashboardWidget(action) {
  const { dashboardId, widget } = action

  try {
    const newWidget = yield call(
      systemDashboardsApi.createSystemDashboardWidget,
      {
        dashboardId,
        widget,
      }
    )
    yield put(
      createSystemDashboardWidgetSuccess({
        widget: newWidget,
        temporaryWidgetId: widget.id,
      })
    )
  } catch (error) {
    yield put(handleError(error, createSystemDashboardWidgetError))
  }
}

export function* deleteSystemDashboard(action) {
  const { id, navigate } = action

  try {
    yield call(systemDashboardsApi.deleteSystemDashboard, {
      dashboardId: id,
    })
    yield put(deleteSystemDashboardSuccess({ id }))
    navigate(parseLocation())
  } catch (error) {
    yield put(deleteSystemDashboardError({ error, id }))
  }
}

export function* deleteSystemDashboardWidget(action) {
  const { dashboardId, widgetId } = action

  try {
    yield call(systemDashboardsApi.deleteSystemDashboardWidget, {
      dashboardId,
      widgetId,
    })
    yield put(deleteSystemDashboardWidgetSuccess())
  } catch (error) {
    yield put(deleteSystemDashboardWidgetError(error))
  }
}

export function* getSystemDashboards(action) {
  try {
    const dashboards = yield call(systemDashboardsApi.getSystemDashboards)
    yield put(getSystemDashboardsSuccess({ dashboards }))
  } catch (error) {
    yield put(handleError(error, getSystemDashboardsError))
  }
}

export function* updateSystemDashboard(action) {
  const { dashboard, updatedDashboard } = action
  const { id: dashboardId } = dashboard
  const patch = diff(
    new UpdateDashboardRecord(dashboard),
    new UpdateDashboardRecord(updatedDashboard)
  ).toJS()

  try {
    const updatedDashboard = yield call(
      systemDashboardsApi.patchSystemDashboard,
      {
        patch,
        dashboardId,
      }
    )
    yield put(updateSystemDashboardSuccess({ dashboard: updatedDashboard }))
  } catch (error) {
    yield put(updateSystemDashboardError({ error, dashboardId }))
  }
}

export function* updateSystemDashboardWidget(action) {
  const { dashboardId, editWidget, widget } = action
  const {
    parameters: { x, y, width, height },
  } = editWidget.toJS()

  try {
    const patch = diff(
      new UpdateWidgetRecord(editWidget),
      new UpdateWidgetRecord({
        ...widget,
        parameters: {
          x,
          y,
          width,
          height,
          ...widget.parameters,
        },
      })
    ).toJS()

    const updatedWidget = yield call(
      systemDashboardsApi.patchSystemDashboardWidget,
      {
        dashboardId,
        widgetId: editWidget.id,
        patch,
      }
    )
    yield put(
      updateSystemDashboardWidgetSuccess({ dashboardId, widget: updatedWidget })
    )
  } catch (error) {
    yield put(handleError(error, updateSystemDashboardWidgetError))
  }
}

export function* updateSystemDashboardWidgetParameters(action) {
  const { dashboardId, widgetId, parameters } = action

  try {
    yield call(systemDashboardsApi.upsertWidgetParameters, {
      dashboardId,
      widgetId,
      parameters,
    })
    yield put(updateSystemDashboardWidgetParametersSuccess())
  } catch (error) {
    yield put(handleError(error, updateSystemDashboardWidgetParametersError))
  }
}

export function* systemDashboards() {
  yield all([
    takeEvery(COPY_SYSTEM_DASHBOARD, copySystemDashboard),
    takeEvery(COPY_SYSTEM_DASHBOARD_WIDGET, copySystemDashboardWidget),
    takeEvery(CREATE_SYSTEM_DASHBOARD, createSystemDashboard),
    takeEvery(CREATE_SYSTEM_DASHBOARD_WIDGET, createSystemDashboardWidget),
    takeEvery(DELETE_SYSTEM_DASHBOARD, deleteSystemDashboard),
    takeEvery(DELETE_SYSTEM_DASHBOARD_WIDGET, deleteSystemDashboardWidget),
    takeEvery(UPDATE_SYSTEM_DASHBOARD_WIDGET, updateSystemDashboardWidget),
    takeEvery(
      UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS,
      updateSystemDashboardWidgetParameters
    ),
    takeLatest(GET_SYSTEM_DASHBOARDS, getSystemDashboards),
    takeLatest(UPDATE_SYSTEM_DASHBOARD, updateSystemDashboard),
  ])
}

export default systemDashboards
