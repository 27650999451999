import PropTypes from 'prop-types'

import React from 'react'

const ListItem = (props) => {
  const { id } = props.item
  let { path } = props.item
  if (!path) path = ['']
  const name = path.join(' ')
  const level = `level-${path.length}`

  return (
    <div className={level} data-id={id}>
      {name}
    </div>
  )
}

ListItem.propTypes = {
  item: PropTypes.object,
}

export default ListItem
