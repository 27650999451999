import PropTypes from 'prop-types'
import React from 'react'
import { NavDropdown, Dropdown } from 'react-bootstrap'
import { injectIntl, intlShape } from 'react-intl'

import NavItem from './NavItem'
import messages from './messages'
import styles from './styles.module.scss'

const CustomerDropdownMenu = ({
  customerCode,
  intl: { formatMessage },
  setOpen,
  hasParent,
  open,
}) => {
  const customerDropdownItems = [
    {
      to: `/${customerCode}`,
      messageId: messages.companies,
    },
    {
      to: `/${customerCode}/customerdashboards`,
      messageId: messages.dashboards,
    },
    {
      to: `/${customerCode}/customerreports-beta`,
      messageId: messages.customerReportSelections,
    },
    {
      to: `/${customerCode}/accountschemetemplates`,
      messageId: messages.accountSchemeTemplates,
    },
    {
      to: `/${customerCode}/calculationconstants`,
      messageId: messages.calculationConstants,
    },
    {
      to: `/${customerCode}/reportschemes`,
      messageId: messages.reportSchemes,
    },
    {
      to: `/${customerCode}/reportschemetemplates`,
      messageId: messages.reportSchemeTemplates,
    },
    {
      divider: true,
    },
    {
      to: `/${customerCode}/users`,
      messageId: messages.users,
    },
    {
      to: `/customers/${customerCode}`,
      messageId: messages.settings,
    },
  ]

  return (
    <NavDropdown
      id="customer-manage-nav-dropdown"
      title={
        hasParent
          ? formatMessage(messages.customerNavHeader)
          : formatMessage(messages.settings)
      }
      data-testing-id="header-customer-list"
      onToggle={(state) => setOpen(state)}
      open={open}
      className={
        hasParent
          ? styles.settingsTitleDropped
          : !open
          ? styles.settingsTitleInNav
          : `${styles.settingsTitleInNav} ${styles.active}`
      }
    >
      {customerDropdownItems.map((item) =>
        item.divider ? (
          <Dropdown.Divider key="divider" />
        ) : (
          <NavItem
            formatMessage={formatMessage}
            {...item}
            key={item.messageId.id}
          />
        )
      )}
    </NavDropdown>
  )
}

CustomerDropdownMenu.propTypes = {
  customerCode: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
}

export default injectIntl(CustomerDropdownMenu)
