import { Record } from 'immutable'

const ContentRecord = Record({
  id: 0,
  type: undefined,
  targetId: undefined,
  data: undefined,
  dimensionTarget: undefined,
  name: undefined,
  createdBy: undefined,
  createdByName: undefined,
  lastModifiedBy: undefined,
  lastModified: undefined,
  created: undefined,
})

export default ContentRecord
