import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const useLocationUpdater = (updateAvailable) => {
  const location = useLocation()
  const prevLocation = useRef(location)

  useEffect(() => {
    //Represents the current page based on the location pathname.
    //It is obtained by removing the leading slash, splitting the pathname by slashes,
    //and finding the first part that does not contain any digits.
    const currentPage = location.pathname
      .substring(1)
      .split('/')
      .find((part) => !/\d/.test(part))
    const previousPage = prevLocation.current.pathname
      .substring(1)
      .split('/')
      .find((part) => !/\d/.test(part))

    if (currentPage !== previousPage && updateAvailable) {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(async function(names) {
          await Promise.all(names.map((name) => caches.delete(name)))
        })
      }
      window.location.reload()
    }

    prevLocation.current = location
  }, [location, updateAvailable])
}

export default useLocationUpdater
