import axios from 'axios'
import token from './TokenStorage'
import { apiConfig, returnData, config, catchError } from './api-utils'

const getIntegrationSourceSystems = ({ context }) => {
  const location = context ? `/${context}` : ''
  return axios.get(`${location}/integrationsourcesystems`, config())
}
const createIntegrationSourceSystem = ({ sourceSystem }) =>
  axios.post('/integrationsourcesystems', { ...sourceSystem }, config())
const deleteIntegrationSourceSystem = ({ id }) =>
  axios
    .delete(`/integrationsourcesystems/${id}`, config())
    .then(returnData)
    .catch(catchError)
const patchIntegrationSourceSystem = ({ id, patch }) =>
  axios.patch(`/integrationsourcesystems/${id}`, patch, config())

const getIntegrationLogs = ({
  companyCode,
  start,
  end,
  page,
  pageSize,
  itemType,
  groupId,
}) =>
  axios
    .get(
      `${companyCode}/Integrationlog/`,
      config({
        params: {
          start,
          end,
          page,
          pageSize,
          logItemType: itemType,
          groupId,
        },
      })
    )
    .then(returnData)
    .catch(catchError)

const executeCompanyIntegration = ({
  companyCode,
  startDate,
  endDate,
  materialTypes,
  modifiedLogicApplied,
}) =>
  axios
    .post(
      `${companyCode}/integration/execute`,
      { startDate, endDate, materialTypes, modifiedLogicApplied },
      config()
    )
    .then(returnData)
    .catch(catchError)

const executeSingleIntegration = ({
  companyCode,
  startDate,
  endDate,
  materialTypes,
  integrationCode,
  modifiedLogicApplied,
}) =>
  axios
    .post(
      `${companyCode}/integration/${integrationCode}/execute`,
      { startDate, endDate, materialTypes, modifiedLogicApplied },
      config()
    )
    .then(returnData)
    .catch(catchError)

const getIntegrationEvents = ({ companyCode, limit }) =>
  axios
    .get(`${companyCode}/integration/events?limit=${limit}`, config())
    .then(returnData)
    .catch(catchError)

const getIntegrationConnections = ({ companyCode }) =>
  axios
    .get(`${companyCode}/integration`, config())
    .then(returnData)
    .catch(catchError)

const makeFormData = (file) => {
  const formData = new FormData()
  formData.append('uploadedFile', file)
  return formData
}

const getScheduledIntegrations = ({ ovt }) =>
  axios
    .get(`${apiConfig.gtUrl}Company/GetScheduledIntegrations?ovt=${ovt}`, {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token.getTokens().access_token}`,
        refresh_token: `${token.getTokens().refresh_token}`,
        'x-finazilla.request.client.user.guid': token.getProfileId(),
      },
    })
    .then(returnData)
    .catch(catchError)

const triggerScheduledIntegration = ({ ovt, id }) => {
  return axios
    .get(
      `${apiConfig.gtUrl}Company/TriggerSceduledIntegration?ovt=${ovt}&hangfireJobId=${id}`,
      {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.getTokens().access_token}`,
          refresh_token: `${token.getTokens().refresh_token}`,
          'x-finazilla.request.client.user.guid': token.getProfileId(),
        },
      }
    )
    .then(returnData)
    .catch(catchError)
}

const uploadOperativeFile = ({
  uploadedFile,
  ovt,
  dataGroup,
  overrideSourceSystem,
}) =>
  axios
    .post(
      `${apiConfig.gtUrl}Company/IntegrateOperative?ovt=${ovt}&dataGroup=${dataGroup}&overrideSourceSystemId=${overrideSourceSystem.id}`,
      makeFormData(uploadedFile),
      {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.getTokens().access_token}`,
          refresh_token: `${token.getTokens().refresh_token}`,
          'x-finazilla.request.client.user.guid': token.getProfileId(),
        },
      }
    )
    .then(returnData)
    .catch(catchError)
const uploadSubBudgetFile = ({
  uploadedFile,
  ovt,
  overrideSourceSystem,
  budgetId,
  personnelExpenseAccountNumber,
  startMonth,
  startYear,
  endMonth,
  endYear,
  holidaySalaryCalculateConstantSymbol,
  filePassword,
}) => {
  let formData = makeFormData(uploadedFile)
  formData.append('budgetId', budgetId)
  formData.append(
    'personnelExpenseAccountNumber',
    personnelExpenseAccountNumber
  )
  formData.append('startMonth', startMonth)
  formData.append('startYear', startYear)
  formData.append('endMonth', endMonth)
  formData.append('endYear', endYear)
  formData.append(
    'holidaySalaryCalculateConstantSymbol',
    holidaySalaryCalculateConstantSymbol
  )
  formData.append('filePassword', filePassword)
  return axios
    .post(
      `${apiConfig.gtUrl}Company/GenerateSubBudgets?ovt=${ovt}&overrideSourceSystemId=${overrideSourceSystem.id}`,
      formData,
      {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.getTokens().access_token}`,
          refresh_token: `${token.getTokens().refresh_token}`,
          'x-finazilla.request.client.user.guid': token.getProfileId(),
        },
      }
    )
    .then(returnData)
    .catch(catchError)
}
const uploadVoucherFile = ({ uploadedFile, ovt, overrideSourceSystem = {} }) =>
  axios
    .post(
      `${apiConfig.gtUrl}Company/IntegrateVouchers?ovt=${ovt}&overrideSourceSystemId=${overrideSourceSystem.id}`,
      makeFormData(uploadedFile),
      {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.getTokens().access_token}`,
          refresh_token: `${token.getTokens().refresh_token}`,
          'x-finazilla.request.client.user.guid': token.getProfileId(),
        },
      }
    )
    .then(returnData)
    .catch(catchError)

const getIntegrationLogGroups = ({ companyCode }) =>
  axios
    .get(`${companyCode}/Integrationlog/Groups?PageSize=100`, config())
    .then(returnData)
    .catch(catchError)

const deleteIntegrationConnection = ({ companyCode, integrationCode }) =>
  axios
    .delete(`${companyCode}/integration/${integrationCode}`, config())
    .then(returnData)
    .catch(catchError)

const putIntegrationConnection = ({
  companyCode,
  integrationCode,
  connection,
}) =>
  axios
    .put(`${companyCode}/integration/${integrationCode}`, connection, config())
    .then(returnData)
    .catch(catchError)

const integrationApi = {
  getIntegrationSourceSystems,
  getIntegrationLogs,
  getIntegrationLogGroups,
  executeCompanyIntegration,
  getIntegrationEvents,
  getScheduledIntegrations,
  uploadOperativeFile,
  uploadSubBudgetFile,
  uploadVoucherFile,
  createIntegrationSourceSystem,
  deleteIntegrationSourceSystem,
  patchIntegrationSourceSystem,
  getIntegrationConnections,
  deleteIntegrationConnection,
  putIntegrationConnection,
  triggerScheduledIntegration,
  executeSingleIntegration,
}

export default integrationApi
