/*
 *
 * Customer actions
 *
 */

import {
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
} from './constants'

export const updateCustomer = ({
  customerCode,
  customer,
  updatedCustomer,
}) => ({ type: UPDATE_CUSTOMER, customerCode, customer, updatedCustomer })
export const updateCustomerSuccess = ({ customer }) => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  customer,
})
export const updateCustomerError = (error) => ({
  type: UPDATE_CUSTOMER_ERROR,
  error,
})
