/*
 *
 * CalculationConstants actions
 *
 */

import {
  CREATE_CALCULATION_CONSTANT,
  CREATE_CALCULATION_CONSTANT_ERROR,
  CREATE_CALCULATION_CONSTANT_SUCCESS,
  DELETE_CALCULATION_CONSTANT,
  DELETE_CALCULATION_CONSTANT_ERROR,
  DELETE_CALCULATION_CONSTANT_SUCCESS,
  EDIT_CALCULATION_CONSTANT,
  GET_CALCULATION_CONSTANTS,
  GET_CALCULATION_CONSTANTS_SUCCESS,
  GET_CALCULATION_CONSTANTS_ERROR,
  TOGGLE_SHOW_CREATE_CALCULATION_CONSTANTS,
  UPDATE_CALCULATION_CONSTANT,
  UPDATE_CALCULATION_CONSTANT_ERROR,
  UPDATE_CALCULATION_CONSTANT_SUCCESS,
} from './constants'

export const createCalculationConstant = ({
  budgetId,
  companyCode,
  customerCode,
  name,
  symbol,
  unit,
}) => ({
  type: CREATE_CALCULATION_CONSTANT,
  budgetId,
  companyCode,
  customerCode,
  name,
  symbol,
  unit,
})
export const createCalculationConstantError = (error) => ({
  type: CREATE_CALCULATION_CONSTANT_ERROR,
  error,
})
export const createCalculationConstantSuccess = ({ calculationConstant }) => ({
  type: CREATE_CALCULATION_CONSTANT_SUCCESS,
  calculationConstant,
})

export const deleteCalculationConstant = ({
  budgetId,
  companyCode,
  customerCode,
  calculationConstant,
}) => ({
  type: DELETE_CALCULATION_CONSTANT,
  budgetId,
  companyCode,
  customerCode,
  calculationConstant,
})
export const deleteCalculationConstantError = (error) => ({
  type: DELETE_CALCULATION_CONSTANT_ERROR,
  error,
})
export const deleteCalculationConstantSuccess = ({ id }) => ({
  type: DELETE_CALCULATION_CONSTANT_SUCCESS,
  id,
})

export const editCalculationConstant = ({ calculationConstantId }) => ({
  type: EDIT_CALCULATION_CONSTANT,
  calculationConstantId,
})

export const getCalculationConstants = ({
  budgetId,
  companyCode,
  customerCode,
}) => ({
  type: GET_CALCULATION_CONSTANTS,
  budgetId,
  companyCode,
  customerCode,
})
export const getCalculationConstantsSuccess = ({ calculationConstants }) => ({
  type: GET_CALCULATION_CONSTANTS_SUCCESS,
  calculationConstants,
})
export const getCalculationConstantsError = (error) => ({
  type: GET_CALCULATION_CONSTANTS_ERROR,
  error,
})

export const toggleShowCreateCalculationConstantForm = () => ({
  type: TOGGLE_SHOW_CREATE_CALCULATION_CONSTANTS,
})

export const updateCalculationConstant = ({
  budgetId,
  companyCode,
  customerCode,
  calculationConstantId,
  updatedFields,
}) => ({
  type: UPDATE_CALCULATION_CONSTANT,
  budgetId,
  companyCode,
  customerCode,
  calculationConstantId,
  updatedFields,
})
export const updateCalculationConstantError = (error) => ({
  type: UPDATE_CALCULATION_CONSTANT_ERROR,
  error,
})
export const updateCalculationConstantSuccess = ({ calculationConstant }) => ({
  type: UPDATE_CALCULATION_CONSTANT_SUCCESS,
  calculationConstant,
})
