import axios from 'axios'
import { returnData, config, catchError } from './api-utils'
import accountApi from './AccountApi'

// ##### init #####
const getInit = (companyCode) =>
  axios
    .all([
      getRollingRules(companyCode),
      accountApi.getAccounts({ companyCode }),
    ])
    .then(axios.spread((rollings, accounts) => ({ rollings, accounts })))
    .catch(catchError)

const getRollingRules = (companyCode) =>
  axios
    .get(`${companyCode}/rollingruletemplates`, config())
    .then(returnData)
    .catch(catchError)

const addRollingRule = (companyCode, rule) =>
  axios
    .post(`${companyCode}/rollingruletemplates`, rule, config())
    .then(returnData)
    .catch(catchError)

const deleteRollingRule = (ruleId, companyCode) =>
  axios
    .delete(`${companyCode}/rollingruletemplates/${ruleId}`, config())
    .then(returnData)
    .catch(catchError)

const patchRollingRule = (companyCode, diff, ruleId) =>
  axios
    .patch(`${companyCode}/rollingruletemplates/${ruleId}`, diff, config())
    .then(returnData)
    .catch(catchError)

const getRollingRuleTree = (companyCode, dimensionId) =>
  axios
    .get(`${companyCode}/rollingruletemplates/${dimensionId}/tree`, config())
    .then(returnData)
    .catch(catchError)

const getRollingRuleData = (companyCode, dimensionId) =>
  axios
    .get(`${companyCode}/rollingruletemplates/${dimensionId}/data`, config())
    .then(returnData)
    .catch(catchError)

const patchRollingRuleSchemeData = (companyCode, patch, ruleId) =>
  axios
    .patch(
      `${companyCode}/rollingruletemplates/${ruleId}/data`,
      patch,
      config()
    )
    .then(returnData)
    .catch(catchError)

const rollingRuleApi = {
  getInit,
  patchRollingRule,
  getRollingRules,
  addRollingRule,
  deleteRollingRule,
  getRollingRuleTree,
  getRollingRuleData,
  patchRollingRuleSchemeData,
}

export default rollingRuleApi
