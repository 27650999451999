import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const createFASAcquisitionVouchers = ({ companyCode, ...form }) =>
  axios
    .post(`${companyCode}/acquisition/fas`, form, config())
    .then(returnData)
    .catch(catchError)

const createEditedFASAcquisitionVouchers = ({ companyCode, ...form }) =>
  axios
    .post(`${companyCode}/acquisition/fas/editedVouchers`, form, config())
    .then(returnData)
    .catch(catchError)

const getAcquisitionForms = ({ companyCode, generatorId }) =>
  axios
    .get(`${companyCode}/acquisition/list/${generatorId}`, config())
    .then(returnData)
    .catch(catchError)

const getAllCreatedAcquisitionForms = ({ companyCode }) =>
  axios
    .get(`${companyCode}/acquisition/list/all`, config())
    .then(returnData)
    .catch(catchError)

const deleteAcquisition = ({ companyCode, id }) =>
  axios
    .delete(`${companyCode}/acquisition/${id}`, config())
    .then(returnData)
    .catch(catchError)

const acquisitionApi = {
  createFASAcquisitionVouchers,
  createEditedFASAcquisitionVouchers,
  getAcquisitionForms,
  getAllCreatedAcquisitionForms,
  deleteAcquisition,
}

export default acquisitionApi
