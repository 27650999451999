/*
 *
 * SubBudgetTags constants
 *
 */

export const GET_BUDGET_TAGS = 'app/SubBudgetTags/GET_BUDGET_TAGS'
export const GET_BUDGET_TAGS_ERROR = 'app/SubBudgetTags/GET_BUDGET_TAGS_ERROR'
export const GET_BUDGET_TAGS_SUCCESS =
  'app/SubBudgetTags/GET_BUDGET_TAGS_SUCCESS'

export const EDIT_BUDGET_TAG = 'app/SubBudgetTags/EDIT_BUDGET_TAG'

export const REMOVE_BUDGET_TAG = 'app/SubBudgetTags/REMOVE_BUDGET_TAG'
export const REMOVE_BUDGET_TAG_ERROR =
  'app/SubBudgetTags/REMOVE_BUDGET_TAG_ERROR'
export const REMOVE_BUDGET_TAG_SUCCESS =
  'app/SubBudgetTags/REMOVE_BUDGET_TAG_SUCCESS'

export const CREATE_BUDGET_TAG = 'app/SubBudgetTags/CREATE_BUDGET_TAG'
export const CREATE_BUDGET_TAG_ERROR =
  'app/SubBudgetTags/CREATE_BUDGET_TAG_ERROR'
export const CREATE_BUDGET_TAG_SUCCESS =
  'app/SubBudgetTags/CREATE_BUDGET_TAG_SUCCESS'

export const UPDATE_BUDGET_TAG = 'app/SubBudgetTags/UPDATE_BUDGET_TAG'
export const UPDATE_BUDGET_TAG_ERROR =
  'app/SubBudgetTags/UPDATE_BUDGET_TAG_ERROR'
export const UPDATE_BUDGET_TAG_SUCCESS =
  'app/SubBudgetTags/UPDATE_BUDGET_TAG_SUCCESS'

export const TOGGLE_SHOW_CREATE_TAG_FORM =
  'app/SubBudgetTags/TOGGLE_SHOW_CREATE_TAG_FORM'
