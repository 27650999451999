// import { take, call, put, select } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects'

import { GET_DATASOURCES } from './constants'

export function* getDatasources(action) {}

export function* datasourceSaga() {
  yield takeLatest(GET_DATASOURCES, getDatasources)
}

// All sagas to be loaded
export default datasourceSaga
