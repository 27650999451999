import {
  call,
  put,
  race,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import voucherApi from 'api/VoucherApi'
import operativeDataApi from 'api/OperativeDataApi'
import reportApi from 'api/ReportApi'

import {
  getVouchersError,
  getVouchersSuccess,
  getOperativeItemsSuccess,
  getOperativeItemsError,
  deleteReportBalanceSuccess,
  getReportBalancesSuccess,
  getReportBalancesListSuccess,
  createReportBalanceSuccess,
  setReportBalancesError,
  deleteReportBalanceError,
  deleteAllReportBalancesSuccess,
  deleteAllReportBalancesError,
  updateReportBalanceSuccess,
  updateReportBalanceError,
} from './actions'
import {
  GET_VOUCHERS,
  GET_VOUCHERS_CANCEL,
  GET_OPERATIVE_ITEMS,
  DELETE_REPORT_BALANCE,
  GET_REPORT_BALANCES,
  GET_REPORT_BALANCES_LIST,
  CREATE_REPORT_BALANCE,
  DELETE_ALL_REPORT_BALANCES,
  UPDATE_REPORT_BALANCE,
} from './constants'
import trackEvent from 'utils/trackEvent'

export function* getVouchers(action) {
  try {
    const { cancel, vouchers } = yield race({
      cancel: take(GET_VOUCHERS_CANCEL),
      vouchers: call(voucherApi.getVouchers, action),
    })

    if (cancel === undefined) {
      yield put(getVouchersSuccess({ params: action, vouchers }))
    }
  } catch (error) {
    yield put(handleError(error, getVouchersError))
  }
}

export function* getOperativeItems(action) {
  try {
    if (action.operativeItemIds.length === 0) {
      yield put(getOperativeItemsSuccess({ operativeItems: [] }))
      return
    }
    const operativeItems = yield call(
      operativeDataApi.getOperativeItems,
      action
    )
    yield put(getOperativeItemsSuccess({ operativeItems }))
  } catch (error) {
    yield put(handleError(error, getOperativeItemsError))
  }
}

export function* getReportBalances(action) {
  try {
    const reportBalances = yield call(reportApi.getReportBalances, action)
    yield put(getReportBalancesSuccess({ reportBalances }))
  } catch (error) {
    yield put(handleError(error, setReportBalancesError))
  }
}

export function* deleteReportBalance(action) {
  const realBalanceId = action.realBalanceId
  try {
    yield call(reportApi.deleteReportBalance, action)
    yield put(deleteReportBalanceSuccess({ realBalanceId }))
  } catch (error) {
    yield put(handleError(error, deleteReportBalanceError))
  }
}

export function* getReportBalancesList(action) {
  try {
    if (action.reportBalanceIds.length === 0) {
      yield put(getReportBalancesListSuccess({ reportBalances: [] }))
      return
    }
    const reportBalances = yield call(reportApi.getReportBalancesList, action)
    yield put(getReportBalancesListSuccess({ reportBalances }))
  } catch (error) {
    yield put(handleError(error, getVouchersError))
  }
}

export function* createReportBalance(action) {
  try {
    trackEvent('report', 'Create report balance')
    const returnItems = []
    for (const item of action.items) {
      var returnValue = yield call(reportApi.createReportBalance, item)
      returnItems.push(returnValue)
    }
    yield put(createReportBalanceSuccess(returnItems))
  } catch (error) {
    yield put(handleError(error, setReportBalancesError))
  }
}

export function* deleteAllReportBalances(action) {
  try {
    yield call(reportApi.deleteAllReportBalances, action)
    yield put(deleteAllReportBalancesSuccess())
  } catch (error) {
    yield put(handleError(error, deleteAllReportBalancesError))
  }
}

export function* updateReportBalance(action) {
  try {
    const reportBalance = yield call(reportApi.updateReportBalance, action)
    yield put(updateReportBalanceSuccess({ reportBalance }))
  } catch (error) {
    yield put(handleError(error, updateReportBalanceError))
  }
}

// All sagas to be loaded
export function* reportSaga() {
  yield takeLatest(GET_VOUCHERS, getVouchers)
  yield takeLatest(GET_REPORT_BALANCES, getReportBalances)
  yield takeLatest(DELETE_REPORT_BALANCE, deleteReportBalance)
  yield takeLatest(GET_REPORT_BALANCES_LIST, getReportBalancesList)
  yield takeLatest(GET_OPERATIVE_ITEMS, getOperativeItems)
  yield takeEvery(CREATE_REPORT_BALANCE, createReportBalance)
  yield takeLatest(DELETE_ALL_REPORT_BALANCES, deleteAllReportBalances)
  yield takeEvery(UPDATE_REPORT_BALANCE, updateReportBalance)
}

export default reportSaga
