import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import accountSchemeTemplateApi from 'api/AccountSchemeTemplateApi'

import {
  createAccountSchemeTemplateError,
  createAccountSchemeTemplateSuccess,
  deleteAccountSchemeTemplateError,
  deleteAccountSchemeTemplateSuccess,
  getAccountSchemeTemplatesError,
  getAccountSchemeTemplatesSuccess,
  updateAccountSchemeTemplateError,
  updateAccountSchemeTemplateSuccess,
  copyAccountSchemeTemplateError,
  copyAccountSchemeTemplateSuccess,
  getAccountSchemeTemplates as getAccountSchemeTemplatesAction,
} from './actions'

import {
  CREATE_ACCOUNT_SCHEME_TEMPLATE,
  DELETE_ACCOUNT_SCHEME_TEMPLATE,
  GET_ACCOUNT_SCHEME_TEMPLATES,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE,
  COPY_ACCOUNT_SCHEME_TEMPLATE,
} from './constants'

// Individual exports for testing
export function* createAccountSchemeTemplate(action) {
  const { companyCode, customerCode, name } = action

  try {
    const accountSchemeTemplate = yield call(
      accountSchemeTemplateApi.createAccountSchemeTemplate,
      { companyCode, customerCode, name }
    )

    yield put(createAccountSchemeTemplateSuccess({ accountSchemeTemplate }))
  } catch (error) {
    yield put(handleError(error, createAccountSchemeTemplateError))
  }
}

export function* copyAccountSchemeTemplate(action) {
  try {
    const {
      customerCode,
      companyCode,
      accountSchemeTemplateId,
      newName,
    } = action
    const copy = yield call(
      accountSchemeTemplateApi.copyAccountSchemeTemplate,
      {
        companyCode,
        customerCode,
        accountSchemeTemplateId,
        newName,
      }
    )
    yield put(copyAccountSchemeTemplateSuccess({ accountSchemeTemplate: copy }))
    yield put(getAccountSchemeTemplatesAction({ customerCode, companyCode }))
  } catch (error) {
    yield put(handleError(error, copyAccountSchemeTemplateError))
  }
}

export function* deleteAccountSchemeTemplate(action) {
  const { companyCode, customerCode, id } = action

  try {
    yield call(accountSchemeTemplateApi.deleteAccountSchemeTemplate, {
      companyCode,
      customerCode,
      id,
    })
    yield put(deleteAccountSchemeTemplateSuccess({ id }))
  } catch (error) {
    yield put(handleError(error, deleteAccountSchemeTemplateError))
  }
}

export function* getAccountSchemeTemplates(action) {
  const { companyCode, customerCode } = action

  try {
    const accountSchemeTemplates = yield call(
      accountSchemeTemplateApi.getAccountSchemeTemplates,
      { companyCode, customerCode }
    )
    yield put(getAccountSchemeTemplatesSuccess({ accountSchemeTemplates }))
  } catch (error) {
    yield put(handleError(error, getAccountSchemeTemplatesError))
  }
}

export function* updateAccountSchemeTemplate(action) {
  const { companyCode, customerCode, id, name } = action
  const patch = [
    {
      op: 'replace',
      path: '/name',
      value: name,
    },
  ]

  try {
    const accountSchemeTemplate = yield call(
      accountSchemeTemplateApi.patchAccountSchemeTemplate,
      { companyCode, customerCode, id, patch }
    )
    yield put(updateAccountSchemeTemplateSuccess({ accountSchemeTemplate }))
  } catch (error) {
    yield put(handleError(error, updateAccountSchemeTemplateError))
  }
}

// All sagas to be loaded
export function* accountSchemeTemplatesSaga() {
  yield all([
    takeLatest(CREATE_ACCOUNT_SCHEME_TEMPLATE, createAccountSchemeTemplate),
    takeEvery(DELETE_ACCOUNT_SCHEME_TEMPLATE, deleteAccountSchemeTemplate),
    takeLatest(COPY_ACCOUNT_SCHEME_TEMPLATE, copyAccountSchemeTemplate),
    takeLatest(GET_ACCOUNT_SCHEME_TEMPLATES, getAccountSchemeTemplates),
    takeLatest(UPDATE_ACCOUNT_SCHEME_TEMPLATE, updateAccountSchemeTemplate),
  ])
}

export default accountSchemeTemplatesSaga
