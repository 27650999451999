/*
 *
 * Dimensions constants
 *
 */

export const GET_DIMENSIONS = 'app/Dimensions/GET_DIMENSIONS'
export const GET_DIMENSIONS_SUCCESS = 'app/Dimensions/GET_DIMENSIONS_SUCCESS'
export const GET_DIMENSIONS_ERROR = 'app/Dimensions/GET_DIMENSIONS_ERROR'

export const GET_DIMENSIONS_SEARCH_TERM =
  'app/Dimensions/GET_DIMENSIONS_SEARCH_TERM'
export const GET_DIMENSIONS_SEARCH = 'app/Dimensions/GET_DIMENSIONS_SEARCH'
export const GET_DIMENSIONS_SEARCH_SUCCESS =
  'app/Dimensions/GET_DIMENSIONS_SEARCH_SUCCESS'

export const GET_BY_DIMENSIONVALUE = 'app/Dimensions/GET_BY_DIMENSIONVALUE'
export const GET_BY_DIMENSIONVALUE_SUCCESS =
  'app/Dimensions/GET_BY_DIMENSIONVALUE_SUCCESS'

export const CREATE_DIMENSION = 'app/Dimensions/CREATE_DIMENSION'
export const CREATE_DIMENSION_SUCCESS =
  'app/Dimensions/CREATE_DIMENSION_SUCCESS'
export const CREATE_DIMENSION_ERROR = 'app/Dimensions/CREATE_DIMENSION_ERROR'

export const DELETE_DIMENSION = 'app/Dimensions/DELETE_DIMENSION'
export const DELETE_DIMENSION_SUCCESS =
  'app/Dimensions/DELETE_DIMENSION_SUCCESS'
export const DELETE_DIMENSION_ERROR = 'app/Dimensions/DELETE_DIMENSION_ERROR'

export const BULK_DELETE_DIMENSION = 'app/Dimensions/BULk_DELETE_DIMENSION'
export const BULK_DELETE_DIMENSION_ERROR =
  'app/Dimensions/BULK_DELETE_DIMENSION_ERROR'
export const BULK_DELETE_DIMENSION_SUCCESS =
  'app/Dimensions/BULK_DELETE_DIMENSION_SUCCESS'

export const DISMISS_BULK_DELETE_ERROR =
  'app/Dimensions/DISMISS_BULK_DELETE_ERROR'
