import { Record } from 'immutable'

const RecurringPaymentPaymentEventRecord = Record({
  dueDate: null,
  amountTaxIncluded: 0.0,
  paymentType: '',
  recurringPaymentId: 0,
  nthOccurence: 0,
  isRemoved: false,
  recurringPaymentOccurenceId: null,
  description: '',
})

export default RecurringPaymentPaymentEventRecord
