import { Record } from 'immutable'

const BudgetBalanceSchemeNode = Record({
  type: '',
  rowType: '',
  account: undefined,
  dimensionTarget: undefined,
  accountGroupType: undefined,
  subBudget: undefined,
  rowId: '',
  path: '',
  name: '',
  children: undefined,
  editable: false,
  rowFactor: 1,
})

export default BudgetBalanceSchemeNode
