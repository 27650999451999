import { selectClaims } from 'containers/App/selectors'
import { useSelector } from 'react-redux'
import { HasClaim } from 'utils/Authorization'

export default function useHasClaim({
  claim,
  allowAll,
  value,
  companyCode,
  customerCode,
}) {
  const permissions = useSelector((state) =>
    selectClaims(state, { params: { companyCode, customerCode } })
  )

  if (!permissions) return false

  return HasClaim({
    claim,
    value,
    allowAll,
    permissions,
  })
}

export function useIsSystemUser() {
  return useHasClaim({
    claim: 'System',
    allowAll: false,
  })
}
