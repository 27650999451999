import { Record, List } from 'immutable'

const DashboardRecord = Record({
  companyIds: List(),
  description: '',
  id: '', // id or uuid
  integrationSourceSystemIds: List(),
  isCustomerDashboard: false,
  isOwner: false,
  isSystemDashboard: false,
  name: '',
})

export default DashboardRecord
