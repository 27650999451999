import { List } from 'immutable'
import { isEmpty } from 'lodash'

const SUBTOTAL = 'Subtotal'
const SCHEMEGROUP_ROOT = '/SchemeGroup'

// tree must be record
export const transformTree = ({ node, transform }) => {
  if (!node) return node
  const self = transform(node)
  return self.update('children', (children) =>
    children.map((child) => transformTree({ node: child, transform }))
  )
}

// Given a scheme `row`, it finds the last order number of its children.
// If the `row` has no children, -1 is returned.
export const findLastOrderNumber = (row) =>
  row.children
    .map((child) => child.order)
    .reduce((orderA, orderB) => Math.max(orderA, orderB), -1)

// Finds the parent of `row` by traversing the `tree`
export const findParent = ({ tree, row }) =>
  flattenChildren(tree).find((r) => r.id === row.parentId)

// Traverses `tree` and finds the row with `rowId`.
// `tree` can be JS object.
export const traverseAndFind = ({ tree, rowId }) => {
  if (tree.rowId === rowId) {
    return tree
  }

  const child = tree.children.find((row) => row.rowId === rowId)

  if (child) {
    return child
  }

  return tree.children
    .map((row) => traverseAndFind({ tree: row, rowId }))
    .find((descendant) => Boolean(descendant))
}

// Flattens the `schemeGroup`. The input can be a subtree also.
export const flattenChildren = (schemeGroup) => {
  if (isEmpty(schemeGroup)) return List()
  if (!schemeGroup.children) return List([schemeGroup])
  const childArrayIsNotList =
    !schemeGroup.children.isList || !schemeGroup.children.isList()
  const ensuredList = childArrayIsNotList
    ? List(schemeGroup.children)
    : schemeGroup.children
  const flattenedChildren = ensuredList.flatMap(flattenChildren)

  return List([schemeGroup]).concat(flattenedChildren)
}

export const flattenAndRemoveChildrenByIds = (
  schemeGroup,
  accountSchemeRowIds,
  accountIds
) => {
  if (
    schemeGroup.children.size > 0 &&
    schemeGroup.children.every((child) =>
      accountIds.includes(child.account?.id)
    )
  ) {
    return
  }

  if (isEmpty(schemeGroup)) return List()
  if (!schemeGroup.children) return List([schemeGroup])
  const children = schemeGroup.children.filter(
    (child) =>
      !accountSchemeRowIds.includes(child.accountSchemeRowId) &&
      child.accountGroupType !== SUBTOTAL
  )
  schemeGroup = schemeGroup.set('children', children)
  const childArrayIsNotList =
    !schemeGroup.children.isList || !schemeGroup.children.isList()
  const ensuredList = childArrayIsNotList
    ? List(schemeGroup.children)
    : schemeGroup.children
  const flattenedChildren = ensuredList.flatMap((child) =>
    flattenAndRemoveChildrenByIds(child, accountSchemeRowIds, accountIds)
  )
  if (schemeGroup.path === SCHEMEGROUP_ROOT) {
    return List().concat(flattenedChildren)
  }
  return List([schemeGroup]).concat(flattenedChildren)
}

export const fixLeadingSlash = (path) => {
  if (path.startsWith('/')) return path
  return `/${path}`
}
