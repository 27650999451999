/*
 *
 * IntegrationLogs actions
 *
 */

import {
  GET_INTEGRATION_LOGS,
  GET_INTEGRATION_LOGS_ERROR,
  GET_INTEGRATION_LOGS_SUCCESS,
} from './constants'

export const getIntegrationLogs = ({
  companyCode,
  start,
  end,
  page,
  pageSize,
  itemType,
}) => ({
  type: GET_INTEGRATION_LOGS,
  companyCode,
  start,
  end,
  page,
  pageSize,
  itemType,
})
export const getIntegrationLogsSuccess = ({ logs, page, pageSize }) => ({
  type: GET_INTEGRATION_LOGS_SUCCESS,
  logs,
  page,
  pageSize,
})
export const getIntegrationLogsError = (error) => ({
  type: GET_INTEGRATION_LOGS_ERROR,
  error,
})
