/*
  This is replaces the SelectList component from react-widgets which was dropped from the latest release.
**/
import { useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-formal'
import styles from './styles.module.css'

const propTypes = {
  data: PropTypes.array.isRequired,
  itemComponent: PropTypes.func,
  textField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  vertical: PropTypes.bool,
}

function SelectList(props) {
  const {
    data,
    disabled,
    multiple,
    textField,
    type,
    valueField,
    vertical,
    ...rest
  } = props

  const [hasFocus, setHasFocus] = useState(false)

  return (
    <div
      className={styles.container + (hasFocus ? ` ${styles.focus}` : '')}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      role="radiogroup"
    >
      <ul
        className={styles.list + (vertical ? ` ${styles.vertical}` : '')}
        role="radiogroup"
        tabIndex="0"
      >
        {data.map((item, index) => {
          const isDisabled = Array.isArray(disabled)
            ? disabled.includes(item)
            : Boolean(disabled)

          return (
            <li
              className={
                styles.item + (isDisabled ? ` ${styles.disabled}` : '')
              }
              key={`${props?.name}-${index}`}
            >
              <label>
                <Form.Field
                  {...rest}
                  type={type === 'checkbox' || multiple ? 'checkbox' : 'radio'}
                  disabled={isDisabled}
                  value={valueField ? item[valueField] : item}
                />
                {!textField
                  ? item
                  : typeof textField === 'function'
                  ? textField(item)
                  : textField}
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

SelectList.propTypes = propTypes

export default SelectList
