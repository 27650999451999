export const CHOSE_PROFILE = 'app/App/CHOSE_PROFILE'
export const CHOOSE_PROFILE = 'app/App/CHOOSE_PROFILE'

export const AAD_LOGIN_ERROR = 'app/App/AAD_LOGIN_ERROR'

export const FINVOICER_ID_TOKEN_FLOW = 'app/App/FINVOICER_ID_TOKEN_FLOW'

export const PASSWORD_LOGIN = 'app/App/PASSWORD_LOGIN'
export const PASSWORD_LOGIN_ERROR = 'app/App/PASSWORD_LOGIN_ERROR'

export const LOGIN_SUCCESS = 'app/App/LOGIN_SUCCESS'

export const LOGOUT = 'app/App/LOGOUT'
export const LOGOUT_SUCCESS = 'app/App/LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'app/App/LOGOUT_ERROR'

export const CHECK_LOGIN = 'app/App/CHECK_LOGIN'

export const GET_LOGGED_IN_USER_INFO = 'app/App/GET_LOGGED_IN_USER_INFO'
export const GET_LOGGED_IN_USER_INFO_ERROR =
  'app/App/GET_LOGGED_IN_USER_INFO_ERROR'
export const GET_PERMISSIONS_SUCCESS = 'app/App/GET_PERMISSIONS_SUCCESS'

export const GET_SUB_USERS_ERROR = 'app/App/GET_SUB_USERS_ERROR'
export const GET_SUB_USERS_SUCCESS = 'app/App/GET_SUB_USERS_SUCCESS'

export const GET_LOGIN_METHOD = 'app/App/GET_LOGIN_METHOD'

export const CHANGE_LOCALE = 'app/App/CHANGE_LOCALE'

export const START_USER_ACCOUNT_CONVERSION =
  'app/App/START_USER_ACCOUNT_CONVERSION'

export const CANCEL_ACCOUNT_CONVERSION = 'app/App/CANCEL_ACCOUNT_CONVERSION'

export const SEND_INVITE = 'app/App/SEND_INVITE'
export const INVITE_ERROR = 'app/App/INVITE_ERROR'

export const REMOVE_AAD_PROFILE = 'app/APP/REMOVE_AAD_PROFILE'

export const CHECK_LOGIN_STATE = 'app/App/LOGIN_STATE/CHECK_LOGIN'
export const GET_LOGGED_IN_USER_INFO_STATE =
  'app/App/LOGIN_STATE/GET_LOGGED_IN_USER_INFO'

export const SELECT_LOGIN_METHOD_STATE =
  'app/App/LOGIN_STATE/SELECT_LOGIN_METHOD'
export const CHOOSE_PROFILE_STATE = 'app/App/LOGIN_STATE/CHOOSE_PROFILE'
export const ACCOUNT_CONVERSION_STATE = 'app/App/LOGIN_STATE/ACCOUNT_CONVERSION'

export const LOGGED_IN_STATE = 'app/App/LOGIN_STATE/LOGGED_IN'

export const INITIALIZE_LEMONSOFT_USER = 'app/App/INITIALIZE_LEMONSOFT_USER'
export const INITIALIZE_LEMONSOFT_USER_SUCCESS =
  'app/App/INITIALIZE_LEMONSOFT_USER_SUCCESS'
export const INITIALIZE_LEMONSOFT_USER_ERROR =
  'app/App/INITIALIZE_LEMONSOFT_USER_ERROR'

export const INITIALIZE_LEMONSOFT_USER_PROGRESS =
  'app/App/INITIALIZE_LEMONSOFT_USER_PROGRESS'

export const SELECT_LEMONSOFT_ORGANIZATION =
  'app/App/SELECT_LEMONSOFT_ORGANIZATION'

export const LOGIN_FLOW_STATES = [
  CHECK_LOGIN_STATE, // Initial state to check if we have existing login (or can get one by refreshing)

  SELECT_LOGIN_METHOD_STATE, // We didn't have initial login so user needs to choose a login method

  CHOOSE_PROFILE_STATE,
  ACCOUNT_CONVERSION_STATE,

  GET_LOGGED_IN_USER_INFO_STATE,

  SELECT_LEMONSOFT_ORGANIZATION, // While initializing lemonsoft user we found multiple organizations

  LOGGED_IN_STATE,
]
