/*
 *
 * SubBudget reducer
 *
 */

import { fromJS, Map, List, Record, Set } from 'immutable'
import patch from 'immpatch'
import { isArray } from 'lodash'

import { SchemeGroupRecord } from 'records'
import { getDimensionsArray } from 'containers/Budget/functions'
import { createBudgetData } from 'containers/Budget/reducer'
import {
  COPY_SUB_BUDGET_ROW_SUCCESS,
  CREATE_SUB_BUDGET_ROW_SUCCESS,
  UPDATE_SUB_BUDGET_ROW_SUCCESS,
} from 'containers/SubBudgetRowModal/constants'
import {
  UPDATE_BUDGET_TAG_SUCCESS,
  REMOVE_BUDGET_TAG_SUCCESS,
} from 'containers/SubBudgetTags/constants'
import { mapTag } from 'containers/SubBudgetTags/reducer'

import {
  GET_SUB_BUDGET,
  GET_SUB_BUDGET_ERROR,
  GET_SUB_BUDGET_SUCCESS,
  GET_SUB_BUDGET_DATA,
  GET_SUB_BUDGET_DATA_ERROR,
  GET_SUB_BUDGET_DATA_SUCCESS,
  GET_SUB_BUDGET_TREE,
  GET_SUB_BUDGET_TREE_ERROR,
  GET_SUB_BUDGET_TREE_SUCCESS,
  REMOVE_SUB_BUDGET_ROW,
  REMOVE_SUB_BUDGET_ROW_ERROR,
  REMOVE_SUB_BUDGET_ROW_SUCCESS,
  UPDATE_SUB_BUDGET_CELL,
  UPDATE_SUB_BUDGET_CELL_SUCCESS,
  UPDATE_SUB_BUDGET_CELL_ERROR,
  RESET_ALL_SUBBUDGETS,
  MOVE_SUB_BUDGET_ROW,
  MOVE_SUB_BUDGET_ROW_ERROR,
  MOVE_SUB_BUDGET_ROW_SUCCESS,
  SHOW_SUB_BUDGET_ROW_NOTES,
  GET_SUB_BUDGET_IMPORT_DATA,
  GET_SUB_BUDGET_IMPORT_DATA_ERROR,
  GET_SUB_BUDGET_IMPORT_DATA_SUCCESS,
  UPDATE_SUB_BUDGET_STATUS_ERROR,
  SHOW_SUB_BUDGET_IMPORT_MODAL,
  CLOSE_SUB_BUDGET_IMPORT_MODAL,
  CLEAR_SUB_BUDGET_DATA,
} from './constants'
import { DUPLICATE_SUB_BUDGET_ROW } from 'containers/SubBudgetRowModal/constants'

const mapSubBudgetSchemeGroup = (schemeGroup, level = 0) =>
  new SchemeGroupRecord({ ...schemeGroup, level })
    .set(
      'children',
      List(
        schemeGroup.children.map((child) =>
          mapSubBudgetSchemeGroup(child, level + 1)
        )
      )
    )
    .set('tags', List(schemeGroup.tags.map(mapTag)))

const updateTag = ({ tree, tag }) => {
  const children = tree.children.map((tree) => updateTag({ tree, tag }))

  return tree
    .set('children', children)
    .update('tags', (tags) =>
      tags.map((oldTag) => (oldTag.id === tag.id ? tag : oldTag))
    )
}

const removeTag = ({ tree, tagId }) => {
  const children = tree.children.map((tree) => removeTag({ tree, tagId }))

  return tree
    .set('children', children)
    .update('tagIds', (tagIds) => tagIds.filter((tId) => tId !== tagId))
    .update('tags', (tags) => tags.filter((tag) => tag.id !== tagId))
}

export const SubBudgetDataKeyRecord = Record({
  subBudgetId: undefined,
  dv: Set(),
})

// trees are mapped by subBudgetId,
// data are mapped by SubBudgetDataKeyRecords.
const initialState = fromJS({
  treeLoading: Map(), // loading state for each subBudgetId tree
  error: false,
  tree: Map(),
  data: Map(),
  importPreviewTree: undefined, //SchemeGroupRecord(),
  importPreviewData: undefined, //BudgetDataRecord(),
  previewDataLoading: false,
  rowNotes: {
    show: false,
    row: undefined,
  },
})

const subBudgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case DUPLICATE_SUB_BUDGET_ROW:
    case GET_SUB_BUDGET_TREE:
    case REMOVE_SUB_BUDGET_ROW:
    case GET_SUB_BUDGET_DATA:
    case GET_SUB_BUDGET:
    case MOVE_SUB_BUDGET_ROW:
      return state
        .setIn(['treeLoading', action.subBudgetId], true)
        .set('error', false)

    case GET_SUB_BUDGET_IMPORT_DATA:
      return state
        .set('previewDataLoading', true)
        .delete('importPreviewData')
        .set('importLoading', true)

    case UPDATE_SUB_BUDGET_STATUS_ERROR:
    case GET_SUB_BUDGET_TREE_ERROR:
    case REMOVE_SUB_BUDGET_ROW_ERROR:
    case GET_SUB_BUDGET_DATA_ERROR:
    case GET_SUB_BUDGET_ERROR:
    case GET_SUB_BUDGET_IMPORT_DATA_ERROR:
    case MOVE_SUB_BUDGET_ROW_ERROR:
    case UPDATE_SUB_BUDGET_CELL_ERROR:
      return state
        .setIn(['treeLoading', action.subBudgetId], false)
        .set('previewDataLoading', false)
        .set('importLoading', false)
        .set('error', action.error)

    case GET_SUB_BUDGET_DATA_SUCCESS: {
      const dv = Set(getDimensionsArray(action.dv))
      const subBudgetKey = new SubBudgetDataKeyRecord({
        subBudgetId: action.subBudgetId,
        dv,
      })
      if (!action.data) {
        return state.set('importLoading', false).set('showImportModal', false)
      }
      return state
        .setIn(['treeLoading', action.subBudgetId], false)
        .set('importLoading', false)
        .set('showImportModal', false)
        .setIn(['data', subBudgetKey], createBudgetData(action.data))
    }

    case GET_SUB_BUDGET_IMPORT_DATA_SUCCESS:
      return state
        .set('previewDataLoading', false)
        .set('importLoading', false)
        .set('importPreviewData', createBudgetData(action.data))

    case GET_SUB_BUDGET_TREE_SUCCESS:
      return state
        .setIn(['treeLoading', action.subBudgetId], false)
        .setIn(
          ['tree', action.subBudgetId],
          mapSubBudgetSchemeGroup(action.tree.schemeGroup)
        )
    case RESET_ALL_SUBBUDGETS:
      return state.set('tree', Map())

    case UPDATE_SUB_BUDGET_ROW_SUCCESS:
    case COPY_SUB_BUDGET_ROW_SUCCESS:
    case REMOVE_SUB_BUDGET_ROW_SUCCESS:
    case CREATE_SUB_BUDGET_ROW_SUCCESS:
    case GET_SUB_BUDGET_SUCCESS:
    case MOVE_SUB_BUDGET_ROW_SUCCESS: {
      const dv = Set(getDimensionsArray(action.dv))
      const subBudgetKey = new SubBudgetDataKeyRecord({
        subBudgetId: action.subBudgetId,
        dv,
      })
      return state
        .setIn(['treeLoading', action.subBudgetId], false)
        .setIn(
          ['tree', action.subBudgetId],
          mapSubBudgetSchemeGroup(action.tree.schemeGroup)
        )
        .setIn(['data', subBudgetKey], createBudgetData(action.data))
    }

    case UPDATE_SUB_BUDGET_CELL: {
      const { selection, value } = action

      const hasDifferentValueForEachCell = isArray(value)
      const cells = hasDifferentValueForEachCell ? value : selection
      return cells.reduce((working, cell) => {
        const valueToSet = hasDifferentValueForEachCell ? cell.value : value
        const dv = Set(getDimensionsArray(action.dv))
        const subBudgetKey = new SubBudgetDataKeyRecord({
          subBudgetId: action.subBudgetId,
          dv,
        })
        return working.setIn(
          ['data', subBudgetKey, 'rows', cell.row, cell.column, 'amount'],
          valueToSet
        )
      }, state)
    }

    case UPDATE_SUB_BUDGET_CELL_SUCCESS: {
      const dv = Set(getDimensionsArray(action.dv))
      const subBudgetKey = new SubBudgetDataKeyRecord({
        subBudgetId: action.subBudgetId,
        dv,
      })
      const data = state.getIn(['data', subBudgetKey])
      return state.setIn(['data', subBudgetKey], patch(data, action.dataPatch))
    }

    case UPDATE_BUDGET_TAG_SUCCESS:
      return state.update('tree', (treeMap) =>
        treeMap.map((tree) => updateTag({ tree, tag: mapTag(action.tag) }))
      )

    case REMOVE_BUDGET_TAG_SUCCESS:
      return state.update('tree', (treeMap) =>
        treeMap.map((tree) => removeTag({ tree, tagId: action.tagId }))
      )

    case SHOW_SUB_BUDGET_ROW_NOTES:
      return state
        .setIn(['rowNotes', 'row'], action.row)
        .setIn(['rowNotes', 'show'], action.show)

    case SHOW_SUB_BUDGET_IMPORT_MODAL:
      return state.set('showImportModal', true)
    case CLOSE_SUB_BUDGET_IMPORT_MODAL:
      return state.set('showImportModal', false)

    case CLEAR_SUB_BUDGET_DATA: {
      const dv = Set(getDimensionsArray(action.dv))
      const subBudgetKey = new SubBudgetDataKeyRecord({
        subBudgetId: action.subBudgetId,
        dv,
      })
      return state.setIn(['data', subBudgetKey], undefined)
    }

    default:
      return state
  }
}

export default subBudgetReducer
