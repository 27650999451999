import { defineMessages } from 'react-intl'

export default defineMessages({
  systemNavHeader: {
    id: 'components.nav.system.systemNavHeader',
    description: 'NavigationBar: System dropdown',
    defaultMessage: 'System',
  },
  dashboards: {
    id: 'components.nav.manage.dashboards',
    description: 'NavigationBar: Settings Menu > Dashboards',
    defaultMessage: 'Dashboards',
  },
  customers: {
    id: 'components.nav.manage.customers',
    description: 'NavigationBar: Settings Menu > Customers',
    defaultMessage: 'Customers',
  },
  users: {
    id: 'components.nav.manage.users',
    description: 'NavigationBar: Settings Menu > Users',
    defaultMessage: 'Users',
  },
  allUsers: {
    id: 'components.nav.manage.aadUsers',
    description: 'NavigationBar: Settings Menu > All Users',
    defaultMessage: 'All Users',
  },
  accountSchemeTemplates: {
    id: 'components.nav.manage.account-scheme-templates',
    description: 'NavigationBar: Settings Menu > Account Scheme Templates',
    defaultMessage: 'Account Scheme Templates',
  },
  reportSchemes: {
    id: 'components.nav.manage.reportSchemes',
    description: 'Navigation Bar: Settings Menu > Reports Schemes',
    defaultMessage: 'Report Schemes',
  },
  reportSchemeTemplates: {
    id: 'components.nav.manage.reportSchemeTemplates',
    description: 'Navigation Bar: Settings Menu > Reports Scheme Templates',
    defaultMessage: 'Report Scheme Templates',
  },
  systemReportSelections: {
    id: 'components.nav.manage.systemReportSelections',
    description: 'Navigation Bar: Settings Menu > Reports Selections',
    defaultMessage: 'Reports',
  },
  customerReportSelections: {
    id: 'components.nav.manage.customerReportSelections',
    description: 'Navigation Bar: Settings Menu > Reports Selections',
    defaultMessage: 'Reports',
  },
  calculationConstants: {
    id: 'components.nav.manage.calculation-constants',
    description: 'NavigationBar: Settings Menu > Calculation Constants',
    defaultMessage: 'Calculation Constants',
  },
  roles: {
    id: 'components.nav.manage.roles',
    description: 'NavigationBar: Settings Menu > Roles',
    defaultMessage: 'Roles',
  },
  gtHangfireLink: {
    id: 'components.nav.manage.gtHangfireLink',
    description: 'NavigationBar: Settings Menu > gtHangfireLink',
    defaultMessage: 'GT Scheduled jobs',
  },
  companies: {
    id: 'components.nav.manage.companies',
    description: 'NavigationBar: Settings Menu Item > Companies',
    defaultMessage: 'Companies',
  },
  customerNavHeader: {
    id: 'components.nav.system.customerNavHeader',
    description: 'NavigationBar: Settings Menu',
    defaultMessage: 'Customer',
  },
  settings: {
    id: 'components.nav.manage.settings',
    description: 'NavigationBar: Settings Menu Item > Settings',
    defaultMessage: 'Settings',
  },
  accountingPeriods: {
    id: 'app.components.SettingsDropdown.accountingPeriods',
    description: 'NavigationBar: Settings > Accounting Periods',
    defaultMessage: 'Accounting Periods',
  },
  accounts: {
    id: 'app.components.SettingsDropdown.accounts',
    description: 'Navigation Bar: Settings > Accounts',
    defaultMessage: 'Accounts',
  },
  accountSchemes: {
    id: 'app.components.SettingsDropdown.accountSchemes',
    description: 'NavigationBar: Settings > Account Schemes',
    defaultMessage: 'Account Schemes',
  },
  budgets: {
    id: 'app.components.SettingsDropdown.budgets',
    description: 'Navigation Bar: Settings > Budgets',
    defaultMessage: 'Budgets',
  },
  cashFlow: {
    id: 'app.components.SettingsDropdown.cashFlow',
    description: 'NavigationBar: Settings > Cash Flow',
    defaultMessage: 'Cash Flow',
  },
  companyNavHeader: {
    id: 'app.components.CompanyDropdown.CompanyNavHeader',
    description: 'NavigationBar: Company dropdown',
    defaultMessage: 'Company',
  },
  dimensions: {
    id: 'app.components.SettingsDropdown.dimensions',
    description: 'NavigationBar: Settings > Dimensions',
    defaultMessage: 'Dimensions',
  },
  integrationLogs: {
    id: 'components.nav.SettingsDropdown.integrationLogs',
    description: 'NavigationBar: Settings > Integration Logs',
    defaultMessage: 'Integration Logs',
  },
  reportingGroups: {
    id: 'app.components.SettingsDropdown.reportingGroups',
    description: 'NavigationBar: Settings > Reporting groups',
    defaultMessage: 'Reporting Groups',
  },
  rollingRules: {
    id: 'app.components.SettingsDropdown.rollingRules',
    description: 'NavigationBar: Settings > Rolling rule templates',
    defaultMessage: 'Rolling Rule Templates',
  },
  vouchers: {
    id: 'components.nav.SettingsDropdown.vouchers',
    description: 'NavigationBar: Settings > Vouchers',
    defaultMessage: 'Vouchers',
  },
  integrationsourcesystems: {
    id: 'components.nav.SettingsDropdown.integrationsourcesystems',
    description: 'NavigationBar: Settings > Integration source systems',
    defaultMessage: 'Integration source systems',
  },
})
