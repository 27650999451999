import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_SUCCESS,
  DISMISS_ALL_NOTIFICATIONS,
  DISMISS_ALL_NOTIFICATIONS_ERROR,
  DISMISS_ALL_NOTIFICATIONS_SUCCESS,
  DISMISS_NOTIFICATION,
  DISMISS_NOTIFICATION_ERROR,
  DISMISS_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
} from './constants'

export const getNotifications = ({ companyCode, customerCode }) => ({
  type: GET_NOTIFICATIONS,
  companyCode,
  customerCode,
})

export const getNotificationsSuccess = ({
  companyCode,
  customerCode,
  notifications,
}) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  companyCode,
  customerCode,
  notifications,
})

export const getNotificationsError = ({
  companyCode,
  customerCode,
  error,
}) => ({
  type: GET_NOTIFICATIONS_ERROR,
  companyCode,
  customerCode,
  error,
})

export const createNotification = ({
  companyCode,
  customerCode,
  notification,
}) => ({
  type: CREATE_NOTIFICATION,
  companyCode,
  customerCode,
  notification,
})

export const createNotificationSuccess = ({
  companyCode,
  customerCode,
  notification,
}) => ({
  type: CREATE_NOTIFICATION_SUCCESS,
  companyCode,
  customerCode,
  notification,
})

export const createNotificationError = ({
  companyCode,
  customerCode,
  error,
}) => ({
  type: CREATE_NOTIFICATION_ERROR,
  companyCode,
  customerCode,
  error,
})

export const dismissNotification = ({
  companyCode,
  customerCode,
  notificationId,
}) => ({
  type: DISMISS_NOTIFICATION,
  companyCode,
  customerCode,
  notificationId,
})

export const dismissAllNotifications = ({ companyCode, customerCode }) => ({
  type: DISMISS_ALL_NOTIFICATIONS,
  companyCode,
  customerCode,
})

export const dismissAllNotificationsSuccess = ({
  companyCode,
  customerCode,
}) => ({
  type: DISMISS_ALL_NOTIFICATIONS_SUCCESS,
  companyCode,
  customerCode,
})

export const dismissAllNotificationsError = ({
  companyCode,
  customerCode,
  error,
}) => ({
  type: DISMISS_ALL_NOTIFICATIONS_ERROR,
  companyCode,
  customerCode,
  error,
})

export const dismissNotificationSuccess = ({
  companyCode,
  customerCode,
  notificationId,
}) => ({
  type: DISMISS_NOTIFICATION_SUCCESS,
  companyCode,
  customerCode,
  notificationId,
})

export const dismissNotificationError = ({
  companyCode,
  customerCode,
  error,
}) => ({
  type: DISMISS_NOTIFICATION_ERROR,
  companyCode,
  customerCode,
  error,
})

export const deleteNotification = ({
  companyCode,
  customerCode,
  notificationId,
}) => ({
  type: DELETE_NOTIFICATION,
  companyCode,
  customerCode,
  notificationId,
})

export const deleteNotificationSuccess = ({
  companyCode,
  customerCode,
  notificationId,
}) => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  companyCode,
  customerCode,
  notificationId,
})

export const deleteNotificationError = ({
  companyCode,
  customerCode,
  error,
}) => ({
  type: DELETE_NOTIFICATION_ERROR,
  companyCode,
  customerCode,
  error,
})
