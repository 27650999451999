import { Record } from 'immutable'

const FolderRecord = Record({
  id: undefined,
  name: '',
  type: undefined,
  companyId: undefined,
})

export default FolderRecord
