import moment from 'moment'
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  getCompaniesSuccess,
  getCompaniesError,
  createCompanySuccess,
  createCompanyError,
  deleteCompanySuccess,
  deleteCompanyError,
} from './actions'
import { createAccountingPeriodSuccess } from '../AccountingPeriods/actions'
import { getAccountListChoices } from '../CompanyCreationWizard/actions'
import { handleError } from 'api/api-utils'
import companyApi from 'api/CompanyApi'
import accountingPeriodApi from 'api/AccountingPeriodApi'
import { convertDateTimeToString } from 'utils/Date'
import { GET_COMPANIES, CREATE_COMPANY, DELETE_COMPANY } from './constants'

const START_OF_CURRENT_YEAR = convertDateTimeToString(moment().startOf('year'))
const END_OF_CURRENT_YEAR = convertDateTimeToString(moment().endOf('year'))
const START_OF_NEXT_YEAR = convertDateTimeToString(
  moment()
    .add(1, 'y')
    .startOf('year')
)
const END_OF_NEXT_YEAR = convertDateTimeToString(
  moment()
    .add(1, 'y')
    .endOf('year')
)
const CURRENT_YEAR_STRING = moment(START_OF_CURRENT_YEAR)
  .year()
  .toString()
const NEXT_YEAR_STRING = moment(START_OF_NEXT_YEAR)
  .year()
  .toString()

export function* deleteCompany(action) {
  const { customerCode, companyCode } = action

  try {
    yield call(companyApi.deleteCompany, { customerCode, companyCode })
    yield put(deleteCompanySuccess({ customerCode, companyCode }))
  } catch (error) {
    yield put(handleError(error, deleteCompanyError))
  }
}

export function* createCompany(action) {
  const { company, customerCode } = action
  try {
    const createdCompany = yield call(companyApi.createCompany, {
      company,
      customerCode,
    })

    const firstPeriod = yield call(accountingPeriodApi.createAccountingPeriod, {
      end: END_OF_CURRENT_YEAR,
      companyCode: createdCompany.code,
      name: CURRENT_YEAR_STRING,
      start: START_OF_CURRENT_YEAR,
    })
    const secondPeriod = yield call(
      accountingPeriodApi.createAccountingPeriod,
      {
        end: END_OF_NEXT_YEAR,
        companyCode: createdCompany.code,
        name: NEXT_YEAR_STRING,
        start: START_OF_NEXT_YEAR,
      }
    )
    yield put(createCompanySuccess({ company: createdCompany, customerCode }))
    yield put(
      createAccountingPeriodSuccess({
        accountingPeriod: firstPeriod,
        companyCode: createdCompany.code,
      })
    )
    yield put(
      createAccountingPeriodSuccess({
        accountingPeriod: secondPeriod,
        companyCode: createdCompany.code,
      })
    )
    yield put(getAccountListChoices())
  } catch (error) {
    yield put(handleError(error, createCompanyError))
  }
}

export function* getCompanies(action) {
  const { customerCode } = action

  try {
    const companies = yield call(companyApi.getCompanies, { customerCode })

    yield put(getCompaniesSuccess({ companies }))
  } catch (error) {
    yield put(handleError(error, getCompaniesError))
  }
}

export function* companiesSaga() {
  yield all([
    takeLatest(GET_COMPANIES, getCompanies),
    takeEvery(CREATE_COMPANY, createCompany),
    takeEvery(DELETE_COMPANY, deleteCompany),
  ])
}

// All sagas to be loaded
export default companiesSaga
