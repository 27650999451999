import {
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_ERROR,
  GET_BANK_ACCOUNTS_SUCCESS,
  SAVE_BANK_ACCOUNTS,
  SAVE_BANK_ACCOUNTS_ERROR,
  SAVE_BANK_ACCOUNTS_SUCCESS,
} from './constants'

export const getBankAccounts = (companyCode) => ({
  type: GET_BANK_ACCOUNTS,
  companyCode,
})

export const getBankAccountsError = ({ error }) => ({
  type: GET_BANK_ACCOUNTS_ERROR,
  error,
})

export const getBankAccountsSuccess = ({ bankAccounts, companyCode }) => ({
  type: GET_BANK_ACCOUNTS_SUCCESS,
  bankAccounts,
  companyCode,
})

export const saveBankAccounts = ({ companyCode, selectedAccountIds }) => ({
  type: SAVE_BANK_ACCOUNTS,
  companyCode,
  selectedAccountIds,
})

export const saveBankAccountsError = () => ({
  type: SAVE_BANK_ACCOUNTS_ERROR,
})

export const saveBankAccountsSuccess = ({
  companyCode,
  selectedBankAccounts,
}) => ({
  type: SAVE_BANK_ACCOUNTS_SUCCESS,
  companyCode,
  selectedBankAccounts,
})
