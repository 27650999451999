import { UPDATE_USER_SUCCESS } from 'containers/User/constants'
import { fromJS } from 'immutable'
import { PermissionRecord } from 'records'
import token from 'api/TokenStorage'

import {
  CHANGE_LOCALE,
  CHOSE_PROFILE,
  CHOOSE_PROFILE,
  GET_LOGGED_IN_USER_INFO,
  GET_LOGGED_IN_USER_INFO_ERROR,
  GET_SUB_USERS_SUCCESS,
  GET_PERMISSIONS_SUCCESS,
  INVITE_ERROR,
  PASSWORD_LOGIN,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  START_USER_ACCOUNT_CONVERSION,
  REMOVE_AAD_PROFILE,
  SELECT_LOGIN_METHOD_STATE,
  LOGGED_IN_STATE,
  CHOOSE_PROFILE_STATE,
  CHECK_LOGIN_STATE,
  GET_LOGGED_IN_USER_INFO_STATE,
  GET_LOGIN_METHOD,
  ACCOUNT_CONVERSION_STATE,
  GET_SUB_USERS_ERROR,
  PASSWORD_LOGIN_ERROR,
  AAD_LOGIN_ERROR,
  INITIALIZE_LEMONSOFT_USER,
  SELECT_LEMONSOFT_ORGANIZATION,
  INITIALIZE_LEMONSOFT_USER_ERROR,
  INITIALIZE_LEMONSOFT_USER_PROGRESS,
} from './constants'

const initialState = fromJS({
  locale: 'en',
  profiles: {},
  user: fromJS({
    loading: false,
    isLoggedIn: false,
    data: {},
    permissions: {},
    forceConversion: false,
  }),
  selectedProfileId: null,
  loginState: CHECK_LOGIN_STATE,
  inviteError: undefined,
  passwordLoginError: undefined,
  userDataFetchError: undefined,
  getSubUsersError: undefined,
  lemonsoftInitializationError: undefined,
  lemonsoftInitializationProgress: undefined,
  organizations: undefined,
})

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale)

    case INITIALIZE_LEMONSOFT_USER_PROGRESS:
      return state.set('lemonsoftInitializationProgress', action.progress)

    case INITIALIZE_LEMONSOFT_USER:
      return state.setIn(['user', 'loading'], true)

    case SELECT_LEMONSOFT_ORGANIZATION:
      return state
        .setIn(['user', 'loading'], false)
        .set('organizations', fromJS(action.organizations))
        .set('loginState', SELECT_LEMONSOFT_ORGANIZATION)

    case INITIALIZE_LEMONSOFT_USER_ERROR:
      return state
        .setIn(['user', 'loading'], false)
        .set('loginState', SELECT_LEMONSOFT_ORGANIZATION)
        .set('lemonsoftInitializationError', action.error)

    case CHOSE_PROFILE: {
      const profileId = action.profile.id
      const chosen = action.profile
      token.setProfileId(profileId)
      return state
        .set('loginState', LOGGED_IN_STATE)
        .set('selectedProfileId', profileId)
        .setIn(['user', 'loading'], false)
        .setIn(['user', 'data'], fromJS(chosen))
    }

    case CHOOSE_PROFILE:
      token.clearProfileToken() // clear profile token store
      return state
        .set('loginState', CHOOSE_PROFILE_STATE)
        .set('profiles', fromJS(action.userData))
        .set('userDataFetchError', undefined)
        .set('subUsersLoading', true)
        .setIn(['user', 'loading'], true)
        .setIn(['user', 'isLoggedIn'], false)

    case GET_SUB_USERS_SUCCESS: {
      const arr = action.user
      return state
        .setIn(['user', 'loading'], false)
        .setIn(['profiles'], fromJS(arr))
    }

    case GET_SUB_USERS_ERROR:
      return state
        .setIn(['user', 'loading'], false)
        .set('getSubUsersError', action.error)

    case GET_LOGGED_IN_USER_INFO:
      return state
        .set('loginState', GET_LOGGED_IN_USER_INFO_STATE)
        .set('userDataFetchError', undefined)
        .setIn(['user', 'loading'], true)
    case AAD_LOGIN_ERROR:
    case GET_LOGGED_IN_USER_INFO_ERROR:
      return state
        .setIn(['user', 'loading'], false)
        .set('loginState', GET_LOGGED_IN_USER_INFO_STATE)
        .set('userDataFetchError', action.error)

    case GET_LOGIN_METHOD:
      return state.set('loginState', SELECT_LOGIN_METHOD_STATE)

    case GET_PERMISSIONS_SUCCESS:
      return state.setIn(
        ['user', 'permissions'],
        PermissionRecord(action.permissions)
      )

    case PASSWORD_LOGIN:
      return state.setIn(['user', 'loading'], true)

    case PASSWORD_LOGIN_ERROR:
      return state
        .setIn(['user', 'loading'], false)
        .set('passwordLoginError', action.error)

    case LOGIN_SUCCESS:
      return state
        .setIn(['user', 'isLoggedIn'], true)
        .setIn(['user', 'loading'], false)
        .setIn(['user', 'data'], fromJS(action.user))

    case LOGOUT_SUCCESS:
      return state.set('loginState', SELECT_LOGIN_METHOD_STATE).set(
        'user',
        fromJS({
          loading: false,
          isLoggedIn: false,
          data: {},
          permissions: {},
        })
      )
    case START_USER_ACCOUNT_CONVERSION:
      return state
        .set('loginState', ACCOUNT_CONVERSION_STATE)
        .setIn(['user', 'loading'], false)
        .setIn(['user', 'forceConversion'], action.forceConversion)

    case INVITE_ERROR: {
      return state.set('inviteError', action.error)
    }

    case UPDATE_USER_SUCCESS:
      if (state.getIn(['user', 'data', 'id']) !== action.user.id) return state

      return state.setIn(['user', 'data'], fromJS(action.user))

    case REMOVE_AAD_PROFILE:
      const id = action.aad ? 'aadUserId' : 'id'
      return state.update('profiles', (profiles) =>
        profiles.filter((profile) => profile.get(id) !== action.userId)
      )

    default:
      return state
  }
}

export default appReducer
