import { all, call, put, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import calculationConstantApi from 'api/CalculationConstantApi'
import { updateCalculationConstantSuccess } from 'containers/CalculationConstants/actions'

import {
  createCalculationConstantValueError,
  createCalculationConstantValueSuccess,
  deleteCalculationConstantValueError,
  deleteCalculationConstantValueSuccess,
  updateCalculationConstantValueError,
  updateCalculationConstantValueSuccess,
} from './actions'
import {
  CREATE_CALCULATION_CONSTANT_VALUE,
  DELETE_CALCULATION_CONSTANT_VALUE,
  UPDATE_CALCULATION_CONSTANT_VALUE,
} from './constants'

// All sagas to be loaded
export function* createCalculationConstantValue(action) {
  const {
    budgetId,
    calculationConstantId,
    companyCode,
    customerCode,
    definedInScope,
    start,
    value,
  } = action

  const patch = [
    {
      op: 'add',
      path: '/values/-',
      value: { start, value },
    },
  ]

  try {
    const calculationConstant = yield call(
      calculationConstantApi.patchCalculationConstant,
      {
        budgetId,
        calculationConstantId,
        companyCode,
        customerCode,
        definedInScope,
        patch,
      }
    )
    yield put(createCalculationConstantValueSuccess())
    yield put(updateCalculationConstantSuccess({ calculationConstant }))
  } catch (error) {
    yield put(handleError(error, createCalculationConstantValueError))
  }
}

export function* deleteCalculationConstantValue(action) {
  const {
    budgetId,
    calculationConstantId,
    companyCode,
    customerCode,
    definedInScope,
    index,
  } = action

  const patch = [
    {
      op: 'remove',
      path: `/values/${index}`,
    },
  ]

  try {
    const calculationConstant = yield call(
      calculationConstantApi.patchCalculationConstant,
      {
        budgetId,
        calculationConstantId,
        companyCode,
        customerCode,
        definedInScope,
        patch,
      }
    )
    yield put(deleteCalculationConstantValueSuccess())
    yield put(updateCalculationConstantSuccess({ calculationConstant }))
  } catch (error) {
    yield put(handleError(error, deleteCalculationConstantValueError))
  }
}

export function* updateCalculationConstantValue(action) {
  const {
    budgetId,
    calculationConstant: { id: calculationConstantId, definedInScope },
    companyCode,
    customerCode,
    edit,
    start,
    value,
  } = action
  const patch = [
    {
      op: 'replace',
      path: `/values/${edit}`,
      value: {
        start,
        value,
      },
    },
  ]

  try {
    const calculationConstant = yield call(
      calculationConstantApi.patchCalculationConstant,
      {
        budgetId,
        calculationConstantId,
        companyCode,
        customerCode,
        definedInScope,
        patch,
      }
    )
    yield put(updateCalculationConstantValueSuccess())
    yield put(updateCalculationConstantSuccess({ calculationConstant }))
  } catch (error) {
    yield put(handleError(error, updateCalculationConstantValueError))
  }
}

export function* calculationConstantSaga() {
  yield all([
    takeEvery(
      CREATE_CALCULATION_CONSTANT_VALUE,
      createCalculationConstantValue
    ),
    takeEvery(
      DELETE_CALCULATION_CONSTANT_VALUE,
      deleteCalculationConstantValue
    ),
    takeEvery(
      UPDATE_CALCULATION_CONSTANT_VALUE,
      updateCalculationConstantValue
    ),
  ])
}

export default calculationConstantSaga
