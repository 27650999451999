/*
 *
 * CalculationConstants reducer
 *
 */
import { fromJS, List } from 'immutable'
import {
  CalculationConstantRecord,
  CalculationConstantValueRecord,
} from 'records'

import {
  CREATE_CALCULATION_CONSTANT_SUCCESS,
  CREATE_CALCULATION_CONSTANT_ERROR,
  DELETE_CALCULATION_CONSTANT_ERROR,
  DELETE_CALCULATION_CONSTANT_SUCCESS,
  EDIT_CALCULATION_CONSTANT,
  GET_CALCULATION_CONSTANTS,
  GET_CALCULATION_CONSTANTS_ERROR,
  GET_CALCULATION_CONSTANTS_SUCCESS,
  TOGGLE_SHOW_CREATE_CALCULATION_CONSTANTS,
  UPDATE_CALCULATION_CONSTANT,
  UPDATE_CALCULATION_CONSTANT_SUCCESS,
  UPDATE_CALCULATION_CONSTANT_ERROR,
} from './constants'
import { alphabeticallyAscendingComparer } from 'utils/sort'

export const mapCalculationConstantValues = (values) =>
  values.map((value) => new CalculationConstantValueRecord({ ...value }))

const mapCalculationConstants = (calculationConstants) =>
  calculationConstants.map(
    (calculationConstant) =>
      new CalculationConstantRecord({
        ...calculationConstant,
        values: List(mapCalculationConstantValues(calculationConstant.values)),
      })
  )

const initialState = fromJS({
  calculationConstants: undefined,
  edit: undefined,
  loading: false,
  showCreateCalculationConstantForm: false,
})

const calculationConstantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CALCULATION_CONSTANT_SUCCESS:
      return state
        .set('showCreateCalculationConstantForm', false)
        .set('loading', false)
        .set(
          'calculationConstants',
          state
            .get('calculationConstants')
            .push(new CalculationConstantRecord(action.calculationConstant))
            .sort(alphabeticallyAscendingComparer)
        )

    case CREATE_CALCULATION_CONSTANT_ERROR:
    case DELETE_CALCULATION_CONSTANT_ERROR:
    case GET_CALCULATION_CONSTANTS_ERROR:
    case UPDATE_CALCULATION_CONSTANT_ERROR:
      return state.set('loading', false).set('error', action.error)

    case DELETE_CALCULATION_CONSTANT_SUCCESS:
      return state.set('loading', false).set(
        'calculationConstants',
        state
          .get('calculationConstants')
          .filter((calculationConstant) => calculationConstant.id !== action.id)
          .sort(alphabeticallyAscendingComparer)
      )

    case EDIT_CALCULATION_CONSTANT:
      return state
        .set('edit', action.calculationConstantId)
        .set('showCreateCalculationConstantForm', false)

    case GET_CALCULATION_CONSTANTS:
    case UPDATE_CALCULATION_CONSTANT:
      return state.set('loading', true)

    case GET_CALCULATION_CONSTANTS_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'calculationConstants',
          List(mapCalculationConstants(action.calculationConstants)).sort(
            alphabeticallyAscendingComparer
          )
        )

    case TOGGLE_SHOW_CREATE_CALCULATION_CONSTANTS:
      return state
        .set('edit', undefined)
        .set(
          'showCreateCalculationConstantForm',
          !state.get('showCreateCalculationConstantForm')
        )

    case UPDATE_CALCULATION_CONSTANT_SUCCESS: {
      const { calculationConstant } = action
      const calculationConstants = state.get('calculationConstants')
      const index = calculationConstants.findIndex(
        (item) => item.id === calculationConstant.id
      )
      return state
        .set('loading', false)
        .set('edit', undefined)
        .set(
          'calculationConstants',
          calculationConstants
            .setIn(
              [index],
              new CalculationConstantRecord({
                ...action.calculationConstant,
                values: List(
                  mapCalculationConstantValues(
                    action.calculationConstant.values
                  )
                ),
              })
            )
            .sort(alphabeticallyAscendingComparer)
        )
    }

    default:
      return state
  }
}

export default calculationConstantsReducer
