import { useEffect } from 'react'

const KEY_EVENTS = ['keydown', 'keypress', 'keyup']
const NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

function useKey({ callback, keyEvent = KEY_EVENTS[0], listenKeys = [] }) {
  if (!Array.isArray(listenKeys)) {
    throw new Error('listenKeys is not an array!')
  }
  if (!KEY_EVENTS.includes(keyEvent)) {
    throw new Error('keyEvent is not valid keyboard event!')
  }
  if (!window) {
    throw new Error('window is undefined!')
  }

  const listenAllKeys = listenKeys.length === 0

  useEffect(() => {
    const handleEvent = (event) => {
      if (listenAllKeys || listenKeys.includes(event.key)) {
        return callback(event)
      }
    }

    window.addEventListener(keyEvent, handleEvent)

    return () => {
      window.removeEventListener(keyEvent, handleEvent)
    }
  }, [callback, keyEvent, listenAllKeys, listenKeys])
}

// Hook for Esc-button.
function useEscPress(callback) {
  useKey({ callback, listenKeys: ['Escape'] })
}

export { NUMBERS, useKey, useEscPress }
