/*
 *
 * AccountSchemes constants
 *
 */
export const CANCEL_CREATE_ACCOUNT_SCHEME =
  'app/AccountSchemes/CANCEL_CREATE_ACCOUNT_SCHEME'

export const CREATE_ACCOUNT_SCHEME = 'app/AccountSchemes/CREATE_ACCOUNT_SCHEME'
export const CREATE_ACCOUNT_SCHEME_ERROR =
  'app/AccountSchemes/CREATE_ACCOUNT_SCHEME_ERROR'
export const CREATE_ACCOUNT_SCHEME_SUCCESS =
  'app/AccountSchemes/CREATE_ACCOUNT_SCHEME_SUCCESS'

export const EDIT_ACCOUNT_SCHEME = 'app/AccountSchemes/EDIT_ACCOUNT_SCHEME'

export const DELETE_ACCOUNT_SCHEME = 'app/AccountSchemes/DELETE_ACCOUNT_SCHEME'
export const DELETE_ACCOUNT_SCHEME_ERROR =
  'app/AccountSchemes/DELETE_ACCOUNT_SCHEME_ERROR'
export const DELETE_ACCOUNT_SCHEME_SUCCESS =
  'app/AccountSchemes/DELETE_ACCOUNT_SCHEME_SUCCESS'

export const GET_ACCOUNT_SCHEMES = 'app/AccountSchemes/GET_ACCOUNT_SCHEMES'
export const GET_ACCOUNT_SCHEMES_ERROR =
  'app/AccountSchemes/GET_ACCOUNT_SCHEMES_ERROR'
export const GET_ACCOUNT_SCHEMES_SUCCESS =
  'app/AccountSchemes/GET_ACCOUNT_SCHEMES_SUCCESS'
export const RESET_STATE = 'app/AccountSchemes/RESET_STATE'
export const SET_DEFAULT_ACCOUNT_SCHEME =
  'app/AccountSchemes/SET_DEFAULT_ACCOUNT_SCHEME'
export const SET_DEFAULT_ACCOUNT_SCHEME_ERROR =
  'app/AccountSchemes/SET_DEFAULT_ACCOUNT_SCHEME_ERROR'
export const SET_DEFAULT_ACCOUNT_SCHEME_SUCCESS =
  'app/AccountSchemes/SET_DEFAULT_ACCOUNT_SCHEME_SUCCESS'

export const TOGGLE_SHOW_CREATE_ACCOUNT_SCHEME =
  'app/AccountSchemes/TOGGLE_SHOW_CREATE_ACCOUNT_SCHEME'

export const UPDATE_ACCOUNT_SCHEME = 'app/AccountSchemes/UPDATE_ACCOUNT_SCHEME'
export const UPDATE_ACCOUNT_SCHEME_ERROR =
  'app/AccountSchemes/UPDATE_ACCOUNT_SCHEME_ERROR'
export const UPDATE_ACCOUNT_SCHEME_SUCCESS =
  'app/AccountSchemes/UPDATE_ACCOUNT_SCHEME_SUCCESS'
