import { createSelector } from 'reselect'

/*
 * The Global App state selectors
 */
const selectGlobal = (state) => state.get('global')

const selectCustomerCode = (state, props) =>
  props?.params?.customerCode || props?.customerCode

const selectCompanyCode = (state, props) =>
  props?.params?.companyCode || props?.companyCode

const selectInviteError = createSelector(
  selectGlobal,
  (state) => state.get('inviteError')
)

const selectUser = createSelector(
  selectGlobal,
  (state) => state.get('user')
)

const selectUserIsLoggedIn = createSelector(
  selectUser,
  (user) => user.get('isLoggedIn')
)

const selectUserIsForcedToConvert = createSelector(
  selectUser,
  (user) => user.get('forceConversion')
)

const selectAllPermissions = createSelector(
  selectUser,
  (user) => user.get('permissions')
)

const selectClaims = createSelector(
  [selectAllPermissions, selectCustomerCode, selectCompanyCode],
  (permissions, customerCode, companyCode) => {
    return getContextPermissions({ permissions, companyCode, customerCode })
  }
)

const getContextPermissions = ({ permissions, customerCode, companyCode }) => {
  const claims = permissions.claims ? permissions.claims.slice() : []
  if (
    customerCode &&
    permissions.customers &&
    permissions.customers[customerCode]
  ) {
    claims.push(...permissions.customers[customerCode].claims)

    if (
      companyCode &&
      permissions.customers[customerCode].companies[companyCode]
    ) {
      claims.push(
        ...permissions.customers[customerCode].companies[companyCode].claims
      )
    }
  }
  return claims
}

const selectUserData = createSelector(
  selectUser,
  (user) => user.get('data')
)

const selectUserDefaultPage = createSelector(
  selectUserData,
  (user) => user.get('defaultPage')
)

const selectUserUsername = createSelector(
  selectUserData,
  (data) => data.get('name')
)

const selectUserId = createSelector(
  selectUserData,
  (data) => data.get('id')
)

const selectUserLoading = createSelector(
  selectUser,
  (user) => user.get('loading')
)

const selectUserProfiles = createSelector(
  selectGlobal,
  (state) => state.get('profiles')
)
const selectUserTheme = createSelector(
  selectUserData,
  (user) => user.get('theme')
)

const selectLoginFlowState = createSelector(
  selectGlobal,
  (state) => state.get('loginState')
)

const selectPasswordLoginError = createSelector(
  selectGlobal,
  (state) => state.get('passwordLoginError')
)

const selectUserDataFetchError = createSelector(
  selectGlobal,
  (state) => state.get('userDataFetchError')
)

const selectGetSubUsersError = createSelector(
  selectGlobal,
  (state) => state.get('getSubUsersError')
)

const selectInitializeLemonsoftUserError = createSelector(
  selectGlobal,
  (state) => state.get('lemonsoftInitializationError')
)

const selectLemonsoftOrganizations = createSelector(
  selectGlobal,
  (state) => state.get('organizations')
)

const selectInitializeLemonsoftUserProgress = createSelector(
  selectGlobal,
  (state) => state.get('lemonsoftInitializationProgress')
)

export {
  selectInviteError,
  selectUser,
  selectUserData,
  selectUserDefaultPage,
  selectUserId,
  selectUserIsLoggedIn,
  selectUserLoading,
  selectUserUsername,
  selectClaims,
  selectUserProfiles,
  selectUserTheme,
  selectAllPermissions,
  selectUserIsForcedToConvert,
  getContextPermissions,
  selectLoginFlowState,
  selectPasswordLoginError,
  selectUserDataFetchError,
  selectGetSubUsersError,
  selectInitializeLemonsoftUserError,
  selectLemonsoftOrganizations,
  selectInitializeLemonsoftUserProgress,
}
