/**
 *
 * ErrorComponent
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import errorCodes from './errorCodes'
import DefaultErrorComponent from './ErrorComponents'
import trackEvent from 'utils/trackEvent'

const ErrorComponent = (props) => {
  const { data } = props
  trackEvent('Error component rendered')
  const message = data && (data.Message || data.message || 'Error')
  const code = data && (data.Code || data.code)
  const errorMessages = message
    ?.split(/\r?\n/)
    ?.filter((m) => !m?.includes('End of stack trace '))
  const Error = errorCodes[code] || DefaultErrorComponent

  return (
    <Error
      {...props}
      message={message}
      code={code}
      errorMessages={errorMessages}
    />
  )
}

ErrorComponent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  config: PropTypes.object,
  jsError: PropTypes.string,
  status: PropTypes.number,
  statusText: PropTypes.string,
}

export default ErrorComponent
