import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.components.Notifications.header',
    defaultMessage: 'This is Notifications component !',
  },
  title: {
    id: 'app.components.Notifications.Title',
    defaultMessage: 'Title',
  },
  scope: {
    id: 'app.components.Notifications.scope',
    defaultMessage: 'Notification scope',
  },
  description: {
    id: 'app.components.Notifications.Description',
    defaultMessage: 'Description',
  },
  link: {
    id: 'app.components.Notifications.Link',
    defaultMessage: 'Link',
  },
  status: {
    id: 'app.components.Notifications.Status',
    defaultMessage: 'Status',
  },
  validTo: {
    id: 'app.components.Notifications.ValidTo',
    defaultMessage: 'Display until',
  },
  goTo: {
    id: 'app.components.Notifications.goTo',
    defaultMessage: 'Go to',
  },
  more: {
    id: 'app.components.Notifications.more',
    defaultMessage: 'More',
  },
  less: {
    id: 'app.components.Notifications.less',
    defaultMessage: 'Less',
  },
  dismiss: {
    id: 'app.components.Notifications.dismiss',
    defaultMessage: 'Dismiss',
  },
  notifications: {
    id: 'app.components.Notifications.notifications',
    defaultMessage: 'Notifications',
  },
  sendNotifications: {
    id: 'app.components.Notifications.sendNotifications',
    defaultMessage: 'Send',
  },
  createNotifications: {
    id: 'app.components.Notifications.createNotifications',
    defaultMessage: 'Create',
  },
  noName: {
    id: 'app.components.Notifications.noName',
    defaultMessage: 'No name',
  },
  dissMissAll: {
    id: 'app.components.Notifications.dissMissAll',
    defaultMessage: 'Dismiss All',
  },
})
