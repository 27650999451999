import { all, call, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { Record } from 'immutable'
import diff from 'immutablediff'

import dashboardApi from 'api/DashboardApi'
import { handleError } from 'api/api-utils'

import { parseLocation } from './functions'

import {
  copyDashboardError,
  copyDashboardSuccess,
  copyDashboardWidgetError,
  copyDashboardWidgetSuccess,
  createDashboardError,
  createDashboardSuccess,
  createDashboardShare,
  createDashboardShareError,
  createDashboardShareSuccess,
  createDashboardWidgetError,
  createDashboardWidgetSuccess,
  deleteDashboardError,
  deleteDashboardSuccess,
  deleteDashboardShare,
  deleteDashboardShareError,
  deleteDashboardShareSuccess,
  deleteDashboardWidgetError,
  deleteDashboardWidgetSuccess,
  getDashboardsError,
  getDashboardsSuccess,
  getDashboardSharesError,
  getDashboardSharesSuccess,
  updateDashboardError,
  updateDashboardSuccess,
  updateDashboardSharesError,
  updateDashboardSharesSuccess,
  updateDashboardWidgetError,
  updateDashboardWidgetSuccess,
  updateDashboardWidgetParametersError,
  updateDashboardWidgetParametersSuccess,
} from './actions'
import {
  COPY_DASHBOARD,
  COPY_DASHBOARD_WIDGET,
  CREATE_DASHBOARD,
  CREATE_DASHBOARD_SHARE,
  CREATE_DASHBOARD_SHARE_SUCCESS,
  CREATE_DASHBOARD_WIDGET,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_SHARE,
  DELETE_DASHBOARD_SHARE_SUCCESS,
  DELETE_DASHBOARD_WIDGET,
  GET_DASHBOARDS,
  GET_DASHBOARD_SHARES,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_SHARES,
  UPDATE_DASHBOARD_WIDGET,
  UPDATE_DASHBOARD_WIDGET_PARAMETERS,
} from './constants'

const UpdateDashboardRecord = Record({
  name: undefined,
})

const UpdateWidgetRecord = Record({
  parameters: undefined,
  type: undefined,
})

export function* createDashboard(action) {
  const { name } = action

  try {
    const dashboard = yield call(dashboardApi.createDashboard, {
      name,
    })
    yield put(createDashboardSuccess({ dashboard }))
  } catch (error) {
    yield put(handleError(error, createDashboardError))
  }
}

export function* createDashboardShares(action) {
  try {
    const { dashboardId, userIds } = action
    yield call(dashboardApi.createDashboardShare, { dashboardId, userIds })
    yield put(createDashboardShareSuccess({ dashboardId, userIds }))
  } catch (error) {
    yield put(handleError(error, createDashboardShareError))
  }
}

export function* copyDashboard(action) {
  const { copyDashboardShares, id, name } = action

  try {
    const dashboard = yield call(dashboardApi.copyDashboard, {
      copyDashboardShares,
      id,
      name,
    })
    yield put(copyDashboardSuccess({ dashboard }))
  } catch (error) {
    yield put(handleError(error, copyDashboardError))
  }
}

export function* copyDashboardWidget(action) {
  const { dashboardId, widget } = action

  try {
    const newWidget = yield call(dashboardApi.createDashboardWidget, {
      dashboardId,
      widget,
    })
    yield put(
      copyDashboardWidgetSuccess({
        widget: newWidget,
        temporaryWidgetId: widget.id,
      })
    )
  } catch (error) {
    yield put(handleError(error, copyDashboardWidgetError))
  }
}

export function* createDashboardWidget(action) {
  const { dashboardId, widget } = action

  try {
    const newWidget = yield call(dashboardApi.createDashboardWidget, {
      dashboardId,
      widget,
    })
    yield put(
      createDashboardWidgetSuccess({
        widget: newWidget,
        temporaryWidgetId: widget.id,
      })
    )
  } catch (error) {
    yield put(handleError(error, createDashboardWidgetError))
  }
}

export function* deleteDashboard(action) {
  const { companyCode, customerCode, id, navigate } = action

  try {
    yield call(dashboardApi.deleteDashboard, {
      dashboardId: id,
    })
    yield put(deleteDashboardSuccess({ id }))
    navigate(parseLocation({ companyCode, customerCode }))
  } catch (error) {
    yield put(deleteDashboardError({ error, id }))
  }
}

export function* deleteDashboardWidget(action) {
  const { dashboardId, widgetId } = action

  try {
    yield call(dashboardApi.deleteDashboardWidget, {
      dashboardId,
      widgetId,
    })
    yield put(deleteDashboardWidgetSuccess())
  } catch (error) {
    yield put(deleteDashboardWidgetError(error))
  }
}

export function* deleteDashboardShares(action) {
  try {
    const { dashboardId, userIds } = action
    yield call(dashboardApi.deleteDashboardShare, { dashboardId, userIds })
    yield put(deleteDashboardShareSuccess({ dashboardId, userIds }))
  } catch (error) {
    yield put(handleError(error, deleteDashboardShareError))
  }
}

export function* getDashboards(action) {
  const { companyCode } = action

  try {
    const dashboards = yield call(dashboardApi.getDashboards, companyCode)
    yield put(getDashboardsSuccess({ dashboards }))
  } catch (error) {
    yield put(handleError(error, getDashboardsError))
  }
}

export function* getDashboardShares(action) {
  try {
    const { dashboardId } = action
    const userIds = yield call(dashboardApi.getDashboardShares, dashboardId)
    yield put(getDashboardSharesSuccess({ userIds, dashboardId }))
  } catch (error) {
    yield put(handleError(error, getDashboardSharesError))
  }
}

export function* updateDashboard(action) {
  const { dashboard, updatedDashboard } = action
  const { id: dashboardId } = dashboard
  const patch = diff(
    new UpdateDashboardRecord(dashboard),
    new UpdateDashboardRecord(updatedDashboard)
  ).toJS()

  try {
    const updatedDashboard = yield call(dashboardApi.patchDashboard, {
      patch,
      dashboardId,
    })
    yield put(updateDashboardSuccess({ dashboard: updatedDashboard }))
  } catch (error) {
    yield put(updateDashboardError({ error, dashboardId }))
  }
}

export function* updateDashboardShares(action) {
  try {
    const { dashboardId, userIdsToAdd, userIdsToRemove } = action

    if (userIdsToRemove.length > 0) {
      yield put(deleteDashboardShare({ dashboardId, userIds: userIdsToRemove }))

      // We want to wait until this delete completes
      yield take(DELETE_DASHBOARD_SHARE_SUCCESS)
    }

    if (userIdsToAdd.length > 0) {
      yield put(createDashboardShare({ dashboardId, userIds: userIdsToAdd }))

      // We want to wait until this create completes
      yield take(CREATE_DASHBOARD_SHARE_SUCCESS)
    }

    yield put(updateDashboardSharesSuccess({ dashboardId }))
  } catch (error) {
    yield put(handleError(error, updateDashboardSharesError))
  }
}

export function* updateDashboardWidget(action) {
  const { dashboardId, editWidget, widget } = action
  const {
    parameters: { x, y, width, height },
  } = editWidget.toJS()

  try {
    const patch = diff(
      new UpdateWidgetRecord(editWidget),
      new UpdateWidgetRecord({
        ...widget,
        parameters: {
          x,
          y,
          width,
          height,
          ...widget.parameters,
        },
      })
    ).toJS()

    const updatedWidget = yield call(dashboardApi.patchDashboardWidget, {
      dashboardId,
      widgetId: editWidget.id,
      patch,
    })
    yield put(
      updateDashboardWidgetSuccess({ dashboardId, widget: updatedWidget })
    )
  } catch (error) {
    yield put(handleError(error, updateDashboardWidgetError))
  }
}

export function* updateDashboardWidgetParameters(action) {
  const { dashboardId, widgetId, parameters } = action

  try {
    yield call(dashboardApi.upsertWidgetParameters, {
      dashboardId,
      widgetId,
      parameters,
    })
    yield put(updateDashboardWidgetParametersSuccess())
  } catch (error) {
    yield put(handleError(error, updateDashboardWidgetParametersError))
  }
}

export function* dashboards() {
  yield all([
    takeEvery(COPY_DASHBOARD, copyDashboard),
    takeEvery(COPY_DASHBOARD_WIDGET, copyDashboardWidget),
    takeEvery(CREATE_DASHBOARD, createDashboard),
    takeEvery(CREATE_DASHBOARD_SHARE, createDashboardShares),
    takeEvery(CREATE_DASHBOARD_WIDGET, createDashboardWidget),
    takeEvery(DELETE_DASHBOARD, deleteDashboard),
    takeEvery(DELETE_DASHBOARD_SHARE, deleteDashboardShares),
    takeEvery(DELETE_DASHBOARD_WIDGET, deleteDashboardWidget),
    takeEvery(UPDATE_DASHBOARD_SHARES, updateDashboardShares),
    takeEvery(UPDATE_DASHBOARD_WIDGET, updateDashboardWidget),
    takeEvery(
      UPDATE_DASHBOARD_WIDGET_PARAMETERS,
      updateDashboardWidgetParameters
    ),
    takeLatest(GET_DASHBOARDS, getDashboards),
    takeLatest(GET_DASHBOARD_SHARES, getDashboardShares),
    takeLatest(UPDATE_DASHBOARD, updateDashboard),
  ])
}

export default dashboards
