import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import integrationApi from 'api/IntegrationApi'

import {
  executeCompanyIntegrationSuccess,
  executeCompanyIntegrationError,
  getIntegrationEventsSuccess,
  getIntegrationEventsError,
  getIntegrationEvents as getIntegrationEventsAction,
  getIntegrationConnectionsSuccess,
  getIntegrationConnectionsError,
  deleteIntegrationConnectionSuccess,
  deleteIntegrationConnectionError,
  putIntegrationConnectionSuccess,
  putIntegrationConnectionError,
  createIntegrationConnectionError,
} from './actions'
import {
  EXECUTE_INTEGRATION,
  GET_INTEGRATION_EVENTS,
  GET_INTEGRATION_CONNECTIONS,
  DELETE_INTEGRATION_CONNECTION,
  PUT_INTEGRATION_CONNECTION,
  CREATE_INTEGRATION_CONNECTION,
} from './constants'
import { createNotification } from 'containers/Notifications/actions'
import moment from 'moment'
import { NOTIFICATION_STATUS_INFORMATION } from 'containers/Notifications/constants'
import { NOTIFICATION_SCOPE_COMPANY } from 'containers/Notifications/constants'

export function* executeIntegration(action) {
  const {
    companyCode,
    customerCode,
    startDate,
    endDate,
    materialTypes,
    integrationCode,
    modifiedLogicApplied,
  } = action
  try {
    yield put(
      createNotification({
        companyCode,
        customerCode,
        notification: {
          validTo: moment().add(5, 'hours'),
          status: NOTIFICATION_STATUS_INFORMATION,
          scope: NOTIFICATION_SCOPE_COMPANY,
          description: `Starting integration for material types ${materialTypes}
  Requesting data from source system. Please wait.`,
          title: `Integration requested`,
          companyCode: companyCode,
        },
      })
    )
    const companyIntegration = yield call(
      !!integrationCode
        ? integrationApi.executeSingleIntegration
        : integrationApi.executeCompanyIntegration,
      {
        companyCode,
        startDate,
        endDate,
        materialTypes,
        integrationCode,
        modifiedLogicApplied,
      }
    )
    yield put(executeCompanyIntegrationSuccess(companyIntegration))
    yield put(getIntegrationEventsAction({ companyCode, limit: 5 }))
  } catch (error) {
    yield put(handleError(error, executeCompanyIntegrationError))
  }
}

export function* getIntegrationEvents(action) {
  const { companyCode, limit } = action
  try {
    const integrationEvents = yield call(integrationApi.getIntegrationEvents, {
      companyCode,
      limit,
    })
    yield put(getIntegrationEventsSuccess(integrationEvents))
  } catch (error) {
    yield put(handleError(error, getIntegrationEventsError))
  }
}

export function* getIntegrationConnections(action) {
  const { companyCode } = action
  try {
    const connections = yield call(integrationApi.getIntegrationConnections, {
      companyCode,
    })
    yield put(getIntegrationConnectionsSuccess({ companyCode, connections }))
  } catch (error) {
    yield put(handleError(error, getIntegrationConnectionsError))
  }
}

export function* deleteIntegrationConnection(action) {
  const { companyCode, integrationCode } = action
  try {
    yield call(integrationApi.deleteIntegrationConnection, {
      companyCode,
      integrationCode,
    })
    yield put(
      deleteIntegrationConnectionSuccess({ companyCode, integrationCode })
    )
  } catch (error) {
    yield put(
      handleError(error, deleteIntegrationConnectionError, { integrationCode })
    )
  }
}

export function* putIntegrationConnection(action) {
  const { companyCode, integrationCode, connection } = action
  try {
    const created = yield call(integrationApi.putIntegrationConnection, {
      companyCode,
      integrationCode,
      connection,
    })
    yield put(
      putIntegrationConnectionSuccess({
        companyCode,
        integrationCode,
        connection: created,
      })
    )
  } catch (error) {
    yield put(
      handleError(error, putIntegrationConnectionError, { integrationCode })
    )
  }
}

export function* createIntegrationConnection(action) {
  const { companyCode, integrationCode, connection } = action
  try {
    const created = yield call(integrationApi.putIntegrationConnection, {
      companyCode,
      integrationCode,
      connection,
    })
    yield put(
      putIntegrationConnectionSuccess({
        companyCode,
        integrationCode,
        connection: created,
      })
    )
  } catch (error) {
    yield put(
      handleError(error, createIntegrationConnectionError, { integrationCode })
    )
  }
}

export function* integrationSagas() {
  yield all([
    takeLatest(GET_INTEGRATION_EVENTS, getIntegrationEvents),

    takeLatest(EXECUTE_INTEGRATION, executeIntegration),
    takeLatest(GET_INTEGRATION_CONNECTIONS, getIntegrationConnections),
    takeEvery(DELETE_INTEGRATION_CONNECTION, deleteIntegrationConnection),
    takeEvery(PUT_INTEGRATION_CONNECTION, putIntegrationConnection),
    takeEvery(CREATE_INTEGRATION_CONNECTION, createIntegrationConnection),
  ])
}

// All sagas to be loaded
export default integrationSagas
