/*
 *
 * BudgetBalanceImportModal constants
 *
 */
export const IMPORT_BUDGET_BALANCES =
  'app/BudgetBalanceImportModal/IMPORT_BUDGET_BALANCES'
export const IMPORT_BUDGET_BALANCES_ERROR =
  'app/BudgetBalanceImportModal/IMPORT_BUDGET_BALANCES_ERROR'
export const IMPORT_BUDGET_BALANCES_SUCCESS =
  'app/BudgetBalanceImportModal/IMPORT_BUDGET_BALANCES_SUCCESS'

export const PARSE_BUDGET_BALANCES =
  'app/BudgetBalanceImportModal/PARSE_BUDGET_BALANCES'
export const PARSE_BUDGET_BALANCES_ERROR =
  'app/BudgetBalanceImportModal/PARSE_BUDGET_BALANCES_ERROR'
export const PARSE_BUDGET_BALANCES_SUCCESS =
  'app/BudgetBalanceImportModal/PARSE_BUDGET_BALANCES_SUCCESS'

export const RESET_IMPORT_BUDGET_BALANCE_MODAL =
  'app/BudgetBalanceImportModal/RESET_IMPORT_BUDGET_BALANCE_MODAL'
