import { Record } from 'immutable'

const AccountingPeriodRecord = Record({
  id: undefined,
  name: '',
  start: '',
  end: '',
  active: false,
  rangeEditable: true,
  deletable: true,
})

export default AccountingPeriodRecord
