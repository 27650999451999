import { defineMessages } from 'react-intl'

export default defineMessages({
  dashboards: {
    id: 'app.containers.NavigationBar.dashboards',
    description: 'Navigation Bar: Dashboards',
    defaultMessage: 'Dashboards',
  },
  helpCenter: {
    id: 'app.containers.NavigationBar.helpCenter',
    description: 'Navigation Bar: Help Center',
    defaultMessage: 'Help Center',
  },
  selectCustomer: {
    id: 'nav.customer-dropdown.no-selection',
    description: 'NavigationBar: Customer Dropdown, Empty selection',
    defaultMessage: 'Select Customer',
  },
  selectCompany: {
    id: 'nav.company-dropdown.no-selection',
    description: 'NavigationBar: Company Dropdown, Empty selection',
    defaultMessage: 'Select Company',
  },
  login: {
    id: 'nav.login',
    description: 'Navigation Bar: Login Button',
    defaultMessage: 'Login',
  },
  logout: {
    id: 'nav.user.logout',
    description: 'Navigation Bar: User Menu > Logout',
    defaultMessage: 'Sign out',
  },
  settings: {
    id: 'app.containers.NavigationBar.settings',
    description: 'Navigation Bar: User Menu > Settings',
    defaultMessage: 'Settings',
  },
  reports: {
    id: 'app.components.CompanyDropdown.reports',
    description: 'Navigation Bar: Company > Reports',
    defaultMessage: 'Reports',
  },
  customer: {
    id: 'nav.customer-dropdown.header',
    description: 'Navigation Bar: Customer Dropdown, Header',
    defaultMessage: 'Customer',
  },
  company: {
    id: 'nav.company-dropdown.header',
    description: 'Navigation Bar: Company Dropdown, Header',
    defaultMessage: 'Company',
  },
  loggedinas: {
    id: 'nav.user.loggedinas',
    description: 'Navigation Bar: User Menu, Header',
    defaultMessage: 'Signed in as',
  },
  budgets: {
    id: 'app.components.CompanyDropdown.budgets',
    description: 'Navigation Bar: Company > budgets',
    defaultMessage: 'Budgets',
  },
  cashFlow: {
    id: 'app.components.CompanyDropdown.cashFlow',
    description: 'NavigationBar: Company > Cash Flow',
    defaultMessage: 'Cash Flow',
  },
  linksNavigation: {
    id: 'app.components.CompanyDropdown.linksNavigation',
    description: 'NavigationBar: Company > Links Navigation',
    defaultMessage: 'Tools',
  },
})
