import PropTypes from 'prop-types'

import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  Nav,
  Navbar,
  NavItem,
  NavbarBrand,
  Container,
  NavLink,
  NavDropdown,
  Dropdown,
} from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'

import { logout } from 'containers/App/actions'
import {
  selectUserDefaultPage,
  selectUserIsLoggedIn,
  selectUserUsername,
  selectClaims,
} from 'containers/App/selectors'
import selectCustomers from 'containers/Customers/selectors'
import selectCustomer from 'containers/Customer/selectors'
import selectCompanies from 'containers/Companies/selectors'
import Icon from 'components/Icon'
import CustomerCompanyDropdown from 'components/CustomerCompanyDropdown'
import UserDropdown from 'components/UserDropdown'
import IfClaim from 'containers/IfClaim'
import { createPathPrefix } from 'utils/context'

import { selectError, selectLoading } from 'containers/CompanyIndex/selectors'
import messages from './messages'
import styles from './styles.module.scss'

import SettingsMenu from 'components/SettingsMenu'
import withRouterProps from 'utils/withRouterProps'
import Notifications from 'containers/Notifications'
import selectCompany from 'containers/Company/selectors'
import { selectUserProfiles, selectUserId } from 'containers/App/selectors'

const Header = ({ customerCode, customers, defaultPage }) => {
  const customLogoUrl = customers?.find(
    (customer) => customer.code === customerCode
  )?.customLogoUrl

  let linkTo = '/'

  const customer = customers?.first()
  const companies = customer?.get('companies')

  if (defaultPage) {
    linkTo = defaultPage
  } else if (customer && companies && companies.size === 1) {
    linkTo = `/${customer.get('code')}/${companies.first().get('code')}`
  }

  return (
    <NavbarBrand className={styles.logoContainer}>
      <Link
        to={linkTo}
        className={customLogoUrl ? styles.customLogo : styles.mainLogo}
        style={{
          ...(customLogoUrl && {
            backgroundImage: `url(${customLogoUrl})`,
          }),
        }}
      >
        <i>Finazilla</i>
      </Link>
    </NavbarBrand>
  )
}

const SelectCustomerDropdown = (props) => {
  const { customers, customerCode, defaultTitle, header, navigate } = props
  const location = useLocation()

  const hasSingleCustomer = customers?.size === 1
  useEffect(() => {
    if (!hasSingleCustomer) return
    if (!customerCode) {
      const customer = customers.first()
      if (location.pathname.includes('reports-beta')) {
        navigate(`${customer.code}/reports-beta`)
        return
      }
      if (location.pathname.includes('reports')) {
        navigate(`${customer.code}/reports`)
        return
      }
      navigate(`${customer.code}/dashboards`)
    }
  }, [customerCode, customers, navigate, hasSingleCustomer, location.pathname])

  if (hasSingleCustomer) {
    return <span />
  }

  const items = customers.map((customer) => ({
    code: customer.code,
    name: customer.name,
    linkTo: `/${customer.code}`,
  }))

  return (
    <CustomerCompanyDropdown
      id="select-customer-dropdown"
      defaultTitle={defaultTitle}
      items={items}
      selectedItemCode={customerCode}
      navigate={navigate}
      header={header}
    />
  )
}

SelectCustomerDropdown.propTypes = {
  customers: ImmutablePropTypes.list,
  customerCode: PropTypes.string,
  defaultTitle: PropTypes.string,
  header: PropTypes.string,
  navigate: PropTypes.func,
}

const SelectCompanyDropdown = (props) => {
  const {
    customerCode,
    companies,
    companyCode,
    defaultTitle,
    navigate,
    header,
  } = props
  const items =
    companies &&
    companies.map((company) => ({
      code: company.code,
      name: company.name,
      linkTo: `/${customerCode}/${company.code}`,
    }))
  return (
    <CustomerCompanyDropdown
      id="select-company-dropdown"
      defaultTitle={defaultTitle}
      items={items}
      selectedItemCode={companyCode}
      navigate={navigate}
      header={header}
    />
  )
}

SelectCompanyDropdown.propTypes = {
  companies: ImmutablePropTypes.list,
  customerCode: PropTypes.string,
  companyCode: PropTypes.string,
  defaultTitle: PropTypes.string,
  header: PropTypes.string,
  navigate: PropTypes.func,
}

const LoginButton = (props) => (
  <NavItem>
    <Link to="/loginModal">{props.text}</Link>
  </NavItem>
)
LoginButton.propTypes = {
  text: PropTypes.string,
}

function NavigationBar(props) {
  const {
    user,
    customers,
    companies,
    params: { companyCode, customerCode },
    intl: { formatMessage },
  } = props

  const hasCompanies = customers.map((t) => t.companies).size > 0

  const isCompanyDropdownActive =
    customerCode && companyCode && user.permissions && customers && companies
  const reportsLink = `${createPathPrefix(
    customerCode,
    companyCode
  )}${'reports-beta'}`

  const dashboardsLink = `${createPathPrefix(
    customerCode,
    companyCode
  )}dashboards`
  const budgetsLink = `${createPathPrefix(customerCode, companyCode)}budgets`
  const cashFlowLink = `${createPathPrefix(customerCode, companyCode)}cashflow`
  const profileLink = `${createPathPrefix(customerCode, companyCode)}users/${
    user.userId
  }`
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const guessRoute = () => {
    if (
      pathname.includes('reports') &&
      !pathname.includes('reportschemetemplates')
    )
      return reportsLink
    if (pathname.includes('dashboards')) return dashboardsLink
    if (pathname.includes('budgets')) return budgetsLink
    if (pathname.includes('cashflow')) return cashFlowLink
    return 'none'
  }
  const activeKey = guessRoute()
  const [linksOpen, setLinksOpen] = useState(false)
  const navigateAndSetActive = (key) => navigate(key)
  const onLogout = () => dispatch(logout(navigate))
  const [settingsOpen, setSettingsOpen] = useState(false)
  const customer = useSelector((state) =>
    selectCustomer(state, { customerCode })
  )
  const company = useSelector((state) =>
    selectCompany(state, { params: { companyCode, customerCode } })
  )

  const subUsers = useSelector(selectUserProfiles)
  const isLocal = ({ url }) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      return new URL(url)?.origin === location.origin
    } catch (e) {
      return false
    }
  }

  const onSettings = () => {
    navigate(profileLink)
  }

  const goToUrl = ({ url }) => {
    try {
      const newURL = new URL(url)
      // eslint-disable-next-line no-restricted-globals
      if (newURL.origin === location.origin)
        navigate({
          pathname: newURL.pathname,
          search: newURL.search,
        })
      else window.open(newURL.href)
    } catch (e) {
      console.warn(e)
    }
  }

  const { customNavbarBackgroundColor, customNavbarColor } = customer || {}
  const customNavbarStyles = {
    ...(customNavbarBackgroundColor && {
      '--fz-navbar-background-color': customNavbarBackgroundColor,
    }),
    ...(customNavbarColor && {
      '--fz-navbar-dropdown-color': customNavbarColor,
    }),
  }

  // TODO: Check permissions for CustomerNav & SystemNav
  return (
    <div>
      <Navbar
        expand="md"
        variant="dark"
        role="navigation"
        className={styles.navBar}
        style={customNavbarStyles}
      >
        <Header
          customerCode={customerCode}
          customers={customers}
          defaultPage={user.defaultPage}
        />
        <Navbar.Toggle className={styles.toggle} />
        <Navbar.Collapse
          className={styles.allowWrap}
          data-html2canvas-ignore="true"
        >
          <Nav
            onSelect={(key) => key !== '' && navigateAndSetActive(key)}
            activeKey={activeKey}
          >
            <NavItem className={`${settingsOpen ? 'active' : ''} active`}>
              <SettingsMenu
                user={user}
                open={settingsOpen}
                setOpen={setSettingsOpen}
                customerCode={customerCode}
                companyCode={companyCode}
                isCompanyDropdownActive={isCompanyDropdownActive}
                navigate={navigateAndSetActive}
              />
            </NavItem>
            {companyCode &&
              company &&
              company.links &&
              company.links.length > 0 && (
                <NavDropdown
                  title={
                    company.linksNavigationTitle ||
                    formatMessage(messages.linksNavigation)
                  }
                  className={`${styles.linksDropdown} ${
                    linksOpen ? styles.active : ''
                  }`}
                  key={`company-links`}
                  open={linksOpen}
                  onToggle={(isOpen) => setLinksOpen(isOpen)}
                >
                  {company.links.map((link, index) => (
                    <Dropdown.Item
                      onClick={() => goToUrl({ url: link.url })}
                      target="_blank"
                      eventKey={''}
                      key={`link-${index}`}
                    >
                      {link.name}{' '}
                      {!isLocal({ url: link.url }) && (
                        <Icon name="external-link" set="fa" />
                      )}
                    </Dropdown.Item>
                  ))}
                </NavDropdown>
              )}
            {companyCode && (
              <>
                <NavLink
                  eventKey={budgetsLink}
                  className={`${styles.leftSideItem} ${
                    budgetsLink === activeKey ? styles.active : ''
                  } `}
                >
                  <FormattedMessage {...messages.budgets} />
                </NavLink>
                <IfClaim claim="CashFlowRead" propsToPass={{}}>
                  <NavLink
                    eventKey={cashFlowLink}
                    className={`${styles.leftSideItem} ${
                      cashFlowLink === activeKey ? styles.active : ''
                    } `}
                  >
                    <FormattedMessage {...messages.cashFlow} />
                  </NavLink>
                </IfClaim>
              </>
            )}
            {hasCompanies && (
              <NavLink
                eventKey={reportsLink}
                className={`${styles.leftSideItem} ${
                  reportsLink === activeKey ? styles.active : ''
                } `}
              >
                <FormattedMessage {...messages.reports} />
              </NavLink>
            )}
            {companyCode ? (
              <NavLink
                eventKey={dashboardsLink}
                className={`${styles.leftSideItem} ${
                  dashboardsLink === activeKey ? styles.active : ''
                } `}
              >
                <FormattedMessage {...messages.dashboards} />
              </NavLink>
            ) : null}
            <NavLink
              href="https://intercom.help/finazilla"
              target="_blank"
              eventKey={''}
              className={styles.leftSideItem}
            >
              <div>
                <FormattedMessage {...messages.helpCenter} />{' '}
                <Icon name="external-link" set="fa" />
              </div>
            </NavLink>
          </Nav>
          <Nav className={styles.pullRight}>
            {user.isLoggedIn && (
              <SelectCustomerDropdown
                customers={customers}
                customerCode={customerCode}
                defaultTitle={formatMessage(messages.selectCustomer)}
                header={`${formatMessage(messages.customer)}${
                  customerCode ? ` / ${customerCode}` : ''
                }`}
                navigate={navigateAndSetActive}
              />
            )}
            {user.isLoggedIn && (
              <SelectCompanyDropdown
                companies={companies}
                customerCode={customerCode}
                companyCode={companyCode}
                defaultTitle={formatMessage(messages.selectCompany)}
                header={`${formatMessage(messages.company)}${
                  companyCode ? ` / ${companyCode}` : ''
                }`}
                navigate={navigateAndSetActive}
              />
            )}
            {user.isLoggedIn && user.username && (
              <UserDropdown
                username={user.username}
                onSettings={onSettings}
                onLogout={onLogout}
                header={formatMessage(messages.loggedinas)}
                logoutText={formatMessage(messages.logout)}
                getUserInfo={props.onGetUserInfo}
                settingsText={formatMessage(messages.settings)}
                subUsers={subUsers ?? []}
              />
            )}
            {user.isLoggedIn && (
              <Notifications
                user={user}
                customerCode={customerCode}
                companyCode={companyCode}
              />
            )}
            {!user.isLoggedIn && (
              <LoginButton text={formatMessage(messages.login)} />
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container className={styles.navBarBottom} />
    </div>
  )
}

NavigationBar.propTypes = {
  intl: intlShape,
  customers: ImmutablePropTypes.list,
  companies: ImmutablePropTypes.list,
  selectedCompany: ImmutablePropTypes.record,
  selectedCustomer: ImmutablePropTypes.record,
  user: PropTypes.object,
}

const mapStateToProps = () =>
  createStructuredSelector({
    customers: selectCustomers,
    companies: selectCompanies,
    error: selectError,
    loading: selectLoading,
    user: createStructuredSelector({
      defaultPage: selectUserDefaultPage,
      isLoggedIn: selectUserIsLoggedIn,
      permissions: selectClaims,
      username: selectUserUsername,
      userId: selectUserId,
    }),
  })

export default withRouterProps(
  injectIntl(
    connect(
      mapStateToProps,
      null
    )(NavigationBar)
  )
)
