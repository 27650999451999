/*
 *
 * BudgetBalanceModal constants
 *
 */

export const IMPORT_BUDGET_BALANCES =
  'app/BudgetBalanceModal/IMPORT_BUDGET_BALANCES'
export const IMPORT_BUDGET_BALANCES_SUCCESS =
  'app/BudgetBalanceModal/IMPORT_BUDGET_BALANCES_SUCCESS'
export const GET_BUDGET_BALANCES_PREVIEW =
  'app/BudgetBalanceModal/GET_BUDGET_BALANCES_PREVIEW'
export const GET_BUDGET_BALANCES_PREVIEW_SUCCESS =
  'app/BudgetBalanceModal/GET_BUDGET_BALANCES_PREVIEW_SUCCESS'

export const IMPORT_BUDGET_BALANCES_ERROR =
  'app/BudgetBalanceModal/IMPORT_BUDGET_BALANCES_ERROR'

export const ALL_DIMENSION_TARGETS = 'allDimensionTargets'
export const SINGLE_DIMENSION_TARGET = 'singleDimensionTarget'
export const SUM_OF_DIMENSIONS_TO_TARGET = 'dimensionsTotal'
export const BUDGET_BALANCE_IMPORT_MODES = [
  SINGLE_DIMENSION_TARGET,
  ALL_DIMENSION_TARGETS,
]

export const SOURCE_DATA_TYPE_ACTUALS = 'Actuals'
export const SOURCE_DATA_TYPE_ACTUALS_DIMENSIONS_TOTAL =
  'ActualsDimensionsTotal'
export const SOURCE_DATA_TYPE_BUDGET = 'Budget'
export const SOURCE_DATA_TYPE_BUDGET_DIMENSIONS_TOTAL = 'BudgetDimensionsTotal'
export const SOURCE_DATA_TYPES = [
  SOURCE_DATA_TYPE_ACTUALS,
  SOURCE_DATA_TYPE_ACTUALS_DIMENSIONS_TOTAL,
  SOURCE_DATA_TYPE_BUDGET,
  SOURCE_DATA_TYPE_BUDGET_DIMENSIONS_TOTAL,
]

export const SINGLE_TARGET_DATA_SOURCE = 'SINGLE_TARGET_DATA_SOURCE'
export const MULTI_TARGET_DATA_SOURCE = 'MULTI_TARGET_DATA_SOURCE'
export const DATA_SOURCE_DIMENSION_MODES = [
  SINGLE_TARGET_DATA_SOURCE,
  MULTI_TARGET_DATA_SOURCE,
]

export const TRANSFORMATION_STATIC_ADDITION = 'staticAddition'
export const TRANSFORMATION_MULTIPLIER = 'multiplier'

export const TRANSFORMATION_TYPES = [
  TRANSFORMATION_STATIC_ADDITION,
  TRANSFORMATION_MULTIPLIER,
]

export const MAXIMUM_DATA_SOURCE_COUNT = 10
