import {
  GET_INVOICES,
  GET_INVOICES_ERROR,
  GET_INVOICES_SUCCESS,
  GET_CASH_FLOW,
  GET_CASH_FLOW_ERROR,
  GET_CASH_FLOW_SUCCESS,
  GET_INVOICES_BY_DATE,
  GET_INVOICES_BY_DATE_ERROR,
  GET_INVOICES_BY_DATE_SUCCESS,
  GET_EXCLUDED_DAYS,
  GET_EXCLUDED_DAYS_ERROR,
  GET_EXCLUDED_DAYS_SUCCESS,
  SET_EXCLUDED_DAYS,
  SET_EXCLUDED_DAYS_ERROR,
  SET_EXCLUDED_DAYS_SUCCESS,
  GET_EXCLUDED_INVOICES,
  GET_EXCLUDED_INVOICES_ERROR,
  GET_EXCLUDED_INVOICES_SUCCESS,
  SET_EXCLUDED_INVOICES,
  SET_EXCLUDED_INVOICES_ERROR,
  SET_EXCLUDED_INVOICES_SUCCESS,
} from './constants'

export const getCashFlow = ({ companyCode }) => ({
  type: GET_CASH_FLOW,
  companyCode,
})

export const getCashFlowError = (error) => ({
  type: GET_CASH_FLOW_ERROR,
  error,
})

export const getCashFlowSuccess = ({ cashFlow }) => ({
  type: GET_CASH_FLOW_SUCCESS,
  cashFlow,
})

export const getInvoicesByDate = ({ companyCode, date }) => ({
  type: GET_INVOICES_BY_DATE,
  companyCode,
  date,
})

export const getInvoicesByDateError = () => ({
  type: GET_INVOICES_BY_DATE_ERROR,
})

export const getInvoicesByDateSuccess = (invoices) => ({
  type: GET_INVOICES_BY_DATE_SUCCESS,
  invoices,
})

export const getInvoices = ({ companyCode, startDate, endDate }) => ({
  type: GET_INVOICES,
  companyCode,
  startDate,
  endDate,
})

export const getInvoicesError = () => ({
  type: GET_INVOICES_ERROR,
})

export const getInvoicesSuccess = (invoices) => ({
  type: GET_INVOICES_SUCCESS,
  invoices,
})

export const getExcludedDays = ({ companyCode }) => ({
  type: GET_EXCLUDED_DAYS,
  companyCode,
})

export const getExcludedDaysError = () => ({
  type: GET_EXCLUDED_DAYS_ERROR,
})

export const getExcludedDaysSuccess = (excludedDays) => ({
  type: GET_EXCLUDED_DAYS_SUCCESS,
  excludedDays,
})

export const setExcludedDays = ({ companyCode, days }) => ({
  type: SET_EXCLUDED_DAYS,
  companyCode,
  days,
})

export const setExcludedDaysError = () => ({
  type: SET_EXCLUDED_DAYS_ERROR,
})

export const setExcludedDaysSuccess = (excludedDays) => ({
  type: SET_EXCLUDED_DAYS_SUCCESS,
  excludedDays,
})

export const getExcludedInvoices = ({ companyCode }) => ({
  type: GET_EXCLUDED_INVOICES,
  companyCode,
})

export const getExcludedInvoicesError = () => ({
  type: GET_EXCLUDED_INVOICES_ERROR,
})

export const getExcludedInvoicesSuccess = (excludedInvoices) => ({
  type: GET_EXCLUDED_INVOICES_SUCCESS,
  excludedInvoices,
})

export const setExcludedInvoices = ({ companyCode, sourceInvoiceIds }) => ({
  type: SET_EXCLUDED_INVOICES,
  companyCode,
  sourceInvoiceIds,
})

export const setExcludedInvoicesError = () => ({
  type: SET_EXCLUDED_INVOICES_ERROR,
})

export const setExcludedInvoicesSuccess = (excludedInvoices) => ({
  type: SET_EXCLUDED_INVOICES_SUCCESS,
  excludedInvoices,
})
