import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.containers.SubBudgetDuplicateModal.header',
    defaultMessage: 'Duplicate Row',
  },
  duplicationMode: {
    id: 'app.containers.SubBudgetDuplicateModal.duplicationMode',
    defaultMessage: 'Duplication Mode',
  },
  copySingle: {
    id: 'app.components.SubBudgetDuplicateModal.copySingle',
    defaultMessage: 'Single copy',
  },
  copyValues: {
    id: 'app.components.SubBudgetDuplicateModal.copyMode',
    defaultMessage: 'Copy for dimension values',
  },
  copyMatrixes: {
    id: 'app.components.SubBudgetDuplicateModal.copyMatrixes',
    defaultMessage: 'Duplicate for matrixes',
  },
  name: {
    id: 'app.components.SubBudgetDuplicateModal.name',
    defaultMessage: 'Name',
  },
  typeName: {
    id: 'app.components.SubBudgetDuplicateModal.typeName',
    defaultMessage: 'Type name',
  },
  duplicateForDimensionValues: {
    id: 'app.components.SubBudgetDuplicateModal.duplicateForDimensionValues',
    description: 'Generate rows for these',
    defaultMessage: 'Generate new row for dimension values',
  },
  targetDimension: {
    id: 'app.components.SubBudgetDuplicateModal.targetDimension',
    defaultMessage: 'Target duplicated rows to selected dimension value',
  },
  overRecommendedSize: {
    id: 'app.components.SubBudgetDuplicateModal.overRecommendedSize',
    defaultMessage:
      'Duplication will create larger than recommended sub budget. Splitting sub budget into multiple smaller ones is recommended',
  },
})
