/*
 *
 * Report reducer
 *
 */

import { fromJS } from 'immutable'
import {
  DISMISS_NOTIFICATION,
  DELETE_NOTIFICATION,
  DISMISS_ALL_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  DISMISS_ALL_NOTIFICATIONS_ERROR,
  DISMISS_ALL_NOTIFICATIONS_SUCCESS,
} from './constants'

const initialState = fromJS({
  notifications: undefined,
  error: false,
  loading: false,
})

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISMISS_ALL_NOTIFICATIONS:
      return state.set('loading', true).set('error', false)
    case DISMISS_ALL_NOTIFICATIONS_ERROR:
      return state.set('loading', false).set('error', action.error)
    case DISMISS_ALL_NOTIFICATIONS_SUCCESS:
      return state.set('loading', false).delete('notifications')
    case DISMISS_NOTIFICATION:
    case DELETE_NOTIFICATION:
      return state.update('notifications', (notifications) =>
        notifications.filter(
          (notification) => notification.id !== action.notificationId
        )
      )
    case GET_NOTIFICATIONS_SUCCESS:
      return state.set('notifications', action.notifications)
    default:
      return state
  }
}

export default notificationReducer
