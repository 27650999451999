import { Record } from 'immutable'

const ScheduledIntegrationRecord = Record({
  id: undefined,
  lastExecution: undefined,
  lastJobState: undefined,
  nextExecution: undefined,
})

export default ScheduledIntegrationRecord
