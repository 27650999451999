/*
 *
 * ReportingGroups constants
 *
 */

export const ADD_DIMENSIONS_TO_REPORTING_GROUP =
  'app/ReportingGroups/ADD_DIMENSIONS_TO_REPORTING_GROUP'
export const ADD_DIMENSIONS_TO_REPORTING_GROUP_ERROR =
  'app/ReportingGroups/ADD_DIMENSIONS_TO_REPORTING_GROUP_ERROR'
export const ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS =
  'app/ReportingGroups/ADD_DIMENSIONS_TO_REPORTING_GROUP_SUCCESS'

export const ADD_ROW = 'app/ReportingGroups/ADD_ROW'

export const EDIT_ROW = 'app/ReportingGroups/EDIT_ROW'

export const GET_REPORTING_GROUPS = 'app/ReportingGroups/GET_REPORTING_GROUPS'
export const GET_REPORTING_GROUPS_ERROR =
  'app/ReportingGroups/GET_REPORTING_GROUPS_ERROR'
export const GET_REPORTING_GROUPS_SUCCESS =
  'app/ReportingGroups/GET_REPORTING_GROUPS_SUCCESS'

export const DELETE_REPORTING_GROUP =
  'app/ReportingGroups/DELETE_REPORTING_GROUP'
export const DELETE_REPORTING_GROUP_ERROR =
  'app/ReportingGroups/DELETE_REPORTING_GROUP_ERROR'
export const DELETE_REPORTING_GROUP_SUCCESS =
  'app/ReportingGroups/DELETE_REPORTING_GROUP_SUCCESS'

export const CREATE_REPORTING_GROUP =
  'app/ReportingGroups/CREATE_REPORTING_GROUP'
export const CREATE_REPORTING_GROUP_ERROR =
  'app/ReportingGroups/CREATE_REPORTING_GROUP_ERROR'
export const CREATE_REPORTING_GROUP_SUCCESS =
  'app/ReportingGroups/CREATE_REPORTING_GROUP_SUCCESS'

export const REMOVE_DIMENSION = 'app/ReportingGroups/REMOVE_DIMENSION'
export const REMOVE_DIMENSION_ERROR =
  'app/ReportingGroups/REMOVE_DIMENSION_ERROR'
export const REMOVE_DIMENSION_SUCCESS =
  'app/ReportingGroups/REMOVE_DIMENSION_SUCCESS'

export const REMOVE_DIMENSION_VALUE =
  'app/ReportingGroups/REMOVE_DIMENSION_VALUE'
export const REMOVE_DIMENSION_VALUE_ERROR =
  'app/ReportingGroups/REMOVE_DIMENSION_VALUE_ERROR'
export const REMOVE_DIMENSION_VALUE_SUCCESS =
  'app/ReportingGroups/REMOVE_DIMENSION_VALUE_SUCCESS'

export const TOGGLE_SHOW_REPORTING_GROUP_FORM =
  'app/ReportingGroups/TOGGLE_SHOW_REPORTING_GROUP_FORM'

export const TOGGLE_COLLAPSE = 'app/ReportingGroups/TOGGLE_COLLAPSE'

export const UPDATE_REPORTING_GROUP =
  'app/ReportingGroups/UPDATE_REPORTING_GROUP'
export const UPDATE_REPORTING_GROUP_ERROR =
  'app/ReportingGroups/UPDATE_REPORTING_GROUP_ERROR'
export const UPDATE_REPORTING_GROUP_SUCCESS =
  'app/ReportingGroups/UPDATE_REPORTING_GROUP_SUCCESS'
