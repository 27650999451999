import React, { useState, useEffect } from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import CompanyDropdownMenu from './CompanyDropdownMenu'
import CustomerDropdownMenu from './CustomerDropdownMenu'
import SystemDropdownMenu from './SystemDropdownMenu'
import { HasClaim } from 'utils/Authorization'
import styles from './styles.module.scss'

const SettingsMenu = ({
  user,
  customerCode,
  companyCode,
  isCompanyDropdownActive,
  navigate,
}) => {
  const [open, setOpen] = useState(false)
  const [systemClaim, setSystemClaim] = useState(
    HasClaim({
      permissions: user.permissions,
      claim: 'System',
      allowAll: false,
    })
  )
  const [customerClaim, setCustomerClaim] = useState(
    customerCode &&
      HasClaim({
        permissions: user.permissions,
        claim: 'Company',
        value: '*',
        allowAll: false,
      })
  )
  const [companyClaim, setCompanyClaim] = useState(
    customerCode &&
      isCompanyDropdownActive &&
      HasClaim({
        permissions: user.permissions,
        claim: 'Company',
        allowAll: false,
      })
  )

  useEffect(() => {
    setSystemClaim(
      HasClaim({
        permissions: user.permissions,
        claim: 'System',
        allowAll: false,
      })
    )

    setCustomerClaim(
      customerCode &&
        HasClaim({
          permissions: user.permissions,
          claim: 'Company',
          value: '*',
          allowAll: false,
        })
    )

    setCompanyClaim(
      customerCode &&
        isCompanyDropdownActive &&
        HasClaim({
          permissions: user.permissions,
          claim: 'Company',
          allowAll: false,
        })
    )
  }, [customerCode, isCompanyDropdownActive, user.permissions])

  const handleToggle = (toggle) => {
    setOpen(toggle)
  }

  if (!systemClaim && !customerClaim && !companyClaim) {
    return null
  }
  if (systemClaim && !customerClaim) {
    return (
      <Nav>
        <SystemDropdownMenu setOpen={setOpen} open={open} />
      </Nav>
    )
  }
  if (customerClaim && !systemClaim && !companyClaim) {
    return (
      <Nav>
        <CustomerDropdownMenu
          customerCode={customerCode}
          setOpen={setOpen}
          open={open}
          permission={user.permission}
        />
      </Nav>
    )
  }
  if (companyClaim && !systemClaim && !customerClaim) {
    return (
      <Nav>
        <CompanyDropdownMenu
          permissions={user.permissions}
          customerCode={customerCode}
          companyCode={companyCode}
          setOpen={setOpen}
          open={open}
          navigate={navigate}
        />
      </Nav>
    )
  }

  return (
    <NavDropdown
      id="settings-dropdown"
      title="Settings"
      onToggle={handleToggle}
      open={open}
      className={`${styles.settingsTitleInNav} ${open ? styles.active : ''}`}
    >
      {systemClaim && customerClaim && (
        <SystemDropdownMenu setOpen={setOpen} hasParent />
      )}

      {customerCode && customerClaim && (systemClaim || companyClaim) && (
        <CustomerDropdownMenu
          customerCode={customerCode}
          setOpen={setOpen}
          hasParent
        />
      )}

      {companyCode && companyClaim && (systemClaim || customerClaim) && (
        <CompanyDropdownMenu
          permissions={user.permissions}
          customerCode={customerCode}
          companyCode={companyCode}
          setOpen={setOpen}
          navigate={navigate}
          hasParent
        />
      )}
    </NavDropdown>
  )
}

export default SettingsMenu
