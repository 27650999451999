import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

import messages from './messages'
import styles from './LoadingCircle.module.css'

const propTypes = {
  className: PropTypes.string,
}

const LoadingCircle = ({
  className,
  intl: { formatMessage },
  size,
  dataTestingId,
}) => (
  <div
    aria-label={formatMessage(messages.labelLoading)}
    className={classNames(
      styles[size === 'small' ? 'sk-fading-circle-small' : 'sk-fading-circle'],
      className
    )}
    data-testing-id={dataTestingId || 'loading-indicator'}
  >
    <div className={styles.skCircle} />
    <div className={styles['sk-circle2']} />
    <div className={styles['sk-circle3']} />
    <div className={styles['sk-circle4']} />
    <div className={styles['sk-circle5']} />
    <div className={styles['sk-circle6']} />
    <div className={styles['sk-circle7']} />
    <div className={styles['sk-circle8']} />
    <div className={styles['sk-circle9']} />
    <div className={styles['sk-circle10']} />
    <div className={styles['sk-circle11']} />
    <div className={styles['sk-circle12']} />
  </div>
)
LoadingCircle.propTypes = propTypes

export default injectIntl(LoadingCircle)
