import { Record } from 'immutable'

const AccountRecord = Record({
  accountNumber: '',
  isConsolidatedAccount: false,
  targetCompanyId: null,
  name: '',
  reportingCode: '',
  id: '',
})

export default AccountRecord
