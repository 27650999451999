import { createSelector } from 'reselect'
import { List } from 'immutable'
import { alphabeticallyAscendingComparer } from 'utils/sort'
import { flattenChildren } from 'utils/schemeGroupUtils'

/**
 * Direct selector to the budgets state domain
 */
const selectBudgetsDomain = () => (state) => state.get('budgets')

const companyFilter = (state, props) => {
  const paramsCompanyCode = props.params && props.params.companyCode
  const companyCode = props.companyCode
  return paramsCompanyCode || companyCode
}
const budgetFilter = (state, props) =>
  props?.params?.budgetId || props?.budgetId

const selectAllBudgets = () =>
  createSelector(
    selectBudgetsDomain(),
    (budgets) => budgets.get('budgets')
  )
const selectCompanyBudgets = createSelector(
  [selectAllBudgets(), companyFilter],
  (budgets, companyCode) =>
    budgets?.get(companyCode)?.sort(alphabeticallyAscendingComparer)
)
const selectBudgetTree = createSelector(
  [selectCompanyBudgets, budgetFilter],
  (companyBudgets, budgetId) => {
    return flattenChildren(
      companyBudgets?.find((bud) => bud.id === budgetId)?.get('tree')
    )
  }
)
const selectAllBudgetFolders = createSelector(
  selectBudgetsDomain(),
  (budgets) => budgets.get('folders')
)

const selectBudgets = createSelector(
  [selectAllBudgets(), companyFilter],
  (budgets, companyCode) => {
    const companyBudgets = budgets.get(companyCode)
    return companyBudgets
      ? companyBudgets.sort(alphabeticallyAscendingComparer)
      : new List()
  }
)

const selectBudgetFolders = createSelector(
  [selectAllBudgetFolders, companyFilter],
  (folders, companyCode) => {
    const companyBudgets = folders.get(companyCode)
    return companyBudgets
      ? companyBudgets.sort(alphabeticallyAscendingComparer)
      : null
  }
)

const selectError = createSelector(
  selectBudgetsDomain(),
  (budgets) => budgets.get('error')
)

const selectLoading = createSelector(
  selectBudgetsDomain(),
  (budgets) => budgets.get('loading')
)

const selectLoadingTree = createSelector(
  selectBudgetsDomain(),
  (budgets) => budgets.get('loadingTree')
)

/**
 * Default selector used by Budgets
 */

export default selectBudgets
export {
  selectBudgetTree,
  selectLoadingTree,
  selectAllBudgets,
  selectCompanyBudgets,
  selectBudgets,
  selectBudgetsDomain,
  selectBudgetFolders,
  selectError,
  selectLoading,
}
