export const GET_INVOICES = 'app/CashFlow/GET_INVOICES'
export const GET_INVOICES_SUCCESS = 'app/CashFlow/GET_INVOICES_SUCCESS'
export const GET_INVOICES_ERROR = 'app/CashFlow/GET_INVOICES_ERROR'

export const GET_CASH_FLOW = 'app/CashFlow/GET_CASH_FLOW'
export const GET_CASH_FLOW_ERROR = 'app/CashFlow/GET_CASH_FLOW_ERROR'
export const GET_CASH_FLOW_SUCCESS = 'app/CashFlow/GET_CASH_FLOW_SUCCESS'

export const GET_INVOICES_BY_DATE = 'app/CashFlow/GET_INVOICES_BY_DATE'
export const GET_INVOICES_BY_DATE_ERROR =
  'app/CashFlow/GET_INVOICES_BY_DATE_ERROR'
export const GET_INVOICES_BY_DATE_SUCCESS =
  'app/CashFlow/GET_INVOICES_BY_DATE_SUCCESS'

export const GET_EXCLUDED_DAYS = 'app/CashFlow/GET_EXCLUDED_DAYS'
export const GET_EXCLUDED_DAYS_ERROR = 'app/CashFlow/GET_EXCLUDED_DAYS_ERROR'
export const GET_EXCLUDED_DAYS_SUCCESS =
  'app/CashFlow/GET_EXCLUDED_DAYS_SUCCESS'

export const SET_EXCLUDED_DAYS = 'app/CashFlow/SET_EXCLUDED_DAYS'
export const SET_EXCLUDED_DAYS_ERROR = 'app/CashFlow/SET_EXCLUDED_DAYS_ERROR'
export const SET_EXCLUDED_DAYS_SUCCESS =
  'app/CashFlow/SET_EXCLUDED_DAYS_SUCCESS'

export const GET_EXCLUDED_INVOICES = 'app/CashFlow/GET_EXCLUDED_INVOICES'
export const GET_EXCLUDED_INVOICES_ERROR =
  'app/CashFlow/GET_EXCLUDED_INVOICES_ERROR'
export const GET_EXCLUDED_INVOICES_SUCCESS =
  'app/CashFlow/GET_EXCLUDED_INVOICES_SUCCESS'

export const SET_EXCLUDED_INVOICES = 'app/CashFlow/SET_EXCLUDED_INVOICES'
export const SET_EXCLUDED_INVOICES_ERROR =
  'app/CashFlow/SET_EXCLUDED_INVOICES_ERROR'
export const SET_EXCLUDED_INVOICES_SUCCESS =
  'app/CashFlow/SET_EXCLUDED_INVOICES_SUCCESS'
