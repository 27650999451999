import { jwtDecode } from 'jwt-decode'

export const Keys = {
  ACCESS_TOKEN: 'finazilla_access_token',
  REFRESH_TOKEN: 'finazilla_refresh_token',
  PROFILE_ID: 'finazilla_profile_id',
}
/**
 * Use these functions only when you cannot use redux state and selectors.
 */

/**
 * @returns oid from identity provider
 */
const getUserId = () => {
  const { access_token } = getTokens()
  const userToken = decodeToken(access_token)
  return userToken.oid
}
const getIssuer = () => {
  const { access_token } = getTokens()
  const userToken = decodeToken(access_token)
  return userToken.iss
}
const setProfileId = (profileId) => {
  const currentProfileId = getProfileId()
  if (currentProfileId === profileId) return
  localStorage.setItem(Keys.PROFILE_ID, profileId)
}
const setTokens = (access_token, refresh_token) => {
  setAccessToken(access_token)
  localStorage.setItem(Keys.REFRESH_TOKEN, refresh_token)
}

const setAccessToken = (access_token) => {
  localStorage.setItem(Keys.ACCESS_TOKEN, access_token)
}
const clearProfileToken = () => {
  localStorage.removeItem(Keys.PROFILE_ID)
}
const clearTokens = () => {
  localStorage.removeItem(Keys.ACCESS_TOKEN)
  localStorage.removeItem(Keys.REFRESH_TOKEN)
  clearProfileToken()
}
const getProfileId = () => {
  return localStorage.getItem(Keys.PROFILE_ID)
}
const getTokens = () => ({
  access_token: localStorage.getItem(Keys.ACCESS_TOKEN),
  refresh_token: localStorage.getItem(Keys.REFRESH_TOKEN),
})
const getIdentity = () => {
  try {
    const { access_token } = getTokens()
    const userToken = decodeToken(access_token)
    return userToken?.email || userToken?.name || userToken?.emails?.[0]
  } catch (err) {
    return null
  }
}
/**
 * @returns false when access token has expired, at least one token is missing or something is broken
 */
const isLoggedIn = () => {
  const tokens = getTokens()
  if (!tokens.access_token) return false
  try {
    const userToken = decodeToken(tokens.access_token)
    if (userToken.exp * 1000 < Date.now()) return false
  } catch (err) {
    console.log(err)
    return false
  }
  return tokens.access_token && tokens.refresh_token
}
/** Tells if current sign in was made using AD.  */
const isADToken = () => {
  try {
    const issuer = getIssuer()
    return issuer.includes('sts.windows.net')
  } catch (err) {
    return false
  }
}
const isLemonsoftToken = () => {
  try {
    const issuer = getIssuer()
    return issuer.includes('lemonsoft')
  } catch (err) {
    return false
  }
}

function decodeToken(token) {
  const parts = token?.split('.')
  if (parts?.length !== 3) {
    throw new Error('JWT must have 3 parts')
  }

  return jwtDecode(token)
}

const tokenStorage = {
  clearProfileToken,
  clearTokens,
  decodeToken,
  getProfileId,
  getTokens,
  getUserId,
  isADToken,
  isLemonsoftToken,
  isLoggedIn,
  setAccessToken,
  setProfileId,
  setTokens,
  getIdentity,
}
export default tokenStorage
