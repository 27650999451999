/*
 *
 * BudgetBalanceModal actions
 *
 */

import {
  IMPORT_BUDGET_BALANCES,
  IMPORT_BUDGET_BALANCES_ERROR,
  IMPORT_BUDGET_BALANCES_SUCCESS,
  GET_BUDGET_BALANCES_PREVIEW_SUCCESS,
  GET_BUDGET_BALANCES_PREVIEW,
} from './constants'

export const importBudgetBalances = ({
  budgetId,
  budgetSchemeRowId,
  companyCode,
  importMode,
  dimensionValues,
  additionAmount,
  multiplier,
  targetStart,
  targetEnd,
  dataTransformationMethod,
  dataSources,
}) => ({
  type: IMPORT_BUDGET_BALANCES,
  budgetId,
  budgetSchemeRowId,
  companyCode,
  importMode,
  dimensionValues,
  additionAmount,
  multiplier,
  targetStart,
  targetEnd,
  dataTransformationMethod,
  dataSources,
})

export const importBudgetBalancesSuccess = () => ({
  type: IMPORT_BUDGET_BALANCES_SUCCESS,
})

export const getBudgetBalancesPreview = ({
  budgetId,
  budgetSchemeRowId,
  companyCode,
  importMode,
  dimensionValues,
  additionAmount,
  multiplier,
  targetStart,
  targetEnd,
  dataTransformationMethod,
  dataSources,
}) => ({
  type: GET_BUDGET_BALANCES_PREVIEW,
  budgetId,
  budgetSchemeRowId,
  companyCode,
  importMode,
  dimensionValues,
  additionAmount,
  multiplier,
  targetStart,
  targetEnd,
  dataTransformationMethod,
  dataSources,
})

export const getBudgetBalancesPreviewSuccess = ({ data }) => ({
  type: GET_BUDGET_BALANCES_PREVIEW_SUCCESS,
  data,
})

export const importBudgetBalancesError = (error) => ({
  type: IMPORT_BUDGET_BALANCES_ERROR,
  error,
})
