/*
 *
 * AccountSchemeTemplate constants
 *
 */
export const ADD_CHILD_GROUP = 'app/AccountSchemeTemplate/ADD_CHILD_GROUP'
export const ADD_GROUP = 'app/AccountSchemeTemplate/ADD_GROUP'
export const ADD_GROUP_ERROR = 'app/AccountSchemeTemplate/ADD_GROUP_ERROR'
export const ADD_GROUP_SUCCESS = 'app/AccountSchemeTemplate/ADD_GROUP_SUCCESS'

export const ADD_ROW = 'app/AccountSchemeTemplate/ADD_ROW'
export const ADD_ROW_ERROR = 'app/AccountSchemeTemplate/ADD_ROW_ERROR'
export const ADD_ROW_SUCCESS = 'app/AccountSchemeTemplate/ADD_ROW_SUCCESS'

export const DELETE_ROW = 'app/AccountSchemeTemplate/DELETE_ROW'
export const DELETE_ROW_ERROR = 'app/AccountSchemeTemplate/DELETE_ROW_ERROR'
export const DELETE_ROW_SUCCESS = 'app/AccountSchemeTemplate/DELETE_ROW_SUCCESS'

export const DELETE_ROWS = 'app/AccountSchemeTemplate/DELETE_ROWS'

export const GET_ACCOUNT_SCHEME_TEMPLATE =
  'app/AccountSchemeTemplate/GET_ACCOUNT_SCHEME_TEMPLATE'
export const GET_ACCOUNT_SCHEME_TEMPLATE_ERROR =
  'app/AccountSchemeTemplate/GET_ACCOUNT_SCHEME_TEMPLATE_ERROR'
export const GET_ACCOUNT_SCHEME_TEMPLATE_SUCCESS =
  'app/AccountSchemeTemplate/GET_ACCOUNT_SCHEME_TEMPLATE_SUCCESS'

export const UPDATE_GROUP = 'app/AccountSchemeTemplate/UPDATE_GROUP'
export const UPDATE_GROUP_ERROR = 'app/AccountSchemeTemplate/UPDATE_GROUP_ERROR'
export const UPDATE_GROUP_SUCCESS =
  'app/AccountSchemeTemplate/UPDATE_GROUP_SUCCESS'

export const UPDATE_ROW = 'app/AccountSchemeTemplate/UPDATE_ROW'
export const UPDATE_ROW_ERROR = 'app/AccountSchemeTemplate/UPDATE_ROW_ERROR'
export const UPDATE_ROW_SUCCESS = 'app/AccountSchemeTemplate/UPDATE_ROW_SUCCESS'

export const FORM_TYPES = {
  accountGroup: 'accountGroup',
  accountRange: 'accountRange',
}

export const GROUP_TYPES = {
  subTotal: 'Subtotal',
}

export const ROW_TYPES = {
  header: 'Header',
}

export const AccountGroupTypes = [
  {
    text: 'Undefined',
    type: 'Undefined',
  },
  {
    text: 'Assets',
    type: 'Assets',
  },
  {
    text: 'Liabilities',
    type: 'Liabilities',
  },
  {
    text: 'Profit And Loss',
    type: 'ProfitAndLoss',
  },
  {
    text: 'Subtotal',
    type: 'Subtotal',
  },
]
