import { List } from 'immutable'

// Remove dimension from reporting group. It is done recursively,
// and the tree is traversed from top to bottom every time, even if
// the removed dimension was found from the first node.
export const removeDimension = (idToDelete, reportingGroup) => {
  const newDimensions = reportingGroup.reportingGroupDimensionIds.filter(
    (rgdId) => idToDelete !== rgdId
  )
  const newReportingGroup = reportingGroup.set(
    'reportingGroupDimensionIds',
    newDimensions
  )

  // do the same for all child reporting groups
  return newReportingGroup.set(
    'reportingGroups',
    reportingGroup.reportingGroups.map((g) => removeDimension(idToDelete, g))
  )
}

export const toggleCollapse = ({ toggleId, reportingGroup }) => {
  if (toggleId === reportingGroup.id) {
    return reportingGroup.set('isCollapsed', !reportingGroup.isCollapsed)
  }
  return reportingGroup.set(
    'reportingGroups',
    reportingGroup.reportingGroups.map((g) =>
      toggleCollapse({ toggleId, reportingGroup: g })
    )
  )
}

// Remove dimensionValue from reporting group. Does the same as above.
export const removeDimensionValue = (idToDelete, reportingGroup) => {
  const newDimensions = reportingGroup.reportingGroupDimensionValueIds.filter(
    (rgdvId) => rgdvId !== idToDelete
  )
  const newReportingGroup = reportingGroup.set(
    'reportingGroupDimensionValueIds',
    newDimensions
  )

  // do the same for all child reporting groups
  return newReportingGroup.set(
    'reportingGroups',
    reportingGroup.reportingGroups.map((g) =>
      removeDimensionValue(idToDelete, g)
    )
  )
}

export const deleteReportingGroup = (idToDelete, reportingGroups) => {
  const filtered = reportingGroups.filter((r) => r.id !== idToDelete)
  return filtered.map((g) =>
    g.set(
      'reportingGroups',
      deleteReportingGroup(idToDelete, g.reportingGroups)
    )
  )
}

export const flattenChildren = (reportingGroup) => {
  const flattenedChildren = reportingGroup.reportingGroups.flatMap(
    flattenChildren
  )

  return List([reportingGroup]).concat(flattenedChildren)
}

export const flattenExpandedChildren = (reportingGroup) => {
  if (reportingGroup.isCollapsed) {
    return List([reportingGroup])
  }
  const flattenedChildren = reportingGroup.reportingGroups.flatMap(
    flattenExpandedChildren
  )
  return List([reportingGroup]).concat(flattenedChildren)
}
