import { createSelector } from 'reselect'
import { List, Map } from 'immutable'
import { flattenChildren, flattenDimensionValues } from './utils'
import { alphabeticallyAscendingComparer } from 'utils/sort'
import { companyFilter } from 'utils/selectors'
import { mapDimensions } from '../CompanyIndex/functions'
import { DimensionRecord, DimensionValueRecord } from 'records'
import { getDimensionName } from 'containers/Dimension/functions.js'
/**
 * convenience functions
 */
const isVisibleInReporting = (dimension) => !dimension.hideFromReporting
const isVisibleInBudgeting = (dimension) => !dimension.hideFromBudgeting
const isOperativeDimension = (dimension) => dimension.dimensionType === 1
const filterDimensionsByCompanyCodeForReporting = (
  dimensionsByCompanyCode,
  filterFunc
) => {
  const filteredDimensionsByCompanyCode = dimensionsByCompanyCode.map(
    (list) => {
      const filteredList = list.filter(filterFunc)
      return filteredList.sort(alphabeticallyAscendingComparer)
    }
  )
  const dimensionValueFilterFunc = (dim) =>
    isVisibleInReporting(dim) && nameExists(dim)
  const reportingDimensionsByCompanyCode = filteredDimensionsByCompanyCode.map(
    (list) =>
      list.map((dimension) => {
        const newValues = filterDimensionValues({
          valueList: dimension.values,
          filterFunc: dimensionValueFilterFunc,
        })
        //values list might be empty now. Removing dimensions without values
        if (!newValues || newValues < 1) return undefined
        const returnValue = makeNewDimensionRecord(dimension, newValues)
        return returnValue
      })
  )
  return reportingDimensionsByCompanyCode
}
const filterDimensionValues = ({ valueList, filterFunc }) => {
  if (!valueList) return new List()
  const filteredValues = valueList.filter(filterFunc)
  const newValues = filteredValues.map((value) => {
    const returnValue = new DimensionValueRecord({
      ...value.toJS(),
      children: filterDimensionValues({
        valueList: value.children,
        filterFunc,
      }),
    })
    return returnValue
  })
  if (!newValues) return new List()
  return newValues
}

const makeNewDimensionRecord = (dimension, newValues) => {
  return new DimensionRecord({
    ...dimension.toJS(),
    values: newValues ? newValues : List(),
    dimensionValuesById: Map(
      newValues.flatMap(flattenChildren).map((value) => [value.id, value])
    ),
  })
}
const nameExists = (dim) => {
  if (!getDimensionName(dim)) return false
  return true
}

const combineDimensions = (companyDomain) => {
  const explicitelyFetchedDimensions = companyDomain.get('dimensions') || Map()
  const searchedDimensions = companyDomain.get('searchedDimensions') || Map()
  const dimensions = searchedDimensions
    .mergeDeep(explicitelyFetchedDimensions)
    .toList()
    .filter((d) => d.has('dimension'))
    .map((dimensionMap) => {
      const dimensionObject = dimensionMap.toJS()
      return {
        ...dimensionObject.dimension,
        values: Object.values(dimensionObject.values),
      }
    })
  return dimensions
}

//Remove dimensions that have only fetched specific values and not the dimensions themselves
const isNotValuesOnly = (company) =>
  !company.get('dimensions') ||
  company.get('dimensions')?.size === 0 ||
  !company
    .get('dimensions')
    ?.every(
      (dimension) => dimension.get('values') && !dimension.get('dimension')
    )

/**
 * Direct selector to the dimensions state domain
 */
const selectDimensionsDomain = () => (state) => state.get('dimensions')

const selectAllDimensionsRaw = (requireOperative = true) =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) => {
      const dimensionsByCompanyId =
        dimensions && dimensions.get('dimensions')
          ? dimensions
              .get('dimensions')
              .filter(isNotValuesOnly)
              .filter(
                (company) =>
                  !requireOperative || company.get('includeOperative')
              )
              .map(combineDimensions)
          : new Map()
      return dimensionsByCompanyId
    }
  )

const selectAllDimensionNames = () =>
  createSelector(
    selectAllDimensionsRaw(),
    (dimensions) =>
      dimensions.reduce((result, company) => {
        const dimensionNames = company.map((dimension, index) =>
          dimension.name ? dimension.name.toLowerCase() : `No Name ${index}`
        )
        return result.concat(dimensionNames)
      }, List())
  )

const selectAllDimensionValuesByCompanyCodes = () =>
  createSelector(
    selectAllDimensionsRaw(),
    (dimensions) =>
      dimensions
        ?.map((dim) => dim.flatMap(flattenDimensionValues))
        ?.map(mapDimensions)
  )

const selectAllDimensions = (requireOperative = true) =>
  createSelector(
    selectAllDimensionsRaw(requireOperative),
    (dimensions) => dimensions.map(mapDimensions)
  )

const selectError = () =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) => dimensions.get('error')
  )

//Todo refactor away
const selectLoading = () =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) => dimensions.get('loading')
  )

const selectLoadingDimensions = createSelector(
  selectDimensionsDomain(),
  (dimensions) => dimensions.get('loadingDimensions')
)

const selectErrorDimensions = createSelector(
  selectDimensionsDomain(),
  (dimensions) => dimensions.get('errorDimensions')
)

const selectDimensionSearchLoading = ({ companyCode }) =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) =>
      dimensions
        ?.get('dimensions')
        ?.get(companyCode)
        ?.get('dimensionSearchLoading')
  )

const selectCompanyLoading = ({ companyCode }) =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) =>
      dimensions
        ?.get('dimensions')
        ?.get(companyCode)
        ?.get('loading')
  )

const selectCompaniesLoading = () =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) => dimensions?.get('dimensions')?.map((v) => v.get('loading'))
  )

const selectDimensionValueLoading = () =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) => dimensions.get('dimensionValueLoading')
  )

const selectAllCompanyDimensions = (requireOperative = true) =>
  createSelector(
    [selectAllDimensionsRaw(requireOperative), companyFilter],
    (dimensions, companyCode) => {
      if (dimensions.has(companyCode)) {
        const companyDimensions = dimensions
          .get(companyCode)
          .sort(alphabeticallyAscendingComparer)
        return mapDimensions(companyDimensions)
      }
      return undefined
    }
  )

// Select dimension values only from level 1
const selectDimensionValuesShallow = () =>
  createSelector(
    selectAllCompanyDimensions(),
    (dimensions) =>
      dimensions ? dimensions.flatMap((d) => d.values) : undefined
  )

const selectOperativeDimensions = () =>
  createSelector(
    selectAllCompanyDimensions(),
    (dimensions) =>
      dimensions ? dimensions.filter((d) => isOperativeDimension(d)) : undefined
  )

const selectProvidesMatrixSelectionDimensions = (requireOperative = true) =>
  createSelector(
    selectAllCompanyDimensions(requireOperative),
    (dimensions) =>
      dimensions
        ? dimensions.filter((d) => d.providesMatrixSelection)
        : undefined
  )

const selectOperativeMatrixSelectionDimensions = () =>
  createSelector(
    selectProvidesMatrixSelectionDimensions(),
    (dimensions) =>
      dimensions ? dimensions.filter((d) => isOperativeDimension(d)) : undefined
  )

const selectOperativeMatrixSelectionDimensionsForBudgeting = createSelector(
  selectOperativeMatrixSelectionDimensions(),
  (dimensions) =>
    dimensions ? dimensions.filter((d) => isVisibleInBudgeting(d)) : undefined
)

const selectFlattenedNonOperativeCompanyDimensionValues = () =>
  createSelector(
    selectAllCompanyDimensions(false),
    (dimensions) =>
      dimensions
        ? dimensions
            .filter((d) => !isOperativeDimension(d))
            .flatMap(flattenDimensionValues)
        : undefined
  )

// Select dimensionvalues of dimensions where providesMatrixSelection === true.
// Then flatten the values.
// `level` tells the depth, and the order of the flat rows tells the hierarchy.
const selectFlattenedNonOperativeDimensionValuesDeep = createSelector(
  selectProvidesMatrixSelectionDimensions(false),
  (dimensions) =>
    dimensions
      ? dimensions
          .filter((d) => !isOperativeDimension(d))
          .flatMap(flattenDimensionValues)
      : undefined
)

const selectFlattenedNonOperativeDimensionValuesDeepForBudgeting = createSelector(
  selectProvidesMatrixSelectionDimensions(false),
  (dimensions) =>
    dimensions
      ? dimensions
          .filter((d) => !isOperativeDimension(d) && isVisibleInBudgeting(d))
          .flatMap(flattenDimensionValues)
          .filter((dv) => isVisibleInBudgeting(dv))
      : undefined
)

// Select dimensionValues where type === OperativeItemDimension and providesMatrixSelection === true.
// Then flatten the values.
const selectFlattenedOperativeDimensionValuesDeepForBudgeting = () =>
  createSelector(
    selectOperativeMatrixSelectionDimensions(),
    (dimensions) =>
      dimensions
        ? dimensions
            .filter((d) => isVisibleInBudgeting(d))
            .flatMap(flattenDimensionValues)
            .filter((dv) => isVisibleInBudgeting(dv))
        : undefined
  )

/*
 *This does NOT use company filter.
 */
const selectDimensionsThatProvideMatrixSelection = (requireOperative = true) =>
  createSelector(
    selectAllDimensionsRaw(requireOperative),
    (dimensionsMap) =>
      dimensionsMap
        .map((dimensions) =>
          dimensions.filter((d) => d.providesMatrixSelection)
        )
        .map(mapDimensions)
  )

// Returns flattened dimension values that are mapped by companyCode
// and provide matrix selection
const selectFlattenedDimensionValuesMap = () =>
  createSelector(
    selectDimensionsThatProvideMatrixSelection(),
    (dimsThatProvideMatrixSelection) =>
      dimsThatProvideMatrixSelection.map((dimensions) =>
        dimensions.flatMap(flattenDimensionValues)
      )
  )

// Returns flattened dimension values that are mapped by companyCode
// and provide matrix selection
const selectFlattenedNonOperativeDimensionValuesMap = (
  requireOperative = false
) =>
  createSelector(
    selectDimensionsThatProvideMatrixSelection(requireOperative),
    (dimsThatProvideMatrixSelection) => {
      const filteredMap = dimsThatProvideMatrixSelection.map((list) =>
        list.filter((dim) => !isOperativeDimension(dim))
      )
      return filteredMap.map((dimensions) =>
        dimensions.flatMap(flattenDimensionValues)
      )
    }
  )

// Returns operative dimensions and their values
const selectOperativeAndWithMatrixSelectionDimensionValuesMapForReporting = () =>
  createSelector(
    selectDimensionsThatProvideMatrixSelection(true),
    (dimensionsByCompanyCode) => {
      const filterFunc = (dim) =>
        isOperativeDimension(dim) &&
        isVisibleInReporting(dim) &&
        nameExists(dim) &&
        dim.values &&
        dim.values.size > 0
      return filterDimensionsByCompanyCodeForReporting(
        dimensionsByCompanyCode,
        filterFunc
      )
    }
  )

const selectNonOperativeAndWithMatrixSelectionDimensionMapForReporting = (
  requireOperative = false
) =>
  createSelector(
    selectDimensionsThatProvideMatrixSelection(requireOperative),
    (dimensionsByCompanyCode) => {
      const filterFunc = (dim) =>
        !isOperativeDimension(dim) &&
        isVisibleInReporting(dim) &&
        nameExists(dim) &&
        dim.values &&
        dim.values.size > 0
      return filterDimensionsByCompanyCodeForReporting(
        dimensionsByCompanyCode,
        filterFunc
      )
    }
  )

const selectNonOperativeAndWithMatrixSelectionDimensionsForBudgeting = createSelector(
  [selectAllCompanyDimensions(false)],
  (dimensions) => {
    const nonOperatives = dimensions
      ? dimensions.filter(
          (dim) =>
            !isOperativeDimension(dim) &&
            dim.providesMatrixSelection &&
            isVisibleInBudgeting(dim)
        )
      : undefined
    if (!nonOperatives) return undefined
    const budgetingDimensions = nonOperatives.map((dimension) => {
      const newValues = filterDimensionValues({
        valueList: dimension.values,
        filterFunc: isVisibleInBudgeting,
      })
      const returnValue = makeNewDimensionRecord(dimension, newValues)
      return returnValue
    })
    return budgetingDimensions
  }
)

const selectAllCompanyDimensionsWithMatrixSelection = (
  requireOperative = true
) =>
  createSelector(
    [selectAllCompanyDimensions(requireOperative)],
    (dimensions) => {
      const companyDimensions = dimensions
        ? dimensions.filter((dim) => dim.providesMatrixSelection)
        : undefined
      return companyDimensions
    }
  )

const selectAllCompanyDimensionsWithMatrixSelectionForBudgeting = () =>
  createSelector(
    [selectAllCompanyDimensionsWithMatrixSelection()],
    (dimensions) => {
      const companyDimensions = dimensions
        ? dimensions.filter((dim) => isVisibleInBudgeting(dim))
        : undefined
      if (!companyDimensions) return undefined
      const reportingDimensions = companyDimensions.map((dimension) => {
        const newValues = filterDimensionValues({
          valueList: dimension.values,
          filterFunc: isVisibleInBudgeting,
        })
        const returnValue = makeNewDimensionRecord(dimension, newValues)
        return returnValue
      })
      return reportingDimensions
    }
  )

const selectAllCompanyDimensionsWithMatrixSelectionForReporting = () =>
  createSelector(
    [selectAllCompanyDimensionsWithMatrixSelection()],
    (dimensions) => {
      const companyDimensions = dimensions
        ? dimensions.filter((dim) => isVisibleInReporting(dim))
        : undefined
      if (!companyDimensions) return undefined
      const reportingDimensions = companyDimensions.map((dimension) => {
        const newValues = filterDimensionValues({
          valueList: dimension.values,
          filterFunc: isVisibleInReporting,
        })
        const returnValue = makeNewDimensionRecord(dimension, newValues)
        return returnValue
      })
      return reportingDimensions
    }
  )

/**
 * Default selector used by Dimensions
 */
const selectDimensions = (requireOperative = false) =>
  createSelector(
    [selectAllCompanyDimensions(requireOperative)],
    (dimensions) =>
      dimensions
        ? dimensions.filter((dim) => !isOperativeDimension(dim))
        : undefined
  )

const selectCompany = (companyCode) =>
  createSelector(
    selectDimensionsDomain(),
    (dimensions) => dimensions?.get('dimensions')?.get(companyCode)
  )

const filterDimensionValuesWithFunction = (dimensions, filterFunc) =>
  dimensions.map((dim) =>
    dim.update('values', (valueList) =>
      filterDimensionValues({
        valueList,
        filterFunc,
      })
    )
  )

const sortDimensionsAndValues = (dimensions) => {
  const sortedDimensions = dimensions.sort(alphabeticallyAscendingComparer)
  const sortedBoth = sortedDimensions.map((dim) =>
    dim.update('values', (oldValues) =>
      oldValues.sort(alphabeticallyAscendingComparer)
    )
  )
  return sortedBoth
}

// All selectors above are a lot slower and can't be used for performance heavy components
// On other ones any change to dimensions triggers "mapDimension" for all companies
// this here only remaps changed companies
// TODO: Refactor every single component to use this selector
const selectDimensionForSelectTool = ({
  companyCode,
  reporting, // Require dimension and value to be visible in reporting
  budgeting, // Require dimension and value to be visible in budgeting
  rootOnly, // Only include root level selections (don't include hierarchical dimensions if not top level)
  operativeOnly, // Only operative dimensions
  balanceOnly, // Only balance dimensions
}) =>
  createSelector(
    selectCompany(companyCode),
    (dimensions) => {
      if (!dimensions) return dimensions
      let companyDimensions = dimensions
      //TODO Do mapping after filtering.
      companyDimensions = mapDimensions(combineDimensions(companyDimensions))
      if (reporting) {
        companyDimensions = companyDimensions.filter(
          (dimension) => !dimension.hideFromReporting
        )
        companyDimensions = filterDimensionValuesWithFunction(
          companyDimensions,
          isVisibleInReporting
        )
      }
      if (budgeting) {
        companyDimensions = companyDimensions.filter(
          (dimension) => !dimension.hideFromBudgeting
        )
        companyDimensions = filterDimensionValuesWithFunction(
          companyDimensions,
          isVisibleInBudgeting
        )
      }
      if (rootOnly) {
        companyDimensions = companyDimensions.filter(
          (dimension) => dimension.providesMatrixSelection
        )
      }
      if (operativeOnly) {
        companyDimensions = companyDimensions.filter(isOperativeDimension)
      }
      if (balanceOnly) {
        companyDimensions = companyDimensions.filter(
          (d) => !isOperativeDimension(d)
        )
      }
      return sortDimensionsAndValues(companyDimensions)
    }
  )

const selectCompanyDimensionsForBudgeting = createSelector(
  [selectAllDimensionsRaw(false), companyFilter],
  (dimensions, companyCode) => {
    if (dimensions.has(companyCode)) {
      const companyDimensions = dimensions
        .get(companyCode)
        .sort(alphabeticallyAscendingComparer)
      return mapDimensions(companyDimensions)
    }
    return undefined
  }
)

const selectCompanyDimensions = createSelector(
  [
    (state) => state.get('dimensions'),
    (_state, { companyCode }) => companyCode,
    (_state, { balanceOnly }) => balanceOnly,
    (_state, { budgeting }) => budgeting,
    (_state, { operativeOnly }) => operativeOnly,
    (_state, { reporting }) => reporting,
    (_state, { rootOnly = true }) => rootOnly,
  ],
  (
    store,
    companyCode,
    balanceOnly,
    budgeting,
    operativeOnly,
    reporting,
    rootOnly
  ) => {
    let companyDimensions = store?.getIn(['dimensions', companyCode])

    if (!companyDimensions) return undefined

    companyDimensions = mapDimensions(combineDimensions(companyDimensions))

    if (budgeting) {
      companyDimensions = companyDimensions.filter(
        (dimension) => !dimension.hideFromBudgeting
      )
      companyDimensions = filterDimensionValuesWithFunction(
        companyDimensions,
        isVisibleInBudgeting
      )
    }

    if (reporting) {
      companyDimensions = companyDimensions.filter(
        (dimension) => !dimension.hideFromReporting
      )
      companyDimensions = filterDimensionValuesWithFunction(
        companyDimensions,
        isVisibleInReporting
      )
    }
    if (rootOnly) {
      companyDimensions = companyDimensions.filter(
        (dimension) => dimension.providesMatrixSelection
      )
    }

    if (operativeOnly) {
      companyDimensions = companyDimensions.filter(isOperativeDimension)
    }

    if (balanceOnly) {
      companyDimensions = companyDimensions.filter(
        (dimension) => !isOperativeDimension(dimension)
      )
    }
    return sortDimensionsAndValues(companyDimensions)
  }
)

export default selectDimensions
export {
  selectAllDimensions,
  selectAllDimensionNames,
  selectAllDimensionValuesByCompanyCodes,
  selectAllCompanyDimensions,
  selectAllCompanyDimensionsWithMatrixSelection,
  selectAllCompanyDimensionsWithMatrixSelectionForBudgeting,
  selectAllCompanyDimensionsWithMatrixSelectionForReporting,
  selectCompanyDimensions,
  selectDimensionValuesShallow,
  selectDimensionsDomain,
  selectDimensions,
  selectError,
  selectFlattenedDimensionValuesMap,
  selectFlattenedNonOperativeCompanyDimensionValues,
  selectFlattenedNonOperativeDimensionValuesDeep,
  selectFlattenedNonOperativeDimensionValuesDeepForBudgeting,
  selectFlattenedNonOperativeDimensionValuesMap,
  selectFlattenedOperativeDimensionValuesDeepForBudgeting,
  selectLoading,
  selectLoadingDimensions,
  selectErrorDimensions,
  selectDimensionSearchLoading,
  selectDimensionValueLoading,
  selectCompanyLoading,
  selectCompaniesLoading,
  selectNonOperativeAndWithMatrixSelectionDimensionMapForReporting,
  selectNonOperativeAndWithMatrixSelectionDimensionsForBudgeting,
  selectOperativeDimensions,
  selectOperativeAndWithMatrixSelectionDimensionValuesMapForReporting,
  selectOperativeMatrixSelectionDimensions,
  selectOperativeMatrixSelectionDimensionsForBudgeting,
  selectProvidesMatrixSelectionDimensions,
  selectDimensionForSelectTool,
  selectCompanyDimensionsForBudgeting,
}
