import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getBankAccounts = ({ companyCode }) =>
  axios
    .get(`${companyCode}/bankaccount`, config())
    .then(returnData)
    .catch(catchError)

const saveBankAccounts = ({ companyCode, selectedAccountIds }) =>
  axios
    .post(`${companyCode}/bankaccount`, { selectedAccountIds }, config())
    .then(returnData)
    .catch(catchError)

const bankAccountApi = {
  getBankAccounts,
  saveBankAccounts,
}

export default bankAccountApi
