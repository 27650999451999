import { Record } from 'immutable'

const BudgetValueRecord = Record({
  amount: undefined,
  transferAmount: undefined,
  rowId: '',
  columnId: '',
})

export default BudgetValueRecord
