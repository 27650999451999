import { fromJS, List } from 'immutable'
import { updateListValue } from 'utils/immutable'

import {
  COPY_SYSTEM_DASHBOARD,
  COPY_SYSTEM_DASHBOARD_ERROR,
  COPY_SYSTEM_DASHBOARD_SUCCESS,
  COPY_SYSTEM_DASHBOARD_WIDGET,
  COPY_SYSTEM_DASHBOARD_WIDGET_ERROR,
  COPY_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  CREATE_SYSTEM_DASHBOARD,
  CREATE_SYSTEM_DASHBOARD_ERROR,
  CREATE_SYSTEM_DASHBOARD_SUCCESS,
  CREATE_SYSTEM_DASHBOARD_WIDGET,
  CREATE_SYSTEM_DASHBOARD_WIDGET_ERROR,
  CREATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  DELETE_SYSTEM_DASHBOARD,
  DELETE_SYSTEM_DASHBOARD_ERROR,
  DELETE_SYSTEM_DASHBOARD_SUCCESS,
  DELETE_SYSTEM_DASHBOARD_WIDGET,
  DELETE_SYSTEM_DASHBOARD_WIDGET_ERROR,
  DELETE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  GET_SYSTEM_DASHBOARDS,
  GET_SYSTEM_DASHBOARDS_ERROR,
  GET_SYSTEM_DASHBOARDS_SUCCESS,
  UPDATE_SYSTEM_DASHBOARD,
  UPDATE_SYSTEM_DASHBOARD_ERROR,
  UPDATE_SYSTEM_DASHBOARD_SUCCESS,
  UPDATE_SYSTEM_DASHBOARD_WIDGET,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_ERROR,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_ERROR,
  UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_SUCCESS,
} from './constants'
import { mapDashboard, mapWidget } from './functions'

const initialState = fromJS({
  dashboards: undefined,
  error: false,
  loading: false,
  widgets: List(),
})

const dashboardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COPY_SYSTEM_DASHBOARD:
    case CREATE_SYSTEM_DASHBOARD:
    case DELETE_SYSTEM_DASHBOARD:
    case GET_SYSTEM_DASHBOARDS:
    case UPDATE_SYSTEM_DASHBOARD: {
      return state.set('loading', true).set('error', false)
    }

    case COPY_SYSTEM_DASHBOARD_ERROR:
    case COPY_SYSTEM_DASHBOARD_WIDGET_ERROR:
    case CREATE_SYSTEM_DASHBOARD_ERROR:
    case CREATE_SYSTEM_DASHBOARD_WIDGET_ERROR:
    case DELETE_SYSTEM_DASHBOARD_ERROR:
    case DELETE_SYSTEM_DASHBOARD_WIDGET_ERROR:
    case GET_SYSTEM_DASHBOARDS_ERROR:
    case UPDATE_SYSTEM_DASHBOARD_ERROR:
    case UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_ERROR: {
      return state.set('loading', false).set('error', action.error)
    }

    case COPY_SYSTEM_DASHBOARD_SUCCESS: {
      return state
        .updateIn(['dashboards'], (dashboards) => {
          const newDashboard = mapDashboard(action.dashboard)
          return dashboards
            ? new List([...dashboards].concat(newDashboard))
            : new List(newDashboard)
        })
        .updateIn(['widgets'], (widgets) => {
          const newWidgets = action.dashboard.widgets.map((widget) =>
            mapWidget({ widget, dashboardId: action.dashboard.id })
          )
          return widgets
            ? new List([...widgets].concat(newWidgets))
            : new List(newWidgets)
        })
        .set('loading', false)
    }

    case CREATE_SYSTEM_DASHBOARD_SUCCESS: {
      return state
        .updateIn(['dashboards'], (dashboards) => {
          const newDashboard = mapDashboard(action.dashboard)
          return dashboards
            ? new List([...dashboards].concat(newDashboard))
            : new List(newDashboard)
        })
        .set('loading', false)
    }

    case COPY_SYSTEM_DASHBOARD_WIDGET:
    case CREATE_SYSTEM_DASHBOARD_WIDGET: {
      return state.updateIn(['widgets'], (widgets) => {
        const newWidget = mapWidget(action)
        return widgets?.size > 0
          ? new List([...widgets].concat(newWidget))
          : new List([newWidget])
      })
    }

    case COPY_SYSTEM_DASHBOARD_WIDGET_SUCCESS:
    case CREATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS: {
      const widgets = state.get('widgets')
      // TODO: We could just find index of temporaryWidget id and update single widget
      const updatedWidgets = updateListValue(
        widgets,
        (widget) => widget.id === action.temporaryWidgetId,
        'id',
        action.widget.id
      )
      return state.set('widgets', updatedWidgets)
    }

    case DELETE_SYSTEM_DASHBOARD_SUCCESS: {
      const dashboards = state
        .get('dashboards')
        .filter((dashboard) => dashboard.id !== action.id)
      return state.set('dashboards', dashboards).set('loading', false)
    }

    case DELETE_SYSTEM_DASHBOARD_WIDGET: {
      return state.updateIn(['widgets'], (widgets) =>
        widgets.filter((widget) => widget.id !== action.widgetId)
      )
    }

    case GET_SYSTEM_DASHBOARDS_SUCCESS: {
      return state
        .set(
          'dashboards',
          new List(
            action.dashboards.map((dashboard) => mapDashboard(dashboard))
          )
        )
        .set(
          'widgets',
          new List(
            action.dashboards.reduce(
              (widgets, current) => [
                ...widgets,
                ...current.widgets.map((widget) =>
                  mapWidget({ widget, dashboardId: current.id })
                ),
              ],
              []
            )
          )
        )
        .set('loading', false)
    }

    case UPDATE_SYSTEM_DASHBOARD_SUCCESS: {
      const {
        dashboard,
        dashboard: { id },
      } = action
      const indexOfDashboard = state
        .get('dashboards')
        .findIndex((dashboard) => dashboard.id === id)

      return state
        .setIn(['dashboards', indexOfDashboard], mapDashboard(dashboard))
        .set('loading', false)
    }

    case UPDATE_SYSTEM_DASHBOARD_WIDGET: {
      return state
    }
    case UPDATE_SYSTEM_DASHBOARD_WIDGET_SUCCESS: {
      const {
        dashboardId,
        widget,
        widget: { id },
      } = action

      const index = state.get('widgets').findIndex((item) => item.id === id)
      return state.setIn(['widgets', index], mapWidget({ dashboardId, widget }))
    }
    case UPDATE_SYSTEM_DASHBOARD_WIDGET_ERROR: {
      // TODO: Fix widget error handling once old dashboard is gone
      return state
    }

    case UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS: {
      const { parameters, widgetId } = action
      const widgets = state.get('widgets')
      // TODO: We could just find index and update only one item
      const currentWidget = widgets.find((widget) => widget.id === widgetId)

      if (currentWidget) {
        const updatedWidgets = updateListValue(
          widgets,
          (widget) => widget.id === widgetId,
          'parameters',
          currentWidget.parameters.merge(parameters)
        )
        return state.set('widgets', updatedWidgets)
      }
      return state
    }

    // TODO
    case DELETE_SYSTEM_DASHBOARD_WIDGET_SUCCESS:
    case UPDATE_SYSTEM_DASHBOARD_WIDGET_PARAMETERS_SUCCESS:
    default:
      return state
  }
}

export default dashboardsReducer
