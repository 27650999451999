/*
 *
 * CompanyCreationWizard actions
 *
 */

import {
  CLOSE_MODAL,
  COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME,
  COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_ERROR,
  COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_SUCCESS,
  COMPANY_CREATION_WIZARD_CREATE_BUDGET,
  COMPANY_CREATION_WIZARD_CREATE_BUDGET_ERROR,
  COMPANY_CREATION_WIZARD_CREATE_BUDGET_SUCCESS,
  COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR,
  COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS,
  GET_ACCOUNT_LIST_CHOICES,
  GET_ACCOUNT_LIST_CHOICES_ERROR,
  GET_ACCOUNT_LIST_CHOICES_SUCCESS,
  GET_ACCOUNT_LIST_FILE,
  GET_ACCOUNT_LIST_FILE_ERROR,
  GET_ACCOUNT_LIST_FILE_SUCCESS,
  IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS,
  IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR,
  IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR,
  PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
  SET_SPECIAL_ACCOUNTS,
  SET_SPECIAL_ACCOUNTS_ERROR,
  SET_SPECIAL_ACCOUNTS_SUCCESS,
  START_CREATE_BUDGET,
} from './constants'
export const closeModal = () => ({ type: CLOSE_MODAL })
export const getAccountListChoices = () => ({
  type: GET_ACCOUNT_LIST_CHOICES,
})
export const companyCreationWizardCreateAccountScheme = ({
  accountListName,
  accountSchemeTemplateId,
  companyCode,
  name,
}) => ({
  type: COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME,
  accountListName,
  accountSchemeTemplateId,
  companyCode,
  name,
})
export const companyCreationWizardCreateAccountSchemeError = (error) => ({
  type: COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_ERROR,
  error,
})
export const companyCreationWizardCreateAccountSchemeSuccess = ({
  accountScheme,
}) => ({
  type: COMPANY_CREATION_WIZARD_CREATE_ACCOUNT_SCHEME_SUCCESS,
  accountScheme,
})

export const getAccountListChoicesSuccess = ({ accountListChoices }) => ({
  type: GET_ACCOUNT_LIST_CHOICES_SUCCESS,
  accountListChoices,
})
export const getAccountListChoicesError = () => ({
  type: GET_ACCOUNT_LIST_CHOICES_ERROR,
})
export const getAccountListFile = ({ choice, companyCode }) => ({
  type: GET_ACCOUNT_LIST_FILE,
  choice,
  companyCode,
})

export const getAccountListFileError = () => ({
  type: GET_ACCOUNT_LIST_FILE_ERROR,
})

export const getAccountListFileSuccess = () => ({
  type: GET_ACCOUNT_LIST_FILE_SUCCESS,
})

export const importCompanyCreationWizardAccounts = ({
  companyCode,
  validAccounts,
}) => ({
  type: IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS,
  companyCode,
  validAccounts,
})
export const importCompanyCreationWizardAccountsError = (error) => ({
  type: IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR,
  error,
})
export const importCompanyCreationWizardAccountsSuccess = ({
  accounts,
  parsedFile,
}) => ({
  type: IMPORT_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
  accounts,
  parsedFile,
})

export const parseCompanyCreationWizardAccounts = ({ companyCode, file }) => ({
  type: PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS,
  companyCode,
  file,
})
export const parseCompanyCreationWizardAccountsError = (error) => ({
  type: PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_ERROR,
  error,
})
export const parseCompanyCreationWizardAccountsSuccess = ({
  companyCode,
  parsedAccounts,
  parsedFile,
}) => ({
  type: PARSE_COMPANY_CREATION_WIZARD_ACCOUNTS_SUCCESS,
  companyCode,
  parsedAccounts,
  parsedFile,
})
export const setSpecialAccounts = ({
  accountListName,
  accountScheme,
  companyCode,
}) => ({
  type: SET_SPECIAL_ACCOUNTS,
  accountListName,
  accountScheme,
  companyCode,
})
export const companyCreationWizardCreateBudget = ({
  accountSchemeId,
  budgetType,
  companyCode,
  displayDecimalsAmount,
  endDate,
  lockDate,
  name,
  startDate,
  useAccountingLockDate,
  presentationType,
  realBalanceEndDate,
  timeRangeStart,
  timeRangeEnd,
}) => ({
  type: COMPANY_CREATION_WIZARD_CREATE_BUDGET,
  accountSchemeId,
  budgetType,
  companyCode,
  displayDecimalsAmount,
  endDate,
  lockDate,
  name,
  startDate,
  useAccountingLockDate,
  presentationType,
  realBalanceEndDate,
  timeRangeStart,
  timeRangeEnd,
})
export const companyCreationWizardCreateBudgetError = (error) => ({
  type: COMPANY_CREATION_WIZARD_CREATE_BUDGET_ERROR,
  error,
})
export const companyCreationWizardCreateBudgetSuccess = ({
  budget,
  companyCode,
}) => ({
  type: COMPANY_CREATION_WIZARD_CREATE_BUDGET_SUCCESS,
  budget,
  companyCode,
})
export const companyCreationWizardSetAccountSchemeSpecialAccount = ({
  accountNumber,
  companyCode,
  id,
  specialAccount,
}) => ({
  type: COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  accountNumber,
  companyCode,
  id,
  specialAccount,
})
export const companyCreationWizardSetAccountSchemeSpecialAccountError = (
  error
) => ({
  type: COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR,
  error,
})
export const companyCreationWizardSetAccountSchemeSpecialAccountSuccess = ({
  accountScheme,
}) => ({
  type: COMPANY_CREATION_WIZARD_SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS,
  accountScheme,
})

export const setSpecialAccountsError = (error) => ({
  type: SET_SPECIAL_ACCOUNTS_ERROR,
  error,
})
export const setSpecialAccountsSuccess = () => ({
  type: SET_SPECIAL_ACCOUNTS_SUCCESS,
})
export const startCreateBudget = () => ({
  type: START_CREATE_BUDGET,
})
