import { alphabeticallyAscendingComparer } from 'utils/sort'

const hasParent = (dimension) => !!dimension.get('parent')

const dimensionValueCount = (dimension) => dimension.valueCount

const getDimensionName = (dimension) => {
  if (!dimension) return null
  return (
    getFieldValue(dimension, 'nameToDisplay') ||
    getFieldValue(dimension, 'code')
  )
}
const getDimensionNameWithDefaultValue = (dimension, noNameMsg) => {
  const tmpName = getDimensionName(dimension)
  if (!tmpName) return noNameMsg
  return tmpName
}
const getFieldValue = (obj, propertyName) => {
  if (obj.get) {
    //object is immutable.js, record
    return getRecordProperty(obj, propertyName)
  }
  //object is JS object
  let candidate = obj[propertyName]
  if (candidate) return candidate
}
const getRecordProperty = (record, property) =>
  record ? record.get(property) : undefined

const flattenTree = (value) => {
  if (!value) {
    return []
  }

  if (!value.children) {
    return [value]
  }

  const flattenedChildren = value.children
    .flatMap(flattenTree)
    .sort(alphabeticallyAscendingComparer)

  return [value].concat(flattenedChildren)
}

export {
  dimensionValueCount,
  hasParent,
  getDimensionName,
  getDimensionNameWithDefaultValue,
  getRecordProperty,
  flattenTree,
}
