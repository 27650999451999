/*
 *
 * IntegrationLogs reducer
 *
 */

import { fromJS, List } from 'immutable'
import {
  GET_INTEGRATION_LOGS_SUCCESS,
  GET_INTEGRATION_LOGS_ERROR,
  GET_INTEGRATION_LOGS,
} from './constants'
import integrationLogEntry from '../../records/integrationLogEntry'

const initialState = fromJS({
  logs: new List(),
  loading: false,
  error: false,
})

function integrationLogsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INTEGRATION_LOGS:
      return state.set('loading', true).set('error', false)
    case GET_INTEGRATION_LOGS_SUCCESS: {
      const mappedLogs = action.logs.map(integrationLogEntry)
      const logs =
        action.page === 0
          ? new List(mappedLogs)
          : state.get('logs').concat(mappedLogs) // paged values are appended
      return state.set('loading', false).set('logs', logs)
    }

    case GET_INTEGRATION_LOGS_ERROR:
      return state.set('loading', false).set('error', action.error)
    default:
      return state
  }
}

export default integrationLogsReducer
