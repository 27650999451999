import { createSelector } from 'reselect'

/**
 * Direct selector to the calculationConstants state domain
 */
const selectCalculationConstantsDomain = (state) =>
  state.get('calculationConstants')

/**
 * Other specific selectors
 */
const selectCalculationConstants = createSelector(
  selectCalculationConstantsDomain,
  (calculationConstants) => calculationConstants.get('calculationConstants')
)

const selectCalculationConstantsEdit = createSelector(
  selectCalculationConstantsDomain,
  (calculationConstants) => calculationConstants.get('edit')
)

const selectCalculationConstantsError = createSelector(
  selectCalculationConstantsDomain,
  (calculationConstants) => calculationConstants.get('error')
)

const selectCalculationConstantsLoading = createSelector(
  selectCalculationConstantsDomain,
  (calculationConstants) => calculationConstants.get('loading')
)

const selectEditedCalculationConstant = createSelector(
  [selectCalculationConstants, selectCalculationConstantsEdit],
  (calculationConstants, calculationConstantId) =>
    calculationConstants?.find(
      (calculationConstant) => calculationConstant.id === calculationConstantId
    )
)

const selectShowCreateCalculationConstantForm = () =>
  createSelector(
    selectCalculationConstantsDomain,
    (calculationConstants) =>
      calculationConstants.get('showCreateCalculationConstantForm')
  )

/**
 * Default selector used by CalculationConstants
 */

export default selectCalculationConstantsDomain
export {
  selectCalculationConstants,
  selectCalculationConstantsEdit,
  selectCalculationConstantsError,
  selectCalculationConstantsLoading,
  selectEditedCalculationConstant,
  selectShowCreateCalculationConstantForm,
}
