import { all, call, put, takeEvery, take } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import reportApi from 'api/ReportApi'

import {
  getReportDataError,
  getReportDataSuccess,
  invalidateReportDataCacheError,
  invalidateReportDataCacheSuccess,
  getReportProgressSuccess,
} from './actions'
import { GET_REPORT_DATA, INVALIDATE_REPORT_DATA_CACHE } from './constants'
import { eventChannel, END } from 'redux-saga'
import { tryParseErrorJson } from 'utils/parseError'

// Individual exports for testing
let payloadCache = []
export function* getReportData(action) {
  const { companyCode, customerCode, enableCaching, reportId } = action
  const cacheKey = reportId

  if (payloadCache.includes(cacheKey)) return
  try {
    payloadCache = [...payloadCache, cacheKey]
    const channel = eventChannel((eventEmmit) => {
      const onMessage = (event) => {
        try {
          eventEmmit(
            getReportDataSuccess({
              report: JSON.parse(event.data).data,
              reportId,
              companyCode,
            })
          )
          eventEmmit(END)
        } catch (error) {
          eventEmmit(handleError(error, getReportDataError, { reportId }))
          eventEmmit(END)
        }
      }

      const onProgress = (event) => {
        eventEmmit(
          getReportProgressSuccess({ progress: event.data, ...action })
        )
      }

      const onError = (event) => {
        eventEmmit(
          handleError(tryParseErrorJson(event.data), getReportDataError, {
            reportId,
          })
        )
        eventEmmit(END)
      }

      reportApi.getReport({
        companyCode,
        customerCode,
        enableCaching,
        reportId,
        onMessage,
        onProgress,
        onError,
      })

      return () => {}
    })
    while (true) {
      const event = yield take(channel)
      if (event === END) break
      yield put(event)
    }
  } catch (error) {
    yield put(handleError(error, getReportDataError, { reportId }))
  } finally {
    payloadCache = payloadCache.filter((key) => key !== cacheKey)
  }
}

export function* invalidateReportDataCache(action) {
  try {
    const { reportId } = action
    yield call(reportApi.invalidateReport, { reportId })
    yield put(invalidateReportDataCacheSuccess({ reportId }))
  } catch (error) {
    yield put(handleError(error, invalidateReportDataCacheError))
  }
}

// All sagas to be loaded
export function* reportDatasSaga() {
  yield all([
    takeEvery(GET_REPORT_DATA, getReportData),
    takeEvery(INVALIDATE_REPORT_DATA_CACHE, invalidateReportDataCache),
  ])
}

export default reportDatasSaga
