export const INTEGRATION_FILE_UPLOAD_DATA_TYPE_OPERATIVE = 'Operative data'
export const INTEGRATION_FILE_UPLOAD_DATA_TYPE_SUB_BUDGET =
  'Sub budget generation'

export const INTEGRATION_FILE_UPLOAD_DATA_TYPE_VOUCHER = 'Vouchers'

export const INTEGRATION_FILE_UPLOAD_DATA_TYPES = [
  INTEGRATION_FILE_UPLOAD_DATA_TYPE_OPERATIVE,
  INTEGRATION_FILE_UPLOAD_DATA_TYPE_VOUCHER,
]
export const INTEGRATION_SYSTEM_FOR_SUB_BUDGET_FORM = 'YTHSSubBudget'
