/*
 *
 * AccountScheme reducer
 *
 */
import { fromJS, List } from 'immutable'
import {
  ADD_ACCOUNT_SCHEME_ROW,
  ADD_ACCOUNT_SCHEME_ROW_ERROR,
  ADD_ACCOUNT_SCHEME_ROW_SUCCESS,
  ADD_ACCOUNT_SCHEME_ROW_GROUP,
  ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP,
  ADD_ACCOUNT_SCHEME_ROW_GROUP_ERROR,
  ADD_ACCOUNT_SCHEME_ROW_GROUP_SUCCESS,
  DELETE_ACCOUNT_SCHEME_ROW,
  DELETE_ACCOUNT_SCHEME_ROW_ERROR,
  DELETE_ACCOUNT_SCHEME_ROW_SUCCESS,
  GET_ACCOUNT_SCHEME,
  GET_ACCOUNT_SCHEME_ERROR,
  GET_ACCOUNT_SCHEME_SUCCESS,
  GET_ACCOUNT_SCHEME_TREE,
  GET_ACCOUNT_SCHEME_TREE_ERROR,
  GET_ACCOUNT_SCHEME_TREE_SUCCESS,
  GET_ACCOUNT_SCHEME_AND_TREE,
  GET_ACCOUNT_SCHEME_AND_TREE_ERROR,
  GET_ACCOUNT_SCHEME_AND_TREE_SUCCESS,
  MOVE_ACCOUNT_SCHEME_ROW,
  MOVE_ACCOUNT_SCHEME_ROW_ERROR,
  MOVE_ACCOUNT_SCHEME_ROW_SUCCESS,
  TOGGLE_ACCOUNT_SCHEME_SHOW_SETTINGS,
  SET_ACCOUNT_SCHEME_ACTIVE_ROW,
  SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT,
  SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR,
  SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_SCHEME_ROW,
  UPDATE_ACCOUNT_SCHEME_ROW_ERROR,
  UPDATE_ACCOUNT_SCHEME_ROW_SUCCESS,
} from './constants'
import { AccountSchemeGroupRecord } from 'records'

const initialState = {
  accountSchemeId: undefined,
  activeRow: new AccountSchemeGroupRecord(),
  error: false,
  loading: false,
  showSettings: false,
  specialAccounts: undefined,
  tree: new AccountSchemeGroupRecord(),
  editLoading: false,
}
//add tree levels for groups
let level = 0
const mapAccountSchemeTree = (accountSchemeGroup, lvl = level) =>
  new AccountSchemeGroupRecord({
    ...accountSchemeGroup,
    level: lvl,
  }).set(
    'children',
    List(
      accountSchemeGroup.children.map((g) => mapAccountSchemeTree(g, lvl + 1))
    )
  )

const specialAccountsAsArray = (specialAccounts) =>
  Object.keys(specialAccounts).map((account) => ({
    ...specialAccounts[account],
    type: account,
  }))

const accountSchemeReducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case ADD_ACCOUNT_SCHEME_ROW_ERROR:
    case ADD_ACCOUNT_SCHEME_ROW_GROUP_ERROR:
    case DELETE_ACCOUNT_SCHEME_ROW_ERROR:
    case GET_ACCOUNT_SCHEME_ERROR:
    case GET_ACCOUNT_SCHEME_AND_TREE_ERROR:
    case GET_ACCOUNT_SCHEME_TREE_ERROR:
    case MOVE_ACCOUNT_SCHEME_ROW_ERROR:
    case SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_ERROR:
    case UPDATE_ACCOUNT_SCHEME_ROW_ERROR:
      return state
        .set('loading', false)
        .set('editLoading', false)
        .set('error', action.error)

    case ADD_ACCOUNT_SCHEME_ROW_SUCCESS:
    case UPDATE_ACCOUNT_SCHEME_ROW_SUCCESS:
      return state
        .set('tree', mapAccountSchemeTree(action.tree.schemeGroup))
        .set('editLoading', false)
        .set('activeRow', initialState.activeRow)

    case ADD_ACCOUNT_SCHEME_ROW_GROUP_SUCCESS:
    case GET_ACCOUNT_SCHEME_TREE_SUCCESS:
    case MOVE_ACCOUNT_SCHEME_ROW_SUCCESS:
      return state
        .set('tree', mapAccountSchemeTree(action.tree.schemeGroup))
        .set('loading', false)
        .set('editLoading', false)
        .set('activeRow', initialState.activeRow)

    case ADD_ACCOUNT_SCHEME_ROW:
    case ADD_ACCOUNT_SCHEME_ROW_GROUP:
    case ADD_ACCOUNT_SCHEME_ROW_CHILD_GROUP:
    case DELETE_ACCOUNT_SCHEME_ROW:
    case MOVE_ACCOUNT_SCHEME_ROW:
    case SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT:
    case UPDATE_ACCOUNT_SCHEME_ROW:
      return state.set('editLoading', true).set('error', false)

    case DELETE_ACCOUNT_SCHEME_ROW_SUCCESS:
      return state
        .set('tree', mapAccountSchemeTree(action.tree.schemeGroup))
        .set('activeRow', initialState.activeRow)
        .set('loading', false)
        .set('editLoading', false)

    case GET_ACCOUNT_SCHEME:
      return state
        .set('specialAccounts', initialState.specialAccounts)
        .set('loading', true)
        .set('error', false)

    case GET_ACCOUNT_SCHEME_AND_TREE:
    case GET_ACCOUNT_SCHEME_TREE:
      return state
        .set('accountSchemeId', action.id)
        .set('activeRow', initialState.activeRow)
        .set('loading', true)
        .set('error', false)

    case GET_ACCOUNT_SCHEME_AND_TREE_SUCCESS:
      return state
        .set(
          'specialAccounts',
          specialAccountsAsArray(action.accountScheme.specialAccounts)
        )
        .set('tree', mapAccountSchemeTree(action.tree.schemeGroup))
        .set('loading', false)

    case GET_ACCOUNT_SCHEME_SUCCESS:
    case SET_ACCOUNT_SCHEME_SPECIAL_ACCOUNT_SUCCESS:
      return state
        .set(
          'specialAccounts',
          specialAccountsAsArray(action.accountScheme.specialAccounts)
        )
        .set('loading', false)

    case TOGGLE_ACCOUNT_SCHEME_SHOW_SETTINGS: {
      return state.set('showSettings', !state.get('showSettings'))
    }

    case SET_ACCOUNT_SCHEME_ACTIVE_ROW: {
      return state.set('activeRow', action.row)
    }

    default:
      return state
  }
}

export default accountSchemeReducer
