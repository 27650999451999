import {
  CREATE_FAS_ACQUISITION_VOUCHERS,
  CREATE_FAS_ACQUISITION_VOUCHERS_ERROR,
  CREATE_FAS_ACQUISITION_VOUCHERS_SUCCESS,
  GET_ACQUISITION_FORMS,
  GET_ACQUISITION_FORMS_ERROR,
  GET_ACQUISITION_FORMS_SUCCESS,
  GET_ALL_CREATED_ACQUISITIONS,
  GET_ALL_CREATED_ACQUISITIONS_ERROR,
  GET_ALL_CREATED_ACQUISITIONS_SUCCESS,
  CREATE_EDITED_FAS_ACQUISITION_VOUCHERS,
  CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_ERROR,
  CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_SUCCESS,
  DELETE_ACQUISITION,
  DELETE_ACQUISITION_ERROR,
  DELETE_ACQUISITION_SUCCESS,
} from './constants'

export const createFASAcquisitionVouchers = ({ companyCode, ...form }) => ({
  type: CREATE_FAS_ACQUISITION_VOUCHERS,
  companyCode,
  ...form,
})

export const createFASAcquisitionVouchersError = (error) => ({
  type: CREATE_FAS_ACQUISITION_VOUCHERS_ERROR,
  error,
})
export const createFASAcquisitionVouchersSuccess = () => ({
  type: CREATE_FAS_ACQUISITION_VOUCHERS_SUCCESS,
})

export const getAcquisitionForms = ({ companyCode, generatorId }) => ({
  type: GET_ACQUISITION_FORMS,
  companyCode,
  generatorId,
})

export const getAcquisitionFormsError = (error) => ({
  type: GET_ACQUISITION_FORMS_ERROR,
  error,
})
export const getAcquisitionFormsSuccess = ({ companyCode, forms }) => ({
  type: GET_ACQUISITION_FORMS_SUCCESS,
  companyCode,
  forms,
})

export const getAllCreatedAcquisitions = ({ companyCode }) => ({
  type: GET_ALL_CREATED_ACQUISITIONS,
  companyCode,
})

export const getAllCreatedAcquisitionsError = (error) => ({
  type: GET_ALL_CREATED_ACQUISITIONS_ERROR,
  error,
})

export const getAllCreatedAcquisitionsSuccess = ({ companyCode, forms }) => ({
  type: GET_ALL_CREATED_ACQUISITIONS_SUCCESS,
  companyCode,
  forms,
})

export const createEditedFASAcquisitionVouchers = ({
  companyCode,
  ...form
}) => ({
  type: CREATE_EDITED_FAS_ACQUISITION_VOUCHERS,
  companyCode,
  ...form,
})

export const createEditedFASAcquisitionVouchersError = (error) => ({
  type: CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_ERROR,
  error,
})

export const createEditedFASAcquisitionVouchersSuccess = () => ({
  type: CREATE_EDITED_FAS_ACQUISITION_VOUCHERS_SUCCESS,
})

export const deleteAcquisition = ({ companyCode, id }) => ({
  type: DELETE_ACQUISITION,
  companyCode,
  id,
})

export const deleteAcquisitionError = (error) => ({
  type: DELETE_ACQUISITION_ERROR,
  error,
})
export const deleteAcquisitionSuccess = ({ companyCode, id }) => ({
  type: DELETE_ACQUISITION_SUCCESS,
  companyCode,
  id,
})
